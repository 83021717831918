import React, {
    ReactNode,
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { IEditDesignResultsViewModel } from "types/generated/Calfrac/Jet/Web/Models/DesignResults/IEditDesignResultsViewModel";

import StandardMaskedTextBox from "components/kendoExtensions/standardExtensions/StandardMaskedTextBox";
import StandardNumericTextBox from "components/kendoExtensions/standardExtensions/StandardNumericTextBox";
import CementView, { NotCementView } from "components/Layout/CementView";
import CtanView, { NotCtanView } from "components/Layout/CtanView";
import FormSection, { Title } from "components/Layout/FormSection";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardValidationSummary from "components/shared/GenericCustomComponents/StandardValidationSummary";
import DesignTable from "components/Tables/Design/DesignTable";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { EmptyMasks, Masks } from "utils/enumerations";
import { CountriesWithUnitsT, fieldFormats } from "utils/formatLookupTable";
import { resources } from "utils/resources";

type EditableTablesT =
    | IEditDesignResultsViewModel["BaseFluids"]
    | IEditDesignResultsViewModel["EnergyTypes"]
    | IEditDesignResultsViewModel["Chemicals"]
    | IEditDesignResultsViewModel["PremixedChemicals"]
    | IEditDesignResultsViewModel["Proppants"]
    | IEditDesignResultsViewModel["Activators"]
    | IEditDesignResultsViewModel["Cements"];

const Page: React.FC = () => {
    // Constants
    const url = "/api/DesignResults/EditDesignResults";
    const { reset } = useFormContext();
    const firstModelState = useRef<IEditDesignResultsViewModel>();

    const model = useWatch() as IEditDesignResultsViewModel;
    const [firstModelStateSaved, setFirstmodelStateSaved] = useState(false);

    useEffect(() => {
        // check for any table (in this case "BaseFluids") existing in model
        // to make sure we have the correct state of the model that includes the table data to save,
        // otherwise save will fail the first time as no reference table exists to compare data,
        // this is a race condition with data updates on first load
        if (
            Object.keys(model).length &&
            Object.keys(model).includes("BaseFluids") &&
            !firstModelStateSaved
        ) {
            firstModelState.current = model;
            setFirstmodelStateSaved(true);
        }
    }, [model, firstModelStateSaved]);

    // Updating this render hash will rerender all the rows. This is useful for data changes like after saving.
    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    const detectChangedFields = useCallback(() => {
        if (!model || !firstModelState.current) return;

        // Clone the model so we can modify it
        const nextModelState = JSON.parse(JSON.stringify(model));

        const Tables: Array<keyof IEditDesignResultsViewModel> = [
            "BaseFluids",
            "EnergyTypes",
            "Chemicals",
            "PremixedChemicals",
            "Proppants",
            "Activators",
            "AdditionalChemicals",
            "Cements",
        ];

        for (const table of Tables) {
            const referenceTable = firstModelState.current[
                table
            ] as EditableTablesT;
            const modelTable = model[table] as EditableTablesT;
            const nextTable = nextModelState[table];

            if (!Array.isArray(modelTable) || !Array.isArray(referenceTable)) {
                continue;
            }

            // For each row of model.BaseFluid, check if the corresponding row in firstModelState.current.BaseFluid has changed
            // If it has, then set isBaseFluidChanged to true
            modelTable.forEach((tableRow, index) => {
                let referenceRow = referenceTable[index];

                nextTable[index].IsFinalTotalChanged =
                    tableRow?.FinalTotal !== referenceRow?.FinalTotal;

                nextTable[index].IsContingencyChanged =
                    tableRow?.Contingency !== referenceRow?.Contingency;

                if ("Bottoms" in tableRow && "Bottoms" in referenceRow) {
                    nextTable[index].IsBottomsChanged =
                        tableRow?.Bottoms !== referenceRow?.Bottoms;
                }

                if (
                    "BottomsAmount" in tableRow &&
                    "BottomsAmount" in referenceRow
                ) {
                    nextTable[index].IsBottomsAmountChanged =
                        tableRow?.BottomsAmount !== referenceRow?.BottomsAmount;
                    if (nextTable[index].IsLiquid) {
                        nextTable[index].BottomsAmountLiquid =
                            nextTable[index].BottomsAmount;
                    } else {
                        nextTable[index].BottomsAmountGas =
                            nextTable[index].BottomsAmount;
                    }
                }

                if (
                    "BottomsVolume" in tableRow &&
                    "BottomsVolume" in referenceRow
                ) {
                    nextTable[index].IsBottomsVolumeChanged =
                        tableRow?.BottomsVolume !== referenceRow?.BottomsVolume;
                }

                if (
                    "ContingencyVolume" in tableRow &&
                    "ContingencyVolume" in referenceRow
                ) {
                    nextTable[index].IsContingencyVolumeChanged =
                        tableRow?.ContingencyVolume !==
                        referenceRow?.ContingencyVolume;
                }

                if (
                    "ContingencyAmount" in tableRow &&
                    "ContingencyAmount" in referenceRow
                ) {
                    nextTable[index].IsContingencyAmountChanged =
                        tableRow?.ContingencyAmount !==
                        referenceRow?.ContingencyAmount;
                }

                if ("IsLiquid" in tableRow) {
                    nextTable[index].ContingencyAmountGas =
                        tableRow.ContingencyAmount;
                    nextTable[index].ContingencyAmountLiquid =
                        tableRow.ContingencyAmount;

                    nextTable[index].FinalTotalGas = tableRow.FinalTotal;
                    nextTable[index].FinalTotalLiquid = tableRow.FinalTotal;

                    nextTable[index].CoiledTubingFillAmountGas =
                        tableRow.CoiledTubingFillAmount;
                    nextTable[index].CoiledTubingFillAmountLiquid =
                        tableRow.CoiledTubingFillAmount;
                }

                if ("IsWetChemical" in tableRow) {
                    nextTable[index].ContingencyAmountWet =
                        tableRow.ContingencyAmount;
                    nextTable[index].ContingencyAmountDry =
                        tableRow.ContingencyAmount;

                    nextTable[index].CoiledTubingFillAmountDry =
                        tableRow.CoiledTubingFillAmount;
                    nextTable[index].CoiledTubingFillAmountWet =
                        tableRow.CoiledTubingFillAmount;
                }

                if ("IsWet" in tableRow) {
                    nextTable[index].ContingencyAmountWet =
                        tableRow.Contingency;
                    nextTable[index].ContingencyAmountDry =
                        tableRow.Contingency;

                    nextTable[index].FinalTotalWet = tableRow.FinalTotal;
                    nextTable[index].FinalTotalDry = tableRow.FinalTotal;
                }
            });
        }

        reset(nextModelState);
    }, [model, reset]);

    // Url params
    const { programId, program, isPageEditable } = useProgramParams();

    const units = useUnits();
    const country =
        (program?.CountryId as CountriesWithUnitsT) ?? CountryId.Canada;

    return (
        <StandardForm
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            beforeSaveHandler={detectChangedFields}
            onSaveSuccess={(data) => {
                updateTableRenderHash();
                firstModelState.current =
                    data as unknown as IEditDesignResultsViewModel;
            }}
        >
            <StandardValidationSummary errorMsg={""} />
            <FormSection label={resources.Summary} flexDir={"row"}>
                <div className={"col-span-full"}>
                    <div className={"col-span-full grid grid-cols-4 gap-6"}>
                        <div>
                            <NotCementView>
                                <StandardNumericTextBox
                                    id="edit-design-results-frac-gradient"
                                    name={"FracGradient"}
                                    label={`${resources.FracGradient} ${units.fractureGradientUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.FracGradient"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <StandardNumericTextBox
                                    id="edit-design-results-depth-to-top-perf"
                                    name={"DepthToTopPerforation"}
                                    label={`${resources.DepthToTopPerf} ${units.depthUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.DepthToTopPerforation"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                            </NotCementView>
                            <StandardNumericTextBox
                                id="edit-design-results-true-vertical-depth"
                                name={"TrueVerticalDepth"}
                                label={`${resources.TVD} ${units.depthUnit}`}
                                format={
                                    fieldFormats[
                                        "DesignResults.TrueVerticalDepth"
                                    ][country]
                                }
                                disabled={true}
                            />
                            <CementView>
                                <StandardNumericTextBox
                                    id="edit-design-results-surface-pumping-pressure"
                                    name={"SurfacePumpingPressure"}
                                    label={`${resources.SurfacePumpingPressure} ${units.pressureSmallUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.SurfacePumpingPressure"
                                        ][country]
                                    }
                                />
                            </CementView>
                            <NotCementView>
                                <StandardNumericTextBox
                                    id="edit-design-results-specific-gravity"
                                    name={"SpecificGravity"}
                                    label={resources.SpecificGravity}
                                    format={
                                        fieldFormats[
                                            "DesignResults.SpecificGravity"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <CtanView>
                                    <StandardNumericTextBox
                                        name={"MaxDepth"}
                                        label={`${resources.MaxDepthCoil} ${units.depthUnit}`}
                                        format={
                                            fieldFormats[
                                                "DesignResults.MaxDepth"
                                            ][country]
                                        }
                                        disabled={true}
                                    />
                                </CtanView>
                            </NotCementView>
                        </div>
                        <div>
                            <CementView>
                                <StandardNumericTextBox
                                    id="edit-design-results-max-coil-depth"
                                    name={"MaxCoilDepth"}
                                    label={`${resources.MaxDepthCoil} ${units.depthUnit}`}
                                    disabled={true}
                                    format={
                                        fieldFormats[
                                            "DesignResults.MaxCoilDepth"
                                        ][country]
                                    }
                                />
                            </CementView>
                            <NotCementView>
                                <StandardNumericTextBox
                                    id="edit-design-results-bottom-hole-fracture"
                                    name={"BottomHoleFracture"}
                                    label={`${resources.BottomHoleFracture} ${units.pressureSmallUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.BottomHoleFracture"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <StandardNumericTextBox
                                    id="edit-design-results-pumping-friction-pressure"
                                    name={"PumpingFrictionPressure"}
                                    label={`${resources.PumpingFrictionPressure} ${units.pressureSmallUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.PumpingFrictionPressure"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <StandardNumericTextBox
                                    id="edit-design-results-hydrostatic-head"
                                    name={"HydrostaticHead"}
                                    label={`${resources.HydrostaticHead} ${units.pressureSmallUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.HydrostaticHead"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <StandardNumericTextBox
                                    id="edit-design-results-surface-pumping-pressure"
                                    name={"SurfacePumpingPressure"}
                                    label={`${resources.SurfacePumpingPressure} ${units.pressureSmallUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.SurfacePumpingPressure"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                            </NotCementView>
                            <StandardNumericTextBox
                                id="edit-design-results-required-pump-power"
                                name={"RequiredPumpPower"}
                                label={`${resources.MaxRequiredPumpPower} ${units.powerUnit}`}
                                format={
                                    fieldFormats[
                                        "DesignResults.RequiredPumpPower"
                                    ][country]
                                }
                                disabled={true}
                            />
                        </div>
                        <div>
                            <CementView>
                                <StandardNumericTextBox
                                    id="edit-design-results-max-rate"
                                    name={"MaxRate"}
                                    label={`${resources.MaxRate} ${units.rateWetFastUnit}`}
                                    format={
                                        fieldFormats["DesignResults.MaxRate"][
                                            country
                                        ]
                                    }
                                    disabled={true}
                                />
                            </CementView>
                            <NotCementView>
                                <StandardNumericTextBox
                                    id="edit-design-results-slurry-rate"
                                    name={"SlurryRate"}
                                    label={`${resources.MaxSlurryRate} ${units.rateWetFastUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.SlurryRate"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <StandardNumericTextBox
                                    name={"MaxFluidRate"}
                                    label={`${resources.MaxFluidRate} ${units.rateWetFastUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.MaxFluidRate"
                                        ][country]
                                    }
                                    disabled={true}
                                />

                                <StandardNumericTextBox
                                    name={"MaxN2Rate"}
                                    label={`${resources.MaxN2Rate} ${units.gasRateUnit}`}
                                    format={
                                        fieldFormats["DesignResults.MaxN2Rate"][
                                            country
                                        ]
                                    }
                                    disabled={true}
                                />

                                <StandardNumericTextBox
                                    name={"MaxCombinedDownholeRate"}
                                    label={`${resources.MaxCombinedDownholeRate} ${units.rateWetFastUnit}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.MaxCombinedDownholeRate"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <CtanView>
                                    <StandardNumericTextBox
                                        name={"MaxCombinedDisplacementRate"}
                                        label={`${resources.MaxCombinedDisplacementRate} ${units.rateWetFastUnit}`}
                                        format={
                                            fieldFormats[
                                                "DesignResults.MaxCombinedDisplacementRate"
                                            ][country]
                                        }
                                        disabled={true}
                                    />
                                </CtanView>
                            </NotCementView>
                        </div>

                        <NotCementView>
                            <div>
                                {program?.CountryId === CountryId.Argentina && (
                                    <CtanView>
                                        <StandardNumericTextBox
                                            name={"KickOffPointBottomsUpVolume"}
                                            label={`${resources.KickOffPointBottomsUpVolume} ${units.volumeBottomsUp}`}
                                            format={
                                                fieldFormats[
                                                    "DesignResults.KickOffPointBottomsUpVolume"
                                                ][country]
                                            }
                                            disabled={true}
                                        />
                                        <StandardNumericTextBox
                                            name={"OneThousandMBottomsUpVolume"}
                                            label={`${resources.OneThousandMBottomsUpVolume} ${units.volumeBottomsUp}`}
                                            format={
                                                fieldFormats[
                                                    "DesignResults.OneThousandMBottomsUpVolume"
                                                ][country]
                                            }
                                            disabled={true}
                                        />
                                    </CtanView>
                                )}
                                <StandardNumericTextBox
                                    name={"NumberBridgePlugs"}
                                    label={`${resources.NumberBridgePlugs}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.NumberBridgePlugs"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <StandardNumericTextBox
                                    name={"NumberFracPorts"}
                                    label={`${resources.NumberFracPorts}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.NumberFracPorts"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                                <StandardNumericTextBox
                                    name={"NumberOfIntervals"}
                                    label={`${resources.NumberOfIntervals}`}
                                    format={
                                        fieldFormats[
                                            "DesignResults.NumberOfIntervals"
                                        ][country]
                                    }
                                    disabled={true}
                                />
                            </div>
                        </NotCementView>
                    </div>
                </div>
            </FormSection>
            <FormSection label={resources.JobParameters}>
                <div className={"col-span-full"}>
                    <div className={"col-span-full grid grid-cols-4 gap-6"}>
                        <div>
                            <StandardMaskedTextBox
                                name={"EstimatedTime"}
                                mask={Masks.TimeInputMask}
                                emptyMask={EmptyMasks.TimeInputMask}
                                label={resources.EstimatedTime}
                                disabled={!isPageEditable}
                                className={"mt-[6px]"}
                                size={"medium"}
                            />
                        </div>
                        <div>
                            <StandardNumericTextBox
                                id="edit-design-results-max-treating-pressure"
                                name={"MaxTreatingPressure"}
                                label={`${resources.MaxTreatingPressure} ${units.pressureSmallUnit}`}
                                format={
                                    fieldFormats[
                                        "DesignResults.MaxTreatingPressure"
                                    ][country]
                                }
                            />
                        </div>
                        <div>
                            <StandardNumericTextBox
                                id="edit-design-results-max-treating-rate"
                                name={"MaxTreatingRate"}
                                label={`${resources.MaxTreatingRate} ${units.rateWetFastUnit}`}
                                format={
                                    fieldFormats[
                                        "DesignResults.MaxTreatingRate"
                                    ][country]
                                }
                            />
                        </div>
                    </div>
                </div>
            </FormSection>
            <div className="border-2">
                <div className="grid grid-cols-2 text-sm">
                    <div className="bg-calfrac-gray-100 p-2">
                        {resources.StageType}
                    </div>
                    <div className="bg-calfrac-gray-100 p-2">
                        {resources.StageTypeTime}
                    </div>
                    {model.StageDescriptionTypeTimeBreakdown?.map(
                        (breakdown) => (
                            <>
                                <div
                                    className={`bg-calfrac-gray-50 p-2 ${
                                        breakdown.StageDescriptionType ===
                                        "Total time"
                                            ? "font-semibold underline"
                                            : ""
                                    }`}
                                >
                                    {breakdown.StageDescriptionType}
                                </div>
                                <div
                                    className={`bg-calfrac-gray-50 p-2 ${
                                        breakdown.StageDescriptionType ===
                                        "Total time"
                                            ? "font-semibold underline"
                                            : ""
                                    }`}
                                >
                                    {breakdown.TotalTime}
                                </div>
                            </>
                        ),
                    )}
                </div>
            </div>
            <DesignFormSection label={resources.Tubulars}>
                <DesignTable
                    name={"Tubulars"}
                    renderHash={tableRenderHash}
                    HasCoiledTubings={model?.HasCoiledTubings}
                    HasCasings={model?.HasCasings}
                />
            </DesignFormSection>
            <NotCementView>
                <DesignFormSection label={resources.FrictionGradient}>
                    <DesignTable
                        name={"FrictionGradients"}
                        renderHash={tableRenderHash}
                    />
                </DesignFormSection>
            </NotCementView>
            <DesignFormSection label={resources.Fluids}>
                <DesignTable name={"BaseFluids"} renderHash={tableRenderHash} />
            </DesignFormSection>
            <NotCementView>
                <NotCtanView>
                    <DesignFormSection label={resources.WaterSummary}>
                        <DesignTable
                            name={"BaseFluidSummaries"}
                            renderHash={tableRenderHash}
                        />
                    </DesignFormSection>
                </NotCtanView>
            </NotCementView>
            <NotCementView>
                <DesignFormSection label={resources.EnergizedFluids}>
                    <DesignTable
                        name={"EnergyTypes"}
                        renderHash={tableRenderHash}
                    />
                </DesignFormSection>
            </NotCementView>
            <DesignFormSection label={resources.Chemicals}>
                <DesignTable
                    name={"Chemicals"}
                    showBothFluidTypes={Boolean(model?.HasSecondaryFluid)}
                    renderHash={tableRenderHash}
                />
            </DesignFormSection>
            <DesignFormSection label={resources.Premix}>
                <DesignTable
                    name={"PremixedChemicals"}
                    renderHash={tableRenderHash}
                />
            </DesignFormSection>
            <DesignFormSection label={resources.AdditionalChemicals}>
                <DesignTable
                    name={"AdditionalChemicals"}
                    renderHash={tableRenderHash}
                />
            </DesignFormSection>
            <CementView>
                <DesignFormSection label={resources.Cements}>
                    <DesignTable
                        name={"Cements"}
                        renderHash={tableRenderHash}
                    />
                </DesignFormSection>
            </CementView>
            <NotCementView>
                <DesignFormSection label={resources.Proppants}>
                    <DesignTable
                        name={"Proppants"}
                        renderHash={tableRenderHash}
                    />
                </DesignFormSection>
                <NotCtanView>
                    <DesignFormSection label={resources.Activators}>
                        <DesignTable
                            name={"Activators"}
                            renderHash={tableRenderHash}
                        />
                    </DesignFormSection>
                </NotCtanView>
            </NotCementView>
        </StandardForm>
    );
};

const DesignFormSection: React.FC<{
    label: string;
    children: ReactNode;
}> = ({ label, children }) => (
    <>
        <div className="z-0 pt-2">
            <div className="bg-white shadow-md ring-1 ring-calfrac-gray/5  md:col-span-2">
                {label && <Title title={label} />}
                <div className="flex flex-col gap-x-6 gap-y-14">{children}</div>
            </div>
        </div>
    </>
);

export default Page;

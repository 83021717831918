//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PremixedChemicalTypeId {
	Standard = 1,
	Custom = 2,
	Extra = 3,
	PeriodicPill = 4,
	ExternalInhibitor = 5,
	ContingencyChemical = 6
}

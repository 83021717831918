import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import { Dialog } from "@progress/kendo-react-all";

import StandardButton from "components/shared/GenericCustomComponents/StandardButton";
import { ValidationSummary } from "components/shared/GenericCustomComponents/StandardValidationSummary";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import { resources } from "utils/resources";

import BaseFluidTable from "./BaseFluidTable";

type Props = {
    row?:
        | { fluidSystemId?: number; rowIdx?: number; open: boolean }
        | undefined;
    onClose?: () => void;
};

const BaseFluidConcentrationsPopup = ({ row, onClose }: Props) => {
    // Name constants
    const name = `FluidSystems.${row?.rowIdx}.BaseFluids`;

    // Index of the row with error (if any)
    const errorRowIdx: number | null | undefined = useWatch({
        name: `ErrorRowIdx`,
    });

    const [isLoading] = useState<boolean>(false);

    if (!row?.fluidSystemId || (row.rowIdx !== 0 && !row?.rowIdx)) return <></>;

    return (
        <Dialog
            title={resources.BaseFluidConcentrations}
            onClose={onClose}
            width={550}
            height={380}
            themeColor={"primary"}
        >
            {!isLoading && (
                <div className={"relative h-[280px]"}>
                    <div>
                        {errorRowIdx === row.rowIdx && <ValidationSummary />}
                        <div
                            className={
                                "mt-2 h-[220px] overflow-y-auto overflow-x-hidden"
                            }
                        >
                            <BaseFluidTable name={name} />
                        </div>
                    </div>
                    <div className={"mr-2 mt-12 flex justify-end"}>
                        <StandardButton
                            classes={
                                "main-header-action main-header-action--primary"
                            }
                            text={"Done"}
                            onClick={onClose}
                        />
                    </div>
                </div>
            )}
            {isLoading && <LoadingOverlay />}
        </Dialog>
    );
};

export default BaseFluidConcentrationsPopup;

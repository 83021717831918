import { CalculationTypeId } from "types/generated/Calfrac/Jet/Core/Models/CalculationTypeId";

import { FormulaCalculateScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/FormulaCalculateScheduleStageTarget";
import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import { resources } from "utils/resources";

export const FormulaCalculateColumnInfo: {
    [key: string]: FormulaCalculateScheduleStageTarget;
} = {
    StartDepth: FormulaCalculateScheduleStageTarget.StartDepth,
    EndDepth: FormulaCalculateScheduleStageTarget.EndDepth,
    Speed: FormulaCalculateScheduleStageTarget.Speed,
    Time: FormulaCalculateScheduleStageTarget.Time,
    FluidRate: FormulaCalculateScheduleStageTarget.FluidRate,
    FluidTotal: FormulaCalculateScheduleStageTarget.FluidTotal,
    N2Rate: FormulaCalculateScheduleStageTarget.N2Rate,
    N2Total: FormulaCalculateScheduleStageTarget.N2Total,
    CombinedDisplacementRate:
        FormulaCalculateScheduleStageTarget.CombinedDisplacementRate,
    CombinedDisplacementTotal:
        FormulaCalculateScheduleStageTarget.CombinedDisplacementTotal,
};

export const TargetToName: {
    [key in FormulaCalculateScheduleStageTarget]?: string;
} = {
    [FormulaCalculateScheduleStageTarget.StartDepth]: resources.StartDepth,
    [FormulaCalculateScheduleStageTarget.EndDepth]: resources.EndDepth,
    [FormulaCalculateScheduleStageTarget.Speed]: resources.Speed,
    [FormulaCalculateScheduleStageTarget.Time]: resources.Time,
    [FormulaCalculateScheduleStageTarget.FluidRate]: resources.FluidRate,
    [FormulaCalculateScheduleStageTarget.FluidTotal]: resources.FluidTotal,
    [FormulaCalculateScheduleStageTarget.N2Rate]: resources.N2Rate,
    [FormulaCalculateScheduleStageTarget.N2Total]: resources.N2Total,
    [FormulaCalculateScheduleStageTarget.CombinedDisplacementRate]:
        resources.CombinedDisplacementRate,
    [FormulaCalculateScheduleStageTarget.CombinedDisplacementTotal]:
        resources.CombinedDisplacementTotal,
};

export const Formulas: {
    [key in FormulaCalculateScheduleStageTarget]: {
        [key in CalculationTypeId]?: string;
    };
} = {
    [FormulaCalculateScheduleStageTarget.StartDepth]: {
        [CalculationTypeId.StartDepthPreviousStartDepth]:
            resources.PreviousStartDepth,
        [CalculationTypeId.StartDepthPreviousEndDepth]:
            resources.PreviousEndDepth,
        [CalculationTypeId.StartDepthSameEndDepth]: resources.SameEndDepth,
        [CalculationTypeId.StartDepthTimeEndDepthAndSpeed]:
            resources.TimeEndDepthAndSpeed,

        [CalculationTypeId.StartDepthPerforationTop]: resources.PerforationTop,
        [CalculationTypeId.StartDepthPerforationBottom]:
            resources.PerforationBottom,
        [CalculationTypeId.StartDepthPerforationPlugDepth]:
            resources.PerforationPlugDepth,
        [CalculationTypeId.StartDepthWellConfigPbtd]: resources.WellConfigPBTD,
        [CalculationTypeId.StartDepthWellConfigKop]: resources.WellConfigKOP,
        [CalculationTypeId.StartDepthReservoirTvd]: resources.ReservoirTVD,
        [CalculationTypeId.StartDepthWellConfigHeel]: resources.WellConfigHeel,
        [CalculationTypeId.StartDepthWellConfigCasingTop]:
            resources.WellConfigCasingTop,
        [CalculationTypeId.StartDepthWellConfigLinerTop]:
            resources.WellConfigLinerTop,
        [CalculationTypeId.StartDepthWellConfigTubingTop]:
            resources.WellConfigTubingTop,
        [CalculationTypeId.StartDepthWellConfigOpenHoleTop]:
            resources.WellConfigOpenHoleTop,
        [CalculationTypeId.StartDepthWellConfigCasingBottom]:
            resources.WellConfigCasingBottom,
        [CalculationTypeId.StartDepthWellConfigLinerBottom]:
            resources.WellConfigLinerBottom,
        [CalculationTypeId.StartDepthWellConfigTubingBottom]:
            resources.WellConfigTubingBottom,
        [CalculationTypeId.StartDepthWellConfigOpenHoleBottom]:
            resources.WellConfigOpenHoleBottom,
        [CalculationTypeId.StartDepthWellConfigDownholeItem]:
            resources.WellConfigDownHoleItem,
        [CalculationTypeId.StartDepthPrevScheduleStartDepth]:
            resources.PreviousScheduleStartDepth,
        [CalculationTypeId.StartDepthPrevScheduleEndDepth]:
            resources.PreviousScheduleEndDepth,
    },
    [FormulaCalculateScheduleStageTarget.EndDepth]: {
        [CalculationTypeId.EndDepthPreviousStartDepth]:
            resources.PreviousStartDepth,
        [CalculationTypeId.EndDepthPreviousEndDepth]:
            resources.PreviousEndDepth,
        [CalculationTypeId.EndDepthSameStartDepth]: resources.SameStartDepth,
        [CalculationTypeId.EndDepthTimeStartDepthAndSpeed]:
            resources.TimeStartDepthAndSpeed,
        [CalculationTypeId.EndDepthPerforationTop]: resources.PerforationTop,
        [CalculationTypeId.EndDepthPerforationBottom]:
            resources.PerforationBottom,
        [CalculationTypeId.EndDepthPerforationPlugDepth]:
            resources.PerforationPlugDepth,
        [CalculationTypeId.EndDepthWellConfigPbtd]: resources.WellConfigPBTD,
        [CalculationTypeId.EndDepthWellConfigKop]: resources.WellConfigKOP,
        [CalculationTypeId.EndDepthReservoirTvd]: resources.ReservoirTVD,
        [CalculationTypeId.EndDepthWellConfigHeel]: resources.WellConfigHeel,
        [CalculationTypeId.EndDepthWellConfigCasingTop]:
            resources.WellConfigCasingTop,
        [CalculationTypeId.EndDepthWellConfigLinerTop]:
            resources.WellConfigLinerTop,
        [CalculationTypeId.EndDepthWellConfigTubingTop]:
            resources.WellConfigTubingTop,
        [CalculationTypeId.EndDepthWellConfigOpenHoleTop]:
            resources.WellConfigOpenHoleTop,
        [CalculationTypeId.EndDepthWellConfigCasingBottom]:
            resources.WellConfigCasingBottom,
        [CalculationTypeId.EndDepthWellConfigLinerBottom]:
            resources.WellConfigLinerBottom,
        [CalculationTypeId.EndDepthWellConfigTubingBottom]:
            resources.WellConfigTubingBottom,
        [CalculationTypeId.EndDepthWellConfigOpenHoleBottom]:
            resources.WellConfigOpenHoleBottom,
        [CalculationTypeId.EndDepthWellConfigDownholeItem]:
            resources.WellConfigDownHoleItem,
        [CalculationTypeId.EndDepthPrevScheduleStartDepth]:
            resources.PreviousScheduleStartDepth,
        [CalculationTypeId.EndDepthPrevScheduleEndDepth]:
            resources.PreviousScheduleEndDepth,
    },
    [FormulaCalculateScheduleStageTarget.Speed]: {
        [CalculationTypeId.SpeedStartDepthEndDepthAndTime]:
            resources.StartDepthEndDepthAndTime,
    },
    [FormulaCalculateScheduleStageTarget.Time]: {
        [CalculationTypeId.TimeStartDepthEndDepthAndSpeed]:
            resources.StartDepthEndDepthAndSpeed,
        [CalculationTypeId.TimeFluidTotalAndRate]: resources.FluidTotalAndRate,
        [CalculationTypeId.TimeN2TotalAndRate]: resources.N2TotalAndRate,
        [CalculationTypeId.TimeCombinedDisplacementTotalAndRate]:
            resources.CombinedDisplacementTotalAndRate,
    },
    [FormulaCalculateScheduleStageTarget.FluidRate]: {
        [CalculationTypeId.FluidRateTotalAndTime]: resources.TotalAndTime,
    },
    [FormulaCalculateScheduleStageTarget.N2Rate]: {
        [CalculationTypeId.N2RateTotalAndTime]: resources.TotalAndTime,
    },
    [FormulaCalculateScheduleStageTarget.CombinedDisplacementRate]: {
        [CalculationTypeId.CombinedDisplacementRateTotalAndTime]:
            resources.TotalAndTime,
    },
    [FormulaCalculateScheduleStageTarget.FluidTotal]: {
        [CalculationTypeId.FluidTotalRateAndTime]: resources.RateAndTime,
        [CalculationTypeId.FluidTotalPerforationsVolumeOne]:
            resources.PerfVolumeOne,
        [CalculationTypeId.FluidTotalPerforationsVolumeTwo]:
            resources.PerfVolumeTwo,
        [CalculationTypeId.FluidTotalPerforationsDispVolume]:
            resources.PerfDisplacementVolume,
        [CalculationTypeId.FluidTotalWellConfigCTVolume]:
            resources.WellConfigCTVolume,
        [CalculationTypeId.FluidTotalFlowConfigTotalVolume]:
            resources.FlowConfigTotalVolume,
        [CalculationTypeId.FluidTotalFlowConfigVolume]:
            resources.FlowConfigVolume,
        [CalculationTypeId.FluidTotalPreviousScheduleVolume]:
            resources.PreviousScheduleVolume,
        [CalculationTypeId.FluidTotalPreviousSchedulesVolumeSum]:
            resources.PreviousSchedulesVolumeSum,
        [CalculationTypeId.FluidTotalPerfBottomsUpVolume]:
            resources.PerfBottomsUpVolume,
    },
    [FormulaCalculateScheduleStageTarget.N2Total]: {
        [CalculationTypeId.N2TotalRateAndTime]: resources.RateAndTime,
        [CalculationTypeId.N2TotalPerforationsVolumeOne]:
            resources.PerfVolumeOne,
        [CalculationTypeId.N2TotalPerforationsVolumeTwo]:
            resources.PerfVolumeTwo,
        [CalculationTypeId.N2TotalPerforationsDispVolume]:
            resources.PerfDisplacementVolume,
        [CalculationTypeId.N2TotalWellConfigCTVolume]:
            resources.WellConfigCTVolume,
        [CalculationTypeId.N2TotalFlowConfigTotalVolume]:
            resources.FlowConfigTotalVolume,
        [CalculationTypeId.N2TotalFlowConfigVolume]: resources.FlowConfigVolume,
        [CalculationTypeId.N2TotalPreviousScheduleVolume]:
            resources.PreviousScheduleVolume,
        [CalculationTypeId.N2TotalPreviousSchedulesVolumeSum]:
            resources.PreviousSchedulesVolumeSum,
    },
    [FormulaCalculateScheduleStageTarget.CombinedDisplacementTotal]: {
        [CalculationTypeId.CombinedDisplacementTotalRateAndTime]:
            resources.RateAndTime,
        [CalculationTypeId.CombinedDisplacementTotalPerforationsVolumeOne]:
            resources.PerfVolumeOne,
        [CalculationTypeId.CombinedDisplacementTotalPerforationsVolumeTwo]:
            resources.PerfVolumeTwo,
        [CalculationTypeId.CombinedDisplacementTotalPerforationsDispVolume]:
            resources.PerfDisplacementVolume,
        [CalculationTypeId.CombinedDisplacementTotalWellConfigCTVolume]:
            resources.WellConfigCTVolume,
        [CalculationTypeId.CombinedDisplacementTotalFlowConfigTotalVolume]:
            resources.FlowConfigTotalVolume,
        [CalculationTypeId.CombinedDisplacementTotalFlowConfigVolume]:
            resources.FlowConfigVolume,
        [CalculationTypeId.CombinedDisplacementTotalPreviousScheduleVolume]:
            resources.PreviousScheduleVolume,
        [CalculationTypeId.CombinedDisplacementTotalPreviousSchedulesVolumeSum]:
            resources.PreviousSchedulesVolumeSum,
    },
};

export const getFormulas = (target: FormulaCalculateScheduleStageTarget) => {
    const calculations: { [key in CalculationTypeId]?: string } =
        Formulas[target];
    const data: INamedItemViewModel[] = [];
    Object.keys(calculations).forEach((key) => {
        const calculationTypeId: CalculationTypeId = Number(
            key,
        ) as CalculationTypeId;
        const calculationName: string = calculations[calculationTypeId] ?? "";
        data.push({
            Id: calculationTypeId,
            Name: calculationName,
        } as INamedItemViewModel);
    });
    return data.sort((first, second) => (first.Name > second.Name ? 1 : -1));
};

// @ts-ignore
export default FormulaCalculateColumnInfo;

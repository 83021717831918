import React, { useEffect, useMemo, useReducer, useRef } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { IProgramProppantsViewModel } from "types/generated/Calfrac/Jet/Web/Models/FluidsEnergyAndProppants/IProgramProppantsViewModel";

import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import IntegerCell from "components/Cells/IntegerCell";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type ProppantsTableProps = {
    name: string;
    renderHash?: string;
};

const columnHelper = createColumnHelper<IProgramProppantsViewModel>();

const ProppantsTable: React.FC<ProppantsTableProps> = (props) => {
    const { isPageEditable } = useProgram();

    const tableRef = useRef<HTMLTableElement>(null);

    const units = useUnits();

    const { programId, program } = useProgramParams();

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.Proppant, {
                id: "Proppant",
                size: 1450,
                maxSize: 1450,
                header: resources.Proppant,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.Total, {
                id: "Total",
                size: 80,
                maxSize: 80,
                header: `${resources.Total} ${units.massSmallUnit}`,
                cell:
                    program?.CountryId === CountryId.Canada
                        ? FormattedNumericCell
                        : IntegerCell,
            }),
        ];
    }, [program?.CountryId, units.massSmallUnit]);

    return (
        <BaseTable
            tableRef={tableRef}
            name={props.name}
            defaultRecord={{}}
            columns={defaultColumns}
            renderHash={renderHash}
            meta={{
                canDelete: isPageEditable,
                urls: {
                    Proppant: `/api/FluidsEnergyProppantsCements/Proppant?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                },
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default ProppantsTable;

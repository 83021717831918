import React, { useReducer, useState } from "react";

import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import CementView, {
    isProgramCement,
    NotCementView,
} from "components/Layout/CementView";
import CtanView, { NotCtanView } from "components/Layout/CtanView";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import BaseFluidConcentrationsPopup from "components/Tables/FluidsEnergyProppantsCements/BaseFluidConcentrationsPopup";
import CementsTable from "components/Tables/FluidsEnergyProppantsCements/CementsTable";
import FluidSystemsTable from "components/Tables/FluidsEnergyProppantsCements/FluidSystemsTable";
import ProppantsTable from "components/Tables/FluidsEnergyProppantsCements/ProppantsTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";
import { useTitle } from "hooks/useTitle";

import { JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

const FluidsEnergyProppantsCements: React.FC = () => {
    // Constants
    const readUrl: JetApiUrls =
        "/api/FluidsEnergyProppantsCements/GetEditFluidsEnergyProppantsCements";
    const saveUrl: JetApiUrls =
        "/api/FluidsEnergyProppantsCements/EditFluidsEnergyProppantsCements";

    // Hooks
    const { program } = useProgram();
    const isCement = isProgramCement(program);

    useTitle(
        isCement
            ? resources.FluidsAndCements
            : resources.FluidsEnergyAndProppants,
    );

    const { programId, isPageEditable } = useProgramParams();

    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    const [row, setRow] = useState<
        { fluidSystemId?: number; rowIdx?: number; open: boolean } | undefined
    >(undefined);

    return (
        <StandardForm
            readUrl={readUrl}
            readParams={{ programId }}
            saveUrl={saveUrl}
            clearCacheOnSave={["/api/Schedule/EditSchedules"]}
            onSaveSuccess={updateTableRenderHash}
        >
            <FormSectionDivider>
                <FormSection label={resources.FluidSystems}>
                    <FluidSystemsTable
                        name={"FluidSystems"}
                        renderHash={tableRenderHash}
                        openConcentrationsWindow={(
                            open,
                            fluidSystemId,
                            rowIdx,
                        ) => {
                            setRow({ fluidSystemId, rowIdx, open });
                        }}
                    />
                    <NotCtanView>
                        <NotCementView>
                            <StandardCheckBox
                                name={"HasSecondaryFluidSystem"}
                                disabled={!isPageEditable}
                                label={resources.HasSecondaryFluidSystem}
                            />
                        </NotCementView>
                    </NotCtanView>
                    <NotCementView>
                        <StandardMultipleSelect
                            name={"EnergyTypes"}
                            url={"/api/FluidsEnergyProppantsCements/EnergyType"}
                            byField={"Id"}
                            className={
                                "col-span-full md:col-span-1 lg:col-span-1 xl:col-span-1 2xl:col-span-1"
                            }
                            params={{
                                programId,
                            }}
                            disabled={!isPageEditable}
                            label={resources.EnergizedFluids}
                        />
                    </NotCementView>
                    <CtanView>
                        <div
                            className={
                                "col-span-full flex items-end md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2"
                            }
                        >
                            <div
                                className={
                                    "rounded-lg border border-solid border-amber-200 bg-amber-200/30 p-2 text-xs font-medium"
                                }
                            >
                                {resources.OnePerforationRequired}
                            </div>
                        </div>
                    </CtanView>
                </FormSection>

                <NotCementView>
                    <FormSection label={resources.Proppants}>
                        <ProppantsTable
                            name={"Proppants"}
                            renderHash={tableRenderHash}
                        />
                    </FormSection>
                </NotCementView>
                <CementView>
                    <FormSection label={resources.Cements}>
                        <CementsTable
                            name={"Cements"}
                            renderHash={tableRenderHash}
                        />
                    </FormSection>
                </CementView>

                {row?.open && (
                    <BaseFluidConcentrationsPopup
                        row={row}
                        onClose={() => {
                            setRow(undefined);
                            updateTableRenderHash();
                        }}
                    />
                )}
            </FormSectionDivider>
        </StandardForm>
    );
};

export default FluidsEnergyProppantsCements;

import React, { useEffect, useState } from "react";
import { CellContext } from "@tanstack/react-table";

const TextReadOnly = ({
    getValue,
    styleReadOnly = true,
    ...props
}: CellContext<any, any> & { styleReadOnly?: boolean; value?: string }) => {
    const initialValue = // @ts-ignore
        typeof getValue() === "object" ? (getValue()?.Name ?? "") : getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <span
            className={`${
                styleReadOnly ? "read-only" : ""
            } inline-block w-full px-2 py-1 text-sm text-black shadow-none`}
        >
            {props.value ?? (value as string)}
        </span>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(TextReadOnly, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return prevValue === nextValue;
});

import React, {
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Table } from "@tanstack/react-table";

import { RenderHashMetaT } from "types/Tables/Cells";

import { getTextAreaRows } from "utils/helpers";

type BaseInputCellProps = {
    value: string | null;
    viewOnlyValue?: string | null;
    setValue: (value: string | null) => void;
    rowIndex: number;
    columnId: string;
    onBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
    table: Table<any>;
    isNumeric: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    inputRef?: RefObject<HTMLInputElement>;
    pasteColumnId?: string;
    applyReadOnlyStyle?: boolean;
    placeholder?: string;
};

const BaseInputCell = ({
    value,
    viewOnlyValue,
    setValue,
    rowIndex,
    columnId,
    onBlur,
    table,
    isNumeric,
    onKeyDown,
    inputRef,
    pasteColumnId,
    applyReadOnlyStyle = false,
    placeholder,
}: BaseInputCellProps) => {
    const defaultInputRef = useRef<HTMLInputElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const localInputRef = inputRef ?? defaultInputRef;
    const [inEdit, setInEdit] = useState(false);
    const [textAreaRows, setTextAreaRows] = useState(1);

    const tableMeta = table.options.meta as RenderHashMetaT;

    const onFocus = useCallback(() => {
        if (tableMeta?.disabled) return;

        setInEdit(true);
        tableMeta.select(rowIndex, columnId);
    }, [rowIndex, columnId, setInEdit, tableMeta]);

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        onKeyDown?.(e);

        // CMD+V and CTRL+V (PASTE hotkeys)
        if (e.key === "v" && (e.metaKey || e.ctrlKey)) {
            // Need to re-render before to ensure values are up to date for memoized cells
            if ("updateRenderHash" in (table.options.meta ?? {})) {
                tableMeta.updateRenderHash();
            }

            const copiedItems = await navigator.clipboard.readText();
            let itemsToPaste = copiedItems.split("\r\n");
            if (itemsToPaste.length === 0) {
                return;
            }
            //Knock off empty string after last line break
            if (itemsToPaste[itemsToPaste.length - 1] === "") {
                itemsToPaste.pop();
            }

            itemsToPaste.forEach((item, i) => {
                if (i === 0) {
                    setValue(item);
                    return;
                }
                const nextRowIndex = rowIndex + i;

                if (isNumeric)
                    tableMeta.updateNumericCellData(
                        nextRowIndex,
                        pasteColumnId ?? columnId,
                        item,
                    );
                else
                    tableMeta.updateData(
                        nextRowIndex,
                        pasteColumnId ?? columnId,
                        item,
                    );
            });

            if ("updateRenderHash" in (table.options.meta ?? {})) {
                tableMeta.updateRenderHash();
            }
        }
    };

    const onBlurLocal = (e: React.FocusEvent<HTMLInputElement>) => {
        onBlur?.(e);
        setInEdit(false);
    };

    useEffect(() => {
        if (inEdit) {
            localInputRef.current?.select();
            if (inputCurrentValue === "0.") setValue("0");
        }
        setTextAreaRows(getTextAreaRows(textAreaRef.current));
    }, [inEdit, localInputRef]); // eslint-disable-line react-hooks/exhaustive-deps

    const inputCurrentValue = useMemo(() => {
        if (!inEdit && viewOnlyValue !== undefined)
            return viewOnlyValue?.toString() ?? "";
        return value?.toString() ?? "";
    }, [inEdit, value, viewOnlyValue]);

    if (tableMeta.disabled) return <>{inputCurrentValue || ""}</>;

    return (
        <>
            {inEdit ? (
                <input
                    onKeyDown={handleKeyDown}
                    name={columnId}
                    onDragStart={(e) => e.preventDefault()}
                    disabled={tableMeta?.disabled}
                    ref={localInputRef}
                    value={inputCurrentValue}
                    onChange={(e) => {
                        // Get rid of irrelevant characters first
                        let newValue = e.target.value;
                        setValue(newValue.toString());
                    }}
                    onBlur={onBlurLocal}
                    onFocus={onFocus}
                    className={
                        "w-100 rounded border border-calfrac-gray-200 p-1 focus:shadow-none focus:ring-calfrac-gray-100 focus:ring-offset-1 focus:ring-offset-calfrac-gray-100"
                    }
                    placeholder={placeholder}
                />
            ) : (
                <textarea
                    rows={textAreaRows}
                    ref={textAreaRef}
                    onDragStart={(e) => e.preventDefault()}
                    wrap={"soft"}
                    name={columnId}
                    value={inputCurrentValue || ""}
                    onChange={() => void 0}
                    onFocus={onFocus}
                    className={`w-100 resize-none overflow-hidden border-0 bg-transparent p-1 ${
                        applyReadOnlyStyle ? "read-only" : ""
                    }`}
                    placeholder={placeholder}
                />
            )}
        </>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(BaseInputCell);

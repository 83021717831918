import React, { memo, useEffect, useMemo, useReducer, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditChemicalLoadingItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/ChemicalLoading/IEditChemicalLoadingItemViewModel";
import { IEditChemicalLoadingNoteViewModel } from "types/generated/Calfrac/Jet/Web/Models/ChemicalLoading/IEditChemicalLoadingNoteViewModel";

import CheckBox from "components/Cells/CheckBox";
import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";
import LoadDefaults from "components/shared/CustomGridToolbar/ToolbarElements/LoadDefaults";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import {
    ItemType,
    RecordType,
} from "components/Tables/BaseTable/Inner/InnerTable";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";
import NoteSection from "components/Tables/Schedules/NoteSection";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type ChemicalLoadingTableProps = {
    name: string;
    notesName: string;
    renderHash?: string;
    onEditNotes: () => void;
};

const columnHelper = createColumnHelper<any>();

const ChemicalLoadingTable: React.FC<ChemicalLoadingTableProps> = (props) => {
    const { programId, program, isPageEditable } = useProgramParams();
    const [displayNotInUse, setDisplayNotInUse] = useState<boolean>(false);
    const { setValue } = useFormContext();

    // This is to determine, for frac programs, whether we are currently set to Standard or Advanced.
    // We need to show notes ONLY if it is a Standard Frac configuration
    const isAdvancedChemSchedule = useWatch({
        name: "IsAdvancedChemSchedule",
    }) as boolean;

    const isStandardChemSchedule =
        typeof isAdvancedChemSchedule == "boolean" && !isAdvancedChemSchedule;

    const chemicalLoadingNotes: IEditChemicalLoadingNoteViewModel[] = useWatch({
        name: props.notesName,
    });

    const data: IEditChemicalLoadingItemViewModel[] = useWatch({
        name: props.name,
    });

    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);
    const isFrac = !isCtan && !isCement;

    // For "Notes" (edited in the pop-up modal) to be shown at the top of the table
    const [showNotesView, setShowNotesView] = useState(true);

    // Updating this render hash will rerender all the rows. This is useful for data changes like copying and pasting from excel.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const defaultColumns = useMemo(
        () => [
            columnHelper.display({
                id: "Delete",
                size: 20,
                cell: DeleteButton,
            }),
            columnHelper.accessor((row) => row.FluidSystemType, {
                id: "FluidSystemType",
                header: resources.FluidSystemType,
                size: 100,
                cell: isCement ? TextReadOnly : DropDown,
            }),
            columnHelper.accessor((row) => row.ProgramFluidSystem, {
                id: "ProgramFluidSystem",
                header: resources.FluidSystem,
                size: 350,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.IsPremixed, {
                id: "IsPremixed",
                header: resources.Premixed,
                size: 10,
                cell: CheckBox,
            }),
            columnHelper.accessor((row) => row.ApplyToFluid, {
                id: "ApplyToFluid",
                header: resources.ApplyToBaseFluid,
                size: 150,
                cell: (props) => (
                    <DropDown
                        url={`/api/FluidsEnergyProppantsCements/BaseFluids?programFluidSystemId=${props.row.original?.ProgramFluidSystem?.Id}`}
                        {...props}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.StageDescription, {
                id: "StageDescription",
                header: resources.StageDescription,
                size: 200,
                cell: (p) => (
                    <DropDown
                        {...p}
                        defaultItem={{
                            Id: 0,
                            Name: resources.ChemicalLoadingStageDescriptionDefault,
                        }}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.ChemicalView, {
                id: "ChemicalView",
                header: resources.Chemical,
                size: isCtan ? 300 : 200,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.Concentration, {
                id: "Concentration",
                header: resources.Concentration,
                size: 50,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor(
                (row) => row.ChemicalView?.ConcentrationUnitSymbol,
                {
                    id: "ChemicalView.ConcentrationUnitSymbol",
                    header: resources.Unit,
                    size: 50,
                    cell: TextReadOnly,
                },
            ),
            columnHelper.accessor((row) => row.IsUsedOnPumpSchedule, {
                id: "IsUsedOnPumpSchedule",
                header: resources.InUse,
                size: 50,
                cell: (props) => <CheckBox readOnly={true} {...props} />,
            }),
        ],
        [isCtan, isCement],
    );

    const toolbarExtras = () => {
        return (
            <>
                <div
                    className={
                        "sticky left-0 z-10 mb-1 flex w-full items-center space-x-4"
                    }
                >
                    <LoadDefaults
                        url={"/api/ChemicalLoading/LoadDefaults"}
                        params={{ programId }}
                        warningMsg={resources.LoadDefaultsWarning}
                        isVisible={isPageEditable}
                        onNewData={(newData) => {
                            for (const row of newData) {
                                const key = Math.random()
                                    .toString(36)
                                    .substring(2, 15);
                                row.Id = `NEW-${key}`;
                            }
                            setValue(props.name, newData, {
                                shouldDirty: true,
                            });
                            updateRenderHash();
                        }}
                    />

                    {isFrac && isStandardChemSchedule && (
                        <>
                            <span
                                onClick={props.onEditNotes}
                                className={
                                    "cursor-pointer uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                                }
                            >
                                {resources.EditNotes}
                            </span>
                            <span
                                onClick={() => setShowNotesView((c) => !c)}
                                className={
                                    "cursor-pointer uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                                }
                            >
                                {resources.ToggleNotesView}
                            </span>
                        </>
                    )}

                    <span>
                        <StandardCheckBox
                            sidelabel={resources.DisplayNotInUse}
                            checked={displayNotInUse}
                            onChange={(v) => {
                                setDisplayNotInUse(v);
                                updateRenderHash();
                            }}
                        />
                    </span>
                </div>
            </>
        );
    };

    const toolbarBody = () => {
        // We only want to show the notes components and functionality for Fracturing service line with Standard chemical schedule
        if (isFrac && isStandardChemSchedule) {
            let noteSectionItems: string[] = [];
            if (
                chemicalLoadingNotes &&
                Array.isArray(chemicalLoadingNotes) &&
                chemicalLoadingNotes.length > 0
            ) {
                noteSectionItems = chemicalLoadingNotes
                    ?.filter((note: IEditChemicalLoadingNoteViewModel) => {
                        return note.DisplayOnOutput;
                    })
                    ?.map((note: IEditChemicalLoadingNoteViewModel) => {
                        return note?.Note;
                    });
            }

            return (
                <NoteSection
                    isVisible={showNotesView}
                    notes={noteSectionItems}
                />
            );
        }

        return <></>;
    };

    const hiddenColumns = useMemo(() => {
        return {
            FluidSystemType: !isCtan,
            ProgramBaseFluids: isCtan,
        };
    }, [isCtan]);

    const numberOfRecordsInUse = useMemo(
        () =>
            data?.filter(
                (rec: IEditChemicalLoadingItemViewModel) =>
                    rec.IsUsedOnPumpSchedule,
            ).length,
        [data],
    );

    return (
        <BaseTable
            columns={defaultColumns}
            name={props.name}
            renderHash={renderHash}
            toolbarExtras={toolbarExtras}
            toolbarBody={toolbarBody}
            columnVisibility={hiddenColumns}
            displayNotInUse={displayNotInUse}
            defaultRecord={{
                FluidSystemType: {
                    Id: 1,
                    Name: resources.Primary,
                },
                IsUsedOnPumpSchedule: true,
                Concentration: 0,
            }}
            emptyBody={
                (numberOfRecordsInUse === 0 && !displayNotInUse) ||
                (data?.length === 0 && displayNotInUse)
            }
            noRowsMessage={
                data?.length > 0 &&
                numberOfRecordsInUse === 0 &&
                !displayNotInUse
                    ? resources.NoRecordsInUse
                    : undefined
            }
            meta={{
                disabled: !isPageEditable,
                canDelete: isPageEditable,
                urls: {
                    FluidSystemType: `/api/ChemicalLoading/FluidSystemTypes?serviceLineId=${program?.ServiceLineId}`,
                    ProgramFluidSystem: `/api/PremixedChemical/ProgramFluidSystem?programId=${programId}`,
                    StageDescription: `/api/ChemicalLoading/StageDescription?serviceLineId=${program?.ServiceLineId}`,
                    ChemicalView: `/api/PremixedChemical/Chemical?countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                },
            }}
            afterUpdateData={(
                columnId: string,
                dataItem: RecordType,
                value: RecordType | ItemType,
                rowIndex?: number,
            ) => {
                // Fluid System was updated
                if (columnId === "ProgramFluidSystem") {
                    setValue(`${props.name}.${rowIndex}.ApplyToFluid`, null);
                    updateRenderHash();
                }
            }}
            afterAddRecord={() => {
                updateRenderHash();
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(ChemicalLoadingTable);

function isApiVersionGreater(UIVersion: string, APIVersion: string): boolean {
    // Remove the 'v' prefix and split the version strings into parts
    const uiParts = UIVersion.substring(1).split(".");
    const apiParts = APIVersion.substring(1).split(".");

    // Compare each part of the version strings
    for (let i = 0; i < Math.max(uiParts.length, apiParts.length); i++) {
        // Convert each part to a number for comparison
        const uiPart = i < uiParts.length ? parseInt(uiParts[i], 10) : 0;
        const apiPart = i < apiParts.length ? parseInt(apiParts[i], 10) : 0;

        // Compare the current parts
        if (apiPart > uiPart) {
            return true;
        } else if (apiPart < uiPart) {
            return false;
        }
        // If the parts are equal, continue to the next part
    }

    // If all parts are equal, the API version is not greater
    return false;
}

export default isApiVersionGreater;

//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FilterViewId {
	TaskIndexFilterView = 1,
	RequestIndexFilterView = 2,
	ProgramIndexFilterView = 3,
	JobIndexFilterView = 4
}

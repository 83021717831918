// This method takes a number and truncates it to the specified number of decimal places.
const formatInputNumber = (
    value: string | number,
    editDecimals: number,
): string => {
    let containsMinus = String(value).indexOf("-") > -1;
    let isNegative = String(value).split("-").length % 2 === 0;

    value = String(value).replace(/[^\d.]+/g, "");

    // Move a "-" character to the front of the string and remove the rest, if one exists.
    if (containsMinus && isNegative) {
        value = "-" + value;
    }

    // If the first character in value is a dot
    if (value?.[0] === ".") {
        value = "0" + value;
    }

    // Remove every . after the first one
    const dotSplit = value.split(".");
    const afterFirstDotRemovedDots = dotSplit[1]?.replace(".", "") ?? "";

    // only allow up until 5 decimals
    value =
        value.indexOf(".") > -1
            ? dotSplit[0] +
              "." +
              afterFirstDotRemovedDots.substring(0, editDecimals)
            : dotSplit[0];

    return value;
};

export default formatInputNumber;

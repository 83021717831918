import React, { ReactNode } from "react";

export const H2: React.FC<{ children: ReactNode; className?: string }> = ({
    children,
    className,
}) => {
    return (
        <h2
            className={`text-2xl font-bold leading-7 text-calfrac-gray  sm:truncate sm:text-3xl sm:tracking-tight ${className}`}
        >
            {children}
        </h2>
    );
};
export const H3: typeof H2 = ({ children, className }) => {
    return (
        <h3
            className={`text-base font-semibold leading-7  ${
                className ? className : "text-calfrac-gray "
            }`}
        >
            {children}
        </h3>
    );
};

import React, { useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { DataSourceRequestState } from "@progress/kendo-data-query/dist/npm/mvc/operators";
import {
    CompositeFilterDescriptor,
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";
import { CellContext } from "@tanstack/react-table";

import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import { TableMetaT } from "types/Tables/Cells";

import { toQueryString } from "components/kendoExtensions/grids/StandardIndexGrid";

import useDebounce from "hooks/useDebounce";

import { fetchJET, JetApiUrls } from "utils/fetchJet";

const GridDropDownProgramNumbers = ({
    row,
    column,
    getValue,
    table,
}: CellContext<any, any>) => {
    const initialValue = getValue();

    // Hooks
    const { countryId } = useParams();
    let serviceLine = useWatch({
        name: `${(table.options.meta as TableMetaT).name}.${
            row.index
        }.ServiceLine.Id`,
    });

    // State
    const [value, setValue] = useState<INamedItemViewModel>(initialValue);
    const [filter, setFilter] = useState<CompositeFilterDescriptor>();

    const state: DataSourceRequestState = {
        skip: 0,
        take: 100,
        filter,
    };

    const baseUrl = `/api/Request/GetProgramNumbersOnlyVirtual?countryId=${countryId}&serviceLineId=${serviceLine}`;
    const url: JetApiUrls = useDebounce(
        `${baseUrl}&` + toQueryString(state),
    ) as unknown as JetApiUrls;

    const { data, isLoading } = useQuery({
        queryKey: [url],
        queryFn: async () => {
            return await fetchJET<{
                Data: INamedItemViewModel[];
                Total: number;
            }>(url);
        },
    });

    // Handlers
    const onChange = useCallback(
        (e: DropDownListChangeEvent) => {
            setValue(e.target.value);
            (table.options.meta as TableMetaT)?.updateData(
                row.index,
                column.id,
                e.target.value,
            );
        },
        [table.options.meta, row.index, column.id],
    );

    // Use Effects
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <DropDownList
            data={data?.Data && data?.Data?.length > 0 ? data?.Data : []}
            filterable={true}
            onFilterChange={(event) => {
                const isFilterEmpty = !(
                    typeof event.filter.value === "string" &&
                    event.filter.value.trim().length > 0
                );

                if (isFilterEmpty) {
                    setFilter(undefined);
                    return;
                }

                event.filter.value = event.filter.value.trim();
                let filters: CompositeFilterDescriptor = {
                    logic: "and",
                    filters: [event.filter],
                };
                setFilter(filters);
            }}
            onChange={onChange}
            value={value}
            disabled={row.original.editable === false}
            defaultItem={{}}
            dataItemKey={"Name"}
            textField={"Name"}
            loading={isLoading}
        />
    );
};

export default GridDropDownProgramNumbers;

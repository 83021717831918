import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
    filterBy,
    MultiSelect,
    MultiSelectChangeEvent,
    MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { handleEditableCellKeyDown } from "utils/keyDownHandlers";

import { LoaderWithLabel } from "./LoaderWithLabel";

interface Props<T, E> {
    id?: string;
    value?: T[];
    url: JetApiUrls | undefined;
    params?: Record<string, string> | string;
    queryKey?: string[];
    onChange?: (selectedItems: E[]) => void;
    getValue?: (value?: T[], data?: E[]) => E[] | undefined;
    setValue?: (value?: E[]) => T[] | undefined;
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    // Used with react hook form to register element
    name?: string;
    hasObjects?: boolean;
    byField?: "Id" | "Name";
    className?: string;
    required?: boolean;
}

const StandardMultipleSelect = ({
    name = " ",
    hasObjects = false,
    byField = "Id",
    className,
    ...props
}: Props<INamedItemViewModel, INamedItemViewModel>) => {
    const value = useWatch({ name });

    const { setValue, clearErrors } = useFormContext();
    const [filteredData, setFilteredData] = React.useState<
        INamedItemViewModel[]
    >([]);

    const query = useQuery<INamedItemViewModel[]>({
        queryKey: props.queryKey ?? [props.url, props.id],
        queryFn: () => {
            return fetchJET(props.url, props.params);
        },
    });

    const currentValue = useMemo(() => {
        if (!byField) {
            return value;
        } else if (hasObjects) {
            return query?.data?.filter(
                (row) =>
                    value?.filter(
                        (obj: INamedItemViewModel) =>
                            obj?.[byField] === row?.[byField],
                    ).length > 0,
            );
        }
        return query?.data?.filter((row) => value?.includes?.(row?.[byField]));
    }, [byField, value, query.data, hasObjects]);

    const handleChange = useCallback(
        (e: MultiSelectChangeEvent) => {
            let value = e.target.value;

            // Apply an optional pre-filter
            if (byField && !hasObjects) {
                setValue(
                    name,
                    value.map((row) => row[byField]),
                    { shouldDirty: true },
                );
            } else {
                setValue(name, value, { shouldDirty: true });
            }
            clearErrors(name);
        },
        [byField, clearErrors, name, setValue, hasObjects],
    );

    if (query.isLoading) return <LoaderWithLabel label={props.label} />;

    const filterData = (event: MultiSelectFilterChangeEvent) => {
        if (query.data === undefined) return;
        setFilteredData(filterBy(query.data, [event.filter], "contains"));
    };

    return (
        <MultiSelectContainer
            className={className}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleEditableCellKeyDown(e, "input", clearErrors)
            }
        >
            {props.label && (
                <StandardLabel
                    labelFor={props.id}
                    label={props.label}
                    required={props.required}
                />
            )}
            <MultiSelect
                id={props.id}
                value={currentValue}
                filterable={true}
                onFilterChange={filterData}
                data={filteredData?.length > 0 ? filteredData : query.data}
                onChange={handleChange}
                textField={"Name"}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onOpen={async () => {
                    if (query.data === undefined) await query.refetch();
                }}
            />
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </MultiSelectContainer>
    );
};

const MultiSelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export default StandardMultipleSelect;

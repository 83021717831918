import React from "react";
import { Label } from "@progress/kendo-react-all";

export const LoadingOverlay: React.FC<{
    className?: string;
    message?: string;
    children?: React.ReactNode;
}> = ({ className, message, children }) => (
    <div
        className={`left-1/2 top-1/2 z-50 flex h-full w-full translate-x-[-50%] translate-y-[-50%] transform cursor-wait items-center justify-center bg-white/50 bg-loading bg-center bg-no-repeat  ${
            className ? className : "absolute"
        }`}
    >
        {message && (
            <Label className="pb-44 text-calfrac-green">{message}</Label>
        )}
        {children}
    </div>
);

import React from "react";
import * as ReactHookForm from "react-hook-form";
import { useForm } from "react-hook-form";

import JSONFormMonitor from "utils/JSONFormMonitor";

type FormInfo = {
    formId: string;
    setFormId: React.Dispatch<React.SetStateAction<string>>;
};
export const FormInfoContext = React.createContext<FormInfo>({
    formId: "",
    setFormId: () => void 0,
});

// Provider for formId information
export const FormProvider: React.FC<{
    children: React.ReactNode;
    currentValues?: any;
}> = ({ children, currentValues }) => {
    const methods = useForm({ values: currentValues });
    const [formId, setFormId] = React.useState<string>("");

    return (
        <FormInfoContext.Provider
            value={{
                formId,
                setFormId,
            }}
        >
            <ReactHookForm.FormProvider {...methods}>
                {children}
                <JSONFormMonitor />
            </ReactHookForm.FormProvider>
        </FormInfoContext.Provider>
    );
};

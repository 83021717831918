import React, {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
} from "react";
import { useFormContext } from "react-hook-form";
import { CalculatorIcon } from "@heroicons/react/24/outline";
import { Table } from "@tanstack/react-table";

import { FormulaCalculateScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/FormulaCalculateScheduleStageTarget";

import { isProgramCtan } from "components/Layout/CtanView";

import { useProgramParams } from "hooks/useProgramParams";

import { clearSelection } from "utils/copyDown";
import { resources } from "utils/resources";

import FormulaCalculateColumnInfo from "./FormulaCalculateColumnInfo";

export type FormulaCalculateButtonProps = {
    colId: string;
    table: Table<any>;
    tableRef?: React.RefObject<HTMLTableElement>;
    selected: MutableRefObject<Map<number, Array<string>>>;
    updateRenderHash: () => void;
    onFormulaCalculate?: (
        colId: string,
        rowIdx: number,
        target: FormulaCalculateScheduleStageTarget,
        callback: () => void,
    ) => void;
};

const FormulaCalculateButton: React.FC<FormulaCalculateButtonProps> = ({
    table,
    tableRef,
    selected,
    updateRenderHash,
    ...props
}: FormulaCalculateButtonProps) => {
    const { formState } = useFormContext();
    const { program } = useProgramParams();

    const isCtan = isProgramCtan(program);

    const isVisible = useMemo(
        () => FormulaCalculateColumnInfo[props.colId] !== undefined && isCtan,
        [isCtan, props.colId],
    );

    const validate = useCallback(
        (colId: string): null | number => {
            if (formState.isDirty) {
                alert(
                    resources.PleaseSaveYourCurrentChangesPriorToRunningFormulaCalculations,
                );
                clearSelection(selected);
                return null;
            }
            if (selected.current.size > 1) {
                alert(resources.PleaseSelectASingleCell);
                clearSelection(selected);
                return null;
            }

            let rowIdx;

            // Get the first RowID in the selected Map which has a value for the columnId
            for (let [key, value] of selected.current) {
                if (value.includes(colId)) {
                    rowIdx = key;
                    break;
                }
            }

            if (rowIdx === undefined) {
                const col = table.getColumn(colId);
                let columnName = col?.columnDef.header ?? colId;

                alert(
                    resources.BackCalculateInvalidCell2.replace(
                        "0",
                        `"${String(columnName)}"`,
                    ),
                );
                clearSelection(selected);
                return null;
            }
            return rowIdx;
        },
        [formState.isDirty, selected, table],
    );

    const onClick = useCallback(() => {
        const rowIdx = validate(props.colId);
        if (rowIdx !== null) {
            props.onFormulaCalculate?.(
                props.colId,
                rowIdx,
                FormulaCalculateColumnInfo[props.colId],
                updateRenderHash,
            );
            clearSelection(selected); // clear the selection now
        }
    }, [props, selected, updateRenderHash, validate]);

    // Adding key event listener to open the formula popup when "=" is pressed
    useEffect(() => {
        const ref = tableRef?.current;
        const onKeyDown = (event: KeyboardEvent): any => {
            // Key is used for formula calculation
            const activeElement = document.activeElement;

            // Find first selected cell
            const selectedEntries = selected.current;
            if (selectedEntries.size === 0 || selectedEntries.size > 1) return; // Nothing is selected

            const col = selectedEntries.entries().next().value[1][0];

            if (
                FormulaCalculateColumnInfo[col] !== undefined &&
                event.key === "=" &&
                (activeElement instanceof HTMLInputElement ||
                    activeElement instanceof HTMLSpanElement)
            ) {
                const rowIdx = validate(col);

                if (rowIdx !== null) {
                    props.onFormulaCalculate?.(
                        col,
                        rowIdx,
                        FormulaCalculateColumnInfo[col],
                        updateRenderHash,
                    );
                    clearSelection(selected); // clear the selection now
                }
            }
        };
        ref?.addEventListener("keydown", onKeyDown);
        return () => ref?.removeEventListener("keydown", onKeyDown);
    }, [props, selected, tableRef, updateRenderHash, validate]);

    if (!isVisible) return <></>;

    return (
        <div
            className={
                "mt-auto cursor-pointer text-calfrac-green-600 hover:text-calfrac-green-700"
            }
            id={"selecto-ignore"}
            onClick={onClick}
        >
            <CalculatorIcon
                id={"selecto-ignore"}
                className={"pointer-events-none h-6 w-6"}
            />
        </div>
    );
};

export default FormulaCalculateButton;

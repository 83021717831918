import React from "react";

import StandardComboBox from "components/kendoExtensions/standardExtensions/StandardComboBox";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import { FilterManager } from "components/shared/Filter/FilterManager";

import { resources } from "utils/resources";

type Props = {
    onClose: () => void;
};

const EditTaskFilter: React.FC<Props> = ({ onClose }: Props) => {
    return (
        <FilterManager onClose={onClose} name={"Task"}>
            <StandardTextBox
                name={"NextFilter.FilterName"}
                label={resources.FilterName}
                className={"col-span-full"}
            />
            <StandardDropDownList
                url={"/api/Task/CountryCode"}
                name={"NextFilter.CountryId"}
                byField={"Id"}
                label={resources.Country}
            />
            <StandardDropDownList
                url={"/api/Task/TaskStatus"}
                name={"NextFilter.TaskStatusId"}
                byField={"Id"}
                label={resources.TaskStatus}
            />
            <StandardMultipleSelect
                name={"NextFilter.ServiceLine"}
                url={"/api/Program/ServiceLine"}
                byField={"Id"}
                label={resources.ServiceLine}
            />
            <StandardMultipleSelect
                name={"NextFilter.TaskName"}
                url={"/api/Task/GetPossibleTaskNames"}
                byField={"Name"}
                label={resources.Task}
            />
            <StandardMultipleSelect
                name={"NextFilter.AccountManager"}
                url={"/api/Program/AccountManager"}
                byField={"Id"}
                label={resources.AccountManager}
            />
            <StandardMultipleSelect
                name={"NextFilter.CustomerCompany"}
                url={"/api/Filter/AllAccounts"}
                byField={"Id"}
                label={resources.Operator}
            />
            <StandardMultipleSelect
                name={"NextFilter.AssignedTo"}
                url={"/api/Task/GetPossibleTaskAssignees"}
                byField={"Name"}
                label={resources.AssignedTo}
            />
            <StandardComboBox
                url={"/api/Request/ProjectDescriptions"}
                name={"NextFilter.ProjectDescription"}
                label={resources.PadName}
            />
        </FilterManager>
    );
};

export default EditTaskFilter;

import React from "react";
import {
    createBrowserRouter,
    RouteObject,
    RouterProvider,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

import AppProviders from "./AppRoutes/AppProviders";
import AuthProviders from "./AppRoutes/AuthProviders";
import Layout from "./components/Layout";
import AppRoutes from "./AppRoutes";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

const routes: RouteObject[] = [
    {
        element: (
            <AppProviders>
                <Layout />
                {/*<HelpButton />*/}
            </AppProviders>
        ),
        children: [...(AppRoutes as RouteObject[])],
    },
];
const App: React.FC = () => {
    const router = createBrowserRouter(routes, {
        basename: baseUrl ?? undefined,
    });

    return (
        <>
            <Toast position={"top-right"} />
            <AuthProviders>
                <RouterProvider router={router} />
            </AuthProviders>
        </>
    );
};

const Toast = styled(ToastContainer)`
    margin-top: 50px;
    & > div {
        opacity: 95%;
    }
`;

export default App;

import React, { ReactNode } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

const ButtonClasses =
    "h-10 max-w-min whitespace-nowrap rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 flex flex-row gap-2 items-center";

const PrimaryButtonClasses = `${ButtonClasses} bg-calfrac-green text-white hover:bg-calfrac-green-700 focus-visible:outline-calfrac-green-500`;
export const SecondaryButtonClasses = `${ButtonClasses} text-calfrac-gray  shadow-sm ring-1 ring-inset ring-calfrac-gray-300 hover:bg-calfrac-gray-50  `;
const WarningButtonClasses = `${ButtonClasses} text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50`;
export const SecondaryButton: React.FC<
    {
        children: ReactNode;
        cursor?: string;
    } & React.HTMLProps<HTMLButtonElement>
> = ({ children, cursor, ...buttonProps }) => {
    return (
        <SecBtn cursor={cursor}>
            <button
                {...buttonProps}
                type="button"
                className={SecondaryButtonClasses}
            >
                {children}
            </button>
        </SecBtn>
    );
};

const SecBtn = styled.div<{ cursor?: string }>`
    &:hover {
        cursor: ${(props) => props.cursor ?? "default"};
    }
`;

export const PrimaryButton: React.FC<
    {
        children: ReactNode;
        type?: "button" | "submit" | "reset";
    } & React.HTMLProps<HTMLButtonElement>
> = ({ children, type = "button", ...buttonProps }) => {
    if (buttonProps.disabled) {
        return (
            <label
                className={
                    "border-3 rounded-lg border-dotted border-gray-400 p-2 text-gray-400"
                }
            >
                {children}
            </label>
        );
    }
    return (
        <button
            type={type}
            {...buttonProps}
            className={buttonProps.className + " " + PrimaryButtonClasses}
            disabled={buttonProps.readOnly}
        >
            {children}
        </button>
    );
};

export const WarningButton: React.FC<
    {
        children: ReactNode;
        type?: "button" | "submit" | "reset";
    } & React.HTMLProps<HTMLButtonElement>
> = ({ children, ...buttonProps }) => {
    return (
        <button {...buttonProps} className={WarningButtonClasses}>
            {children}
        </button>
    );
};

export const PrimaryButtonLink: React.FC<{
    children: ReactNode;
    to: string;
}> = ({ children, to }) => {
    return (
        <Link to={to} className={PrimaryButtonClasses}>
            {children}
        </Link>
    );
};
export const PrimaryButtonNavLink: React.FC<
    {
        children: ReactNode;
        to: string;
    } & React.HTMLProps<HTMLButtonElement>
> = ({ children, to, ...buttonProps }) => {
    return (
        <NavLink
            to={to}
            className={buttonProps.className + " " + PrimaryButtonClasses}
        >
            {children}
        </NavLink>
    );
};

export const WarningButtonNavLink: React.FC<
    {
        children: ReactNode;
        to: string;
    } & React.HTMLProps<HTMLButtonElement>
> = ({ children, to, ...buttonProps }) => {
    return (
        <NavLink
            to={to}
            className={buttonProps.className + " " + WarningButtonClasses}
        >
            {children}
        </NavLink>
    );
};

//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum RequestProgramTypeId {
	New = 1,
	Revision = 2,
	PriceRevision = 3,
	PumpScheduleTool = 4
}

import React from "react";
import { DocumentIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { DocumentCheckIcon } from "@heroicons/react/24/solid";
import { CellContext } from "@tanstack/react-table";

import { downloadJET } from "utils/fetchJet";
import { resources } from "utils/resources";

const PDFCell = ({
    cell,
    value,
}: CellContext<any, any> & { value?: string }) => {
    const dataItem = cell.row.original;

    const isDraft = dataItem.ClientOutputsAction === resources.ViewDraftPdf;
    const isFinal = dataItem.ClientOutputsAction === resources.ViewFinalPdf;
    const blobUrl = dataItem.BlobUrl;

    // Ref
    if (!blobUrl) return <div></div>;

    return (
        <div className={"read-only"}>
            <button
                title={dataItem.ClientOutputsAction}
                aria-label={"Download File"}
                className="mx-auto flex w-full"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    downloadJET("/api/AzureBlob/DownloadFile", {
                        path: blobUrl,
                    });
                }}
            >
                {isDraft && (
                    <>
                        <PencilSquareIcon
                            className={"h-5 w-5 stroke-calfrac-green"}
                        />
                        {value && <>{value}</>}
                    </>
                )}
                {isFinal && (
                    <>
                        <DocumentCheckIcon
                            className={"h-5 w-5 fill-calfrac-green"}
                        />
                        {value && <>{value}</>}
                    </>
                )}
                {!isDraft && !isFinal && (
                    <>
                        <DocumentIcon
                            className={"h-5 w-5 stroke-calfrac-green"}
                        />
                        {value && <>{value}</>}
                    </>
                )}
            </button>
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(PDFCell);

import { SelectoProps } from "react-selecto";

import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

export const SelectoDefaults: Partial<SelectoProps> = {
    selectableTargets: [".selecto-cell"],
    hitRate: 0,
    selectFromInside: true,
    continueSelect: false,
    selectByClick: false,
    continueSelectWithoutDeselect: false,
    toggleContinueSelect: "shift",
    toggleContinueSelectWithoutDeselect: [["ctrl"], ["meta"]],
    ratio: 0,
};

export function createCopyDownKeys(
    name: string,
    selection: Map<number, Array<string>>,
    data: Array<Array<INamedItemViewModel & Record<string, any>>>,
): Record<string, any> {
    const result: Record<string, any> = {};
    const firstValues: { [key: string]: string } = {};

    const map = new Map([...selection].sort((a, b) => a[0] - b[0]));

    // loop through all the rows
    for (let [rowId, columns] of map.entries()) {
        // and loop through all the columns
        for (let column of columns) {
            // if the column is not in the firstValues object, add it
            if (firstValues[column] !== undefined) {
                result[`${name}.${rowId}.${column}`] = firstValues[column];
                continue;
            }
            firstValues[column] =
                // @ts-ignore
                data[rowId]?.[column];

            result[`${name}.${rowId}.${column}`] = firstValues[column];
        }
    }
    return result;
}

const addSelection = (
    el: HTMLElement | SVGElement,
    selected: React.MutableRefObject<Map<number, Array<string>>>,
    singleSelectColumns?: string[],
) => {
    // Retrieve row and column IDs from the element
    const rowId = el.getAttribute("data-row-id");
    const colId = el.getAttribute("data-col-id");

    // Ignore if either rowId or colId is missing, or if it's a single select column
    if (!rowId || !colId || singleSelectColumns?.includes(colId)) return;

    // Add selected class for styling
    el.classList.add("bg-calfrac-green-selected");

    // Update the map with the new selection
    // If the row already exists in the map, append the new column
    // Otherwise, create a new entry with the column ID
    const parsedRowId = parseInt(rowId);
    const currentRow = selected.current.get(parsedRowId) ?? [];
    selected.current.set(parsedRowId, currentRow.concat(colId));
};

export const onSelectIntoMap =
    (
        selected: React.MutableRefObject<Map<number, Array<string>>>,
        singleSelectColumns?: string[],
    ): SelectoProps["onSelect"] =>
    (e) => {
        // If the inputEvent is on an element with #selecto-ignore, return
        if (e.inputEvent.target.id === "selecto-ignore") return;

        (document?.activeElement as HTMLElement)?.blur();

        if (
            e.added.length === 1 &&
            singleSelectColumns?.includes(
                e.added[0].getAttribute("data-col-id") ?? "",
            )
        ) {
            const el = e.added[0];
            selected.current.clear();
            // find all elements with the class "bg-calfrac-green-selected" and remove that class
            document
                .querySelectorAll(".bg-calfrac-green-selected")
                .forEach((el) =>
                    el.classList.remove("bg-calfrac-green-selected"),
                );

            addSelection(el, selected);
            return;
        }

        // Process added selections
        e.added.forEach((el) => {
            addSelection(el, selected, singleSelectColumns);
        });

        // Process removed selections
        e.removed.forEach((el) => {
            // Remove selected class for styling
            el.classList.remove("bg-calfrac-green-selected");

            // Retrieve row and column IDs from the element
            const rowId = el.getAttribute("data-row-id");
            const colId = el.getAttribute("data-col-id");

            // Ignore if either rowId or colId is missing
            if (!rowId || !colId) return;

            const parsedRowId = parseInt(rowId);

            // Ignore if the rowId is not in the map
            if (!selected.current.has(parsedRowId)) return;

            // Remove the rowId if this is the only column selected
            const currentRow = selected.current.get(parsedRowId);
            if (currentRow?.length === 1) {
                selected.current.delete(parsedRowId);
                return;
            }

            // Otherwise, remove the specific column from the rowId
            const updatedRow = currentRow?.filter((e) => e !== colId) ?? [];
            selected.current.set(parsedRowId, updatedRow);
        });
    };

export const clearSelection = (
    selected: React.MutableRefObject<Map<number, Array<string>>>,
) => {
    selected.current.clear();
    // find all elements with the class "bg-calfrac-green-selected" and remove that class
    document
        .querySelectorAll(".bg-calfrac-green-selected")
        .forEach((el) => el.classList.remove("bg-calfrac-green-selected"));
};

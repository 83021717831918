import React, { ReactNode, useEffect } from "react";
import { InteractionType } from "@azure/msal-browser";
import {
    AuthenticatedTemplate,
    MsalProvider,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from "@azure/msal-react";
import process from "process";

import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import { msalInstance } from "utils/auth";
import { resources } from "utils/resources";

import GeneralErrorPage from "views/Error/GeneralErrorPage";

/**
 * This component will redirect the user to Azure AD to login if they are not authenticated.
 * If they are authenticated, it will render the children.
 *
 * You may access the user's authentication state with the useIsAuthenticated and useMsal hooks.
 * @param children
 * @constructor
 */
const AuthenticationRedirect: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const { result, error } = useMsalAuthentication(InteractionType.Redirect, {
        account: msalInstance.getActiveAccount() ?? undefined,
        scopes: [process.env.REACT_APP_AZURE_SCOPE ?? ""],
    });

    const [validUserRole, setValidUserRole] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    useEffect(() => {
        if (!result?.account) {
            setValidUserRole(false);
            return;
        }
        msalInstance.setActiveAccount(result?.account ?? null);

        // check the the 'roles' contains JET.USER
        const roles = result?.account?.idTokenClaims?.roles;
        setValidUserRole(
            Boolean(
                roles?.includes("JET.USER") || roles?.includes("JET.ADMIN"),
            ),
        );
    }, [result?.account]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }, []);

    return (
        <>
            <UnauthenticatedTemplate>
                <LoadingOverlay />

                {error && (
                    <GeneralErrorPage
                        code={401}
                        title={resources.UnauthorizedError}
                        subtitle={resources.UnauthorizedErrorMessage}
                    />
                )}
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {validUserRole && children}
                {!validUserRole && loading && <LoadingOverlay />}
                {!validUserRole && !loading && (
                    <GeneralErrorPage
                        code={403}
                        title={resources.InvalidRoleError}
                        subtitle={resources.InvalidRoleErrorMessage}
                    />
                )}
            </AuthenticatedTemplate>
        </>
    );
};

const AuthProviders: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const bypassToken = process.env.REACT_APP_E2E_TOKEN_ENABLED === "true";
    return bypassToken ? (
        <>{children}</>
    ) : (
        <MsalProvider instance={msalInstance}>
            <AuthenticationRedirect>{children}</AuthenticationRedirect>
        </MsalProvider>
    );
};

export default AuthProviders;

import React, { memo, useEffect, useMemo, useReducer, useState } from "react";
import { useFormContext } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditProgramFormationViewModel } from "types/generated/Calfrac/Jet/Web/Models/ReservoirProperties/IEditProgramFormationViewModel";

import CheckBox from "components/Cells/CheckBox";
import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import NumericCellNullable from "components/Cells/NumericCellNullable";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import NumericReadOnlyNullable from "components/Cells/NumericReadOnlyNullable";
import { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type ReservoirTableProps = {
    name: string;
    renderHash?: string;
};

const columnHelper = createColumnHelper<IEditProgramFormationViewModel>();

const ReservoirTable: React.FC<ReservoirTableProps> = (props) => {
    const { isPageEditable, program } = useProgram();
    const { setValue } = useFormContext();
    const units = useUnits();
    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);

    const defaultColumns = useMemo(() => {
        const SqueezeGradientColumn = columnHelper.accessor(
            (row) => row.SqueezeGradient,
            {
                id: "SqueezeGradient",
                header: `${resources.SqueezeGradient} ${units.squeezeGradientUnit}`,
                cell: NumericCellNullable,
            },
        );

        const CO2Column = columnHelper.accessor((row) => row.CO2, {
            id: "CO2",
            size: 70,
            header: `${resources.CO2} ${units.percentageUnit}`,
            cell: NumericCellNullable,
        });

        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Formation, {
                id: "Formation",
                header: resources.Formation,
                size: 140,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.DefinedTarget, {
                id: "DefinedTarget",
                size: 100,
                header: resources.DefinedTarget,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.TrueVerticalDepth, {
                id: "TrueVerticalDepth",
                size: 80,
                header: `${resources.TVD} ${units.depthUnit}`,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.PoreGradient, {
                id: "PoreGradient",
                size: 80,
                header: `${resources.PoreGradient} ${units.fractureGradientUnit}`,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.FractureGradient, {
                id: "FractureGradient",
                header: `${resources.FracGradient} ${units.fractureGradientUnit}`,
                cell: NumericCellNullable,
            }),
            ...(isCtan ? [SqueezeGradientColumn] : []),
            columnHelper.accessor((row) => row.BottomholeTemperature, {
                id: "BottomholeTemperature",
                header: `${resources.BHTemp} ${units.tempUnit}`,
                cell: NumericCellNullable,
            }),
            columnHelper.group({
                header: resources.BHTempCalculator.toUpperCase(),
                id: "BHTempCalculator",
                columns: [
                    columnHelper.accessor(
                        (row) => row.BottomholeTemperatureDepth,
                        {
                            id: "BottomholeTemperatureDepth",
                            header: `${resources.Depth} ${units.depthUnit}`,
                            size: 70,
                            cell: (cell) => {
                                if (
                                    cell.row.original
                                        .UseTrueVerticalDepthAsBottomholeTemperatureDepth
                                ) {
                                    setValue(
                                        `Formations.[${cell.row.index}].BottomholeTemperatureDepth`,
                                        cell.row?.original?.TrueVerticalDepth,
                                        { shouldDirty: false },
                                    );
                                    return (
                                        <NumericReadOnlyNullable
                                            {...cell}
                                            getValue={() =>
                                                cell.row?.original
                                                    ?.TrueVerticalDepth as unknown as any
                                            }
                                        />
                                    );
                                }
                                return <NumericCellNullable {...cell} />;
                            },
                        },
                    ),
                    columnHelper.accessor(
                        (row) =>
                            row.UseTrueVerticalDepthAsBottomholeTemperatureDepth,
                        {
                            id: "UseTrueVerticalDepthAsBottomholeTemperatureDepth",
                            size: 70,
                            header: resources.UseTVD,
                            cell: CheckBox,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.BottomholeTemperatureEstimated,
                        {
                            id: "BottomholeTemperatureEstimated",
                            header: `${resources.BHTempEst} ${units.tempUnit}`,
                            size: 100,
                            cell: (cell) => {
                                const formation = cell.row.original;
                                const bottomHoleTemperatureDepth =
                                    formation?.UseTrueVerticalDepthAsBottomholeTemperatureDepth
                                        ? formation?.TrueVerticalDepth
                                        : formation.BottomholeTemperature;

                                const surfaceTemperature =
                                    formation?.SurfaceTemperature;
                                const temperatureGradient =
                                    formation?.TemperatureGradient;
                                const bottomHoleTemperatureEstimated =
                                    bottomHoleTemperatureDepth != null
                                        ? surfaceTemperature +
                                          temperatureGradient *
                                              bottomHoleTemperatureDepth
                                        : null;

                                if (
                                    bottomHoleTemperatureEstimated != null &&
                                    !isNaN(bottomHoleTemperatureEstimated)
                                ) {
                                    setValue(
                                        `Formations.[${cell.row.index}].BottomholeTemperatureEstimated`,
                                        bottomHoleTemperatureEstimated,
                                        { shouldDirty: false },
                                    );
                                    return (
                                        <NumericReadOnlyNullable
                                            {...cell}
                                            getValue={() =>
                                                bottomHoleTemperatureEstimated as any
                                            }
                                        />
                                    );
                                }
                                return <NumericReadOnlyNullable {...cell} />;
                            },
                        },
                    ),
                ],
            }),
            columnHelper.accessor((row) => row.BottomholeReservoirPressure, {
                id: "BottomholeReservoirPressure",
                header: `${resources.BHReservoirPressure} ${units.pressureLargeUnit}`,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.H2SPpm, {
                id: "H2SPpm",
                size: 70,
                header: `${resources.H2S} ${units.partsPerMillionUnit}`,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.PorePressure, {
                id: "PorePressure",
                size: 80,
                header: `${resources.PorePressure} ${units.pressureSmallUnit}`,
                cell: isCement ? NumericReadOnly : NumericCellNullable,
            }),
            ...(isCtan ? [CO2Column] : []),
            columnHelper.accessor((row) => row.Permeability, {
                id: "Permeability",
                header: `${resources.Permeability} ${units.permeabilityUnit}`,
                size: 110,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.FracPressure, {
                id: "FracPressure",
                size: 80,
                header: `${resources.FracPressure} ${units.pressureLargeUnit}`,
                cell: isCement ? NumericReadOnly : NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.PorosityPercent, {
                id: "PorosityPercent",
                header: `${resources.Porosity} ${units.percentageUnit}`,
                size: 80,
                cell: NumericCellNullable,
            }),
            ...(isCtan ? [] : [SqueezeGradientColumn, CO2Column]),
        ];
    }, [
        units.squeezeGradientUnit,
        units.percentageUnit,
        units.depthUnit,
        units.fractureGradientUnit,
        units.tempUnit,
        units.pressureLargeUnit,
        units.partsPerMillionUnit,
        units.permeabilityUnit,
        units.pressureSmallUnit,
        isCtan,
        isCement,
        setValue,
    ]);

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const [readOnlyHidden, setReadOnlyHidden] = useState(false);

    useEffect(() => {
        updateRenderHash();
    }, [readOnlyHidden]);

    const toolbarExtras = () => {
        return (
            <span
                onClick={() => setReadOnlyHidden((c) => !c)}
                className={
                    "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                }
            >
                {resources.ToggleReadOnlyColumns}
            </span>
        );
    };

    const hiddenColumns = useMemo(() => {
        return {
            Delete: isPageEditable,
            BottomholeTemperatureEstimated: !readOnlyHidden,
            PoreGradient: isCement,
            PorePressure: isCement,
        };
    }, [isPageEditable, readOnlyHidden, isCement]);

    return (
        <BaseTable
            columns={defaultColumns}
            name={props.name}
            disableSelectColumns={["Delete", "BottomholeTemperatureEstimated"]}
            defaultRecord={{
                BottomholeReservoirPressure: null,
                BottomholeTemperature: null,
                BottomholeTemperatureDepth: null,
                DefinedTarget: undefined,
                Formation: undefined,
                FractureGradient: null,
                H2SPpm: null,
                Permeability: null,
                PorosityPercent: null,
                SurfaceTemperature: null,
                TemperatureGradient: null,
                TrueVerticalDepth: null,
                UseTrueVerticalDepthAsBottomholeTemperatureDepth: true,
            }}
            afterUpdateData={(columnId) => {
                if (
                    columnId ===
                    "UseTrueVerticalDepthAsBottomholeTemperatureDepth"
                ) {
                    updateRenderHash();
                }
            }}
            columnVisibility={hiddenColumns}
            toolbarExtras={toolbarExtras}
            renderHash={renderHash}
            meta={{
                name: "Formations",
                canDelete: isPageEditable,
                urls: {
                    Formation: `/api/ReservoirProperties/Formation?programFormationId={Id}&countryId=${program?.CountryId}`,
                    DefinedTarget: `/api/ReservoirProperties/DefinedTarget?programFormationId={Id}&countryId=${program?.CountryId}`,
                },
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(ReservoirTable);

import React, { useCallback, useEffect, useState } from "react";

import { CellType, TableMetaT } from "types/Tables/Cells";

import BaseInputCell from "./BaseInputCell";

const TextCell: CellType = ({
    getValue,
    row: { index },
    column: { id },
    table,
}) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState<string | null>(initialValue as string);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = useCallback(() => {
        (table.options.meta as TableMetaT)?.updateData(index, id, value);
    }, [value, table.options.meta, index, id]);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue as string);
    }, [initialValue]);

    return (
        <BaseInputCell
            table={table}
            onBlur={onBlur}
            rowIndex={index}
            columnId={id}
            value={value}
            setValue={(v) => setValue(v)}
            isNumeric={false}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(TextCell, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return prevValue === nextValue;
});

import React from "react";
import { useWatch } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { H3 } from "components/Headers";

const WarningSummary: React.FC = () => {
    const warnings = useWatch({ name: "Warnings" })?.filter(
        (warning: unknown) => typeof warning === "string",
    );

    if (!warnings || warnings?.length === 0) return <></>;

    return (
        <div className="mb-2 mt-3 rounded-lg border border-solid border-amber-200 bg-amber-200/30 p-3">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationCircleIcon
                        className="h-5 w-5 text-yellow-600"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <H3 className="text-d text-sm font-medium">
                        {`There are ${warnings?.length} soft warnings`}
                    </H3>
                    <div className="mt-2 text-sm font-medium">
                        <ul className="font-regular list-disc space-y-1 pl-5 text-sm text-black">
                            {warnings?.map((warning: string, idx: number) => (
                                <li key={idx}>{warning}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WarningSummary;

import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { ErrorOption, useFormContext } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { Dialog, RadioButtonChangeEvent } from "@progress/kendo-react-all";
import { RadioButton } from "@progress/kendo-react-inputs";
import { useMutation } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";

import { BackCalculatePumpScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/BackCalculatePumpScheduleStageTarget";
import { IBackCalculatePumpScheduleStageViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IBackCalculatePumpScheduleStageViewModel";

import { queryClient } from "AppRoutes/AppProviders";

import StandardNumericTextBox from "components/kendoExtensions/standardExtensions/StandardNumericTextBox";
import StandardButton from "components/shared/GenericCustomComponents/StandardButton";
import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";
import { ValidationSummary } from "components/shared/GenericCustomComponents/StandardValidationSummary";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import { Formats } from "utils/enumerations";
import { fetchJET, saveJET } from "utils/fetchJet";
import { fieldErrorToErrorObject } from "utils/helpers";
import { resources } from "utils/resources";

type BackCalculateProps = {
    onClose: () => void;
    pumpScheduleStageId: number;
    target: BackCalculatePumpScheduleStageTarget;
    field?: string;
};

const Page: React.FC<BackCalculateProps> = ({
    pumpScheduleStageId = 0,
    target = BackCalculatePumpScheduleStageTarget.DownholeConcentrationStart,
    field = "",
    ...props
}: BackCalculateProps) => {
    const { reset, getValues, formState } = useFormContext();
    const [model, setModel] = useState<
        IBackCalculatePumpScheduleStageViewModel | undefined
    >(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<
        { [key: string]: ErrorOption } | undefined
    >();
    const containerRef = useRef<HTMLDivElement | null>(null);

    // Queries
    useEffect(() => {
        setIsLoading(true);
        fetchJET<IBackCalculatePumpScheduleStageViewModel>(
            `/api/Schedule/BackCalculatePumpScheduleStage`,
            {
                pumpScheduleStageId: pumpScheduleStageId.toString(),
                target: field,
            },
        ).then((m) => {
            setModel({
                ...m,
                Target: target,
            });
        });
        setIsLoading(false);
    }, [field, pumpScheduleStageId, target]);

    // Listening to changes in the model. If the model changes we must reset the current errors.
    useEffect(() => {
        setErrors(undefined);
    }, [model]);

    // Used to handle enter key down
    useHotkeys("Enter", (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (model) {
            mutation.mutate();
        }
    });

    const mutation = useMutation({
        mutationFn: async () => {
            await saveJET<Record<string, any>>(
                "/api/Schedule/BackCalculatePumpScheduleStage",
                {},
                serialize(model, { indices: true }),
            ).then(async (res: any) => {
                // If there are server validation errors
                if (res.Errors) {
                    setErrors(fieldErrorToErrorObject(res.Errors));
                    return res;
                } else {
                    let jsonData = await res.json();
                    reset(jsonData);

                    // TODO: Tech debt. This shouldn't be necessary. We should be able to invalidate only the cache for the current page.
                    await queryClient.invalidateQueries({
                        predicate: (query) => {
                            return !(query.queryKey[0] as string).startsWith(
                                `/api/Schedule/BackCalculatePumpScheduleStage`,
                            );
                        },
                        type: "all",
                    });
                    props?.onClose();
                }
            });
        },
    });

    // When the component mounts attach event listener for "Enter" key
    useEffect(() => {
        // Handle the keydown event
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Enter" && model) {
                const numericInput: HTMLInputElement | undefined =
                    containerRef.current?.getElementsByTagName("input")?.[0];
                if (numericInput) {
                    numericInput.blur();
                }
                mutation.mutate();
            }
        };

        const numericInput: HTMLInputElement | undefined =
            containerRef.current?.getElementsByTagName("input")?.[0];
        const radioButtons: HTMLCollectionOf<Element> | [] =
            containerRef.current?.getElementsByClassName("k-radio") ?? [];

        // Add key listener for enter to numeric input
        if (numericInput) {
            numericInput.addEventListener("keydown", onKeyDown);
        }
        // Add key listener for radio buttons
        for (const radioButton of radioButtons) {
            (radioButton as HTMLInputElement)?.addEventListener(
                "keydown",
                onKeyDown,
            );
        }
        return () => {
            numericInput?.removeEventListener("keydown", onKeyDown);
            for (const radioButton of radioButtons) {
                (radioButton as HTMLInputElement)?.removeEventListener(
                    "keydown",
                    onKeyDown,
                );
            }
        };
    }, [mutation, containerRef, model]);

    const handleBackCalculateSubmit = useCallback(async () => {
        mutation.mutate();
    }, [mutation]);

    const handleChange = useCallback(
        (value: number | null) => {
            const numericInput: HTMLInputElement | undefined =
                containerRef.current?.getElementsByTagName("input")?.[0];
            if (numericInput) {
                numericInput.blur();
            }
            setModel({
                ...model,
                TargetValue: value,
            } as IBackCalculatePumpScheduleStageViewModel);
        },
        [model, setModel, containerRef],
    );

    const handleRadioChange = useCallback(
        (e: RadioButtonChangeEvent) => {
            setModel({
                ...model,
                FieldToBackCalculate: e.value,
            } as IBackCalculatePumpScheduleStageViewModel);
        },
        [model, setModel],
    );

    // Helpers

    const dialogHeight = useMemo(() => {
        if (model?.FieldsToBackCalculate && errors) {
            return (
                275 +
                (Object.keys(errors).length > 0 ? 100 : 0) +
                model?.FieldsToBackCalculate?.length * 22
            );
        }
        return model?.FieldsToBackCalculate &&
            model?.FieldsToBackCalculate?.length <= 0
            ? 100
            : 275;
    }, [errors, model?.FieldsToBackCalculate]);

    const ColumnName = useMemo(() => {
        switch (target) {
            case BackCalculatePumpScheduleStageTarget.DownholeConcentrationStart:
                return resources.DownholeConcentrationStart;
            case BackCalculatePumpScheduleStageTarget.DownholeConcentrationEnd:
                return resources.DownholeConcentrationEnd;
            case BackCalculatePumpScheduleStageTarget.ProppantStage:
                return resources.ProppantStage;
            case BackCalculatePumpScheduleStageTarget.ProppantStageCumulative:
                return resources.ProppantCumulative;
            case BackCalculatePumpScheduleStageTarget.N2Stage:
                return resources.N2Stage;
            case BackCalculatePumpScheduleStageTarget.CO2Stage:
                return resources.CO2Stage;
            case BackCalculatePumpScheduleStageTarget.SecondaryFluidSystemStage:
                return resources.SecondaryFluidSystemStage;
            case BackCalculatePumpScheduleStageTarget.DownholeRate:
                return resources.Rate;
            case BackCalculatePumpScheduleStageTarget.DownholeVolume:
                return resources.DownholeVolume;
            case BackCalculatePumpScheduleStageTarget.DownholeFoamQuality:
                return resources.DownholeFoamQuality;
            case BackCalculatePumpScheduleStageTarget.DownholeTotal:
                return resources.DownholeTotal;
        }
    }, [target]);

    // One-time, on-load, focus the numeric input when the modal dialog opens
    useEffect(() => {
        if (!isLoading && containerRef.current) {
            const inputs: HTMLCollectionOf<HTMLInputElement> | undefined =
                containerRef.current?.getElementsByTagName("input");
            if (inputs && inputs?.[0]) {
                const firstInput: HTMLInputElement | undefined = inputs?.[0];
                if (firstInput) {
                    firstInput.focus();
                }
            }
        }
    }, [containerRef, isLoading]);

    return (
        <Dialog
            title={`${resources.BackCalculate} ${ColumnName}`}
            onClose={() => {
                // Note: Dialog onClose will only be entered when the user closes the modal without saving, it will not be
                // called when the user submits (Save & Calculate)

                // If the user clicked the "X" button to close the dialog, you will have a formState.isDirty = true, but since
                // nothing has changed, and we don't save anything from the modal dialog form, we just want the same behavior as
                // if the user had hit Escape to close the dialog (if the user uses Escape to close the modal dialog,
                // formState.isDirty = false).
                if (formState.isDirty) {
                    // User closed the modal dialog by clicking the "X" button (top right of modal dialog)
                    const formValues = getValues();
                    const dirtyFieldKeys =
                        Object.keys(formState.dirtyFields) || [];
                    for (const dirtyFieldKey of dirtyFieldKeys) {
                        delete formValues[dirtyFieldKey];
                    }
                    reset({ ...formValues });
                } else {
                    // User closed the modal dialog by hitting Escape while dialog was focused
                    reset({}, { keepValues: true });
                }

                setModel(undefined);
                if (typeof props?.onClose === "function") {
                    props.onClose();
                }
            }}
            width={500}
            autoFocus={true}
            height={dialogHeight}
            themeColor={"primary"}
        >
            {!mutation.isPending &&
                !isLoading &&
                (model?.FieldsToBackCalculate?.length ?? 1) <= 0 && (
                    <>
                        {
                            resources.BasedOnTheCurrentConditionsBackCalculationIsNotSupportedForThisField
                        }
                    </>
                )}
            {!mutation.isPending &&
                !isLoading &&
                (model?.FieldsToBackCalculate?.length ?? 1) > 0 && (
                    <>
                        <ValidationSummary externalErrors={errors} />
                        <div
                            className={"flex flex-col gap-4"}
                            ref={containerRef}
                        >
                            <StandardNumericTextBox
                                format={Formats.GridTwoDecimal}
                                inEditFormat={Formats.TwoDecimal.toString()}
                                defaultValue={0.0}
                                placeholder={(0.0).toFixed(2)}
                                onChange={handleChange}
                                label={resources.TargetValue}
                            />
                            <div>
                                <StandardLabel
                                    label={resources.FieldToBackCalculate}
                                />
                                {model?.FieldsToBackCalculate?.map(
                                    (field, index) => {
                                        return (
                                            <Fragment key={`${index}${field}`}>
                                                <div className={"mb-2"}>
                                                    <RadioButton
                                                        label={field}
                                                        key={index + field}
                                                        value={field}
                                                        checked={
                                                            model.FieldToBackCalculate ===
                                                            field
                                                        }
                                                        onChange={
                                                            handleRadioChange
                                                        }
                                                        className="white border-2 border-calfrac-gray-100 checked:border-calfrac-green checked:bg-calfrac-green"
                                                    />
                                                    <br />
                                                </div>
                                            </Fragment>
                                        );
                                    },
                                )}
                            </div>
                            <StandardButton
                                onClick={handleBackCalculateSubmit}
                                text={resources.CalculateAndSave}
                                type={"submit"}
                            />
                        </div>
                    </>
                )}
            {(mutation.isPending || isLoading) && <LoadingOverlay />}
        </Dialog>
    );
};

export default Page;

import React, { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { queryClient } from "AppRoutes/AppProviders";

import { PrimaryButton, WarningButton } from "components/Buttons";
import { SingleErrorSummary } from "components/Errors/SingleErrorSummary";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import AdminView from "components/Layout/AdminView";
import CementView, { NotCementView } from "components/Layout/CementView";
import CtanView, { NotCtanView } from "components/Layout/CtanView";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import { HeaderPortal } from "components/Layout/HeaderPortal";
import { Spinner } from "components/LoadingIndicators/Spinner";
import SaveProgramButtons from "components/shared/GenericCustomComponents/SaveProgramButtons";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardTextArea from "components/shared/GenericCustomComponents/StandardTextArea";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";
import ClientOutputsTable from "components/Tables/ClientOutputs/ClientOutputsTable";
import AttachmentTable from "components/Tables/Task/AttachmentTable";

import useInterval from "hooks/useInterval";
import { useProgramParams } from "hooks/useProgramParams";

import { downloadJET, saveJET } from "utils/fetchJet";
import {
    initialPDFGenerationStatus,
    pollPDFGenerationStatus,
} from "utils/pdfGenerationStatus";
import { resources } from "utils/resources";

const Page: React.FC = () => {
    // Constants
    const url = "/api/ClientOutputs/EditClientOutputsCriteria";

    const { setValue } = useFormContext();

    const model = useWatch();

    // Url params
    const { programId, program, isPageEditable } = useProgramParams();

    const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
    const [pollingInterval, setPollingInterval] = useState<number | null>(null);
    const [pdfErrorMessage, setPDFErrorMessage] = useState<string | undefined>(
        undefined,
    );
    const [pdfBlobUrl, setPDFBlobUrl] = useState<string | null>(null);

    const invalidateRequestQuery = useCallback(() => {
        queryClient.invalidateQueries({
            predicate: (query) => {
                return (query.queryKey[0] as string)?.startsWith(
                    "/api/ClientOutputs/EditClientOutputsCriteria",
                );
            },
            type: "all",
        });
    }, []);

    // initial poll since states are wiped if user refreshed page or navigated away and came back to client outputs
    useEffect(() => {
        initialPDFGenerationStatus(
            programId,
            (isPDFStillGenerating: boolean) => {
                if (isPDFStillGenerating) {
                    setIsGeneratingPDF(true);
                    setPollingInterval(5000);
                }
            },
        );
    }, [programId]);

    // Polls API endpoint for pdf generation status every specific polling interval (milliseconds).
    // Set polling interval to null to stop polling.
    useInterval(() => {
        pollPDFGenerationStatus(
            programId,
            (
                isPDFStillGenerating: boolean,
                error: boolean,
                errorMsg: string,
                blobUrl: string | null,
            ) => {
                if (!isPDFStillGenerating) {
                    invalidateRequestQuery();
                    setIsGeneratingPDF(false);
                    setPDFBlobUrl(blobUrl);
                    setPollingInterval(null);
                    setPDFErrorMessage(
                        error
                            ? errorMsg === "Incorrect file header"
                                ? "One or more of the attachment files is not a .pdf file. Please ensure all attached files are .pdf files and try again"
                                : errorMsg
                            : undefined,
                    );
                }
            },
        );
    }, pollingInterval);

    const startPollingPDFGeneration = () => {
        setPDFErrorMessage(undefined);
        setIsGeneratingPDF(true);
        setPollingInterval(5000);
    };

    const resetPDFStatus = useCallback(() => {
        // post to api endpoint to reset pdf status
        saveJET("/api/ClientOutputs/ResetGenerating", {
            programId: programId ?? "",
        }).then(() => {
            setIsGeneratingPDF(false);
            setPollingInterval(null);
        });
    }, [programId]);

    return (
        <>
            {isGeneratingPDF && (
                <LoadingOverlay
                    className="fixed"
                    message={resources.GeneratingPDF}
                >
                    <AdminView>
                        <div className={"absolute top-[calc(50%+8rem)]"}>
                            <WarningButton onClick={resetPDFStatus}>
                                {"Admin Only: Reset PDF Status"}
                            </WarningButton>
                        </div>
                    </AdminView>
                </LoadingOverlay>
            )}
            <HeaderPortal>
                {isGeneratingPDF && (
                    <Spinner loadingMessage={resources.GeneratingPDF} />
                )}
                {(pdfBlobUrl !== null || model.BlobUrl) && !isGeneratingPDF && (
                    <PrimaryButton
                        onClick={() =>
                            downloadJET("/api/AzureBlob/DownloadFile", {
                                path:
                                    pdfBlobUrl !== null
                                        ? pdfBlobUrl
                                        : model?.BlobUrl,
                            })
                        }
                        type={"button"}
                    >
                        {resources.ViewPdf}
                    </PrimaryButton>
                )}
                <SaveProgramButtons
                    title={resources.SaveAndGeneratePDF}
                    disabledSaveButton={isGeneratingPDF}
                />
            </HeaderPortal>
            {pdfErrorMessage && (
                <SingleErrorSummary
                    title={resources.GeneratingPDFError}
                    errorMsg={pdfErrorMessage}
                />
            )}
            <StandardForm
                readUrl={url}
                readParams={{ programId }}
                saveUrl={url}
                beforeSaveHandler={startPollingPDFGeneration}
                ignoreLoadingOverlay={isGeneratingPDF}
            >
                <FormSectionDivider>
                    <FormSection label={resources.CoverTitlePage}>
                        <StandardTextBox
                            name={"DefaultTreatmentTitle"}
                            disabled={true}
                            label={resources.DefaultTreatmentTitle}
                            className={"col-span-full"}
                        />
                        <StandardTextBox
                            name={"TreatmentTitle"}
                            disabled={!isPageEditable}
                            label={resources.TreatmentTitle}
                            className={"col-span-full"}
                        />
                        <StandardMultipleSelect
                            name={"TreatmentObjectives"}
                            disabled={!isPageEditable}
                            url={`/api/Request/TreatmentObjectives?programTypeId=${program?.ServiceLineId}`}
                            label={resources.TreatmentObjective}
                            className={"col-span-full"}
                            hasObjects={true}
                        />
                        {model?.HasApiNumberAndWellLocation && (
                            <>
                                <StandardCheckBox
                                    sidelabel={resources.ApiNumber}
                                    name={"ShowApiNumber"}
                                    disabled={!isPageEditable}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.WellLocation}
                                    name={"ShowWellLocation"}
                                    disabled={!isPageEditable}
                                />
                            </>
                        )}
                        {program?.CountryId !== CountryId.Canada &&
                            program?.CountryId !== CountryId.Argentina && (
                                <StandardCheckBox
                                    sidelabel={resources.AfeNumber}
                                    name={"ShowAfeNumber"}
                                    disabled={!isPageEditable}
                                />
                            )}
                        <StandardCheckBox
                            sidelabel={resources.PadLatLong}
                            name={"ShowPadLatitudeAndLongitude"}
                            disabled={!isPageEditable}
                        />
                        <StandardCheckBox
                            sidelabel={resources.SurfaceWellLatLong}
                            name={"ShowLatitudeAndLongitude"}
                            disabled={!isPageEditable}
                        />
                        <StandardCheckBox
                            sidelabel={resources.BottomWellLatLong}
                            name={"ShowBottomLatitudeAndLongitude"}
                            disabled={!isPageEditable}
                        />
                        {program?.CountryId !== CountryId.Argentina && (
                            <StandardCheckBox
                                sidelabel={resources.ShowApiLabel}
                                name={"ShowApiLabel"}
                                disabled={!isPageEditable}
                            />
                        )}
                        <NotCtanView>
                            {(program?.CountryId === CountryId.Canada ||
                                program?.CountryId ===
                                    CountryId.UnitedStates) && (
                                <StandardCheckBox
                                    sidelabel={resources.ShowProgramType}
                                    name={"ShowProgramType"}
                                    disabled={!isPageEditable}
                                />
                            )}
                        </NotCtanView>
                    </FormSection>
                    <FormSection
                        label={resources.WellConfiguration}
                        className={"grid w-full grid-cols-1 gap-x-6 gap-y-8"}
                    >
                        <StandardCheckBox
                            sidelabel={resources.ShowWellConfigPages}
                            name={"ShowWellConfigurationPages"}
                            disabled={!isPageEditable}
                        />
                        <div
                            className={
                                "grid gap-y-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5"
                            }
                        >
                            <StandardCheckBox
                                sidelabel={resources.KickOffPoint}
                                name={"ShowWellConfigKickOffPoint"}
                                disabled={
                                    !isPageEditable ||
                                    !model?.ShowWellConfigurationPages
                                }
                                wrapperClassName={"mb-4"}
                            />
                            <StandardCheckBox
                                sidelabel={resources.NinetyDegreeHeel}
                                name={"ShowWellConfigNinetyDegreeHeel"}
                                disabled={
                                    !isPageEditable ||
                                    !model?.ShowWellConfigurationPages
                                }
                                wrapperClassName={"mb-4"}
                            />
                            <StandardCheckBox
                                sidelabel={resources.ShowBitSize}
                                name={"ShowBitSize"}
                                disabled={
                                    !isPageEditable ||
                                    !model?.ShowWellConfigurationPages
                                }
                                wrapperClassName={"mb-4"}
                            />
                            <CementView>
                                <StandardCheckBox
                                    sidelabel={resources.ShowPressureTestTable}
                                    name={"ShowPressureTestTable"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowWellConfigurationPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                            </CementView>
                            <CtanView>
                                <StandardCheckBox
                                    sidelabel={resources.BHN2SpaceFactor}
                                    name={"ShowWellConfigBHN2SpaceFactor"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowWellConfigurationPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                            </CtanView>
                        </div>
                        <StandardTextArea
                            label={resources.Notes}
                            name={"WellConfigNotes"}
                            disabled={!isPageEditable}
                            className={"col-span-full"}
                        />
                    </FormSection>

                    <FormSection
                        label={resources.DesignResults}
                        className={"grid w-full grid-cols-1 gap-x-6 gap-y-8"}
                    >
                        <StandardCheckBox
                            sidelabel={resources.ShowDesignResultsPages}
                            name={"ShowDesignResultsPages"}
                            disabled={!isPageEditable}
                        />
                        <div
                            className={
                                "grid gap-y-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5"
                            }
                        >
                            <NotCementView>
                                <StandardCheckBox
                                    sidelabel={
                                        resources.InjectionPointsAndIntrinsikTable
                                    }
                                    name={
                                        "ShowDesignResultsInjectionPointsAndIntrinsikTable"
                                    }
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowDesignResultsPages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.InjectionPoints}
                                    name={"ShowDesignResultsInjectionPoints"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowDesignResultsPages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.IntrinsikDesignation}
                                    name={
                                        "ShowDesignResultsIntrinsikDesignation"
                                    }
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowDesignResultsPages
                                    }
                                />
                            </NotCementView>
                            <StandardCheckBox
                                sidelabel={resources.ChemicalOnNewPage}
                                name={"ShowChemicalsOnNewPage"}
                                disabled={
                                    !isPageEditable ||
                                    !model?.ShowDesignResultsPages
                                }
                            />
                            <NotCementView>
                                <NotCtanView>
                                    <StandardCheckBox
                                        sidelabel={resources.WaterSummary}
                                        name={"ShowBaseFluidSummary"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowDesignResultsPages
                                        }
                                    />
                                </NotCtanView>
                            </NotCementView>
                            <CementView>
                                <StandardCheckBox
                                    sidelabel={
                                        resources.ShowMaxRequiredPumpPower
                                    }
                                    name={"ShowMaxRequiredPumpPower"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowDesignResultsPages
                                    }
                                />
                            </CementView>
                        </div>
                    </FormSection>
                    <NotCementView>
                        <FormSection label={resources.Intervals}>
                            <StandardCheckBox
                                sidelabel={resources.ShowIntervalPages}
                                name={"ShowIntervalPages"}
                                disabled={!isPageEditable}
                            />
                            <div className={"flex-col gap-y-8"}>
                                <StandardCheckBox
                                    sidelabel={resources.CompletionTechnology}
                                    name={"ShowIntervalCompletionTechnology"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.FormationAccess}
                                    name={"ShowIntervalFormationAccess"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.TVD}
                                    name={"ShowIntervalTrueVerticalDepth"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Top}
                                    name={"ShowIntervalTop"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Bottom}
                                    name={"ShowIntervalBottom"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.PlugDepth}
                                    name={"ShowIntervalPlugDepth"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.BallSize}
                                    name={"ShowIntervalBallSize"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.SeatSize}
                                    name={"ShowSeatSize"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.DisplacementVolume}
                                    name={"ShowIntervalDisplacementVolume"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <CtanView>
                                    <StandardCheckBox
                                        sidelabel={resources.BottomsUpVolume}
                                        name={"ShowIntervalBottomsUpVolume"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowIntervalPages
                                        }
                                        wrapperClassName={"mb-4"}
                                    />
                                </CtanView>
                                <NotCtanView>
                                    <StandardCheckBox
                                        sidelabel={resources.Flush}
                                        name={"ShowIntervalFlushVolume"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowIntervalPages
                                        }
                                        wrapperClassName={"mb-4"}
                                    />
                                </NotCtanView>
                            </div>
                            <div className={"flex-col gap-y-8"}>
                                <StandardCheckBox
                                    sidelabel={resources.ClusterNumber}
                                    name={
                                        "ShowIntervalPerforationClusterNumber"
                                    }
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.ClusterTop}
                                    name={"ShowIntervalPerforationClusterTop"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.ClusterBottom}
                                    name={
                                        "ShowIntervalPerforationClusterBottom"
                                    }
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />

                                <StandardCheckBox
                                    sidelabel={resources.ClusterShots}
                                    name={"ShowIntervalPerforationClusterShots"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.ClusterTotal}
                                    name={"ShowIntervalPerforationClusterTotal"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.TotalPerfs}
                                    name={"ShowIntervalTotalPerforations"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.PerforationDiameter}
                                    name={
                                        "ShowIntervalPerforationClusterDiameter"
                                    }
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                            </div>
                            <div className={"flex-col gap-y-8"}>
                                <StandardCheckBox
                                    sidelabel={resources.Description}
                                    name={"ShowIntervalDescription"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <CtanView>
                                    <StandardCheckBox
                                        sidelabel={resources.TreatmentVolume}
                                        name={"ShowTreatmentVolume"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowIntervalPages
                                        }
                                        wrapperClassName={"mb-4"}
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.FluidType}
                                        name={"ShowFluidType"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowIntervalPages
                                        }
                                        wrapperClassName={"mb-4"}
                                    />
                                </CtanView>
                                <StandardCheckBox
                                    sidelabel={resources.Misc1}
                                    name={"ShowIntervalMiscellaneous1"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Misc2}
                                    name={"ShowIntervalMiscellaneous2"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Volume1Title}
                                    name={"ShowVolume1"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Volume2Title}
                                    name={"ShowVolume2"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Proppants}
                                    name={"ShowIntervalProppants"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowIntervalPages
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                            </div>
                        </FormSection>
                    </NotCementView>
                    <FormSection label={resources.Procedures}>
                        <StandardCheckBox
                            sidelabel={resources.ShowProcedurePages}
                            name={"ShowProcedurePages"}
                            disabled={!isPageEditable}
                        />
                        <ClientOutputsTable
                            name={"ProgramProcedures"}
                            columnsDisabled={!model?.ShowProcedurePages}
                        />
                    </FormSection>
                    <FormSection
                        label={resources.Schedules}
                        className={"grid w-full grid-cols-1 gap-x-6 gap-y-8"}
                    >
                        <StandardCheckBox
                            sidelabel={resources.ShowSchedulePages}
                            name={"ShowSchedulePages"}
                            disabled={!isPageEditable}
                        />
                        <NotCtanView>
                            <StandardCheckBox
                                sidelabel={resources.ChemicalTotals}
                                name={"ShowChemicalTotals"}
                                disabled={
                                    !isPageEditable || !model?.ShowSchedulePages
                                }
                            />
                        </NotCtanView>
                        <div
                            className={
                                "grid gap-y-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5"
                            }
                        >
                            <NotCtanView>
                                <StandardCheckBox
                                    sidelabel={resources.FluidSystem}
                                    name={"ShowScheduleFluidSystem"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.BaseFluid}
                                    name={"ShowScheduleBaseFluid"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <CementView>
                                    <StandardCheckBox
                                        sidelabel={resources.Cement}
                                        name={"ShowScheduleCement"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.CementStage}
                                        name={"ShowScheduleCementStage"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.CementCumulative}
                                        name={"ShowScheduleCementCum"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.Description}
                                        name={"ShowScheduleDescription"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.VolumeCumulative2}
                                        name={"ShowScheduleVolumeCum"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.Density}
                                        name={"ShowScheduleDensity"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />

                                    <StandardCheckBox
                                        sidelabel={resources.StageTime}
                                        name={"ShowScheduleStageTime"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.CumulativeTime}
                                        name={"ShowScheduleTimeCum"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                </CementView>
                                <NotCementView>
                                    <StandardCheckBox
                                        sidelabel={resources.RateAll}
                                        name={"ShowScheduleRateGroup"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.VolumeGroup}
                                        name={"ShowScheduleVolumeGroup"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.ProppantGroup}
                                        name={"ShowScheduleProppantGroup"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.N2Group}
                                        name={"ShowScheduleN2Group"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.CO2Group}
                                        name={"ShowScheduleCO2Group"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />

                                    <StandardCheckBox
                                        sidelabel={
                                            resources.SecondaryFluidSystemGroup
                                        }
                                        name={
                                            "ShowScheduleSecondaryFluidSystemGroup"
                                        }
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={
                                            resources.DownholeConditionsGroup
                                        }
                                        name={
                                            "ShowScheduleDownholeConditionsGroup"
                                        }
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.Quality}
                                        name={"ShowScheduleDownholeFoamQuality"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.DownholeVolume}
                                        name={"ShowScheduleDownholeVolume"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={resources.TimeGroup}
                                        name={"ShowScheduleTimeGroup"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={
                                            resources.StandardChemicalScheduleOnNewPage
                                        }
                                        name={
                                            "StandardChemicalScheduleOnNewPage"
                                        }
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={
                                            resources.ChemicalScheduleProppantConcentration
                                        }
                                        name={
                                            "ShowScheduleProppantBlenderConcentration"
                                        }
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                    <StandardCheckBox
                                        sidelabel={
                                            resources.ShowSecondaryChemicalSchedule
                                        }
                                        name={"ShowSecondaryChemicalSchedule"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowSchedulePages
                                        }
                                    />
                                </NotCementView>
                            </NotCtanView>
                            <CtanView>
                                <StandardCheckBox
                                    sidelabel={resources.Depth}
                                    name={"ShowScheduleDepth"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Speed}
                                    name={"ShowScheduleSpeed"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Time}
                                    name={"ShowScheduleTime"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.FluidRates}
                                    name={"ShowScheduleFluidGroup"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.CombinedDisplacement}
                                    name={
                                        "ShowScheduleCombinedDisplacementGroup"
                                    }
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Downhole}
                                    name={"ShowScheduleDownholeGroup"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.NetDepth}
                                    name={"ShowScheduleNetMeter"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.N2Group}
                                    name={"ShowScheduleN2Group"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.FluidSystem}
                                    name={"ShowFluidSystem"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowSchedulePages
                                    }
                                />
                            </CtanView>
                        </div>
                    </FormSection>
                    <NotCementView>
                        <FormSection
                            label={resources.CalculationSummary}
                            className={
                                "grid w-full grid-cols-1 gap-x-6 gap-y-8"
                            }
                        >
                            <StandardCheckBox
                                sidelabel={resources.ShowCalculationSummary}
                                name={"ShowCalculationSummary"}
                                disabled={!isPageEditable}
                            />
                            <div
                                className={
                                    "grid gap-y-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5"
                                }
                            >
                                <StandardCheckBox
                                    sidelabel={resources.TVD}
                                    name="ShowCalculationSummaryTrueVerticalDepth"
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowCalculationSummary
                                    }
                                    wrapperClassName={"mb-4"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Depth}
                                    name={"ShowCalculationSummaryDepth"}
                                    wrapperClassName={"mb-4"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowCalculationSummary
                                    }
                                />
                                <NotCtanView>
                                    <StandardCheckBox
                                        sidelabel={resources.CO2Rate}
                                        name={"ShowCalculationSummaryCO2Rate"}
                                        wrapperClassName={"mb-4"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowCalculationSummary
                                        }
                                    />
                                </NotCtanView>
                                <StandardCheckBox
                                    sidelabel={resources.FracGradient}
                                    name={
                                        "ShowCalculationSummaryFractureGradient"
                                    }
                                    wrapperClassName={"mb-4"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowCalculationSummary
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.FrictionGradient}
                                    name={
                                        "ShowCalculationSummaryFrictionGradient"
                                    }
                                    wrapperClassName={"mb-4"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowCalculationSummary
                                    }
                                />
                                <NotCtanView>
                                    <StandardCheckBox
                                        sidelabel={resources.SlurryRate}
                                        name={
                                            "ShowCalculationSummarySlurryRate"
                                        }
                                        wrapperClassName={"mb-4"}
                                        disabled={
                                            !isPageEditable ||
                                            !model?.ShowCalculationSummary
                                        }
                                    />
                                </NotCtanView>
                                <StandardCheckBox
                                    sidelabel={resources.SpecificGravity}
                                    name={
                                        "ShowCalculationSummarySpecificGravity"
                                    }
                                    wrapperClassName={"mb-4"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowCalculationSummary
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.Time}
                                    name={"ShowCalculationSummaryTime"}
                                    wrapperClassName={"mb-4"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowCalculationSummary
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={resources.BreakDownOfTotalTime}
                                    name={"ShowBreakdownOfTotalTime"}
                                    wrapperClassName={"mb-4"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowCalculationSummary
                                    }
                                />
                                <StandardCheckBox
                                    sidelabel={
                                        resources.CalculationSummarySummaryOnNewPage
                                    }
                                    name={
                                        "ShowCalculationSummaryFooterDetailsOnNewPage"
                                    }
                                    wrapperClassName={"mb-4"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowCalculationSummary
                                    }
                                />
                            </div>
                        </FormSection>
                    </NotCementView>
                    <FormSection
                        label={resources.PricePage}
                        className={"grid w-full grid-cols-1 gap-x-6 gap-y-8"}
                    >
                        <StandardCheckBox
                            sidelabel={resources.ShowPricingPages}
                            name={"ShowPricingPages"}
                            disabled={!isPageEditable}
                        />
                        <div
                            className={
                                "grid gap-y-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5"
                            }
                        >
                            <StandardCheckBox
                                sidelabel={resources.Comments}
                                name={"ShowPricePageComments"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={resources.BookPrice}
                                name={"ShowPricePageBookPrice"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={resources.Discount}
                                name={"ShowPricePageDiscount"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={resources.QuantityUnit}
                                name={"ShowPricePageQuantityUnit"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={resources.MaterialsOnNewPage}
                                name={"ShowPricePageMaterialsOnNewPage"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={resources.CartageOnNewPage}
                                name={"ShowPricePageCartageOnNewPage"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={resources.AdditionalItemsOnNewPage}
                                name={"ShowPricePageAdditionalItemsOnNewPage"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={resources.ShowAveragePricePerStage}
                                name={"ShowAveragePricePerStage"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={
                                    resources.GroupMaterialsByFluidSystem
                                }
                                name={
                                    "ShowPricePageMaterialsGroupedByFluidSystem"
                                }
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                                onChange={() =>
                                    setValue(
                                        "ShowPricePageMaterialsGroupSubtotal",
                                        false,
                                    )
                                }
                            />
                            {model?.ShowPricePageMaterialsGroupedByFluidSystem && (
                                <StandardCheckBox
                                    sidelabel={
                                        resources.ShowGroupedMaterialsSubtotal
                                    }
                                    name={"ShowPricePageMaterialsGroupSubtotal"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowPricingPages
                                    }
                                />
                            )}
                            <CtanView>
                                <StandardCheckBox
                                    sidelabel={
                                        resources.ShowPricePageEquipmentGrouping
                                    }
                                    name={"ShowPricePageEquipmentGrouping"}
                                    disabled={
                                        !isPageEditable ||
                                        !model?.ShowPricingPages
                                    }
                                />
                            </CtanView>
                            <StandardCheckBox
                                sidelabel={resources.ShowPriceCode}
                                name={"ShowPriceCode"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                            <StandardCheckBox
                                sidelabel={resources.ShowCustomerCode}
                                name={"ShowCustomerCode"}
                                disabled={
                                    !isPageEditable || !model?.ShowPricingPages
                                }
                            />
                        </div>
                    </FormSection>
                    <FormSection label={resources.InsertAdditionalPages}>
                        <AttachmentTable
                            name={"ClientOutputsPdfAttachments"}
                            addButtonLabel={resources.AddAttachmentOnlyPdf}
                            canAddNewFile={true}
                            allowedExtensions={["pdf"]}
                            showDownloadAttachmentTemplate={true}
                        />
                    </FormSection>
                    {program?.CountryId !== CountryId.Argentina && (
                        <FormSection label={resources.TermsAndConditions}>
                            <StandardCheckBox
                                sidelabel={resources.TermsAndConditions}
                                name={"ShowTermsAndConditions"}
                                disabled={!isPageEditable}
                            />
                        </FormSection>
                    )}
                </FormSectionDivider>
            </StandardForm>
        </>
    );
};

export default Page;

import React from "react";
import { useWatch } from "react-hook-form";

import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { resources } from "utils/resources";

type Props = {
    name: string;
};

const EditProgramNextTask: React.FC<Props> = ({ name }: Props) => {
    // Constants
    const url = "/api/Task/GetPossibleTaskAssignees";

    const model = useWatch({ name });

    return (
        <div>
            {model.IsOptional && (
                <StandardCheckBox
                    name={`${name}.IsAvailable`}
                    checked={model?.IsAvailable}
                    sidelabel={`Include ${model?.TaskName} Task`}
                />
            )}
            {model?.IsAvailable && (
                <div className="border-3 mt-3 rounded border-black border-opacity-50 p-4">
                    <StandardLabel label={model?.TaskName} className={"mb-2"} />
                    <StandardMultipleSelect
                        name={`${name}.Assignees`}
                        hasObjects={true}
                        byField={"Id"}
                        url={url}
                        params={{ countryId: model?.CountryId.toString() }}
                        queryKey={[url, model?.CountryId.toString()]}
                        label={resources.AssignTo}
                    />
                </div>
            )}
        </div>
    );
};

export default EditProgramNextTask;

import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// Required for Kendo
import "bootstrap/dist/css/bootstrap.css";
import "./style/kendo.scss";

// Required for Custom Style
// Note: All tailwind classes end with !important
// This is to override Bootstrap's default styles
// Both Bootstrap and Tailwind are used in this project for development velocity.
import "./style/tailwindOutput.css";

// Required for React Toastify
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";

import { PrimaryButton } from "components/Buttons";
import { BaseProps } from "components/shared/CustomGridToolbar/StandardEditGridToolbar";

import { resources } from "utils/resources";

export interface AddNewProps extends BaseProps {
    label?: string;
    readOnly?: boolean;
}

const AddNew: React.FC<AddNewProps> = ({
    label = resources.AddNewRecord,
    isVisible = true,
    readOnly = false,
    ...props
}: AddNewProps) => {
    if (!isVisible) return <></>;

    return (
        <PrimaryButton
            title="Add new"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={(e) => {
                props.onClick?.();
                e.preventDefault();
            }}
            readOnly={readOnly}
        >
            {label}
        </PrimaryButton>
    );
};

export default AddNew;

import React from "react";

import { BackCalculatePumpScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/BackCalculatePumpScheduleStageTarget";
import { FormulaCalculateScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/FormulaCalculateScheduleStageTarget";

import BaseTable, {
    BaseTableProps,
} from "components/Tables/BaseTable/Tables/BaseTable";
import BackCalculateButton, {
    BackCalculateButtonProps,
} from "components/Tables/Schedules/BackCalculate/BackCalculateButton";
import FormulaCalculateButton, {
    FormulaCalculateButtonProps,
} from "components/Tables/Schedules/FormulaCalculate/FormulaCalculateButton";

interface BaseSchedulesTableProps extends BaseTableProps {
    onBackCalculate?: (
        colId: string,
        rowIdx: number,
        target: BackCalculatePumpScheduleStageTarget,
        callback: () => void,
    ) => void;
    onFormulaCalculate?: (
        colId: string,
        rowIdx: number,
        target: FormulaCalculateScheduleStageTarget,
        callback: () => void,
    ) => void;
}

const BaseStagesTable = (props: BaseSchedulesTableProps) => {
    const headerButtons = [
        (headerProps: BackCalculateButtonProps) => (
            <BackCalculateButton
                {...headerProps}
                onBackCalculate={props.onBackCalculate}
            />
        ),
        (headerProps: FormulaCalculateButtonProps) => (
            <FormulaCalculateButton
                {...headerProps}
                tableRef={props.tableRef}
                onFormulaCalculate={props.onFormulaCalculate}
            />
        ),
    ];

    return <BaseTable {...props} headerButtons={headerButtons} />;
};

export default BaseStagesTable;

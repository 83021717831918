import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { ProgramContext } from "providers/ProgramProvider";

import { pageEditable } from "utils/helpers";

/**
 * This hook provides the current program item.
 * however it must be used under a React Router.
 */
export const useProgramParams = () => {
    const { program, refetchContext } = React.useContext(ProgramContext);
    const { programId } = useParams();

    if (!programId) throw new Error("programId is not defined.");

    return {
        program,
        programId,
        refetchContext,
        isPageEditable: useMemo(
            () => pageEditable(program?.IsActive, program?.WorkflowStatusId),
            [program?.IsActive, program?.WorkflowStatusId],
        ),
    };
};

/**
 * This hook provides the current program item.
 * This may be used outside of the react router.
 */
export const useProgram = () => React.useContext(ProgramContext);

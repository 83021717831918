import React, { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { IJobIndexViewModel } from "types/generated/Calfrac/Jet/Web/Models/Job/IJobIndexViewModel";
import { IEditRequestViewModel } from "types/generated/Calfrac/Jet/Web/Models/Request/IEditRequestViewModel";

import { queryClient } from "AppRoutes/AppProviders";

import { WarningButton } from "components/Buttons";
import { H2 } from "components/Headers";
import StandardComboBox from "components/kendoExtensions/standardExtensions/StandardComboBox";
import StandardDatePicker from "components/kendoExtensions/standardExtensions/StandardDatePicker";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import { HeaderPortal } from "components/Layout/HeaderPortal";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";
import StandardButton from "components/shared/GenericCustomComponents/StandardButton";
import StandardCancelButton from "components/shared/GenericCustomComponents/StandardCancelButton";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardReadOnly from "components/shared/GenericCustomComponents/StandardReadOnly";
import StandardSaveButton from "components/shared/GenericCustomComponents/StandardSaveButton";
import StandardTextArea from "components/shared/GenericCustomComponents/StandardTextArea";
import ProgramsTable from "components/Tables/Request/ProgramsTable";
import AttachmentTable from "components/Tables/Task/AttachmentTable";

import { fetchJET, JetApiUrls, saveJET } from "utils/fetchJet";
import { findFieldFormat } from "utils/findFieldFormat";
import { resources } from "utils/resources";

const Page: React.FC = () => {
    // Hooks
    const model = useWatch() as IEditRequestViewModel;
    const params = useParams<{
        requestId: string;
        countryId: string;
    }>();
    const requestId = params.requestId ?? "";

    const [searchParams] = useSearchParams();
    const jobId = model?.JobId ?? Number(searchParams.get("jobId")) ?? null;

    const [countryId, setCountryId] = useState(
        model?.CountryId?.toString() ?? params.countryId ?? "",
    );
    const [shouldBeAbleToAcceptAll, setShouldBeAbleToAcceptAll] = useState(
        model.Programs?.some((viewModel) => viewModel.NewProgramId == null),
    );

    const navigate = useNavigate();
    const { reset, setValue } = useFormContext();

    // Constants
    const url: JetApiUrls = "/api/Request/EditRequestViewModel";
    const [saveUrl, setSaveUrl] = useState<JetApiUrls>(
        "/api/Request/EditRequest",
    );

    // ID of 0 indicates this entity has not been created in the database yet, but will be created upon successful form submission.
    const isCreating = Number(requestId) === 0;
    const isEditable = isCreating || !model.IsReadOnly;

    const { formState } = useFormContext();

    const disableProgramCreation = !model.JobId;
    const [isAccepting, setIsAccepting] = useState<boolean>(false);

    const [customerContactShouldRefresh, setCustomerContactShouldRefresh] =
        useState(false);

    const [customerCompanyShouldRefresh, setCustomerCompanyShouldRefresh] =
        useState(false);
    const [jobIdShouldRefresh, setJobIdShouldRefresh] = useState(false);

    useEffect(() => {
        // Reading the job id from the url.
        if (!jobId || !model.CanSubmit) return;

        // Fetching new job information
        fetchJET<IJobIndexViewModel>(`/api/Job/GetJob`, {
            jobId: jobId.toString(),
        }).then((job) => {
            setValue("JobId", jobId);
            setValue("Job", job);
            setValue("AccountManagerId", job?.AccountManagerId ?? null);
            setValue("CustomerCompanyId", job?.OperatorId ?? null);
            setValue(
                "PrimaryCustomerCompanyId",
                job?.PrimaryOperatorId ?? null,
            );
            setValue("CustomerContactId", job?.OperatorContactId ?? null);
            setValue("ProjectDescription", job?.PadName);
            setValue("PadLatitude", job?.PadLatitude);
            setValue("PadLongitude", job?.PadLongitude);
        });
    }, [jobId, model.CanSubmit, model.JobId, setValue]);

    useEffect(() => {
        if (!isCreating) {
            setCountryId(
                model?.CountryId?.toString() ?? params.countryId ?? "",
            );
            setShouldBeAbleToAcceptAll(
                model?.Programs?.some(
                    (viewModel) =>
                        viewModel.NewProgramId == null &&
                        viewModel.ProgramNumber !== "",
                ),
            );
        } else setCountryId(params.countryId ?? "");
    }, [model?.CountryId, params.countryId, isCreating, model?.Programs]);

    // Callbacks
    const invalidateRequestQuery = useCallback(() => {
        queryClient.invalidateQueries({
            predicate: (query) => {
                return (query.queryKey[0] as string)?.startsWith(
                    "/api/Request/EditRequestViewModel",
                );
            },
            type: "all",
        });
    }, []);

    const handleSaveSuccess = useCallback(
        (data: { Id?: number }) => {
            if (!isCreating || !requestId) return;

            // If we're creating, then after create we want to navigate to the edit page
            const newRequestId = data.Id ?? 0;
            navigate(
                `/Request/EditRequest/${newRequestId}/${countryId}?jobId=${Number(
                    searchParams.get("jobId"),
                )}`,
                {
                    replace: true,
                },
            );
        },
        [countryId, isCreating, navigate, requestId, searchParams],
    );

    const handleReOpenRequest = useCallback(() => {
        if (window.confirm(resources.ReopenRequestConfirmation)) {
            saveJET("/api/Workflow/ReopenRequest", {
                requestId: String(model.Id),
            }).then(() => {
                navigate(0);
            });
        }
    }, [navigate, model.Id]);

    const handleReSendNotification = useCallback(() => {
        if (formState.isDirty) {
            alert(resources.YouHaveUnsavedChanges);
            return;
        }

        toast.success(resources.NotificationSent);
        saveJET("/api/Request/ResendSubmittedRequestNotification", {
            requestId: String(model.Id),
        });
    }, [formState.isDirty, model.Id]);

    const handleDelete = useCallback(async () => {
        if (!window.confirm(resources.DeleteRequestConfirmation)) {
            return;
        }
        await saveJET("/api/Request/DeleteRequest", {
            requestId: String(model.Id),
        });
        invalidateRequestQuery();
        navigate(`/Request`);
    }, [invalidateRequestQuery, model.Id, navigate]);

    const handleCloseRequest = useCallback(async () => {
        if (formState.isDirty) {
            alert(resources.YouHaveUnsavedChanges);
            return;
        }
        if (window.confirm(resources.CloseRequestConfirmation)) {
            await saveJET("/api/Workflow/CloseRequest", {
                requestId: String(model.Id),
            });

            await fetchJET<IEditRequestViewModel>(url, {
                requestId,
                countryId,
                jobId: String(jobId),
            }).then((data) => {
                reset(data);
            });

            invalidateRequestQuery();
        }
    }, [
        formState.isDirty,
        model.Id,
        requestId,
        countryId,
        jobId,
        invalidateRequestQuery,
        reset,
    ]);

    return (
        <>
            <HeaderPortal>
                <div className={"-ml-5 mr-auto flex flex-row"}>
                    <H2 className={"-ml-4 mr-4"}>{`${
                        model.RequestNumber ? ": " + model.RequestNumber : ""
                    }`}</H2>
                    {jobId ? (
                        <Link
                            className={"mr-10 flex items-center gap-1"}
                            to={`/Job/EditJob/${jobId}`}
                        >
                            <ArrowLeftCircleIcon
                                className={"h-7 w-7 fill-calfrac-green"}
                            />
                            <StandardAnchorLink text={resources.BackToJob} />
                        </Link>
                    ) : null}
                    {isEditable && !isCreating && model.CanDelete && (
                        <WarningButton
                            onClick={handleDelete}
                            className={"mr-auto"}
                        >
                            {resources.Delete}
                        </WarningButton>
                    )}
                    {isEditable && model.CanClose && (
                        <WarningButton
                            onClick={handleCloseRequest}
                            className={"mr-auto"}
                        >
                            {resources.CloseRequest}
                        </WarningButton>
                    )}
                </div>

                {isEditable && !isCreating && model.CanResendNotification && (
                    <StandardButton
                        text={resources.ReSendNotification}
                        onClick={handleReSendNotification}
                    />
                )}
                {!isCreating && model.CanReopen && (
                    <StandardButton
                        text={resources.ReopenRequest}
                        onClick={handleReOpenRequest}
                    />
                )}
                {!isCreating && isEditable && model.CanSubmit && (
                    <StandardSaveButton
                        onClick={() => {
                            setSaveUrl("/api/Request/SaveAndSubmitRequest");
                        }}
                        title={resources.SubmitRequest}
                    />
                )}
                {isEditable && (
                    <StandardSaveButton
                        onClick={() => {
                            setSaveUrl("/api/Request/EditRequest");
                        }}
                    />
                )}
                {isEditable && <StandardCancelButton />}
            </HeaderPortal>
            <StandardForm
                readUrl={url}
                readParams={{ requestId, countryId, jobId }}
                saveUrl={saveUrl}
                onSaveSuccess={handleSaveSuccess}
                disableNavigationPrompt={isCreating}
                shouldBeLoading={isAccepting}
            >
                <FormSectionDivider>
                    <FormSection label={resources.BasicInformation}>
                        <StandardReadOnly
                            name={"Country"}
                            label={resources.Country}
                        />
                        {
                            <StandardDropDownList
                                name={"JobId"}
                                url={`/api/Job/GetJobNumbersOnlyVirtual?countryId=${countryId}`}
                                label={resources.JobNumber}
                                byField={"Id"}
                                disabled={!isEditable || !model.CanSubmit}
                                onChange={() => {
                                    setCustomerCompanyShouldRefresh(true);
                                }}
                                shouldRefresh={jobIdShouldRefresh}
                                setShouldRefresh={setJobIdShouldRefresh}
                            />
                        }
                        <StandardDropDownList
                            name={"AccountManagerId"}
                            url={`/api/Program/AccountManager`}
                            label={resources.AccountManager}
                            byField={"Id"}
                            disabled={true}
                            hasIcon={true}
                            tooltipText={
                                resources.ThisFieldAppliesToOfficialProgram
                            }
                        />
                        <StandardComboBox
                            name={"ProjectDescription"}
                            url={`/api/Job/GetPadNames`}
                            params={{
                                countryId: countryId,
                                operatorAccountId:
                                    model?.CustomerCompanyId?.toString() ?? "",
                            }}
                            disabled={true}
                            label={resources.PadName}
                        />
                        <StandardReadOnly
                            name={"PadLatitude"}
                            label={resources.PadLatitude}
                            format={findFieldFormat(
                                "WellData",
                                "Latitude",
                                model?.CountryId,
                            )}
                        />
                        <StandardReadOnly
                            name={"PadLongitude"}
                            label={resources.PadLongitude}
                            format={findFieldFormat(
                                "WellData",
                                "Longitude",
                                model?.CountryId,
                            )}
                        />
                    </FormSection>
                    <FormSection label={resources.Operator}>
                        {/* Operator - Field is always disabled at the Request Level (changes at Job level will propagate down to the Requests */}
                        <StandardDropDownList
                            name={"CustomerCompanyId"}
                            url={`/api/Program/Account`}
                            params={{
                                countryId,
                            }}
                            byField={"Id"}
                            disabled={true}
                            label={resources.Operator}
                            onChange={() => {
                                // When this changes clear the currently selected contact.
                                setValue("CustomerContactId", undefined, {
                                    shouldDirty: true,
                                });
                                setCustomerContactShouldRefresh(true);
                                setJobIdShouldRefresh(true);
                            }}
                            shouldRefresh={customerCompanyShouldRefresh}
                            setShouldRefresh={setCustomerCompanyShouldRefresh}
                        />

                        {/* Primary Operator (Argentina only) - Field is always disabled at the Request Level (changes at Job level will propagate down to the Requests */}
                        {model.CountryId === CountryId.Argentina && (
                            <StandardDropDownList
                                name={"PrimaryCustomerCompanyId"}
                                url={`/api/Program/PrimaryAccount`}
                                params={{
                                    countryId,
                                }}
                                byField={"Id"}
                                disabled={true}
                                label={resources.PrimaryOperator}
                            />
                        )}

                        {/* Operator Contact - Field is always disabled at the Request Level (changes at Job level will propagate down to the Requests */}
                        <StandardDropDownList
                            name={"CustomerContactId"}
                            url={`/api/Request/Contact`}
                            queryKey={[model.CustomerCompanyId]}
                            params={{
                                customerCompanyId:
                                    model?.CustomerCompanyId?.toString() ?? "",
                                countryId,
                            }}
                            byField={"Id"}
                            disabled={true}
                            label={resources.OperatorContact}
                            shouldRefresh={customerContactShouldRefresh}
                            setShouldRefresh={setCustomerContactShouldRefresh}
                        />
                        <StandardDropDownList
                            name={"CustomerTypeId"}
                            url={`/api/Request/GetRequestCustomerType`}
                            byField={"Id"}
                            disabled={!isEditable}
                            label={resources.CustomerType}
                        />
                        <StandardDatePicker
                            name={"EstimatedJobStartDate"}
                            disabled={true}
                            label={resources.EstimatedJobStartDate}
                        />
                        <StandardDatePicker
                            name={"ProgramDueDate"}
                            disabled={!isEditable}
                            id={"ProgramDueDate"}
                            label={resources.ProgramDueDate}
                        />
                        <StandardCheckBox
                            name={"IsRushed"}
                            checked={model.IsRushed}
                            disabled={!isEditable}
                            label={resources.IsRushed}
                            id={"IsRushed"}
                        />
                    </FormSection>
                    <FormSection label={resources.Programs}>
                        <ProgramsTable
                            name={"Programs"}
                            setIsAccepting={setIsAccepting}
                            shouldBeAbleToAcceptAll={shouldBeAbleToAcceptAll}
                            disableAddNewProgram={disableProgramCreation}
                        />
                    </FormSection>
                    <FormSection label={resources.RequestAttachments}>
                        <AttachmentTable
                            name={"Attachments"}
                            canAddNewFile={isEditable}
                        />
                    </FormSection>
                    <FormSection label={resources.RequestComments}>
                        <StandardTextArea
                            id={resources.Notes}
                            name={"Comments"}
                            disabled={!isEditable}
                            className={"col-span-full"}
                            rows={7}
                        />
                    </FormSection>
                </FormSectionDivider>
            </StandardForm>
        </>
    );
};
export default Page;

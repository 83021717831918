import React, { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import styled from "styled-components";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { handleEditableCellKeyDown } from "utils/keyDownHandlers";

type Props = {
    id?: string;
    value?: string;
    onChange?: (text: string) => void;
    className?: string;
    disabled?: boolean;
    label?: string;
    // Used with react hook form to register element
    name?: string;
    alignRight?: boolean;
    hint?: string;
    alwaysClearErrors?: boolean;
    overrideValue?: string;
};

const StandardTextBox: React.FC<Props> = ({
    name = " ",
    hint,
    alwaysClearErrors = true,
    ...props
}: Props) => {
    // Form Hook
    const { setValue, clearErrors } = useFormContext();
    let value = useWatch({ name });

    const {
        overrideValue,
        onChange,
        label,
        id,
        alignRight,
        className,
        disabled,
    } = props;

    if (overrideValue) {
        value = overrideValue;
    }

    const handleChange = useCallback(
        (e: TextBoxChangeEvent) => {
            if (name !== " ") {
                setValue(name, e.target?.value?.toString(), {
                    shouldDirty: true,
                });
            } else {
                onChange?.(e.target?.value?.toString() ?? "");
            }
            if (alwaysClearErrors) {
                clearErrors(name);
            }
        },
        [name, alwaysClearErrors, setValue, clearErrors, onChange],
    );

    return (
        <TextBoxContainer
            alignRight={alignRight}
            className={className ?? "col-span-1"}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleEditableCellKeyDown(e, "input", clearErrors)
            }
        >
            {label && <StandardLabel labelFor={id} label={label} />}
            <TextBox
                id={id}
                type={"text"}
                value={value}
                onChange={handleChange}
                className={
                    disabled ? "bg-calfrac-gray-50 text-calfrac-gray-300" : ""
                }
                readOnly={disabled}
            />
            {hint && (
                <span className="ml-auto w-full text-right text-sm text-calfrac-gray-300">
                    {hint}
                </span>
            )}
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </TextBoxContainer>
    );
};

type TextBoxContainerProps = {
    alignRight?: boolean;
};

const TextBoxContainer = styled.div<TextBoxContainerProps>`
    text-align: ${(props) => (props.alignRight ? "right" : "inherit")};

    display: flex;
    flex-direction: column;

    input {
        text-align: ${(props) => (props.alignRight ? "right" : "inherit")};
    }
`;

export default StandardTextBox;

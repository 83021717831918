import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { useFormContext } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { PremixedChemicalTypeId } from "types/generated/Calfrac/Jet/Core/Models/PremixedChemicalTypeId";

import { IEditPremixedChemicalItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/PremixedChemical/IEditPremixedChemicalItemViewModel";

import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import TextCell from "components/Cells/TextCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import LoadDefaults from "components/shared/CustomGridToolbar/ToolbarElements/LoadDefaults";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type PremixedChemicalsTableProps = {
    name: string;
    renderHash?: string;
    tableType: PremixedChemicalTypeId;
};

const columnHelper = createColumnHelper<IEditPremixedChemicalItemViewModel>();

const PremixedChemicalsTable: React.FC<PremixedChemicalsTableProps> = ({
    tableType,
    ...props
}) => {
    const { isPageEditable } = useProgram();

    const tableRef = useRef<HTMLTableElement>(null);

    const { programId, program } = useProgramParams();
    const units = useUnits();

    const { setValue } = useFormContext();

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.PremixedChemical, {
                id: "PremixedChemical",
                size:
                    tableType === PremixedChemicalTypeId.Extra ||
                    tableType === PremixedChemicalTypeId.ContingencyChemical
                        ? 1000
                        : 500,
                maxSize:
                    tableType === PremixedChemicalTypeId.Extra ||
                    tableType === PremixedChemicalTypeId.ContingencyChemical
                        ? 1200
                        : 500,
                header: resources.Chemical,
                cell: (props) => <DropDown {...props} />,
            }),
            columnHelper.accessor((row) => row.Comments, {
                id: "Comments",
                size: 300,
                maxSize: 300,
                header: resources.Comments,
                cell: TextCell,
            }),
            columnHelper.accessor((row) => row.OverriddenConcentration, {
                id: "OverriddenConcentration",
                size: 100,
                maxSize: 100,
                header: resources.Concentration,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor(
                (row) => row.PremixedChemical?.ConcentrationUnitSymbol,
                {
                    id: "PremixedChemical.ConcentrationUnitSymbol",
                    size: 100,
                    maxSize: 100,
                    header: resources.Unit,
                    cell: TextReadOnly,
                },
            ),
            columnHelper.accessor((row) => row.ProgramFluidSystem, {
                id: "ProgramFluidSystem",
                size: 500,
                maxSize: 500,
                header: resources.FluidSystem,
                cell: (props) => <DropDown {...props} />,
            }),
            columnHelper.accessor((row) => row.FluidVolume, {
                id: "FluidVolume",
                size: 100,
                maxSize: 100,
                header: `${resources.Volume} ${units.volumeWetMediumUnit}`,
                cell: (props) => <FormattedNumericCell {...props} />,
            }),
            columnHelper.accessor((row) => row.Total, {
                id: "Total",
                size: 100,
                maxSize: 100,
                header: resources.ChemicalTotal,
                cell: (props) =>
                    tableType !== PremixedChemicalTypeId.Extra &&
                    tableType !== PremixedChemicalTypeId.ContingencyChemical ? (
                        <NumericReadOnly {...props} />
                    ) : (
                        <FormattedNumericCell {...props} />
                    ),
            }),
            columnHelper.accessor(
                (row) => row.PremixedChemical?.VolumeUnitSymbol,
                {
                    id: "PremixedChemical.VolumeUnitSymbol",
                    size: 100,
                    maxSize: 100,
                    header: resources.Unit,
                    cell: TextReadOnly,
                },
            ),
        ];
    }, [tableType, units.volumeWetMediumUnit]);

    const columnVisibility = () => {
        return {
            "PremixedChemical.ConcentrationUnitSymbol":
                tableType !== PremixedChemicalTypeId.Extra &&
                tableType !== PremixedChemicalTypeId.ContingencyChemical,
            ProgramFluidSystem:
                tableType !== PremixedChemicalTypeId.Extra &&
                tableType !== PremixedChemicalTypeId.ContingencyChemical,
            FluidVolume:
                tableType !== PremixedChemicalTypeId.Extra &&
                tableType !== PremixedChemicalTypeId.ContingencyChemical,
            Comments:
                tableType === PremixedChemicalTypeId.ContingencyChemical ||
                tableType === PremixedChemicalTypeId.Extra,
            OverriddenConcentration:
                tableType === PremixedChemicalTypeId.Custom,
        };
    };

    // Handlers
    const afterUpdateData = useCallback((columnId: string) => {
        if (columnId !== "PremixedChemical") return;
        updateRenderHash();
    }, []);

    // Default Record Explanation: If you need to set default values for certain columns, for certain tables, when the
    // user clicks "Add New Record", this is where you can set those defaults for the record.
    // Reason: because until the user focuses the cell, it may contain a null (despite displaying a "0.0", for example),
    // and this may cause it to fail form validation on "Save" until they focus the validation-failing-cell, thereby
    // setting the value on the form. So, we can set up the default record values here as a workaround.
    const defaultRecord = useMemo(() => {
        const record = {
            PremixedChemicalTypeId: tableType,
        };

        // For the (Frac) Extra table, we want the user to be able to click "Add New Record", select a chemical from the
        // drop down, then Save without errors (meaning the Chemical Total should be saved with a default of 0.0, as shown
        // by default in the cell). This means we need to set default values for the Chemical Total column, otherwise it
        // will send back null (until the user focuses the field once) and fail validation.
        if (tableType === PremixedChemicalTypeId.Extra) {
            return { ...record, Total: 0.0 };
        }

        if (tableType === PremixedChemicalTypeId.ContingencyChemical) {
            return { ...record, Total: 0.0 };
        }
        return record;
    }, [tableType]);

    const toolbarExtras = () => {
        return (
            <div
                className={
                    "sticky left-0 z-10 mb-1 flex w-full items-center space-x-4"
                }
            >
                <LoadDefaults
                    url={`/api/PremixedChemical/LoadDefaults?programId=${programId}&chemicalTypeId=${tableType}`}
                    warningMsg={resources.LoadDefaultsWarning}
                    isVisible={
                        isPageEditable &&
                        (tableType === PremixedChemicalTypeId.Extra ||
                            tableType ===
                                PremixedChemicalTypeId.ContingencyChemical)
                    }
                    onNewData={(newData) => {
                        for (const row of newData) {
                            const key = Math.random()
                                .toString(36)
                                .substring(2, 15);
                            row.Id = `NEW-${key}`;
                        }
                        setValue(props.name, newData, { shouldDirty: true });
                        updateRenderHash();
                    }}
                />
            </div>
        );
    };

    return (
        <BaseTable
            tableRef={tableRef}
            toolbarExtras={toolbarExtras}
            name={props.name}
            defaultRecord={defaultRecord}
            columns={defaultColumns}
            renderHash={renderHash}
            afterUpdateData={afterUpdateData}
            meta={{
                canDelete: isPageEditable,
                urls: {
                    PremixedChemical: `/api/PremixedChemical/Chemical?countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                    ProgramFluidSystem: `/api/PremixedChemical/ProgramFluidSystem?programId=${programId}`,
                },
            }}
            columnVisibility={columnVisibility()}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default PremixedChemicalsTable;

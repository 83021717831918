import React, { useEffect, useMemo, useReducer, useRef } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { IProgramCementsViewModel } from "types/generated/Calfrac/Jet/Web/Models/FluidsEnergyProppantsCements/IProgramCementsViewModel";

import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type CementsTableProps = {
    name: string;
    renderHash?: string;
};

const CementsTable: React.FC<CementsTableProps> = (
    props: CementsTableProps,
) => {
    // Refs
    const tableRef = useRef<HTMLTableElement>(null);

    // Hooks
    const units = useUnits();
    const { isPageEditable } = useProgram();
    const { programId, program } = useProgramParams();

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    // Effects
    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const columnHelper = createColumnHelper<IProgramCementsViewModel>();
    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.Cement, {
                id: "Cement",
                size: 1450,
                maxSize: 1450,
                header: resources.Cements,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.Total, {
                id: "Total",
                size: 80,
                maxSize: 80,
                header: `${resources.Total} ${units.massCementSmall}`,
                cell: FormattedNumericCell,
            }),
        ];
    }, [columnHelper, units.massCementSmall]);

    return (
        <BaseTable
            tableRef={tableRef}
            name={props.name}
            defaultRecord={{}}
            columns={defaultColumns}
            renderHash={renderHash}
            meta={{
                canDelete: isPageEditable,
                urls: {
                    Cement: `/api/FluidsEnergyProppantsCements/Cement?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                },
            }}
        />
    );
};
export default CementsTable;

import React, { useReducer } from "react";

import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardTextArea from "components/shared/GenericCustomComponents/StandardTextArea";
import { ValidationSummary } from "components/shared/GenericCustomComponents/StandardValidationSummary";
import RiskAssessmentTable from "components/Tables/RiskAssessment/RiskAssessmentTable";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

const Page: React.FC = () => {
    // Constants
    const url = "/api/RiskAssessment/EditRiskAssessment";

    // Url params
    const { programId, isPageEditable } = useProgramParams();

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );
    return (
        <StandardForm
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            onSaveSuccess={updateRenderHash}
        >
            <FormSectionDivider>
                <FormSection label={resources.RiskAssessmentChecklist}>
                    <RiskAssessmentTable
                        name={"RiskAssessmentChecklist"}
                        renderHash={renderHash}
                    />
                    <StandardCheckBox
                        label={resources.NoRiskAssessmentOptionsApply}
                        name={"NoRiskAssessmentOptionsApply"}
                        disabled={!isPageEditable}
                        onChange={() => updateRenderHash()}
                    />
                </FormSection>
                <ValidationSummary />
                <FormSection label={resources.RiskAssessmentWasPerformed}>
                    <StandardCheckBox
                        label={resources.RiskAssessmentWasPerformed}
                        name={"RiskAssesmentWasPerformed"}
                        disabled={!isPageEditable}
                        className={"col-span-full"}
                    />
                    <StandardTextArea
                        name={"RiskAssesmentReviewComments"}
                        label={resources.RiskAssessmentReviewComments}
                        disabled={!isPageEditable}
                        className={"col-span-full"}
                    />
                </FormSection>
            </FormSectionDivider>
        </StandardForm>
    );
};

export default Page;

import React from "react";

import { ProgramWorkflowStatusId } from "types/generated/Calfrac/Jet/Core/Models/ProgramWorkflowStatusId";

import { useProgram } from "hooks/useProgramParams";

import { resources } from "utils/resources";

const steps = [
    {
        id: "01",
        statusId: ProgramWorkflowStatusId.InDesign,
        name: resources.InDesign,
        status: "complete",
    },
    {
        id: "02",
        statusId: ProgramWorkflowStatusId.QueuedForReview,
        name: resources.QueuedForReview,
        status: "current",
    },
    {
        id: "03",
        statusId: ProgramWorkflowStatusId.InReview,
        name: resources.InReview,
        status: "upcoming",
    },
    {
        id: "04",
        statusId: ProgramWorkflowStatusId.InApproval,
        name: resources.InApproval,
        status: "upcoming",
    },
    {
        id: "05",
        statusId: ProgramWorkflowStatusId.Approved,
        name: resources.Approved,
        status: "upcoming",
    },
    {
        id: "06",
        statusId: ProgramWorkflowStatusId.SentToOperations,
        name: resources.SentToOperations,
        status: "upcoming",
    },
];

const TaskStepsBar = () => {
    const { program } = useProgram();
    // canceled programs return a workflow status of 7 from API, revised programs return 8
    const isProgramCanceledOrRevised =
        program?.WorkflowStatusId === 7 || program?.WorkflowStatusId === 8;

    return (
        <nav aria-label="Progress" className={"my-4"}>
            <ol className="divide-y divide-gray-300 rounded-md border border-gray-300   xl:flex xl:divide-y-0">
                {steps.map((step, stepIdx) => {
                    if (!program) return null;
                    const isComplete = step.statusId < program.WorkflowStatusId;
                    const isCurrent =
                        step.statusId === program.WorkflowStatusId;

                    return (
                        <li
                            key={step.name}
                            className={`relative ${
                                isProgramCanceledOrRevised
                                    ? "bg-calfrac-gray-50"
                                    : ""
                            } xl:flex xl:flex-1`}
                        >
                            {isProgramCanceledOrRevised ? (
                                <div
                                    className="flex items-center px-6 py-4 text-sm font-medium"
                                    aria-current="step"
                                >
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-calfrac-gray-300">
                                        <span className="text-calfrac-gray-300">
                                            {step.id}
                                        </span>
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-calfrac-gray-300">
                                        {step.name}
                                    </span>
                                </div>
                            ) : isComplete ? (
                                <div className="group flex w-full items-center">
                                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-900">
                                            <span className="text-gray-900">
                                                {step.id}
                                            </span>
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-gray-900 ">
                                            {step.name}
                                        </span>
                                    </span>
                                </div>
                            ) : isCurrent ? (
                                <div
                                    className="flex items-center px-6 py-4 text-sm font-medium"
                                    aria-current="step"
                                >
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-calfrac-green-600">
                                        <span className="text-calfrac-green-600">
                                            {step.id}
                                        </span>
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-calfrac-green-600">
                                        {step.name}
                                    </span>
                                </div>
                            ) : (
                                <div className="group flex items-center">
                                    <span className="flex items-center px-6 py-4 text-sm font-medium">
                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                            <span className="text-gray-500 group-hover:text-gray-900  ">
                                                {step.id}
                                            </span>
                                        </span>
                                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900  ">
                                            {step.name}
                                        </span>
                                    </span>
                                </div>
                            )}

                            {stepIdx !== steps.length - 1 ? (
                                <>
                                    {/* Arrow separator for lg screens and up */}
                                    <div
                                        className="absolute right-0 top-0 hidden h-full w-5 xl:block"
                                        aria-hidden="true"
                                    >
                                        <svg
                                            className="h-full w-full text-gray-300"
                                            viewBox="0 0 22 80"
                                            fill="none"
                                            preserveAspectRatio="none"
                                        >
                                            <path
                                                d="M0 -2L20 40L0 82"
                                                vectorEffect="non-scaling-stroke"
                                                stroke="currentcolor"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </>
                            ) : null}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default TaskStepsBar;

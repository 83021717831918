import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { Id } from "react-toastify/dist/types";
import isPropValid from "@emotion/is-prop-valid";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Cookies from "js-cookie";
import { StyleSheetManager } from "styled-components";

import useInterval from "hooks/useInterval";

import { FormProvider } from "providers/FormProvider";

import isApiVersionGreater from "utils/isApiVersionGreater";
import { resources } from "utils/resources";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 1000 * 60 * 15,
            gcTime: 1000 * 60 * 15,
        },
    },
});

const VersionChecker = () => {
    const toastId = useRef<Id | null>(null);
    // We must use a ref here because the formState.isDirty is not updated in the toast.
    const isFormDirty = useRef(false);

    const { formState } = useFormContext();

    useEffect(() => {
        isFormDirty.current = formState.isDirty;
    }, [formState.isDirty]);

    const refreshPage = useCallback(() => {
        const userResponse = isFormDirty.current
            ? window.confirm(`${resources.YouHaveUnsavedChanges}`)
            : true;
        if (!userResponse) {
            return;
        } else if (userResponse) {
            // Clear cache and hard reload
            if (window.caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (let name of names) caches.delete(name);
                });
            }
            window.location.reload();
        }
    }, []);

    const checkVersion = useCallback(() => {
        const uiVersion = process.env.REACT_APP_VERSION ?? "v0.0.0.0";
        const apiVersion = `v${Cookies.get("X-API-VERSION") ?? "3.0.0.0"}`;

        const shouldRefresh = isApiVersionGreater(uiVersion, apiVersion);

        // find "version-toast-message" element. Note: this is a failsafe in case the useRef is cleared for some reason.
        // This is a rare case, but it can happen on some browsers (recently noted in Edge)
        const element = document.getElementById("version-toast-message");
        const isToastDisplayed =
            (toastId.current && toast.isActive(toastId.current)) ||
            element !== null;

        // early return if toast is already displayed or if we don't need to refresh
        if (!shouldRefresh || isToastDisplayed) {
            return;
        }

        // Otherwise, create a toast and save the toastId
        toastId.current = toast.info(
            <div className="flex flex-col" id={"version-toast-message"}>
                <span>{resources.WrongVersion.replace("{1}", apiVersion)}</span>
                <button
                    onClick={refreshPage}
                    className="mt-2 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                >
                    {resources.Reload}
                </button>
            </div>,
            {
                position: "bottom-right",
                autoClose: false,
                closeOnClick: false,
                draggable: false,
            },
        );
    }, [refreshPage]);

    // Every 15 seconds we'll confirm that the user is on the correct version.
    // Since this is a client side check, it's low cost.
    useInterval(() => {
        checkVersion();
    }, 1000 * 15);

    return <></>;
};

const AppProviders: React.FC<{ children?: ReactNode; storybookData?: any }> = ({
    children,
    storybookData,
}) => (
    <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <FormProvider currentValues={storybookData}>
                <VersionChecker />
                {children}
            </FormProvider>
        </QueryClientProvider>
    </StyleSheetManager>
);

export default AppProviders;

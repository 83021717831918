import { useEffect } from "react";

export const useScroll = (ref?: HTMLElement | null, onScroll?: () => void) => {
    useEffect(() => {
        if (!ref) return;

        const scrollHandler = () => onScroll?.();
        ref.addEventListener("scroll", scrollHandler);

        return () => ref.removeEventListener("scroll", scrollHandler);
    }, [ref, onScroll]);
};

//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WorkflowActionId {
	RegularProgramWorkflowProgrammerAcceptAccept = 1,
	RegularProgramWorkflowProgrammerSubmitForReview = 2,
	RegularProgramWorkflowReviewer1AcceptAccept = 3,
	RegularProgramWorkflowReviewer2AcceptAccept = 4,
	RegularProgramWorkflowReviewer1Approve = 5,
	RegularProgramWorkflowReviewer1Reject = 6,
	RegularProgramWorkflowReviewer2Approve = 7,
	RegularProgramWorkflowReviewer2Reject = 8,
	RegularProgramWorkflowAccountManagerApprove = 9,
	RegularProgramWorkflowAccountManagerReject = 10,
	RegularProgramWorkflowSendtoOperationsNotifyOperations = 11,
	RegularProgramWorkflowProgrammerCancelProgram = 12,
	RegularProgramWorkflowReviewer1CancelProgram = 13,
	RegularProgramWorkflowReviewer2CancelProgram = 14,
	RegularProgramWorkflowAccountManagerCancelProgram = 15,
	RegularProgramWorkflowSendtoOperationsCancelProgram = 16,
	RegularProgramWorkflowUnCancelProgramUnCancel = 17,
	RegularProgramWorkflowUnApproveProgramUnApprove = 18,
	RegularProgramWorkflowConvertToExecutableProgramConvert = 19,
	RegularProgramWorkflowReviseProgramRevise = 20,
	RegularProgramWorkflowUnReviseProgramUnRevise = 21,
	ProgramPriceRevisionWorkflowAccountManagerAcceptAccept = 22,
	ProgramPriceRevisionWorkflowAccountManagerApprove = 23,
	ProgramPriceRevisionWorkflowAccountManagerCancelProgram = 24,
	ProgramPriceRevisionWorkflowSendtoOperationsNotifyOperations = 25,
	ProgramPriceRevisionWorkflowSendtoOperationsCancelProgram = 26,
	ProgramPriceRevisionWorkflowUnCancelProgramUnCancel = 27,
	ProgramPriceRevisionWorkflowUnApproveProgramUnApprove = 28,
	ProgramPriceRevisionWorkflowConvertToExecutableProgramConvert = 29,
	ProgramPriceRevisionWorkflowReviseProgramRevise = 30,
	ProgramPriceRevisionWorkflowUnReviseProgramUnRevise = 31,
	PumpScheduleToolWorkflowProgrammerAcceptAccept = 32
}

import React from "react";
import { GridCellProps } from "@progress/kendo-react-grid";

import TableCell from "./TableCell";

/**
 * This function is used to format a date value in a grid column.
 *
 * @param props
 * @constructor
 */
const BoundDate = (props: GridCellProps) => {
    const dateInput = props?.dataItem?.[props.field as string];
    const dateValue = new Date(dateInput);
    // The date value is stored as a string in the dataItem.
    // If dateValue is before 1970, it will be invalid.
    if (!dateInput || dateValue.getFullYear() < 1970)
        return <TableCell {...props} />;

    const formattedDate = dateValue.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    // To locale string is used to format the date value.

    return <TableCell {...props}>{formattedDate}</TableCell>;
};

export default BoundDate;

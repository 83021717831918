import React, { memo, useEffect, useMemo, useReducer } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import TextReadOnly from "components/Cells/TextReadOnly";
import { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type DesignTableProps = {
    name: string;
    title?: string;
    showBothFluidTypes?: boolean;
    HasCasings?: boolean;
    HasCoiledTubings?: boolean;
    renderHash?: string;
};

const columnHelper = createColumnHelper<any>();

const DesignTable: React.FC<DesignTableProps> = (props) => {
    const { showBothFluidTypes } = props;
    const model = useWatch({ name: props.name });
    const { setValue, getValues } = useFormContext();
    const units = useUnits();

    let finalTotalUnit = "";
    let descriptionSize = 175;

    const { program } = useProgramParams();
    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);
    const isFrac = !isCement && !isCtan;

    // Updating this render hash will rerender all the rows. This is useful for data changes like copying and pasting from excel.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    switch (props.name) {
        case "BaseFluids":
            finalTotalUnit = units.volumeWetMediumUnit;
            break;
        case "Proppants":
            finalTotalUnit = units.massLargeUnit;
            break;
        case "Activators":
            finalTotalUnit = units.volumeActivatorDesignResults;
            break;
        case "Cements":
            finalTotalUnit = units.massCementSmall;
            break;
    }

    switch (props.name) {
        case "BaseFluids":
            descriptionSize = isCtan ? 90 : 150;
            break;
        case "EnergyTypes":
            descriptionSize = 25;
            break;
        case "PremixedChemicals":
        case "AdditionalChemicals":
            descriptionSize = 100;
            break;
        case "Activators":
            descriptionSize = 350;
            break;
        case "Proppants":
            descriptionSize = 350;
            break;
        case "Cements":
            descriptionSize = 350;
            break;
    }

    // IMPORTANT: unless you know what you're doing do not change the order of these columns,
    // This page has many tables with different values, this order is specifically so that
    // all columns show in the right order on all pages.
    const defaultColumns = useMemo(() => {
        return [
            columnHelper.accessor((row) => row.ProgramFluidSystemId, {
                id: "ProgramFluidSystemId",
                header: resources.FluidSystem,
                size: 50,
                cell: (c) =>
                    c.cell.getIsGrouped() ? (
                        <TextReadOnly
                            styleReadOnly={false}
                            value={c.row.original.FluidSystem}
                            {...c}
                        />
                    ) : null,
            }),
            columnHelper.accessor((row) => row.BaseFluid, {
                id: "BaseFluid",
                header: resources.BaseFluid,
                size: 30,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.FinalTotal, {
                id: "Final",
                header: `${resources.Final} ${units.volumeWetMediumUnit}`,
                size: 85,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Description, {
                id: "Description",
                header:
                    props.name === "BaseFluids"
                        ? resources.BaseFluid
                        : resources.Description,
                size: descriptionSize,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Percentage, {
                id: "Percentage",
                header: `${resources.Percentage} ${units.percentageUnit}`,
                size: 15,
                cell: NumericReadOnly,
                aggregationFn: (_) => "",
            }),
            columnHelper.accessor((row) => row.TotalVolume, {
                id: "TotalVolume",
                header: `${
                    props.name === "Activators"
                        ? resources.TotalAmount
                        : resources.TotalVolume
                } ${
                    props.name === "Activators"
                        ? units.volumeActivatorDesignResults
                        : units.volumeWetMediumUnit
                }`,
                size: 100,
                cell: NumericReadOnly,
                aggregationFn: (_) => "",
            }),
            columnHelper.accessor((row) => row.TotalPrimaryAmount, {
                id: "TotalPrimaryAmount",
                header: resources.TotalPrimaryAmount,
                size: 85,
                cell: NumericReadOnly,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.TotalSecondaryAmount, {
                id: "TotalSecondaryAmount",
                header: resources.TotalSecondaryAmount,
                size: 85,
                cell: NumericReadOnly,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.TotalAmount, {
                id: "TotalAmount",
                // This was in dev but is this right?
                header: `${resources.TotalAmount}${
                    props.name === "Proppants"
                        ? " " + units.massLargeUnit
                        : props.name === "Cements"
                          ? " " + units.massCementSmall
                          : ""
                }`,
                size: 100,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Unit, {
                id: "Unit",
                header: resources.Unit,
                size: 85,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.DisplayUnitSymbol, {
                id: "DisplayUnitSymbol",
                header: resources.Unit,
                size: 30,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Contingency, {
                id: "Contingency",
                header: `${resources.Contingency} ${units.percentageUnit}`,
                size: 85,
                cell: FormattedNumericCell,
                aggregationFn: (_) => "",
            }),
            columnHelper.accessor((row) => row.OutsideDiameter, {
                id: "OutsideDiameter",
                header: `${resources.OD} ${units.lengthSmallUnit}`,
                size: 60,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Weight, {
                id: "Weight",
                header: `${resources.Weight} ${units.weightPerLengthUnit}`,
                size: 80,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Grade, {
                id: "Grade",
                header: resources.Grade,
                size: 70,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.InternalYieldPressure, {
                id: "InternalYieldPressure",
                header: `${resources.IntYield} ${units.pressureLargeUnit}`,
                size: 70,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Collapse, {
                id: "Collapse",
                header: `${resources.Collapse} ${units.pressureLargeUnit}`,
                size: 70,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Top, {
                id: "Top",
                header: `${resources.Top} ${units.depthUnit}`,
                size: 70,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Bottom, {
                id: "Bottom",
                header: `${resources.Bottom} ${units.depthUnit}`,
                size: 70,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.MaxWallThickness, {
                id: "MaxWallThickness",
                header: `${resources.MaxWallThickness} ${units.thicknessUnit}`,
                size: 70,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.FrictionGradient, {
                id: "FrictionGradient",
                header: `${resources.FrictionGradient} ${units.frictionGradientUnit}`,
                size: 125,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor(
                (row) => row.FlowConfigurationItemStartDepth,
                {
                    id: "FlowConfigurationItemStartDepth",
                    header: `${resources.StartDepth} ${units.depthUnit}`,
                    size: 125,
                    cell: NumericReadOnly,
                },
            ),
            columnHelper.accessor((row) => row.FlowConfigurationItemEndDepth, {
                id: "FlowConfigurationItemEndDepth",
                header: `${resources.EndDepth} ${units.depthUnit}`,
                size: 125,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.TubularFrictionPressure, {
                id: "TubularFrictionPressure",
                header: `${resources.TubularFrictionPressure} ${units.pressureSmallUnit}`,
                size: 650,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.ContingencyVolume, {
                id: "ContingencyVolume",
                // This logic was in DEV, but is this correct? Why do Activators display the volume as amount?
                header: `${
                    props.name === "Activators"
                        ? resources.ContingencyAmount
                        : resources.ContingencyVolume
                } ${
                    props.name === "Activators"
                        ? units.volumeActivatorDesignResults
                        : units.volumeWetMediumUnit
                }`,
                size: 85,
                cell: FormattedNumericCell,
                aggregationFn: (_) => "",
            }),
            columnHelper.accessor((row) => row.ContingencyAmount, {
                id: "ContingencyAmount",
                header: `${resources.ContingencyAmount}${
                    props.name === "Proppants"
                        ? " " + units.massLargeUnit
                        : props.name === "Cements"
                          ? " " + units.massCementSmall
                          : ""
                }`,
                size: 85,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.Bottoms, {
                id: "Bottoms",
                header: `${resources.Bottoms} ${units.percentageUnit}`,
                size: 85,
                cell: FormattedNumericCell,
                aggregationFn: (_) => "",
            }),
            columnHelper.accessor((row) => row.BottomsVolume, {
                id: "BottomsVolume",
                header: `${resources.BottomsVolume} ${units.volumeWetMediumUnit}`,
                size: 85,
                cell: FormattedNumericCell,
                aggregationFn: (_) => "",
            }),
            columnHelper.accessor((row) => row.CoiledTubingFillVolume, {
                id: "CoiledTubingFillVolume",
                header: `${resources.CoiledTubingAcronym}${
                    props.name === "BaseFluids"
                        ? " " + units.volumeWetMediumUnit
                        : ""
                }`,
                size: 85,
                cell: FormattedNumericCell,
                aggregationFn: (_) => "",
            }),
            columnHelper.accessor((row) => row.BottomsAmount, {
                id: "BottomsAmount",
                header: resources.BottomsAmount,
                size: 85,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.CoiledTubingFillAmount, {
                id: "CoiledTubingFillAmount",
                header: resources.CoiledTubingAcronym,
                size: 85,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.FinalTotal, {
                id: "FinalTotal",
                header: `${
                    props.name !== "PremixedChemicals"
                        ? resources.FinalTotal
                        : resources.Final
                }${finalTotalUnit ? " " + finalTotalUnit : ""}`,
                size: 85,
                cell:
                    props.name === "BaseFluids" || props.name === "EnergyTypes"
                        ? FormattedNumericCell
                        : NumericReadOnly,
                aggregationFn: "sum",
                aggregatedCell: (c) => (
                    <NumericReadOnly styleReadOnly={false} {...c} />
                ),
            }),
            columnHelper.accessor((row) => row.Unit, {
                id: "Unit2",
                header: resources.Unit,
                size: 85,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.FloatShoe, {
                id: "FloatShoe",
                header: `${resources.FloatShoe} ${units.lengthLargeUnit}`,
                size: 85,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.FloatCollar, {
                id: "FloatCollar",
                header: `${resources.FloatCollar} ${units.lengthLargeUnit}`,
                size: 85,
                cell: NumericReadOnly,
            }),
        ];
    }, [
        props.name,
        descriptionSize,
        units.percentageUnit,
        units.volumeActivatorDesignResults,
        units.volumeWetMediumUnit,
        units.massLargeUnit,
        units.massCementSmall,
        units.lengthSmallUnit,
        units.weightPerLengthUnit,
        units.pressureLargeUnit,
        units.depthUnit,
        units.thicknessUnit,
        units.frictionGradientUnit,
        units.pressureSmallUnit,
        units.lengthLargeUnit,
        finalTotalUnit,
    ]);

    const hiddenColumns = useMemo(() => {
        return {
            ProgramFluidSystemId: props.name === "BaseFluids",
            Description:
                props.name === "Tubulars" ||
                props.name === "BaseFluids" ||
                props.name === "EnergyTypes" ||
                props.name === "Chemicals" ||
                props.name === "PremixedChemicals" ||
                props.name === "AdditionalChemicals" ||
                props.name === "Proppants" ||
                props.name === "Activators" ||
                (isCement && props.name === "Cements"),
            Percentage: props.name === "BaseFluids",
            OutsideDiameter: props.name === "Tubulars",
            Weight: props.name === "Tubulars",
            Grade: props.name === "Tubulars",
            InternalYieldPressure: props.name === "Tubulars",
            Collapse: props.name === "Tubulars",
            Top: props.name === "Tubulars",
            Bottom: props.name === "Tubulars",
            FrictionGradient: props.name === "FrictionGradients",
            FlowConfigurationItemStartDepth: props.name === "FrictionGradients",
            FlowConfigurationItemEndDepth: props.name === "FrictionGradients",
            TubularFrictionPressure: props.name === "FrictionGradients",
            TotalVolume:
                props.name === "BaseFluids" || props.name === "Activators",
            Contingency:
                props.name === "BaseFluids" ||
                props.name === "EnergyTypes" ||
                props.name === "Chemicals" ||
                (!isCtan && props.name === "AdditionalChemicals") ||
                props.name === "Proppants" ||
                props.name === "Activators" ||
                (isCement && props.name === "Cements"),
            ContingencyVolume:
                props.name === "BaseFluids" || props.name === "Activators",
            ContingencyAmount:
                props.name === "EnergyTypes" ||
                props.name === "Chemicals" ||
                (!isCtan && props.name === "AdditionalChemicals") ||
                props.name === "Proppants" ||
                (isCement && props.name === "Cements"),
            Bottoms:
                props.name === "BaseFluids" || props.name === "EnergyTypes",
            BottomsVolume: props.name === "BaseFluids",
            BottomsAmount: props.name === "EnergyTypes",
            CoiledTubingFillVolume: !isCtan && props.name === "BaseFluids",
            FinalTotal:
                props.name === "BaseFluids" ||
                props.name === "EnergyTypes" ||
                props.name === "Chemicals" ||
                props.name === "PremixedChemicals" ||
                props.name === "Proppants" ||
                props.name === "Activators" ||
                (props.name === "AdditionalChemicals" && !isCtan) ||
                (isCement && props.name === "Cements"),
            FloatShoe:
                isCement &&
                props?.HasCasings === true &&
                props.name === "Tubulars",
            FloatCollar:
                isCement &&
                props?.HasCasings === true &&
                props.name === "Tubulars",
            TotalAmount:
                props.name === "EnergyTypes" ||
                props.name === "Chemicals" ||
                props.name === "AdditionalChemicals" ||
                props.name === "Proppants" ||
                (isCement && props.name === "Cements"),
            DisplayUnitSymbol: props.name === "EnergyTypes",
            CoiledTubingFillAmount:
                (isCement && props.name === "EnergyTypes") ||
                (!isCtan && props.name === "EnergyTypes") ||
                (isFrac &&
                    (props.name === "Chemicals" ||
                        props.name === "AdditionalChemicals")),
            TotalPrimaryAmount:
                !isCement &&
                Boolean(showBothFluidTypes) &&
                props.name === "Chemicals",
            TotalSecondaryAmount:
                !isCement &&
                Boolean(showBothFluidTypes) &&
                props.name === "Chemicals",
            Unit:
                props.name === "Chemicals" ||
                props.name === "AdditionalChemicals",
            Unit2: props.name === "PremixedChemicals",
            MaxWallThickness:
                props?.HasCoiledTubings === true && props.name === "Tubulars",
            CTVolume:
                props?.HasCoiledTubings === true && props.name === "Tubulars",
            Volume:
                (props?.HasCoiledTubings === true &&
                    props.name === "Tubulars") ||
                props.name === "EnergyTypes",
            BaseFluid: props.name === "BaseFluidSummaries",
            Final: props.name === "BaseFluidSummaries",
        };
    }, [
        props?.HasCasings,
        props?.HasCoiledTubings,
        props.name,
        showBothFluidTypes,
        isCtan,
        isCement,
        isFrac,
    ]);

    const disableSelectColumns = useMemo(() => {
        const disabled = [
            "Description",
            "TotalVolume",
            "TotalAmount",
            "TotalPrimaryAmount",
            "TotalSecondaryAmount",
            "Unit",
            "DisplayUnitSymbol",
            "OutsideDiameter",
            "Weight",
            "Grade",
            "InternalYieldPressure",
            "Collapse",
            "Top",
            "Bottom",
            "FrictionGradient",
            "FlowConfigurationItemStartDepth",
            "FlowConfigurationItemEndDepth",
            "TubularFrictionPressure",
            "FinalTotal",
        ];

        if (props.name === "BaseFluids") {
            disabled.push("Percentage");
        }

        return disabled;
    }, [props.name]);

    const grouping = useMemo(
        () => (props.name === "BaseFluids" ? ["ProgramFluidSystemId"] : []),
        [props.name],
    );

    return (
        <BaseTable
            columns={defaultColumns}
            columnVisibility={hiddenColumns}
            grouping={grouping}
            disableSelectColumns={disableSelectColumns}
            name={props.name}
            renderHash={renderHash}
            meta={{
                pageName: "DesignResults",
                updateNumericCellData: (
                    rowIndex: number,
                    columnId: string,
                    value: unknown,
                ) => {
                    if (rowIndex > (model?.length ?? 0) - 1) return;
                    const valueName = `${props.name}.${rowIndex}.${columnId}`;
                    const lastValue = getValues(valueName);
                    if (lastValue === value) return;

                    if (columnId === "CoiledTubingFillAmount") {
                        setValue(
                            `${props.name}.${rowIndex}.CoiledTubingFillAmountDry`,
                            value,
                            {
                                shouldDirty: true,
                            },
                        );

                        setValue(
                            `${props.name}.${rowIndex}.CoiledTubingFillAmountWet`,
                            value,
                            {
                                shouldDirty: true,
                            },
                        );
                    }

                    // Skip page index reset until after next rerender
                    setValue(valueName, value, { shouldDirty: true });
                },
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(DesignTable);

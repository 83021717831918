import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";

const DragBtnCell = ({ provided }: { provided?: DraggableProvided }) => {
    return (
        <div
            {...provided?.dragHandleProps}
            className={"read-only flex select-none justify-center gap-x-[1px]"}
        >
            <ThreeDots opacity={30} />
            <ThreeDots />
        </div>
    );
};

type Props = {
    opacity?: number;
};

const Dot = ({ opacity = 40 }: Props) => {
    return (
        <div
            className={`h-[4px] w-[4px] rounded-full bg-gray-500 opacity-${opacity}`}
        />
    );
};

const ThreeDots = ({ opacity = 40 }: Props) => {
    return (
        <div className={"grid justify-center gap-y-[1px]"}>
            <Dot opacity={opacity} />
            <Dot opacity={opacity} />
            <Dot opacity={opacity} />
        </div>
    );
};

export default DragBtnCell;

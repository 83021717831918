import React, { useCallback } from "react";
import { useWatch } from "react-hook-form";

import { WorkflowActionId } from "types/generated/Calfrac/Jet/Core/Models/WorkflowActionId";

import { IOpenTaskViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/IOpenTaskViewModel";

import {
    PrimaryButton,
    PrimaryButtonNavLink,
    WarningButton,
    WarningButtonNavLink,
} from "components/Buttons";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import AdminView from "components/Layout/AdminView";
import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { useProgramParams } from "hooks/useProgramParams";

import { fetchJET } from "utils/fetchJet";
import { resources } from "utils/resources";

type Props = {
    model: IOpenTaskViewModel;
    index: number;
};

const EditOpenTask: React.FC<Props> = ({ model, index }: Props) => {
    // Constants
    const url = "/api/Task/GetPossibleTaskAssignees";

    return (
        <div className="border-3 mt-3 rounded border-black border-opacity-50 p-4">
            <StandardLabel className="open-task-name" label={model?.Task} />
            <StandardLabel labelFor={""} label={resources.AssignedTo} />
            <StandardMultipleSelect
                name={`OpenTasks.${index}.Assignees`}
                url={url}
                params={{ countryId: model?.CountryId.toString() }}
                queryKey={[url, model?.CountryId.toString()]}
                hasObjects={true}
                byField={"Id"}
            />
            <ActionButtons model={model} index={index} />
        </div>
    );
};

export const RedButtonActions = [
    WorkflowActionId.ProgramPriceRevisionWorkflowSendtoOperationsCancelProgram,
    WorkflowActionId.RegularProgramWorkflowProgrammerCancelProgram,
    WorkflowActionId.RegularProgramWorkflowReviewer1CancelProgram,
    WorkflowActionId.RegularProgramWorkflowReviewer2CancelProgram,
    WorkflowActionId.RegularProgramWorkflowAccountManagerCancelProgram,
    WorkflowActionId.RegularProgramWorkflowSendtoOperationsCancelProgram,
    WorkflowActionId.ProgramPriceRevisionWorkflowAccountManagerCancelProgram,
    WorkflowActionId.RegularProgramWorkflowReviewer1Reject,
    WorkflowActionId.RegularProgramWorkflowReviewer2Reject,
    WorkflowActionId.RegularProgramWorkflowAccountManagerReject,
];

export const AdminOnlyActions = [
    WorkflowActionId.RegularProgramWorkflowReviewer1AcceptAccept,
    WorkflowActionId.RegularProgramWorkflowReviewer2AcceptAccept,
    WorkflowActionId.RegularProgramWorkflowUnApproveProgramUnApprove,
    WorkflowActionId.ProgramPriceRevisionWorkflowUnApproveProgramUnApprove,
];
const ActionButtons: React.FC<Props> = (props: Props) => {
    const { programId } = useProgramParams();
    const model = useWatch({ name: "OpenTasks" }) as IOpenTaskViewModel[];

    const RedirectActionTypes = [
        "Submit For Review",
        "Cancel Program",
        "Reject",
        "Notify Operations",
        "Approve",
    ];
    const RequestActionTypes = ["Accept"];

    const Actions = model?.[props.index].Actions;

    const RedirectActionsRed = Actions?.filter(
        (a) =>
            RedButtonActions.includes(a.Id) &&
            RedirectActionTypes.includes(a.ActionName),
    );
    const RedirectActionsOther = Actions?.filter(
        (a) =>
            !RedButtonActions.includes(a.Id) &&
            RedirectActionTypes.includes(a.ActionName),
    );

    const RequestActionsRed = Actions?.filter(
        (a) =>
            RedButtonActions.includes(a.Id) &&
            RequestActionTypes.includes(a.ActionName),
    );

    const RequestActionsOther = Actions?.filter(
        (a) =>
            !RedButtonActions.includes(a.Id) &&
            RequestActionTypes.includes(a.ActionName),
    );
    const AdminOnlyButtons = Actions?.filter(
        (a) =>
            !AdminOnlyActions.includes(a.Id) &&
            RequestActionTypes.includes(a.ActionName),
    );

    const HandleRequestAction = useCallback(
        (taskId: string, actionId: string) => {
            fetchJET("/api/Task/EditProgramTaskAction", {
                taskId,
                workflowTaskActionId: actionId,
            }).then(() => window.location.reload());
        },
        [],
    );

    return (
        <>
            <div className={"mr-auto flex flex-row gap-4 py-4"}>
                <AdminView>
                    {AdminOnlyButtons?.map((action, index) => (
                        <WarningButton
                            key={index}
                            onClick={() =>
                                HandleRequestAction(
                                    String(action.TaskId),
                                    String(action.Id),
                                )
                            }
                            className={"clickable-btn"}
                        >
                            {action?.ActionName}
                        </WarningButton>
                    ))}
                </AdminView>
                {RequestActionsRed?.map((action, index) => (
                    <WarningButton
                        key={index}
                        onClick={() =>
                            HandleRequestAction(
                                String(action.TaskId),
                                String(action.Id),
                            )
                        }
                        className={"clickable-btn"}
                    >
                        {action?.ActionName}
                    </WarningButton>
                ))}
                {RedirectActionsRed?.map((action, index) => (
                    <WarningButtonNavLink
                        to={`/Task/EditProgramTaskAction/${programId}/${action.TaskId}/${action.Id}`}
                        key={index}
                        className={"clickable-btn"}
                    >
                        {action?.ActionName}
                    </WarningButtonNavLink>
                ))}
                {RedirectActionsOther?.map((action, index) => (
                    <PrimaryButtonNavLink
                        to={`/Task/EditProgramTaskAction/${programId}/${action.TaskId}/${action.Id}`}
                        key={index}
                        className={"clickable-btn"}
                    >
                        {action?.ActionName}
                    </PrimaryButtonNavLink>
                ))}
                {RequestActionsOther?.map((action, index) => (
                    <PrimaryButton
                        key={index}
                        onClick={() =>
                            HandleRequestAction(
                                String(action.TaskId),
                                String(action.Id),
                            )
                        }
                        className={"clickable-btn"}
                    >
                        {action?.ActionName}
                    </PrimaryButton>
                ))}
            </div>
        </>
    );
};

export default EditOpenTask;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DatePicker } from "@progress/kendo-react-all";
import { CellContext } from "@tanstack/react-table";

import { TableMetaT } from "types/Tables/Cells";

import { Formats } from "utils/enumerations";

const DateCell = ({
    getValue,
    row: { index },
    column: { id },
    table,
    readOnly = false,
}: CellContext<any, any> & {
    readOnly?: boolean;
}) => {
    const initialValue = getValue();

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const dateObj = useMemo(() => {
        if (!value) {
            return undefined;
        }

        const date = new Date(value as string);

        if (date.getFullYear() < 1970) {
            return undefined;
        }

        return date;
    }, [value]);

    // When the input is blurred, we'll call our table meta's updateData function
    const onChange = useCallback(() => {
        let nextValue = !value;
        setValue(nextValue);
        (table.options.meta as TableMetaT)?.updateData(index, id, nextValue);
    }, [id, index, table.options.meta, value]);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    // Setting background of the cell to gray

    if (readOnly) {
        return (
            <div className={"read-only text-black shadow-none"}>
                {dateObj?.toLocaleDateString("en-US")}
            </div>
        );
    }

    return (
        <DatePicker
            format={Formats.DateFormat.toString()}
            weekNumber={false}
            value={dateObj}
            disabled={(table.options.meta as TableMetaT)?.disabled || readOnly}
            onChange={onChange}
            className={
                (table.options.meta as TableMetaT)?.disabled || readOnly
                    ? "text-calfrac-gray opacity-60 shadow-none"
                    : ""
            }
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(DateCell);

import React from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";

import BaseTableDraggableRow from "components/Tables/BaseTable/Rows/BaseTableDraggableRow";

import { BaseTableBodyProps } from "./BaseTableBody";

const BaseTableDroppableBody = ({
    name,
    table,
    renderHash,
    pinnedColumnBgColor,
}: BaseTableBodyProps) => {
    return (
        <Droppable droppableId={`${name}Droppable`}>
            {(provided: DroppableProvided) => (
                <tbody
                    className={"px-4"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {table.getRowModel().rows.map((row, index) => (
                        <BaseTableDraggableRow
                            name={name}
                            row={row}
                            table={table}
                            key={`${row.original.Id}`}
                            renderHash={renderHash}
                            pinnedColumnBgColor={pinnedColumnBgColor}
                            index={index}
                        />
                    ))}
                    {provided.placeholder}
                </tbody>
            )}
        </Droppable>
    );
};

export default BaseTableDroppableBody;

import React, { useReducer } from "react";

import FormSection from "components/Layout/FormSection";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import ReservoirTable from "components/Tables/ReservoirProperties/ReservoirTable";

import { useProgramParams } from "hooks/useProgramParams";

import { JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

const EditReservoirPropertiesForm: React.FC = () => {
    const url: JetApiUrls = "/api/ReservoirProperties/EditReservoirProperties";

    const { programId } = useProgramParams();

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    return (
        <StandardForm
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            onSaveSuccess={() => {
                updateRenderHash();
            }}
        >
            <FormSection label={resources.Formations}>
                <ReservoirTable name={"Formations"} renderHash={renderHash} />
            </FormSection>
        </StandardForm>
    );
};

export default EditReservoirPropertiesForm;

import * as React from "react";
import {
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-all";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { useQuery } from "@tanstack/react-query";

import { fetchJET, JetApiUrls } from "utils/fetchJet";

interface DropdownFilterCellProps extends GridFilterCellProps {
    url?: JetApiUrls;
    comparisonOperator: string;
}

export const DropdownFilterCell = (props: DropdownFilterCellProps) => {
    const { url, comparisonOperator, value } = props;
    const defaultItem = "";

    const allData = useQuery<string[] | undefined>({
        queryKey: [url, ""],
        queryFn: async () => {
            return await fetchJET(url);
        },
        enabled: true,
    });

    const filterableData: string[] = Array.from(
        new Set(allData.data?.map((p: any) => p.Name ?? p)),
    );

    let hasValue: any = (value: any) => Boolean(value && value !== defaultItem);

    const onChange = (event: DropDownListChangeEvent) => {
        hasValue = hasValue(event.target.value);
        props.onChange({
            value: hasValue ? event.target.value : "",
            operator: hasValue ? comparisonOperator : "",
            syntheticEvent: event.syntheticEvent,
        });
    };

    return (
        <div className="k-filtercell">
            <DropDownList
                style={{ width: "100%" }}
                data={["", ...filterableData]}
                value={value || defaultItem}
                onChange={onChange}
                className="bg-white bg-none"
            />
        </div>
    );
};

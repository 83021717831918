import React, { ReactNode, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Dialog } from "@progress/kendo-react-all";
import { serialize } from "object-to-formdata";

import { IJobFilterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Job/IJobFilterViewModel";
import { IProgramFilterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Program/IProgramFilterViewModel";
import { IRequestFilterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Request/IRequestFilterViewModel";
import { ITaskFilterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/ITaskFilterViewModel";

import { queryClient } from "AppRoutes/AppProviders";

import {
    PrimaryButton,
    SecondaryButton,
    WarningButton,
} from "components/Buttons";

import { saveJET } from "utils/fetchJet";
import { resources } from "utils/resources";

export const FilterManager: React.FC<{
    children: ReactNode;
    onClose: () => void;
    name: "Program" | "Request" | "Task" | "Job";
}> = ({ children, onClose, name }) => {
    const { getValues, setValue } = useFormContext();
    const nextFilter = useWatch({ name: "NextFilter" }) as
        | IRequestFilterViewModel
        | IProgramFilterViewModel
        | ITaskFilterViewModel
        | IJobFilterViewModel;

    useEffect(() => {
        // On load set the current filter as the next filter
        // "NextFilter" is the item in edit.
        const current = getValues(`${name}.CurrentFilter`);
        setValue("NextFilter", current);
    }, [getValues, name, setValue]);

    // Handlers
    const onSave = async () => {
        saveJET(
            `/api/${name}/EditFilterViewModel`,
            {},
            serialize({ ...nextFilter, Name: nextFilter?.FilterName }),
        )
            .then((res: any) => res.json())
            .then((data: any[]) => {
                const updatedFilter = data.find(
                    (
                        el:
                            | IRequestFilterViewModel
                            | IProgramFilterViewModel
                            | ITaskFilterViewModel
                            | IJobFilterViewModel,
                    ) => el.FilterName === nextFilter.FilterName,
                );
                setValue(`NextFilter`, updatedFilter);
                setValue(`${name}.CurrentFilter`, updatedFilter);
            });
        await onApply();
        onClose();
    };

    const onDelete = async () => {
        setValue(`${name}.CurrentFilter`, undefined);
        setValue("NextFilter", undefined);
        await saveJET(`/api/${name}/DeleteFilter`, {
            filterId: nextFilter?.Id.toString() ?? " ",
        });
        await InvalidateQueries();
        onClose();
    };

    const onApply = async () => {
        setValue(`${name}.CurrentFilter`, {
            ...nextFilter,
            Name: nextFilter?.FilterName,
        });
        setValue("NextFilter", undefined);
        await InvalidateQueries();
    };

    const InvalidateQueries = async () => {
        await queryClient.refetchQueries({
            predicate: (query) => {
                return (
                    (query.queryKey[0] as string).startsWith(
                        `/api/${name}/Index`,
                    ) ||
                    (query.queryKey[0] as string).startsWith(
                        `/api/${name}/GetSavedFilters`,
                    )
                );
            },
            type: "all",
        });
    };

    return (
        <Dialog
            title={resources.EditFilter}
            onClose={onClose}
            width={600}
            themeColor={"primary"}
        >
            <fieldset className="grid	grid-cols-2 gap-4 ">{children}</fieldset>
            <div className={"border-t-1 mt-4 flex flex-row gap-4 pt-4"}>
                <div className={"flex-grow"}>
                    {nextFilter?.Id !== undefined && nextFilter?.Id !== 0 && (
                        <WarningButton onClick={onDelete}>
                            {resources.Delete}
                        </WarningButton>
                    )}
                </div>
                <div className={"flex flex-row gap-2 gap-4"}>
                    <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                    <PrimaryButton onClick={onSave}>
                        {resources.Save}
                    </PrimaryButton>
                    <PrimaryButton
                        className={"button--primary"}
                        onClick={() => {
                            onApply();
                            onClose();
                        }}
                    >
                        {resources.Apply}
                    </PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
};

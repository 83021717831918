import React from "react";
import { Skeleton } from "@progress/kendo-react-all";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

export const LoaderWithLabel = ({ label }: { label?: string }) => (
    <div>
        {label && <StandardLabel label={label} />}
        <Skeleton
            shape={"rectangle"}
            style={{
                height: "30px",
                borderRadius: "4px",
            }}
            className={"w-full"}
        />
    </div>
);

import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
    MaskedTextBox,
    MaskedTextBoxChangeEvent,
    MaskedTextBoxEvent,
} from "@progress/kendo-react-all";
import { CellContext } from "@tanstack/react-table";

import { RenderHashMetaT, TableMetaT } from "types/Tables/Cells";

// extensions
// providers
import { EmptyMasks, Masks } from "utils/enumerations";

const TimeMaskedCell = ({
    getValue,
    row: { index },
    column: { id },
    table,
    readOnly,
    mask = Masks.TimeInputMask,
    emptyMask = EmptyMasks.TimeInputMask,
    allowRerender = false,
}: CellContext<any, any> & {
    readOnly?: boolean;
    mask?: Masks;
    emptyMask?: EmptyMasks;
    allowRerender?: boolean;
}) => {
    const isReadOnly = (table.options.meta as TableMetaT)?.disabled || readOnly;

    const maskedTextBox = useRef<MaskedTextBox>(null);
    useEffect(() => {
        if (!maskedTextBox.current?.element) return;
        maskedTextBox.current.element.ondragstart = (e) => e.preventDefault();
    }, [maskedTextBox]);

    // Should be a string value, e.g. "00:53:41"
    const initialValue = getValue();

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState<string>(initialValue ?? "");
    const [inEdit, setInEdit] = useState(false);

    useEffect(() => {
        if (isReadOnly || allowRerender) {
            setValue(initialValue ?? "");
            (table.options.meta as RenderHashMetaT).updateRenderHash();
        }
    }, [allowRerender, initialValue, isReadOnly, table]);

    const onFocus = useCallback(() => {
        const tableMeta = table.options.meta as RenderHashMetaT;
        if (tableMeta?.disabled) return;

        setInEdit(true);
        tableMeta.select(index, id);
    }, [index, id, setInEdit, table]);

    const onChange = useCallback(
        (e: MaskedTextBoxChangeEvent) => {
            let value = e.target.value;
            setValue(value === emptyMask ? "" : value);
        },
        [emptyMask],
    );

    const onBlur = useCallback(
        (e: MaskedTextBoxEvent) => {
            const value = e.target.value;
            const timeComponents = value.split(":");
            const hoursAsNumber = Number(timeComponents[0]);
            const minutesAsNumber = Number(timeComponents[1]);
            const secondsAsNumber = Number(timeComponents[2]);

            // each section could potentially include an "_" resulting in a NaN value, default to 0 if entered value is not a number
            let hours = !Number.isNaN(hoursAsNumber) ? hoursAsNumber : 0;
            let minutes = !Number.isNaN(minutesAsNumber) ? minutesAsNumber : 0;
            let seconds = !Number.isNaN(secondsAsNumber) ? secondsAsNumber : 0;

            if (seconds > 59) {
                seconds = seconds - 60;
                minutes += 1;
            }
            if (minutes > 59) {
                minutes = minutes - 60;
                hours += 1;
            }
            if (hours > 99) {
                hours = 99;
            }

            const hoursString = hours.toString().padStart(2, "0");
            const minutesString = minutes.toString().padStart(2, "0");
            const secondsString = seconds.toString().padStart(2, "0");

            const validTimeValue = `${hoursString}:${minutesString}:${secondsString}`;

            // Update value in parent
            (table.options.meta as TableMetaT)?.updateData(
                index,
                id,
                validTimeValue,
            );
            setInEdit(false);
        },
        [id, index, table.options.meta],
    );

    if (isReadOnly) {
        return <div className={"read-only"}>{initialValue as string}</div>;
    }

    return (
        <MaskedTextBox
            ref={maskedTextBox}
            className={`${
                inEdit
                    ? "rounded border border-calfrac-gray-200 p-0 focus:shadow-none focus:ring-calfrac-gray-100 focus:ring-offset-1 focus:ring-offset-calfrac-gray-100"
                    : "border-0 bg-transparent"
            }`}
            value={value as string}
            mask={mask}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={mask}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default TimeMaskedCell;

import React from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useFormContext, useWatch } from "react-hook-form";

import BaseTableDroppableBody from "components/Tables/BaseTable/Body/BaseTableDroppableBody";
import InnerTable from "components/Tables/BaseTable/Inner/InnerTable";

import { BaseTableProps } from "./BaseTable";

const BaseTableDragDrop = (props: BaseTableProps) => {
    const data = useWatch({ name: props.name });

    const { setValue } = useFormContext();

    const onDragEnd = ({ source, destination }: DropResult) => {
        // If there is no destination, or we are dropping in another droppable or we are dropping the element in the same position
        if (
            !destination ||
            source.droppableId !== destination.droppableId ||
            source.index === destination.index
        )
            return;

        // Get the current data in the array
        let elemList: Record<string, string | number | boolean | object>[] =
            Array.from(data);

        // Update the OrderIndex of the elements
        elemList[source.index].OrderIndex = destination.index;
        elemList[destination.index].OrderIndex = source.index;

        // Updating the list by moving the dragged element from source to destination based on id
        const draggedElement = elemList[source.index];
        elemList.splice(source.index, 1); // Deleting the element that we will move in the list
        elemList.splice(destination.index, 0, draggedElement); // Inserting the dragged element in to its new position

        // Updating OrderIndex
        elemList = elemList.map((el, idx) => {
            return { ...el, OrderIndex: idx };
        });

        setValue(props.name, elemList); // Update the data
    };

    return (
        <DragDropContext
            onDragStart={() => {}}
            onDragEnd={onDragEnd}
            onDragUpdate={() => {}}
        >
            <InnerTable {...props} TableBody={BaseTableDroppableBody} />
        </DragDropContext>
    );
};

export default React.memo(BaseTableDragDrop);

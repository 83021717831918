import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Disclosure, Popover } from "@headlessui/react";
import {
    ArrowPathRoundedSquareIcon,
    BanknotesIcon,
    BeakerIcon,
    CheckBadgeIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClipboardDocumentCheckIcon,
    ClockIcon,
    DocumentChartBarIcon,
    EllipsisVerticalIcon,
    ExclamationCircleIcon,
    EyeIcon,
    HomeIcon,
    InformationCircleIcon,
    MapPinIcon,
    PaperClipIcon,
    RectangleStackIcon,
    SignalIcon,
    Square3Stack3DIcon,
    WrenchIcon,
} from "@heroicons/react/24/outline";

import { ProgramWorkflowStatusId } from "types/generated/Calfrac/Jet/Core/Models/ProgramWorkflowStatusId";
import { ServiceLineId } from "types/generated/Calfrac/Jet/Core/Models/ServiceLineId";

import { IProgramContextViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/IProgramContextViewModel";

import { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";

import { useProgram, useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type NavigationItem = {
    name: string;
    href: string | undefined;
    Icon?: typeof HomeIcon;
    hidden?: boolean;
    children?: NavigationItem[];
};

const buildNavigation = async ({
    program,
    programId,
}: {
    program: IProgramContextViewModel | undefined;
    programId: string;
}): Promise<NavigationItem[]> => {
    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);

    const isLimitedMenu =
        program?.ServiceLineId !== ServiceLineId.Fracturing &&
        !isCtan &&
        !isCement;
    return [
        {
            name: resources.ProgramData,
            href: `/Program/EditProgramData/${programId}`,
            Icon: HomeIcon,
        },
        // Show well data directly if this is not a fracturing program
        {
            name: resources.WellData,
            href: `/WellData/EditWellData/${programId}`,
            Icon: MapPinIcon,
            hidden: !isLimitedMenu,
        },
        // Otherwise, hide it inside of the Well Drawer
        {
            name: resources.Well,
            Icon: MapPinIcon,
            hidden: isLimitedMenu,
            href: undefined,
            children: [
                {
                    name: resources.WellData,
                    href: `/WellData/EditWellData/${programId}`,
                    Icon: MapPinIcon,
                },
                {
                    name: resources.WellConfig,
                    href: `/WellConfig/EditWellConfig/${programId}`,
                    Icon: WrenchIcon,
                },
                {
                    name: resources.FlowConfiguration,
                    href: `/FlowConfiguration/EditFlowConfigurations/${programId}`,
                    Icon: ArrowPathRoundedSquareIcon,
                },
                {
                    name: resources.ReservoirProperties,
                    href: `/ReservoirProperties/EditReservoirProperties/${programId}`,
                    Icon: Square3Stack3DIcon,
                },
                {
                    name: resources.Perforations,
                    href: `/Perforation/EditPerforations/${programId}`,
                    Icon: EllipsisVerticalIcon,
                    hidden: isCement,
                },
            ],
        },
        {
            name: isCement
                ? resources.FluidsAndCements
                : resources.FluidsEnergyAndProppants,
            href: isCement
                ? `/FluidsEnergyAndCements/EditFluidsEnergyAndCements/${programId}`
                : `/FluidsEnergyAndProppants/EditFluidsEnergyAndProppants/${programId}`,
            Icon: SignalIcon,
            hidden: isLimitedMenu,
        },
        {
            name: resources.Schedules,
            href: `/Schedules/EditSchedules/${programId}`,
            Icon: ClockIcon,
            hidden: isLimitedMenu,
        },
        {
            name: resources.Chemicals,
            Icon: BeakerIcon,
            hidden: isLimitedMenu,
            href: undefined,
            children: [
                {
                    name: resources.ChemicalLoadings,
                    href: `/ChemicalLoading/EditChemicalLoading/${programId}`,
                },
                {
                    name: resources.AdditionalChemicals,
                    href: `/AdditionalChemical/EditAdditionalChemical/${programId}`,
                },
                {
                    name: resources.ActivatorLoading,
                    href: `/ActivatorLoading/EditActivatorLoading/${programId}`,
                    hidden: isCtan || isCement,
                },
            ],
        },
        {
            name: resources.DesignResults,
            href: `/DesignResults/EditDesignResults/${programId}`,
            Icon: EyeIcon,
            hidden: isLimitedMenu,
        },
        {
            name: resources.Pricing,
            href: `/Pricing/EditPricing/${programId}`,
            Icon: BanknotesIcon,
            hidden: isLimitedMenu,
        },
        {
            name: resources.ClientOutputs,
            href: `/ClientOutputs/EditClientOutputsCriteria/${programId}`,
            Icon: DocumentChartBarIcon,
            hidden: isLimitedMenu,
        },
        {
            name: "Workflow",
            Icon: ClipboardDocumentCheckIcon,
            href: undefined,
            children: [
                {
                    name: resources.RiskAssessment,
                    Icon: ExclamationCircleIcon,

                    href: `/RiskAssessment/EditRiskAssessment/${programId}`,
                },
                {
                    name: resources.Tasks,
                    Icon: RectangleStackIcon,

                    href: `/Task/EditProgramTasks/${programId}`,
                },
                {
                    name: resources.Attachments,
                    Icon: PaperClipIcon,

                    href: `/ProgramAttachment/EditProgramAttachments/${programId}`,
                },
                {
                    name: resources.ProgramApproval,
                    Icon: CheckBadgeIcon,

                    href: `/ProgramApproval/EditProgramApproval/${programId}`,
                },
            ],
        },
    ];
};

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

const ProgramStatusResources: { [key in ProgramWorkflowStatusId]: string } = {
    [ProgramWorkflowStatusId.InDesign]: resources.InDesign,
    [ProgramWorkflowStatusId.QueuedForReview]: resources.QueuedForReview,
    [ProgramWorkflowStatusId.InReview]: resources.InReview,
    [ProgramWorkflowStatusId.InApproval]: resources.InApproval,
    [ProgramWorkflowStatusId.Approved]: resources.Approved,
    [ProgramWorkflowStatusId.JobExecuted]: resources.JobExecuted,
    [ProgramWorkflowStatusId.Cancelled]: resources.Cancelled,
    [ProgramWorkflowStatusId.Revised]: resources.Revised,
    [ProgramWorkflowStatusId.SentToOperations]: resources.SentToOperations,
};

const MenuWide: React.FC<{ navigation: NavigationItem[] }> = ({
    navigation,
}) => {
    const location = useLocation();

    const isChildActive = (children: any) => {
        // Do any children paths match the current locations?
        for (const child of children) {
            if (location.pathname.includes(child?.href)) {
                return true;
            }
        }

        return false;
    };

    const isItemActive = (isActive: boolean, subItem: any): boolean => {
        return (
            isActive ||
            (subItem.name === "Flow Config" &&
                location.pathname.includes(
                    "/FlowConfiguration/EditFlowConfiguration",
                )) ||
            (subItem.name === "Schedules" &&
                location.pathname.includes("/Schedules/EditStages")) ||
            (subItem.name === "Schedules" &&
                location.pathname.includes(
                    "/ChemicalLoading/EditChemicalSchedule",
                ))
        );
    };

    return (
        <ul className="-mx-2 space-y-1">
            {navigation.map((item) =>
                item.hidden ? null : (
                    <li key={item.name}>
                        {/*if item has children render those children */}
                        {!item.children ? (
                            <NavLink
                                to={item.href ?? ""}
                                className={({
                                    isActive,
                                }: {
                                    isActive: boolean;
                                }) =>
                                    classNames(
                                        isItemActive(isActive, item)
                                            ? "bg-calfrac-gray-950 text-white"
                                            : "text-gray-400 hover:bg-calfrac-gray-700 hover:text-white",
                                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                    )
                                }
                            >
                                {item?.Icon && (
                                    <item.Icon
                                        className="h-6 w-6 shrink-0"
                                        aria-hidden="true"
                                    />
                                )}
                                {item.name}
                            </NavLink>
                        ) : (
                            <Disclosure
                                as="div"
                                defaultOpen={isChildActive(item.children)}
                            >
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={classNames(
                                                isChildActive(item.children) &&
                                                    !open
                                                    ? "bg-calfrac-gray-950 text-white"
                                                    : "text-gray-400 hover:bg-calfrac-gray-700 hover:text-white",
                                                "flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 ",
                                            )}
                                        >
                                            {item?.Icon && (
                                                <item.Icon
                                                    className="h-6 w-6 shrink-0 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            )}
                                            {item.name}
                                            <ChevronRightIcon
                                                className={classNames(
                                                    open
                                                        ? "rotate-90 text-white"
                                                        : "text-gray-400",
                                                    "ml-auto h-5 w-5 shrink-0",
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel
                                            as="ul"
                                            className="mt-1 px-2"
                                        >
                                            {item?.children
                                                ?.filter(
                                                    (subItem) =>
                                                        !subItem.hidden,
                                                )
                                                ?.map((subItem) => (
                                                    <li key={subItem.name}>
                                                        {/* 44px */}
                                                        <NavLink
                                                            to={
                                                                subItem.href ??
                                                                ""
                                                            }
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                classNames(
                                                                    isItemActive(
                                                                        isActive,
                                                                        subItem,
                                                                    )
                                                                        ? "bg-calfrac-gray-950 text-white"
                                                                        : "text-gray-400 hover:bg-calfrac-gray-700 hover:text-white",
                                                                    "block rounded-md py-2 pl-9 pr-2 text-sm leading-6 ",
                                                                )
                                                            }
                                                        >
                                                            {subItem.name}
                                                        </NavLink>
                                                    </li>
                                                ))}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        )}
                    </li>
                ),
            )}
        </ul>
    );
};

const MenuNarrow: React.FC<{ navigation: NavigationItem[] }> = ({
    navigation,
}) => {
    const location = useLocation();

    const isChildActive = (children: any) => {
        // Do any children paths match the current locations?
        for (const child of children) {
            if (location.pathname.includes(child?.href)) {
                return true;
            }
        }

        return false;
    };

    return (
        <ul className="-mx-2 space-y-1">
            {navigation.map((item) =>
                item.hidden ? null : (
                    <li key={item.name}>
                        {/*if item has children render those children */}
                        {!item.children ? (
                            <NavLink
                                to={item.href ?? ""}
                                className={({
                                    isActive,
                                }: {
                                    isActive: boolean;
                                }) =>
                                    classNames(
                                        isActive
                                            ? "bg-calfrac-gray-950 text-white"
                                            : "text-gray-400 hover:bg-calfrac-gray-700 hover:text-white",
                                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                    )
                                }
                            >
                                {item?.Icon && (
                                    <item.Icon
                                        className="h-6 w-6 shrink-0"
                                        aria-hidden="true"
                                    />
                                )}
                            </NavLink>
                        ) : (
                            <Popover as="div">
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            className={classNames(
                                                isChildActive(item.children) &&
                                                    !open
                                                    ? "bg-calfrac-gray-950 text-white"
                                                    : "text-gray-400 hover:bg-calfrac-gray-700 hover:text-white",
                                                "flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 ",
                                            )}
                                        >
                                            {item?.Icon && (
                                                <item.Icon
                                                    className="h-6 w-6 shrink-0 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </Popover.Button>
                                        <Popover.Panel
                                            as="div"
                                            className="fixed z-[999] -mt-[2.875rem] ml-12 rounded-r-md bg-calfrac-gray p-4 drop-shadow-md"
                                        >
                                            <li className={"-mt-2 mb-2"}>
                                                <span
                                                    className={
                                                        "flex w-full items-center gap-x-3 border-b p-2 text-left text-sm font-semibold leading-6 text-white"
                                                    }
                                                >
                                                    {item.name}
                                                </span>
                                            </li>
                                            {item?.children?.map((subItem) => (
                                                <li key={subItem.name}>
                                                    {/* 44px */}
                                                    <NavLink
                                                        to={subItem.href ?? ""}
                                                        className={({
                                                            isActive,
                                                        }) =>
                                                            classNames(
                                                                isActive
                                                                    ? "bg-calfrac-gray-950 text-white"
                                                                    : "text-gray-400 hover:bg-calfrac-gray-700 hover:text-white",
                                                                "block rounded-md px-2 py-2 text-sm leading-6 ",
                                                            )
                                                        }
                                                    >
                                                        {subItem.name}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </Popover.Panel>
                                    </>
                                )}
                            </Popover>
                        )}
                    </li>
                ),
            )}
        </ul>
    );
};

export default function ProgramSideBar() {
    const location = useLocation();
    const [collapse, setCollapse] = useState(false);
    const { program, isPageEditable, refetchContext } = useProgram();
    const { programId } = useProgramParams();
    // Determines if the program/ fracturing section should be rendered

    const [navigation, setNavigation] = useState<NavigationItem[]>([]);
    useEffect(() => {
        buildNavigation({
            program,
            programId,
        }).then((navResult) => {
            setNavigation(navResult);
        });
    }, [program, programId]);

    useEffect(() => {
        refetchContext();
    }, [location, refetchContext]);

    return (
        <div
            className={`${
                collapse
                    ? "w-[4.5rem] min-w-[4.5rem] max-w-[4.5rem]"
                    : "w-[15.625rem] min-w-[15.625rem] max-w-[15.625rem]"
            } relative -ml-2 flex  min-h-full grow-0 flex-col gap-y-5 overflow-y-auto bg-calfrac-gray px-6 sm:-ml-8`}
        >
            {!collapse && (
                <div className={"sticky left-0 top-0 bg-calfrac-gray py-4"}>
                    <h3 className="text-lg font-semibold text-white">
                        {resources.Program} {program?.ProgramNumber}-
                        {program?.Revision}
                    </h3>
                    <h4
                        className={classNames(
                            "text-md",
                            isPageEditable
                                ? "text-calfrac-green-300"
                                : "text-calfrac-gray-400",
                        )}
                    >
                        {program?.WorkflowStatusId
                            ? ProgramStatusResources[
                                  program.WorkflowStatusId as ProgramWorkflowStatusId
                              ]
                            : resources.Loading}
                    </h4>
                    <div className={"py-2"}>
                        {program?.ProgramNumber &&
                            program.CreditLimitFlag === true && (
                                <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20">
                                    {resources.CreditCheckRequired}
                                </span>
                            )}
                        {program?.ProgramNumber &&
                            program.CreditLimitFlag === null && (
                                <span className="inline-flex items-center rounded-md bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-500 ring-1 ring-inset ring-yellow-400/20">
                                    {resources.CreditDataNotAvailable}
                                </span>
                            )}
                    </div>
                </div>
            )}
            {collapse && (
                <Popover className="relative">
                    <Popover.Button>
                        <InformationCircleIcon className="mt-4 h-6 w-6 shrink-0 stroke-calfrac-green-300 " />
                    </Popover.Button>

                    <Popover.Panel className="fixed z-[999] -mt-[2.875rem] ml-12 rounded-r-md bg-calfrac-gray p-4 drop-shadow-md">
                        <div className={"sticky left-0 top-0 bg-calfrac-gray "}>
                            <h3 className="text-lg font-semibold text-white">
                                {resources.Program} {program?.ProgramNumber}-
                                {program?.Revision}
                            </h3>
                            <h4
                                className={classNames(
                                    "text-md",
                                    isPageEditable
                                        ? "text-calfrac-green-300"
                                        : "text-calfrac-gray-400",
                                )}
                            >
                                {program?.WorkflowStatusId
                                    ? ProgramStatusResources[
                                          program.WorkflowStatusId as ProgramWorkflowStatusId
                                      ]
                                    : resources.Loading}
                            </h4>
                            <div className={"py-2"}>
                                {program?.ProgramNumber &&
                                    program.CreditLimitFlag === true && (
                                        <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20">
                                            {resources.CreditCheckRequired}
                                        </span>
                                    )}
                                {program?.ProgramNumber &&
                                    program.CreditLimitFlag === null && (
                                        <span className="inline-flex items-center rounded-md bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-500 ring-1 ring-inset ring-yellow-400/20">
                                            {resources.CreditDataNotAvailable}
                                        </span>
                                    )}
                            </div>
                        </div>
                    </Popover.Panel>
                </Popover>
            )}

            <nav className="flex max-h-min flex-1 flex-col">
                <ul className="flex flex-col gap-y-7">
                    <li>
                        {!collapse && <MenuWide navigation={navigation} />}
                        {collapse && <MenuNarrow navigation={navigation} />}
                    </li>
                </ul>
            </nav>
            <div className={"mt-auto"}>
                <button
                    className={
                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:text-white"
                    }
                    onClick={() => setCollapse(!collapse)}
                >
                    {!collapse && (
                        <>
                            <ChevronLeftIcon className={"h-5 w-5 shrink-0"} />
                            {resources.Collapse}
                        </>
                    )}

                    {collapse && (
                        <ChevronRightIcon className={"h-5 w-5 shrink-0"} />
                    )}
                </button>
            </div>
        </div>
    );
}

// export default ProgramSideBar;

import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { resources } from "utils/resources";

import GeneralErrorPage from "./GeneralErrorPage";

const Page: React.FC = () => {
    useEffect(() => {
        return () => toast.dismiss();
    }, []);

    return (
        <GeneralErrorPage
            code={401}
            title={resources.YouAreNotAuthorizedToAccessThisResource}
            subtitle={
                resources.YouAreNotAuthorizedToAccessThisResourceSubMessage
            }
        />
    );
};

export default Page;

import React from "react";
import { toast } from "react-toastify";
import process from "process";

import { IActivatorLoadingController } from "types/generated/Calfrac/Jet/Web/Controllers/IActivatorLoadingController";
import { IAzureBlobController } from "types/generated/Calfrac/Jet/Web/Controllers/IAzureBlobController";
import { ICalculationController } from "types/generated/Calfrac/Jet/Web/Controllers/ICalculationController";
import { IChemicalLoadingController } from "types/generated/Calfrac/Jet/Web/Controllers/IChemicalLoadingController";
import { IClientOutputsController } from "types/generated/Calfrac/Jet/Web/Controllers/IClientOutputsController";
import { IDesignResultsController } from "types/generated/Calfrac/Jet/Web/Controllers/IDesignResultsController";
import { IDiagnosticsController } from "types/generated/Calfrac/Jet/Web/Controllers/IDiagnosticsController";
import { IErrorController } from "types/generated/Calfrac/Jet/Web/Controllers/IErrorController";
import { IFeatureFlagsController } from "types/generated/Calfrac/Jet/Web/Controllers/IFeatureFlagsController";
import { IFilterController } from "types/generated/Calfrac/Jet/Web/Controllers/IFilterController";
import { IFlowConfigurationController } from "types/generated/Calfrac/Jet/Web/Controllers/IFlowConfigurationController";
import { IFluidsEnergyProppantsCementsController } from "types/generated/Calfrac/Jet/Web/Controllers/IFluidsEnergyProppantsCementsController";
import { IHelpRequestController } from "types/generated/Calfrac/Jet/Web/Controllers/IHelpRequestController";
import { IJobController } from "types/generated/Calfrac/Jet/Web/Controllers/IJobController";
import { IPerforationController } from "types/generated/Calfrac/Jet/Web/Controllers/IPerforationController";
import { IPremixedChemicalController } from "types/generated/Calfrac/Jet/Web/Controllers/IPremixedChemicalController";
import { IPricingController } from "types/generated/Calfrac/Jet/Web/Controllers/IPricingController";
import { IProgramApprovalController } from "types/generated/Calfrac/Jet/Web/Controllers/IProgramApprovalController";
import { IProgramAttachmentController } from "types/generated/Calfrac/Jet/Web/Controllers/IProgramAttachmentController";
import { IProgramController } from "types/generated/Calfrac/Jet/Web/Controllers/IProgramController";
import { IRequestController } from "types/generated/Calfrac/Jet/Web/Controllers/IRequestController";
import { IReservoirPropertiesController } from "types/generated/Calfrac/Jet/Web/Controllers/IReservoirPropertiesController";
import { IRiskAssessmentController } from "types/generated/Calfrac/Jet/Web/Controllers/IRiskAssessmentController";
import { IScheduleController } from "types/generated/Calfrac/Jet/Web/Controllers/IScheduleController";
import { ITaskController } from "types/generated/Calfrac/Jet/Web/Controllers/ITaskController";
import { IWellConfigController } from "types/generated/Calfrac/Jet/Web/Controllers/IWellConfigController";
import { IWellDataController } from "types/generated/Calfrac/Jet/Web/Controllers/IWellDataController";
import { IWorkbookController } from "types/generated/Calfrac/Jet/Web/Controllers/IWorkbookController";
import { IWorkflowController } from "types/generated/Calfrac/Jet/Web/Controllers/IWorkflowController";

import getAuthToken from "./authToken";
import { ErrorMessages } from "./enumerations";
import { resources } from "./resources";

type Urls =
    | `/api/ActivatorLoading/${keyof IActivatorLoadingController}`
    | `/api/AzureBlob/${keyof IAzureBlobController}`
    | `/api/ChemicalLoading/${keyof IChemicalLoadingController}`
    | `/api/ClientOutputs/${keyof IClientOutputsController}`
    | `/api/DesignResults/${keyof IDesignResultsController}`
    | `/api/Diagnostics/${keyof IDiagnosticsController}`
    | `/api/Error/${keyof IErrorController}`
    | `/api/Filter/${keyof IFilterController}`
    | `/api/FlowConfiguration/${keyof IFlowConfigurationController}`
    | `/api/HelpRequest/${keyof IHelpRequestController}`
    | `/api/FeatureFlags/${keyof IFeatureFlagsController}`
    | `/api/FluidsEnergyProppantsCements/${keyof IFluidsEnergyProppantsCementsController}`
    | `/api/Perforation/${keyof IPerforationController}`
    | `/api/PremixedChemical/${keyof IPremixedChemicalController}`
    | `/api/Pricing/${keyof IPricingController}`
    | `/api/ProgramApproval/${keyof IProgramApprovalController}`
    | `/api/ProgramAttachment/${keyof IProgramAttachmentController}`
    | `/api/Program/${keyof IProgramController}`
    | `/api/Schedule/${keyof IScheduleController}`
    | `/api/Request/${keyof IRequestController}`
    | `/api/Job/${keyof IJobController}`
    | `/api/ReservoirProperties/${keyof IReservoirPropertiesController}`
    | `/api/RiskAssessment/${keyof IRiskAssessmentController}`
    | `/api/Task/${keyof ITaskController}`
    | `/api/WellConfig/${keyof IWellConfigController}`
    | `/api/WellData/${keyof IWellDataController}`
    | `/api/Workflow/${keyof IWorkflowController}`
    | `/api/Workbook/${keyof IWorkbookController}`
    | `/api/Calculation/${keyof ICalculationController}`;
// Make an external request to JET API.

export type JetApiUrls = `${Urls}/${string}` | `${Urls}?${string}` | Urls;

export interface FileInfo {
    blob: Blob;
    name: string;
}

/**
 * Fetches data from the JET API.
 *
 * This prefixes the url with `/api` and then appends the query string.
 * This method injects the access token into the request.
 *
 * @param url
 * @param init
 * @param params
 * @param manuallyEncodedString
 * @param isFile
 * @param skipJSONParse
 */

export const fetchJET = async <T,>(
    url?: JetApiUrls,
    params?: ConstructorParameters<typeof URLSearchParams>[0],
    manuallyEncodedString?: string,
    init?: RequestInit,
    isFile?: boolean,
    skipJSONParse?: boolean,
): Promise<T> => {
    // The user may have passed in a URL with a query string.
    // We'll parse it to preserve those params.
    // We use "http://localhost.com" as fake domain to ensure that the URL is parsed correctly.
    const parsedUrl = new URL(`http://localhost.com${url}`);
    const existingQueryString = parsedUrl.searchParams.toString();

    // This ensures that the URL does not end with a trailing slash.
    // trailing slashes are not supported by the JET API.
    const objectParametersString = new URLSearchParams(params).toString();

    // Combine the existing query string with the new one.
    const queryString = [
        objectParametersString,
        existingQueryString,
        manuallyEncodedString,
    ]
        .filter(Boolean)
        .join("&");

    const token = await getAuthToken();

    const { headers, ...rest } = init || {};

    const apiHost = process.env.REACT_APP_API_HOST ?? "";
    let fileName = "";
    const res = fetch(`${apiHost}${parsedUrl.pathname}?${queryString}`, {
        ...rest,
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    })
        .then(async (res) => {
            const version = res.headers.get("X-API-VERSION");

            // update a cookie with the latest version
            // this is used by the client to ensure that the server version matches the client version
            document.cookie = `X-API-VERSION=${version}; path=/; SameSite=Strict; Secure;`;

            if (res.status === 400) return res.json(); // A possible validation error has occured
            if (res.status === 404) throw new Error(ErrorMessages.NotFound);
            if (res.status >= 500 && res.status < 600)
                throw new Error(ErrorMessages.InternalServerError);
            if (res.status !== 200 && !res.ok) throw new Error(res.statusText);

            if (isFile) {
                // Parsing header to find file name
                const header = res.headers.get("Content-Disposition");
                const parts = header?.split(";");

                fileName =
                    parts?.[1]?.split("=")?.[1]?.split('"')?.join("") ??
                    "Unknown File.pdf";

                // print all headers
                res.headers.forEach((value, key) => {
                    console.log(`${key}: ${value}`);
                });

                return res.blob();
            }

            if (skipJSONParse) return res;

            return res.json();
        })
        .catch((err: Error) => {
            // If error is "user abort" then ignore. This is caused by the user navigating away from the page before the request is complete.
            if (err.name === "AbortError") return;

            if (err.message === ErrorMessages.NotFound) {
                window.location.replace("/Error/NotFound");
            }

            if (err.message === ErrorMessages.InternalServerError) {
                console.error("ERROR", err);
                toast.dismiss();
                toast.error(
                    <div className={"grid"}>{resources.ErrorAPI}</div>,
                    {
                        autoClose: false,
                    },
                );
            }
        });
    if (isFile) {
        const data = await res;
        console.log("data", data);
        return { blob: await res, name: fileName } as T;
    }
    return res;
};

export const saveJET = async <T,>(
    url: JetApiUrls,
    params?: ConstructorParameters<typeof URLSearchParams>[0],
    body?: string | FormData,
    manuallyEncodedString?: string,
    init?: RequestInit,
    skipJSONParse: boolean = true,
): Promise<T> => {
    const initParams = {
        ...init,
        method: "POST",
        body: body,
    };

    return fetchJET(
        url,
        params,
        manuallyEncodedString,
        initParams,
        false,
        skipJSONParse,
    );
};

export const downloadJET = (
    url: JetApiUrls,
    params?: ConstructorParameters<typeof URLSearchParams>[0],
    downloadCompleted?: () => void,
): void => {
    // Getting file blob
    fetchJET<FileInfo>(url, params, undefined, undefined, true)
        .then((fileInfo) => {
            // Downloading the file locally
            console.log("fileInfo", fileInfo);
            const url = window.URL.createObjectURL(fileInfo.blob);
            let alink = document.createElement("a");
            alink.target = "_blank";
            alink.href = url;
            alink.download = fileInfo.name;
            alink.click();
        })
        .finally(() => {
            downloadCompleted?.();
        });
};

import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { ProgramWorkflowStatusId } from "types/generated/Calfrac/Jet/Core/Models/ProgramWorkflowStatusId";

import RenderActionCell from "components/Cells/RenderActionCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardTextArea from "components/shared/GenericCustomComponents/StandardTextArea";
import AttachmentTable from "components/Tables/Task/AttachmentTable";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

const columnHelper = createColumnHelper<any>();

const Page: React.FC = () => {
    // Constants
    const url = "/api/ProgramAttachment/EditProgramAttachments";

    // Url params
    const { programId, program } = useProgramParams();

    const isPageEditable = useMemo(() => {
        return (
            program?.WorkflowStatusId !== ProgramWorkflowStatusId.Cancelled &&
            program?.WorkflowStatusId !== ProgramWorkflowStatusId.Revised &&
            program?.WorkflowStatusId !==
                ProgramWorkflowStatusId.SentToOperations
        );
    }, [program]);

    return (
        <StandardForm
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            asForm
        >
            <FormSectionDivider>
                <FormSection label={resources.RevisionComment}>
                    <StandardTextArea
                        name={"RevisionComment"}
                        disabled={!isPageEditable}
                        className={"col-span-full"}
                    />
                </FormSection>
                <FormSection
                    label={resources.OperationNotificationsAttachments}
                >
                    <AttachmentTable
                        name={"ProgramAttachments"}
                        canAddNewFile={true}
                    />
                </FormSection>
                <FormSection label={resources.InheritedAttachments}>
                    <AttachmentTable
                        name={"InheritedAttachments"}
                        canDelete={false}
                        canAddNewFile={false}
                        columns={[
                            columnHelper.accessor((row) => row.RequestNumber, {
                                id: "RequestNumber",
                                size: 80,
                                header: resources.Request,
                                cell: (props) => (
                                    <RenderActionCell
                                        {...props}
                                        to={`/Request/EditRequest/${props.row.original.RequestId}/0`}
                                    />
                                ),
                            }),
                            columnHelper.accessor((row) => row.ProgramName, {
                                id: "ProgramName",
                                size: 80,
                                header: resources.Program,
                                cell: TextReadOnly,
                            }),
                        ]}
                        columnOrder={["RequestNumber", "ProgramName"]}
                    />
                </FormSection>
                <FormSection label={resources.ProgramAttachments}>
                    <AttachmentTable
                        name={"NonOpsProgramAttachments"}
                        canAddNewFile={true}
                    />
                </FormSection>
            </FormSectionDivider>
        </StandardForm>
    );
};

export default Page;

import React from "react";
import { ErrorOption, FieldErrors, useFormContext } from "react-hook-form";
import { XCircleIcon } from "@heroicons/react/20/solid";

import { H3 } from "components/Headers";

import { resources } from "utils/resources";

type Props = {
    errorMsg?: string[] | string;
};

export const StandardValidationSummary: React.FC<Props> = (props: Props) => {
    if (!props.errorMsg) return <></>;

    const errors = !Array.isArray(props.errorMsg)
        ? props.errorMsg.split("\n")
        : props.errorMsg;

    return (
        <div className="bg-red rounded-md p-2">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        className="h-5 w-5 text-red-600"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <H3 className="text-red-600">
                        {`There were ${errors.length} errors with your submission`}
                    </H3>
                    <div className="mt-2 text-sm text-red-700">
                        <ul className="list-disc space-y-1 pl-5 text-red-600">
                            {Object.entries(errors)?.map((error, idx) => (
                                <li key={idx}>{JSON.stringify(error)}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ErrorItem: React.FC<{
    name: string;
    message?: string;
}> = ({ name, message }) => {
    const rawName = name?.replace(/Id$/g, "");
    let safeName =
        resources[rawName as unknown as keyof typeof resources] ?? rawName;

    if (safeName === "NtsUwi" || safeName === "DlsUwi") {
        safeName = resources.Uwi;
    }

    const messages = message?.split("\n") ?? ["Unknown error"];

    return (
        <>
            {messages.map((msg, index) => (
                <li key={index}>
                    {name !== "ProgramToCopyCreatedDate" ? (
                        <span className={"mr-2 font-medium text-red-800"}>
                            {safeName}
                        </span>
                    ) : null}
                    {msg}
                </li>
            ))}
        </>
    );
};

export const ValidationSummary: React.FC<{
    name?: string;
    externalErrors?: { [key: string]: ErrorOption };
}> = ({ name, externalErrors }) => {
    let {
        formState: { errors },
    } = useFormContext();

    // In case we want to pass field errors not present in the form
    if (externalErrors) {
        errors = externalErrors as FieldErrors;
    }

    if (!errors || Object.keys(errors).length === 0) return <></>;
    if (name && !Object.keys(errors).includes(name)) return <></>;

    if (name) {
        errors = { [name]: errors[name] };
    }
    return (
        <div className="mt-4 rounded-md border border-red-800 bg-red-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                        There were errors with your submission
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                        <ul className="list-disc space-y-1 pl-5">
                            {Object.entries(errors)?.map((error, idx) => {
                                const errorObject = error[1];

                                const isArray = Array.isArray(errorObject);

                                if (!isArray) {
                                    return (
                                        <ErrorItem
                                            key={idx}
                                            name={error[0]}
                                            message={
                                                errorObject?.message as string
                                            }
                                        />
                                    );
                                }
                                return (
                                    <li>
                                        <>
                                            <span
                                                className={
                                                    "font-medium text-red-800"
                                                }
                                            >
                                                {name}
                                            </span>
                                            <ul className="list-disc space-y-1 pl-5">
                                                {errorObject?.map(
                                                    (row, idx) => (
                                                        <>
                                                            <span
                                                                className={
                                                                    "font-medium text-red-800"
                                                                }
                                                            >
                                                                {`Row: ${idx + 1}`}
                                                            </span>
                                                            <ul
                                                                className={
                                                                    "list-disc space-y-1 pl-5"
                                                                }
                                                            >
                                                                {row.message && (
                                                                    <ErrorItem
                                                                        name={
                                                                            name ??
                                                                            "hey"
                                                                        }
                                                                        message={
                                                                            row.message
                                                                        }
                                                                    />
                                                                )}
                                                                {!row?.message &&
                                                                    Object.entries(
                                                                        row,
                                                                    )?.map(
                                                                        (
                                                                            rowError,
                                                                            idx,
                                                                        ) => (
                                                                            <ErrorItem
                                                                                key={
                                                                                    idx
                                                                                }
                                                                                name={
                                                                                    rowError[0]
                                                                                }
                                                                                message={
                                                                                    (
                                                                                        rowError[1] as {
                                                                                            message: string;
                                                                                        }
                                                                                    )
                                                                                        ?.message as string
                                                                                }
                                                                            />
                                                                        ),
                                                                    )}
                                                            </ul>
                                                        </>
                                                    ),
                                                )}
                                            </ul>
                                        </>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StandardValidationSummary;

import React from "react";

import { BaseProps } from "components/shared/CustomGridToolbar/StandardEditGridToolbar";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";

import { resources } from "utils/resources";

const EditNotes: React.FC<BaseProps> = ({
    isVisible = false,
    ...props
}: BaseProps) => {
    if (!isVisible) return <></>;
    return (
        <StandardAnchorLink
            text={resources.EditNotes.toUpperCase()}
            onClick={props.onClick}
        />
    );
};

export default EditNotes;

import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import { formatNumber } from "@progress/kendo-intl";
import {
    GridColumn,
    GridDataStateChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { IProgramFilterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Program/IProgramFilterViewModel";

import { H3 } from "components/Headers";
import TableCell from "components/kendoExtensions/gridExtensions/TableCell";
import TableLink from "components/kendoExtensions/gridExtensions/TableLink";
import StandardIndexGrid from "components/kendoExtensions/grids/StandardIndexGrid";
import { PageHeaderBar } from "components/Layout";
import PDFCell from "components/shared/CustomGridCells/PDFCell";
import {
    CountryFilterCell,
    CTTreatmentObjectiveFilterCell,
    ServiceLineFilterCell,
} from "components/shared/Filter/FilterDropdowns";

import {
    COLUMN_INFO_ICON_PROPS,
    COLUMN_PROPS,
    DATE_COLUMN_PROPS,
} from "const/columns";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

import { AddListToQueryData } from "views/Task";
import FilterToolbar from "views/Task/FilterToolbar";

import EditProgramFilter from "./_EditProgramFilter";

export const DEFAULT_SORT: SortDescriptor[] = [
    { field: "CreatedDate", dir: "desc" },
    { field: "ProgramNumberAndRevision", dir: "desc" },
];

const Page = (props: {
    title?: string;
    jobId?: string;
    serverFilter?: Array<FilterDescriptor>;
    jobCountry?: number;
}) => {
    const { setValue } = useFormContext();
    const filters = useWatch({
        name: "Program.CurrentFilter",
    }) as IProgramFilterViewModel;

    const defaultFilterUrl: JetApiUrls = "/api/Program/Index";
    // Load the default filter.
    const { data: defaultFilter } = useQuery<IProgramFilterViewModel>({
        queryKey: ["Program", defaultFilterUrl],
        queryFn: async () => {
            return await fetchJET(defaultFilterUrl);
        },
        refetchOnMount: "always",
    });

    useEffect(() => {
        setValue("Program", defaultFilter);
    }, [defaultFilter, setValue]);

    const query = useMemo(() => {
        const newFilters: FilterDescriptor[] = [];

        if (filters?.CountryId)
            newFilters.push({
                field: "CountryId",
                operator: "eq",
                value: filters.CountryId,
            });

        const queryData: { [key: string]: any } = {};

        if (filters?.Operator) queryData["Operator"] = filters.Operator;
        if (filters?.Consultant) queryData["Consultant"] = filters.Consultant;
        if (filters?.WellName) queryData["WellName"] = filters.WellName;
        if (filters?.WellName) queryData["WellName"] = filters.WellName;
        if (filters?.AccountManagerId)
            queryData["AccountManagerId"] = filters.AccountManagerId;
        if (filters?.PreparedById)
            queryData["PreparedById"] = filters.PreparedById;

        if (filters?.ProjectDescription)
            queryData["ProjectDescription"] = filters.ProjectDescription;

        if (props.jobId) {
            newFilters.push({
                field: "JobId",
                value: props.jobId,
                operator: "eq",
            });
        }

        AddListToQueryData(queryData, filters, "ServiceLine");
        AddListToQueryData(queryData, filters, "Formations");
        AddListToQueryData(queryData, filters, "Field");
        AddListToQueryData(queryData, filters, "CompletionTechnologies");
        AddListToQueryData(queryData, filters, "CTTreatmentObjective");
        AddListToQueryData(queryData, filters, "FluidSystems");

        return {
            filters: {
                logic: "and",
                filters: newFilters,
            } as GridDataStateChangeEvent["dataState"]["filter"],
            queryData,
        };
    }, [filters, props.jobId]);

    return (
        <>
            {props.title ? (
                <H3 className={"pb-1"}>{props.title}</H3>
            ) : (
                <PageHeaderBar />
            )}
            <StandardIndexGrid
                path={`/api/Program/GetProgramIndexItems`}
                externalFilters={query.filters}
                queryData={query.queryData}
                initialSort={DEFAULT_SORT}
                serverFilter={props.serverFilter}
            >
                <GridToolbar>
                    <FilterToolbar
                        Popover={EditProgramFilter}
                        name={"Program"}
                        savedFilterUrl={"/api/Program/GetSavedFilters"}
                    />
                </GridToolbar>
                <GridColumn
                    field={"CreatedDate"}
                    title={resources.CreatedDate}
                    locked
                    {...DATE_COLUMN_PROPS}
                    width={160}
                />
                {!props.jobId && (
                    <GridColumn
                        field={"JobNumber"}
                        title={resources.JobNumber}
                        locked
                        {...COLUMN_PROPS}
                        cell={(props) => {
                            return (
                                <TableLink
                                    {...props}
                                    url={`/Job/EditJob/${props.dataItem.JobId}`}
                                    value={props.dataItem.JobNumber}
                                />
                            );
                        }}
                        width={100}
                    />
                )}
                <GridColumn
                    field={"RequestNumber"}
                    title={resources.Request}
                    cell={(props) => (
                        <TableLink
                            url={`/Request/EditRequest/${props.dataItem.RequestId}/${props.dataItem.CountryId}`}
                            value={props.dataItem.RequestNumber}
                            {...props}
                            id={"ProgramIndex-ProgramRequestNumber"}
                        />
                    )}
                    locked
                    {...COLUMN_PROPS}
                    width={110}
                />
                <GridColumn
                    field={"ProgramNumberAndRevision"}
                    title={resources.ProgramNumberAndRevision}
                    locked
                    cell={(props) => (
                        <TableLink
                            url={`/Program/EditProgramData/${props.dataItem.Id}`}
                            value={props.dataItem.ProgramNumberAndRevision}
                            {...props}
                        />
                    )}
                    {...COLUMN_PROPS}
                    width={120}
                />
                <GridColumn
                    field={"ProgramWorkflowStatus"}
                    title={resources.ProgramWorkflowStatus}
                    locked
                    {...COLUMN_PROPS}
                    width={170}
                />
                <GridColumn
                    width={50}
                    locked
                    title={"PDF"}
                    field={"ClientOutputsUrl"}
                    cell={PDFCell}
                    filterable={false}
                    sortable={false}
                />
                <GridColumn
                    field={"CountryCode"}
                    title={resources.Country}
                    width={70}
                    filterCell={(props) => (
                        <CountryFilterCell
                            gridFilterCellProps={props}
                            url={"/api/Program/CountryCode"}
                        />
                    )}
                />
                <GridColumn
                    field={"ServiceLine"}
                    title={resources.ProgramType}
                    width={100}
                    filterCell={(props) => (
                        <ServiceLineFilterCell gridFilterCellProps={props} />
                    )}
                />
                <GridColumn
                    field={"ProgramTreatmentObjectives"}
                    title={resources.TreatmentObjectives}
                    {...COLUMN_PROPS}
                    filterCell={CTTreatmentObjectiveFilterCell}
                />
                <GridColumn
                    field={"OperatorName"}
                    title={
                        props.jobCountry === CountryId.Argentina
                            ? resources.PrimaryOperator
                            : resources.Operator
                    }
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"WellIdentifier"}
                    title={resources.WellIdentifier}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"WellName"}
                    title={resources.WellName}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"PreparedByName"}
                    title={resources.PreparedBy}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"ReviewedBy1Name"}
                    title={resources.ReviewedBy1}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"AccountManagerName1"}
                    title={resources.AccountManager1}
                    {...COLUMN_INFO_ICON_PROPS}
                />
                <GridColumn
                    field={"AccountManagerName2"}
                    title={resources.AccountManager2}
                    {...COLUMN_INFO_ICON_PROPS}
                />
                <GridColumn
                    field={"RequestProjectDescription"}
                    title={resources.PadName}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"ConsultantName"}
                    title={resources.Consultant}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"ProvinceStateName"}
                    title={resources.ProvinceState}
                    {...COLUMN_PROPS}
                    width={155}
                />
                <GridColumn
                    field={"District"}
                    title={resources.District}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"FieldName"}
                    title={resources.Field}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    {...COLUMN_PROPS}
                    width={120}
                    field={"TotalProppant"}
                    title={resources.ProppantTotal}
                    filter={"numeric"}
                    cell={(props) => (
                        <TableCell {...props}>
                            <span>
                                {formatNumber(
                                    props.dataItem.TotalProppant,
                                    "n0",
                                )}{" "}
                                {props.dataItem.TotalProppantUnitSymbol}
                            </span>
                        </TableCell>
                    )}
                />
                <GridColumn
                    field={"ApprovedDate"}
                    title={resources.ApprovedDate}
                    {...DATE_COLUMN_PROPS}
                    width={140}
                />
                <GridColumn
                    field={"CopiedFromProgramNumber"}
                    title={resources.CopiedFrom}
                    {...COLUMN_PROPS}
                    width={100}
                    cell={(props) => (
                        <TableLink
                            url={`/Program/EditProgramData/${props.dataItem.Id}`}
                            value={props.dataItem.CopiedFromProgramNumber}
                            {...props}
                        />
                    )}
                />
            </StandardIndexGrid>
        </>
    );
};

export default Page;

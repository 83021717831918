//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BackCalculatePumpScheduleStageTarget {
	DownholeConcentrationStart = 0,
	DownholeConcentrationEnd = 1,
	ProppantStage = 2,
	ProppantStageCumulative = 3,
	N2Stage = 4,
	CO2Stage = 5,
	SecondaryFluidSystemStage = 6,
	DownholeRate = 7,
	DownholeVolume = 8,
	DownholeFoamQuality = 9,
	DownholeTotal = 10
}

export const makeSequentialField: (
    dataIn: Record<string, number>[],
    key: string,
) => (Record<string, number>[] & { [key: string]: number })[] = (
    dataIn: Record<string, number>[],
    key: string,
) => {
    let dataOut: any[] = dataIn?.map((row, index) => {
        return { ...row, [key]: index + 1 };
    });
    return dataOut;
};

export const getLargestField = (dataIn: any[], key: string) => {
    let largest = 0;

    for (let i = 0; i < dataIn?.length; i++) {
        let value = dataIn?.[i]?.[key];

        if (value && value > largest) {
            largest = value;
        }
    }

    return largest;
};

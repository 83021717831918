import { IsGeneratingPdfT } from "types/pdf";

import { fetchJET } from "./fetchJet";

const pdfGenerationProgressUrl = "/api/ClientOutputs/IsGenerating";

export const getPDFGenerationStatus = async (programId?: string) => {
    if (!programId) {
        return;
    }
    return await fetchJET(pdfGenerationProgressUrl, { programId });
};

export const initialPDFGenerationStatus = (
    programId: string | undefined,
    callback: (isPDFStillGenerating: boolean) => void,
) => {
    getPDFGenerationStatus(programId).then((data) => {
        const pdfData = data as IsGeneratingPdfT;
        const { IsGenerating: isPDFStillGenerating } = pdfData;
        callback(isPDFStillGenerating);
    });
};

export const pollPDFGenerationStatus = (
    programId: string | undefined,
    callback: (
        isPDFStillGenerating: boolean,
        error: boolean,
        errorMsg: string,
        blobUrl: string | null,
    ) => void,
) => {
    getPDFGenerationStatus(programId).then((data) => {
        const pdfData = data as IsGeneratingPdfT;
        const {
            IsGenerating: isPDFStillGenerating,
            Error: error,
            ErrorMessage: errorMsg,
            BlobUrl: blobUrl,
        } = pdfData;
        callback(isPDFStillGenerating, error, errorMsg, blobUrl);
    });
};

import React, { ReactNode } from "react";
import { useMsal } from "@azure/msal-react";

const AdminView: React.FC<{ children: ReactNode }> = ({ children }) => {
    // Determine if the msal user has a role 'JET.ADMIN'
    // If not, return null

    const msal = useMsal();
    const result = msal.instance.getActiveAccount();
    const roles = result?.idTokenClaims?.roles;

    if (!roles?.includes("JET.ADMIN")) return null;

    return <>{children}</>;
};

export default AdminView;

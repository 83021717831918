import React, { memo, useMemo, useReducer } from "react";
import { useWatch } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditBaseFluidViewModel } from "types/generated/Calfrac/Jet/Web/Models/ChemicalLoading/IEditBaseFluidViewModel";

import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { findFieldFormatNullable } from "utils/findFieldFormat";
import { resources } from "utils/resources";

type BaseFluidTableProps = {
    name: string;
};

const columnHelper = createColumnHelper<IEditBaseFluidViewModel>();

const BaseFluidTable: React.FC<BaseFluidTableProps> = ({
    name,
}: BaseFluidTableProps) => {
    const { isPageEditable, program } = useProgramParams();

    const data = useWatch({ name });
    const units = useUnits();

    // Updating this render hash will rerender all the rows. This is useful for data changes like copying and pasting from excel.
    const [renderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    const defaultColumns = useMemo(
        () => [
            columnHelper.display({
                id: "Delete",
                size: 20,
                cell: DeleteButton,
            }),
            columnHelper.accessor((row) => row.BaseFluid, {
                id: "BaseFluid",
                header: resources.BaseFluid,
                size: 200,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.Concentration, {
                id: "Concentration",
                header: `${resources.Percentage} ${units.percentageUnit}`,
                size: 200,
                cell: (props) => (
                    <FormattedNumericCell
                        {...props}
                        inViewFormat={findFieldFormatNullable(
                            "",
                            "FluidSystems.BaseFluids.Concentration",
                            program?.CountryId,
                        )}
                    />
                ),
            }),
        ],
        [program?.CountryId, units.percentageUnit],
    );

    return (
        <BaseTable
            columns={defaultColumns}
            name={name}
            renderHash={renderHash}
            defaultRecord={{
                BaseFluid: {},
                Concentration: data?.length <= 0 ? 100.0 : 0.0,
            }}
            meta={{
                disabled: !isPageEditable,
                canDelete: isPageEditable,
                urls: {
                    BaseFluid: `/api/FluidsEnergyProppantsCements/BaseFluid?countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                },
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(BaseFluidTable);

import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import {
    GridColumn,
    GridDataStateChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";
import { TaskStatusId } from "types/generated/Calfrac/Jet/Core/Models/TaskStatusId";

import { ITaskFilterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/ITaskFilterViewModel";

import { H3 } from "components/Headers";
import TableLink from "components/kendoExtensions/gridExtensions/TableLink";
import StandardIndexGrid from "components/kendoExtensions/grids/StandardIndexGrid";
import { PageHeaderBar } from "components/Layout";
import { BooleanFilterCell } from "components/shared/CustomGridCells/BooleanFilterCell";
import PDFCell from "components/shared/CustomGridCells/PDFCell";
import {
    CountryFilterCell,
    CTTreatmentObjectiveFilterCell,
    ServiceLineFilterCell,
    TaskStatusFilterCell,
} from "components/shared/Filter/FilterDropdowns";

import {
    COLUMN_INFO_ICON_PROPS,
    COLUMN_PROPS,
    DATE_COLUMN_PROPS,
} from "const/columns";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

import FilterToolbar from "views/Task/FilterToolbar";

import EditTaskFilter from "./_EditTaskFilter";

const DEFAULT_SORT: SortDescriptor[] = [{ field: "CreatedOn", dir: "desc" }];

export const AddListToQueryData = <T,>(
    target: Record<string, unknown>,
    source: T,
    key: keyof T,
) => {
    if (!source || !source?.[key] || !Array.isArray(source?.[key])) return;

    const data = source?.[key] as unknown[];

    let i = 0;
    for (const row of data) {
        target[`${String(key)}[${i}]`] = row;
        i++;
    }
};

const Page = (props: {
    title?: string;
    jobId?: string;
    jobCountry?: number;
}) => {
    const { setValue } = useFormContext();
    const filters = useWatch({
        name: "Task.CurrentFilter",
    }) as ITaskFilterViewModel;

    const defaultFilterUrl: JetApiUrls = "/api/Task/Index";
    // Load the default filter.
    const { data: defaultFilter } = useQuery<ITaskFilterViewModel>({
        queryKey: ["Task", defaultFilterUrl],
        queryFn: async () => {
            return await fetchJET(defaultFilterUrl);
        },
        refetchOnMount: "always",
    });

    useEffect(() => {
        setValue("Task", defaultFilter);
    }, [defaultFilter, setValue]);

    const query = useMemo(() => {
        const newFilters: FilterDescriptor[] = [];

        if (filters?.TaskStatusId)
            newFilters.push({
                field: "TaskStatusId",
                operator: "eq",
                value: filters.TaskStatusId,
            });

        if (filters?.CountryId)
            newFilters.push({
                field: "CountryId",
                operator: "eq",
                value: filters.CountryId,
            });

        if (filters?.ProjectDescription)
            newFilters.push({
                field: "ProjectDescription",
                operator: "eq",
                value: filters.ProjectDescription,
            });

        if (props.jobId)
            newFilters.push({
                field: "JobId",
                value: props.jobId,
                operator: "eq",
            });

        const queryData: { [key: string]: any } = {};
        AddListToQueryData(queryData, filters, "ServiceLine");
        AddListToQueryData(queryData, filters, "TaskName");
        AddListToQueryData(queryData, filters, "AccountManager");
        AddListToQueryData(queryData, filters, "CustomerCompany");
        AddListToQueryData(queryData, filters, "AssignedTo");

        return {
            filters: {
                logic: "and",
                filters: newFilters,
            } as GridDataStateChangeEvent["dataState"]["filter"],
            queryData,
        };
    }, [filters, props.jobId]);

    return (
        <>
            {props.title ? (
                <H3 className={"pb-1"}>{props.title}</H3>
            ) : (
                <PageHeaderBar />
            )}

            <StandardIndexGrid
                path={"/api/Task/GetTaskIndexItems"}
                externalFilters={query.filters}
                queryData={query.queryData}
                initialSort={DEFAULT_SORT}
            >
                <GridToolbar>
                    <FilterToolbar
                        name={"Task"}
                        Popover={EditTaskFilter}
                        savedFilterUrl={"/api/Task/GetSavedFilters"}
                    />
                </GridToolbar>
                <GridColumn
                    field={"Task"}
                    title={resources.Task}
                    {...COLUMN_PROPS}
                    locked
                    cell={(props) => {
                        let url: `/${string}`;

                        if (!props.dataItem.ProgramId) {
                            url = `/Request/EditRequest/${props.dataItem.RequestId}/${props.dataItem.CountryId}`;
                        } else {
                            url = `/Task/EditProgramTasks/${props.dataItem.ProgramId}`;
                        }
                        return (
                            <TableLink
                                {...props}
                                url={url}
                                value={props.dataItem.Task}
                                disabled={
                                    TaskStatusId[
                                        props.dataItem.TaskStatus as unknown as
                                            | "Closed"
                                            | "Open"
                                    ] === TaskStatusId.Closed
                                }
                            />
                        );
                    }}
                    width={150}
                />
                <GridColumn
                    field={"TaskStatus"}
                    title={resources.TaskStatus}
                    {...COLUMN_PROPS}
                    locked
                    width={110}
                    filterCell={TaskStatusFilterCell}
                />
                {!props.jobId && (
                    <GridColumn
                        field={"JobNumber"}
                        title={resources.JobNumber}
                        locked
                        {...COLUMN_PROPS}
                        cell={(props) => {
                            return (
                                <TableLink
                                    {...props}
                                    url={`/Job/EditJob/${props.dataItem.JobId}`}
                                    value={props.dataItem.JobNumber}
                                />
                            );
                        }}
                        width={100}
                    />
                )}
                <GridColumn
                    field={"ProgramNumber"}
                    title={resources.Program}
                    cell={(props) => {
                        let url: `/${string}`;

                        if (!props.dataItem.ProgramId) {
                            url = `/Request/EditRequest/${props.dataItem.RequestId}/${props.dataItem.CountryId}`;
                        } else {
                            url = `/Task/EditProgramTasks/${props.dataItem.ProgramId}`;
                        }

                        return (
                            <TableLink
                                {...props}
                                url={url}
                                value={props.dataItem.ProgramNumber}
                            />
                        );
                    }}
                    {...COLUMN_PROPS}
                    locked
                    width={125}
                />
                <GridColumn
                    width={50}
                    title={"PDF"}
                    cell={PDFCell}
                    filterable={false}
                    sortable={false}
                    locked
                />
                <GridColumn
                    field={"AssignedTo"}
                    title={resources.AssignTo}
                    locked
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"IsRushed"}
                    title={resources.IsRushed}
                    {...COLUMN_PROPS}
                    width={75}
                    filterCell={BooleanFilterCell}
                />
                <GridColumn
                    field={"ProgramDueDate"}
                    title={resources.ProgramDueDate}
                    {...DATE_COLUMN_PROPS}
                    width={140}
                />
                <GridColumn
                    field={"AccountManager"}
                    title={resources.AccountManager}
                    {...COLUMN_INFO_ICON_PROPS}
                />
                <GridColumn
                    field={"CustomerCompany"}
                    title={
                        props.jobCountry === CountryId.Argentina
                            ? resources.PrimaryOperator
                            : resources.Operator
                    }
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"Uwi"}
                    title={resources.UwiCanada}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"WellName"}
                    title={resources.WellNameUsa}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"ProjectDescription"}
                    title={resources.PadName}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"RequestNumber"}
                    title={resources.Request}
                    {...COLUMN_PROPS}
                    width={110}
                    cell={(props) => (
                        <TableLink
                            {...props}
                            url={`/Request/EditRequest/${props.dataItem.RequestId}/${props.dataItem.CountryId}`}
                            value={props.dataItem.RequestNumber}
                        />
                    )}
                />
                <GridColumn
                    field={"Field"}
                    title={resources.Field}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"Formation"}
                    title={resources.Formation}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"CustomerContact"}
                    title={resources.OperatorContact}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"Country"}
                    title={resources.Country}
                    {...COLUMN_PROPS}
                    width={70}
                    filterCell={(props) => (
                        <CountryFilterCell
                            gridFilterCellProps={props}
                            url={"/api/Task/CountryCode"}
                        />
                    )}
                />
                <GridColumn
                    field={"ServiceLine"}
                    title={resources.ProgramType}
                    {...COLUMN_PROPS}
                    width={95}
                    filterCell={(props) => (
                        <ServiceLineFilterCell gridFilterCellProps={props} />
                    )}
                />
                <GridColumn
                    field={"ProgramTreatmentObjectives"}
                    title={resources.TreatmentObjectives}
                    {...COLUMN_PROPS}
                    filterCell={CTTreatmentObjectiveFilterCell}
                />
                <GridColumn
                    field={"EstimatedJobStartDate"}
                    title={resources.EstimatedJobStartDate}
                    {...DATE_COLUMN_PROPS}
                    width={170}
                />
                <GridColumn
                    field={"CreatedOn"}
                    title={resources.CreatedOn}
                    {...DATE_COLUMN_PROPS}
                    width={155}
                />
                <GridColumn
                    field={"CreatedBy"}
                    title={resources.CreatedBy}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"OriginalProgramNumber"}
                    title={resources.OriginalProgram}
                    {...COLUMN_PROPS}
                    width={120}
                    cell={(props) => (
                        <TableLink
                            {...props}
                            url={`/Task/EditProgramTasks/${props.dataItem.OriginalProgramId}`}
                            value={props.dataItem.OriginalProgramNumber}
                        />
                    )}
                />
                <GridColumn
                    field={"RequestType"}
                    title={resources.RequestType}
                    {...COLUMN_PROPS}
                    width={120}
                />
            </StandardIndexGrid>
        </>
    );
};

export default Page;

import React, { memo, useEffect, useMemo, useReducer } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { IPricingItem } from "types/generated/Calfrac/Jet/Core/Models/Ops/IPricingItem";
import { PricingItemTypeId } from "types/generated/Calfrac/Jet/Core/Models/PricingItemTypeId";

import { IPricingItemAutomaticItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Pricing/IPricingItemAutomaticItemViewModel";
import { IPricingItemManualItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Pricing/IPricingItemManualItemViewModel";

import { PricingMetaT } from "types/Tables/Cells";

import CheckBox from "components/Cells/CheckBox";
import ComboBoxGrid from "components/Cells/ComboBoxGrid";
import DeleteButton from "components/Cells/DeleteButton";
import DescriptionDropDown from "components/Cells/DescriptionDropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import { OverrideCell } from "components/Cells/OverrideCell";
import TextCell from "components/Cells/TextCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import BaseTableDragDrop from "components/Tables/BaseTable/Tables/BaseTableDragDrop";

import { useProgram } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type PricingTableProps = {
    name: string;
    IsAdditionalPriceItem: boolean;
    descriptionEndPoint?: string;
    pricingItemTypeId?: PricingItemTypeId;
    title?: string;
    renderHash?: string;
    displayCustomerCodeColumn: boolean;
    displayPriceCodeColumn: boolean;
};

const columnHelper = createColumnHelper<
    IPricingItem &
        IPricingItemManualItemViewModel &
        IPricingItemAutomaticItemViewModel
>();

const PricingTable: React.FC<PricingTableProps> = (props) => {
    const data = useWatch({ name: props.name });
    const { setValue, getValues } = useFormContext();
    const { isPageEditable } = useProgram();

    const defaultColumns = useMemo(
        () => [
            columnHelper.display({
                id: "DragHandle",
                size: 30,
                maxSize: 30,
                cell: DeleteButton,
                enableHiding: true,
            }),
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Ignore, {
                id: "Ignore",
                header: resources.Ignore,
                size: 40,
                maxSize: 40,
                enableHiding: true,
                cell: (props) => (
                    <CheckBox
                        {...props}
                        applyReadOnlyStyle={props.row.original.Ignore}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.Description, {
                id: "Description",
                header: resources.Description,
                size: 300,
                cell: DescriptionDropDown,
            }),
            columnHelper.accessor((row) => row.FinanceItemNumber, {
                id: "FinanceItemNumber",
                header: resources.PriceCode,
                size: 100,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.CustomerCode, {
                id: "CustomerCode",
                header: resources.CustomerCode,
                size: 100,
                cell: (props) =>
                    props.row.original.Ignore ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <TextCell {...props} />
                    ),
            }),
            columnHelper.accessor((row) => row.Details, {
                id: "Details",
                header: resources.Details,
                size: 220,
                cell: (props) => (
                    <ComboBoxGrid
                        {...props}
                        applyReadOnlyStyle={props.row.original.Ignore}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.Quantity, {
                id: "Quantity",
                header: resources.Quantity,
                size: 100,
                cell: (props) => (
                    <QuantityCell
                        {...props}
                        applyReadOnlyStyle={props.row.original.Ignore}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.Unit, {
                id: "Unit",
                header: resources.Unit,
                size: 100,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.Comments, {
                id: "Comments",
                header: resources.Comments,
                size: 300,
                cell: (props) => (
                    <ComboBoxGrid
                        {...props}
                        applyReadOnlyStyle={props.row.original.Ignore}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.BookPrice, {
                id: "BookPrice",
                header: resources.BookPrice,
                size: 100,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Discount, {
                id: "Discount",
                header: resources.DiscountP,
                size: 100,
                cell: (props) => (
                    <FormattedNumericCell
                        {...props}
                        applyReadOnlyStyle={props.row.original.Ignore}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.FinalPrice, {
                id: "FinalPrice",
                header: resources.UnitPrice,
                size: 100,
                cell: (props) => (
                    <FormattedNumericCell
                        {...props}
                        applyReadOnlyStyle={props.row.original.Ignore}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.TotalPrice, {
                id: "TotalPrice",
                header: resources.TotalPrice,
                size: 100,
                cell: NumericReadOnly,
            }),
        ],
        [],
    );

    // Updating this render hash will rerender all the rows. This is useful for data changes like copying and pasting from excel.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const hiddenColumns = useMemo(() => {
        return {
            Delete: props.IsAdditionalPriceItem && isPageEditable,
            Ignore: !props.IsAdditionalPriceItem,
            FinanceItemNumber: props.displayPriceCodeColumn,
            CustomerCode: props.displayCustomerCodeColumn,
        };
    }, [
        props.IsAdditionalPriceItem,
        isPageEditable,
        props.displayPriceCodeColumn,
        props.displayCustomerCodeColumn,
    ]);

    return (
        <BaseTableDragDrop
            defaultRecord={props.IsAdditionalPriceItem ? {} : undefined}
            columns={defaultColumns}
            columnVisibility={hiddenColumns}
            afterUpdateData={(columnId: string) => {
                if (columnId === "Ignore") {
                    updateRenderHash();
                }
            }}
            disableSelectColumns={[
                "Delete",
                "Ignore",
                "Unit",
                "BookPrice",
                "TotalPrice",
            ]}
            name={props.name}
            renderHash={renderHash}
            meta={{
                pageName: "Pricing",
                canDelete: props.IsAdditionalPriceItem,
                urls: {
                    Description: props.descriptionEndPoint,
                    Comments: `/api/Pricing/Comments`,
                    Details: `/api/Pricing/Details`,
                },
                updateNumericCellData: (
                    rowIndex: number,
                    columnId: string,
                    value: unknown,
                ) => {
                    if (rowIndex > (data?.length ?? 0) - 1) return;
                    const valueName = `${props.name}.${rowIndex}.${columnId}`;
                    const lastValue = getValues(valueName);
                    if (lastValue === value) return;

                    let updateChangedField = null;
                    if (columnId === "Discount")
                        updateChangedField = "IsDiscountChanged";
                    else if (columnId === "FinalPrice")
                        updateChangedField = "IsFinalPriceChanged";

                    if (updateChangedField)
                        setValue(
                            `${props.name}.${rowIndex}.${updateChangedField}`,
                            true,
                            {
                                shouldDirty: true,
                            },
                        );

                    // Skip page index reset until after next rerender
                    setValue(valueName, value, { shouldDirty: true });
                },
                isAdditionalPriceItem: (): boolean => {
                    return props.IsAdditionalPriceItem;
                },
            }}
        />
    );
};

const QuantityCell = ({
    applyReadOnlyStyle = false,
    ...props
}: CellContext<any, any> & { applyReadOnlyStyle?: boolean }) => {
    if (!(props.table.options.meta as PricingMetaT).isAdditionalPriceItem())
        return (
            <OverrideCell
                {...props}
                pDefaultName={"Quantity"}
                pOverriddenName={"OverridenQuantity"}
                overrideTooltip={
                    resources.QuantityHasBeenOverriddenClearTheCellToResetTheValueToTheDefaultOne
                }
                applyReadOnlyStyle={applyReadOnlyStyle}
            />
        );
    return (
        <FormattedNumericCell
            {...props}
            applyReadOnlyStyle={applyReadOnlyStyle}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(PricingTable);

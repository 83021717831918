import React from "react";
import { useWatch } from "react-hook-form";
import moment from "moment-timezone";

import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";

type Props = {
    name: string;
    label?: string;
    disabled?: boolean;
};

const StandardDateTime: React.FC<Props> = (props) => {
    let time: string = useWatch({ name: props.name });

    if (!time) {
        return (
            <StandardTextBox name={" "} label={props.label} disabled={true} />
        );
    }

    // Parse the ISO string
    const dateTime = moment(time);

    // Format
    const mstTime = dateTime.format("MM/DD/YYYY h:mm A");

    return (
        <StandardTextBox
            name={" "}
            label={props.label}
            overrideValue={mstTime}
            disabled={props.disabled}
        />
    );
};

export default StandardDateTime;

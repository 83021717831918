import React from "react";
import {
    GridColumnProps,
    GridHeaderCellProps,
} from "@progress/kendo-react-all";

import { IconHeaderCell } from "components/Cells/IconHeaderCell";
import BoundDate from "components/kendoExtensions/gridExtensions/BoundDate";

import { resources } from "utils/resources";

export const COLUMN_PX = 150;
export const COLUMN_PROPS: Partial<GridColumnProps> = {
    width: COLUMN_PX,
    filter: "text",
    minResizableWidth: COLUMN_PX / 2,
};
export const AUTO_WIDTH_COLUMN_PROPS: Partial<GridColumnProps> = {
    ...COLUMN_PROPS,
    width: undefined,
};

export const SMALL_COLUMN_PROPS: Partial<GridColumnProps> = {
    ...COLUMN_PROPS,
    width: 80,
};

export const DATE_COLUMN_PROPS: Partial<GridColumnProps> = {
    width: 120,
    filter: "date",
    cell: (props) => <BoundDate {...props} />,
};

export const COLUMN_INFO_ICON_PROPS: Partial<GridColumnProps> = {
    width: COLUMN_PX,
    filter: "text",
    minResizableWidth: COLUMN_PX / 2,
    headerCell: (props: GridHeaderCellProps) => (
        <IconHeaderCell
            title={props.title}
            tooltipText={resources.ThisFieldAppliesToOfficialProgram}
            titleStyleClassName={"fw-lighter"}
        />
    ),
};

import {
    AccountInfo,
    EventType,
    PublicClientApplication,
} from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser/dist/config/Configuration";

const msalConfig: Configuration = {
    auth: {
        clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
        redirectUri: "/signin-oidc",
    },
};
/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload) {
        const account: AccountInfo = event.payload as AccountInfo;
        msalInstance.setActiveAccount(account);
    }
});

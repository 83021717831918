//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FormulaCalculateScheduleStageTarget {
	StartDepth = 0,
	EndDepth = 1,
	Speed = 2,
	Time = 3,
	FluidRate = 4,
	FluidTotal = 5,
	N2Rate = 6,
	N2Total = 7,
	CombinedDisplacementRate = 8,
	CombinedDisplacementTotal = 9
}

import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

type Props = {
    className?: string;
    to?: string;
    text?: string;
    label?: string;
    onClick?: () => void;
    children?: ReactNode;
    justifyContent?: string;
    id?: string;
};

const StandardAnchorLink: React.FC<Props> = ({
    justifyContent = "left",
    ...props
}: Props) => {
    if (props.to) {
        // There is a URL we want to navigate to
        return (
            <AnchorLink justifyContent={justifyContent}>
                {props.label && <StandardLabel label={props.label} />}
                <Link
                    to={props.to}
                    className={`cursor-pointer text-calfrac-green underline hover:text-calfrac-green-800 ${props.className}`}
                    target="_blank"
                    id={props.id}
                >
                    {props.text}
                    {props.children}
                </Link>
            </AnchorLink>
        );
    }

    // We want to react to an on-click
    return (
        <AnchorLink justifyContent={justifyContent}>
            <span
                onClick={() => {
                    props.onClick?.();
                    return false;
                }}
                className={
                    "cursor-pointer text-calfrac-green underline hover:text-calfrac-green-300"
                }
            >
                {props.text}
                {props.children}
            </span>
        </AnchorLink>
    );
};

const AnchorLink = styled.div<{ justifyContent?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.justifyContent ?? "center"};
`;

export default StandardAnchorLink;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { ProgramWorkflowStatusId } from "types/generated/Calfrac/Jet/Core/Models/ProgramWorkflowStatusId";

import { PrimaryButton, SecondaryButton } from "components/Buttons";

import { useProgram } from "hooks/useProgramParams";

import { resources } from "utils/resources";

import StandardCancelButton from "./StandardCancelButton";
import StandardSaveButton from "./StandardSaveButton";

export const SaveButtons: React.FC<{
    onSave?: () => void;
    onCancel?: () => void;
    title?: string;
    disabledSaveButton?: boolean;
    overrideIsPageEditable?: boolean;
}> = ({
    onSave,
    onCancel,
    title,
    disabledSaveButton,
    overrideIsPageEditable,
}) => (
    <div className={"flex flex-row gap-4"}>
        <StandardSaveButton
            title={title}
            onClick={onSave}
            disabled={disabledSaveButton}
            overrideIsPageEditable={overrideIsPageEditable}
        />
        <StandardCancelButton
            onClick={onCancel}
            overrideIsPageEditable={overrideIsPageEditable}
        />
    </div>
);
const SaveProgramButtons: React.FC<{
    title?: string;
    disabledSaveButton?: boolean;
}> = ({ title, disabledSaveButton }) => {
    const { isPageEditable, program } = useProgram();
    const { formState } = useFormContext();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const modalRef = useRef<HTMLDialogElement | null>(null);

    useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            if (showCancelModal) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }
    }, [showCancelModal]);

    const toggleCancelDialog = () => {
        setShowCancelModal(!showCancelModal);
    };

    const checkForUnsavedChanges = () => {
        if (formState.isDirty) {
            toggleCancelDialog();
        } else {
            reloadCurrentPage();
        }
    };

    const reloadCurrentPage = () => {
        window.location.reload();
    };

    const pathName = window.location.pathname;

    const pageEditable = useMemo(() => {
        return pathName.includes("EditProgramAttachments") ||
            pathName.includes("EditProgramTasks")
            ? isPageEditable ||
                  program?.WorkflowStatusId === ProgramWorkflowStatusId.Approved
            : isPageEditable;
    }, [isPageEditable, program?.WorkflowStatusId, pathName]);

    if (!pageEditable) return <></>;

    return (
        <>
            <SaveButtons
                title={title}
                onCancel={checkForUnsavedChanges}
                disabledSaveButton={disabledSaveButton}
                overrideIsPageEditable={pageEditable}
            />
            <dialog ref={modalRef} className="mt-5 px-4 py-4">
                <p className="text-calfrac-grey text-sm">
                    {resources.ReloadSite}
                </p>
                <p className="text-calfrac-grey mt-2 text-sm">
                    {resources.ChangesYouMadeMayNotBeSaved}
                </p>
                <div className={"mt-4 flex flex-row justify-end gap-1"}>
                    <PrimaryButton onClick={reloadCurrentPage}>
                        {resources.Reload}
                    </PrimaryButton>
                    <SecondaryButton onClick={toggleCancelDialog}>
                        {resources.Cancel}
                    </SecondaryButton>
                </div>
            </dialog>
        </>
    );
};

export default SaveProgramButtons;

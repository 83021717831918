import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    filterBy,
    MultiSelect,
    MultiSelectChangeEvent,
    MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";
import { CellContext } from "@tanstack/react-table";

import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import { TableMetaT } from "types/Tables/Cells";

import { fetchJET, JetApiUrls } from "utils/fetchJet";

const MultiSelectCell = ({
    row,
    column,
    getValue,
    table,
    byField = "Id",
    hasObjects = true,
    placeholder,
    readOnly = false,
    ...props
}: CellContext<any, any> & {
    hasObjects?: boolean;
    byField?: "Id" | "Name";
    placeholder?: string;
    readOnly?: boolean;
    url?: string;
}) => {
    const initialValue = getValue();
    const { id } = column;

    // State
    const [filteredData, setFilteredData] = React.useState<
        INamedItemViewModel[]
    >([]);
    const [value, setValue] = useState<any>();

    const url =
        (table.options.meta as TableMetaT)?.urls?.[id] ?? props.url ?? "";

    const query = useQuery<INamedItemViewModel[]>({
        queryKey: [url, id],
        queryFn: () => {
            return fetchJET(url as JetApiUrls);
        },
    });

    const currentValue = useMemo(() => {
        if (!byField) {
            return value;
        } else if (hasObjects) {
            return query?.data?.filter((row) => {
                const result = value?.filter(
                    (obj: INamedItemViewModel) =>
                        obj?.[byField] === row?.[byField],
                )?.length;
                return result !== undefined && result > 0;
            });
        }
        return query?.data?.filter((row) => value?.includes?.(row?.[byField]));
    }, [byField, hasObjects, query?.data, value]);

    // Handlers

    const onChange = useCallback(
        (e: MultiSelectChangeEvent) => {
            let value = e.target.value as unknown as INamedItemViewModel[];
            const valueToSave =
                byField && !hasObjects
                    ? value.map((row) => row[byField])
                    : value;
            setValue(valueToSave);
            (table.options.meta as TableMetaT)?.updateData(
                row.index,
                id,
                valueToSave,
            );
        },
        [byField, hasObjects, id, row.index, table.options.meta],
    );

    const filterData = (event: MultiSelectFilterChangeEvent) => {
        if (query.data === undefined) return;
        setFilteredData(filterBy(query.data, [event.filter], "contains"));
    };

    // Use Effects

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    if ((table.options.meta as TableMetaT)?.disabled || readOnly) {
        return (
            <div className={"read-only inline-block"}>
                {currentValue?.map((v: any) => (
                    <div
                        className={
                            "m-0.5 inline-block rounded border border-solid border-black border-opacity-20 p-1"
                        }
                    >
                        {v.Name}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <MultiSelect
            value={currentValue}
            filterable={true}
            onFilterChange={filterData}
            data={filteredData?.length > 0 ? filteredData : query.data}
            onChange={onChange}
            textField={"Name"}
            placeholder={placeholder}
            onOpen={async () => {
                if (query.data === undefined) await query.refetch();
            }}
        />
    );
};
export default MultiSelectCell;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CellContext } from "@tanstack/react-table";

import { CalculationTypeId } from "types/generated/Calfrac/Jet/Core/Models/CalculationTypeId";

import { FormulaCalculateScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/FormulaCalculateScheduleStageTarget";
import { IEditStageCalculationViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditStageCalculationViewModel";
import { IErrorableCalculationId } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IErrorableCalculationId";

import { CellType, ScheduleStagesMetaT } from "types/Tables/Cells";

import { OverriddenDiv } from "components/shared/StyledComponents/OverriddenDiv";
import FormulaCalculateColumnInfo, {
    Formulas,
} from "components/Tables/Schedules/FormulaCalculate/FormulaCalculateColumnInfo";

import { parseSafeStringIdToInt } from "utils/parseSafeStringIdToInt";

import BaseNumericCell from "./BaseNumericCell";
import NumericReadOnly from "./NumericReadOnly";

type CalculationNumericCellProps = CellContext<any, any> & {};

const CalculationNumericCell: CellType = (
    props: CalculationNumericCellProps,
) => {
    const initialValue = props.getValue();

    // State
    const [defaultValue, setDefaultValue] = useState(initialValue);
    const { formState } = useFormContext();

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState<number | null>(initialValue);

    // Will be called on initial render, on next renders will return the same value again if the deps have not changed
    // since last render (otherwise: will call calculateValueMemoFn)
    const calculateValueMemoFn = () => {
        const isInCurrentColumn = (
            columnId: string,
            calcTypeId: CalculationTypeId,
        ) => {
            const formulaStageTarget = FormulaCalculateColumnInfo[
                columnId
            ] as FormulaCalculateScheduleStageTarget;
            const formulaMap = Formulas[formulaStageTarget];
            const formulaResult = formulaMap[calcTypeId as CalculationTypeId];
            return formulaResult !== undefined;
        };

        const isInCurrentRow = (rowOriginalId: string, scheduleId: string) => {
            return scheduleId === rowOriginalId;
        };

        const foundCalculation: IEditStageCalculationViewModel | undefined = (
            props.table.options.meta as ScheduleStagesMetaT
        )?.calculations?.find((calc: IEditStageCalculationViewModel) => {
            const isMatch =
                isInCurrentRow(
                    props.row.original.Id,
                    calc.PumpScheduleStageId,
                ) && isInCurrentColumn(props.column.id, calc.CalculationTypeId);
            return isMatch;
        });

        if (foundCalculation) {
            if (foundCalculation.Id.startsWith("NEW-")) {
                // Newly copied stage calculations with referenced data become stage calculation errors (IsError = true); we
                // must initialize the value to 0.0 for these stage calculation errors
                const newValue = foundCalculation.IsError ? 0.0 : defaultValue;
                (
                    props.table.options.meta as ScheduleStagesMetaT
                ).updateNumericCellData(
                    props.row.index,
                    props.column.id,
                    newValue,
                    undefined,
                    false,
                );
            }
        }

        return foundCalculation;
    };

    // MEMO
    const calculation: IEditStageCalculationViewModel | undefined = useMemo(
        calculateValueMemoFn,
        [
            props.table.options.meta,
            props.row.original.Id,
            props.row.index,
            props.column.id,
            defaultValue,
        ],
    );

    useEffect(() => {
        setDefaultValue(initialValue);
        // eslint-disable-next-line
  }, []);

    // Edge case handling: when user creates a cell with a calculation, then manually edits the cell value, saves, then
    // adds a formula back into that same cell. If we don't re-initialize defaultValue, then the next time the user adds a
    // calc to that cell, the blue tick styling will fail to display until refresh (because defaultValue is still null
    // from when the first calculation was edited, and refreshing re-initializes the defaultValue to a non-null value).
    useEffect(() => {
        if (defaultValue === null && formState.isSubmitSuccessful) {
            // Re-initialize the default value inside a cleanup function when the component unmounts
            return () => {
                setDefaultValue(initialValue);
            };
        }
        // eslint-disable-next-line
  }, [formState]);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    // True for calculations (but not calculation errors, which are handled separately); false otherwise.
    const isOverridden: boolean =
        calculation !== undefined && defaultValue !== null;

    // External non-reference calculations are calculations that do not use referenced-data options in the formula pop-up,
    // but do use other external data configured in the program.
    // Examples of external non-reference calculations are: "Reservoir TVD", "Well Config Heel", "Well Config KOP",
    // "Well Config PBTD", "Well Config CT Volume"
    const isExternalNonReferenceCalculationError: boolean =
        calculation !== undefined &&
        typeof calculation?.IsExternalNonReferenceCalculationError ===
            "boolean" &&
        calculation?.IsExternalNonReferenceCalculationError;

    // Reference calculations are calculations that use referenced-data in the program to compute the result
    // Examples of referenced-data are: "Perforation (Zone / Stage #)", "Schedule #", "Casing", "Downhole Item", "Liner",
    // "Open Hole", "Tubing", "Flow Config (Description)"
    const isReferenceCalculationError: boolean =
        calculation !== undefined &&
        typeof calculation?.IsError === "boolean" &&
        calculation?.IsError;

    // Note: defaultValue is set to null when we detect a change to the value
    const isError =
        (isExternalNonReferenceCalculationError ||
            isReferenceCalculationError) &&
        defaultValue !== null;

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = useCallback(() => {
        // If null, then we know the calculation has been changed, and thus we should add it to the deleted list, and update
        // the numeric cell data. Otherwise, nothing has changed, so don't update the numeric cell data
        if (defaultValue === null) {
            let dc: IErrorableCalculationId | undefined;
            const stringableId = parseSafeStringIdToInt(calculation?.Id);
            if (typeof stringableId === "number") {
                dc = {
                    Id: stringableId,
                    IsError: Boolean(calculation?.IsError),
                };
            }

            (
                props.table.options.meta as ScheduleStagesMetaT
            )?.updateNumericCellData(
                props.row.index,
                props.column.id,
                value ?? 0.0,
                dc,
            );
        }
    }, [
        defaultValue,
        calculation?.Id,
        calculation?.IsError,
        props.table.options.meta,
        props.row.index,
        props.column.id,
        value,
    ]);

    // The user must save before changing any copied stage calculations or stage calculation errors. We make the cell a
    // readonly type until that first save.
    return calculation?.PumpScheduleStageId.startsWith("NEW-") &&
        (isError || isOverridden) ? (
        <OverriddenDiv isOverridden={isOverridden} isError={isError}>
            <NumericReadOnly {...props} />
        </OverriddenDiv>
    ) : (
        <OverriddenDiv isOverridden={isOverridden} isError={isError}>
            <BaseNumericCell
                table={props.table}
                onBlur={onBlur}
                rowIndex={props.row.index}
                columnId={props.column.id}
                value={value ?? 0.0}
                setValue={(v) => {
                    const epsilon = 0.0001;
                    const isDiff =
                        Math.abs((v ?? 0.0) - (value ?? 0.0)) > epsilon;
                    if (isDiff) {
                        setValue(v ?? 0.0);

                        // Setting this to null notifies us that the value has been updated (even if it was changed to the same value,
                        // e.g. calculation value of 123.0, user selects the cell, types 123, blurs; this is still a change)
                        setDefaultValue(null);
                    }
                }}
                placeholderNumber={0}
            />
        </OverriddenDiv>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(CalculationNumericCell, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return (
        prevValue === nextValue &&
        (prevProps.table.options.meta as ScheduleStagesMetaT)?.calculations ===
            (nextProps.table.options.meta as ScheduleStagesMetaT)
                ?.calculations &&
        prevProps.row === nextProps.row
    );
});

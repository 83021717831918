import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@progress/kendo-react-all";
import { useMutation } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";
import styled from "styled-components";

import { ICopyProgramViewModel } from "types/generated/Calfrac/Jet/Web/Models/Program/ICopyProgramViewModel";
import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import { PrimaryButton, WarningButton } from "components/Buttons";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";
import { ValidationSummary } from "components/shared/GenericCustomComponents/StandardValidationSummary";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import { useProgramParams } from "hooks/useProgramParams";

import { saveJET } from "utils/fetchJet";
import { setFormErrors } from "utils/helpers";
import { resources } from "utils/resources";

type Props = {
    canCopy?: boolean;
    onLoad?: (isLoading: boolean) => void;
    onCopyProgram?: () => void;
};

const CopyProgram: React.FC<Props> = ({
    onLoad,
    canCopy,
    onCopyProgram,
}: Props) => {
    // Constants
    const copyUrl = "/api/Program/CopyProgram";
    const navigate = useNavigate();

    // React-hook form
    const { setError, formState, clearErrors } = useFormContext();

    // Mutations
    const copyProgramMutation = useMutation({
        mutationFn: async (data: ICopyProgramViewModel) => {
            const res = await saveJET<Record<string, any>>(
                copyUrl,
                {},
                serialize(data, { indices: true }),
            ).then((res) => {
                if (res.Errors) {
                    setFormErrors(res.Errors, setError);
                    return res;
                }
                return res.json();
            });
            if (Object.keys(formState.errors).length <= 0) {
                setVisible(false);
                navigate(`/Program/EditProgramData/${res.ProgramId}`);
                // TODO: Tech debt - should not need to reload.
                window.location.reload();
            }
        },
    });

    useEffect(() => {
        onLoad?.(copyProgramMutation.isPending);
    }, [copyProgramMutation.isPending, onLoad]);

    // State
    const [visible, setVisible] = useState<boolean>(false);
    const [programToCopyFrom, setProgramToCopyFrom] =
        useState<INamedItemViewModel | null>();
    const [alsoCopyDataFromRequest, setAlsoCopyDataFromRequest] =
        useState<boolean>(true);

    const { programId, program } = useProgramParams();

    // Handlers
    const handleProgramToCopyFromChange = useCallback(
        (e?: INamedItemViewModel) => {
            setProgramToCopyFrom(e);
        },
        [],
    );

    const handleCopy = useCallback(() => {
        clearErrors();

        if (!programToCopyFrom) {
            window.alert(resources.SelectProgramToCopyFrom);
            return;
        }

        const copyProgram = {
            CountryId: program?.CountryId,
            ServiceLineId: program?.ServiceLineId,
            AssignedToServiceLineId: program?.AssignedToServiceLineId,
            CurrentProgramId: program?.ProgramId,
            ProgramToCopyId: programToCopyFrom?.Id,
            ShouldCopyDataFromRequest: alsoCopyDataFromRequest,
        } as ICopyProgramViewModel;

        copyProgramMutation.mutate(copyProgram);
    }, [
        alsoCopyDataFromRequest,
        copyProgramMutation,
        program?.AssignedToServiceLineId,
        program?.CountryId,
        program?.ProgramId,
        program?.ServiceLineId,
        programToCopyFrom,
        clearErrors,
    ]);

    const visibleHandler = useCallback(() => {
        onCopyProgram?.();
        setVisible(!visible);
    }, [onCopyProgram, visible]);

    if (!canCopy) return <></>;

    return (
        <>
            {visible && (
                <Dialog
                    title={"Copy Program"}
                    onClose={visibleHandler}
                    width={500}
                    height={230}
                    themeColor={"primary"}
                >
                    {copyProgramMutation.isPending && <LoadingOverlay />}
                    {!copyProgramMutation.isPending && (
                        <>
                            <ValidationSummary />
                            <StandardDropDownList
                                name={"ProgramToCopyFrom"}
                                url={`/api/Program/GetCopyProgramProgramNumbers`}
                                params={{
                                    countryId:
                                        program?.CountryId?.toString() ?? "",
                                    serviceLineId:
                                        program?.ServiceLineId?.toString() ??
                                        "",
                                    currentProgramNumberId: programId,
                                }}
                                queryKey={[
                                    "/api/Program/GetCopyProgramProgramNumbers",
                                    program?.CountryId?.toString() ?? "",
                                    program?.ServiceLineId?.toString() ?? "",
                                    programId,
                                ]}
                                onChange={handleProgramToCopyFromChange}
                                label={resources.ProgramToCopyFrom}
                            />
                            <div className={"mt-4 flex items-center gap-4"}>
                                <StandardLabel
                                    label={resources.AlsoCopyDataFromRequest}
                                />
                                <StandardCheckBox
                                    checked={alsoCopyDataFromRequest}
                                    onChange={setAlsoCopyDataFromRequest}
                                />
                            </div>
                            <ButtonContainer>
                                <WarningButton onClick={visibleHandler}>
                                    {resources.Cancel}
                                </WarningButton>
                                <PrimaryButton
                                    onClick={handleCopy}
                                    type={"button"}
                                >
                                    {resources.CopyProgram}
                                </PrimaryButton>
                            </ButtonContainer>
                        </>
                    )}
                </Dialog>
            )}
            <PrimaryButton onClick={visibleHandler}>
                {resources.CopyProgram}
            </PrimaryButton>
        </>
    );
};

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;
    column-gap: 1rem;
    margin-top: 15px;
`;

export default CopyProgram;

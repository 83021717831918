/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { ReactNode } from "react";

import { H3 } from "components/Headers";

export const FormSectionDivider: React.FC<{ children: ReactNode }> = ({
    children,
}) => <div className="mb-10 divide-y divide-calfrac-gray/10 ">{children}</div>;

export const Title: React.FC<{
    title?: string;
    description?: string;
}> = ({ title, description }) => (
    <div className="sticky top-0 z-10 border-b-2 border-calfrac-gray/5 bg-white px-4 py-4 ">
        <H3>{title}</H3>
        {description && (
            <p className="mt-1 text-sm leading-6 text-gray-600 ">
                {description}
            </p>
        )}
    </div>
);

export const FormSectionFlex: React.FC<{
    label?: string;
    description?: string;
    children: ReactNode;
}> = ({ label, description, children }) => (
    <div className="z-0 pt-4">
        <div className="bg-white shadow-md ring-1 ring-calfrac-gray/5  md:col-span-2">
            {label && <Title title={label} description={description} />}
            <div className="px-4 py-4">{children}</div>
        </div>
    </div>
);

const FormSection: React.FC<{
    label?: string;
    description?: string;
    children: ReactNode;
    flexDir?: "row" | "column";
    className?: string;
    padding?: string;
}> = ({
    label = "",
    description,
    children,
    flexDir = "row",
    className,
    padding = "px-4 py-4",
}) => (
    <>
        <div className="z-0 pt-2">
            <div className="bg-white shadow-md ring-1 ring-calfrac-gray/5  md:col-span-2">
                {label && <Title title={label} description={description} />}
                <div className={padding}>
                    {flexDir === "row" && (
                        <div
                            className={
                                className ??
                                "grid w-full grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6"
                            }
                        >
                            {children}
                        </div>
                    )}
                    {flexDir === "column" && (
                        <div className={"grid grid-cols-1 gap-y-8"}>
                            {children}
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
);
export default FormSection;

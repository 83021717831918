import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { resources } from "utils/resources";

const GeneralErrorPage: React.FC<{
    code: number;
    title: string;
    subtitle: string;
    showHomeLink?: boolean;
}> = ({ code, title, subtitle, showHomeLink }) => {
    useEffect(() => {
        return () => toast.dismiss();
    }, []);

    return (
        <>
            <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-calfrac-green-600">
                        {code}
                    </p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 ">
                        {title}
                    </h1>
                    <p className="mt-6 text-base leading-7 text-gray-600 ">
                        {subtitle}
                    </p>
                    {showHomeLink && (
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <Link
                                to={"/"}
                                className="rounded-md bg-calfrac-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-calfrac-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-calfrac-green-600"
                            >
                                {resources.Home}
                            </Link>
                        </div>
                    )}
                </div>
            </main>
        </>
    );
};

export default GeneralErrorPage;

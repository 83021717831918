import React from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate, Outlet, RouteObject } from "react-router-dom";

import { resources } from "utils/resources";

import NotFound from "views/Error/NotFound";
import Job from "views/Job";
import EditJob from "views/Job/EditJob";
import Program from "views/Program";
import RequestPage from "views/Request";
import Task from "views/Task";

import ErrorRoutes from "./ErrorRoutes";
import ProgramRoutes from "./ProgramRoutes";
import RequestRoutes from "./RequestRoutes";

const RedirectToIndex: React.FC = () => {
    return (
        <Link to={"/"}>
            You have been authenticated. Click here to return home.
        </Link>
    );
};
export const NoScroll = () => (
    <>
        <Helmet>
            <style>
                {`.body {
        overflow: hidden;
        }`}
            </style>
        </Helmet>
        <Outlet />
    </>
);

const AppRoutes: RouteObject[] = [
    {
        element: <NoScroll />,
        // ROOT TABLE PAGES PAGES
        children: [
            {
                index: true,
                path: "",
                element: <Program />,
                handle: {
                    title: resources.Programs,
                },
            },
            {
                path: "Program",
                element: <Navigate to={"/"} />,
                handle: {
                    title: resources.Programs,
                },
            },
            {
                path: "Task",
                element: <Task />,
                handle: {
                    title: resources.Tasks,
                },
            },
            {
                path: "Request",
                element: <RequestPage />,
                handle: {
                    title: resources.Requests,
                },
            },
            {
                path: "Job",
                element: <Job />,
                handle: {
                    title: resources.Jobs,
                },
            },
            {
                path: "Job/EditJob/:jobId/",
                element: <EditJob />,
                handle: {
                    title: resources.Job,
                },
            },
        ],
    },
    {
        path: "signin-oidc",
        element: <RedirectToIndex />,
        handle: {
            title: resources.Jet,
        },
    },
    RequestRoutes,
    ProgramRoutes,
    ErrorRoutes,
    {
        path: "*",
        element: <NotFound />,
    },
];

export default AppRoutes;

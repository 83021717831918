import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    MaskedTextBox,
    MaskedTextBoxChangeEvent,
} from "@progress/kendo-react-all";
import { CellContext } from "@tanstack/react-table";

import { RequestProgramTypeId } from "types/generated/Calfrac/Jet/Core/Models/RequestProgramTypeId";

import { TableMetaT } from "types/Tables/Cells";

import { EmptyMasks, Masks } from "utils/enumerations";

const NtsDLSCell = ({
    row,
    getValue,
    column,
    table,
    readOnly = false,
}: CellContext<any, any> & { readOnly?: boolean }) => {
    const isNts = row.original?.["IsNts"] ?? false;

    // allows for W in UWI masks to represent any letter,
    // identical to default L but L doesn't make sense to show in the mask for UWIs
    const maskRules = {
        W: /[A-Za-z]/,
        b: /[A-Za-z]/,
        l: /[A-Za-z]/,
        B: /[A-Za-z]/,
    };

    const initialValue = getValue() ?? "";
    const [value, setValue] = useState<string>(initialValue);

    const isReadOnly =
        row.original.RequestProgramType?.Id === RequestProgramTypeId.Revision ||
        row.original.RequestProgramType?.Id ===
            RequestProgramTypeId.PriceRevision ||
        row.original.NewProgramId > 0 ||
        readOnly;

    const isEditable = useMemo(
        () => row.original.editable !== false && !isReadOnly,
        [row.original.editable, isReadOnly],
    );

    const format = useMemo(() => {
        return isNts ? Masks.NtsInputMask : Masks.DlsInputMask;
    }, [isNts]);

    const emptyMaskFormat = useMemo(() => {
        return isNts ? EmptyMasks.NtsInputMask : EmptyMasks.DlsInputMask;
    }, [isNts]);

    const onChange = useCallback(
        (e: MaskedTextBoxChangeEvent) => {
            const value = e.target.value;
            setValue(value);
            (table.options.meta as TableMetaT)?.updateData(
                row.index,
                column.id,
                value === emptyMaskFormat ? "" : value,
            );
            (table.options.meta as TableMetaT)?.updateData(
                row.index,
                isNts ? "NtsUwi" : "DlsUwi",
                value === emptyMaskFormat ? "" : value,
            );
        },
        [table.options.meta, row.index, column.id, emptyMaskFormat, isNts],
    );

    // Use Effects
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const formattedValue = useMemo((): string => {
        if (value === "") return format;
        return value.toString();
    }, [format, value]);

    if (isReadOnly || !isEditable) {
        return (
            <div className={"read-only"}>
                <span
                    className={
                        "w-100 inline-block rounded-md bg-calfrac-gray-100 p-1 tabular-nums"
                    }
                >
                    {formattedValue}
                </span>
            </div>
        );
    }

    return (
        <MaskedTextBox
            disabled={!isEditable}
            name={column.id}
            value={formattedValue}
            mask={format}
            onChange={onChange}
            placeholder={format}
            rules={maskRules}
            size={"small"}
        />
    );
};

export default NtsDLSCell;

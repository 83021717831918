import React, { useEffect, useMemo, useRef, useState } from "react";
import { toString } from "@progress/kendo-intl";
import { Table } from "@tanstack/react-table";

import { TableMetaT } from "types/Tables/Cells";

import { useProgramParams } from "hooks/useProgramParams";

import { findFieldFormatNullable } from "utils/findFieldFormat";
import formatInputNumber from "utils/formatNumber";

import BaseInputCell from "./BaseInputCell";

type BaseNumericCellProps = {
    value: number | string | null;
    setValue: (value: number | null) => void;
    rowIndex: number;
    columnId: string;
    onBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
    table: Table<any>;
    defaultViewFormat?: string;
    inViewFormat?: string;
    displayNullValue?: number;
    pasteColumnId?: string;
    applyReadOnlyStyle?: boolean;
    placeholderNumber?: number;
};

const BaseNumericCell = ({
    value,
    setValue,
    rowIndex,
    columnId,
    onBlur,
    table,
    defaultViewFormat = "n5",
    displayNullValue,
    pasteColumnId,
    applyReadOnlyStyle = false,
    placeholderNumber,
    ...props
}: BaseNumericCellProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [numericValue, setNumericValue] = useState<number | null>(null);
    const { program } = useProgramParams();

    const inViewFormat = useMemo(() => {
        const format =
            props.inViewFormat ??
            findFieldFormatNullable(
                (table.options.meta as TableMetaT).pageName,
                `${(table.options.meta as TableMetaT).name}.${columnId}`,
                program?.CountryId,
            );
        const regex = /n\d+/;
        const match = format?.match(regex);
        if (match) {
            return match[0];
        }
        return defaultViewFormat ?? "n5";
    }, [
        columnId,
        program?.CountryId,
        table.options.meta,
        defaultViewFormat,
        props.inViewFormat,
    ]);
    const [editFormat, setEditFormat] = useState(
        numericValue === null ? "" : formatInputNumber(numericValue, 5),
    );

    const setNewValue = (val: string | number | null) => {
        if (val === "=") return; // Necessary so that the value of the input is unchanged when user presses this key
        if (val === null || val === undefined || val === "") {
            setEditFormat("");
            setNumericValue(null);
            return;
        }
        // Get rid of irrelevant characters first
        let newEditFormat = formatInputNumber(val, 5);
        setEditFormat(newEditFormat);
        setNumericValue(
            Number.isNaN(newEditFormat) || newEditFormat === ""
                ? null
                : Number(newEditFormat),
        );
    };

    useEffect(() => {
        if (value !== numericValue) setNewValue(value);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (value !== numericValue) setValue(numericValue);
    }, [numericValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const formattedValue = useMemo(() => {
        return toString(
            numericValue !== null ? numericValue : (displayNullValue ?? ""),
            inViewFormat,
        );
    }, [inViewFormat, numericValue, displayNullValue]);

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (numericValue == null) {
            return;
        }
        if (e.code === "ArrowUp") {
            setNewValue(numericValue ? numericValue + 1 : 1);
        } else if (e.code === "ArrowDown") {
            setNewValue(numericValue ? numericValue - 1 : -1);
        }
    };

    return (
        <BaseInputCell
            applyReadOnlyStyle={applyReadOnlyStyle}
            onKeyDown={onKeyDown}
            table={table}
            onBlur={onBlur}
            rowIndex={rowIndex}
            columnId={columnId}
            pasteColumnId={pasteColumnId}
            value={editFormat}
            viewOnlyValue={formattedValue}
            setValue={(v) => setNewValue(v)}
            isNumeric={true}
            inputRef={inputRef}
            placeholder={
                placeholderNumber !== undefined
                    ? toString(placeholderNumber, inViewFormat)
                    : undefined
            }
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(BaseNumericCell);

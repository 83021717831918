import { useMemo } from "react";
import { useWatch } from "react-hook-form";

import { isProgramCtan } from "components/Layout/CtanView";
import {
    pumpScheduleTableColumns,
    sharedTableColumns,
    TableType,
    treatmentScheduleTableColumns,
} from "components/Tables/Schedules/SchedulesColumns";

import { useProgramParams } from "./useProgramParams";
import { useUnits } from "./useUnits";

const useStagesColumns = () => {
    const model = useWatch();
    const { program } = useProgramParams();
    const units: { [key: string]: string } = useUnits();

    const isCtan = isProgramCtan(program);

    const tableType = isCtan
        ? TableType.TreatmentScheduleStages
        : TableType.PumpScheduleStages;

    const sharedColumns = useMemo(() => {
        return sharedTableColumns();
    }, []);

    const pumpScheduleColumns = useMemo(() => {
        return pumpScheduleTableColumns(model, units);
    }, [model, units]);

    const treatmentScheduleColumns = useMemo(() => {
        return treatmentScheduleTableColumns(units);
    }, [units]);

    switch (tableType) {
        case TableType.PumpScheduleStages:
            return {
                columns: [
                    ...sharedColumns.columns,
                    ...pumpScheduleColumns.columns,
                ],
                order: [...sharedColumns.order, ...pumpScheduleColumns.order],
            };
        case TableType.TreatmentScheduleStages:
            return {
                columns: [
                    ...sharedColumns.columns,
                    ...treatmentScheduleColumns.columns,
                ],
                order: [
                    ...sharedColumns.order,
                    ...treatmentScheduleColumns.order,
                ],
            };
        default:
            return { columns: [], order: [] };
    }
};

export default useStagesColumns;

import React from "react";
import { Dialog, GridColumn } from "@progress/kendo-react-all";

import StandardIndexGrid from "components/kendoExtensions/grids/StandardIndexGrid";
import StandardButton from "components/shared/GenericCustomComponents/StandardButton";

import { useProgramParams } from "hooks/useProgramParams";

import { findFieldFormat } from "utils/findFieldFormat";

type Props = {
    title?: string;
    scheduleId?: number;
    onClose?: () => void;
};
const ChemicalsPopup: React.FC<Props> = ({
    scheduleId,
    onClose,
    ...props
}: Props) => {
    const { program } = useProgramParams();

    return (
        <Dialog
            title={props.title}
            onClose={onClose}
            width={500}
            themeColor={"primary"}
        >
            <StandardIndexGrid
                path={`/api/Schedule/GetChemicalItems?scheduleId=${
                    scheduleId?.toString() ?? ""
                }`}
                filterable={false}
            >
                <GridColumn
                    field={"Name"}
                    editable={false}
                    editor={"text"}
                    filterable={false}
                    width={280}
                />
                <GridColumn
                    field={"Volume"}
                    editable={false}
                    editor={"numeric"}
                    format={findFieldFormat(
                        "PumpSchedules.TotalChemicals",
                        "Volume",
                        program?.CountryId,
                    )}
                    filterable={false}
                />
                <GridColumn
                    field={"Unit"}
                    editable={false}
                    editor={"text"}
                    filterable={false}
                    width={80}
                />
            </StandardIndexGrid>
            <div className={"w-100 mt-15 flex justify-end"}>
                <StandardButton
                    classes={"main-header-action main-header-action--primary"}
                    text={"Done"}
                    onClick={onClose}
                />
            </div>
        </Dialog>
    );
};

export default ChemicalsPopup;

import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { configure, GlobalHotKeys } from "react-hotkeys";
import { unstable_usePrompt } from "react-router-dom";
import { toast } from "react-toastify";
import { MutationOptions } from "@tanstack/query-core/src/types";
import { useMutation } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";

import { queryClient } from "AppRoutes/AppProviders";

import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import { useScroll } from "hooks/useScroll";
import useStatefulQuery from "hooks/useStatefulQuery";

import { fetchJET, JetApiUrls, saveJET } from "utils/fetchJet";
import { setFormErrors } from "utils/helpers";
import { resources } from "utils/resources";

import { ValidationSummary } from "./StandardValidationSummary";
import WarningSummary from "./StandardWarningSummary";

export interface Props<T> {
    readUrl?: JetApiUrls;
    initialData?: T;
    readParams?: Record<string, number | string | undefined> | string;
    saveUrl: JetApiUrls;
    saveParams?: Record<string, number | string | undefined> | string;
    onSaveSuccess?: MutationOptions<T>["onSuccess"];
    onModelUpdated?: (model: Record<string, string>) => void;
    children?: ReactNode | ReactNode[];
    className?: string;
    asForm?: boolean;
    extraFormData?: object;
    disableNavigationPrompt?: boolean;
    formId?: string;
    beforeSaveHandler?: () => void;
    clearCacheOnSave?: string[];
    showValidation?: boolean;
    shouldBeLoading?: boolean;
    ignoreLoadingOverlay?: boolean;
    showWarning?: boolean;
}

configure({
    ignoreTags: ["input", "select", "textarea"],
    // @ts-ignore
    ignoreEventsCondition: function () {},
});

const StandardForm = <T,>({
    // To retrieve data
    readUrl,
    readParams,
    // To save data
    saveUrl,
    saveParams = readParams,
    extraFormData,
    onSaveSuccess,
    onModelUpdated,
    children,
    className,
    initialData,
    disableNavigationPrompt,
    formId = "PageForm",
    beforeSaveHandler,
    showValidation = true,
    shouldBeLoading = false,
    ignoreLoadingOverlay = false,
    showWarning = true,
    clearCacheOnSave = [],
}: Props<T>) => {
    // State
    const formRef = useRef<HTMLFormElement | null>(null);
    const handleScroll = () => {
        // Here we get rid of any possible dropdown or combobox opened windows (we hide them)
        const windows =
            document.getElementsByClassName(
                "k-animation-container k-animation-container-shown",
            ) ?? Array<HTMLDivElement>();
        for (const element of windows) {
            element.classList.remove("k-animation-container");
            element.classList.remove("k-animation-container-shown");
            element.classList.remove("k-animation-container-relative");
            element.classList.add("invisible");
        }
    };
    // Hook we use th detect when the page has been scrolled in the specified element.
    useScroll(formRef.current?.parentElement, handleScroll);

    // Read Hook Form
    const { handleSubmit, getValues, setValue, reset, formState, setError } =
        useFormContext();

    // Initializing the form's value
    const [model, , { isLoading }] = useStatefulQuery<T | undefined>({
        queryKey: [readUrl, ...Object.values(readParams ?? {})],
        queryFn: async () => {
            if (!readUrl) return undefined;
            return await fetchJET(
                readUrl,
                readParams as unknown as Record<string, string>,
            );
        },
        initialData,
        enabled:
            readUrl !== undefined &&
            !(
                readParams &&
                Object.values(readParams).some(
                    (val) => val === null || val === undefined || val === "",
                )
            ),
    });

    useEffect(() => {
        const data = model || initialData;
        if (data) reset(data);
    }, [reset, model, initialData]);

    // Mutations
    const mutation = useMutation({
        mutationFn: async () => {
            beforeSaveHandler?.();
            const values = getValues();

            const { files, ...rest } = values;

            let body = serialize(
                { ...rest, ...extraFormData },
                { indices: true },
            );

            // If there are files, then we append them as "files[]" and allow the controllers to manage the logic of storing them correctly.
            for (let i = 0; i < files?.length; i++) {
                body.append("files[]", files[i], files[i].name);
            }

            setValue("ErrorRowIdx", null);

            const res = await saveJET<T>(
                saveUrl,
                saveParams as unknown as Record<string, string>,
                body,
                undefined,
                undefined,
                false,
            );
            let newRecord = res as Record<string, any>;

            // If there are server validation errors
            if (newRecord.Errors) {
                setFormErrors(newRecord.Errors, setError);
                setValue("ErrorRowIdx", newRecord.RowIndex);
                // throw so onSuccess isn't hit
                throw new Error("Error was returned from server");
            }

            // Update the form model
            reset(newRecord);
            onModelUpdated?.(newRecord);

            if (!readUrl) {
                return res;
            }

            queryClient.invalidateQueries({
                predicate: (query) => {
                    return clearCacheOnSave.includes(
                        query.queryKey[0] as string,
                    );
                },
                type: "all",
            });

            return res;
        },
        onSuccess: (data, variables, context) => {
            // Drop the entire cache.
            // TODO: Tech debt. Drop the entire cache for the whole site on save. This ensures related pages work as expected.
            queryClient.removeQueries();

            if (formState.isDirty) reset({}, { keepValues: true }); // Make sure form is reset, in some instances this is not the case

            onSaveSuccess?.(data, variables, context);
        },
    });
    // Dismiss any toasts that are on the screen when moving away from page
    useEffect(() => {
        return () => toast.dismiss();
    }, []);

    unstable_usePrompt({
        when: disableNavigationPrompt === true ? false : formState.isDirty,
        message: resources.YouHaveUnsavedChanges,
    });

    // Handlers
    const onSubmit: SubmitHandler<Record<string, any>> = () => {
        mutation.mutate();
    };

    const handleSave = useCallback(
        (e: KeyboardEvent) => {
            e?.preventDefault();
            e?.stopPropagation();

            // focus on the form to blur any input that is currently focused
            const activeElement = document.activeElement;
            // @ts-ignore
            activeElement?.blur();

            document.body.focus();

            // set a timeout to permit other cells to save their values
            setTimeout(() => {
                mutation.mutate();
            }, 100);
        },
        [mutation],
    );

    // "CTRL+S" because global hot keys does not support commands when caps lock is active.
    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            const ctrlKey = e.ctrlKey;
            const sKey = 83;

            if (ctrlKey && e.keyCode === sKey) {
                handleSave(e);
            }
        };
        document.addEventListener("keydown", keyDownHandler);
        return () => document.removeEventListener("keydown", keyDownHandler);
    }, [handleSave, mutation]);

    if (
        !ignoreLoadingOverlay &&
        ((!model && readUrl !== undefined) || shouldBeLoading)
    )
        return <LoadingOverlay />;

    return (
        <GlobalHotKeys
            keyMap={{
                SAVE: ["cmd+s"],
                CREATE: ["ctrl+enter", "cmd+enter"],
            }}
            handlers={{
                SAVE: (e) => {
                    if (e) handleSave(e);
                },
            }}
        >
            {/* This is a full screen overlay that prevents navigation, but also lets the user see the content of the screen below*/}
            {!ignoreLoadingOverlay &&
                readUrl !== undefined &&
                (isLoading || mutation.isPending) && (
                    <LoadingOverlay className={"fixed"} />
                )}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={className}
                id={formId}
                ref={formRef}
            >
                {showValidation && <ValidationSummary />}
                {showWarning && <WarningSummary />}
                {children}
            </form>
        </GlobalHotKeys>
    );
};
export default StandardForm;

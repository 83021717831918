import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { PlusIcon } from "@heroicons/react/24/outline";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditPerforationViewModel } from "types/generated/Calfrac/Jet/Web/Models/Perforation/IEditPerforationViewModel";

import { PerforationsMetaT } from "types/Tables/Cells";

import { queryClient } from "AppRoutes/AppProviders";

import ClusterCell from "components/Cells/ClusterCell";
import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import IntegerCell from "components/Cells/IntegerCell";
import NumericCellNullable from "components/Cells/NumericCellNullable";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import NumericReadOnlyNullable from "components/Cells/NumericReadOnlyNullable";
import TextCell from "components/Cells/TextCell";
import { isProgramCtan } from "components/Layout/CtanView";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import { RecordType } from "components/Tables/BaseTable/Inner/InnerTable";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { downloadJET } from "utils/fetchJet";
import { resources } from "utils/resources";

type PerforationsTableProps = {
    name: string;
    dialogState: "cluster" | "importIntervals" | "configureGrid" | null;
    openImportIntervalsPopup: () => void;
    openConfigureGridPopup: () => void;
    openClustersPopup: (
        open: boolean,
        dialogName: string,
        dialogTitle: string,
    ) => void;
    renderHash?: string;
};

const columnHelper = createColumnHelper<IEditPerforationViewModel>();

const PerforationsTable: React.FC<PerforationsTableProps> = (props) => {
    const data = useWatch({ name: props.name });
    const miscHeader1 = useWatch({ name: "Miscellaneous1Title" });
    const miscHeader2 = useWatch({ name: "Miscellaneous2Title" });
    const volHeader1 = useWatch({ name: "Volume1Title" });
    const volHeader2 = useWatch({ name: "Volume2Title" });
    const units = useUnits();
    const { setValue } = useFormContext();
    const { isPageEditable, program } = useProgram();

    const invalidateRequestQuery = useCallback(() => {
        queryClient.invalidateQueries({
            predicate: (query) => {
                return (query.queryKey[0] as string)?.startsWith(
                    "/api/Perforation/FlowConfiguration",
                );
            },
            type: "all",
        });
    }, []);

    const [readOnlyHidden, setReadOnlyHidden] = useState(false);

    // Updating this render hash will rerender all the rows. This is useful for data changes like copying and pasting from excel.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
        invalidateRequestQuery();
    }, [props.renderHash, invalidateRequestQuery]);

    useEffect(() => {
        if (props.dialogState !== null) return;
        // Any time the dialog closes we want to update the render hash so that all rows rerender
        updateRenderHash();
    }, [props.dialogState]);

    const isCtan = isProgramCtan(program);
    const { programId } = useProgramParams();

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.display({
                id: "CopyAction",
                size: 40,
                maxSize: 40,
                cell: (props) => (
                    <PlusIcon
                        className={"h-6 w-6 cursor-pointer text-calfrac-green"}
                        aria-hidden="true"
                        onClick={() =>
                            (
                                props.table.options.meta as PerforationsMetaT
                            ).copyRow(
                                props.row.index,
                                props.row.original.Id.toString(),
                            )
                        }
                    />
                ),
                enablePinning: true,
                enableHiding: true,
            }),
            columnHelper.display({
                id: "OpenClustersAction",
                header: resources.Clusters,
                size: 60,
                maxSize: 60,
                cell: ClusterCell,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.ZoneNumber, {
                id: "ZoneNumber",
                header: resources.ZoneStageNumber,
                size: 60,
                maxSize: 60,
                cell: IntegerCell,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.ProgramTreatmentMode, {
                id: "ProgramTreatmentMode",
                header: resources.TreatmentMode,
                size: 170,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.ProgramCompletionTechnology, {
                id: "ProgramCompletionTechnology",
                header: resources.CompletionTechnology,
                size: 140,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.FormationAccess, {
                id: "FormationAccess",
                header: resources.FormationAccess,
                size: 140,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.ProgramFormation, {
                id: "ProgramFormation",
                header: resources.Formation,
                size: 140,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.TVD, {
                id: "TVD",
                header: `${resources.TVD} ${units.depthUnit}`,
                size: 50,
                minSize: 50,
                cell: NumericReadOnly,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Top, {
                id: "Top",
                header: `${resources.Top} ${units.depthUnit}`,
                size: 70,
                minSize: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.Bottom, {
                id: "Bottom",
                header: `${resources.Bottom} ${units.depthUnit}`,
                size: 70,
                minSize: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.PlugDepth, {
                id: "PlugDepth",
                header: `${resources.PlugDepth} ${units.depthUnit}`,
                size: 70,
                minSize: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.BallSize, {
                id: "BallSize",
                header: `${resources.BallSize} ${units.lengthSmallUnit2}`,
                size: 70,
                minSize: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.SeatSize, {
                id: "SeatSize",
                header: `${resources.SeatSize} ${units.thicknessUnit}`,
                size: 70,
                minSize: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.FlowConfiguration, {
                id: "FlowConfiguration",
                header: resources.FlowConfiguration,
                size: 70,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.TubularFrictionPressure, {
                id: "TubularFrictionPressure",
                header: `${resources.TubularFrictionPressure} ${units.pressureSmallUnit}`,
                size: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.DisplacementVolume, {
                id: "DisplacementVolume",
                header: `${resources.DisplacementVolume} ${units.volumeWetLargeUnit}`,
                size: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.BottomsUpVolume, {
                id: "BottomsUpVolume",
                header: `${resources.BottomsUpVolume} ${units.volumeBottomsUp}`,
                size: 50,
                minSize: 50,
                cell: NumericReadOnlyNullable,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ProgramFluidSystem, {
                id: "ProgramFluidSystem",
                header: resources.FluidType,
                size: 70,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.TreatmentVolume, {
                id: "TreatmentVolume",
                header: `${resources.TreatmentVolume} ${units.volumeWetLargeUnit}`,
                size: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.FlushVolume, {
                id: "FlushVolume",
                header: `${resources.FlushVolume} ${units.volumeWetLargeUnit}`,
                size: 70,
                cell: NumericCellNullable,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Description, {
                id: "Description",
                header: resources.Description,
                size: 200,
                cell: TextCell,
            }),
            columnHelper.accessor((row) => row.Miscellaneous1, {
                id: "Miscellaneous1",
                header: miscHeader1,
                size: 70,
                cell: TextCell,
            }),
            columnHelper.accessor((row) => row.Miscellaneous2, {
                id: "Miscellaneous2",
                header: miscHeader2,
                size: 70,
                cell: TextCell,
            }),
            columnHelper.accessor((row) => row.Volume1, {
                id: "Volume1",
                header: `${volHeader1} ${units.volumeWetLargeUnit}`,
                size: 70,
                cell: NumericCellNullable,
            }),
            columnHelper.accessor((row) => row.Volume2, {
                id: "Volume2",
                header: `${volHeader2} ${units.volumeWetLargeUnit}`,
                size: 70,
                cell: NumericCellNullable,
            }),
        ];
    }, [
        units.depthUnit,
        units.lengthSmallUnit2,
        units.pressureSmallUnit,
        units.volumeWetLargeUnit,
        miscHeader1,
        miscHeader2,
        volHeader1,
        volHeader2,
        units.thicknessUnit,
        units.volumeBottomsUp,
    ]);

    const reOrderZoneNumbers = (dataToReorder: any[]) => {
        const newData = [];
        let zoneNumber = 1;
        for (const row of dataToReorder) {
            row.ZoneNumber = zoneNumber;
            newData.push(row);
            zoneNumber++;
        }

        return newData;
    };

    const getNewPerforation = useCallback(
        (
            newPerf: IEditPerforationViewModel,
            prevPerf?: IEditPerforationViewModel,
        ) => {
            if (!prevPerf)
                return { ...newPerf, ZoneNumber: 1, PerforationClusters: [] };
            return {
                ...prevPerf,
                ...newPerf,
                ZoneNumber: (prevPerf.ZoneNumber as number) + 1,
            };
        },
        [],
    );

    const tableRef = useRef<HTMLTableElement>(null);

    const afterAddRecord = useCallback(
        (val: RecordType[]) => {
            const newValue = val as unknown as IEditPerforationViewModel[];

            const newPerf = getNewPerforation(
                newValue[newValue.length - 1],
                data.length > 0 ? data[data.length - 1] : undefined,
            );
            setValue(props.name, [...data, newPerf], { shouldDirty: true });

            setTimeout(() => {
                if (!tableRef.current) return;
                // if table ref is defined, scroll to the bottom
                const tBody = tableRef.current?.tBodies?.[0];

                if (!tBody) return;
                // find the last row

                // scroll to the bottom of the table

                const tRow = tBody?.rows[tBody.rows.length - 1];

                if (!tRow) return;

                // scroll into view
                tRow.scrollIntoView({
                    behavior: "smooth",
                });

                // find the first input cell and focus
                tRow.querySelector("textarea")?.select();
            }, 100);
        },
        [data, props.name, setValue, getNewPerforation],
    );

    const toolbarExtras = () => {
        return (
            <div
                className={
                    "sticky left-0 z-10 mb-1 flex w-full items-center justify-between"
                }
            >
                <div className={"space-x-4"}>
                    <span
                        onClick={props.openImportIntervalsPopup}
                        className={
                            "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                        }
                    >
                        {resources.ImportIntervals}
                    </span>
                    <span
                        onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            downloadJET(
                                `/api/Perforation/GetPerforationsXlsxFile?programId=${programId}`,
                            );
                        }}
                        className={
                            "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                        }
                    >
                        {resources.ExportIntervals}
                    </span>
                    <span
                        onClick={props.openConfigureGridPopup}
                        className={
                            "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                        }
                    >
                        {resources.ConfigureGrid}
                    </span>
                    <span
                        onClick={() => setReadOnlyHidden((c) => !c)}
                        className={
                            "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                        }
                    >
                        {resources.ToggleReadOnlyColumns}
                    </span>
                </div>
                <div>
                    <StandardCheckBox
                        sidelabel={resources.EstimatedDepth}
                        name={"EstimatedDepth"}
                    />
                </div>
            </div>
        );
    };

    const hiddenColumns = useMemo(() => {
        return {
            Delete: isPageEditable,
            CopyAction: isPageEditable,
            TVD: !readOnlyHidden,
            FlushVolume: !isCtan,
            TreatmentVolume: isCtan,
            BottomsUpVolume: !readOnlyHidden && isCtan,
            ProgramFluidSystem: isCtan,
        };
    }, [isPageEditable, readOnlyHidden, isCtan]);

    return (
        <BaseTable
            tableRef={tableRef}
            className={"w-max !border-separate !border-spacing-0"}
            toolbarExtras={toolbarExtras}
            copyDownEnabled={true}
            pinnedColumnBgColor={"#F7F7F7"}
            afterAddRecord={afterAddRecord}
            defaultRecord={{
                TVD: null,
                Top: null,
                Bottom: null,
                PlugDepth: null,
                BallSize: null,
                TubularFrictionPressure: null,
                DisplacementVolume: null,
                FlushVolume: null,
                BottomsUpVolume: null,
                TreatmentVolume: null,
                SeatSize: null,
                PerforationClusters: [],
                Description: null,
                Miscellaneous1: null,
                Miscellaneous2: null,
                Volume1: null,
                Volume2: null,
            }}
            columns={defaultColumns}
            pinnedColumns={[
                "Delete",
                "CopyAction",
                "OpenClustersAction",
                "ZoneNumber",
            ]}
            disableSelectColumns={[
                "Delete",
                "CopyAction",
                "OpenClustersAction",
                "TVD",
                "BottomsUpVolume",
            ]}
            columnVisibility={hiddenColumns}
            name={props.name}
            renderHash={renderHash}
            meta={{
                canDelete: isPageEditable,
                urls: {
                    ProgramTreatmentMode:
                        "/api/Perforation/ProgramTreatmentMode",
                    ProgramCompletionTechnology:
                        "/api/Perforation/ProgramCompletionTechnology",
                    FormationAccess: `/api/Perforation/FormationAccess?countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                    ProgramFormation: "/api/Perforation/ProgramFormation",
                    FlowConfiguration: "/api/Perforation/FlowConfiguration",
                    ProgramFluidSystem: `/api/Schedule/FluidSystem?programId=${program?.ProgramId}`,
                },
                openClustersPopup: (rowIndex: number, zoneNumber: number) => {
                    props.openClustersPopup(
                        true,
                        `${props.name}.${rowIndex}.PerforationClusters`,
                        `${resources.ZoneStageNumber} ${zoneNumber}`,
                    );
                },
                copyRow: (rowIndex: number, rowId: string) => {
                    let newRecords = reOrderZoneNumbers(
                        JSON.parse(JSON.stringify(data)) as any[],
                    );
                    const recordToCopy = data[rowIndex];
                    recordToCopy.Id = `copy-${Math.floor(
                        Math.random() * 10000,
                    )}-${rowId}`;

                    newRecords.splice(rowIndex, 0, recordToCopy);
                    setValue(
                        props.name,
                        // @ts-ignore
                        newRecords.map((record, idx) => {
                            const newRecord =
                                record as IEditPerforationViewModel;

                            newRecord.ZoneNumber = idx + 1;
                            if (idx === rowIndex) {
                                // Newly copied items should have some empty columns.
                                newRecord.TubularFrictionPressure = undefined;
                                newRecord.DisplacementVolume = undefined;
                                newRecord.FlushVolume = undefined;
                                newRecord.Top = undefined;
                                newRecord.Bottom = undefined;
                                newRecord.PlugDepth = undefined;
                                newRecord.BallSize = undefined;
                                newRecord.TVD = undefined;
                                newRecord.PerforationClusters = [];
                            }
                            return newRecord;
                        }),
                        { shouldDirty: true },
                    );
                    //Re-render so zoneNumbers show correct
                    updateRenderHash();
                },
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default PerforationsTable;

import React from "react";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import StandardRadioButton from "./StandardRadioButton";

type Props = {
    name: string;
    readOnly?: boolean;
    label1: string;
    label2: string;
    target1: boolean; // Specifies which radio button should be checked based on the true value of a field
    target2: boolean;
    id?: string;
    label?: string;
};

const RadioButtonGroup: React.FC<Props> = ({
    name,
    readOnly,
    label1,
    label2,
    target1,
    target2,
    id,
    label,
}: Props) => {
    return (
        <div className={"flex-col justify-center align-middle"}>
            {label && <StandardLabel labelFor={id} label={label} />}
            <div className={"col-g flex justify-start gap-3"}>
                <StandardRadioButton
                    id={id}
                    name={name}
                    label={label1}
                    disabled={readOnly}
                    target={target1}
                />
                <StandardRadioButton
                    id={id}
                    name={name}
                    label={label2}
                    disabled={readOnly}
                    target={target2}
                />
            </div>
        </div>
    );
};

export default RadioButtonGroup;

import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";

import { FluidSystemTypeId } from "types/generated/Calfrac/Jet/Core/Models/FluidSystemTypeId";

import { H2 } from "components/Headers";
import { SubtitlePortal } from "components/Layout/SubtitlePortal";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import ChemicalScheduleTable from "components/Tables/Schedules/ChemicalScheduleTable";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type Params = {
    scheduleId: string;
    fluidSystemTypeId: string;
};

const Page: React.FC = () => {
    // Constants
    const url = "/api/ChemicalLoading/EditChemicalSchedule";

    const { programId } = useProgramParams();

    const model = useWatch();

    // Url params
    const { scheduleId, fluidSystemTypeId } = useParams<Params>();

    const { isPageEditable } = useProgramParams();

    // Helpers
    const subtitle = useMemo(() => {
        if (model?.FluidSystemTypeId === FluidSystemTypeId.Primary) {
            return `/ Primary Chemical Schedule ${model?.ScheduleNumber}`;
        }
        return `/ Secondary Chemical Schedule ${model?.ScheduleNumber}`;
    }, [model?.ScheduleNumber, model?.FluidSystemTypeId]);

    return (
        <StandardForm
            readUrl={url}
            readParams={{
                pumpScheduleId: scheduleId,
                fluidSystemTypeId,
            }}
            saveUrl={url}
            className={"h-full"}
        >
            <SubtitlePortal>
                <div className={"flex items-center gap-5"}>
                    <H2>{subtitle}</H2>
                    <Link
                        className={"flex items-center gap-1"}
                        to={`/Schedules/EditSchedules/${programId}`}
                    >
                        <ArrowLeftCircleIcon
                            className={"h-7 w-7 fill-calfrac-green"}
                        />
                        <StandardAnchorLink text={resources.BackToSchedules} />
                    </Link>
                </div>
            </SubtitlePortal>
            <div
                className={
                    "h-full px-4 pt-6 shadow-md ring-1 ring-calfrac-gray/5"
                }
            >
                <ChemicalScheduleTable
                    name={"ChemicalScheduleStages"}
                    canEdit={isPageEditable}
                />
            </div>
        </StandardForm>
    );
};

export default Page;

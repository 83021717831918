import React, { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

import { FilterViewId } from "types/generated/Calfrac/Jet/Core/Models/FilterViewId";

import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import { PrimaryButton, SecondaryButton } from "components/Buttons";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { JetApiUrls, saveJET } from "utils/fetchJet";
import { resources } from "utils/resources";

// On page load this will get the current user's preferred filters or allow them to create preferred filters.
const FilterToolbar: React.FC<{
    savedFilterUrl: JetApiUrls;
    Popover: React.FC<{ onClose: () => void }>;
    name: "Program" | "Request" | "Task" | "Job";
}> = ({ Popover, savedFilterUrl, name }) => {
    const { reset, setValue, getValues } = useFormContext();
    // State
    const [editorOpen, setEditorOpen] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const saveLastFilter = useCallback(
        async (value?: INamedItemViewModel) => {
            let filterViewId;
            switch (name) {
                case "Program":
                    filterViewId = FilterViewId.ProgramIndexFilterView;
                    break;
                case "Request":
                    filterViewId = FilterViewId.RequestIndexFilterView;
                    break;
                case "Task":
                    filterViewId = FilterViewId.TaskIndexFilterView;
                    break;
                case "Job":
                    filterViewId = FilterViewId.JobIndexFilterView;
                    break;
                default:
                    filterViewId = "";
                    break;
            }

            await saveJET("/api/Filter/SetLatestAccess", {
                filterId: `${value?.Id ?? 0}`,
                filterViewId: String(filterViewId),
            });
        },
        [name],
    );

    const clearFilters = async () => {
        // remove "NextFilter" and anything with the key {name}.CurrentFilter
        // However, some pages carry additional data that we don't want to delete.
        const values = getValues();
        reset({ ...values, [`${name}`]: {}, NextFilter: {} });

        await saveLastFilter();
    };

    const onChange = useCallback(
        async (value?: INamedItemViewModel) => {
            await saveLastFilter(value);
            if (Object.keys(value ?? {}).length <= 0) {
                setValue("NextFilter", {});
            }
        },
        [saveLastFilter, setValue],
    );

    return (
        <>
            {editorOpen && (
                <Popover
                    onClose={() => {
                        setEditorOpen(false);
                        setShouldRefresh(true);
                    }}
                />
            )}
            <ToolbarContainer>
                <div className="pair">
                    <StandardLabel
                        labelFor={"task-toolbar-partial-current-filter"}
                        label={resources.Filters}
                    />
                    <StandardDropDownList
                        url={savedFilterUrl}
                        name={`${name}.CurrentFilter`}
                        filterable={true}
                        onChange={onChange}
                        queryEnabled={false}
                        shouldRefresh={shouldRefresh}
                        setShouldRefresh={setShouldRefresh}
                    />
                </div>
                <PrimaryButton
                    onClick={(e) => {
                        e.preventDefault();
                        setEditorOpen(true);
                    }}
                >
                    {resources.EditFilter}
                </PrimaryButton>
                <SecondaryButton onClick={clearFilters}>
                    {resources.ClearFilters}
                </SecondaryButton>
            </ToolbarContainer>
        </>
    );
};

const ToolbarContainer = styled.div`
    display: flex;
    column-gap: 0.8rem;

    & .pair {
        display: flex;
        column-gap: 0.5rem;
        align-items: center;
        width: 400px;
    }
`;

export default FilterToolbar;

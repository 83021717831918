import React, { useMemo } from "react";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";

import { ProgramContext } from "providers/ProgramProvider";

/**
 * This hook provides all the units for the specific country.
 */
export const useUnits = () => {
    const { program } = React.useContext(ProgramContext);
    const countryId = program?.CountryId;
    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);

    const distanceUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(mi)";
            default:
                return "(km)";
        }
    }, [countryId]);

    const mileageUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(mi)";
            default:
                return "(km)";
        }
    }, [countryId]);

    const tempUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(°F)";
            default:
                return "(°C)";
        }
    }, [countryId]);

    const rateWetFastUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(bpm)";
            default:
                return "(m³/min)";
        }
    }, [countryId]);

    /**
     * Used in:
     *  Volume (CleanVolume, CleanVolumeCumulative)
     *  DownholeCondition (DownholeVolume)
     *  FluidTotal
     *  CombinedDisplacementTotal
     *  DownholeTotal
     */
    const volumeWetMediumUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(gal)";
            case CountryId.Argentina:
                return "(bbl)";
            default:
                return "(m³)";
        }
    }, [countryId]);

    const volumeWetSmallUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(gal)";
            default:
                return "(L)";
        }
    }, [countryId]);

    const volumeActivatorDesignResults = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(gal)";
            default:
                return "(L)";
        }
    }, [countryId]);

    const densityUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(ppg)";
            default:
                return "(kg/m³)";
        }
    }, [countryId]);

    const concentrationPerVolume = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(gal | lbs / bbl)";
            case CountryId.Argentina:
                return "(L | kg / bbl)";
            default:
                return "(L | kg / m³)";
        }
    }, [countryId]);

    const chemicalVolumeUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(lb/gal)";
            case CountryId.Argentina:
                return "(L | kg)";
            default:
                return "(kg/L)";
        }
    }, [countryId]);

    const massSmallUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(lb)";
            default:
                return "(kg)";
        }
    }, [countryId]);

    const massCementLarge = useMemo(() => {
        switch (countryId) {
            case CountryId.Canada:
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(tonnes)";
            default:
                return "(tonnes)";
        }
    }, [countryId]);

    const massCementMedium = useMemo(() => {
        switch (countryId) {
            case CountryId.Canada:
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(bags)";
            default:
                return "(bag)";
        }
    }, [countryId]);

    const massCementSmall = useMemo(() => {
        switch (countryId) {
            case CountryId.Canada:
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(kg)";
            default:
                return "(kg)";
        }
    }, [countryId]);

    const massScheduleUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(lb)";
            default:
                return "(kg)";
        }
    }, [countryId]);

    const massLargeUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(lb)";
            default:
                return "(tonne)";
        }
    }, [countryId]);

    const gasRateUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(scf/min)";
            default:
                return "(scm/min)";
        }
    }, [countryId]);

    const gasRatioUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(scf/bbl)";
            default:
                return "(scm/m³)";
        }
    }, [countryId]);

    const amountGasUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.Argentina:
            case CountryId.UnitedStates:
                return "(scf)";
            default:
                return "(scm)";
        }
    }, [countryId]);

    const ratioWetUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(bbl/bbl)";
            default:
                return "(m³/m³)";
        }
    }, [countryId]);

    const thicknessUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(in)";
            default:
                return "(mm)";
        }
    }, [countryId]);

    const depthUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(ft)";
            default:
                return "(m)";
        }
    }, [countryId]);

    const weightPerLengthUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(lb/ft)";
            default:
                return "(kg/m)";
        }
    }, [countryId]);

    const capacityUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(bbl/ft)";
            case CountryId.Argentina:
                return "(bbl/m)";
            default:
                return "(m³/m)";
        }
    }, [countryId]);

    const pressureLargeUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(psi)";
            default:
                return "(MPa)";
        }
    }, [countryId]);

    const pressureSmallUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(psi)";
            default:
                return "(kPa)";
        }
    }, [countryId]);

    const lengthLargeUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(ft)";
            default:
                return "(m)";
        }
    }, [countryId]);

    const lengthSmallUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(in)";
            default:
                return "(mm)";
        }
    }, [countryId]);

    const lengthSmallUnit2 = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(in)";
            default:
                return "(mm)";
        }
    }, [countryId]);

    const frictionGradientUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(psi/ft)";
            default:
                return "(kPa/m)";
        }
    }, [countryId]);

    const volumeWetLargeUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(bbl)";
            default:
                return "(m³)";
        }
    }, [countryId]);

    const volumeSchedule = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(bbl)";
            default:
                return "(m³)";
        }
    }, [countryId]);

    const fractureGradientUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(psi/ft)";
            default:
                return "(kPa/m)";
        }
    }, [countryId]);

    const squeezeGradientUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(psi/ft)";
            default:
                return "(kPa/m)";
        }
    }, [countryId]);

    const perLengthUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(/ft)";
            case CountryId.Argentina:
                return !isCtan && !isCement ? "(/ft)" : "(/m)";
            default:
                return "(/m)";
        }
    }, [countryId, isCtan, isCement]);

    const concentrationProppantUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(ppg)";
            case CountryId.Argentina:
                return "(ppa)";
            default:
                return "(kg/m³)";
        }
    }, [countryId]);

    const concentrationChemicalWetUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(gpt)";
            default:
                return "(L/m³)";
        }
    }, [countryId]);

    const powerUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(hhp)";
            default:
                return "(kW)";
        }
    }, [countryId]);

    const area = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(ft²)";
            default:
                return "(m²)";
        }
    }, [countryId]);

    const partsPerMillionUnit = "(ppm)";

    const permeabilityUnit = "(mD)";

    const percentageUnit = "(%)";

    const massOrVolume = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(gal | lbs)";
            default:
                return "(L | kg)";
        }
    }, [countryId]);

    const loadingUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(gpt | ppt)";
            default:
                return "(L/m³ | kg/m³)";
        }
    }, [countryId]);

    const runMultiplier = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(gpt)";
            default:
                return "(L/m²)";
        }
    }, [countryId]);

    const speedUnit = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(ft/min)";
            case CountryId.Argentina:
                return isCtan ? "(ft/min)" : "(m/min)";
            default:
                return "(m/min)";
        }
    }, [countryId, isCtan]);

    const secondaryFluidSystemVolume = useMemo(() => {
        switch (countryId) {
            case CountryId.UnitedStates:
                return "(gal)";
            case CountryId.Argentina:
                return "(bbl)";
            default:
                return "(m³)";
        }
    }, [countryId]);

    const pressureTest = useMemo(() => {
        switch (countryId) {
            case CountryId.Canada:
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(psi)";
            default:
                return "(psi)";
        }
    }, [countryId]);

    const volumeBottomsUp = useMemo(() => {
        switch (countryId) {
            case CountryId.Canada:
                return "(m³)";
            case CountryId.UnitedStates:
            case CountryId.Argentina:
                return "(bbl)";
            default:
                return "(m³)";
        }
    }, [countryId]);

    const time = "(min)";
    const hour = "(hour)";

    return {
        distanceUnit,
        mileageUnit,
        tempUnit,
        rateWetFastUnit,
        volumeWetMediumUnit,
        volumeWetSmallUnit,
        volumeActivatorDesignResults,
        densityUnit,
        chemicalVolumeUnit,
        massSmallUnit,
        massLargeUnit,
        gasRateUnit,
        gasRatioUnit,
        amountGasUnit,
        ratioWetUnit,
        thicknessUnit,
        depthUnit,
        weightPerLengthUnit,
        capacityUnit,
        pressureLargeUnit,
        pressureSmallUnit,
        lengthLargeUnit,
        lengthSmallUnit,
        lengthSmallUnit2,
        frictionGradientUnit,
        volumeWetLargeUnit,
        fractureGradientUnit,
        partsPerMillionUnit,
        permeabilityUnit,
        percentageUnit,
        perLengthUnit,
        concentrationProppantUnit,
        concentrationChemicalWetUnit,
        powerUnit,
        time,
        area,
        hour,
        massOrVolume,
        loadingUnit,
        runMultiplier,
        squeezeGradientUnit,
        speedUnit,
        massScheduleUnit,
        volumeSchedule,
        secondaryFluidSystemVolume,
        massCementLarge,
        massCementMedium,
        massCementSmall,
        pressureTest,
        volumeBottomsUp,
        concentrationPerVolume,
    };
};

import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { Cell, flexRender, Row, Table } from "@tanstack/react-table";

import { TableMetaT } from "types/Tables/Cells";

import DragBtnCell from "components/Cells/DragBtnCell";

type TableRowContentProps = {
    row: Row<any>;
    table: Table<any>;
    pinnedColumnBgColor: string;
    draggableProvided?: DraggableProvided;
    draggableColumnId?: string;
};

const InnerTableRow = ({
    row,
    table,
    pinnedColumnBgColor,
    draggableProvided,
    draggableColumnId = "DragHandle",
}: TableRowContentProps) => {
    /**
     * A grouped row will have a non-zero number of subRows. Thus, this returns true if the row is a grouped row, false
     * otherwise.
     * @param row
     */
    const isGroupedRow = (row: Row<any>): boolean => {
        return row.subRows.length > 0;
    };

    /**
     * True if the column that the cell occupies is select-disabled, false otherwise.
     * @param cell
     */
    const isColumnDisabled = (cell: Cell<any, unknown>) => {
        return (table.options.meta as TableMetaT).disableSelectColumns.includes(
            cell.column.id,
        );
    };

    /**
     * True if the table is disabled, false otherwise.
     */
    const isTableDisabled = (): boolean => {
        return (table.options.meta as TableMetaT).disabled;
    };

    /**
     * Determines the CSS classes to apply to the td element. Returns string of space-separated class names.
     * @param row
     * @param cell
     */
    const getCellClassList = (
        row: Row<any>,
        cell: Cell<any, unknown>,
    ): string => {
        const conditionalClassList =
            isColumnDisabled(cell) || isTableDisabled() || isGroupedRow(row)
                ? []
                : [
                      "selecto-cell",
                      "focus-within:bg-calfrac-green-selected",
                      "focus:bg-calfrac-green-selected",
                  ];

        return [
            "[&:has(.read-only):not(.bg-calfrac-green-selected)]:bg-calfrac-gray-100",
            ...conditionalClassList,
            "border",
            "border-gray-200",
            "px-2",
        ].join(" ");
    };

    return (
        <>
            {row.getVisibleCells().map((cell: Cell<any, unknown>) => {
                return (
                    <td
                        key={cell.id}
                        {...{
                            "data-col-id": cell.column.id,
                            "data-row-id": row.id,
                            "data-row-idx": row.id,
                        }}
                        onClick={(e) => {
                            e.currentTarget.querySelector("textarea")?.select();
                        }}
                        className={getCellClassList(row, cell)}
                        style={
                            cell.column.getIsPinned()
                                ? {
                                      zIndex: 2,
                                      width: cell.column.getSize(),
                                      minWidth: cell.column.getSize(),
                                      left: cell.column.getStart("left"),
                                      position: "sticky",
                                      top: 0,
                                      background: pinnedColumnBgColor,
                                      userSelect: "none",
                                  }
                                : {
                                      width: cell.column.getSize(),
                                      minWidth: cell.column.getSize(),
                                      position: "relative",
                                      userSelect: "none",
                                  }
                        }
                    >
                        {cell.column.id === draggableColumnId &&
                            draggableProvided && (
                                <DragBtnCell provided={draggableProvided} />
                            )}
                        {cell.column.id !== draggableColumnId &&
                            (cell.getIsAggregated()
                                ? flexRender(
                                      cell.column.columnDef.aggregatedCell ??
                                          cell.column.columnDef.cell,
                                      cell.getContext(),
                                  )
                                : flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext(),
                                  ))}
                    </td>
                );
            })}
        </>
    );
};

export default InnerTableRow;

import React, { memo, useEffect, useMemo, useReducer } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { IProgramPressureTestItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/ReservoirProperties/IProgramPressureTestItemViewModel";

import NumericCellNullable from "components/Cells/NumericCellNullable";
import TextReadOnly from "components/Cells/TextReadOnly";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type PressureTestTableProps = {
    name: string;
    renderHash?: string;
};

const columnHelper = createColumnHelper<IProgramPressureTestItemViewModel>();

const PressureTestTable: React.FC<PressureTestTableProps> = (props) => {
    const units = useUnits();
    const defaultColumns = useMemo(() => {
        return [
            columnHelper.accessor((row) => row.Event, {
                id: "Event",
                size: 40,
                maxSize: 40,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.Pressure, {
                id: "Pressure",
                header: `${resources.Pressure} ${units.pressureTest}`,
                size: 140,
                cell: NumericCellNullable,
            }),
        ];
    }, [units.pressureTest]);

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    return (
        <BaseTable
            columns={defaultColumns}
            disableSelectColumns={["Event"]}
            name={props.name}
            renderHash={renderHash}
            meta={{
                name: props.name,
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(PressureTestTable);

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Page: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // This page has been deprecated and replaced with the Job page.
        // This redirect is to ensure that any old links to this page are redirected to the new page.
        navigate("/Job/EditJob/0");
    }, [navigate]);

    return <></>;
};

export default Page;

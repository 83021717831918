import React, { useState } from "react";
import { useWatch } from "react-hook-form";

import { IEditSchedulesViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditSchedulesViewModel";

import CementView from "components/Layout/CementView";
import CtanView from "components/Layout/CtanView";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import SchedulesTable from "components/Tables/Schedules/SchedulesTable";

import { useProgramParams } from "hooks/useProgramParams";

import { JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

import WellBorePopup from "./AttachWellboreDiagramsPopup";
import ChemicalsPopup from "./TotalChemicalsPopup";
import FluidsPopup from "./TotalFluidsPopup";

export enum WindowType {
    Chemicals = 0,
    Fluids = 1,
    Wellbore = 3,
}

const Page: React.FC = () => {
    const url: JetApiUrls = "/api/Schedule/EditSchedules";

    const model = useWatch() as IEditSchedulesViewModel;

    const { programId } = useProgramParams();

    // State
    const [windowType, setWindowType] = useState<WindowType | null>();
    const [windowTitle, setWindowTitle] = useState<string>("");
    const [scheduleId, setScheduleId] = useState<number | undefined>();

    return (
        <StandardForm
            className={"h-full"}
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            clearCacheOnSave={["/Task/EditProgramTaskAction"]}
        >
            {windowType === WindowType.Chemicals && (
                <ChemicalsPopup
                    title={windowTitle}
                    scheduleId={scheduleId}
                    onClose={() => setWindowType(null)}
                />
            )}
            <CtanView>
                {windowType === WindowType.Fluids && (
                    <FluidsPopup
                        title={windowTitle}
                        scheduleId={scheduleId}
                        onClose={() => setWindowType(null)}
                    />
                )}
            </CtanView>
            <CementView>
                {windowType === WindowType.Wellbore && (
                    <WellBorePopup
                        title={windowTitle}
                        scheduleId={scheduleId}
                        onClose={() => setWindowType(null)}
                    />
                )}
            </CementView>
            <div
                className={
                    "h-full px-4 pt-6 shadow-md ring-1 ring-calfrac-gray/5"
                }
            >
                <SchedulesTable
                    name={"PumpSchedules"}
                    openWindow={(
                        windowType: WindowType,
                        scheduleIndex: number,
                    ) => {
                        const schedule = model.PumpSchedules[scheduleIndex];
                        setScheduleId(schedule.Id);
                        setWindowType(windowType);

                        let title = "";
                        switch (windowType) {
                            case WindowType.Chemicals:
                                title = resources.TotalChemicals;
                                break;
                            case WindowType.Fluids:
                                title = resources.TotalFluids;
                                break;
                            case WindowType.Wellbore:
                                title = resources.WellboreDiagrams;
                                break;
                        }
                        setWindowTitle(`
                ${resources.ScheduleNumber} ${schedule.ScheduleNumber} - ${title}`);
                    }}
                />
            </div>
        </StandardForm>
    );
};

export default Page;

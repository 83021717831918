import React, { useEffect } from "react";
import { toast } from "react-toastify";

const Page: React.FC = () => {
    useEffect(() => {
        return () => toast.dismiss();
    }, []);

    return <>TODO</>;
};

export default Page;

import React from "react";

import { BaseProps } from "components/shared/CustomGridToolbar/StandardEditGridToolbar";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";

import { useGridState } from "providers/GridStateProvider";

import { resources } from "utils/resources";

const ToggleReadOnly: React.FC<BaseProps> = ({
    isVisible = false,
    ...props
}: BaseProps) => {
    const { isGridEditable } = useGridState();
    if (!isVisible || !isGridEditable) return <></>;
    return (
        <StandardAnchorLink
            text={resources.ToggleReadOnlyColumns.toUpperCase()}
            onClick={props.onClick}
        />
    );
};

export default ToggleReadOnly;

import React, { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { IEditJobViewModel } from "types/generated/Calfrac/Jet/Web/Models/Job/IEditJobViewModel";

import { queryClient } from "AppRoutes/AppProviders";

import { SecondaryButton } from "components/Buttons";
import { H2 } from "components/Headers";
import StandardComboBox from "components/kendoExtensions/standardExtensions/StandardComboBox";
import StandardDatePicker from "components/kendoExtensions/standardExtensions/StandardDatePicker";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardNumericTextBox from "components/kendoExtensions/standardExtensions/StandardNumericTextBox";
import { PageHeaderBar } from "components/Layout";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import { HeaderPortal } from "components/Layout/HeaderPortal";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardReadOnly from "components/shared/GenericCustomComponents/StandardReadOnly";
import StandardSaveButton from "components/shared/GenericCustomComponents/StandardSaveButton";

import useAdminPermissions from "hooks/useAdminPermissions";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { fieldFormats } from "utils/formatLookupTable";
import { resources } from "utils/resources";

import ProgramPage from "views/Program";
import RequestPage from "views/Request";
import TaskPage from "views/Task";

const Page: React.FC = () => {
    const { jobId = "" } = useParams<{
        jobId: string;
    }>();
    const model = useWatch() as IEditJobViewModel;

    const navigate = useNavigate();
    const isCreating = Number(jobId) === 0;
    const [operatorCompanyShouldRefresh, setOperatorCompanyShouldRefresh] =
        useState(false);
    const [operatorContactShouldRefresh, setOperatorContactShouldRefresh] =
        useState(false);

    const { setValue } = useFormContext();

    const [jobNumber, setJobNumber] = useState("");
    const url: JetApiUrls = "/api/Job/EditJobViewModel";
    const [saveUrl, setSaveUrl] = useState<JetApiUrls>("/api/Job/EditJob");
    const [jobCountry, setJobCountry] = useState<number>(0);

    // Operator, Primary Operator (Argentina), Operator Contact, and Account Manager are all editable by all users during create.
    // Once created, only admin can edit these fields.
    const isAdmin = useAdminPermissions();
    const isEditable = isCreating || isAdmin;

    const country =
        model?.CountryId === CountryId.UnitedStates
            ? CountryId.UnitedStates
            : CountryId.Canada;

    useEffect(() => {
        if (!isCreating) {
            fetchJET(`/api/Job/GetJobNumber`, { jobId: jobId }).then((resp) => {
                setJobNumber(resp as string);
            });
            fetchJET(`/api/Job/GetJobCountry`, { jobId: jobId }).then(
                (resp) => {
                    setJobCountry(resp as number);
                },
            );
        }
    }, [isCreating, jobId]);

    const handleSaveSuccess = useCallback(
        (data: { Id?: number }) => {
            // clear the cache for the job.
            queryClient.invalidateQueries({
                predicate: (query) => {
                    return (
                        (query.queryKey[0] as string).startsWith("/api/Job") ||
                        (query.queryKey[0] as string).startsWith("/api/Program")
                    );
                },
                type: "all",
            });

            if (!isCreating || !jobId) return;

            // If we're creating, then after create we want to navigate to the edit page
            const newJobId = data.Id ?? 0;
            navigate(`/Job/EditJob/${newJobId}`, {
                replace: true,
            });
        },
        [isCreating, navigate, jobId],
    );

    return (
        <>
            <PageHeaderBar>
                <HeaderPortal>
                    <div className={"-ml-5 mr-auto flex flex-row pt-1"}>
                        <H2 className={"-ml-4 mr-12"}>{`${
                            jobNumber ? ": " + jobNumber : ""
                        }`}</H2>
                    </div>
                    <StandardSaveButton
                        onClick={() => {
                            setSaveUrl("/api/Job/EditJob");
                        }}
                    />
                    <SecondaryButton
                        onClick={() => {
                            navigate("/Job");
                        }}
                    >
                        {resources.Cancel}
                    </SecondaryButton>
                </HeaderPortal>
            </PageHeaderBar>

            <StandardForm
                readUrl={url}
                readParams={{ jobId }}
                saveUrl={saveUrl}
                onSaveSuccess={handleSaveSuccess}
                disableNavigationPrompt={isCreating}
            >
                <FormSectionDivider>
                    <FormSection
                        label={resources.BasicInformation}
                        className={
                            "grid w-full grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
                        }
                    >
                        <StandardDropDownList
                            name={"CountryId"}
                            url="/api/Program/Country"
                            byField={"Id"}
                            label={resources.Country}
                            disabled={!isCreating}
                            onChange={() => {
                                // When this changes clear the currently selected contact.
                                setValue("OperatorId", undefined, {
                                    shouldDirty: true,
                                });
                                setOperatorCompanyShouldRefresh(true);
                            }}
                        />

                        {/* Operator */}
                        <StandardDropDownList
                            name={"OperatorId"}
                            url={`/api/Program/Account`}
                            params={{
                                countryId: model?.CountryId?.toString() ?? "",
                            }}
                            byField={"Id"}
                            disabled={!isEditable}
                            label={resources.Operator}
                            onChange={() => {
                                // When this changes clear the currently selected contact.
                                setValue("OperatorContactId", undefined, {
                                    shouldDirty: true,
                                });
                                // When this changes clear the PadDescription
                                setValue("PadDescription", undefined, {
                                    shouldDirty: true,
                                });
                                setOperatorContactShouldRefresh(true);
                            }}
                            shouldRefresh={operatorCompanyShouldRefresh}
                            setShouldRefresh={setOperatorCompanyShouldRefresh}
                        />

                        {/* Primary Operator (Argentina) */}
                        {model.CountryId === CountryId.Argentina && (
                            <StandardDropDownList
                                name={"PrimaryOperatorId"}
                                url={`/api/Program/PrimaryAccount`}
                                params={{
                                    countryId:
                                        model?.CountryId?.toString() ?? "",
                                }}
                                byField={"Id"}
                                disabled={!isEditable}
                                label={resources.PrimaryOperator}
                            />
                        )}

                        {/* Operator Contact */}
                        <StandardDropDownList
                            name={"OperatorContactId"}
                            url={`/api/Job/Contact`}
                            queryKey={[model.OperatorId]}
                            params={{
                                customerCompanyId:
                                    model?.OperatorId?.toString() ?? "",
                                countryId: model?.CountryId?.toString() ?? "",
                            }}
                            byField={"Id"}
                            disabled={!isEditable}
                            label={resources.OperatorContact}
                            shouldRefresh={operatorContactShouldRefresh}
                            setShouldRefresh={setOperatorContactShouldRefresh}
                        />

                        {/* Account Manager */}
                        <StandardDropDownList
                            name={"AccountManagerId"}
                            url={`/api/Program/AccountManager`}
                            label={resources.AccountManager}
                            byField={"Id"}
                            disabled={!isEditable}
                            hasIcon={true}
                            tooltipText={
                                resources.ThisFieldAppliesToOfficialProgram
                            }
                        />
                        <StandardReadOnly
                            name={"CreatedBy"}
                            label={resources.CreatedBy}
                        />
                        <StandardDatePicker
                            name={"EstimatedJobStartDate"}
                            label={resources.EstimatedJobStartDate}
                        />
                        <StandardComboBox
                            name={"PadDescription"}
                            url={`/api/Job/GetPadNames`}
                            label={resources.PadName}
                            params={{
                                countryId: model?.CountryId?.toString() ?? "",
                                operatorAccountId: model?.OperatorId
                                    ? model?.OperatorId!.toString()
                                    : "",
                            }}
                        />
                        <StandardNumericTextBox
                            name="PadLatitude"
                            label={resources.PadLatitude}
                            innerProps={{ min: -90, max: 90 }}
                            format={fieldFormats["WellData.Latitude"][country]}
                        />
                        <StandardNumericTextBox
                            name="PadLongitude"
                            label={resources.PadLongitude}
                            innerProps={{ min: -180, max: 180 }}
                            format={fieldFormats["WellData.Longitude"][country]}
                        />
                        <StandardReadOnly
                            name={"CreatedDateDisplay"}
                            label={resources.CreatedDate}
                        />
                    </FormSection>
                </FormSectionDivider>
            </StandardForm>

            {!isCreating && (
                <div className={"-mr-8 overflow-auto pe-4"}>
                    <div className={"vh-50"}>
                        <RequestPage
                            title={"Requests"}
                            jobId={jobId}
                            jobCountry={jobCountry}
                        />
                    </div>
                    <div className={"vh-50"}>
                        <ProgramPage
                            title={"Programs"}
                            jobId={jobId}
                            serverFilter={[
                                {
                                    field: "JobId",
                                    operator: "eq",
                                    value: jobId,
                                },
                                {
                                    field: "ProgramWorkflowStatus",
                                    operator: "neq",
                                    value: "Revised",
                                },
                            ]}
                            jobCountry={jobCountry}
                        />
                    </div>
                    <div className={"vh-50"}>
                        <TaskPage
                            title={"Tasks"}
                            jobId={jobId}
                            jobCountry={jobCountry}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Page;

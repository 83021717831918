import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import { ConcentrationCalculationModeId } from "types/generated/Calfrac/Jet/Core/Models/ConcentrationCalculationModeId";

import { BackCalculatePumpScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/BackCalculatePumpScheduleStageTarget";
import { FormulaCalculateScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/FormulaCalculateScheduleStageTarget";
import { IEditScheduleStagesViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditScheduleStagesViewModel";
import { IEditScheduleStageViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditScheduleStageViewModel";
import { IEditStageCalculationViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditStageCalculationViewModel";
import { IErrorableCalculationId } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IErrorableCalculationId";

import { queryClient } from "AppRoutes/AppProviders";

import { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";
import NoteSection from "components/Tables/Schedules/NoteSection";

import { useProgramParams } from "hooks/useProgramParams";
import useStagesColumns from "hooks/useStagesColumns";

import { makeSequentialField } from "utils/makeSequentialField";
import { resources } from "utils/resources";

import BaseStagesTable from "./BaseStagesTable";

type StagesTableProps = {
    name: string;
    onEditNotes: () => void;
    getNewRecord: () => Partial<IEditScheduleStageViewModel>;
    onBackCalculate: (
        colId: string,
        rowIdx: number,
        target: BackCalculatePumpScheduleStageTarget,
        callback: () => void,
    ) => void;
    onFormulaCalculate?: (
        colId: string,
        rowIdx: number,
        target: FormulaCalculateScheduleStageTarget,
        callback: () => void,
    ) => void;
    renderHash?: string;
};

const StagesTable: React.FC<StagesTableProps> = (props: StagesTableProps) => {
    const model: IEditScheduleStagesViewModel =
        useWatch() as IEditScheduleStagesViewModel;

    const data: (IEditScheduleStageViewModel & any)[] = useWatch({
        name: props.name,
    });
    const { scheduleId } = useParams();
    const { setValue, getValues } = useFormContext();
    const { isPageEditable, program } = useProgramParams();

    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const [readOnlyHidden, setReadOnlyHidden] = useState(false);
    const [showNotesView, setShowNotesView] = useState(true);

    const { columns, order } = useStagesColumns();

    const hiddenColumns = {
        ProgramProppant: !isCement,
        Proppant: !isCement,
        ProgramCement: isCement,
        CopyAction: isPageEditable,
        Delete: isPageEditable,
        FluidType: isCtan,
        Nitrogen: !isCement && model?.HasN2,
        CarbonDioxide: !isCement && model?.HasCO2,
        SecondaryFluidSystem: !isCement && model?.HasSecondaryFluidSystem,
        DownholeCondition:
            !isCement && (model?.HasEnergy || model?.HasSecondaryFluidSystem),
        SlurryRate: !isCtan,
        CleanVolume: !isCtan,
        ProppantStage: !readOnlyHidden && !isCement,
        CleanRateStart: !readOnlyHidden && !isCtan && !isCement,
        CleanVolumeCumulative: !readOnlyHidden && !isCtan,
        Density: isCement,
        CementStage: isCement,
        CementStageCumulative: !readOnlyHidden && isCement,
        BlenderConcentrationStart:
            (model?.ConcentrationCalcMode ===
                ConcentrationCalculationModeId.Surface ||
                model?.ConcentrationCalcMode ===
                    ConcentrationCalculationModeId.Downhole) &&
            !readOnlyHidden &&
            !isCtan &&
            !isCement,
        BlenderConcentrationEnd:
            (model?.ConcentrationCalcMode ===
                ConcentrationCalculationModeId.Surface ||
                model?.ConcentrationCalcMode ===
                    ConcentrationCalculationModeId.Downhole) &&
            !readOnlyHidden &&
            !isCtan &&
            !isCement,
        DownholeConcentrationStart:
            !readOnlyHidden &&
            !isCtan &&
            !isCement &&
            (((model?.HasEnergy || model?.HasSecondaryFluidSystem) &&
                model?.ConcentrationCalcMode ===
                    ConcentrationCalculationModeId.Surface) ||
                model?.ConcentrationCalcMode ===
                    ConcentrationCalculationModeId.Downhole),
        DownholeConcentrationEnd:
            !readOnlyHidden &&
            !isCtan &&
            !isCement &&
            (((model?.HasEnergy || model?.HasSecondaryFluidSystem) &&
                model?.ConcentrationCalcMode ===
                    ConcentrationCalculationModeId.Surface) ||
                model?.ConcentrationCalcMode ===
                    ConcentrationCalculationModeId.Downhole),
        ProppantStageCumulative: !readOnlyHidden && !isCement,
        N2Rate: model?.HasN2 || isCtan,
        N2Stage: model?.HasN2 && !isCtan,
        DownholeN2Rate: model?.HasN2 && !readOnlyHidden && !isCtan,
        N2Ratio: model?.HasN2 && !readOnlyHidden && !isCtan,
        N2StageCumulative: model?.HasN2 && !readOnlyHidden && !isCtan,

        CO2Rate: model?.HasCO2 && !isCtan,
        CO2Stage: model?.HasCO2 && !isCtan,
        CO2Ratio: model?.HasCO2 && !readOnlyHidden && !isCtan,
        CO2StageCumulative: model?.HasCO2 && !readOnlyHidden && !isCtan,

        SecondaryProgramFluidSystem:
            model?.HasSecondaryFluidSystem && !isCtan && !isCement,
        SecondaryFluidSystemRatio:
            model?.HasSecondaryFluidSystem &&
            !readOnlyHidden &&
            !isCtan &&
            !isCement,
        SecondaryFluidSystemStageCumulative:
            model?.HasSecondaryFluidSystem &&
            !readOnlyHidden &&
            !isCtan &&
            !isCement,
        SecondaryFluidSystemRate:
            model?.HasSecondaryFluidSystem && !isCtan && !isCement,
        SecondaryFluidSystemStage:
            model?.HasSecondaryFluidSystem && !isCtan && !isCement,

        CombinedDisplacementRate: isCtan && model?.HasN2,
        CombinedDisplacementTotal: isCtan && model?.HasN2,

        DownholeRate:
            (!isCtan &&
                !isCement &&
                (model?.HasEnergy || model?.HasSecondaryFluidSystem) &&
                !readOnlyHidden) ||
            (isCtan && model?.HasN2 && !readOnlyHidden),
        DownholeTotal: isCtan && model?.HasN2 && !readOnlyHidden,
        DownholeVolume:
            (model?.HasEnergy || model?.HasSecondaryFluidSystem) &&
            !readOnlyHidden &&
            !isCtan &&
            !isCement,
        DownholeFoamQuality:
            (model?.HasEnergy || model?.HasSecondaryFluidSystem) &&
            !readOnlyHidden &&
            !isCtan &&
            !isCement,
        StageTimeCumulative: !readOnlyHidden && !isCtan,
        N2Total: isCtan,
        NetMeter: !readOnlyHidden,
    };

    const disableSelectColumns = useMemo(() => {
        const disabled = [
            "Delete",
            "CopyAction",
            "CleanRateStart",
            "CleanVolumeCumulative",
            "DownholeN2Rate",
            "N2Ratio",
            "N2StageCumulative",
            "CO2Ratio",
            "CO2StageCumulative",
            "SecondaryFluidSystemRatio",
            "StageTimeCumulative",
            "SecondaryFluidSystemStageCumulative",
        ];
        if (
            model?.ConcentrationCalcMode ===
            ConcentrationCalculationModeId.Downhole
        ) {
            disabled.push("BlenderConcentrationStart");
            disabled.push("BlenderConcentrationEnd");
        }
        return disabled;
    }, [model?.ConcentrationCalcMode]);

    const singleSelectColumns = useMemo(() => {
        const singleSelect = [
            "ProppantStage",
            "ProppantStageCumulative",
            "DownholeRate",
            "DownholeVolume",
            "DownholeFoamQuality",
        ];
        if (
            model?.ConcentrationCalcMode ===
            ConcentrationCalculationModeId.Surface
        ) {
            singleSelect.push("DownholeConcentrationStart");
            singleSelect.push("DownholeConcentrationEnd");
        }

        return singleSelect;
    }, [model?.ConcentrationCalcMode]);

    const tableMeta = {
        canDelete: isPageEditable,
        calculations: model?.Calculations ?? [],
        urls: {
            StageDescription: `/api/Schedule/StageDescription?serviceLineId=${program?.ServiceLineId}&scheduleId=${scheduleId}`,
            ProgramFluidSystem: `/api/Schedule/ProgramFluidSystem?programId=${program?.ProgramId}`,
            SecondaryProgramFluidSystem: `/api/Schedule/ProgramFluidSystem`,
            ProgramProppant: `/api/Schedule/ProgramProppant`,
            ProgramCement: `/api/Schedule/ProgramCement`,
            Description: `/api/Schedule/Descriptions?scheduleId=${scheduleId}`,
        },
        deleteRecord: (rowId: number) => {
            if (
                !window.confirm(resources.AreYouSureYouWantToDeleteThisRecord)
            ) {
                return;
            }
            // @ts-ignore
            let newData = data.filter((e) => e.Id !== rowId);
            newData = makeSequentialField(newData, "StageNumber");

            setValue(props.name, newData, { shouldDirty: true });
            updateRenderHash();
        },
        copyRow: (rowId: string, rowIndex: number) => {
            // Note: we do not allow the user to click the "+" (copy row) button on those stage rows with any stage
            // calculation error, so we can be confident that if we are here, this is a row that does NOT have any stage
            // calculation errors. Thus, we do not need to check formState.isDirty; it may be dirty or not, and that is okay,
            // we still allow them to copy as many (non-error) rows as they wish before saving, even if there are (non-error)
            // calculations on the row.
            const newStageId: string = `NEW-${Math.random()
                .toString(36)
                .substring(2, 15)}`;
            const row: IEditScheduleStageViewModel | undefined = data.find(
                (row: IEditScheduleStageViewModel) => {
                    return row.Id === rowId;
                },
            );

            // inset the current row back into data
            const newData = makeSequentialField(
                [
                    ...data.slice(0, rowIndex + 1),
                    {
                        ...row,
                        Id: newStageId,
                    },
                    ...data.slice(rowIndex + 1),
                ],
                "StageNumber",
            );

            let calculationsToCopy: IEditStageCalculationViewModel[] = [];
            if (row !== undefined && typeof row?.Id === "string") {
                calculationsToCopy = (model?.Calculations ?? []).filter(
                    (c: IEditStageCalculationViewModel) => {
                        return c.PumpScheduleStageId === row.Id;
                    },
                );
            }

            const newCalculations: IEditStageCalculationViewModel[] = [];
            for (const calc of calculationsToCopy) {
                const newCalculationId = `NEW-${Math.random()
                    .toString(36)
                    .substring(2, 15)}`;
                newCalculations.push({
                    ...calc,
                    PumpScheduleStageId: newStageId,
                    Id: newCalculationId,
                });
            }

            setValue(
                "Calculations",
                [...model.Calculations, ...newCalculations],
                {
                    shouldDirty: true,
                },
            );
            setValue(props.name, newData, { shouldDirty: true });
            updateRenderHash();
        },
        updateNumericCellData: (
            rowIndex: number,
            columnId: string,
            value: unknown,
            calculationId: IErrorableCalculationId | undefined,
        ) => {
            if (rowIndex > (data?.length ?? 0) - 1) return;

            // Only for CTAN: Remove calculation if user entered a value on a cell with active calculation
            if (isCtan && calculationId) {
                const idx = model?.DeletedCalculationIds.findIndex(
                    (o: IErrorableCalculationId) =>
                        o.Id === calculationId.Id &&
                        o.IsError === calculationId.IsError,
                );
                if (idx === -1) {
                    let newDeletedCalculationIds: IErrorableCalculationId[] = [
                        ...(model?.DeletedCalculationIds ?? []),
                        {
                            Id: calculationId.Id,
                            IsError: calculationId.IsError,
                        },
                    ];
                    setValue("DeletedCalculationIds", newDeletedCalculationIds);
                }
            }

            const valueName = `${props.name}.${rowIndex}.${columnId}`;
            const lastValue = getValues(valueName);
            if (
                lastValue === value ||
                Math.abs(lastValue - ((value as number) ?? 0)) < 0.0001
            ) {
                return;
            }

            // Logic for surface Blender Concentration Start and End
            const isConcentrationCol =
                columnId === "BlenderConcentrationStart" ||
                columnId === "DownholeConcentrationStart";
            const concentrationEndName = `${props.name}.${rowIndex}.${
                columnId === "BlenderConcentrationStart"
                    ? "BlenderConcentrationEnd"
                    : "DownholeConcentrationEnd"
            }`;
            const currentConcentrationStart = getValues(valueName);
            const currentConcentrationEnd = getValues(concentrationEndName);
            if (
                isConcentrationCol &&
                currentConcentrationStart === currentConcentrationEnd
            ) {
                setValue(concentrationEndName, value, { shouldDirty: true });
                updateRenderHash(); // We must re-render the end concentration cell
            }

            // Skip page index reset until after next rerender
            setValue(valueName, value, { shouldDirty: true });
        },
    };

    const tableRef = useRef<HTMLTableElement>(null);

    const afterAddRecord = useCallback(
        (newValue: any[]) => {
            const newData = makeSequentialField(newValue, "StageNumber");

            setValue(props.name, newData, {
                shouldDirty: true,
            });

            updateRenderHash();

            setTimeout(() => {
                if (!tableRef.current) return;
                // if table ref is defined, scroll to the bottom
                const tBody = tableRef.current?.tBodies?.[0];

                if (!tBody) return;
                // find the last row

                // scroll to the bottom of the table

                const tRow = tBody?.rows[tBody.rows.length - 1];

                if (!tRow) return;

                // scroll into view
                tRow.scrollIntoView({
                    behavior: "smooth",
                });

                // find the first input cell and focus
                tRow.querySelector("textarea")?.select();
            }, 100);
        },
        [props.name, tableRef, setValue],
    );

    const toolbarExtras = () => {
        return (
            <>
                <span
                    onClick={props.onEditNotes}
                    className={
                        "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                    }
                >
                    {resources.EditNotes}
                </span>
                <span
                    onClick={() => setReadOnlyHidden((c) => !c)}
                    className={
                        "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                    }
                >
                    {resources.ToggleReadOnlyColumns}
                </span>
                <span
                    onClick={() => setShowNotesView((c) => !c)}
                    className={
                        "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                    }
                >
                    {resources.ToggleNotesView}
                </span>
            </>
        );
    };

    const toolbarBody = () => {
        return (
            <>
                <NoteSection
                    isVisible={showNotesView}
                    notes={model.StagesScheduleNotes?.filter(
                        (note) => note?.DisplayOnOutput,
                    )?.map((note) => note?.Note?.Name)}
                />
            </>
        );
    };

    return (
        <BaseStagesTable
            tableRef={tableRef}
            name={props.name}
            className={"!border-separate !border-spacing-0"}
            afterAddRecord={afterAddRecord}
            defaultRecord={props.getNewRecord()}
            copyDownEnabled={true}
            onBackCalculate={props.onBackCalculate}
            onFormulaCalculate={(colId, rowIdx, target) => {
                if (props.onFormulaCalculate) {
                    props.onFormulaCalculate(colId, rowIdx, target, () => {
                        queryClient.invalidateQueries({
                            predicate: (query) => {
                                return (
                                    query.queryKey[0] as string
                                )?.startsWith(
                                    "/api/Schedule/EditScheduleStages",
                                );
                            },
                            type: "all",
                        });
                    });
                }
            }}
            toolbarExtras={toolbarExtras}
            toolbarBody={toolbarBody}
            columns={columns ?? []}
            columnVisibility={hiddenColumns}
            disableSelectColumns={disableSelectColumns}
            singleSelectColumns={singleSelectColumns}
            columnOrder={order ?? []}
            pinnedColumns={[
                "Delete",
                "CopyAction",
                "StageNumber",
                "StageDescription",
            ]}
            renderHash={renderHash}
            meta={tableMeta}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default StagesTable;

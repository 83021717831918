import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { Dialog } from "@progress/kendo-react-all";

import { IEditSchedulesViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditSchedulesViewModel";

import FormSection from "components/Layout/FormSection";
import AttachmentTable from "components/Tables/Task/AttachmentTable";

import { resources } from "utils/resources";

type Props = {
    title?: string;
    scheduleId?: number;
    onClose?: () => void;
};

const WellborePopup: React.FC<Props> = ({
    scheduleId,
    onClose,
    ...props
}: Props) => {
    const model = useWatch() as IEditSchedulesViewModel;

    const scheduleIdx = useMemo(() => {
        return model.PumpSchedules.findIndex((ps) => ps.Id === scheduleId);
    }, [model.PumpSchedules, scheduleId]);

    return (
        <Dialog
            title={props.title}
            onClose={onClose}
            width={1500}
            height={300}
            themeColor={"primary"}
        >
            <p className={"w-100 p-2 font-bold text-calfrac-gray"}>
                {resources.FileUploadLimitations}
            </p>
            <FormSection>
                <AttachmentTable
                    name={`PumpSchedules.${scheduleIdx}.ScheduleAttachments`}
                    canAddNewFile={true}
                    allowedExtensions={["pdf"]}
                />
            </FormSection>
        </Dialog>
    );
};

export default WellborePopup;

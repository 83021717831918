import React from "react";
import { Link } from "react-router-dom";
import { CellContext } from "@tanstack/react-table";

const HyperLinkCell = ({
    getValue,
    cell,
    to,
    className,
    readOnly = false,
}: CellContext<any, any> & {
    to?: string;
    className?: string;
    readOnly?: boolean;
}) => {
    const text = getValue();
    const dataItem = cell.row.original;

    return (
        <div className={`${readOnly ? "read-only" : ""}`}>
            <Link
                to={to ?? dataItem.Hyperlink}
                className={`cursor-pointer text-calfrac-green underline hover:text-calfrac-green-800 ${className}`}
                target="_blank"
            >
                {text as string}
            </Link>
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(HyperLinkCell, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return (
        prevValue === nextValue && prevProps.className === nextProps.className
    );
});

import React, { useCallback, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
    CheckboxChangeEvent,
    Dialog,
    GridColumn,
} from "@progress/kendo-react-all";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useMutation } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";
import styled from "styled-components";

import { PricingItemTypeId } from "types/generated/Calfrac/Jet/Core/Models/PricingItemTypeId";

import { IEditPricingViewModel } from "types/generated/Calfrac/Jet/Web/Models/Pricing/IEditPricingViewModel";
import { IProgramIndexItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Program/IProgramIndexItemViewModel";

import { PrimaryButton } from "components/Buttons";
import BoundDate from "components/kendoExtensions/gridExtensions/BoundDate";
import StandardIndexGrid from "components/kendoExtensions/grids/StandardIndexGrid";
import {
    CountryFilterCell,
    CTTreatmentObjectiveFilterCell,
    ServiceLineFilterCell,
} from "components/shared/Filter/FilterDropdowns";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import { DATE_COLUMN_PROPS } from "const/columns";

import { useProgramParams } from "hooks/useProgramParams";

import { saveJET } from "utils/fetchJet";
import { setFormErrors } from "utils/helpers";
import { resources } from "utils/resources";

import { DEFAULT_SORT } from "views/Program";

// Type to store the state of the checkboxes
interface PricingSectionCheckBox {
    Equipment: boolean;
    AddEquipment: boolean;
    Materials: boolean;
    AddMaterials: boolean;
    Cartage: boolean;
    AddCartage: boolean;
    AdditionalItems: boolean;
    AddAdditionalItems: boolean;
}

const keyToNumberMapping = {
    Equipment: PricingItemTypeId.Equipment,
    AddEquipment: PricingItemTypeId.AdditionalEquipment,
    Materials: PricingItemTypeId.Material,
    AddMaterials: PricingItemTypeId.AdditionalMaterial,
    Cartage: PricingItemTypeId.Cartage,
    AddCartage: PricingItemTypeId.AdditionalCartage,
    AdditionalItems: PricingItemTypeId.AdditionalItem,
    AddAdditionalItems: PricingItemTypeId.CustomAdditionalItem,
};

const CopyPricingDialogue: React.FC<{ afterUpdate: () => void }> = ({
    afterUpdate,
}) => {
    // Hooks
    const model = useWatch() as IEditPricingViewModel;
    const { programId } = useProgramParams();
    const { setValue, setError, clearErrors } = useFormContext();

    const defaultCheckboxes = useMemo(() => {
        return {
            Equipment: true,
            AddEquipment: true,
            Materials: true,
            AddMaterials: true,
            Cartage: true,
            AddCartage: true,
            AdditionalItems: true,
            AddAdditionalItems: true,
        };
    }, []);

    // States
    const [visible, setVisible] = React.useState(false);

    const [showPrograms, setShowPrograms] = useState(true);
    const [checkboxes, setCheckboxes] =
        useState<PricingSectionCheckBox>(defaultCheckboxes);
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Callbacks
    const onChangeSelectedState = useCallback(
        (val: { [id: string]: boolean | number[] }) => {
            setSelectedState(val);
        },
        [],
    );

    const mutation = useMutation({
        mutationFn: async () => {
            // The first key in the object is the ID of the selected program
            const sourceProgramId = Number(Object.keys(selectedState)[0]);

            const someChecked = Object.values(checkboxes).some(
                (value) => value === true,
            );

            if (!someChecked) {
                alert(resources.AtLeastOnePricingSectionIsRequired);
                return;
            }

            setIsLoading(true);

            const pricingSections = Object.entries(checkboxes)
                .filter(([, value]) => value === true)
                .map(
                    ([key]) =>
                        keyToNumberMapping[
                            key as keyof typeof keyToNumberMapping
                        ],
                );

            const postData = {
                targetProgramId: programId,
                sourceProgramId: sourceProgramId,
                pricingSections: pricingSections,
            };

            await saveJET<Record<string, any>>(
                "/api/Pricing/GetPricingItems",
                {},
                serialize(postData, { indices: true }),
            )
                .then((res) => {
                    if (res.Errors) {
                        setFormErrors(res.Errors, setError);
                        setIsLoading(false);
                        setVisible(false);
                        return res;
                    }
                    return res.json();
                })
                .then((res: IEditPricingViewModel) => {
                    if (!res) {
                        alert(resources.Error);
                        return;
                    }

                    const fields: (keyof IEditPricingViewModel)[] = [
                        "PricingAdditionalCartage",
                        "PricingAdditionalEquipment",
                        "PricingAdditionalItems",
                        "PricingAdditionalMaterials",
                        "PricingCartage",
                        "PricingEquipment",
                        "PricingMaterials",
                        "PricingCustomAdditionalItems",
                        "TotalAdditionalItemsPrice",
                        "TotalCartagePrice",
                        "TotalDiscountPercentage",
                        "TotalDiscountValue",
                        "TotalEquipmentPrice",
                        "TotalMaterialsPrice",
                        "EstimatedGrandTotal",
                        "GrandTotal",
                        "GrandTotalAveragePerStage",
                        "GrandTotalBeforeDiscount",
                        "GrandTotalFirstStage",
                        "HeavyEquipmentTotal",
                        "GrandTotalOtherStages",
                        "PumpsTotal",
                        "PricingEquipmentSubSections",
                    ];

                    for (const field of fields) {
                        const val = res[field];
                        // Fill in NEW-id for rows that have a 0 ID
                        if (val !== null) {
                            for (const row of val) {
                                if (row.Id === 0) {
                                    let key = Math.random()
                                        .toString(36)
                                        .substring(2, 15);
                                    row.Id = `NEW-${key}`;
                                }
                            }
                        }
                        setValue(field, val, { shouldDirty: true });
                    }

                    afterUpdate();
                    setShowPrograms(true);
                    setIsLoading(false);
                    setVisible(false);
                });
        },
    });

    const copyHandler = useCallback(() => {
        clearErrors();
        mutation.mutate();
    }, [mutation, clearErrors]);

    const selectProgramHandler = useCallback(() => {
        if (Object.keys(selectedState).length !== 0) setShowPrograms(false);
        else alert(resources.PleaseSelectTheProgramFirst);
    }, [selectedState]);

    // close modal and reset states so on cancel and reopen it does not maintain previous user inputs
    const handleClose = useCallback(() => {
        setVisible(false);
        setSelectedState({});
        setShowPrograms(true);
        setCheckboxes(defaultCheckboxes);
    }, [defaultCheckboxes]);

    const handleCheckboxChange =
        (key: keyof typeof checkboxes) => (e: CheckboxChangeEvent) => {
            setCheckboxes((prev) => ({ ...prev, [key]: e.target.value }));
        };

    return (
        <>
            {visible && (
                <Dialog
                    title={
                        <div className={"flex items-center justify-end gap-2"}>
                            {!showPrograms && (
                                <ArrowLeftIcon
                                    className={"h-4 w-4 cursor-pointer"}
                                    onClick={() => setShowPrograms(true)}
                                />
                            )}
                            {resources.CopyPricingItems}
                        </div>
                    }
                    onClose={handleClose}
                    height={600}
                    width={1220}
                    themeColor={"primary"}
                >
                    {isLoading && <LoadingOverlay />}
                    {!isLoading && (
                        <div>
                            <GridContainer>
                                {showPrograms && (
                                    <CopyPricingGrid
                                        model={model}
                                        selectedState={selectedState}
                                        onChangeSelectedState={
                                            onChangeSelectedState
                                        }
                                    />
                                )}
                                {!showPrograms && (
                                    <CopyPricingDetails
                                        checkboxes={checkboxes}
                                        handleCheckboxChange={
                                            handleCheckboxChange
                                        }
                                        selectedState={selectedState}
                                    />
                                )}
                            </GridContainer>
                            <footer
                                className={
                                    "flex items-center justify-end gap-4"
                                }
                            >
                                {showPrograms && (
                                    <PrimaryButton
                                        onClick={selectProgramHandler}
                                    >
                                        {resources.SelectProgram}
                                    </PrimaryButton>
                                )}
                                {!showPrograms && (
                                    <PrimaryButton onClick={copyHandler}>
                                        {resources.Copy}
                                    </PrimaryButton>
                                )}
                                <PrimaryButton onClick={handleClose}>
                                    {resources.Cancel}
                                </PrimaryButton>
                            </footer>
                        </div>
                    )}
                </Dialog>
            )}
            <PrimaryButton onClick={() => setVisible(true)}>
                {resources.CopyPricingItems}
            </PrimaryButton>
        </>
    );
};

type CopyPricingDetailsProps = {
    checkboxes: PricingSectionCheckBox;
    selectedState: { [id: string]: boolean | number[] };
    handleCheckboxChange: (
        key: keyof PricingSectionCheckBox,
    ) => (e: CheckboxChangeEvent) => void;
};

const CopyPricingDetails: React.FC<CopyPricingDetailsProps> = (props) => {
    const checkboxes = props.checkboxes;
    return (
        <>
            <fieldset>
                <legend className="text-base font-semibold leading-6 text-gray-900 ">
                    <h2 className={"text-2xl font-bold"}>
                        Program: {Object.keys(props.selectedState)[1]}
                    </h2>
                    <h3 className={"text-lg font-medium"}>
                        Pricing Sections:{" "}
                        {resources.AtLeastOnePricingSectionIsRequired}
                    </h3>
                </legend>
                <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                    {Object.keys(checkboxes).map((key) => (
                        <ListCheckBox
                            key={key}
                            checked={checkboxes[key as keyof typeof checkboxes]}
                            onChange={props.handleCheckboxChange(
                                key as keyof typeof checkboxes,
                            )}
                            label={resources[key as keyof typeof resources]}
                        />
                    ))}
                </div>
            </fieldset>
        </>
    );
};

type ListCheckBoxProps = {
    onChange: (e: CheckboxChangeEvent) => void;
    checked: boolean;
    label: string;
};

const ListCheckBox: React.FC<ListCheckBoxProps> = (props) => {
    return (
        <>
            <div className="relative flex items-start py-2">
                <div className="min-w-0 flex-1 text-sm leading-6">
                    <label
                        htmlFor={`checkbox-${props.label}`}
                        className="select-none font-medium text-gray-900 "
                    >
                        {props.label}
                    </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                    <Checkbox
                        id={props.label}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        checked={props.checked}
                        onChange={props.onChange}
                    />
                </div>
            </div>
        </>
    );
};

type CopyPricingGridProps = {
    onChangeSelectedState: (val: { [id: string]: boolean | number[] }) => void;
    selectedState: { [id: string]: boolean | number[] };
    model: IEditPricingViewModel;
};

const CopyPricingGrid: React.FC<CopyPricingGridProps> = (props) => {
    const model: IEditPricingViewModel = props.model;
    return (
        <>
            <h1 className={"py-2"}>
                {resources.SelectProgramToCopyPricingItemsInstructions}
            </h1>
            <StandardIndexGrid<IProgramIndexItemViewModel>
                path={`/api/Program/GetProgramIndexItems?serviceLineId=${model?.ProgramContext.ServiceLineId}&countryId=${model?.ProgramContext.CountryId}`}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "single",
                }}
                onChangeSelectedState={props.onChangeSelectedState}
                initialState={props.selectedState}
                customKey={"ProgramNumberAndRevision"}
                initialSort={DEFAULT_SORT}
            >
                <GridColumn
                    field={"CreatedDate"}
                    title={resources.CreatedDate}
                    width={130}
                    {...DATE_COLUMN_PROPS}
                />
                <GridColumn
                    field={"ProgramNumberAndRevision"}
                    title={resources.ProgramNumberAndRevision}
                    width={140}
                />
                <GridColumn
                    field={"ProgramWorkflowStatus"}
                    title={resources.ProgramWorkflowStatus}
                    width={180}
                />
                <GridColumn
                    field={"CountryCode"}
                    title={resources.Country}
                    width={100}
                    filterCell={(props) => (
                        <CountryFilterCell
                            gridFilterCellProps={props}
                            url={"/api/Program/CountryCode"}
                        />
                    )}
                />
                <GridColumn
                    field={"ServiceLine"}
                    title={resources.ServiceLine}
                    width={200}
                    filterCell={(props) => (
                        <ServiceLineFilterCell
                            gridFilterCellProps={props}
                            countryId={model.ProgramContext.CountryId}
                        />
                    )}
                />
                <GridColumn
                    field={"OperatorName"}
                    title={resources.Operator}
                    width={200}
                />
                <GridColumn
                    field={"WellIdentifier"}
                    title={resources.WellIdentifier}
                    width={200}
                />
                <GridColumn
                    field={"WellName"}
                    title={resources.WellName}
                    width={200}
                />
                <GridColumn
                    field={"PreparedByName"}
                    title={resources.PreparedBy}
                    width={200}
                />
                <GridColumn
                    field={"ReviewedBy1Name"}
                    title={resources.ReviewedBy1}
                    width={200}
                />
                <GridColumn
                    field={"AccountManagerName1"}
                    title={resources.AccountManager1}
                    width={200}
                />
                <GridColumn
                    field={"AccountManagerName2"}
                    title={resources.AccountManager2}
                    width={200}
                />
                <GridColumn
                    field={"RequestProjectDescription"}
                    title={resources.PadName}
                    width={250}
                />
                <GridColumn
                    field={"ConsultantName"}
                    title={resources.Consultant}
                    width={200}
                />
                <GridColumn
                    field={"ProvinceStateName"}
                    title={resources.ProvinceState}
                    width={200}
                />
                <GridColumn
                    field={"District"}
                    title={resources.District}
                    width={180}
                />
                <GridColumn
                    field={"FluidSystemNames"}
                    title={resources.FluidSystem}
                    width={180}
                />
                <GridColumn
                    field={"FieldName"}
                    title={resources.Field}
                    width={180}
                />
                <GridColumn
                    field={"FormationNames"}
                    title={resources.Formation}
                    width={180}
                />
                <GridColumn
                    field={"TotalProppant"}
                    title={resources.ProppantTotal}
                    width={180}
                />
                <GridColumn
                    field={"CompletionTechnology"}
                    title={resources.CompletionTechnology}
                    width={200}
                />
                <GridColumn
                    field={"ProgramTreatmentObjectives"}
                    title={resources.TreatmentObjective}
                    width={200}
                    filterCell={CTTreatmentObjectiveFilterCell}
                />
                <GridColumn
                    field={"ApprovedDate"}
                    title={resources.ApprovedDate}
                    width={130}
                    cell={(props) => <BoundDate {...props} />}
                    {...DATE_COLUMN_PROPS}
                />
                <GridColumn
                    field={"CopiedFromProgramNumber"}
                    title={resources.CopiedFrom}
                    width={170}
                />
            </StandardIndexGrid>
        </>
    );
};

const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 475px;
`;

export default CopyPricingDialogue;

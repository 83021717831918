import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const IconCell = ({
    overridden = false,
    children = null,
    isEmpty = false,
    classNameIcon = "flex justify-center align-middle",
    ...props
}: any) => {
    if (isEmpty) return <></>;
    return (
        <StyledLink
            title={overridden ? props.overriddenTooltip : props.tooltip}
            className={classNameIcon}
            to={props.to ?? ""}
            overridden={overridden}
        >
            {children}
        </StyledLink>
    );
};

const StyledLink = styled(Link)<{ overridden?: boolean }>`
    position: relative;

    ${(props) =>
        props.overridden &&
        `
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-top: 10px solid #0088cc;
      border-left: 10px solid transparent;
    }
  `}
`;

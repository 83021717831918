// Components
import React from "react";

import StandardNumericTextBox from "components/kendoExtensions/standardExtensions/StandardNumericTextBox";

import { resources } from "utils/resources";

const OffsetAndMultiplierFields = () => {
    return (
        <>
            <StandardNumericTextBox
                label={resources.Multiplier}
                name={"Calculation.OffsetsAndMultipliers.0.Multiplier"}
                defaultValue={1}
            />
            <StandardNumericTextBox
                label={resources.Offset}
                name={"Calculation.OffsetsAndMultipliers.0.Offset"}
                defaultValue={0}
            />
        </>
    );
};

export default OffsetAndMultiplierFields;

import React, { useCallback, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-all";

import { PrimaryButton } from "components/Buttons";
import { EditGridItemType } from "components/kendoExtensions/grids/StandardEditGrid";
import { BaseProps } from "components/shared/CustomGridToolbar/StandardEditGridToolbar";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";

import { useProgramParams } from "hooks/useProgramParams";
import useStatefulQuery from "hooks/useStatefulQuery";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

export interface LoadDefaultsProps extends BaseProps {
    url?: JetApiUrls;
    params?: ConstructorParameters<typeof URLSearchParams>[0];
    warningMsg?: string;
    onNewData?: (newData: Array<EditGridItemType>) => void;
}

const LoadDefaults: React.FC<LoadDefaultsProps> = ({
    url,
    params,
    warningMsg,
    onNewData,
    isVisible = false,
}: LoadDefaultsProps) => {
    // State
    const [visible, setVisible] = useState<boolean>(false);

    const { programId } = useProgramParams();

    // Initializing the form's value
    const [, , { refetch }] = useStatefulQuery({
        queryKey: [url, programId],
        queryFn: async () => {
            return await fetchJET(url, params);
        },
        enabled: false,
    });

    const handleOkClick = useCallback(
        async (e: React.SyntheticEvent) => {
            refetch()
                .then((r) => {
                    if (r.isSuccess) {
                        onNewData?.(r.data as EditGridItemType[]);
                    }
                })
                .finally(() => {
                    e.preventDefault();
                });
            setVisible(!visible);
        },
        [onNewData, refetch, visible],
    );

    if (!isVisible) return <></>;

    return (
        <>
            {visible && (
                <Dialog
                    title={"Please confirm"}
                    onClose={() => setVisible(!visible)}
                >
                    {warningMsg}
                    <DialogActionsBar>
                        <PrimaryButton onClick={handleOkClick}>
                            {resources.Ok}
                        </PrimaryButton>
                        <PrimaryButton onClick={() => setVisible(!visible)}>
                            Cancel
                        </PrimaryButton>
                    </DialogActionsBar>
                </Dialog>
            )}
            <StandardAnchorLink
                text={resources.LoadDefaults.toUpperCase()}
                onClick={() => setVisible(!visible)}
            />
        </>
    );
};

export default LoadDefaults;

import React, { useCallback, useState } from "react";
import { useWatch } from "react-hook-form";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";
import { ProgramWorkflowStatusId } from "types/generated/Calfrac/Jet/Core/Models/ProgramWorkflowStatusId";
import { ServiceLineId } from "types/generated/Calfrac/Jet/Core/Models/ServiceLineId";

import { IEditProgramDataViewModel } from "types/generated/Calfrac/Jet/Web/Models/Program/IEditProgramDataViewModel";

import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import ContactDetails from "components/shared/Details/ContactDetails";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardReadOnly from "components/shared/GenericCustomComponents/StandardReadOnly";
import StandardTextArea from "components/shared/GenericCustomComponents/StandardTextArea";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

import CopyProgram from "./CopyProgram";

const EditProgramData: React.FC = () => {
    // Constants
    const url = "/api/Program/EditProgramData";
    const model = useWatch() as IEditProgramDataViewModel;

    // Url params
    const { programId, program, isPageEditable, refetchContext } =
        useProgramParams();
    const [disableNavigationPrompt, setDisableNavigationPrompt] =
        useState<boolean>(false);
    const [operatorContactShouldRefresh, setOperatorContactShouldRefresh] =
        useState(false);
    const [consultantContactShouldRefresh, setConsultantContactShouldRefresh] =
        useState(false);

    const handleCopyProgram = useCallback(() => {
        setDisableNavigationPrompt(!disableNavigationPrompt);
    }, [disableNavigationPrompt]);

    const isFrac = program?.ServiceLineId === ServiceLineId.Fracturing;
    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);

    return (
        <StandardForm
            readUrl={url}
            readParams={{
                programId,
                countryId: program?.CountryId,
                serviceLineId: program?.ServiceLineId,
            }}
            saveUrl={url}
            extraFormData={{ Id: programId }}
            clearCacheOnSave={["/api/Pricing"]}
            disableNavigationPrompt={disableNavigationPrompt}
            onSaveSuccess={() => {
                refetchContext();
            }}
        >
            <FormSectionDivider>
                <FormSection label={"Internal Data"}>
                    {(isFrac || isCtan || isCement) && (
                        <div className={"col-span-full flex flex-col gap-y-4"}>
                            <CopyProgram
                                canCopy={model?.CanCopy}
                                onCopyProgram={handleCopyProgram}
                            />
                            {model?.CopiedFromProgramNumber && (
                                <div className={"copy-program-link mr-auto"}>
                                    <StandardAnchorLink
                                        to={`/Program/EditProgramData/${
                                            model?.CopiedFromProgramId ??
                                            programId
                                        }`}
                                        text={model?.CopiedFromProgramNumber}
                                        justifyContent={"left"}
                                        label={resources.CopiedFrom}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <StandardReadOnly
                        name={"ProgramNumberAndRevision"}
                        label={resources.ProgramNumberAndRevision}
                        className={"md:col-span-2"}
                    />
                    <StandardReadOnly
                        name={"CountryName"}
                        label={resources.Country}
                    />
                    <StandardReadOnly
                        name={"ServiceLineName"}
                        label={resources.ProgramType}
                    />
                    <StandardDropDownList
                        name={"PreparedById"}
                        url={"/api/Program/GetUser"}
                        params={{
                            currentUserId:
                                model?.PreparedById?.toString() ?? "",
                        }}
                        byField={"Id"}
                        label={resources.PreparedBy}
                        disabled={!isPageEditable}
                    />
                    {program?.CountryId === CountryId.UnitedStates && (
                        <StandardDropDownList
                            name={"PrimaryEngineerContactId"}
                            url={"/api/Program/GetUser"}
                            params={{
                                currentUserId:
                                    model?.PrimaryEngineerContactId?.toString() ??
                                    "",
                            }}
                            byField={"Id"}
                            label={resources.DivisionalEngineer}
                            disabled={!isPageEditable}
                        />
                    )}
                </FormSection>
                <FormSection label={"Basic Information"}>
                    <StandardDropDownList
                        name={"ProgramTypeId"}
                        url={"/api/Program/ProgramType"}
                        params={{
                            programId,
                        }}
                        byField={"Id"}
                        disabled={
                            !isPageEditable ||
                            program?.WorkflowStatusId ===
                                ProgramWorkflowStatusId.InApproval
                        }
                        label={resources.ProgramType}
                    />
                    <StandardTextBox
                        name={"ProgramTypeDescription"}
                        label={resources.ProgramTypeDescription}
                        disabled={!isPageEditable}
                        className={`col-span-${isCtan ? 3 : 5}`}
                    />
                </FormSection>
                <FormSection label={resources.OperatorAccountManagers}>
                    <div className={"col-span-full flex gap-x-4"}>
                        {/* Operator */}
                        <div className="flex h-full w-full flex-col justify-start gap-y-4">
                            <StandardDropDownList
                                name={"OperatorAccountId"}
                                url={"/api/Program/Account"}
                                params={{
                                    countryId:
                                        program?.CountryId?.toString() ?? "",
                                }}
                                byField={"Id"}
                                label={resources.Operator}
                                disabled={!isPageEditable}
                                onChange={() =>
                                    setOperatorContactShouldRefresh(true)
                                }
                            />

                            <ContactDetails
                                name={"OperatorAccountDetails"}
                                url={"/api/Program/AccountDetails"}
                                params={{
                                    accountId:
                                        model?.OperatorAccountId?.toString() ??
                                        "",
                                }}
                            />
                        </div>

                        {/* Operator Contact */}
                        <div className="flex h-full w-full flex-col justify-start gap-y-4">
                            <StandardDropDownList
                                name={"OperatorContactId"}
                                url={"/api/Program/Contact"}
                                params={{
                                    operatorAccountId:
                                        model?.OperatorAccountId?.toString() ??
                                        "",
                                    countryId:
                                        program?.CountryId?.toString() ?? "",
                                }}
                                byField={"Id"}
                                label={resources.OperatorContact}
                                shouldRefresh={operatorContactShouldRefresh}
                                setShouldRefresh={
                                    setOperatorContactShouldRefresh
                                }
                                disabled={
                                    !model?.OperatorAccountId || !isPageEditable
                                }
                            />
                            <ContactDetails
                                name={"OperatorContactDetails"}
                                url={"/api/Program/ContactDetails"}
                                params={{
                                    currentContactId:
                                        model?.OperatorContactId?.toString() ??
                                        "",
                                    accountId:
                                        model?.OperatorAccountId?.toString() ??
                                        "",
                                }}
                            />
                        </div>
                    </div>

                    {/* Primary Operator (Argentina only) */}
                    {program?.CountryId === CountryId.Argentina && (
                        <div className={"col-span-full flex gap-x-4"}>
                            <div className="flex h-full w-full flex-col justify-start gap-y-4">
                                <StandardDropDownList
                                    name={"PrimaryOperatorAccountId"}
                                    url={"/api/Program/PrimaryAccount"}
                                    params={{
                                        countryId:
                                            program?.CountryId?.toString() ??
                                            "",
                                    }}
                                    byField={"Id"}
                                    label={resources.PrimaryOperator}
                                    disabled={!isPageEditable}
                                />
                                <ContactDetails
                                    name={"PrimaryOperatorAccountDetails"}
                                    url={"/api/Program/PrimaryOperatorDetails"}
                                    params={{
                                        primaryOperatorAccountId:
                                            model?.PrimaryOperatorAccountId?.toString() ??
                                            "",
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className={"col-span-full flex gap-x-4"}>
                        <div className="flex h-full w-full flex-col justify-start gap-y-4">
                            <StandardDropDownList
                                name={"ConsultantAccountId"}
                                url={"/api/Program/Account"}
                                params={{
                                    countryId:
                                        program?.CountryId?.toString() ?? "",
                                }}
                                byField={"Id"}
                                label={resources.Consultant}
                                disabled={!isPageEditable}
                                onChange={() =>
                                    setConsultantContactShouldRefresh(true)
                                }
                            />

                            <ContactDetails
                                name={"ConsultantAccountDetails"}
                                url={"/api/Program/AccountDetails"}
                                params={{
                                    accountId:
                                        model?.ConsultantAccountId?.toString() ??
                                        "",
                                }}
                            />
                        </div>
                        <div className="flex h-full w-full flex-col justify-start gap-y-4">
                            <StandardDropDownList
                                name={"ConsultantContactId"}
                                url={"/api/Program/Contact"}
                                params={{
                                    consultantAccountId:
                                        model?.ConsultantAccountId?.toString() ??
                                        "",
                                    countryId:
                                        program?.CountryId?.toString() ?? "",
                                }}
                                byField={"Id"}
                                label={resources.ConsultantContact}
                                disabled={
                                    !model?.ConsultantAccountId ||
                                    !isPageEditable
                                }
                                shouldRefresh={consultantContactShouldRefresh}
                                setShouldRefresh={
                                    setConsultantContactShouldRefresh
                                }
                            />
                            <ContactDetails
                                name={"ConsultantContactDetails"}
                                url={"/api/Program/ContactDetails"}
                                params={{
                                    currentContactId:
                                        model?.ConsultantContactId?.toString() ??
                                        "",
                                    accountId:
                                        model?.ConsultantAccountId?.toString() ??
                                        "",
                                }}
                            />
                        </div>
                    </div>
                    <div className={"col-span-full flex gap-x-4"}>
                        <div className="flex h-full w-full flex-col justify-start gap-y-4">
                            <StandardDropDownList
                                name={"AccountManager1Id"}
                                url={`/api/Program/AccountManager`}
                                label={resources.AccountManager1}
                                byField={"Id"}
                                disabled={!isPageEditable}
                                hasIcon={true}
                                tooltipText={
                                    resources.ThisFieldAppliesToOfficialProgram
                                }
                            />
                            <ContactDetails
                                name={"AccountManager1ContactDetails"}
                                url={"/api/Program/UserDetails"}
                                params={{
                                    userId: String(
                                        model?.AccountManager1Id ?? "",
                                    ),
                                }}
                            />
                        </div>
                        <div className="flex h-full w-full flex-col justify-start gap-y-4">
                            <StandardDropDownList
                                name={"AccountManager2Id"}
                                url={"/api/Program/AccountManager"}
                                byField={"Id"}
                                label={resources.AccountManager2}
                                disabled={!isPageEditable}
                                hasIcon={true}
                                tooltipText={
                                    resources.ThisFieldAppliesToOfficialProgram
                                }
                            />
                            <ContactDetails
                                name={"AccountManager2ContactDetails"}
                                url={"/api/Program/UserDetails"}
                                params={{
                                    userId: String(
                                        model?.AccountManager2Id ?? "",
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </FormSection>
                <FormSection>
                    <StandardTextBox
                        name={"LabReportNumber"}
                        label={resources.LabReportNumber}
                        disabled={!isPageEditable}
                    />
                    <StandardTextBox
                        name={"SimulationNumber"}
                        label={resources.SimulationNumber}
                        disabled={!isPageEditable}
                    />
                </FormSection>
                <FormSection label={"Notes"}>
                    <StandardTextArea
                        name={"Notes"}
                        disabled={!isPageEditable}
                        className={"col-span-full"}
                    />
                </FormSection>
            </FormSectionDivider>
        </StandardForm>
    );
};
export default EditProgramData;

import React, { useCallback, useEffect, useState } from "react";
import { CellContext } from "@tanstack/react-table";

import { TableMetaT } from "types/Tables/Cells";

import BaseNumericCell from "./BaseNumericCell";

const FormattedNumericCell = ({
    getValue,
    row: { index },
    column: { id },
    table,
    applyReadOnlyStyle = false,
    ...props
}: CellContext<any, any> & {
    inViewFormat?: string;
    applyReadOnlyStyle?: boolean;
}) => {
    const initialValue = getValue();

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState<number>(initialValue ?? 0);
    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = useCallback(() => {
        (table.options.meta as TableMetaT)?.updateNumericCellData(
            index,
            id,
            value,
        );
    }, [value, table.options.meta, index, id]);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue ?? 0);
    }, [initialValue]);

    return (
        <BaseNumericCell
            applyReadOnlyStyle={applyReadOnlyStyle}
            table={table}
            onBlur={onBlur}
            rowIndex={index}
            columnId={id}
            value={value}
            setValue={(v) => setValue(v ?? 0)}
            inViewFormat={props.inViewFormat}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(FormattedNumericCell, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return (
        prevValue === nextValue &&
        prevProps.applyReadOnlyStyle === nextProps.applyReadOnlyStyle
    );
});

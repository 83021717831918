import React, { useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

import { IEditProgramTasksViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/IEditProgramTasksViewModel";
import { IOpenTaskViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/IOpenTaskViewModel";

import { PrimaryButton, WarningButton } from "components/Buttons";
import { SingleErrorSummary } from "components/Errors/SingleErrorSummary";
import AdminView from "components/Layout/AdminView";
import FormSection, {
    FormSectionDivider,
    FormSectionFlex,
} from "components/Layout/FormSection";
import { HeaderPortal } from "components/Layout/HeaderPortal";
import StandardLink from "components/shared/GenericCustomComponents/StandardAnchorLink";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";
import TaskHistoryTable from "components/Tables/Task/TaskHistoryTable";

import useInterval from "hooks/useInterval";
import { useProgramParams } from "hooks/useProgramParams";

import { downloadJET, saveJET } from "utils/fetchJet";
import {
    initialPDFGenerationStatus,
    pollPDFGenerationStatus,
} from "utils/pdfGenerationStatus";
import { resources } from "utils/resources";

import EditOpenTask from "./EditOpenTask";
import TaskStepsBar from "./TaskStepsBar";

const Page: React.FC = () => {
    // Constants
    const url = "/api/Task/EditProgramTasks";

    // Url params
    const { programId, program } = useProgramParams();

    let model = useWatch() as IEditProgramTasksViewModel;

    // Callbacks
    const handleUnCancelTheProgram = useCallback(async () => {
        await saveJET("/api/Task/UnCancellTheProgram", { programId }).then(() =>
            window.location.reload(),
        );
    }, [programId]);

    const handleUnApprove = useCallback(async () => {
        await saveJET("/api/Task/UnApproveTheProgram", { programId }).then(() =>
            window.location.reload(),
        );
    }, [programId]);

    const handleReRevise = useCallback(async () => {
        await saveJET("/api/Task/ReviseProgram", { programId }).then(() =>
            window.location.reload(),
        );
    }, [programId]);

    const handleUnRevise = useCallback(async () => {
        await saveJET("/api/Task/UnReviseProgram", { programId }).then(() =>
            window.location.reload(),
        );
    }, [programId]);

    const handleConvertToExecutable = useCallback(async () => {
        await saveJET("/api/Task/ConvertToExecutable", { programId }).then(() =>
            window.location.reload(),
        );
    }, [programId]);

    const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
    const [pollingInterval, setPollingInterval] = useState<number | null>(null);
    const [pdfErrorMessage, setPDFErrorMessage] = useState<string | undefined>(
        undefined,
    );
    const [pdfBlobUrl, setPDFBlobUrl] = useState<string | null>(null);

    // Check if a pdf report is currently being generated and begin polling for status until generation is complete
    useEffect(() => {
        initialPDFGenerationStatus(
            programId,
            (isPDFStillGenerating: boolean) => {
                if (isPDFStillGenerating) {
                    setIsGeneratingPDF(true);
                    setPollingInterval(5000);
                }
            },
        );
    }, [programId]);

    // Polls API endpoint for pdf generation status every specific polling interval (milliseconds).
    // Set polling interval to null to stop polling.
    useInterval(() => {
        pollPDFGenerationStatus(
            programId,
            (
                isPDFStillGenerating: boolean,
                error: boolean,
                errorMsg: string,
                blobUrl: string | null,
            ) => {
                if (!isPDFStillGenerating) {
                    setIsGeneratingPDF(false);
                    setPDFBlobUrl(blobUrl);
                    setPollingInterval(null);
                    setPDFErrorMessage(error ? errorMsg : undefined);
                }
            },
        );
    }, pollingInterval);

    return (
        <>
            <HeaderPortal>
                {(pdfBlobUrl !== null || model.BlobUrl) && !isGeneratingPDF && (
                    <PrimaryButton
                        onClick={() =>
                            downloadJET("/api/AzureBlob/DownloadFile", {
                                path:
                                    pdfBlobUrl !== null
                                        ? pdfBlobUrl
                                        : model?.BlobUrl,
                            })
                        }
                        type={"button"}
                    >
                        {resources.ViewPdf}
                    </PrimaryButton>
                )}
                {model?.CanUnCancelTheProgram && (
                    <PrimaryButton onClick={handleUnCancelTheProgram}>
                        {resources.UnCancelProgram}
                    </PrimaryButton>
                )}
                <AdminView>
                    {model?.CanUnApprove && (
                        <WarningButton onClick={handleUnApprove}>
                            {resources.UnapproveProgram}
                        </WarningButton>
                    )}
                </AdminView>
                {model?.CanRevise && (
                    <WarningButton onClick={handleReRevise}>
                        {resources.ReReviseProgram}
                    </WarningButton>
                )}
                {model?.CanUnRevise && (
                    <WarningButton onClick={handleUnRevise}>
                        {resources.UnReviseProgram}
                    </WarningButton>
                )}
                {model?.CanConvertToExecutableJob && (
                    <PrimaryButton onClick={handleConvertToExecutable}>
                        {resources.ConvertToExecutableJob}
                    </PrimaryButton>
                )}
            </HeaderPortal>
            {pdfErrorMessage && (
                <SingleErrorSummary
                    title={resources.GeneratingPDFError}
                    errorMsg={pdfErrorMessage}
                />
            )}
            <StandardForm
                readUrl={url}
                readParams={{ programId }}
                saveUrl={url}
                ignoreLoadingOverlay={isGeneratingPDF}
            >
                <div className="mt-2 bg-white shadow-sm ring-1 ring-calfrac-gray/5  sm:rounded-xl md:col-span-2">
                    <div className="px-4 py-6 sm:p-8">
                        <div className="flex space-x-4">
                            <StandardLabel
                                labelFor={"edit-program-tasks-request-number"}
                                label={resources.Request}
                            />
                            <div className="form-item-input">
                                {model?.RequestId && (
                                    <StandardLink
                                        to={`/Request/EditRequest/${model?.RequestId}/${
                                            program?.CountryId?.toString() ?? 0
                                        }`}
                                    >
                                        {model?.RequestNumber}
                                    </StandardLink>
                                )}
                            </div>
                        </div>

                        <TaskStepsBar />
                    </div>
                </div>
                <FormSectionDivider>
                    {model?.OpenTasks && model?.OpenTasks.length > 0 && (
                        <FormSectionFlex label={resources.OpenTasks}>
                            <fieldset className="form-section form-section--full">
                                <h3 className="data-grid-heading">
                                    <StandardLabel labelFor={""} />
                                </h3>
                                {model?.OpenTasks?.map(
                                    (ot: IOpenTaskViewModel, idx: number) => (
                                        <EditOpenTask
                                            model={ot}
                                            index={idx}
                                            key={idx}
                                        />
                                    ),
                                )}
                            </fieldset>
                        </FormSectionFlex>
                    )}
                    <FormSection label={resources.TaskHistory}>
                        <TaskHistoryTable name={"TaskHistory"} />
                    </FormSection>
                </FormSectionDivider>
            </StandardForm>
        </>
    );
};

export default Page;

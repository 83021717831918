import React from "react";

import StandardComboBox from "components/kendoExtensions/standardExtensions/StandardComboBox";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import { FilterManager } from "components/shared/Filter/FilterManager";

import { resources } from "utils/resources";

type Props = {
    onClose: () => void;
};

const EditJobFilter: React.FC<Props> = ({ onClose }: Props) => {
    return (
        <FilterManager onClose={onClose} name={"Job"}>
            <StandardTextBox
                name={"NextFilter.FilterName"}
                label={resources.FilterName}
                className={"col-span-full"}
            />
            <StandardDropDownList
                url={"/api/Job/CountryCode"}
                name={"NextFilter.CountryId"}
                byField={"Id"}
                label={resources.Country}
            />
            <StandardComboBox
                url={"/api/Job/PadNames"}
                name={"NextFilter.PadName"}
                label={resources.PadName}
            />
            <StandardDropDownList
                url={"/api/Job/GetOperators"}
                name={"NextFilter.Operator"}
                byField={"Name"}
                label={resources.Operator}
            />
            <StandardDropDownList
                url={"/api/Program/GetAccountManager"}
                name={"NextFilter.AccountManagerId"}
                byField={"Id"}
                label={resources.AccountManager}
            />
            <StandardMultipleSelect
                name={"NextFilter.ServiceLine"}
                url={"/api/Program/ServiceLine"}
                byField={"Id"}
                label={resources.ProgramType}
            />
            <StandardMultipleSelect
                name={"NextFilter.CTTreatmentObjective"}
                url={"/api/Program/GetCTTreatmentObjective"}
                byField={"Name"}
                label={resources.TreatmentObjective}
            />
        </FilterManager>
    );
};

export default EditJobFilter;

import React from "react";
import { useNavigate } from "react-router-dom";

import { SecondaryButton } from "components/Buttons";

import { useProgram } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type Props = {
    onClick?: () => void;
    overrideIsPageEditable?: boolean;
};

const StandardCancelButton: React.FC<Props> = ({
    overrideIsPageEditable,
    ...props
}: Props) => {
    const navigate = useNavigate();
    const { isPageEditable } = useProgram();

    if (!isPageEditable && !overrideIsPageEditable) return <></>;

    return (
        <SecondaryButton
            onClick={() => {
                // If an onClick handler is provided, call it and return
                if (props.onClick) {
                    props.onClick();
                    return;
                }

                // Otherwise, refresh the current page.
                navigate(0);
            }}
        >
            {resources.Cancel}
        </SecondaryButton>
    );
};

export default StandardCancelButton;

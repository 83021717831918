import { Dialog } from "@progress/kendo-react-all";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
    .k-dialog-titlebar {
        background-color: #01996c;
        color: white;
    }

    .k-svg-icon {
        color: white;
    }
`;

export const Wrapper = styled.div<{ padding?: boolean }>`
    position: absolute;
    right: 0;
    height: 100%;
    padding-right: 10px;
    padding-bottom: ${(props) => (props.padding ? "10px" : "0")};
`;

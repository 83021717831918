import React from "react";

import BaseTableBody from "components/Tables/BaseTable/Body/BaseTableBody";
import InnerTable, {
    InnerTableProps,
} from "components/Tables/BaseTable/Inner/InnerTable";

export interface BaseTableProps extends InnerTableProps {}

const BaseTable = (props: BaseTableProps) => {
    return <InnerTable {...props} TableBody={BaseTableBody} />;
};

export default React.memo(BaseTable);

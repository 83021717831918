import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import styled from "styled-components";

import TableCell from "components/kendoExtensions/gridExtensions/TableCell";

import { useGridState } from "providers/GridStateProvider";

import { handleEditableCellKeyDown } from "utils/keyDownHandlers";

import StandardLabel from "./StandardLabel";

type Props = {
    id?: string;
    label?: string;
    sidelabel?: string;
    checked?: boolean;
    className?: string;
    wrapperClassName?: string;
    onChange?: (val: boolean) => void;
    disabled?: boolean;
    // Used with react hook form to register element
    name?: string;
};

export const GridCheckBox: React.FC<
    GridCellProps & { overrideEditable?: boolean; disabled?: boolean }
> = ({ disabled = false, ...props }) => {
    const { clearErrors } = useFormContext();
    const { isGridEditable, formName, readOnlyColumns } = useGridState();

    const { dataItem, dataIndex, field, overrideEditable, className } = props;
    const name = `${formName}.${dataIndex}.${field}`;

    const columnIsReadOnly = useMemo(
        () =>
            field &&
            readOnlyColumns.some((value) => {
                if (typeof value === "string") {
                    return field?.startsWith(value);
                } else {
                    return value.test(field ?? "");
                }
            }),
        [field, readOnlyColumns],
    );
    const isEditable =
        overrideEditable === true ||
        ((dataItem.editable === undefined || dataItem.editable === true) &&
            isGridEditable &&
            !columnIsReadOnly);

    const handleChange = useCallback(() => {
        clearErrors(formName);
    }, [clearErrors, formName]);

    return (
        <TableCell
            {...props}
            className={`${!isEditable && "bg-calfrac-gray-50"}`}
        >
            <StandardCheckBox
                name={name}
                disabled={disabled || !isEditable}
                onChange={handleChange}
                className={className + " flex justify-center"}
            />
        </TableCell>
    );
};

const StandardCheckBox: React.FC<Props> = ({ name = " ", ...props }: Props) => {
    const { setValue, clearErrors } = useFormContext();

    let value = useWatch({ name });

    if (props.checked && props.onChange) {
        value = props.checked;
    }

    const handleChange = useCallback(() => {
        if (name !== " ") {
            setValue(name, !value, { shouldDirty: true });
        }
        props.onChange?.(props.checked ? !props.checked : !value);
    }, [name, props, value, setValue]);

    return (
        <Wrapper
            className={props.wrapperClassName}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleEditableCellKeyDown(e, "input", clearErrors)
            }
        >
            {props.label && <StandardLabel label={props.label} />}
            <Checkbox
                {...props}
                label={props.sidelabel}
                checked={props.checked ?? value ?? false}
                disabled={props.disabled}
                onChange={handleChange}
                className={
                    "text-sm font-medium text-calfrac-gray hover:checked:text-calfrac-green " +
                    props.className
                }
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    // on hover, if checked use the background color green
    input {
        color: #00a279 !important;
    }
`;

export default StandardCheckBox;

import React from "react";
import { ListBulletIcon } from "@heroicons/react/24/outline";
import { CellContext } from "@tanstack/react-table";

import { SchedulesMetaT } from "types/Tables/Cells";

import CtanView from "components/Layout/CtanView";

import NumericReadOnly from "./NumericReadOnly";

const FluidTotalCell = (props: CellContext<any, any>) => {
    return (
        <div className={"read-only d-flex justify-between"}>
            <NumericReadOnly {...props} />
            <CtanView>
                <ListBulletIcon
                    onClick={() =>
                        (
                            props.table.options.meta as SchedulesMetaT
                        ).openFluidsWindow(props.row.index)
                    }
                    className={
                        "w-50 flex h-6 cursor-pointer justify-center text-calfrac-green"
                    }
                    aria-hidden="true"
                />
            </CtanView>
        </div>
    );
};

export default FluidTotalCell;

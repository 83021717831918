import React, { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
    MaskedTextBox,
    MaskedTextBoxChangeEvent,
    MaskedTextBoxProps,
} from "@progress/kendo-react-all";

import { handleStandardNumericTextBoxKeyDown } from "utils/keyDownHandlers";

type Props = {
    id?: string;
    mask: string;
    emptyMask: string;
    value?: string;
    label?: string;
    onChange?: (val: string) => void;
    disabled?: boolean;
    // Used with react hook form to register element
    name?: string;
    hint?: string;
    bold?: boolean;
    // used for futher customization
    size?: "small" | "medium" | "large" | null | undefined;
    className?: string;
};

const StandardMaskedTextBox: React.FC<Props & MaskedTextBoxProps> = ({
    name = " ",
    hint,
    bold = true,
    size = "small",
    ...props
}: Props) => {
    const { setValue, clearErrors } = useFormContext();

    // allows for W in UWI masks to represent any letter,
    // identical to default L but L doesn't make sense to show in the mask for UWIs
    const maskRules = {
        W: /[A-Za-z]/,
        b: /[A-Za-z]/,
        l: /[A-Za-z]/,
        B: /[A-Za-z]/,
    };

    const value = useWatch({ name });

    const handleChange = useCallback(
        (e: MaskedTextBoxChangeEvent) => {
            const value = e.target.value;
            if (value === props.emptyMask) {
                setValue(name, "", { shouldDirty: true });
                return;
            }
            setValue(name, value, { shouldDirty: true });
        },
        [name, props.emptyMask, setValue],
    );

    return (
        <div
            className={"col-span-1 flex flex-col"}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleStandardNumericTextBoxKeyDown(
                    e,
                    name,
                    value ?? "",
                    clearErrors,
                    setValue,
                )
            }
        >
            <label className={`[&>*]:w-full ${bold ? "font-bold" : ""}`}>
                <MaskedTextBox
                    {...props}
                    id={props.id}
                    mask={props.mask}
                    placeholder={props.mask}
                    value={value ?? ""}
                    onChange={handleChange}
                    disabled={props.disabled}
                    className={`${props.className} w-full ${
                        props.disabled ? "bg-calfrac-gray-50 " : ""
                    } text-black`}
                    rules={maskRules}
                    size={size}
                />
            </label>
            {hint && (
                <span className="ml-auto w-full text-right text-sm text-calfrac-gray-300">
                    {hint}
                </span>
            )}
        </div>
    );
};

export default StandardMaskedTextBox;

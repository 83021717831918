import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { handleEditableCellKeyDown } from "utils/keyDownHandlers";

type Props = {
    id?: string;
    value?: string;
    onChange?: (text: string) => void;
    className?: string;
    disabled?: boolean;
    label?: string;
    // Used with react hook form to register element
    name?: string;
    alignRight?: boolean;
    hint?: string;
    alwaysClearErrors?: boolean;
    overrideValue?: string;
    boldTitle?: boolean;
};

const CustomLocalTextBox: React.FC<Props> = ({
    name = " ",
    hint,
    alwaysClearErrors = true,
    boldTitle = false,
    ...props
}: Props) => {
    // Form Hook
    const { setValue, clearErrors } = useFormContext();
    let value = useWatch({ name });

    const [localValue, setLocalValue] = useState<string | undefined>(value);

    const {
        overrideValue,
        onChange,
        label,
        id,
        alignRight,
        className,
        disabled,
    } = props;

    if (overrideValue) {
        value = overrideValue;
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (name !== " ") {
            setValue(name, e.target?.value?.toString(), { shouldDirty: true });
        } else {
            onChange?.(e.target?.value?.toString() ?? "");
        }
        if (alwaysClearErrors) {
            clearErrors(name);
        }
    };

    return (
        <div
            className={`flex flex-col ${className ?? "col-span-1"} ${
                alignRight ? "text-right" : ""
            } `}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleEditableCellKeyDown(e, "input", clearErrors)
            }
        >
            {label && (
                <StandardLabel labelFor={id} label={label} bold={boldTitle} />
            )}
            <TextBox
                id={id}
                type={"text"}
                value={localValue}
                onBlur={onBlur}
                onChange={(e: TextBoxChangeEvent) => {
                    setLocalValue(e.target.value?.toString());
                }}
                className={
                    disabled
                        ? `bg-calfrac-gray-50 ${
                              alignRight ? "[&>input]:text-right" : ""
                          } text-calfrac-gray-300`
                        : `${alignRight ? "[&>input]:text-right" : ""}`
                }
                readOnly={disabled}
            />
            {hint && (
                <span className="ml-auto w-full text-right text-sm text-calfrac-gray-300">
                    {hint}
                </span>
            )}
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </div>
    );
};

export default CustomLocalTextBox;

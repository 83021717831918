import React from "react";

import StandardComboBox from "components/kendoExtensions/standardExtensions/StandardComboBox";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import { FilterManager } from "components/shared/Filter/FilterManager";

import { resources } from "utils/resources";

type Props = {
    onClose: () => void;
};

const EditRequestFilter: React.FC<Props> = ({ onClose }: Props) => {
    return (
        <FilterManager onClose={onClose} name={"Request"}>
            <StandardTextBox
                name={"NextFilter.FilterName"}
                label={resources.FilterName}
                className={"col-span-full"}
            />
            <StandardDropDownList
                label={resources.Country}
                url={"/api/Request/CountryCode"}
                name={"NextFilter.CountryId"}
                byField={"Id"}
            />
            <StandardMultipleSelect
                name={"NextFilter.RequestStatusId"}
                url={"/api/Filter/RequestStatuses"}
                label={resources.RequestStatus}
            />
            <StandardMultipleSelect
                name={"NextFilter.AccountManager"}
                url={"/api/Program/AccountManager"}
                byField={"Id"}
                label={resources.AccountManager}
            />
            <StandardMultipleSelect
                name={"NextFilter.CustomerCompany"}
                url={"/api/Filter/AllAccounts"}
                label={resources.Operator}
            />
            <StandardComboBox
                name={"NextFilter.ProjectDescription"}
                url={"/api/Request/ProjectDescriptions"}
                label={resources.PadName}
            />
            <StandardDropDownList
                url={"/api/Request/YesOrNoOptions"}
                name={"NextFilter.AreAllRequestProgramsAccepted"}
                byField={"Name"}
                label={resources.AllAccepted}
            />
        </FilterManager>
    );
};

export default EditRequestFilter;

import React from "react";
import { CellContext } from "@tanstack/react-table";

import { downloadJET } from "utils/fetchJet";
import { resources } from "utils/resources";

const BoundDownloadCell = ({ cell }: CellContext<any, any>) => {
    const dataItem = cell.row.original;

    return (
        <div className={"read-only"}>
            <button
                title={resources.FileName}
                aria-label={"Download File"}
                className="mx-auto flex w-full  justify-center text-left"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    downloadJET("/api/AzureBlob/DownloadFile", {
                        path: dataItem.BlobUrl,
                    });
                }}
            >
                <span
                    title={resources.FileName}
                    className="w-full text-calfrac-green underline hover:text-calfrac-green"
                >
                    {dataItem.FileName}
                </span>
            </button>
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(BoundDownloadCell);

import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { ITaskHistoryViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/ITaskHistoryViewModel";

import DateCell from "components/Cells/DateCell";
import RenderActionCell from "components/Cells/RenderActionCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type TaskHistoryTableProps = {
    name: string;
};

const columnHelper = createColumnHelper<ITaskHistoryViewModel>();

const TaskHistoryTable: React.FC<TaskHistoryTableProps> = (props) => {
    const { isPageEditable } = useProgram();

    const { programId } = useProgramParams();

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.accessor((row) => row.Action, {
                id: "Action",
                size: 40,
                cell: (props) =>
                    props.row.original.CanSeeDetails ? (
                        <RenderActionCell
                            {...props}
                            to={`/Task/ViewHistoricalProgramTask/${programId}/${props.row.original.Id}`}
                        />
                    ) : (
                        <TextReadOnly {...props} />
                    ),
            }),
            columnHelper.accessor((row) => row.Task, {
                id: "Task",
                size: 40,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.User, {
                id: "User",
                size: 20,
                maxSize: 20,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.OnBehalfOf, {
                id: "OnBehalfOf",
                header: resources.OnBehalfOf,
                size: 20,
                maxSize: 20,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.Comment, {
                id: "Comment",
                header: resources.TaskComments,
                size: 100,
                maxSize: 20,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.CreatedOn, {
                id: "CreatedOn",
                header: resources.CreatedDate,
                size: 20,
                maxSize: 20,
                cell: (props) => <DateCell {...props} readOnly={true} />,
            }),
            columnHelper.accessor((row) => row.NumberOfIngoredWarnings, {
                id: "NumberOfIngoredWarnings",
                header: resources.NumberOfIgnoredWarnings,
                size: 20,
                maxSize: 20,
                cell: TextReadOnly,
            }),
        ];
    }, [programId]);

    return (
        <BaseTable
            name={props.name}
            columns={defaultColumns}
            meta={{
                disabled: !isPageEditable,
                name: props.name,
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default TaskHistoryTable;

/**
 * IEditScheduleStageViewModel.Id, IEditStageCalculationViewModel.PumpScheduleStageId, and IEditStageCalculationViewModel.Id are all string type to support
 * "Copy Schedule" functionality, but for all other operations they will be guaranteed to be parseable to integer, because after a "Copy Schedule" you will be
 * required to save before doing additional formula/back-calculate edits. Once saved, the IDs will be persisted and the string-IDs will contain the entity ID.
 * This function provides some extra checks for safety during this parse.
 * @see IBaseStringIdViewModel
 * @see IEditScheduleStageViewModel
 * @see IEditStageCalculationViewModel
 * @param stringId
 */
export const parseSafeStringIdToInt = (
    stringId: string | undefined,
): number | null => {
    if (typeof stringId === "string") {
        const integerId: number = parseInt(stringId, 10);
        if (!isNaN(integerId)) {
            return integerId;
        }

        console.error(
            `ERROR (parseSafeStringId) argument: stringId = ${stringId}, cannot be parsed to integer, resulted in NaN which was unexpected`,
        );
    }

    return null;
};

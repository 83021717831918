import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-all";
import { useMutation } from "@tanstack/react-query";

import { PrimaryButton } from "components/Buttons";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import FormSection, {
    FormSectionDivider,
    Title,
} from "components/Layout/FormSection";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardDateTime from "components/shared/GenericCustomComponents/StandardDateTime";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";

import { useProgramParams } from "hooks/useProgramParams";

import { saveJET } from "utils/fetchJet";
import { resources } from "utils/resources";

const Page: React.FC = () => {
    // Constants
    const url = "/api/ProgramApproval/EditProgramApproval";
    const workbookUrl = "/api/Workbook/ResendEmail";

    const model = useWatch();

    // State
    const [resendingToDijis, setResendingToDijis] = useState<boolean>(false);

    // Url params
    const { programId } = useProgramParams();

    // React-hook form
    const { setValue } = useFormContext();

    // Mutations
    const ResendToOperations = useMutation({
        mutationFn: async () => {
            return saveJET<Record<string, any>>(workbookUrl, {
                programId: programId,
            }).then((res) => {
                setResendingToDijis(false);
                return res.json();
            });
        },
        onSuccess: (res) => {
            setValue("LastSentToDijisString", res.LastSentToDijis);
        },
    });

    return (
        <StandardForm readUrl={url} readParams={{ programId }} saveUrl={url}>
            <FormSectionDivider>
                <FormSection label={resources.RiskAssessmentReview}>
                    <StandardDropDownList
                        url={"/api/Program/GetUser"}
                        name={"ReviewedBy1Id"}
                        byField={"Id"}
                        label={resources.ReviewedByProgrammer}
                        disabled={true}
                    />
                    <StandardCheckBox
                        label={resources.RiskAssessmentWasPerformed}
                        name={"RiskAssessmentReviewed"}
                        disabled={true}
                    />
                </FormSection>
                <div className="z-0 pt-2">
                    {
                        <Title
                            title={resources.ReviewedByTechServiceEngineering}
                        />
                    }
                    <div className="mt-2 bg-white shadow-sm ring-1 ring-calfrac-gray/5  sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
                                <StandardDropDownList
                                    url={"/api/Program/GetUser"}
                                    name={"ReviewedBy1Id"}
                                    byField={"Id"}
                                    label={resources.ReviewedBy1}
                                    disabled={true}
                                />
                                <StandardDropDownList
                                    url={"/api/Program/GetUser"}
                                    name={"ReviewedBy2Id"}
                                    byField={"Id"}
                                    label={resources.ReviewedBy2}
                                    disabled={true}
                                />
                                <StandardCheckBox
                                    label={resources.ProgramReviewedFlag}
                                    name={"ReviewedFlag"}
                                    disabled={true}
                                />
                            </div>
                            <div className="grid grid-cols-1 items-end gap-x-6 gap-y-8 pt-10 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
                                <StandardDropDownList
                                    url={"/api/Program/GetUser"}
                                    name={"LogApprovedById"}
                                    byField={"Id"}
                                    label={resources.ApprovedBy}
                                    disabled={true}
                                />
                                <StandardDateTime
                                    name={"ApprovedDate"}
                                    label={resources.ProgramApprovedDate}
                                    disabled={true}
                                />
                                <StandardTextBox
                                    name={"JetVersionApprovedIn"}
                                    label={resources.JetVersionApprovedIn}
                                    disabled={true}
                                />
                                <StandardDateTime
                                    name={"LastSentToDijis"}
                                    label={resources.LastSentToOperations}
                                    disabled={true}
                                />
                                {model?.CanPostToDijis && (
                                    <div
                                        className={
                                            "flex items-center justify-center"
                                        }
                                    >
                                        <PrimaryButton
                                            disabled={resendingToDijis}
                                            onClick={() => {
                                                setResendingToDijis(true);
                                                ResendToOperations.mutate();
                                                toast.success(
                                                    resources.EmailSentToOperations,
                                                );
                                            }}
                                        >
                                            {resources.ResendToOperations}
                                        </PrimaryButton>
                                        {resendingToDijis && (
                                            <Loader
                                                size="medium"
                                                type={"pulsing"}
                                                className={
                                                    "flex justify-between align-middle"
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <FormSection label={resources.ApprovedByAccountManager}>
                    <StandardTextBox
                        name={"ApprovedByFullName"}
                        label={resources.ProgramApprovedBy}
                        disabled={true}
                    />
                    <StandardDateTime
                        name={"LogApprovedDate"}
                        label={resources.ApprovedDate}
                        disabled={true}
                    />
                    <StandardCheckBox
                        label={resources.ProgramApprovedFlag}
                        name={"ApprovedFlag"}
                        disabled={true}
                    />
                </FormSection>
            </FormSectionDivider>
        </StandardForm>
    );
};

export default Page;

import React, { useCallback, useEffect, useState } from "react";
import { CellContext } from "@tanstack/react-table";

import { TableMetaT } from "types/Tables/Cells";

const CheckBox = ({
    getValue,
    row: { index },
    column: { id },
    table,
    readOnly,
    disabled,
    applyReadOnlyStyle = false,
}: CellContext<any, any> & {
    readOnly?: boolean;
    applyReadOnlyStyle?: boolean;
    disabled?: boolean;
}) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onChange = useCallback(() => {
        let nextValue = !value;
        setValue(nextValue);
        (table.options.meta as TableMetaT)?.updateData(index, id, nextValue);
    }, [table.options.meta, index, id, value]);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <div className={`${readOnly || applyReadOnlyStyle ? "read-only" : ""}`}>
            <input
                name={id}
                disabled={
                    (table.options.meta as TableMetaT)?.disabled ||
                    readOnly ||
                    disabled
                }
                checked={value as boolean}
                className={
                    "mx-auto flex justify-center border-calfrac-gray-200 text-calfrac-green focus:outline-calfrac-green disabled:cursor-not-allowed disabled:opacity-50"
                }
                type={"checkbox"}
                onChange={onChange}
            />
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(CheckBox, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return (
        prevValue === nextValue &&
        prevProps.applyReadOnlyStyle === nextProps.applyReadOnlyStyle
    );
});

import React, {
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Selecto from "react-selecto";
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getGroupedRowModel,
    GroupColumnDef,
    TableMeta,
    useReactTable,
} from "@tanstack/react-table";

import { TableMetaT } from "types/Tables/Cells";

import { AcceptAllProgramsButton } from "components/Buttons/AcceptAllProgramsButton";
import { H3 } from "components/Headers";
import AddNew from "components/shared/CustomGridToolbar/ToolbarElements/AddNew";
import { BaseTableBodyProps } from "components/Tables/BaseTable/Body/BaseTableBody";
import BaseTableEmptyBody from "components/Tables/BaseTable/Body/BaseTableEmptyBody";

import { CALFRAC_GROUPED_ROW } from "const/styleClassNames";

import { useProgram } from "hooks/useProgramParams";

import {
    clearSelection,
    createCopyDownKeys,
    onSelectIntoMap,
    SelectoDefaults,
} from "utils/copyDown";
import { resources } from "utils/resources";

export type ItemType =
    | string
    | boolean
    | number
    | ItemType[]
    | object
    | undefined
    | null;
export type RecordType = Record<string, ItemType>;

export interface InnerTableProps {
    name: string;
    title?: string;
    columns: (ColumnDef<any, any> | GroupColumnDef<any, any>)[];
    columnVisibility?: { [p: string]: boolean };
    grouping?: string[];
    columnOrder?: string[];
    disableSelectColumns?: string[];
    singleSelectColumns?: string[];
    renderHash?: string;
    meta: TableMeta<any> | undefined;
    defaultRecord?: RecordType;
    afterAddRecord?: (data: RecordType[]) => void;
    beforeUpdateData?: (
        columnId: string,
        dataItem: RecordType,
        value: RecordType | ItemType,
    ) => void;
    afterUpdateData?: (
        columnId: string,
        dataItem: RecordType,
        value: RecordType | ItemType,
        rowIndex?: number,
    ) => void;
    pinnedColumns?: string[];
    pinnedColumnsRight?: string[];
    toolbarExtras?: () => React.JSX.Element;
    toolbarBody?: () => React.JSX.Element;
    copyDownEnabled?: boolean;
    tableRef?: React.RefObject<HTMLTableElement>;
    pinnedColumnBgColor?: string;
    dataFilter?: (d: any) => boolean;
    className?: string;
    noRowsMessage?: string;
    headerButtons?: React.FC<any>[];
    TableBody?: React.FC<BaseTableBodyProps>;
    displayNotInUse?: boolean;
    emptyBody?: boolean;
    disableAddNewRecord?: boolean;
    shouldBeAbleToAcceptAll?: boolean;
    setIsAccepting?: (val: boolean) => void;
}

// Remove the background-color (green) style (indicating cell selection) from all elements
const removeAllSelectedStyle = () => {
    document
        .querySelectorAll(".bg-calfrac-green-selected")
        .forEach((el) => el.classList.remove("bg-calfrac-green-selected"));
};

const InnerTable = ({
    pinnedColumnBgColor = "#FFFFFF",
    className = "",
    afterAddRecord,
    TableBody,
    emptyBody,
    setIsAccepting,
    ...props
}: InnerTableProps) => {
    const localTableRef = useRef<HTMLTableElement>(null);
    const tableRef = props.tableRef ?? localTableRef;
    const unFilteredData = useWatch({ name: props.name });
    const { setValue, getValues } = useFormContext();
    const { isPageEditable } = useProgram();
    const [isArrowKeyNavEnabled, setIsArrowKeyNavEnabled] = useState(false);

    const [isBodyEmpty, setIsBodyEmpty] = useState<boolean | undefined>();

    const selected = useRef(new Map<number, Array<string>>());

    const data = useMemo(() => {
        if (!props.dataFilter) {
            return unFilteredData;
        }

        return unFilteredData?.filter(props.dataFilter);
    }, [unFilteredData, props.dataFilter]);

    // Updating this render hash will rerender all the rows. This is useful for data changes like copying and pasting from excel.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash, props.columnVisibility]);

    const addRecord = useCallback(() => {
        if (
            (Object.keys(props.defaultRecord as Record<string, RecordType>)
                .length > 0 &&
                !props.defaultRecord) ||
            !isPageEditable
        ) {
            alert("You cannot add a record to this table");
            return;
        }
        const key = Math.random().toString(36).substring(2, 15);
        const newRecord = { ...props.defaultRecord, Id: `NEW-${key}` };
        const newValue = [...data, newRecord];
        setValue(props.name, newValue, { shouldDirty: true });
        afterAddRecord?.(newValue);
    }, [
        props.defaultRecord,
        props.name,
        isPageEditable,
        data,
        setValue,
        afterAddRecord,
    ]);

    const table = useReactTable({
        data: data ?? [],
        columns: props.columns,
        columnResizeMode: "onChange",
        getExpandedRowModel: props.grouping ? getExpandedRowModel() : undefined,
        getGroupedRowModel: props.grouping ? getGroupedRowModel() : undefined,
        getCoreRowModel: getCoreRowModel(),
        enableColumnPinning: true,
        initialState: {
            columnPinning: {
                left: props.pinnedColumns ?? [],
            },
        },
        state: {
            columnVisibility: props.columnVisibility,
            columnPinning: {
                left: props.pinnedColumns ?? [],
                right: props.pinnedColumnsRight ?? [],
            },
            columnOrder: props.columnOrder,
            grouping: props.grouping,
            expanded: true,
        },
        meta: {
            disabled: !isPageEditable,
            disableSelectColumns: props.disableSelectColumns ?? [],
            singleSelectColumns: props.singleSelectColumns ?? [],
            name: props.name,
            updateRenderHash: updateRenderHash,
            updateData: (
                rowIndex: number,
                columnId: string,
                value: RecordType | ItemType,
            ) => {
                if (rowIndex > (data?.length ?? 0) - 1) return;
                const valueName = `${props.name}.${rowIndex}.${columnId}`;
                const lastValue = getValues(valueName);
                if (lastValue === value) return;

                props?.beforeUpdateData?.(
                    columnId,
                    getValues(`${props.name}.${rowIndex}`),
                    value,
                );

                // Skip page index reset until after next rerender
                setValue(valueName, value, { shouldDirty: true });

                props.afterUpdateData?.(
                    columnId,
                    getValues(`${props.name}.${rowIndex}`),
                    value,
                    rowIndex,
                );
            },
            deleteRecord: (rowId: number) => {
                if (
                    !window.confirm(
                        resources.AreYouSureYouWantToDeleteThisRecord,
                    )
                ) {
                    return;
                }
                // @ts-ignore
                const newData = data.filter((e) => e.Id !== rowId);
                setValue(props.name, newData, { shouldDirty: true });
            },
            updateNumericCellData: (
                rowIndex: number,
                columnId: string,
                value: unknown,
            ) => {
                if (rowIndex > (data?.length ?? 0) - 1) return;
                const valueName = `${props.name}.${rowIndex}.${columnId}`;
                const lastValue = getValues(valueName);
                if (lastValue === value) return;

                // Skip page index reset until after next rerender
                setValue(valueName, value, { shouldDirty: true });
            },
            select: (row: number, col: string) => {
                selectCell(row, col);
            },
            ...props.meta,
        },
    });

    /**
     * Find and return the logical (non-grouped) row by integer index. Note: for tables with grouped rows, the data-row-id
     * is not parseable to integer, so calls to this method will ignore grouped rows.
     *
     * If you do not want to ignore grouped rows, you should be using a selector that uses tr[data-row-idx="INDEX"], for
     * example: getRow(index, "data-row-idx").
     */
    const getRow = useCallback(
        (
            row: number,
            rowElementAttribute: string = "data-row-id",
        ): Element | null | undefined => {
            return tableRef.current?.querySelector(
                `tr[${rowElementAttribute}="${row}"]`,
            );
        },
        [tableRef],
    );

    const getCell = useCallback(
        (
            row: Element | null | undefined,
            col: string,
            cellElementAttribute: string = "data-col-id",
        ): Element | null | undefined => {
            return row?.querySelector(`td[${cellElementAttribute}="${col}"]`);
        },
        [],
    );

    /**
     * Helper to determine if an element is a grouped table row. Returns true if the row argument passed is a grouped row,
     * false otherwise.
     * @param row
     * @param groupedRowClassName
     */
    const isGroupedRow = (
        row: Element | null | undefined,
        groupedRowClassName: string = CALFRAC_GROUPED_ROW,
    ): boolean => {
        const htmlTableRowElement: HTMLTableRowElement =
            row as HTMLTableRowElement;
        return (
            htmlTableRowElement &&
            htmlTableRowElement.classList.contains(groupedRowClassName)
        );
    };

    /**
     * Select the next cell by row/col indices, where the row to which it belongs is NOT a grouped row. Then, update the current selection map, remove all  clear all previously selected cells'
     * style, and apply the selected style to the new target cell.
     */
    const selectCell = useCallback(
        (
            row: number,
            col: string,
            selectedClassName: string = "bg-calfrac-green-selected",
        ) => {
            const nextRow = getRow(row);
            const nextCell = getCell(nextRow, col);
            if (nextCell instanceof HTMLTableCellElement) {
                selected.current.clear();
                selected.current.set(row, [col]);
                removeAllSelectedStyle();

                if (!isGroupedRow(nextRow)) {
                    (nextCell as HTMLTableCellElement).classList.add(
                        selectedClassName,
                    );
                }
            }
        },
        [getRow, getCell],
    );

    /**
     * Get first selected cell.
     */
    const getFirstSelectedCell = useCallback(():
        | undefined
        | { row: number; col: string } => {
        const selectedEntries: Map<number, Array<string>> = selected.current;
        if (selectedEntries.size === 0) return;
        const firstSelection = selectedEntries.entries().next().value;
        let row: number = firstSelection[0];
        let col: string = firstSelection[1][0];
        return { row, col };
    }, [selected]);

    const getVisibleColumns = useCallback(() => {
        const hiddenColumns: string[] = [];
        if (props.columnVisibility) {
            for (const key of Object.keys(props.columnVisibility)) {
                if (!props.columnVisibility[key]) {
                    hiddenColumns.push(key);
                }
            }
        }

        // Return the list of visible columns
        return props.columns
            .filter((c) => !hiddenColumns.includes(c.id ?? ""))
            .flatMap((c) => {
                if ("columns" in c) {
                    return (
                        c.columns
                            ?.filter(
                                (c2: ColumnDef<any, any>) =>
                                    !hiddenColumns.includes(c2.id ?? ""),
                            )
                            .map((c2: ColumnDef<any, any>) => c2.id ?? "") ?? []
                    );
                }
                return [c.id ?? ""];
            });
    }, [props.columnVisibility, props.columns]);

    /**
     * Return true if the key (i.e. KeyboardEvent.key) is one of the 4 arrow keys; false otherwise.
     * @param key
     */
    const isArrowKey = (key: string): boolean => {
        return ["ArrowUp", "ArrowRight", "ArrowLeft", "ArrowDown"].includes(
            key,
        );
    };

    // Attach a listener to the keydown event, and remove it on cleanup.
    useEffect(() => {
        const keydownEventHandler = (e: KeyboardEvent) => {
            if (!isArrowKeyNavEnabled) return;

            // Handle Tab key
            if (e.key === "Tab") {
                e.preventDefault();
                return;
            }

            // Handle Arrow key or Enter
            if (isArrowKey(e.key) || e.key === "Enter") {
                const firstSelectedCell = getFirstSelectedCell();
                if (!firstSelectedCell) return;
                let { row, col } = firstSelectedCell;

                const columns = getVisibleColumns();
                const colIndex = columns.indexOf(col);

                if (e.key === "ArrowUp" && row > 0) {
                    row = row - 1;
                    selectCell(row, col);
                } else if (
                    e.key === "ArrowRight" &&
                    colIndex >= 0 &&
                    columns.length > colIndex
                ) {
                    col = columns[colIndex + 1];
                    selectCell(row, col);
                } else if (e.key === "ArrowDown" && data.length > row) {
                    row = row + 1;
                    selectCell(row, col);
                } else if (e.key === "ArrowLeft" && colIndex > 0) {
                    col = columns[colIndex - 1];
                    selectCell(row, col);
                } else if (e.key === "Enter") {
                    e.preventDefault();
                    const nextRow = getRow(row);
                    const nextCell = getCell(nextRow, col);
                    const input = nextCell?.querySelector("input");
                    if (input) {
                        input?.select();
                        setIsArrowKeyNavEnabled(false);
                    }
                    const textArea = nextCell?.querySelector("textarea");
                    if (textArea) {
                        textArea?.select();
                        setIsArrowKeyNavEnabled(false);
                    }
                }
            }
        };
        window.addEventListener("keydown", keydownEventHandler);
        return () => window.removeEventListener("keydown", keydownEventHandler);
    }, [
        getRow,
        getCell,
        getFirstSelectedCell,
        getVisibleColumns,
        selectCell,
        isArrowKeyNavEnabled,
        selected,
        setIsArrowKeyNavEnabled,
        props.columnVisibility,
        props.columns,
        data?.length,
        tableRef,
    ]);

    // Attach a listener to the "click" event, removing it on cleanup
    useEffect(() => {
        const clickEventHandler = () => {
            if (!isArrowKeyNavEnabled) return;
            removeAllSelectedStyle();
            setIsArrowKeyNavEnabled(false);
        };
        window.addEventListener("click", clickEventHandler);
        return () => window.removeEventListener("click", clickEventHandler);
    }, [isArrowKeyNavEnabled, setIsArrowKeyNavEnabled]);

    const onKeyDown: KeyboardEventHandler = useCallback(
        (event: React.KeyboardEvent) => {
            // get the current active element
            const activeElement = document.activeElement;

            // Handle Escape key
            if (
                event.key === "Escape" &&
                (activeElement instanceof HTMLInputElement ||
                    activeElement instanceof HTMLSpanElement)
            ) {
                activeElement?.blur();
                setIsArrowKeyNavEnabled(true);
                return;
            }

            // Handle Tab key
            if (
                event.key === "Tab" &&
                (activeElement instanceof HTMLInputElement ||
                    activeElement instanceof HTMLSpanElement)
            ) {
                const firstSelectedCell = getFirstSelectedCell();
                if (!firstSelectedCell) return;
                let { row, col } = firstSelectedCell;

                let selectionUpdated = false;
                const columns = getVisibleColumns();
                const colIndex = columns.indexOf(col);
                if (colIndex >= 0 && columns.length - 1 > colIndex) {
                    col = columns[colIndex + 1];
                    selectionUpdated = true;
                } else if (data.length - 1 > row) {
                    row = row + 1;
                    col = columns[0];
                    selectionUpdated = true;
                } else {
                    clearSelection(selected);
                }

                if (!selectionUpdated) return;

                selectCell(row, col);
                return;
            }

            // Handle Enter key
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();

                // If the ctrl or cmd keys are pressed, add a record and exit.
                if (event.ctrlKey || event.metaKey) {
                    addRecord();
                    return;
                }

                // if the active element is not an input, then move on
                if (!(activeElement instanceof HTMLInputElement)) return;

                // get the column index
                const columnIndex =
                    activeElement?.closest("td")?.getAttribute("data-col-id") ??
                    "0";

                let nextRowIndex = parseInt(
                    activeElement?.closest("tr")?.getAttribute("data-row-id") ??
                        "0",
                );
                nextRowIndex++;

                const nextRow = activeElement
                    .closest("table")
                    ?.querySelector(`tr[data-row-id="${nextRowIndex}"]`);

                const nextCell = nextRow?.querySelector(
                    `td[data-col-id="${columnIndex}"]`,
                );

                const input = nextCell?.querySelector("input");
                const textArea = nextCell?.querySelector("textarea");

                if (input) {
                    input?.select();
                    selectCell(nextRowIndex, columnIndex);
                }
                if (textArea) {
                    textArea?.select();
                    selectCell(nextRowIndex, columnIndex);
                }
            }
        },
        [
            addRecord,
            getFirstSelectedCell,
            getVisibleColumns,
            selectCell,
            data?.length,
        ],
    );

    useEffect(() => {
        if (emptyBody === undefined) {
            setIsBodyEmpty(data?.length === 0);
        } else {
            setIsBodyEmpty(emptyBody);
        }
        updateRenderHash();
    }, [data?.length, emptyBody, table]);

    const scrollRef = useRef<HTMLDivElement>(null);
    const selectoRef = useRef<Selecto>(null);

    return (
        <div className={"col-span-full flex h-full w-full flex-col pe-1"}>
            {props.title && <H3 className={"pb-4 pl-4"}>{props.title}</H3>}

            {isPageEditable && (
                <div
                    className={
                        "sticky left-0 z-10 mb-2 flex w-full items-center space-x-4"
                    }
                >
                    <>
                        {props.defaultRecord && (
                            <AddNew
                                onClick={addRecord}
                                readOnly={props.disableAddNewRecord}
                            />
                        )}
                        <AcceptAllProgramsButton
                            shouldBeAbleToAcceptAll={
                                props.shouldBeAbleToAcceptAll
                            }
                            setIsAccepting={setIsAccepting}
                        />
                        {props.copyDownEnabled && (
                            <span
                                id={"selecto-ignore"}
                                onClick={() => {
                                    const valuesToSet = createCopyDownKeys(
                                        props.name,
                                        selected.current,
                                        data,
                                    );
                                    // for each value, set the value
                                    Object.entries(valuesToSet).forEach(
                                        ([key, value]) => {
                                            setValue(key, value, {
                                                shouldDirty: true,
                                            });
                                        },
                                    );

                                    selected.current.clear();
                                    removeAllSelectedStyle();
                                    updateRenderHash();
                                }}
                                className={
                                    /* eslint-disable */
                                    "text-nowrap cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                                    /* eslint-enable */
                                }
                            >
                                {resources.CopyDown}
                            </span>
                        )}
                        {props.toolbarExtras?.()}
                    </>
                </div>
            )}
            <Selecto
                {...SelectoDefaults}
                ref={selectoRef}
                container={document.body}
                onSelect={onSelectIntoMap(selected, props.singleSelectColumns)}
                scrollOptions={{
                    container: () => scrollRef.current!,
                    getScrollPosition: () => {
                        return [
                            scrollRef.current!.scrollLeft,
                            scrollRef.current!.scrollTop,
                        ];
                    },
                    throttleTime: 30,
                    threshold: 0,
                }}
                onScroll={(e) => {
                    scrollRef.current!.scrollBy(
                        e.direction[0] * 10,
                        e.direction[1] * 10,
                    );
                }}
                selectByClick={true}
            />
            <>{props.toolbarBody?.()}</>
            <div
                ref={scrollRef}
                className={"h-full flex-1 overflow-auto pe-1"}
                onScroll={() => {
                    selectoRef.current?.checkScroll();
                }}
            >
                <table
                    ref={tableRef}
                    className={`w-full border-collapse select-none text-sm ${className} ${
                        (table.options.meta as TableMetaT).disabled &&
                        "bg-calfrac-gray-50 text-gray-500"
                    }`}
                    onKeyDown={onKeyDown}
                    {...{
                        style: {
                            width: table.getCenterTotalSize(),
                            minWidth: table.getCenterTotalSize(),
                        },
                    }}
                >
                    <thead
                        className={
                            "sticky top-0 z-10 border-b-2 border-gray-300 bg-neutral-200 px-4 pb-4"
                        }
                    >
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                className={"border-b border-gray-400"}
                            >
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        id={"selecto-ignore"}
                                        className={
                                            "text-bold px-2 py-3 align-bottom"
                                        }
                                        colSpan={header.colSpan}
                                        style={
                                            header.column.getIsPinned()
                                                ? {
                                                      zIndex: 5,
                                                      width: header.getSize(),
                                                      left: header.getStart(
                                                          "left",
                                                      ),
                                                      position: "sticky",
                                                      top: 0,
                                                      background: "#eee",
                                                  }
                                                : {
                                                      width: header.getSize(),
                                                      position: "relative",
                                                      textAlign:
                                                          data?.length > 0
                                                              ? "left"
                                                              : "center",
                                                  }
                                        }
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext(),
                                              )}
                                        <div
                                            className={`resizer absolute right-0 top-0 -mr-[2.5px] h-full w-[5px] cursor-ew-resize select-none
                    ${
                        header.column.getIsResizing()
                            ? "opacity-1 bg-opacity-1 bg-calfrac-green"
                            : ""
                    }`}
                                            {...{
                                                onMouseDown:
                                                    header.getResizeHandler(),
                                                onTouchStart:
                                                    header.getResizeHandler(),
                                            }}
                                        >
                                            <div
                                                className={
                                                    "mx-auto h-full w-px bg-gray-400 bg-opacity-50"
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                "d-flex items-center justify-center gap-x-2"
                                            }
                                        >
                                            {props.headerButtons?.map(
                                                (
                                                    Component: React.FC<any>,
                                                    index,
                                                ) => {
                                                    return (
                                                        <Component
                                                            colId={
                                                                header.column.id
                                                            }
                                                            table={table}
                                                            selected={selected}
                                                            updateRenderHash={
                                                                updateRenderHash
                                                            }
                                                            key={`${header.column.id}_${index}`}
                                                        />
                                                    );
                                                },
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {/* If the table has some data, render the provided body */}
                    {isBodyEmpty === false && TableBody && (
                        <TableBody
                            table={table}
                            renderHash={renderHash}
                            pinnedColumnBgColor={pinnedColumnBgColor}
                            name={props.name}
                            displayNotInUse={props.displayNotInUse}
                        />
                    )}
                    {/* If the table has no rows, simply show a placeholder for tbody*/}
                    {isBodyEmpty === true && (
                        <BaseTableEmptyBody
                            table={table}
                            noRowsMessage={props.noRowsMessage}
                        />
                    )}
                </table>
            </div>
        </div>
    );
};

export default React.memo(InnerTable);

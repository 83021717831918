import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { GridCellProps } from "@progress/kendo-react-grid";

import TableCell from "components/kendoExtensions/gridExtensions/TableCell";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";

import { useGridState } from "providers/GridStateProvider";

export const GridTextBox: React.FC<
    GridCellProps & { overrideEditable?: boolean; disabled?: boolean }
> = ({ disabled = false, ...props }) => {
    const { clearErrors } = useFormContext();
    const { isGridEditable, formName, readOnlyColumns } = useGridState();

    const { dataItem, dataIndex, field, overrideEditable, className } = props;
    const name = `${formName}.${dataIndex}.${field}`;

    const columnIsReadOnly = useMemo(
        () =>
            field &&
            readOnlyColumns.some((value) => {
                if (typeof value === "string") {
                    return field?.startsWith(value);
                } else {
                    return value.test(field ?? "");
                }
            }),
        [field, readOnlyColumns],
    );
    const isEditable =
        overrideEditable === true ||
        ((dataItem.editable === undefined || dataItem.editable === true) &&
            isGridEditable &&
            !columnIsReadOnly);

    const handleChange = useCallback(() => {
        clearErrors(formName);
    }, [clearErrors, formName]);

    return (
        <TableCell
            {...props}
            className={`${!isEditable && "bg-calfrac-gray-50"}`}
        >
            <StandardTextBox
                name={name}
                disabled={disabled || !isEditable}
                onChange={handleChange}
                className={className + " flex justify-center"}
            />
        </TableCell>
    );
};

import React, { useEffect, useMemo, useReducer } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditRiskAssessmentChecklistViewModel } from "types/generated/Calfrac/Jet/Web/Models/RiskAssessment/IEditRiskAssessmentChecklistViewModel";

import CheckBox from "components/Cells/CheckBox";
import HyperLinkCell from "components/Cells/HyperlinkCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type RiskAssesmentTableProps = {
    name: string;
    renderHash?: string;
};

const columnHelper =
    createColumnHelper<IEditRiskAssessmentChecklistViewModel>();

const RiskAssessmentTable: React.FC<RiskAssesmentTableProps> = (props) => {
    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    const model = useWatch();

    const { setValue, getValues } = useFormContext();

    const { isPageEditable } = useProgram();

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.accessor((row) => row.IsSelected, {
                id: "IsSelected",
                header: " ",
                size: 20,
                maxSize: 20,
                cell: (props) => (
                    <CheckBox
                        readOnly={model.NoRiskAssessmentOptionsApply}
                        {...props}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.CriticalIdentifier, {
                id: "CriticalIdentifier",
                header: resources.CriticalIdentifier,
                size: 150,
                maxSize: 150,
                cell: HyperLinkCell,
            }),
            columnHelper.accessor((row) => row.RecommendedActions, {
                id: "RecommendedActions",
                header: resources.RecommendedActions,
                size: 350,
                maxSize: 350,
                cell: TextReadOnly,
            }),
        ];
    }, [model.NoRiskAssessmentOptionsApply]);

    return (
        <div className={"col-span-full h-full flex-1"}>
            <BaseTable
                name={props.name}
                columns={defaultColumns}
                renderHash={renderHash}
                disableSelectColumns={[
                    "IsSelected",
                    "CriticalIdentifier",
                    "RecommendedActions",
                ]}
                meta={{
                    disabled:
                        !isPageEditable || model.NoRiskAssessmentOptionsApply,
                    name: props.name,
                    updateData: (
                        rowIndex: number,
                        columnId: string,
                        value: unknown,
                    ) => {
                        const valueName = `${props.name}.${rowIndex}.${columnId}`;
                        const lastValue = getValues(valueName);
                        if (lastValue === value) return;

                        if (
                            columnId === "IsSelected" &&
                            !model.NoRiskAssessmentOptionsApply
                        ) {
                            setValue(valueName, value, { shouldDirty: true });
                        } else if (columnId !== "IsSelected") {
                            setValue(valueName, value, { shouldDirty: true });
                        }
                    },
                    test: () =>
                        !isPageEditable || model.NoRiskAssessmentOptionsApply,
                }}
            />
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default RiskAssessmentTable;

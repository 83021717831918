import React from "react";
import { Row, Table } from "@tanstack/react-table";

import BaseTableRow from "components/Tables/BaseTable/Rows/BaseTableRow";

export interface BaseTableBodyProps {
    name: string;
    table: Table<any>;
    renderHash: string;
    pinnedColumnBgColor: string;
    displayNotInUse?: boolean;
}

const BaseTableBody = ({
    table,
    renderHash,
    pinnedColumnBgColor,
    displayNotInUse = false,
}: BaseTableBodyProps) => {
    return (
        <tbody className={"px-4"}>
            {table.getRowModel().rows.map((row: Row<any>, idx: number) => (
                <BaseTableRow
                    row={row}
                    table={table}
                    key={`${row.original.Id}_${row.index}_${row.depth}`}
                    renderHash={renderHash}
                    pinnedColumnBgColor={pinnedColumnBgColor}
                    index={idx}
                    hideRow={
                        (row.original.IsUsedOnPumpSchedule === false ||
                            row.original.IsProppantUsedOnPumpSchedule ===
                                false) &&
                        displayNotInUse === false
                    }
                />
            ))}
        </tbody>
    );
};

export default BaseTableBody;

import React from "react";

import StandardComboBox from "components/kendoExtensions/standardExtensions/StandardComboBox";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import { FilterManager } from "components/shared/Filter/FilterManager";

import { resources } from "utils/resources";

type Props = {
    onClose: () => void;
};

const EditProgramFilter: React.FC<Props> = ({ onClose }: Props) => {
    return (
        <FilterManager onClose={onClose} name={"Program"}>
            <StandardTextBox
                label={resources.FilterName}
                name={"NextFilter.FilterName"}
                className={"col-span-full"}
            />
            <StandardDropDownList
                url={"/api/Program/CountryCode"}
                name={"NextFilter.CountryId"}
                byField={"Id"}
                label={resources.Country}
            />
            <StandardMultipleSelect
                name={"NextFilter.ServiceLine"}
                url={"/api/Program/ServiceLine"}
                byField={"Id"}
                label={resources.ServiceLine}
            />
            <StandardDropDownList
                label={resources.Operator}
                url={"/api/Program/Operator"}
                name={"NextFilter.Operator"}
                byField={"Name"}
            />
            <StandardDropDownList
                url={"/api/Program/GetConsultant"}
                name={"NextFilter.Consultant"}
                byField={"Name"}
                label={resources.Consultant}
            />
            <StandardComboBox
                url={"/api/Program/GetProjectDescription"}
                name={"NextFilter.ProjectDescription"}
                label={resources.PadName}
            />
            <StandardDropDownList
                url={"/api/Program/GetWellNames"}
                name={"NextFilter.WellName"}
                byField={"Name"}
                label={resources.WellName}
            />
            <StandardDropDownList
                url={"/api/Program/GetAccountManager"}
                name={"NextFilter.AccountManagerId"}
                byField={"Id"}
                label={resources.AccountManager1}
            />
            <StandardDropDownList
                url={"/api/Program/GetPrepareds"}
                name={"NextFilter.PreparedById"}
                byField={"Id"}
                label={resources.PreparedBy}
            />
            <StandardMultipleSelect
                name={"NextFilter.Formations"}
                url={"/api/Program/GetFormations"}
                byField={"Name"}
                label={resources.Formations}
            />
            <StandardMultipleSelect
                name={"NextFilter.Field"}
                url={"/api/Program/GetFields"}
                byField={"Name"}
                label={resources.Field}
            />
            <StandardMultipleSelect
                name={"NextFilter.CompletionTechnologies"}
                url={"/api/Program/GetCompletionTechnologies"}
                byField={"Name"}
                label={resources.CompletionTechnologies}
            />
            <StandardMultipleSelect
                name={"NextFilter.CTTreatmentObjective"}
                url={"/api/Program/GetCTTreatmentObjective"}
                byField={"Name"}
                label={resources.TreatmentObjective}
            />
            <StandardMultipleSelect
                name={"NextFilter.FluidSystems"}
                url={"/api/Program/GetFluidSystems"}
                byField={"Name"}
                label={resources.FluidSystems}
            />
        </FilterManager>
    );
};

export default EditProgramFilter;

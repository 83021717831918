import React from "react";
import { useWatch } from "react-hook-form";
import { FolderIcon } from "@heroicons/react/24/outline";
import styled from "styled-components";

import { IEditPerforationViewModel } from "types/generated/Calfrac/Jet/Web/Models/Perforation/IEditPerforationViewModel";

import { CellType, PerforationsMetaT } from "types/Tables/Cells";

import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";

const ClusterCell: CellType = ({ row, table }) => {
    const clusters = useWatch({
        name: `${(table.options.meta as PerforationsMetaT).name}.${
            row.index
        }.PerforationClusters`,
    });
    const zoneNumber = useWatch({
        name: `${(table.options.meta as PerforationsMetaT).name}.${
            row.index
        }.ZoneNumber`,
    });
    return (
        <ClustersCellContainer
            onClick={() =>
                (table.options.meta as PerforationsMetaT).openClustersPopup(
                    row.index,
                    zoneNumber,
                )
            }
        >
            <FolderIcon
                className={"h-6 w-6 cursor-pointer  text-calfrac-green"}
                aria-hidden="true"
            />
            <StandardAnchorLink text={clusters?.length ?? 0} />
        </ClustersCellContainer>
    );
};

const ClustersCellContainer = styled.div`
    display: flex;
    column-gap: 0.8rem;
    justify-content: center;
    align-items: center;

    & span {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 30px;
        margin-bottom: 5px;
        text-decoration: none;
    }
`;

// Default exported so that the memoization is named in the dev tools
export default React.memo(ClusterCell, (prevProps, nextProps) => {
    const prevValue = (prevProps.row.original as IEditPerforationViewModel)
        ?.PerforationClusters?.length;
    const nextValue = (nextProps.row.original as IEditPerforationViewModel)
        ?.PerforationClusters?.length;
    return prevValue === nextValue;
});

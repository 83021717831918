import { BackCalculatePumpScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/BackCalculatePumpScheduleStageTarget";

const BackCalculateColumnInfo: {
    [key: string]: BackCalculatePumpScheduleStageTarget;
} = {
    DownholeConcentrationStart:
        BackCalculatePumpScheduleStageTarget.DownholeConcentrationStart,
    DownholeConcentrationEnd:
        BackCalculatePumpScheduleStageTarget.DownholeConcentrationEnd,
    ProppantStage: BackCalculatePumpScheduleStageTarget.ProppantStage,
    ProppantStageCumulative:
        BackCalculatePumpScheduleStageTarget.ProppantStageCumulative,
    N2Stage: BackCalculatePumpScheduleStageTarget.N2Stage,
    CO2Stage: BackCalculatePumpScheduleStageTarget.CO2Stage,
    SecondaryFluidSystemStage:
        BackCalculatePumpScheduleStageTarget.SecondaryFluidSystemStage,
    DownholeRate: BackCalculatePumpScheduleStageTarget.DownholeRate,
    DownholeVolume: BackCalculatePumpScheduleStageTarget.DownholeVolume,
    DownholeFoamQuality:
        BackCalculatePumpScheduleStageTarget.DownholeFoamQuality,
    DownholeTotal: BackCalculatePumpScheduleStageTarget.DownholeTotal,
};

export default BackCalculateColumnInfo;

import React, { memo, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { createColumnHelper, VisibilityState } from "@tanstack/react-table";

import { FluidSystemTypeId } from "types/generated/Calfrac/Jet/Core/Models/FluidSystemTypeId";

import { IEditChemicalScheduleStageViewModel } from "types/generated/Calfrac/Jet/Web/Models/ChemicalLoading/IEditChemicalScheduleStageViewModel";
import { IEditChemicalScheduleViewModel } from "types/generated/Calfrac/Jet/Web/Models/ChemicalLoading/IEditChemicalScheduleViewModel";

import { CellType } from "types/Tables/Cells";

import IntegerReadOnly from "components/Cells/IntegerReadOnly";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import { OverrideCell } from "components/Cells/OverrideCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type SchedulesTableProps = {
    name: string;
    canEdit: boolean;
};

const columnHelper = createColumnHelper<IEditChemicalScheduleStageViewModel>();

const ChemicalScheduleTable: React.FC<SchedulesTableProps> = (props) => {
    const model = useWatch();
    const isPageEditable = props.canEdit;
    const units = useUnits();

    const defaultColumns = useMemo(
        () => [
            // Display Column
            columnHelper.accessor((row) => row.StageNumber, {
                id: "StageNumber",
                size: 80,
                maxSize: 80,
                header: resources.StageNumber,
                cell: IntegerReadOnly,
            }),

            columnHelper.accessor((row) => row.StageDescription, {
                id: "StageDescription",
                header: resources.StageDescription,
                size: 130,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.ProgramFluidSystem, {
                id: "ProgramFluidSystem",
                header: resources.FluidSystem,
                size: 200,
                cell: TextReadOnly,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ProgramProppant, {
                id: "ProgramProppant",
                header: resources.Proppant,
                size: 150,
                cell: TextReadOnly,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.SlurryRate, {
                id: "SlurryRate",
                header: `${resources.SlurryRate} ${units.rateWetFastUnit}`,
                size: 100,
                cell: NumericReadOnly,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.CleanVolume, {
                id: "CleanVolume",
                header: `${resources.CleanVolume} ${units.volumeWetMediumUnit}`,
                size: 100,
                cell: NumericReadOnly,
                enableHiding: true,
            }),
            columnHelper.group({
                header: resources.Proppant.toUpperCase(),
                id: "Proppant",
                size: 300,
                minSize: 300,
                columns: [
                    columnHelper.accessor(
                        (row) => row.BlenderConcentrationStart,
                        {
                            id: "BlenderConcentrationStart",
                            header: `${resources.BlenderConcentrationStart} ${units.concentrationProppantUnit}`,
                            size: 70,
                            minSize: 70,
                            cell: NumericReadOnly,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.BlenderConcentrationEnd,
                        {
                            id: "BlenderConcentrationEnd",
                            header: `${resources.BlenderConcentrationEnd} ${units.concentrationProppantUnit}`,
                            size: 70,
                            minSize: 70,
                            cell: NumericReadOnly,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.DownholeConcentrationStart,
                        {
                            id: "DownholeConcentrationStart",
                            header: `${resources.DownholeConcentrationStart} ${units.concentrationProppantUnit}`,
                            size: 100,
                            minSize: 100,
                            cell: NumericReadOnly,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.DownholeConcentrationEnd,
                        {
                            id: "DownholeConcentrationEnd",
                            header: `${resources.DownholeConcentrationEnd} ${units.concentrationProppantUnit}`,
                            size: 100,
                            minSize: 100,
                            cell: NumericReadOnly,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor((row) => row.ProppantStage, {
                        id: "ProppantStage",
                        header: `${resources.ProppantStage} ${units.massSmallUnit}`,
                        size: 100,
                        minSize: 100,
                        cell: NumericReadOnly,
                        enableHiding: true,
                    }),
                    columnHelper.accessor(
                        (row) => row.ProppantStageCumulative,
                        {
                            id: "ProppantStageCumulative",
                            header: `${resources.ProppantCumulative} ${units.massSmallUnit}`,
                            size: 100,
                            minSize: 100,
                            cell: NumericReadOnly,
                            enableHiding: true,
                        },
                    ),
                ],
            }),
            columnHelper.accessor((row) => row.Activator, {
                id: "Activator",
                header: resources.Activator,
                size: 130,
                minSize: 130,
                cell: TextReadOnly,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ActivatorLoadingDefault, {
                id: "ActivatorConcentration",
                header: `${resources.ActivatorConcentration} ${units.concentrationChemicalWetUnit}`,
                size: 130,
                minSize: 130,
                cell: ActivatorConcentrationCell,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.SecondaryProgramFluidSystem, {
                id: "SecondaryProgramFluidSystem",
                header: `${resources.SecondaryFluidSystem}`,
                size: 130,
                minSize: 130,
                cell: TextReadOnly,
                enableHiding: true,
            }),
            columnHelper.group({
                header: resources.Rates.toUpperCase(),
                id: "Rates",
                columns: [
                    columnHelper.accessor(
                        (row) => row.SecondaryFluidSystemRate,
                        {
                            id: "SecondaryFluidSystemRate",
                            header: `${resources.SecondaryFluidSystemRate} ${units.rateWetFastUnit}`,
                            size: 90,
                            minSize: 90,
                            cell: NumericReadOnly,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.SecondaryFluidSystemStage,
                        {
                            id: "SecondaryFluidSystemStage",
                            header: `${resources.SecondaryFluidSystemStage} ${units.volumeWetLargeUnit}`,
                            size: 90,
                            minSize: 90,
                            cell: NumericReadOnly,
                            enableHiding: true,
                        },
                    ),
                ],
            }),
            // Adding dynamic columns here
            ...(model.DynamicColumns ?? []).map(
                (dynamicCol: any, idx: number) =>
                    columnHelper.accessor(
                        (row) =>
                            row.ChemicalScheduleItems[idx]
                                .OverriddenConcentration
                                ? row.ChemicalScheduleItems[idx]
                                      .OverriddenConcentration
                                : row.ChemicalScheduleItems[idx]
                                      .DefaultConcentration,
                        {
                            id: `ChemicalScheduleItems_${idx}`,
                            header: dynamicCol.Title,
                            size: 90,
                            minSize: 90,
                            cell: DynamicChemicalCell,
                            enableHiding: true,
                        },
                    ),
            ),
        ],
        [
            model.DynamicColumns,
            units.concentrationChemicalWetUnit,
            units.concentrationProppantUnit,
            units.massSmallUnit,
            units.rateWetFastUnit,
            units.volumeWetLargeUnit,
            units.volumeWetMediumUnit,
        ],
    );

    const hiddenColumns = useMemo(() => {
        return defaultColumns.reduce((visibilityState, column) => {
            // Helper function to determine visibility
            const determineVisibility = (columnId: string) => {
                let colVisibility;
                switch (columnId) {
                    case "ProgramFluidSystem":
                    case "SlurryRate":
                    case "CleanVolume":
                    case "BlenderConcentrationStart":
                    case "BlenderConcentrationEnd":
                    case "DownholeConcentrationStart":
                    case "DownholeConcentrationEnd":
                    case "ProppantStage":
                    case "ProppantStageCumulative":
                    case "Activator":
                    case "ActivatorConcentration":
                    case "ActivatorLoadingDefault":
                        colVisibility =
                            model?.FluidSystemTypeId ===
                            FluidSystemTypeId.Primary;
                        break;
                    case "SecondaryProgramFluidSystem":
                    case "SecondaryFluidSystemRate":
                    case "SecondaryFluidSystemStage":
                    case "Rates":
                        colVisibility =
                            model?.FluidSystemTypeId ===
                            FluidSystemTypeId.Secondary;
                        break;
                    default:
                        colVisibility = true;
                        break;
                }
                return colVisibility;
            };

            // Logic starts here to determine column visibility of columns and subcolumns
            if (!column.id) return visibilityState;

            // @ts-ignore
            const subColumns = column?.columns;

            // This column contains sub-columns
            if (subColumns) {
                subColumns.forEach((col: any) => {
                    visibilityState[col.id] = determineVisibility(col.id);
                });
            } else {
                let colVisibility = determineVisibility(column.id);
                visibilityState[column.id] = colVisibility ?? true;
            }
            return visibilityState;
        }, {} as VisibilityState);
    }, [defaultColumns, model?.FluidSystemTypeId]);

    return (
        <BaseTable
            columns={defaultColumns}
            columnVisibility={hiddenColumns}
            disableSelectColumns={[
                "StageNumber",
                "StageDescription",
                "ProgramFluidSystem",
                "ProgramProppant",
                "SlurryRate",
                "CleanVolume",
                "BlenderConcentrationStart",
                "BlenderConcentrationEnd",
                "DownholeConcentrationStart",
                "DownholeConcentrationEnd",
                "ProppantStage",
                "ProppantStageCumulative",
                "Activator",
                "SecondaryProgramFluidSystem",
                "SecondaryFluidSystemRate",
                "SecondaryFluidSystemStage",
            ]}
            columnOrder={[
                "StageNumber",
                "StageDescription",
                "ProgramFluidSystem",
                "SecondaryProgramFluidSystem",
                "ProgramProppant",
            ]}
            name={props.name}
            meta={{
                name: props.name,
                canDelete: isPageEditable,
            }}
        />
    );
};

const DynamicChemicalCell: CellType = (props) => {
    const dataItem = props.cell.row.original;

    // Constants
    const scheduleItemIdx = Number(
        props.cell.column.id.substring(props.cell.column.id.indexOf("_") + 1),
    );
    const chemicalScheduleItem: IEditChemicalScheduleViewModel =
        dataItem.ChemicalScheduleItems[scheduleItemIdx];

    if (chemicalScheduleItem.Id === 0 || chemicalScheduleItem.IsPremixed) {
        return (
            <NumericReadOnly
                {...props}
                pValue={
                    dataItem.OverriddenConcentration
                        ? chemicalScheduleItem.OverriddenConcentration
                        : chemicalScheduleItem.DefaultConcentration
                }
                viewFormatOverride={"n2"} // We override the format as these columns are generated in the backend
            />
        );
    }
    return (
        <OverrideCell
            {...props}
            pDefaultName={`ChemicalScheduleItems.${scheduleItemIdx}.DefaultConcentration`}
            pOverriddenName={`ChemicalScheduleItems.${scheduleItemIdx}.OverriddenConcentration`}
            pDefaultValue={chemicalScheduleItem.DefaultConcentration}
            pOverriddenValue={chemicalScheduleItem.OverriddenConcentration}
            overrideTooltip={
                resources.ConcentrationHasBeenOverriddenClearTheCellToResetTheValueToTheDefaultOne
            }
            defaultViewFormat={"n2"} // We override the format as these columns are generated in the backend
        />
    );
};

const ActivatorConcentrationCell: CellType = (props) => {
    const dataItem = props.cell.row.original;

    if (!dataItem.Activator) {
        return (
            <NumericReadOnly
                {...props}
                pValue={
                    dataItem.ActivatorLoading
                        ? dataItem.ActivatorLoading
                        : dataItem.ActivatorLoadingDefault
                }
                viewFormatOverride={"n2"} // We override the format as these columns are generated in the backend
            />
        );
    }
    return (
        <OverrideCell
            {...props}
            pDefaultName={"ActivatorLoadingDefault"}
            pOverriddenName={"ActivatorLoading"}
            overrideTooltip={
                resources.ConcentrationHasBeenOverriddenClearTheCellToResetTheValueToTheDefaultOne
            }
            defaultViewFormat={"n2"} // We override the format as these columns are generated in the backend
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(ChemicalScheduleTable);

import React, { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

export const HeaderPortal: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [target, setTarget] = useState<HTMLElement | null>(
        document.getElementById("header-portal"),
    );

    useEffect(() => {
        const targetElement = document.getElementById("header-portal");
        setTarget(targetElement);
    }, []); // Run this effect only once, right after the first render

    if (!target) return null;
    return createPortal(children, target);
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";
import { CellContext } from "@tanstack/react-table";

import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import { RenderHashMetaT, TableMetaT } from "types/Tables/Cells";

import { useProgram } from "hooks/useProgramParams";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { getTextAreaRows } from "utils/helpers";

const DropDown = ({
    getValue,
    row,
    column: { id },
    table,
    defaultItem,
    disableIfOneRecord,
    ...props
}: CellContext<any, any> & {
    defaultItem?: any;
    data?: INamedItemViewModel[];
    url?: string;
    disableIfOneRecord?: boolean;
}) => {
    const { index } = row;
    const initialValue = getValue<INamedItemViewModel>();
    const [value, setValue] = useState<INamedItemViewModel>(initialValue);
    const [filter, setFilter] = useState("");
    const [inEdit, setInEdit] = useState(false);
    const [textAreaRows, setTextAreaRows] = useState(1);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const localInputRef = useRef<DropDownList>(null);

    const tableMeta = table.options.meta as RenderHashMetaT;

    const onFocus = useCallback(() => {
        if (tableMeta?.disabled) return;

        setInEdit(true);
        tableMeta.select(row.index, id);
    }, [row.index, id, setInEdit, tableMeta]);

    const { program } = useProgram();
    const programId = program?.ProgramId?.toString() ?? "";

    const url =
        props.url ?? (table.options.meta as TableMetaT)?.urls?.[id] ?? "";
    // We need to keep and update the state of the cell normally

    // When the input is blurred, we'll call our table meta's updateData function
    const onChange = useCallback(
        (e: DropDownListChangeEvent) => {
            setValue(e.target.value);
            (table.options.meta as TableMetaT)?.updateData(
                index,
                id,
                e.target.value,
            );
        },
        [table.options.meta, index, id],
    );

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (inEdit) localInputRef.current?.element?.focus();
        setTextAreaRows(getTextAreaRows(textAreaRef.current));
    }, [inEdit]);

    // Queries
    const { data, isLoading } = useQuery<INamedItemViewModel[] | undefined>({
        queryKey: [url, programId],
        queryFn: async () => {
            return await fetchJET(url as JetApiUrls, { programId });
        },
        enabled: !!url,
    });

    const allData = props.data ?? data;

    if (
        (table.options.meta as TableMetaT)?.disabled ||
        (disableIfOneRecord && allData?.length === 1)
    ) {
        return <div className={"read-only"}>{value?.Name}</div>;
    }

    return (
        <div
            style={{
                width: "100%",
                maxWidth: "100%",
            }}
            className={"[&>span>span]:whitespace-normal"}
        >
            {inEdit ? (
                <DropDownList
                    ref={localInputRef}
                    filterable={true}
                    name={id}
                    value={value}
                    onChange={onChange}
                    onFilterChange={(e) => setFilter(e.filter.value)}
                    data={
                        filter
                            ? allData?.filter((x) =>
                                  x.Name.toLowerCase().includes(
                                      filter.toLowerCase(),
                                  ),
                              )
                            : allData
                    }
                    textField={"Name"}
                    dataItemKey={"Id"}
                    loading={isLoading}
                    defaultItem={defaultItem ? defaultItem : {}}
                    className={"bg-white"}
                    onBlur={() => setInEdit(false)}
                />
            ) : (
                <textarea
                    rows={textAreaRows}
                    ref={textAreaRef}
                    onDragStart={(e) => e.preventDefault()}
                    wrap={"soft"}
                    name={id}
                    value={value?.Name || (defaultItem ? defaultItem.Name : "")}
                    onChange={() => void 0}
                    onFocus={onFocus}
                    className={
                        "w-100 resize-none overflow-hidden border-0 bg-transparent p-1"
                    }
                />
            )}
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(DropDown, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue<INamedItemViewModel>();
    const nextValue = nextProps.getValue<INamedItemViewModel>();
    return (
        prevValue?.Id === nextValue?.Id &&
        prevProps.data === nextProps.data &&
        prevProps.url === nextProps.url
    );
});

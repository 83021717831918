import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import {
    GridColumn,
    GridDataStateChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";

import { IJobFilterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Job/IJobFilterViewModel";

import { PrimaryButtonLink } from "components/Buttons";
import TableLink from "components/kendoExtensions/gridExtensions/TableLink";
import StandardIndexGrid from "components/kendoExtensions/grids/StandardIndexGrid";
import { PageHeaderBar } from "components/Layout";
import {
    CountryFilterCell,
    CTTreatmentObjectiveFilterCell,
    ServiceLinesFilterCell,
} from "components/shared/Filter/FilterDropdowns";

import {
    COLUMN_INFO_ICON_PROPS,
    COLUMN_PROPS,
    DATE_COLUMN_PROPS,
} from "const/columns";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

import { AddListToQueryData } from "views/Task";
import FilterToolbar from "views/Task/FilterToolbar";

import EditJobFilter from "./_EditJobFilter";

const DEFAULT_SORT: SortDescriptor[] = [{ field: "CreatedDate", dir: "desc" }];

const Page: React.FC = () => {
    const { setValue } = useFormContext();
    const filters = useWatch({
        name: "Job.CurrentFilter",
    }) as IJobFilterViewModel;

    const defaultFilterUrl: JetApiUrls = "/api/Job/Index";
    // Load the default filter.
    const { data: defaultFilter } = useQuery<IJobFilterViewModel>({
        queryKey: ["Job", defaultFilterUrl],
        queryFn: async () => {
            return await fetchJET(defaultFilterUrl);
        },
        refetchOnMount: "always",
    });

    useEffect(() => {
        setValue("Job", defaultFilter);
    }, [defaultFilter, setValue]);

    const query = useMemo(() => {
        const newFilters: FilterDescriptor[] = [];

        if (filters?.CountryId)
            newFilters.push({
                field: "CountryId",
                operator: "eq",
                value: filters.CountryId,
            });

        const queryData: { [key: string]: any } = {};

        if (filters?.Operator) queryData["Operator"] = filters.Operator;
        if (filters?.AccountManagerId)
            queryData["AccountManagerId"] = filters.AccountManagerId;
        if (filters?.PadName) queryData["PadName"] = filters.PadName;
        AddListToQueryData(queryData, filters, "ServiceLine");
        AddListToQueryData(queryData, filters, "CTTreatmentObjective");

        return {
            filters: {
                logic: "and",
                filters: newFilters,
            } as GridDataStateChangeEvent["dataState"]["filter"],
            queryData,
        };
    }, [filters]);

    return (
        <>
            <PageHeaderBar>
                <PrimaryButtonLink to={`/Job/EditJob/0`}>
                    {resources.CreateJob}
                </PrimaryButtonLink>
            </PageHeaderBar>

            <StandardIndexGrid
                path={"/api/Job/GetJobIndexItems"}
                externalFilters={query.filters}
                initialSort={DEFAULT_SORT}
                queryData={query.queryData}
            >
                <GridToolbar>
                    <FilterToolbar
                        name={"Job"}
                        Popover={EditJobFilter}
                        savedFilterUrl={"/api/Job/GetSavedFilters"}
                    />
                </GridToolbar>
                <GridColumn
                    field={"JobNumber"}
                    title={resources.JobNumber}
                    {...COLUMN_PROPS}
                    locked
                    cell={(props) => {
                        return (
                            <TableLink
                                {...props}
                                url={`/Job/EditJob/${props.dataItem.Id}`}
                                value={props.dataItem.JobNumber}
                            />
                        );
                    }}
                    width={100}
                />
                <GridColumn
                    field={"CountryCode"}
                    title={resources.Country}
                    {...COLUMN_PROPS}
                    width={65}
                    filterCell={(props) => (
                        <CountryFilterCell
                            gridFilterCellProps={props}
                            url={"/api/Job/CountryCode"}
                        />
                    )}
                />
                <GridColumn
                    field={"ProgramTypes"}
                    title={resources.ProgramTypes}
                    {...COLUMN_PROPS}
                    width={120}
                    filterCell={ServiceLinesFilterCell}
                />
                <GridColumn
                    field={"TreatmentObjectives"}
                    title={resources.TreatmentObjectives}
                    {...COLUMN_PROPS}
                    width={120}
                    filterCell={CTTreatmentObjectiveFilterCell}
                />
                <GridColumn
                    field={"Operator"}
                    title={resources.Operator}
                    {...COLUMN_PROPS}
                    width={180}
                />
                <GridColumn
                    field={"OperatorContact"}
                    title={resources.OperatorContact}
                    {...COLUMN_PROPS}
                    width={150}
                />
                <GridColumn
                    field={"AccountManager"}
                    title={resources.AccountManager}
                    {...COLUMN_INFO_ICON_PROPS}
                    width={150}
                />
                <GridColumn
                    field={"PadName"}
                    title={resources.PadName}
                    {...COLUMN_PROPS}
                    width={90}
                />
                <GridColumn
                    field={"CreatedDate"}
                    title={resources.CreatedDate}
                    {...DATE_COLUMN_PROPS}
                />
                <GridColumn
                    field={"CreatedBy"}
                    title={resources.CreatedBy}
                    {...COLUMN_PROPS}
                />
            </StandardIndexGrid>
        </>
    );
};

export default Page;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { ComboBox as KendoComboBox } from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";
import { CellContext } from "@tanstack/react-table";

import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import { RenderHashMetaT, TableMetaT } from "types/Tables/Cells";

import { useProgramParams } from "hooks/useProgramParams";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { getTextAreaRows } from "utils/helpers";

const ComboBoxGrid = ({
    getValue,
    row: { index },
    column: { id },
    table,
    applyReadOnlyStyle = false,
}: CellContext<any, any> & {
    applyReadOnlyStyle?: boolean;
}) => {
    const initialValue = getValue();
    const { programId } = useProgramParams();
    const [value, setValue] = useState(initialValue);
    const [inEdit, setInEdit] = useState(false);
    const [textAreaRows, setTextAreaRows] = useState(1);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const localInputRef = useRef<KendoComboBox>(null);

    const tableMeta = table.options.meta as RenderHashMetaT;

    const onFocus = useCallback(() => {
        if (tableMeta?.disabled) return;

        setInEdit(true);
        tableMeta.select(index, id);
    }, [index, id, setInEdit, tableMeta]);

    const url = (table.options.meta as TableMetaT)?.urls?.[id] ?? "";
    // We need to keep and update the state of the cell normally

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
        (table.options.meta as TableMetaT)?.updateData(index, id, value);
        setInEdit(false);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (inEdit) localInputRef.current?.focus();
        setTextAreaRows(getTextAreaRows(textAreaRef.current));
    }, [inEdit]);

    // Queries
    const { data, isLoading } = useQuery<INamedItemViewModel[] | undefined>({
        queryKey: [url, programId],
        queryFn: async () => {
            return await fetchJET(url as JetApiUrls, {
                programId,
            });
        },
    });

    if ((table.options.meta as TableMetaT)?.disabled) {
        return <>{value}</>;
    }

    return (
        <>
            {inEdit ? (
                <KendoComboBox
                    ref={localInputRef}
                    name={id}
                    value={value}
                    onChange={(e) => {
                        // This occurs if you select an option or hit enter on a final option
                        setValue(e.target.value);
                    }}
                    onBlur={onBlur}
                    data={data}
                    onFilterChange={(e) => {
                        // This occurs if you type any characters into the box
                        setValue(e.filter.value);
                    }}
                    loading={isLoading}
                    className={"bg-white"}
                    filterable={true}
                    allowCustom
                />
            ) : (
                <textarea
                    rows={textAreaRows}
                    ref={textAreaRef}
                    onDragStart={(e) => e.preventDefault()}
                    wrap={"soft"}
                    name={id}
                    value={(value as string) || ""}
                    onFocus={onFocus}
                    className={`w-100 ${
                        applyReadOnlyStyle ? "read-only" : ""
                    } resize-none overflow-hidden border-0 bg-transparent p-1`}
                />
            )}
        </>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(ComboBoxGrid, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return (
        prevValue === nextValue &&
        prevProps.applyReadOnlyStyle === nextProps.applyReadOnlyStyle
    );
});

import React, { useReducer } from "react";

import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import FlowConfigurationsTable from "components/Tables/FlowConfig/FlowConfigurationsTable";

import { useProgramParams } from "hooks/useProgramParams";

const Page: React.FC = () => {
    // Constants
    const url = "/api/FlowConfiguration/EditFlowConfigurations";

    // Url params
    const { programId } = useProgramParams();

    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    return (
        <StandardForm
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            clearCacheOnSave={["/api/DesignResults/EditDesignResults"]}
            onSaveSuccess={updateTableRenderHash}
        >
            <div className={"mx-4 mt-5"}>
                <FlowConfigurationsTable
                    name={"FlowConfigurations"}
                    renderHash={tableRenderHash}
                />
            </div>
        </StandardForm>
    );
};

export default Page;

import React, { useReducer, useState } from "react";

import ConfigureGrid from "components/shared/CustomGridToolbar/ToolbarElements/ConfigureGrid";
import ImportIntervals from "components/shared/CustomGridToolbar/ToolbarElements/ImportIntervals";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import PerforationsTable from "components/Tables/Perforations/PerforationsTable";

import { useProgramParams } from "hooks/useProgramParams";

import { ClustersPopup } from "./ClustersPopup";

const EditPerforations: React.FC = () => {
    // Constants
    const url = "/api/Perforation/EditPerforations";

    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    // Url params
    const { programId } = useProgramParams();

    // State
    const [dialogVisible, setDialogVisible] = useState<
        "cluster" | "importIntervals" | "configureGrid" | null
    >(null);
    const [clustersDialogName, setClustersDialogName] = useState<string>("");
    const [clusterDialogTitle, setClustersDialogTitle] = useState<string>("");

    return (
        <>
            <StandardForm
                readUrl={url}
                readParams={{ programId }}
                saveUrl={url}
                className={"h-full"}
                onSaveSuccess={updateTableRenderHash}
                clearCacheOnSave={["/api/Schedule/EditScheduleStages"]}
            >
                <div
                    className={
                        "h-full px-4 pt-6 shadow-md ring-1 ring-calfrac-gray/5"
                    }
                >
                    <PerforationsTable
                        name={"Perforations"}
                        dialogState={dialogVisible}
                        openImportIntervalsPopup={() => {
                            setDialogVisible("importIntervals");
                        }}
                        openConfigureGridPopup={() => {
                            setDialogVisible("configureGrid");
                        }}
                        openClustersPopup={(
                            open: boolean,
                            dialogName: string,
                            dialogTitle: string,
                        ) => {
                            setDialogVisible("cluster");
                            setClustersDialogName(dialogName);
                            setClustersDialogTitle(dialogTitle);
                        }}
                        renderHash={tableRenderHash}
                    />
                </div>
            </StandardForm>
            {dialogVisible === "cluster" && (
                <ClustersPopup
                    title={clusterDialogTitle}
                    name={clustersDialogName}
                    visible={dialogVisible === "cluster"}
                    onClose={() => {
                        setDialogVisible(null);
                        setClustersDialogName("");
                        setClustersDialogTitle("");
                    }}
                />
            )}
            <ImportIntervals
                isVisible={dialogVisible === "importIntervals"}
                onClose={() => {
                    setDialogVisible(null);
                }}
            />
            <ConfigureGrid
                isVisible={dialogVisible === "configureGrid"}
                onClose={() => {
                    setDialogVisible(null);
                }}
            />
        </>
    );
};

export default EditPerforations;

import React from "react";
import { DocumentIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { DocumentCheckIcon } from "@heroicons/react/24/solid";
import { GridCellProps } from "@progress/kendo-react-grid";

import TableCell from "components/kendoExtensions/gridExtensions/TableCell";

import { downloadJET } from "utils/fetchJet";
import { resources } from "utils/resources";

const PDFCell: React.FC<GridCellProps & { value?: string }> = (props) => {
    const isDraft =
        props.dataItem.ClientOutputsAction === resources.ViewDraftPdf;
    const isFinal =
        props.dataItem.ClientOutputsAction === resources.ViewFinalPdf;
    const blobUrl = props.dataItem.BlobUrl;

    if (!blobUrl) return <TableCell {...props} />;

    return (
        <TableCell {...props}>
            <button
                title={props.dataItem.ClientOutputsAction}
                aria-label={"Download File"}
                className="mx-auto flex items-center justify-center"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    downloadJET("/api/AzureBlob/DownloadFile", {
                        path: blobUrl,
                    });
                }}
            >
                {isDraft && (
                    <>
                        <PencilSquareIcon
                            className={"h-5 w-5 stroke-calfrac-green"}
                        />
                        {props.value && <>{props.value}</>}
                    </>
                )}
                {isFinal && (
                    <>
                        <DocumentCheckIcon
                            className={"h-5 w-5 fill-calfrac-green"}
                        />
                        {props.value && <>{props.value}</>}
                    </>
                )}
                {!isDraft && !isFinal && (
                    <>
                        <DocumentIcon
                            className={"h-5 w-5 stroke-calfrac-green"}
                        />
                        {props.value && <>{props.value}</>}
                    </>
                )}
            </button>
        </TableCell>
    );
};

export default PDFCell;

import React from "react";
import { Row, Table } from "@tanstack/react-table";

import InnerTableRow from "components/Tables/BaseTable/Inner/InnerTableRow";

import { CALFRAC_GROUPED_ROW } from "const/styleClassNames";

export interface BaseTableRowProps {
    name?: string;
    row: Row<any>;
    renderHash: string;
    table: Table<any>;
    pinnedColumnBgColor: string;
    dragAndDropEnabled?: boolean;
    index?: number;
    hideRow?: boolean;
}

const BaseTableRow = React.memo(
    ({ row, index, hideRow = false, ...props }: BaseTableRowProps) => {
        let background = "bg-white";

        // Indicates whether this row is a grouped row (with a non-zero number of sub rows under it)
        let isGroupedRow = false;
        if (row.original.Ignore) background = "bg-neutral-200";
        else if (row.subRows.length > 0) {
            background = "bg-blue-300";
            isGroupedRow = true;
        }
        return (
            <tr
                className={`${isGroupedRow ? CALFRAC_GROUPED_ROW : ""} ${background} ${
                    hideRow ? "hidden" : ""
                } border-b border-gray-200`}
                {...{
                    "data-row-idx": index ?? row.index,
                    "data-row-id": row.id,
                }}
            >
                <InnerTableRow row={row} {...props} />
            </tr>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.renderHash === nextProps.renderHash &&
            prevProps.index === nextProps.index
        );
    },
);

export default BaseTableRow;

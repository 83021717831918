import React, { createContext, ReactNode, useState } from "react";

type GridState = {
    isGridEditable: boolean;
    formName: string;
    readOnlyColumns: Array<string | RegExp>;
    rowCount?: number;
    ignoreKeyPresses?: boolean;
    updateIgnoreKeyPresses?: (val: boolean) => void;
};
export const GridStateContext = createContext<GridState>({
    isGridEditable: true,
    formName: "",
    readOnlyColumns: [],
    rowCount: undefined,
    ignoreKeyPresses: false,
    updateIgnoreKeyPresses: undefined,
});

export const useGridState = () => React.useContext(GridStateContext);

/**
 * Many tested components need data related to the current program item.
 *
 * This context makes that widely available.
 * @param children
 * @param editable
 * @param formName
 * @param readonly
 * @constructor
 */
export const GridStateProvider: React.FC<
    {
        children: ReactNode;
    } & GridState
> = ({ children, isGridEditable, formName, readOnlyColumns, rowCount }) => {
    const [ignoreKeyPress, setIgnoreKeyPress] = useState<boolean | undefined>(
        false,
    );

    const updateIgnoreKeyPresses = (val: boolean) => {
        setIgnoreKeyPress(val);
    };

    return (
        <GridStateContext.Provider
            value={{
                isGridEditable: isGridEditable,
                formName,
                readOnlyColumns,
                rowCount,
                ignoreKeyPresses: ignoreKeyPress,
                updateIgnoreKeyPresses,
            }}
        >
            {children}
        </GridStateContext.Provider>
    );
};

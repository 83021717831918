import React from "react";
import { useFormContext } from "react-hook-form";

import { PrimaryButton, WarningButton } from "components/Buttons";

import { useProgram } from "hooks/useProgramParams";

type Props = {
    id?: string;
    title?: string;
    formId?: string;
    onClick?: () => void;
    width?: number;
    type?: "submit" | "button";
    buttonType?: "Primary" | "Warning";
    overrideIsPageEditable?: boolean;
    disabled?: boolean;
};

const StandardSaveButton: React.FC<Props> = ({
    id,
    formId = "PageForm",
    onClick,
    title = "Save",
    type = "submit",
    buttonType = "Primary",
    overrideIsPageEditable = false,
    disabled = false,
}: Props) => {
    const { clearErrors } = useFormContext();
    const { isPageEditable } = useProgram();

    if (!isPageEditable && !overrideIsPageEditable) return <></>;

    if (buttonType === "Warning") {
        return (
            <WarningButton
                form={formId}
                id={id}
                type={onClick ? undefined : "submit"}
                onClick={onClick}
                disabled={disabled}
            >
                {title}
            </WarningButton>
        );
    }

    return (
        <PrimaryButton
            form={formId}
            id={id}
            type={type}
            disabled={disabled}
            onClick={() => {
                clearErrors();
                onClick?.();
            }}
        >
            {title}
        </PrimaryButton>
    );
};

export default StandardSaveButton;

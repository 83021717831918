import React, { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function NavMenu() {
    const navigation = [
        { name: "Programs", href: "/", current: true },
        { name: "Requests", href: "/Request", current: false },
        { name: "Tasks", href: "/Task", current: false },
        { name: "Jobs", href: "/Job", current: false },
    ];

    const msal = useMsal();

    // Parse date from React App Version
    const buildNumber = process.env?.REACT_APP_VERSION ?? "v3";

    return (
        <Disclosure
            as="nav"
            className="sticky left-0 top-0 z-20 bg-calfrac-green sm:bg-calfrac-green"
        >
            {({ open }) => (
                <>
                    <div className="mx-auto sm:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-calfrac-gray hover:bg-calfrac-green-700 hover:text-calfrac-gray-950 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-calfrac-green-800">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6 text-calfrac-gray hover:text-white"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6 text-calfrac-gray hover:text-white "
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="relative flex h-full w-[15.625rem] flex-shrink-0 items-center">
                                    <Link
                                        to="/"
                                        className={
                                            "z-10 inline flex h-16 w-[calc(100%-6rem)] flex-row space-x-4 py-3.5"
                                        }
                                    >
                                        <img
                                            src="/logo-jet.svg"
                                            alt="JET Logo"
                                            className="h-full"
                                        />
                                        <span
                                            className={
                                                "mt-auto border-calfrac-green-900 text-xs font-bold"
                                            }
                                        >
                                            {buildNumber}
                                        </span>
                                    </Link>
                                </div>
                                <div className="align-self-center -ml-3 hidden sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <NavLink
                                                key={item.name}
                                                to={item.href}
                                                className={({ isActive }) =>
                                                    classNames(
                                                        isActive
                                                            ? "bg-calfrac-green-700 text-white"
                                                            : "text-calfrac-gray hover:bg-calfrac-green-700 hover:text-white",
                                                        "rounded-md px-3 py-2 text-sm font-medium",
                                                    )
                                                }
                                                aria-current={
                                                    item.current
                                                        ? "page"
                                                        : undefined
                                                }
                                            >
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="relative flex rounded-full bg-calfrac-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">
                                                Open user menu
                                            </span>
                                            <span className="h-8 w-8 rounded-full bg-calfrac-green-900 pt-0.5 text-xl text-white">
                                                {msal?.accounts?.[0]
                                                    ?.name?.[0] ?? "U"}
                                            </span>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <span
                                                        className={classNames(
                                                            active
                                                                ? "bg-calfrac-gray-100 "
                                                                : "",
                                                            "block px-4 py-2 text-sm  text-calfrac-gray ",
                                                        )}
                                                    >
                                                        {`Build #${buildNumber}`}
                                                    </span>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <span
                                                        className={classNames(
                                                            active
                                                                ? "bg-calfrac-gray-100 "
                                                                : "",
                                                            "block px-4 py-2 text-sm  text-calfrac-gray ",
                                                        )}
                                                    >
                                                        {
                                                            msal?.accounts?.[0]
                                                                ?.name
                                                        }
                                                    </span>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    {/* Mobile navigation */}
                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as={NavLink}
                                    to={item.href}
                                    className={({
                                        isActive,
                                    }: {
                                        isActive: boolean;
                                    }) =>
                                        classNames(
                                            isActive
                                                ? "bg-calfrac-green-800 text-white"
                                                : "text-calfrac-gray hover:bg-calfrac-green-700 hover:text-white",
                                            "block rounded-md px-3 py-2 text-base font-medium",
                                        )
                                    }
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

// export default NavMenu;

import React, { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { IEditWellDataViewModel } from "types/generated/Calfrac/Jet/Web/Models/WellData/IEditWellDataViewModel";

import StandardComboBox from "components/kendoExtensions/standardExtensions/StandardComboBox";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardMaskedTextBox from "components/kendoExtensions/standardExtensions/StandardMaskedTextBox";
import StandardNumericTextBox from "components/kendoExtensions/standardExtensions/StandardNumericTextBox";
import StandardRadioButton from "components/kendoExtensions/standardExtensions/StandardRadioButton";
import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import FormSectionWithInfo, {
    FormSectionDivider,
} from "components/Layout/FormSection";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { EmptyMasks, Masks } from "utils/enumerations";
import { findFieldFormat } from "utils/findFieldFormat";
import { resources } from "utils/resources";

const Page: React.FC = () => {
    // Constants
    const url = "/api/WellData/EditWellData";

    const { setValue } = useFormContext();
    const model = useWatch() as IEditWellDataViewModel;

    // Url params
    const { programId, program, isPageEditable } = useProgramParams();

    const units = useUnits();

    const NumberOfWellsOnPad = model.NumberOfWellsOnPad;
    useEffect(() => {
        if (!NumberOfWellsOnPad) {
            setValue("WellSequence", "");
        }
    }, [NumberOfWellsOnPad, setValue]);

    const PadDescription = model.PadDescription;
    useEffect(() => {
        if (!PadDescription) {
            setValue("WellDescription", "");
        }
    }, [PadDescription, setValue]);

    // Callbacks
    const handleChangeNTS = useCallback(() => {
        if (model?.IsNts) {
            setValue("DlsSurfaceWellLocation", null);
            setValue("DlsBottomHoleWellLocation", null);
            return;
        }
        setValue("NtsSurfaceWellLocation", null);
        setValue("NtsBottomHoleWellLocation", null);
    }, [model?.IsNts, setValue]);

    const [serviceLineChanged, setServiceLineChanged] =
        useState<boolean>(false);

    return (
        <StandardForm
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            beforeSaveHandler={handleChangeNTS}
        >
            <FormSectionDivider>
                <FormSectionWithInfo label={"Basic Information"}>
                    <StandardDropDownList
                        name={"ProvinceStateId"}
                        url={"/api/WellData/ProvinceState"}
                        params={{
                            countryId: program?.CountryId.toString() ?? "",
                        }}
                        byField={"Id"}
                        disabled={!isPageEditable}
                        label={resources.ProvinceState}
                    />
                    <StandardDropDownList
                        name={"DistrictId"}
                        url={"/api/WellData/District"}
                        params={{
                            countryId: program?.CountryId.toString() ?? "",
                            assignedToServiceLineId:
                                model.AssignedToServiceLine?.Id?.toString() ??
                                "",
                        }}
                        byField={"Id"}
                        disabled={!isPageEditable}
                        label={resources.District}
                        shouldRefresh={serviceLineChanged}
                        setShouldRefresh={setServiceLineChanged}
                    />
                    {model.HasCounties && (
                        <StandardDropDownList
                            name={"CountyId"}
                            url="/api/WellData/County"
                            params={{
                                provinceStateId:
                                    model.ProvinceStateId?.toString() ?? "",
                            }}
                            byField={"Id"}
                            disabled={!isPageEditable}
                            label={resources.County}
                        />
                    )}
                    <StandardDropDownList
                        url={`/api/Program/ServiceLine?countryId=${program?.CountryId}`}
                        name={"AssignedToServiceLine"}
                        disabled={!isPageEditable}
                        label={resources.ServiceLine}
                        onChange={() => setServiceLineChanged(true)}
                    />
                    <StandardDropDownList
                        name={"FieldId"}
                        url={"/api/WellData/Field"}
                        params={{
                            countryId: program?.CountryId.toString() ?? "",
                        }}
                        byField={"Id"}
                        disabled={!isPageEditable}
                        label={resources.Field}
                    />
                    <StandardDropDownList
                        url={"/api/WellData/WellType"}
                        name={"WellTypeId"}
                        params={{
                            serviceLineId:
                                program?.ServiceLineId.toString() ?? "",
                        }}
                        byField={"Id"}
                        disabled={!isPageEditable}
                        label={resources.WellType}
                    />
                </FormSectionWithInfo>

                <FormSectionWithInfo label={resources.Location}>
                    <StandardTextBox
                        name={"WellName"}
                        disabled={!isPageEditable}
                        label={resources.WellName}
                    />
                    <StandardTextBox
                        name={"WellLicense"}
                        disabled={!isPageEditable}
                        label={resources.WellLicense}
                    />
                    {model.HasApiNumberAndWellLocation && (
                        <>
                            <StandardMaskedTextBox
                                name={"ApiNumber"}
                                mask={Masks.ApiInputMask}
                                emptyMask={EmptyMasks.ApiInputMask}
                                label={resources.ApiNumber}
                                hint={resources.SampleApiFormat}
                                disabled={!isPageEditable}
                            />

                            <StandardTextBox
                                name={"WellLocation"}
                                disabled={!isPageEditable}
                                label={resources.WellLocation}
                                hint={resources.SamplePlssFormat}
                            />
                        </>
                    )}

                    {model.CountryId !== CountryId.Argentina &&
                        !model.HasApiNumberAndWellLocation && (
                            <>
                                <div
                                    className={
                                        "col-span-full flex flex-col gap-4"
                                    }
                                >
                                    <StandardLabel
                                        label={resources.SurveySystemType}
                                    />
                                    <div
                                        className={
                                            "flex max-w-min flex-row gap-4"
                                        }
                                    >
                                        <StandardRadioButton
                                            name={"IsNts"}
                                            target={false}
                                            label={resources.DLS}
                                            disabled={!isPageEditable}
                                        />
                                        <StandardRadioButton
                                            name={"IsNts"}
                                            target={true}
                                            label={resources.NTS}
                                            disabled={!isPageEditable}
                                        />
                                    </div>
                                </div>
                                {!model?.IsNts && (
                                    <>
                                        <StandardMaskedTextBox
                                            name={"DlsSurfaceWellLocation"}
                                            mask={Masks.DlsInputMask}
                                            emptyMask={EmptyMasks.DlsInputMask}
                                            disabled={!isPageEditable}
                                            label={
                                                resources.SurfaceWellLocation
                                            }
                                            hint={resources.SampleDlsFormat}
                                        />
                                        <StandardMaskedTextBox
                                            name={"DlsBottomHoleWellLocation"}
                                            mask={Masks.DlsInputMask}
                                            emptyMask={EmptyMasks.DlsInputMask}
                                            disabled={!isPageEditable}
                                            label={
                                                resources.BottomHoleWellLocation
                                            }
                                            hint={resources.SampleDlsFormat}
                                        />
                                    </>
                                )}
                                {model?.IsNts && (
                                    <>
                                        <StandardMaskedTextBox
                                            name={"NtsSurfaceWellLocation"}
                                            mask={Masks.NtsInputMask}
                                            emptyMask={EmptyMasks.NtsInputMask}
                                            disabled={!isPageEditable}
                                            label={
                                                resources.SurfaceWellLocation
                                            }
                                            hint={resources.SampleNtsFormat}
                                        />
                                        <StandardMaskedTextBox
                                            name={"NtsBottomHoleWellLocation"}
                                            mask={Masks.NtsInputMask}
                                            emptyMask={EmptyMasks.NtsInputMask}
                                            label={
                                                resources.BottomHoleWellLocation
                                            }
                                            disabled={!isPageEditable}
                                            hint={resources.SampleNtsFormat}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    {model.HasAfeNumber && (
                        <StandardTextBox
                            name="AfeNumber"
                            disabled={!isPageEditable}
                            label={resources.AfeNumber}
                        />
                    )}
                    {model.HasFederalAndIndianWell && (
                        <div className={"col-span-full flex flex-row gap-4"}>
                            <StandardCheckBox
                                label={resources.FederalWell}
                                name={"IsFederalWell"}
                                disabled={!isPageEditable}
                            />
                            <StandardCheckBox
                                label={resources.IndianWell}
                                name={"IsIndianWell"}
                                disabled={!isPageEditable}
                            />
                        </div>
                    )}
                    <div className={"col-span-full flex flex-col gap-y-4"}>
                        <div className="flex h-full w-full justify-start gap-x-4">
                            <StandardNumericTextBox
                                name="Latitude"
                                label={resources.SurfaceWellLatitude}
                                disabled={!isPageEditable}
                                innerProps={{ min: -90, max: 90 }}
                                format={findFieldFormat(
                                    "WellData",
                                    "Latitude",
                                    program?.CountryId,
                                )}
                            />
                            <StandardNumericTextBox
                                name="Longitude"
                                disabled={!isPageEditable}
                                label={resources.SurfaceWellLongitude}
                                innerProps={{ min: -180, max: 180 }}
                                format={findFieldFormat(
                                    "WellData",
                                    "Longitude",
                                    program?.CountryId,
                                )}
                            />
                        </div>
                        <div className="flex h-full w-full justify-start gap-x-4">
                            <StandardNumericTextBox
                                name="BottomWellLatitude"
                                label={resources.BottomWellLatitude}
                                disabled={!isPageEditable}
                                innerProps={{ min: -90, max: 90 }}
                                format={findFieldFormat(
                                    "WellData",
                                    "Latitude",
                                    program?.CountryId,
                                )}
                            />
                            <StandardNumericTextBox
                                name="BottomWellLongitude"
                                disabled={!isPageEditable}
                                label={resources.BottomWellLongitude}
                                innerProps={{ min: -180, max: 180 }}
                                format={findFieldFormat(
                                    "WellData",
                                    "Longitude",
                                    program?.CountryId,
                                )}
                            />
                        </div>
                        <div className="flex h-full w-full justify-start gap-x-4">
                            <StandardNumericTextBox
                                name="EquipmentMileage"
                                label={`${resources.EquipmentMileage} ${units.mileageUnit}`}
                                disabled={!isPageEditable}
                                format={findFieldFormat(
                                    "WellData",
                                    "EquipmentMileage",
                                    program?.CountryId,
                                )}
                                innerProps={{ min: 0, max: 999999999999.99999 }}
                            />
                            <StandardNumericTextBox
                                name="MaterialMileage"
                                disabled={!isPageEditable}
                                label={`${resources.MaterialMileage} ${units.mileageUnit}`}
                                format={findFieldFormat(
                                    "WellData",
                                    "MaterialMileage",
                                    program?.CountryId,
                                )}
                                innerProps={{ min: 0, max: 999999999999.99999 }}
                            />
                        </div>
                    </div>
                </FormSectionWithInfo>
                <FormSectionWithInfo label={resources.Pad}>
                    <div className={"col-span-full flex flex-col gap-y-4"}>
                        <div className="flex h-full w-full justify-start gap-x-4">
                            <StandardNumericTextBox
                                name="PadLatitude"
                                label={resources.PadLatitude}
                                disabled={!isPageEditable}
                                innerProps={{ min: -90, max: 90 }}
                                format={findFieldFormat(
                                    "WellData",
                                    "Latitude",
                                    program?.CountryId,
                                )}
                            />
                            <StandardNumericTextBox
                                name="PadLongitude"
                                disabled={!isPageEditable}
                                label={resources.PadLongitude}
                                innerProps={{ min: -180, max: 180 }}
                                format={findFieldFormat(
                                    "WellData",
                                    "Longitude",
                                    program?.CountryId,
                                )}
                            />
                        </div>
                    </div>
                    <StandardComboBox
                        label={resources.PadName}
                        name={"PadDescription"}
                        url={`/api/Job/GetPadNames`}
                        params={{
                            countryId: model?.CountryId?.toString() ?? "",
                            operatorAccountId: model?.OperatorId
                                ? model?.OperatorId!.toString()
                                : "",
                        }}
                        disabled={!isPageEditable}
                        className={"lg:col-span-2"}
                    />
                    <StandardTextBox
                        name={"WellDescription"}
                        label={resources.WellDescription}
                        disabled={!isPageEditable || !model?.PadDescription}
                    />
                    <StandardNumericTextBox
                        name="NumberOfWellsOnPad"
                        disabled={!isPageEditable}
                        label={resources.NumberOfWellsOnPad}
                        format={findFieldFormat(
                            "WellData",
                            "NumberOfWellsOnPad",
                            program?.CountryId,
                        )}
                        innerProps={{ min: 0, max: Number.MAX_SAFE_INTEGER }}
                    />
                    <StandardNumericTextBox
                        name="WellSequence"
                        label={resources.WellSequence}
                        disabled={!isPageEditable || !model?.NumberOfWellsOnPad}
                        format={findFieldFormat(
                            "WellData",
                            "WellSequence",
                            program?.CountryId,
                        )}
                        innerProps={{ min: 0, max: Number.MAX_SAFE_INTEGER }}
                    />
                </FormSectionWithInfo>
            </FormSectionDivider>
        </StandardForm>
    );
};

export default Page;

import React from "react";

import { downloadJET, JetApiUrls } from "utils/fetchJet";

type Props = {
    url: JetApiUrls;
    text?: string;
};

const StandardDownloadLink: React.FC<Props> = ({ url, text }: Props) => {
    return (
        <span
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                downloadJET(url);
            }}
            className={`cursor-pointer text-calfrac-green underline hover:text-calfrac-green-800 `}
        >
            {text}
        </span>
    );
};

export default StandardDownloadLink;

import React, { useCallback, useMemo, useRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { UploadOnAddEvent } from "@progress/kendo-react-all";
import {
    CellContext,
    ColumnDef,
    createColumnHelper,
} from "@tanstack/react-table";

import { IEditProgramAttachmentViewModel } from "types/generated/Calfrac/Jet/Web/Models/ProgramAttachment/IEditProgramAttachmentViewModel";

import BoundDownloadCell from "components/Cells/BoundDownloadCell";
import DateCell from "components/Cells/DateCell";
import DeleteButton from "components/Cells/DeleteButton";
import PDFCell from "components/Cells/PDFCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import AddFile from "components/shared/CustomGridToolbar/ToolbarElements/AddFile";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram } from "hooks/useProgramParams";

import { downloadJET } from "utils/fetchJet";
import { resources } from "utils/resources";

export enum CellType {
    PDFCell = 0,
    BoundLink = 1,
}

export enum TableType {
    Simple = 0, // Table that has two columns File and File Name
    Complex = 1, // Table that has three columns File Name, CreatedDate, and CreatedBy
}

type AttachmentTableProps = {
    name: string;
    tableType?: TableType;
    fileCellType?: CellType;
    showFileName?: boolean;
    canAddNewFile?: boolean;
    canDelete?: boolean;
    columns?: ColumnDef<any, any>[];
    columnOrder?: string[];
    allowedExtensions?: string[];
    addButtonLabel?: string;

    // Used on the Client Outputs page, on the "Insert Additional Pages" section where user can upload .pdf attachments
    // If true, show the clickable link button that downloads the static file template
    showDownloadAttachmentTemplate?: boolean;
};

const columnHelper = createColumnHelper<any>();

// Cell that changes to either PDF cell or bound download link depending on some conditions
const DynamicFileCell = React.memo(
    ({
        fileCellType,
        ...props
    }: CellContext<IEditProgramAttachmentViewModel, unknown> & {
        fileCellType: CellType;
    }) => {
        const isFileSaved = !isNaN(Number(props.row.original.Id));

        switch (fileCellType) {
            case CellType.PDFCell:
                return !isFileSaved ? <></> : <PDFCell {...props} />;
            case CellType.BoundLink:
                return !isFileSaved ? (
                    <TextReadOnly {...props} />
                ) : (
                    <BoundDownloadCell {...props} />
                );
        }
    },
);

const AttachmentTable: React.FC<AttachmentTableProps> = ({
    name,
    tableType = TableType.Complex,
    fileCellType = CellType.PDFCell,
    canAddNewFile = false,
    canDelete = true,
    showFileName = true,
    columns = [],
    columnOrder = [],
    allowedExtensions,
    addButtonLabel,
    showDownloadAttachmentTemplate,
}: AttachmentTableProps) => {
    const { isPageEditable } = useProgram();

    const msal = useMsal();

    const { clearErrors } = useFormContext();
    const { append } = useFieldArray({ name });

    const newRowCount = useRef(0);

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.display({
                id: "DownloadCell",
                size: fileCellType === CellType.PDFCell ? 30 : 1000,
                header: " ",
                cell: (props) => (
                    <DynamicFileCell fileCellType={fileCellType} {...props} />
                ),
            }),
            columnHelper.accessor((row) => row.FileName, {
                id: "FileName",
                size: tableType === TableType.Complex ? 800 : 1500,
                minSize: tableType === TableType.Complex ? 100 : 1500,
                header: resources.FileName,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row.Date ?? row.EnteredOn, {
                id: "Date",
                size: columns?.length > 0 ? 200 : 250,
                minSize: 20,
                header: resources.CreatedDate,
                cell: (props) => <DateCell {...props} readOnly={true} />,
            }),
            columnHelper.accessor((row) => row.User ?? row.EnteredBy, {
                id: "User",
                size: columns?.length > 0 ? 200 : 250,
                minSize: 20,
                header: resources.CreatedBy,
                cell: TextReadOnly,
            }),
            ...columns,
        ];
    }, [columns, fileCellType, tableType]);

    // Helper
    const decodeFileName = (fileName: string) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = fileName;
        return txt.value;
    };

    // Handlers
    const addFile = useCallback(
        (fileValue: UploadOnAddEvent) => {
            fileValue.newState.forEach((file) => {
                if (file.getRawFile) {
                    const nextId = `NEW-${newRowCount.current++}`;
                    const fileName = decodeFileName(file.name);

                    const newRecord = {
                        EnteredBy: msal.accounts[0].name,
                        EnteredOn: new Date().toISOString(),
                        CreatedBy: msal.accounts[0].name,
                        Date: new Date().toISOString(),
                        FileName: fileName,
                        NewAttachmentFileGuid: file.uid,
                        Id: nextId,
                    };

                    append(newRecord);
                } else {
                    toast.error(
                        `Error uploading file: ${file.name}. Please try again.`,
                    );
                }
            });
            clearErrors();
        },
        [append, clearErrors, msal.accounts],
    );

    return (
        <BaseTable
            name={name}
            columns={defaultColumns}
            columnOrder={[
                "Delete",
                ...columnOrder,
                "DownloadCell",
                "FileName",
                "Date",
                "User",
            ]}
            meta={{
                canDelete: isPageEditable,
                disabled: !isPageEditable,
                name: name,
            }}
            columnVisibility={{
                Delete: isPageEditable && canDelete,
                FileName: showFileName,
                Date: tableType === TableType.Complex,
                User: tableType === TableType.Complex,
            }}
            toolbarExtras={() => (
                <React.Fragment>
                    <AddFile
                        label={addButtonLabel}
                        isVisible={canAddNewFile}
                        allowMultiple={true}
                        showFileList={true}
                        onClick={addFile}
                        allowedExtensions={allowedExtensions}
                    />
                    {showDownloadAttachmentTemplate && (
                        <div
                            className={
                                "sticky left-0 z-10 flex w-full items-center justify-between"
                            }
                        >
                            <div className={"space-x-4"}>
                                <div
                                    onClick={(
                                        e: React.MouseEvent<HTMLSpanElement>,
                                    ) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        downloadJET(
                                            `/api/ClientOutputs/GetAttachmentTemplate`,
                                        );
                                    }}
                                    className={
                                        "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                                    }
                                >
                                    {resources.DownloadAttachmentTemplate}
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default AttachmentTable;

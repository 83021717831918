import React, { useCallback, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
    DropDownList,
    DropDownListChangeEvent,
    DropDownListFilterChangeEvent,
    filterBy,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";
import styled, { css } from "styled-components";

import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { fetchJET, JetApiUrls } from "utils/fetchJet";

import { LoaderWithLabel } from "./LoaderWithLabel";

interface Props<T = INamedItemViewModel, E = INamedItemViewModel> {
    id?: string;
    value?: T;
    data?: E[];
    // Data Retrieval
    url?: JetApiUrls;
    params?: Record<string, string> | string;
    queryKey?: Array<string | number | boolean | undefined | null>;
    // Configuration
    textField?: string;
    disabled?: boolean;
    filterable?: boolean;
    queryEnabled?: boolean;
    label?: string;
    comboBox?: boolean;
    defaultItem?: Record<string, string | number>;
    // Events
    onChange?: (e: E | undefined) => void;
    // Used with react hook form to register element
    name?: string;
    byField?: "Id" | "Name" | "Object";
    // Pagination
    virtual?: boolean;
    shouldRefresh?: boolean;
    setShouldRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
    required?: boolean;
    // Icon
    hasIcon?: boolean;
    tooltipText?: string;
}

const StandardDropDownList = ({
    filterable = true,
    queryEnabled = true,
    defaultItem = {},
    name = " ",
    virtual,
    shouldRefresh = false,
    required = false,
    hasIcon = false,
    tooltipText = "",
    ...props
}: Props) => {
    // React hook form
    const { setValue, clearErrors } = useFormContext();

    const formValue = useWatch({ name });

    const { setShouldRefresh } = props;

    // Queries
    const {
        data: requestData,
        isLoading,
        refetch,
    } = useQuery<INamedItemViewModel[] | undefined>({
        initialData: props.data,
        queryKey: [
            props.url,
            ...(props.queryKey ?? []),
            ...Object.entries(props.params ?? []).map(
                ([key, value]) => `${key}:${value}`,
            ),
        ],
        queryFn: async () => {
            return await fetchJET(props.url, props.params);
        },
        enabled: queryEnabled && !props.data,
    });

    const allData = props.data ?? requestData;

    const currentValue = useMemo(() => {
        if (!props.byField || props.byField === "Object") {
            return formValue;
        }

        const field = props?.byField ?? "Id";
        return allData?.find((row) => row?.[field] === formValue);
    }, [allData, formValue, props.byField]);

    // State
    const [filter, setFilter] = useState("");
    const [filteredData, setFilteredData] = useState<INamedItemViewModel[]>();

    // Filtering
    const filterData = (event: DropDownListFilterChangeEvent) => {
        if (allData === undefined) return;
        setFilter(event.filter.value as string);
        setFilteredData(filterBy(allData, [event.filter], "contains"));
    };

    // Others

    const handleChange = useCallback(
        (e: DropDownListChangeEvent) => {
            let value = e.target.value;

            // Apply an optional pre-filter
            if (props.byField && props.byField !== "Object") {
                setValue(name, value[props.byField], { shouldDirty: true });
            } else {
                setValue(name, value, { shouldDirty: true });
            }
            props.onChange?.(value);
            clearErrors(name);
        },
        [clearErrors, name, props, setValue],
    );

    const onOpen = useCallback(async () => {
        if (!queryEnabled && props.data === undefined) await refetch();

        // Manual refresh if data in this dropdown depends on data selected in a different dropdown.
        // Resolves issue where user could begin a search in the current dropdown and the search would
        // persist after dropdown data options had changed based on another dropdown,
        // trapping the user in an incorrect state that was not dismissable.
        if (shouldRefresh && setShouldRefresh) {
            setFilter("");
            setFilteredData(undefined);
            setShouldRefresh(false);
        }
    }, [props.data, queryEnabled, refetch, shouldRefresh, setShouldRefresh]);

    if (isLoading && queryEnabled)
        return <LoaderWithLabel label={props.label} />;

    return (
        <DropDownListContainer disabled={props.disabled}>
            {props.label && (
                <div>
                    <StandardLabel
                        labelFor={props.id}
                        label={props.label}
                        required={required}
                        showTooltip={hasIcon}
                        tooltipText={tooltipText}
                    />
                </div>
            )}
            <DropDownList
                virtual={
                    virtual ? { total: 500, pageSize: 50, skip: 0 } : undefined
                }
                filterable={filterable}
                filter={filter}
                onFilterChange={filterData}
                textField={props.textField ?? "Name"}
                onChange={handleChange}
                style={
                    !props.disabled
                        ? {
                              width: "100%",
                          }
                        : {
                              width: "100%",
                              color: "black",
                              opacity: "100%",
                              border: "none",
                              backgroundColor: "rgb(247 247 247)",
                          }
                }
                data={filteredData ?? allData}
                value={currentValue ?? defaultItem}
                disabled={props.disabled}
                onOpen={onOpen}
                // The following allows dropdowns to have an "empty" row - which effectively clears the selection.
                defaultItem={defaultItem}
                className={
                    props?.disabled
                        ? "bg-calfrac-gray-50  "
                        : "bg-white bg-none"
                }
            />
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </DropDownListContainer>
    );
};

const DropDownListContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;

    ${(props) =>
        props.disabled
            ? css`
                  .k-clear-value,
                  .k-button {
                      display: none;
                  }
              `
            : ""}
`;

export default StandardDropDownList;

import React, { useCallback, useReducer, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { Dialog, GridColumn } from "@progress/kendo-react-all";

import { BackCalculatePumpScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/BackCalculatePumpScheduleStageTarget";
import { FormulaCalculateScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/FormulaCalculateScheduleStageTarget";
import { IEditScheduleStagesViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditScheduleStagesViewModel";
import { IEditScheduleStageViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditScheduleStageViewModel";
import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";

import { queryClient } from "AppRoutes/AppProviders";

import { PrimaryButton } from "components/Buttons";
import { H2 } from "components/Headers";
import StandardEditGrid from "components/kendoExtensions/grids/StandardEditGrid";
import { GridComboBox } from "components/kendoExtensions/standardExtensions/StandardComboBox";
import CementView, { NotCementView } from "components/Layout/CementView";
import CtanView, {
    isProgramCtan,
    NotCtanView,
} from "components/Layout/CtanView";
import FormSection from "components/Layout/FormSection";
import { HeaderPortal } from "components/Layout/HeaderPortal";
import { SubtitlePortal } from "components/Layout/SubtitlePortal";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";
import StandardButton from "components/shared/GenericCustomComponents/StandardButton";
import StandardCheckBox, {
    GridCheckBox,
} from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardReadOnly from "components/shared/GenericCustomComponents/StandardReadOnly";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";
import FormulaCalculatePopUp from "components/Tables/Schedules/FormulaCalculate/FormulaCalculatePopUp";
import StagesTable from "components/Tables/Schedules/StagesTables/StagesTable";

import { SMALL_COLUMN_PROPS } from "const/columns";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { Formats } from "utils/enumerations";
import { fetchJET } from "utils/fetchJet";
import { findFieldFormat } from "utils/findFieldFormat";
import { parseSafeStringIdToInt } from "utils/parseSafeStringIdToInt";
import { resources } from "utils/resources";

import CopyPumpScheduleStages from "./_CopyPumpScheduleStages";
import BackCalculatePumpScheduleStage from "./BackCalculatePumpScheduleStage";

const Page: React.FC = () => {
    // Url params
    const { scheduleId } = useParams();

    const navigate = useNavigate();

    // Hooks
    const { programId, isPageEditable, program } = useProgramParams();
    const units = useUnits();

    // React-hook form
    const model = useWatch() as IEditScheduleStagesViewModel;
    const { formState, reset } = useFormContext();

    // State
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [notesVisible, setNotesVisible] = useState<boolean>(false);

    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    const getNewPumpScheduleStage =
        useCallback((): Partial<IEditScheduleStageViewModel> => {
            // sorting ensures we always set stage number for new record to the largest value,
            // as the largest value may have been moved elsewhere in the grid by the user
            // and may not be on the last record anymore when they added a new record
            const last =
                model?.Stages?.sort((a, b) => {
                    return a.StageNumber - b.StageNumber;
                }) ?? [];
            const stage = last.at(-1);
            const isInShutDownStage = stage?.IsShutdown ?? false;
            const newStage: Partial<IEditScheduleStageViewModel> = {
                StageNumber: 1,
                SlurryRate: isInShutDownStage ? undefined : 0,
                CleanRateStart: isInShutDownStage ? undefined : 0,
                CleanVolume: isInShutDownStage ? undefined : 0,
                CleanVolumeCumulative: isInShutDownStage ? undefined : 0,
                ProppantStage: isInShutDownStage ? undefined : 0,
                ProppantStageCumulative: isInShutDownStage ? undefined : 0,
                BlenderConcentrationStart: isInShutDownStage ? undefined : 0,
                BlenderConcentrationEnd: isInShutDownStage ? undefined : 0,
                StageTime: "00:00:00",
                StageTimeCumulative: "00:00:00",
                DownholeConcentrationStart: isInShutDownStage ? undefined : 0,
                DownholeConcentrationEnd: isInShutDownStage ? undefined : 0,
                DownholeN2Rate: isInShutDownStage ? undefined : 0,
                N2Rate: isInShutDownStage ? undefined : 0,
                N2Ratio: isInShutDownStage ? undefined : 0,
                N2Stage: isInShutDownStage ? undefined : 0,
                N2StageCumulative: isInShutDownStage ? undefined : 0,
                CO2Rate: isInShutDownStage ? undefined : 0,
                CO2Ratio: isInShutDownStage ? undefined : 0,
                CO2Stage: isInShutDownStage ? undefined : 0,
                CO2StageCumulative: isInShutDownStage ? undefined : 0,
                SecondaryProgramFluidSystem:
                    undefined as unknown as INamedItemViewModel,
                SecondaryFluidSystemRate: isInShutDownStage ? undefined : 0,
                SecondaryFluidSystemRatio: isInShutDownStage ? undefined : 0,
                SecondaryFluidSystemStage: isInShutDownStage ? undefined : 0,
                SecondaryFluidSystemStageCumulative: isInShutDownStage
                    ? undefined
                    : 0,
                DownholeRate: isInShutDownStage ? undefined : 0,
                DownholeVolume: isInShutDownStage ? undefined : 0,
                DownholeFoamQuality: isInShutDownStage ? undefined : 0,
                StartDepth: isInShutDownStage ? undefined : 0,
                EndDepth: isInShutDownStage ? undefined : 0,
                Speed: isInShutDownStage ? undefined : 0,
                Time: isInShutDownStage ? undefined : 0,
                FluidRate: isInShutDownStage ? undefined : 0,
                FluidTotal: isInShutDownStage ? undefined : 0,
                N2Total: isInShutDownStage ? undefined : 0,
                CombinedDisplacementRate: isInShutDownStage ? undefined : 0,
                CombinedDisplacementTotal: isInShutDownStage ? undefined : 0,
                DownholeTotal: isInShutDownStage ? undefined : 0,
                NetMeter: isInShutDownStage ? undefined : 0,
            };

            if (!stage) {
                return newStage;
            } else {
                return {
                    ...newStage,
                    StageNumber: stage.StageNumber + 1,
                    SlurryRate: stage.SlurryRate,
                    StageDescription: stage.StageDescription,
                    ProgramFluidSystem: stage.ProgramFluidSystem,
                    ProgramProppant: stage.ProgramProppant,
                    N2Rate: stage.N2Rate,
                    CO2Rate: stage.CO2Rate,
                    SecondaryProgramFluidSystem:
                        stage.SecondaryProgramFluidSystem,
                    SecondaryFluidSystemRate: stage.SecondaryFluidSystemRate,
                };
            }
        }, [model?.Stages]);

    // For Back-Calculation
    const [showBackCalculatePopup, setShowBackCalculatePopup] = useState(false);
    const [backCalculateData, setBackCalculateData] = useState<{
        colId: string;
        rowId: number | null;
        field: string;
        callback: () => void;
        target: BackCalculatePumpScheduleStageTarget;
    }>();

    const onBackCalculate = useCallback(
        (
            colId: string,
            rowIdx: number,
            target: BackCalculatePumpScheduleStageTarget,
            callback: () => void,
        ) => {
            let dataRow = model?.Stages[rowIdx];
            setBackCalculateData({
                colId,
                rowId: parseSafeStringIdToInt(dataRow?.Id),
                field: `Stages.${rowIdx}.${colId}`,
                callback,
                target,
            });
            setShowBackCalculatePopup(true);
        },
        [model?.Stages],
    );

    // For Formula Calculation
    const [showFormulaCalculatePopup, setShowFormulaCalculatePopup] =
        useState(false);
    const [formulaCalculateData, setFormulaCalculateData] = useState<{
        colId: string;
        rowId: number | null;
        rowIdx: number;
        field: string;
        target: FormulaCalculateScheduleStageTarget;
        callback: () => void;
    }>();

    const onFormulaCalculate = useCallback(
        (
            colId: string,
            rowIdx: number,
            target: FormulaCalculateScheduleStageTarget,
            callback: () => void,
        ) => {
            let dataRow = model?.Stages[rowIdx];

            setFormulaCalculateData({
                colId,
                rowId: parseSafeStringIdToInt(dataRow?.Id),
                rowIdx: rowIdx,
                field: `Stages`,
                target: target,
                callback: callback,
            });
            setShowFormulaCalculatePopup(true);
        },
        [model?.Stages],
    );

    const isCtan = isProgramCtan(program);

    return (
        <>
            {isDownloading && <LoadingOverlay />}
            <StandardForm
                readUrl={"/api/Schedule/EditScheduleStages"}
                readParams={{ scheduleId: scheduleId ?? "" }}
                saveUrl={"/api/Schedule/EditScheduleStages"}
                showValidation={true}
                className={"flex-column flex h-full"}
                onSaveSuccess={() => {
                    reset({}, { keepValues: true });
                    updateTableRenderHash();
                }}
                clearCacheOnSave={[
                    "/api/Schedules/EditSchedules",
                    "/Task/EditProgramTaskAction",
                ]}
            >
                {/*This renders the popup modal*/}
                <EditNotes
                    visible={notesVisible}
                    onClose={() => setNotesVisible(false)}
                />
                <SubtitlePortal>
                    <div className={"flex items-center gap-5"}>
                        <H2>{`/ ${resources.Schedule} ${model?.ScheduleNumber}`}</H2>
                        <div
                            className={"flex items-center gap-1"}
                            onClick={async () => {
                                navigate(
                                    `/Schedules/EditSchedules/${programId}`,
                                );
                                if (isCtan) {
                                    await queryClient.invalidateQueries({
                                        predicate: (query) => {
                                            return !(
                                                query.queryKey[0] as string
                                            )?.startsWith(
                                                `/api/PumpSchedule/EditPumpScheduleStages`,
                                            );
                                        },
                                        type: "all",
                                    });
                                }
                            }}
                        >
                            <ArrowLeftCircleIcon
                                className={"h-7 w-7 fill-calfrac-green"}
                            />
                            <StandardAnchorLink
                                text={resources.BackToSchedules}
                            />
                        </div>
                    </div>
                </SubtitlePortal>
                {isPageEditable && (
                    <HeaderPortal>
                        <div className={"flex items-center justify-around"}>
                            <StandardButton
                                classes={"main-header-action"}
                                onClick={() => {
                                    setIsDownloading(true);
                                    fetchJET<Response>(
                                        "/api/ClientOutputs/PrintPumpSchedule",
                                        {
                                            pumpScheduleId: scheduleId ?? "",
                                            countryId:
                                                program?.CountryId?.toString() ??
                                                "",
                                        },
                                        undefined,
                                        undefined,
                                        undefined,
                                        true,
                                    )
                                        .then(async (res) => {
                                            // read the body of the stream as file and download it
                                            const file = await res.blob();
                                            // download this file
                                            const url =
                                                window.URL.createObjectURL(
                                                    file,
                                                );
                                            let alink =
                                                document.createElement("a");
                                            alink.target = "_blank";
                                            alink.href = url;
                                            alink.download = `${program?.ProgramNumber}-${program?.Revision}.pdf`;
                                            alink.click();
                                        })
                                        .finally(() => {
                                            setIsDownloading(false);
                                        });
                                }}
                                text={resources.ViewPdf}
                            />
                        </div>
                        <CopyPumpScheduleStages
                            updateRenderHash={updateTableRenderHash}
                            isFormDirty={formState.isDirty}
                        />
                    </HeaderPortal>
                )}
                <FormSection padding={"py-1 px-3"}>
                    <NotCementView>
                        <StandardReadOnly
                            name={isCtan ? "Repeated" : "ZoneRanges"}
                            label={
                                isCtan
                                    ? resources.Repeated
                                    : resources.ZonesStages
                            }
                            format={Formats.Integer.toString()}
                        />
                    </NotCementView>
                    <StandardCheckBox
                        label={resources.Executed}
                        name={"IsExecuted"}
                    />
                    <StandardReadOnly
                        name={"FluidTotal"}
                        label={`${resources.FluidTotal} ${units.volumeSchedule}`}
                        format={findFieldFormat(
                            "PumpScheduleStages",
                            "FluidTotal",
                            program?.CountryId,
                        )}
                    />
                    <CementView>
                        <StandardReadOnly
                            name={"CementTotal"}
                            label={`${resources.CementTotal} ${units.massCementSmall}`}
                            format={findFieldFormat(
                                "PumpScheduleStages",
                                "CementTotal",
                                program?.CountryId,
                            )}
                        />
                    </CementView>
                    {
                        <CtanView>
                            <StandardReadOnly
                                name={"N2Total"}
                                label={`${resources.N2Total} ${units.amountGasUnit}`}
                                format={findFieldFormat(
                                    "PumpScheduleStages",
                                    "FluidTotal",
                                    program?.CountryId,
                                )}
                            />
                        </CtanView>
                    }
                    <NotCementView>
                        <NotCtanView>
                            <StandardReadOnly
                                name={"ProppantTotal"}
                                label={`${resources.ProppantTotal} ${units.massSmallUnit}`}
                                format={findFieldFormat(
                                    "PumpScheduleStages",
                                    "ProppantTotal",
                                    program?.CountryId,
                                )}
                            />
                        </NotCtanView>
                    </NotCementView>
                    <StandardReadOnly
                        name={"TimeTotal"}
                        label={resources.TimeTotal}
                    />
                    {model.HasN2 && (
                        <StandardReadOnly
                            name={"SpaceFactor"}
                            label={`${resources.SpaceFactor} ${units.gasRatioUnit}`}
                            format={"n1"}
                        />
                    )}
                    <CtanView>
                        <StandardReadOnly
                            name={"NetMeterTotal"}
                            label={`${resources.NetDepthTotal} ${units.depthUnit}`}
                            format={findFieldFormat(
                                "PumpScheduleStages",
                                "NetMeterTotal",
                                program?.CountryId,
                            )}
                        />
                    </CtanView>
                </FormSection>
                <div
                    className={
                        "mt-4 h-full w-full flex-1 overflow-scroll  bg-white pt-4 shadow-md ring-1 ring-calfrac-gray/5 "
                    }
                >
                    <StagesTable
                        name={"Stages"}
                        getNewRecord={getNewPumpScheduleStage}
                        onEditNotes={() => {
                            setNotesVisible(true);
                        }}
                        onBackCalculate={onBackCalculate}
                        onFormulaCalculate={onFormulaCalculate}
                        renderHash={tableRenderHash}
                    />
                </div>
                {showBackCalculatePopup && (
                    <BackCalculatePumpScheduleStage
                        onClose={() => {
                            backCalculateData?.callback();
                            setShowBackCalculatePopup(false);
                        }}
                        pumpScheduleStageId={backCalculateData?.rowId ?? 0}
                        field={backCalculateData?.colId ?? ""}
                        target={
                            backCalculateData?.target ??
                            BackCalculatePumpScheduleStageTarget.DownholeConcentrationStart
                        }
                    />
                )}
                {showFormulaCalculatePopup && (
                    <FormulaCalculatePopUp
                        onClose={() => {
                            formulaCalculateData?.callback();
                            setShowFormulaCalculatePopup(false);
                        }}
                        treatmentScheduleStageId={
                            formulaCalculateData?.rowId ?? 0
                        }
                        columnName={formulaCalculateData?.colId ?? ""}
                        rowIdx={formulaCalculateData?.rowIdx ?? 0}
                        field={formulaCalculateData?.field ?? ""}
                        target={
                            formulaCalculateData?.target ??
                            FormulaCalculateScheduleStageTarget.StartDepth
                        }
                    />
                )}
            </StandardForm>
        </>
    );
};

type EditNotesProps = {
    visible?: boolean;
    onClose?: () => void;
};

/*
This is a popup modal component that allows the user to edit the notes for the pump schedule stages,
it is opened inside of the main page component when the user clicks on the edit notes button on the standardeditgrid toolbar
*/
const EditNotes: React.FC<EditNotesProps> = (props) => {
    const { isPageEditable, program } = useProgramParams();

    const { setValue } = useFormContext();

    const notes = useWatch({ name: "StagesScheduleNotes" });

    const handleClose = useCallback(() => {
        const mappedNotes: INamedItemViewModel[] = notes.map(
            (note: any | INamedItemViewModel) => {
                if (typeof note.Note === "string") {
                    return { ...note, Note: { Id: 0, Name: note.Note } };
                }
                return note;
            },
        );
        setValue("StagesScheduleNotes", mappedNotes);
        props?.onClose?.();
    }, [notes, props, setValue]);

    if (!props?.visible) return <></>;

    return (
        <>
            <Dialog
                title={resources.EditNotes}
                onClose={handleClose}
                width={800}
                height={450}
                themeColor={"primary"}
            >
                <StandardEditGrid
                    canCreate
                    canEdit={isPageEditable}
                    name={"StagesScheduleNotes"}
                    height={"300px"}
                    addRecordDefault={{ DisplayOnOutput: true }}
                >
                    <GridColumn
                        title={resources.DisplayOnOutput}
                        field={"DisplayOnOutput"}
                        editable={isPageEditable}
                        cell={GridCheckBox}
                        {...SMALL_COLUMN_PROPS}
                        width={140}
                    />
                    <GridColumn
                        title={resources.Note}
                        field={"Note"}
                        editable={isPageEditable}
                        format={`/api/Schedule/StandardPumpScheduleNotes?countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`}
                        cell={GridComboBox}
                    />
                </StandardEditGrid>
                <div className={"w-100 relative"}>
                    <PrimaryButton
                        className={"bottom-100 absolute right-0"}
                        onClick={handleClose}
                    >
                        {resources.Done}
                    </PrimaryButton>
                </div>
            </Dialog>
        </>
    );
};

export default Page;

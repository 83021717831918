import React, { useReducer } from "react";

import { PremixedChemicalTypeId } from "types/generated/Calfrac/Jet/Core/Models/PremixedChemicalTypeId";

import CtanView, { NotCtanView } from "components/Layout/CtanView";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import AdditionalChemicals from "components/Tables/PremixedChemicals/AdditionalChemicalsTable";
import PremixedChemicalsTable from "components/Tables/PremixedChemicals/PremixedChemicalsTable";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

const Page: React.FC = () => {
    // Constants -- Note: PremixedChemicals is the legacy name for this. It is now called AdditionalChemicals
    const url = "/api/PremixedChemical/EditPremixedChemical";

    // Url params
    const { programId } = useProgramParams();

    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    return (
        <StandardForm
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            onSaveSuccess={updateTableRenderHash}
        >
            <FormSectionDivider>
                {/* External Inhibitors, Coiled Tubing - Periodic Pills, and Extra  (CTAN) */}
                <CtanView>
                    <FormSection label={resources.ExternalInhibitors}>
                        <AdditionalChemicals
                            name={"ExternalInhibitorAdditionalChemicals"}
                            tableType={PremixedChemicalTypeId.ExternalInhibitor}
                        />
                    </FormSection>
                    <FormSection label={resources.CoiledTubingPeriodicPills}>
                        <AdditionalChemicals
                            name={"PeriodicPillAdditionalChemicals"}
                            tableType={PremixedChemicalTypeId.PeriodicPill}
                        />
                    </FormSection>
                    <FormSection label={resources.PremixedChemicalsExtra}>
                        <PremixedChemicalsTable
                            name={"ContingencyAdditionalChemicals"}
                            renderHash={tableRenderHash}
                            tableType={
                                PremixedChemicalTypeId.ContingencyChemical
                            }
                        />
                    </FormSection>
                </CtanView>
                {/* Standard, Custom, Extra Premixed Chemicals (FRAC) */}
                <NotCtanView>
                    <FormSection label={resources.PremixedChemicalsCustom}>
                        <PremixedChemicalsTable
                            name={"CustomPremixedChemicals"}
                            renderHash={tableRenderHash}
                            tableType={PremixedChemicalTypeId.Custom}
                        />
                    </FormSection>
                    <FormSection label={resources.CoiledTubingPeriodicPills}>
                        <AdditionalChemicals
                            name={"PeriodicPillAdditionalChemicals"}
                            tableType={PremixedChemicalTypeId.PeriodicPill}
                        />
                    </FormSection>
                    <FormSection label={resources.PremixedChemicalsExtra}>
                        <PremixedChemicalsTable
                            name={"ExtraPremixedChemicals"}
                            renderHash={tableRenderHash}
                            tableType={PremixedChemicalTypeId.Extra}
                        />
                    </FormSection>
                </NotCtanView>
            </FormSectionDivider>
        </StandardForm>
    );
};

export default Page;

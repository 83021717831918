import React from "react";
import { useParams } from "react-router-dom";

import StandardForm from "components/shared/GenericCustomComponents/StandardForm";

import { EditProgramTaskAction } from "./EditProgramTaskAction";

const Page: React.FC = () => {
    const url = "/api/Task/HistoricalProgramTask";
    const { taskId } = useParams();

    return (
        <StandardForm
            readUrl={url}
            readParams={{
                taskId,
            }}
            saveUrl={url}
        >
            <EditProgramTaskAction readOnly={true} />
        </StandardForm>
    );
};

export default Page;

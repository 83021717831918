import React from "react";

import { BaseProps } from "components/shared/CustomGridToolbar/StandardEditGridToolbar";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";

import { resources } from "utils/resources";

export interface EstimatedDepthProps extends BaseProps {
    name?: string;
    readOnly?: boolean;
}

const EstimatedDepth: React.FC<EstimatedDepthProps> = ({
    isVisible,
    readOnly = true,
    ...props
}: EstimatedDepthProps) => {
    if (!isVisible) return <></>;

    return (
        <StandardCheckBox
            sidelabel={resources.EstimatedDepth}
            name={props.name}
            disabled={readOnly}
        />
    );
};

export default EstimatedDepth;

import React from "react";
import { GridFilterCellProps } from "@progress/kendo-react-all";

import { DropdownFilterCell } from "components/shared/CustomGridCells/DropdownFilterCell";

import { JetApiUrls } from "utils/fetchJet";

type Props = {
    gridFilterCellProps: GridFilterCellProps;
    countryId?: number;
    url?: JetApiUrls;
};

export const CountryFilterCell = (props: Props) => (
    <DropdownFilterCell
        {...props.gridFilterCellProps}
        url={props.url}
        comparisonOperator="eq"
    />
);

export const ServiceLineFilterCell = (props: Props) => (
    <DropdownFilterCell
        {...props.gridFilterCellProps}
        url={`/api/Program/ServiceLine?countryId=${props.countryId}`}
        comparisonOperator="eq"
    />
);

// matches any record that contains service line selected in filter dropdown
export const ServiceLinesFilterCell = (props: GridFilterCellProps) => (
    <DropdownFilterCell
        {...props}
        url={"/api/Program/ServiceLine"}
        comparisonOperator="contains"
    />
);

export const CTTreatmentObjectiveFilterCell = (props: GridFilterCellProps) => (
    <DropdownFilterCell
        {...props}
        url={"/api/Program/GetCTTreatmentObjective"}
        comparisonOperator="contains"
    />
);

export const RequestProgramsAcceptedFilterCell = (
    props: GridFilterCellProps,
) => (
    <DropdownFilterCell
        {...props}
        url={"/api/Request/YesOrNoOptions"}
        comparisonOperator="eq"
    />
);

export const TaskStatusFilterCell = (props: GridFilterCellProps) => (
    <DropdownFilterCell
        {...props}
        url={"/api/Task/TaskStatus"}
        comparisonOperator="eq"
    />
);

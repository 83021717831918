import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
    NumericTextBox,
    NumericTextBoxChangeEvent,
    NumericTextBoxProps,
} from "@progress/kendo-react-all";
import styled from "styled-components";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { Formats } from "utils/enumerations";
import { handleStandardNumericTextBoxKeyDown } from "utils/keyDownHandlers";

type Props = {
    id?: string;
    overrideValue?: number | null;
    defaultValue?: number | null;
    onChange?: (val: number | null) => void;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    format?: Formats | string;
    // Used with react hook form to register element
    name?: string;
    // Styling
    alignRight?: boolean;
    spinnersEnabled?: boolean;
    // Other Props
    innerProps?: NumericTextBoxProps;
    inEditFormat?: string;
};

// NOTE: introduced new CustomNumericCell, fixes multiple issues with numeric textboxes,
// this code may need to be removed eventually, leaving for now in case any issues arise
// from new CustomNumericCell implementation
const StandardNumericTextBox: React.FC<Props> = ({
    name = " ",
    spinnersEnabled = false,
    format = Formats.TwoDecimal,
    innerProps,
    inEditFormat,
    ...props
}: Props) => {
    const {
        disabled,
        placeholder,
        overrideValue,
        defaultValue,
        className,
        alignRight,
        id,
        label,
    } = props;
    const { setValue, clearErrors } = useFormContext();
    const [focused, setFocused] = useState(false);
    const editModeFormatInputRef = useRef<any>();
    const [inputEdited, setInputEdited] = useState<boolean>(false);

    let value = useWatch({ name });
    if (overrideValue !== undefined) {
        value = overrideValue;
    }

    const localValue = useRef<number | null>(defaultValue ?? value ?? null);

    const numericFormat = useMemo(() => {
        const regex = /\{0:n(\d+)\}/;

        const match = format?.toString()?.match(regex);

        if (match) {
            return `n${match[1]}`;
        }
        return format.toString();
    }, [format]);

    useEffect(() => {
        localValue.current = value;
    }, [value]);

    /**
     * custom focus function to render a numeric textbox with a different edit format than the view mode
     * format if an edit mode format is specified.
     *
     * This is a required workaround since KendoReact NumericTextBox only allows one format
     * for both view and edit modes.
     */
    const handleFocus = () => {
        setFocused(true);
        // wait for focused state to update and component to re-render children before manually focusing edit mode input
        setTimeout(() => {
            if (
                inEditFormat &&
                !disabled &&
                editModeFormatInputRef &&
                editModeFormatInputRef.current
            ) {
                editModeFormatInputRef.current.focus();
            }
        });
    };

    // Helpers
    const handleBlur = useCallback(() => {
        setFocused(false);
        const valueChanged = localValue?.current !== value;
        setValue(name, localValue.current, { shouldDirty: true });

        if (valueChanged && inputEdited) {
            props.onChange?.(localValue.current);
        }
        setInputEdited(false);
    }, [name, props, setValue, value, inputEdited]);

    const handleChange = useCallback((event: NumericTextBoxChangeEvent) => {
        localValue.current = event.target.value;
    }, []);

    return (
        <NumericTextBoxContainer
            alignRight={alignRight}
            className={className}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleStandardNumericTextBoxKeyDown(
                    e,
                    name,
                    localValue.current,
                    clearErrors,
                    setValue,
                    () => setInputEdited(true),
                )
            }
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            {label && <StandardLabel labelFor={id} label={label} />}
            {!disabled && focused && inEditFormat ? (
                // jsx fragment required to trigger actual re-render of kendo component here, not sure why
                <>
                    <NumericTextBox
                        id={id}
                        placeholder={placeholder}
                        value={value}
                        spinners={spinnersEnabled}
                        onChange={handleChange}
                        format={inEditFormat}
                        {...innerProps}
                        ref={editModeFormatInputRef}
                    />
                </>
            ) : (
                <NumericTextBox
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    spinners={spinnersEnabled}
                    onChange={handleChange}
                    format={numericFormat}
                    disabled={disabled}
                    className={
                        disabled
                            ? "bg-calfrac-gray-50 text-black opacity-100"
                            : ""
                    }
                    {...innerProps}
                />
            )}
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </NumericTextBoxContainer>
    );
};

type StandardNumericTextBoxContainerProps = {
    alignRight?: boolean;
};

export const NumericTextBoxContainer = styled.div<StandardNumericTextBoxContainerProps>`
    text-align: ${(props) => (props.alignRight ? "right" : "inherit")};

    display: flex;
    flex-direction: column;
    width: 100%;

    input {
        text-align: ${(props) => (props.alignRight ? "right" : "inherit")};
    }
`;

export default StandardNumericTextBox;

import { FieldValues, UseFormClearErrors } from "react-hook-form";

/**
 * Handling for key down events when user is typing in an editable cell element
 */
export const handleEditableCellKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    innerElementType: string,
    clearErrors: UseFormClearErrors<FieldValues>,
) => {
    const { key, metaKey, ctrlKey, target } = e;
    const innerElement =
        innerElementType === "textarea"
            ? (target as HTMLTextAreaElement)
            : (target as HTMLInputElement);

    // Prevent default Enter key functionality, Enter is being used for custom grid navigation
    if (innerElementType !== "textarea" && key === "Enter") {
        e.preventDefault();
    }
    // SAVE HOTKEY
    else if (key === "s" && (metaKey || ctrlKey)) {
        e.preventDefault(); // stop browser from trying to save webpage to user files
        clearErrors(); // clear form errors so form submission can be fired again
        innerElement.blur();
    }
};

/**
 * Handling for key down events when user is typing in a
 * StandardNumericTextBox or GridOverridableNumericTextBox cell.
 */
export const handleStandardNumericTextBoxKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    name: string,
    value: number | null,
    clearErrors: UseFormClearErrors<FieldValues>,
    setValue: (
        name: string,
        value: any,
        options?:
            | Partial<{
                  shouldValidate: boolean;
                  shouldDirty: boolean;
                  shouldTouch: boolean;
              }>
            | undefined,
    ) => void,
    onNumericKeyPressed?: () => void,
) => {
    const { key, metaKey, ctrlKey, target } = e;
    const inputElement = target as HTMLInputElement;
    const numberKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    // SAVE HOTKEY
    if (key === "s" && (metaKey || ctrlKey)) {
        e.preventDefault(); // stop browser from trying to save webpage to user files
        setValue(name, value, { shouldDirty: true });

        inputElement.blur();

        // wait for value to update in form before submitting
        setTimeout(() => {
            clearErrors(); // clear form errors so form submission can be fired again
        });
        // We use this case when we want to detect whether a number has been typed in the numeric input box.
        // This is particularly useful when we want to determine if the user entered the same value in the textbox.
        // We need this because the 'onChange' event is never triggered if the user inputs the same value.
        // We are using this for the overridden functionality, allowing a user to override a value with the same value (e.g., x -> x)
    } else if (numberKeys.includes(key) && onNumericKeyPressed) {
        onNumericKeyPressed();
    } else if (key === "Enter") {
        e.preventDefault();
    }
};

import React from "react";
import { Link } from "react-router-dom";
import { GridCellProps } from "@progress/kendo-react-grid";

import TableCell from "./TableCell";

interface LinkCellProps extends GridCellProps {
    // The url to link to
    url: `/${string}`;
    newTab?: boolean;
    // The value of the field to display
    value?: string;
    children?: React.ReactNode;
    disabled?: boolean;
}

/**
 * This function is used to create a hyperlink in a grid column.
 *
 * @param newTab - If true, the link will open in a new tab.
 * @param props
 * @constructor
 */
const TableLink = ({ newTab = true, ...props }: LinkCellProps) => {
    if (props.disabled)
        return (
            <TableCell {...props}>
                {props.value}
                {props.children}
            </TableCell>
        );
    return (
        <TableCell {...props}>
            <Link
                to={props.url}
                className={"text-calfrac-green underline"}
                target={newTab ? "_blank" : "_self"}
                rel={newTab ? "noopener noreferrer" : ""}
            >
                {props.value}
                {props.children}
            </Link>
        </TableCell>
    );
};

export default TableLink;

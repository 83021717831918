import React, { useCallback } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { ProgramWorkflowStatusId } from "types/generated/Calfrac/Jet/Core/Models/ProgramWorkflowStatusId";

import { IEditProgramActionNextTaskViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/IEditProgramActionNextTaskViewModel";
import { IEditProgramActionViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/IEditProgramActionViewModel";

import { H2 } from "components/Headers";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import { HeaderPortal } from "components/Layout/HeaderPortal";
import { SubtitlePortal } from "components/Layout/SubtitlePortal";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardSaveButton from "components/shared/GenericCustomComponents/StandardSaveButton";
import StandardTextArea from "components/shared/GenericCustomComponents/StandardTextArea";
import ActionWarningsTable from "components/Tables/Task/ActionWarningsTable";
import AttachmentTable, {
    TableType,
} from "components/Tables/Task/AttachmentTable";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

import { RedButtonActions } from "./EditOpenTask";
import EditProgramNextTask from "./EditProgramNextTask";

const Page: React.FC = () => {
    // Constants
    const url = "/api/Task/EditProgramTaskAction";
    const model = useWatch() as IEditProgramActionViewModel;

    // Url params
    const { taskId, workflowActionId } = useParams();
    const navigate = useNavigate();
    const { programId } = useProgramParams();

    const buttonType = useCallback(() => {
        const RedButtonAction = RedButtonActions.includes(
            model?.WorkflowActionId,
        );

        if (RedButtonAction) {
            return "Warning";
        }
        return "Primary";
    }, [model]);

    return (
        <>
            <HeaderPortal>
                <StandardSaveButton
                    title={model.ActionName}
                    buttonType={buttonType()}
                    overrideIsPageEditable={true}
                />
            </HeaderPortal>
            <SubtitlePortal>
                <div className={"flex items-center gap-5"}>
                    <H2>{`${resources.Task}${
                        model.TaskName !== undefined
                            ? `: ${model?.TaskName}`
                            : ""
                    }`}</H2>
                </div>
            </SubtitlePortal>
            <StandardForm
                readUrl={url}
                readParams={{
                    taskId,
                    workflowTaskActionId: workflowActionId,
                }}
                saveUrl={url}
                onModelUpdated={() => {
                    navigate(`/Task/EditProgramTasks/${programId}`);
                    window.location.reload();
                }}
                disableNavigationPrompt={true}
            >
                <EditProgramTaskAction />
            </StandardForm>
        </>
    );
};

type Props = {
    readOnly?: boolean;
};

export const EditProgramTaskAction: React.FC<Props> = ({
    readOnly = false,
}) => {
    const model = useWatch();

    const { programId, program } = useProgramParams();

    return (
        <>
            <FormSectionDivider>
                {model?.Warnings?.length > 0 && (
                    <FormSection label={resources.Warnings}>
                        <ActionWarningsTable name={"Warnings"} />
                    </FormSection>
                )}
                {model?.ShowClientOutputs && (
                    <FormSection label={resources.ClientOutputs}>
                        <AttachmentTable name={"ClientOutputs"} />
                    </FormSection>
                )}
                {model?.ShowProgramAttachments && (
                    <FormSection
                        label={resources.OperationNotificationsAttachments}
                    >
                        <AttachmentTable
                            name={"ProgramAttachments"}
                            canAddNewFile={
                                !readOnly &&
                                program?.WorkflowStatusId !==
                                    ProgramWorkflowStatusId.Approved
                            }
                        />
                    </FormSection>
                )}
                {model?.CanOverrideNotificationRecipients && (
                    <FormSection
                        label={resources.NotificationRecipients}
                        flexDir={"column"}
                    >
                        {resources.ExecuteActionWarning}
                        <StandardMultipleSelect
                            name={"EmailTo"}
                            url={
                                "/api/Task/GetPossibleEmailsForActionNotification"
                            }
                            params={{
                                programId,
                            }}
                            hasObjects={true}
                            byField={"Id"}
                            label={resources.EmailTo}
                            disabled={readOnly}
                        />
                        <StandardMultipleSelect
                            name={"EmailCc"}
                            url={
                                "/api/Task/GetPossibleEmailsForActionNotification"
                            }
                            byField={"Id"}
                            hasObjects={true}
                            params={{
                                programId,
                            }}
                            label={resources.EmailCc}
                            disabled={readOnly}
                        />
                    </FormSection>
                )}
                <FormSection label={resources.TaskComments}>
                    <StandardTextArea
                        name={"Comment"}
                        className={"col-span-full"}
                        disabled={readOnly}
                    />
                    <StandardDropDownList
                        name={"OnBehalfOf"}
                        url={"/api/Program/GetUser"}
                        label={resources.OnBehalfOf}
                        disabled={readOnly}
                    />
                </FormSection>

                {/*TODO: This can't be right.*/}
                {model?.ShowProgramAttachments && (
                    <FormSection label={""}>
                        <StandardTextArea
                            name={"RevisionComment"}
                            disabled={true}
                            label={resources.RevisionComment}
                            className="col-span-full"
                        />
                    </FormSection>
                )}

                {model?.ShowCriticalIdentifiers && (
                    <FormSection label={resources.CriticalIdentifier}>
                        <StandardMultipleSelect
                            name={"CriticalIdentifiers"}
                            url={"/api/Program/Flag"}
                            params={{
                                programId,
                                countryId: program?.CountryId.toString() ?? "",
                            }}
                            byField={"Id"}
                            disabled={true}
                            className="col-span-full md:col-span-1 lg:col-span-2 xl:col-span-2 2xl:col-span-2"
                        />
                    </FormSection>
                )}
                {model?.NextTasks?.length > 0 && (
                    <FormSection label={resources.NextTasks} flexDir={"column"}>
                        {model?.NextTasks?.map(
                            (
                                nt: IEditProgramActionNextTaskViewModel,
                                idx: number,
                            ) => (
                                <EditProgramNextTask
                                    name={`NextTasks.${idx}`}
                                />
                            ),
                        )}
                    </FormSection>
                )}
                {(readOnly || model?.ShowTaskAttachments) && (
                    <FormSection label={resources.TaskAttachments}>
                        <AttachmentTable
                            name={"Attachments"}
                            tableType={TableType.Simple}
                            canAddNewFile={!readOnly}
                            canDelete={!readOnly}
                        />
                    </FormSection>
                )}
            </FormSectionDivider>
        </>
    );
};

export default Page;

import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import {
    GridColumn,
    GridDataStateChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { IRequestFilterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Request/IRequestFilterViewModel";

import { PrimaryButtonLink } from "components/Buttons";
import { H3 } from "components/Headers";
import TableLink from "components/kendoExtensions/gridExtensions/TableLink";
import StandardIndexGrid from "components/kendoExtensions/grids/StandardIndexGrid";
import { PageHeaderBar } from "components/Layout";
import { BooleanFilterCell } from "components/shared/CustomGridCells/BooleanFilterCell";
import {
    CountryFilterCell,
    CTTreatmentObjectiveFilterCell,
    RequestProgramsAcceptedFilterCell,
    ServiceLinesFilterCell,
} from "components/shared/Filter/FilterDropdowns";

import {
    COLUMN_INFO_ICON_PROPS,
    COLUMN_PROPS,
    DATE_COLUMN_PROPS,
} from "const/columns";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

import { AddListToQueryData } from "views/Task";
import FilterToolbar from "views/Task/FilterToolbar";

import EditRequestFilter from "./_EditRequestFilter";

const DEFAULT_SORT: SortDescriptor[] = [{ field: "CreatedOn", dir: "desc" }];

const Page = (props: {
    title?: string;
    jobId?: string;
    jobCountry?: number;
}) => {
    const { setValue } = useFormContext();
    const filters = useWatch({
        name: "Request.CurrentFilter",
    }) as IRequestFilterViewModel;

    const defaultFilterUrl: JetApiUrls = "/api/Request/Index";
    // Load the default filter.
    const { data: defaultFilter } = useQuery<IRequestFilterViewModel>({
        queryKey: ["Request", defaultFilterUrl],
        queryFn: async () => {
            return await fetchJET(defaultFilterUrl);
        },
        refetchOnMount: "always",
    });

    useEffect(() => {
        setValue("Request", defaultFilter);
    }, [defaultFilter, setValue]);

    const query = useMemo(() => {
        const newFilters: FilterDescriptor[] = [];

        const queryData: { [key: string]: any } = {};

        // breaking.
        if (filters?.ProjectDescription)
            queryData["ProjectDescription"] = filters.ProjectDescription;

        if (filters?.CountryId) queryData["CountryId"] = filters.CountryId;
        if (filters?.AreAllRequestProgramsAccepted)
            queryData["AreAllRequestProgramsAccepted"] =
                filters.AreAllRequestProgramsAccepted;

        if (props.jobId)
            newFilters.push({
                field: "JobId",
                value: props.jobId,
                operator: "eq",
            });

        AddListToQueryData(queryData, filters, "CustomerCompany");
        AddListToQueryData(queryData, filters, "RequestStatusId");
        AddListToQueryData(queryData, filters, "AccountManager");

        return {
            filters: {
                logic: "and",
                filters: newFilters,
            } as GridDataStateChangeEvent["dataState"]["filter"],
            queryData,
        };
    }, [filters, props.jobId]);

    return (
        <>
            {props.title ? (
                <div className={"w-100 flex items-center justify-between py-2"}>
                    <H3 className={"pb-1"}>{props.title}</H3>
                    <PrimaryButtonLink
                        to={`/Request/EditRequest/0/${props.jobCountry}?jobId=${props.jobId}`}
                    >
                        {resources.CreateRequest}
                    </PrimaryButtonLink>
                </div>
            ) : (
                <PageHeaderBar />
            )}
            <StandardIndexGrid
                path={"/api/Request/GetRequestIndexItems"}
                initialSort={DEFAULT_SORT}
                externalFilters={query.filters}
                queryData={query.queryData}
            >
                <GridToolbar>
                    <FilterToolbar
                        Popover={EditRequestFilter}
                        name={"Request"}
                        savedFilterUrl={"/api/Request/GetSavedFilters"}
                    />
                </GridToolbar>
                {!props.jobId && (
                    <GridColumn
                        field={"Job"}
                        title={resources.JobNumber}
                        locked
                        {...COLUMN_PROPS}
                        cell={(props) => {
                            return (
                                <TableLink
                                    {...props}
                                    url={`/Job/EditJob/${props.dataItem.JobId}`}
                                    value={props.dataItem.Job}
                                />
                            );
                        }}
                        width={100}
                    />
                )}
                <GridColumn
                    field={"RequestNumber"}
                    title={resources.RequestNumber}
                    locked
                    cell={(props) => (
                        <TableLink
                            url={`/Request/EditRequest/${props.dataItem?.Id}/${props.dataItem.CountryId}`}
                            value={props.dataItem.RequestNumber}
                            {...props}
                        />
                    )}
                    {...COLUMN_PROPS}
                    width={100}
                />
                <GridColumn
                    field={"RequestStatus"}
                    title={resources.RequestStatus}
                    {...COLUMN_PROPS}
                    locked
                    width={120}
                />
                <GridColumn
                    field={"AreAllRequestProgramsAccepted"}
                    title={resources.AllAccepted}
                    {...COLUMN_PROPS}
                    width={90}
                    locked
                    filterCell={RequestProgramsAcceptedFilterCell}
                />
                <GridColumn
                    field={"IsRushed"}
                    title={resources.IsRushed}
                    {...COLUMN_PROPS}
                    width={75}
                    filterCell={BooleanFilterCell}
                />
                <GridColumn
                    field={"ProgramDueDate"}
                    title={resources.ProgramDueDate}
                    {...DATE_COLUMN_PROPS}
                    width={140}
                />
                <GridColumn
                    field={"CustomerCompany"}
                    title={
                        props.jobCountry === CountryId.Argentina
                            ? resources.PrimaryOperator
                            : resources.Operator
                    }
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"ProjectDescription"}
                    title={resources.PadName}
                    {...COLUMN_PROPS}
                    width={130}
                />
                <GridColumn
                    field={"Country"}
                    title={resources.Country}
                    {...COLUMN_PROPS}
                    width={70}
                    filterCell={(props) => (
                        <CountryFilterCell
                            gridFilterCellProps={props}
                            url={"/api/Request/CountryCode"}
                        />
                    )}
                />
                <GridColumn
                    field={"RequestServiceLines"}
                    title={resources.ProgramTypes}
                    {...COLUMN_PROPS}
                    width={120}
                    filterCell={ServiceLinesFilterCell}
                />
                <GridColumn
                    field={"RequestTreatmentObjectives"}
                    title={resources.TreatmentObjectives}
                    {...COLUMN_PROPS}
                    filterCell={CTTreatmentObjectiveFilterCell}
                />
                <GridColumn
                    field={"AccountManager"}
                    title={resources.AccountManager}
                    {...COLUMN_INFO_ICON_PROPS}
                />
                <GridColumn
                    field={"RequestUwis"}
                    title={resources.Uwis}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"RequestWellNames"}
                    title={resources.WellNames}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"CustomerContact"}
                    title={resources.OperatorContact}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"CustomerType"}
                    title={resources.CustomerType}
                    {...COLUMN_PROPS}
                    width={120}
                />
                <GridColumn
                    field={"RequestFields"}
                    title={resources.Fields}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"RequestFormations"}
                    title={resources.Formations}
                    {...COLUMN_PROPS}
                />
                <GridColumn
                    field={"EstimatedJobStartDate"}
                    title={resources.EstimatedJobStartDate}
                    {...DATE_COLUMN_PROPS}
                    width={170}
                />
                <GridColumn
                    field={"CreatedOn"}
                    title={resources.CreatedOn}
                    {...DATE_COLUMN_PROPS}
                    width={160}
                />
                <GridColumn
                    field={"CreatedBy"}
                    title={resources.CreatedBy}
                    {...COLUMN_PROPS}
                />
            </StandardIndexGrid>
        </>
    );
};

export default Page;

import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";

import { IEditStageCalculationViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditStageCalculationViewModel";

import { hasEntity } from "utils/helpers";
import { resources } from "utils/resources";

import EntityField from "./EntityField";
import OffsetAndMultiplierFields from "./OffsetAndMultiplierFields";

type Props = {
    scheduleStageId: number;
};

const Fields: React.FC<Props> = ({ scheduleStageId }: Props) => {
    const stageCalculation: IEditStageCalculationViewModel = useWatch({
        name: "Calculation",
    });

    const entityPresent: boolean = useMemo(
        () => hasEntity(stageCalculation?.CalculationTypeId),
        [stageCalculation?.CalculationTypeId],
    );

    // HELPERS

    return (
        <>
            {entityPresent && (
                <>
                    <EntityField scheduleStageId={scheduleStageId} />
                    <OffsetAndMultiplierFields />
                </>
            )}
            {!entityPresent && (
                /* eslint-disable */
        <span className={"text-nowrap w-full py-5"}>
          {resources.NoFieldsRequiredForCalculation}
        </span>
        /* eslint-enable */
            )}
        </>
    );
};

export default Fields;

import React from "react";
import { Outlet, RouteObject } from "react-router-dom";

import { PageHeaderBar } from "components/Layout";

import { resources } from "utils/resources";

import CreateRequest from "views/Request/CreateRequest";
import EditRequest from "views/Request/EditRequest";

const RequestLayout: React.FC = () => {
    return (
        <>
            <PageHeaderBar />
            <div
                className={
                    "relative flex-grow overflow-scroll bg-calfrac-gray-50 px-2  sm:px-6 lg:px-8"
                }
            >
                <Outlet />
            </div>
        </>
    );
};
/**
 * All routes on this page have a program sidebar and have access to the program Context.
 */
const RequestRoutes: RouteObject = {
    // Program Sidebar Pages
    element: <RequestLayout />,
    children: [
        {
            path: "Request/EditRequest/:requestId/:countryId",
            element: <EditRequest />,
            handle: {
                title: resources.Request,
            },
        },
        {
            path: "Request/CreateRequest",
            element: <CreateRequest />,
            handle: {
                title: resources.CreateRequest,
            },
        },
    ],
};

export default RequestRoutes;

//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PricingItemTypeId {
	AdditionalItem = 1,
	AdditionalCartage = 2,
	AdditionalEquipment = 3,
	AdditionalMaterial = 4,
	Cartage = 5,
	Equipment = 6,
	Material = 7,
	CustomAdditionalItem = 8
}

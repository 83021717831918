import React from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";

import InnerTableRow from "components/Tables/BaseTable/Inner/InnerTableRow";

import { BaseTableRowProps } from "./BaseTableRow";

interface BaseTableDraggableRowProps extends BaseTableRowProps {
    index: number;
}

const BaseTableDraggableRow = React.memo(
    ({ row, index, ...props }: BaseTableDraggableRowProps) => {
        return (
            <Draggable
                draggableId={`${props.name}Draggable-${row.original?.Id}`}
                index={index}
            >
                {(provided: DraggableProvided) => (
                    <tr
                        className={`${
                            row.original.Ignore ? "bg-neutral-200" : ""
                        } border-b border-gray-200 bg-white`}
                        {...{
                            "data-row-idx": row.index,
                            "data-row-id": row.id,
                        }}
                        {...provided?.draggableProps}
                        ref={provided?.innerRef}
                    >
                        <InnerTableRow
                            row={row}
                            {...props}
                            draggableProvided={provided}
                        />
                    </tr>
                )}
            </Draggable>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.renderHash === nextProps.renderHash &&
            prevProps.index === nextProps.index
        );
    },
);

export default BaseTableDraggableRow;

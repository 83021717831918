import React from "react";

import { ProgramWorkflowStatusId } from "types/generated/Calfrac/Jet/Core/Models/ProgramWorkflowStatusId";

import { useProgram } from "hooks/useProgramParams";

import { resources } from "utils/resources";

const ProgramWorkflowStatus: React.FC = () => {
    const { program } = useProgram();

    const status = program?.WorkflowStatusId as
        | ProgramWorkflowStatusId
        | undefined;

    const ProgramStatusResources: { [key in ProgramWorkflowStatusId]: string } =
        {
            [ProgramWorkflowStatusId.InDesign]: resources.InDesign,
            [ProgramWorkflowStatusId.QueuedForReview]:
                resources.QueuedForReview,
            [ProgramWorkflowStatusId.InReview]: resources.InReview,
            [ProgramWorkflowStatusId.InApproval]: resources.InApproval,
            [ProgramWorkflowStatusId.Approved]: resources.Approved,
            [ProgramWorkflowStatusId.JobExecuted]: resources.JobExecuted,
            [ProgramWorkflowStatusId.Cancelled]: resources.Cancelled,
            [ProgramWorkflowStatusId.Revised]: resources.Revised,
            [ProgramWorkflowStatusId.SentToOperations]:
                resources.SentToOperations,
        };

    if (!status) return null;
    return (
        <div className="program-status-location">
            <div className="program-status">
                {ProgramStatusResources[status]}
            </div>
        </div>
    );
};

export default ProgramWorkflowStatus;

import React from "react";
import { Dialog } from "@progress/kendo-react-all";

import StandardTextBox from "components/kendoExtensions/standardExtensions/StandardTextBox";
import { BaseProps } from "components/shared/CustomGridToolbar/StandardEditGridToolbar";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

export interface ConfigureGridProps extends BaseProps {
    onClose: () => void;
    updateColumnNames?: () => void;
    misc1Name?: string;
    misc2Name?: string;
}

const ConfigureGrid: React.FC<ConfigureGridProps> = ({
    isVisible = false,
    onClose,
}: ConfigureGridProps) => {
    const { isPageEditable } = useProgramParams();

    if (!isVisible) return <></>;
    return (
        <>
            {isVisible && (
                <Dialog
                    title={resources.ConfigureGrid}
                    onClose={onClose}
                    width={300}
                    themeColor={"primary"}
                >
                    <h3>{resources.MiscColumnNames}</h3>
                    <StandardTextBox
                        label={resources.Misc1Title}
                        name={"Miscellaneous1Title"}
                        disabled={!isPageEditable}
                        alwaysClearErrors={false}
                    />
                    <br />
                    <StandardTextBox
                        label={resources.Misc2Title}
                        name={"Miscellaneous2Title"}
                        disabled={!isPageEditable}
                        alwaysClearErrors={false}
                    />
                    <br />
                    <h3>{resources.VolumeNames}</h3>
                    <StandardTextBox
                        label={resources.Volume1Title}
                        name={"Volume1Title"}
                        disabled={!isPageEditable}
                        alwaysClearErrors={false}
                    />
                    <br />
                    <StandardTextBox
                        label={resources.Volume2Title}
                        name={"Volume2Title"}
                        disabled={!isPageEditable}
                        alwaysClearErrors={false}
                    />
                </Dialog>
            )}
            <StandardAnchorLink
                text={resources.ConfigureGrid.toUpperCase()}
                onClick={onClose}
            />
        </>
    );
};

export default ConfigureGrid;

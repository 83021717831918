import React, { useEffect, useMemo, useState } from "react";
import { toString } from "@progress/kendo-intl";
import { CellContext } from "@tanstack/react-table";

import { TableMetaT } from "types/Tables/Cells";

import { useProgramParams } from "hooks/useProgramParams";

import { findFieldFormat } from "utils/findFieldFormat";

const NumericReadOnlyNullable = ({
    getValue,
    cell,
    table,
    pValue,
    viewFormatOverride,
    className = "",
}: CellContext<any, any> & {
    pValue?: number;
    viewFormatOverride?: string;
    className?: string;
}) => {
    const initialValue = getValue();

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(pValue ?? initialValue);

    const { program } = useProgramParams();
    const inViewFormat = useMemo(() => {
        const format =
            viewFormatOverride ??
            findFieldFormat(
                (table.options.meta as TableMetaT).pageName,
                `${(table.options.meta as TableMetaT).name}.${cell.column.id}`,
                program?.CountryId,
            );
        const regex = /n\d+/;
        const match = format?.match(regex);

        if (match) {
            return match[0];
        }
        return "n5";
    }, [
        cell.column.id,
        program?.CountryId,
        table.options.meta,
        viewFormatOverride,
    ]);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const formattedValue = useMemo(() => {
        // isNaN works on strings too, typescript is wrong so I need to cast as any
        // isNaN('42') => false
        // isNaN('42a') => true
        if (isNaN(value as any)) {
            return value;
        }

        let tempValue = value;

        if (!value) {
            tempValue = "";
        }

        return tempValue
            ? toString(parseFloat(tempValue as string) ?? 0, inViewFormat)
            : undefined;
    }, [inViewFormat, value]);

    return (
        <span
            className={`read-only inline-block w-full px-2 py-1 text-sm text-black shadow-none ${className}`}
        >
            {formattedValue as string}
        </span>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(NumericReadOnlyNullable, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return prevValue === nextValue;
});

import React from "react";
import { Outlet, RouteObject } from "react-router-dom";

import { PageHeaderBar } from "components/Layout";
import SaveProgramButtons from "components/shared/GenericCustomComponents/SaveProgramButtons";

import { ProgramProvider } from "providers/ProgramProvider";

import { resources } from "utils/resources";

import EditActivatorLoading from "views/ActivatorLoading/EditActivatorLoading";
import EditAdditionalChemicals from "views/AdditionalChemical/EditAdditionalChemical";
import EditChemicalLoading from "views/ChemicalLoading/EditChemicalLoading";
import EditChemicalSchedule from "views/ChemicalLoading/EditChemicalSchedule";
import EditClientOutputsCriteria from "views/ClientOutputs/EditClientOutputsCriteria";
import EditDesignResults from "views/DesignResults/EditDesignResults";
import EditFlowConfiguration from "views/FlowConfiguration/EditFlowConfiguration";
import EditFlowConfigurations from "views/FlowConfiguration/EditFlowConfigurations";
import EditFluidsEnergyProppantsCements from "views/FluidsEnergyProppantsCements/EditFluidsEnergyProppantsCements";
import EditPerforations from "views/Perforation/EditPerforations";
import EditPricing from "views/Pricing/EditPricing";
import EditProgramData from "views/Program/EditProgramData";
import ProgramSideBar from "views/Program/ProgramSideBar";
import ProgramWorkflowStatus from "views/Program/ProgramWorkflowStatus";
import EditProgramApproval from "views/ProgramApproval/EditProgramApproval";
import EditProgramAttachments from "views/ProgramAttachment/EditProgramAttachments";
import EditReservoirProperties from "views/ReservoirProperties/EditReservoirProperties";
import EditRiskAssessment from "views/RiskAssessment/EditRiskAssessment";
import EditSchedules from "views/Schedules/EditSchedules";
import EditScheduleStages from "views/Schedules/EditScheduleStages";
import EditProgramTaskAction from "views/Task/EditProgramTaskAction";
import EditProgramTasks from "views/Task/EditProgramTasks";
import ViewHistoricalProgramTask from "views/Task/ViewHistoricalProgramTask";
import EditWellConfig from "views/WellConfig/EditWellConfig";
import EditWellData from "views/WellData/EditWellData";

/**
 * All routes on this page have a program sidebar and have access to the program Context.
 */
const ProgramRoutes: RouteObject = {
    // Program Sidebar Pages
    element: (
        <ProgramProvider>
            <div className={"flex max-h-full flex-grow flex-row"}>
                <ProgramSideBar />
                {/* w-1 required for flex grow to have a valid basis */}
                <div className="-mr-2 flex h-full w-1 max-w-full flex-grow flex-col sm:-mr-6 lg:-mr-8">
                    <Outlet />
                </div>
            </div>
        </ProgramProvider>
    ),

    children: [
        {
            // Pages with no save and cancel buttons
            element: (
                <>
                    <PageHeaderBar className={"px-6"}></PageHeaderBar>
                    <div
                        className={
                            "relative flex-grow overflow-scroll bg-calfrac-gray-50 px-2 pb-4 "
                        }
                    >
                        <Outlet />
                    </div>
                </>
            ),
            children: [
                {
                    path: "Task/EditProgramTaskAction/:programId/:taskId/:workflowActionId",
                    element: <EditProgramTaskAction />,
                },
                {
                    path: "Task/ViewHistoricalProgramTask/:programId/:taskId",
                    element: <ViewHistoricalProgramTask />,
                    handle: {
                        title: resources.Action,
                    },
                },
                {
                    path: "ProgramApproval/EditProgramApproval/:programId",
                    element: <EditProgramApproval />,
                    handle: {
                        title: resources.ProgramApproval,
                    },
                },
                // EditClientOutputs has save and cancel buttons but requires its own implementation of them within the page
                {
                    path: "ClientOutputs/EditClientOutputsCriteria/:programId",
                    element: <EditClientOutputsCriteria />,
                    handle: {
                        title: resources.ClientOutputs,
                    },
                },
            ],
        },
        {
            // Pages with save and cancel buttons
            element: (
                <>
                    <PageHeaderBar className={"px-6"}>
                        <SaveProgramButtons />
                    </PageHeaderBar>
                    <div
                        className={
                            "relative max-w-full flex-grow overflow-x-hidden overflow-y-scroll bg-calfrac-gray-50 px-2 pb-4 "
                        }
                    >
                        <Outlet />
                    </div>
                </>
            ),
            children: [
                {
                    path: "WellData/EditWellData/:programId",
                    element: <EditWellData />,
                    handle: {
                        title: resources.WellData,
                    },
                },
                {
                    path: "ProgramAttachment/EditProgramAttachments/:programId",
                    element: <EditProgramAttachments />,
                    handle: {
                        title: resources.Attachments,
                    },
                },
                {
                    path: "WellConfig/EditWellConfig/:programId",
                    element: <EditWellConfig />,
                    handle: {
                        title: resources.WellConfig,
                    },
                },
                {
                    path: "Schedules/EditStages/:programId/:scheduleId",
                    element: <EditScheduleStages />,
                    handle: {
                        title: resources.Stages,
                    },
                },
                {
                    path: "ChemicalLoading/EditChemicalSchedule/:programId/:scheduleId/:fluidSystemTypeId",
                    element: <EditChemicalSchedule />,
                    handle: {
                        title: resources.ChemicalSchedule,
                    },
                },
                {
                    path: "ChemicalLoading/EditChemicalLoading/:programId",
                    element: <EditChemicalLoading />,
                    handle: {
                        title: resources.ChemicalLoading,
                    },
                },
                {
                    path: "ActivatorLoading/EditActivatorLoading/:programId",
                    element: <EditActivatorLoading />,
                    handle: {
                        title: resources.ActivatorLoading,
                    },
                },
                {
                    path: "AdditionalChemical/EditAdditionalChemical/:programId",
                    element: <EditAdditionalChemicals />,
                    handle: {
                        title: resources.AdditionalChemicals,
                    },
                },
                {
                    path: "FlowConfiguration/EditFlowConfigurations/:programId",
                    element: <EditFlowConfigurations />,
                    handle: {
                        title: resources.FlowConfiguration,
                    },
                },
                {
                    path: "RiskAssessment/EditRiskAssessment/:programId",
                    element: <EditRiskAssessment />,
                    handle: {
                        title: resources.RiskAssessment,
                    },
                },
                {
                    path: "Program/ProgramWorkflowStatus",
                    element: <ProgramWorkflowStatus />,
                    handle: {
                        title: resources.ProgramWorkflowStatus,
                    },
                },
                {
                    path: "DesignResults/EditDesignResults/:programId",
                    element: <EditDesignResults />,
                    handle: {
                        title: resources.DesignResults,
                    },
                },
                {
                    path: "Program/EditProgramData/:programId",
                    element: <EditProgramData />,
                    handle: {
                        title: resources.ProgramData,
                    },
                },
                {
                    path: "ReservoirProperties/EditReservoirProperties/:programId",
                    element: <EditReservoirProperties />,
                    handle: {
                        title: resources.ReservoirProperties,
                    },
                },
                {
                    path: "Pricing/EditPricing/:programId",
                    element: <EditPricing />,
                    handle: {
                        title: resources.Pricing,
                    },
                },
                {
                    path: "Perforation/EditPerforations/:programId",
                    element: <EditPerforations />,
                    handle: {
                        title: resources.Perforations,
                    },
                },
                {
                    path: "FluidsEnergyAndProppants/EditFluidsEnergyAndProppants/:programId",
                    element: <EditFluidsEnergyProppantsCements />,
                    handle: {
                        title: resources.FluidsEnergyAndProppants,
                    },
                },
                {
                    path: "FluidsEnergyAndCements/EditFluidsEnergyAndCements/:programId",
                    element: <EditFluidsEnergyProppantsCements />,
                    handle: {
                        title: resources.FluidsAndCements,
                    },
                },
                {
                    path: "Task/EditProgramTasks/:programId",
                    element: <EditProgramTasks />,
                    handle: {
                        title: resources.Tasks,
                    },
                },
                {
                    path: "FlowConfiguration/EditFlowConfiguration/:programId/:flowConfigurationId",
                    element: <EditFlowConfiguration />,
                    handle: {
                        title: resources.FlowConfiguration,
                    },
                },
                {
                    path: "Schedules/EditSchedules/:programId",
                    element: <EditSchedules />,
                    handle: {
                        title: resources.Schedules,
                    },
                },
            ],
        },
    ],
};

export default ProgramRoutes;

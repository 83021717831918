import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditPerforationClusterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Perforation/IEditPerforationClusterViewModel";

import DeleteButton from "components/Cells/DeleteButton";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useUnits } from "hooks/useUnits";

import { makeSequentialField } from "utils/makeSequentialField";
import { resources } from "utils/resources";

type ClustersTableProps = {
    name: string;
    canCreate: boolean;
    canEdit: boolean;
    canDeleteRecord: boolean;
    computeTotalPerf: (top?: number, bottom?: number, shots?: number) => number;
};

const columnHelper = createColumnHelper<any>();

const ClustersTable: React.FC<ClustersTableProps> = ({
    computeTotalPerf,
    ...props
}) => {
    const data = useWatch({ name: props.name });
    const { setValue, getValues, watch } = useFormContext();
    const tableRef = useRef<HTMLTableElement>(null);
    const isPageEditable = props.canEdit;
    const units = useUnits();

    const defaultColumns = useMemo(
        () => [
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.PerforationClusterNumber, {
                id: "PerforationClusterNumber",
                header: resources.ClusterNumber,
                size: 60,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.Top, {
                id: "Top",
                header: `${resources.Top} ${units.depthUnit}`,
                size: 120,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.Bottom, {
                id: "Bottom",
                header: `${resources.Bottom} ${units.depthUnit}`,
                size: 120,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.Shots, {
                id: "Shots",
                header: `${resources.Shots} ${units.perLengthUnit}`,
                size: 65,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.Diameter, {
                id: "Diameter",
                header: `${resources.Diameter} ${units.lengthSmallUnit}`,
                size: 85,
                cell: FormattedNumericCell,
            }),

            columnHelper.accessor((row) => row.TotalPerforations, {
                id: "TotalPerforations",
                header: resources.Total,
                size: 80,
                cell: NumericReadOnly,
            }),
        ],
        [units.depthUnit, units.perLengthUnit, units.lengthSmallUnit],
    );

    // Use to compute the total perforation for all clusters when pop-up is opened
    useEffect(() => {
        const newData = data?.map(
            (cluster: IEditPerforationClusterViewModel) => {
                return {
                    ...cluster,
                    TotalPerforations: computeTotalPerf(
                        cluster.Top,
                        cluster.Bottom,
                        cluster.Shots,
                    ),
                };
            },
        );
        setValue(props.name, newData ?? []);
    }, [data, computeTotalPerf, setValue, props.name]);

    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    const afterAddRecord = useCallback(
        (newClusters: any[]) => {
            newClusters = makeSequentialField(
                newClusters,
                "PerforationClusterNumber",
            );

            setValue(props.name, newClusters, { shouldDirty: true });
            setTimeout(() => {
                if (!tableRef.current) return;
                // if table ref is defined, scroll to the bottom
                const tBody = tableRef.current?.tBodies?.[0];

                if (!tBody) return;
                // find the last row

                // scroll to the bottom of the table

                const tRow = tBody?.rows[tBody.rows.length - 1];

                if (!tRow) return;

                // scroll into view
                tRow.scrollIntoView({
                    behavior: "smooth",
                });

                // find the first input cell and focus
                tRow.querySelector("textarea")?.select();
            }, 100);
            updateRenderHash();
        },
        [props.name, setValue],
    );

    const hiddenColumns = useMemo(() => {
        return {
            Delete: isPageEditable,
        };
    }, [isPageEditable]);

    return (
        <BaseTable
            tableRef={tableRef}
            afterAddRecord={afterAddRecord}
            defaultRecord={{
                Shots: 0,
                Diameter: undefined,
                Top: 0,
                TotalPerforations: 0,
                PerforationClusterNumber: 0,
            }}
            columns={defaultColumns}
            columnVisibility={hiddenColumns}
            disableSelectColumns={["Delete", "TotalPerforations"]}
            name={props.name}
            renderHash={renderHash}
            meta={{
                name: "ClusterTable",
                pageName: "Clusters",
                canDelete: isPageEditable,
                updateData: (
                    rowIndex: number,
                    columnId: string,
                    value: unknown,
                ) => {
                    if (rowIndex > (data?.length ?? 0) - 1) return;
                    const valueName = `${props.name}.${rowIndex}.${columnId}`;
                    const totalValueName = `${props.name}.${rowIndex}.TotalPerforations`;

                    const lastValue = getValues(valueName);
                    if (lastValue === value) return;

                    // Skip page index reset until after next rerender
                    setValue(valueName, value, { shouldDirty: true });

                    const cluster = data[rowIndex];
                    setValue(
                        totalValueName,
                        computeTotalPerf(
                            cluster.Top,
                            cluster.Bottom,
                            cluster.Shots,
                        ),
                    );
                },
                updateNumericCellData: (
                    rowIndex: number,
                    columnId: string,
                    value: unknown,
                ) => {
                    if (rowIndex > (data?.length ?? 0) - 1) return;
                    const valueName = `${props.name}.${rowIndex}.${columnId}`;
                    const totalValueName = `${props.name}.${rowIndex}.TotalPerforations`;
                    const lastValue = getValues(valueName);
                    if (lastValue === value) return;

                    setValue(valueName, value, { shouldDirty: true });

                    if (
                        columnId === "Top" ||
                        columnId === "Bottom" ||
                        columnId === "Shots"
                    ) {
                        // We require to watch because getValues or data[rowIdx] don't have the latest data
                        const top = watch(`${props.name}.${rowIndex}.Top`);
                        const bottom = watch(
                            `${props.name}.${rowIndex}.Bottom`,
                        );
                        const shots = watch(`${props.name}.${rowIndex}.Shots`);
                        const totalPerforation = computeTotalPerf(
                            top,
                            bottom,
                            shots,
                        );
                        setValue(totalValueName, totalPerforation);
                        updateRenderHash();
                    }
                },
            }}
        />
    );
};

export default ClustersTable;

import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditProgramActionWarningViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/IEditProgramActionWarningViewModel";

import CheckBox from "components/Cells/CheckBox";
import TextReadOnly from "components/Cells/TextReadOnly";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram } from "hooks/useProgramParams";

type ActionWarningsTableProps = {
    name: string;
};

const columnHelper = createColumnHelper<IEditProgramActionWarningViewModel>();

const ActionWarningsTable: React.FC<ActionWarningsTableProps> = (props) => {
    const { setValue, getValues } = useFormContext();

    const { isPageEditable } = useProgram();

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.accessor((row) => row.Ignore, {
                id: "Ignore",
                size: 10,
                cell: (props) => {
                    if (!props.row.original.CanBeIgnored) return <div></div>;
                    return <CheckBox {...props} />;
                },
            }),
            columnHelper.accessor((row) => row.Message, {
                id: "Message",
                size: 1000,
                cell: TextReadOnly,
            }),
        ];
    }, []);

    return (
        <BaseTable
            name={props.name}
            columns={defaultColumns}
            meta={{
                disabled: !isPageEditable,
                name: props.name,
                updateData: (
                    rowIndex: number,
                    columnId: string,
                    value: unknown,
                ) => {
                    const valueName = `${props.name}.${rowIndex}.${columnId}`;
                    const lastValue = getValues(valueName);
                    if (lastValue === value) return;
                    setValue(valueName, value, { shouldDirty: true });
                },
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default ActionWarningsTable;

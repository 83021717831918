import React from "react";
import { Table } from "@tanstack/react-table";

import { resources } from "utils/resources";

const BaseTableEmptyBody = ({
    table,
    noRowsMessage,
}: {
    table: Table<any>;
    noRowsMessage?: string;
}) => {
    return (
        <tbody className={"px-4"}>
            <tr>
                <td
                    className={"py-4 text-center text-gray-500 "}
                    colSpan={table.getVisibleLeafColumns().length}
                >
                    {noRowsMessage ?? resources.NoRecords}
                </td>
            </tr>
        </tbody>
    );
};

export default BaseTableEmptyBody;

import React, { useEffect, useMemo, useReducer, useRef } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditFlowConfigurationItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/FlowConfiguration/IEditFlowConfigurationItemViewModel";

import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type FlowConfigurationTableProps = {
    name: string;
    renderHash?: string;
};

const columnHelper = createColumnHelper<IEditFlowConfigurationItemViewModel>();

const FlowConfigurationTable: React.FC<FlowConfigurationTableProps> = (
    props,
) => {
    const { isPageEditable } = useProgram();

    const tableRef = useRef<HTMLTableElement>(null);

    const units = useUnits();
    const { programId } = useProgramParams();

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),

            columnHelper.accessor((row) => row.FlowType, {
                id: "FlowType",
                size: 200,
                maxSize: 200,
                header: resources.PumpDown,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.InnerTubular, {
                id: "InnerTubular",
                size: 200,
                maxSize: 200,
                header: resources.InnerTubular,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.OuterTubular, {
                id: "OuterTubular",
                size: 200,
                maxSize: 200,
                header: resources.OuterTubular,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.FrictionGradient, {
                id: "FrictionGradient",
                size: 200,
                maxSize: 200,
                header: `${resources.FrictionGradient} ${units.frictionGradientUnit}`,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.StartDepth, {
                id: "StartDepth",
                size: 200,
                maxSize: 200,
                header: `${resources.StartDepth} ${units.depthUnit}`,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.EndDepth, {
                id: "EndDepth",
                size: 200,
                maxSize: 200,
                header: `${resources.EndDepth} ${units.depthUnit}`,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.Capacity, {
                id: "Capacity",
                size: 200,
                maxSize: 200,
                header: `${resources.Capacity} ${units.capacityUnit}`,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.Volume, {
                id: "Volume",
                size: 200,
                maxSize: 200,
                header: `${resources.Volume} ${units.volumeWetLargeUnit}`,
                cell: NumericReadOnly,
            }),
        ];
    }, [
        units.capacityUnit,
        units.depthUnit,
        units.frictionGradientUnit,
        units.volumeWetLargeUnit,
    ]);

    return (
        <BaseTable
            tableRef={tableRef}
            name={props.name}
            defaultRecord={{}}
            columns={defaultColumns}
            renderHash={renderHash}
            meta={{
                canDelete: isPageEditable,
                urls: {
                    FlowType: `/api/FlowConfiguration/FlowType?programId=${programId}`,
                    InnerTubular: `/api/FlowConfiguration/Tubular?programId=${programId}`,
                    OuterTubular: `/api/FlowConfiguration/Tubular?programId=${programId}`,
                },
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default FlowConfigurationTable;

import React from "react";
import {
    CellContext,
    ColumnDef,
    createColumnHelper,
} from "@tanstack/react-table";

import { ConcentrationCalculationModeId } from "types/generated/Calfrac/Jet/Core/Models/ConcentrationCalculationModeId";
import { ServiceLineId } from "types/generated/Calfrac/Jet/Core/Models/ServiceLineId";

import { CellType } from "types/Tables/Cells";

import CalculationNumericCell from "components/Cells/CalculationNumericCell";
import ComboBoxGrid from "components/Cells/ComboBoxGrid";
import CopyRowButton from "components/Cells/CopyRowButton";
import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import IntegerCell from "components/Cells/IntegerCell";
import NumericCellNullable from "components/Cells/NumericCellNullable";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import NumericReadOnlyCement from "components/Cells/NumericReadOnlyCement";
import NumericReadOnlyNullable from "components/Cells/NumericReadOnlyNullable";
import NumericReadOnlyProppant from "components/Cells/NumericReadOnlyProppant";
import TextCell from "components/Cells/TextCell";
import TimeMaskedCell from "components/Cells/TimeMaskedCell";

import { resources } from "utils/resources";

const columnHelper = createColumnHelper<any>();

export enum TableType {
    PumpScheduleStages,
    TreatmentScheduleStages,
}

export type ColumnInfo = {
    columns: ColumnDef<any, unknown>[];
    order: string[];
};

export const sharedTableColumns = (): ColumnInfo => {
    return {
        order: [],
        columns: [
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.display({
                id: "CopyAction",
                size: 40,
                maxSize: 40,
                cell: CopyRowButton,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.StageNumber, {
                id: "StageNumber",
                header: resources.StageNumber,
                size: 60,
                maxSize: 60,
                cell: IntegerCell,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.StageDescription, {
                id: "StageDescription",
                header: resources.StageDescription,
                size: 150,
                minSize: 150,
                cell: DropDown,
                enablePinning: true,
            }),
        ],
    };
};

export const pumpScheduleTableColumns = (
    model: any,
    units: { [key: string]: string },
): ColumnInfo => {
    const isDownhole =
        model?.ConcentrationCalcMode ===
        ConcentrationCalculationModeId.Downhole;
    const isCement =
        model?.ProgramContext?.ServiceLineId === ServiceLineId.Cement;

    return {
        order: isDownhole
            ? [
                  "ProgramFluidSystem",
                  "ProgramProppant",
                  "Cement",
                  "Description",
                  "SlurryRate",
                  "CleanRateStart",
                  "CleanVolume",
                  "CleanVolumeCumulative",
                  "Density",
                  "DownholeConcentrationStart",
                  "DownholeConcentrationEnd",
                  "BlenderConcentrationStart",
                  "BlenderConcentrationEnd",
              ]
            : [],
        columns: [
            columnHelper.accessor((row) => row.ProgramFluidSystem, {
                id: "ProgramFluidSystem",
                header: resources.FluidSystem,
                size: 250,
                minSize: 250,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.ProgramProppant, {
                id: "ProgramProppant",
                header: resources.Proppant,
                size: 150,
                minSize: 150,
                cell: DropDown,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ProgramCement, {
                id: "ProgramCement",
                header: resources.Cement,
                size: 150,
                minSize: 150,
                cell: DropDown,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Description, {
                id: "Description",
                header: resources.Description,
                size: 210,
                minSize: 210,
                cell: TextCell,
            }),
            columnHelper.group({
                header: isCement ? "" : resources.Rate.toUpperCase(),
                id: "Rate",
                columns: [
                    columnHelper.accessor((row) => row.SlurryRate, {
                        id: "SlurryRate",
                        header: `${isCement ? resources.Rate : resources.SlurryRate} ${
                            units.rateWetFastUnit
                        }`,
                        size: 70,
                        minSize: 70,
                        cell: NullNumericWhenShutdown,
                    }),
                    columnHelper.accessor((row) => row.CleanRateStart, {
                        id: "CleanRateStart",
                        header: `${resources.CleanRate} ${units.rateWetFastUnit}`,
                        size: 70,
                        minSize: 70,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                ],
            }),
            columnHelper.group({
                header: resources.Volume.toUpperCase(),
                id: "Volume",
                columns: [
                    columnHelper.accessor((row) => row.CleanVolume, {
                        id: "CleanVolume",
                        header: `${isCement ? resources.Volume : resources.CleanVolume} ${
                            units.volumeWetMediumUnit
                        }`,
                        size: 100,
                        minSize: 100,
                        cell: NullNumericWhenShutdown,
                    }),
                    columnHelper.accessor((row) => row.CleanVolumeCumulative, {
                        id: "CleanVolumeCumulative",
                        header: `${
                            isCement
                                ? resources.VolumeCumulative
                                : resources.CleanVolumeCumulative
                        } ${units.volumeWetMediumUnit}`,
                        size: 100,
                        minSize: 100,
                        cell: NullReadOnlyNumericWhenShutdown,
                    }),
                ],
            }),
            columnHelper.accessor((row) => row.Density, {
                id: "Density",
                header: `${resources.Density} ${units.densityUnit}`,
                size: 100,
                minSize: 100,
                cell: NullNumericWhenShutdown,
            }),
            columnHelper.accessor((row) => row.CementStage, {
                id: "CementStage",
                header: `${resources.CementStage} ${units.massCementMedium}`,
                size: 100,
                minSize: 100,
                cell: NullNumericWhenShutdown,
            }),
            columnHelper.accessor((row) => row.CementStageCumulative, {
                id: "CementStageCumulative",
                header: `${resources.CementStageCumulative} ${units.massCementMedium}`,
                size: 100,
                minSize: 100,
                cell: NumericReadOnlyCement,
            }),
            columnHelper.group({
                header: resources.Proppant.toUpperCase(),
                id: "Proppant",
                columns: [
                    columnHelper.accessor(
                        (row) => row.BlenderConcentrationStart,
                        {
                            id: "BlenderConcentrationStart",
                            header: `${resources.BlenderConcentrationStart} ${units.concentrationProppantUnit}`,
                            size: 90,
                            minSize: 90,
                            cell:
                                model?.ConcentrationCalcMode ===
                                ConcentrationCalculationModeId.Surface
                                    ? NullNumericWhenShutdown
                                    : NullReadOnlyNumericWhenShutdown,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.BlenderConcentrationEnd,
                        {
                            id: "BlenderConcentrationEnd",
                            header: `${resources.BlenderConcentrationEnd} ${units.concentrationProppantUnit}`,
                            size: 90,
                            minSize: 90,
                            cell:
                                model?.ConcentrationCalcMode ===
                                ConcentrationCalculationModeId.Surface
                                    ? NullNumericWhenShutdown
                                    : NullReadOnlyNumericWhenShutdown,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.DownholeConcentrationStart,
                        {
                            id: "DownholeConcentrationStart",
                            header: `${resources.DownholeConcentrationStart} ${units.concentrationProppantUnit}`,
                            size: 90,
                            minSize: 90,
                            cell:
                                model?.ConcentrationCalcMode ===
                                ConcentrationCalculationModeId.Surface
                                    ? NullReadOnlyNumericWhenShutdown
                                    : NullNumericWhenShutdown,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.DownholeConcentrationEnd,
                        {
                            id: "DownholeConcentrationEnd",
                            header: `${resources.DownholeConcentrationEnd} ${units.concentrationProppantUnit}`,
                            size: 90,
                            minSize: 90,
                            cell:
                                model?.ConcentrationCalcMode ===
                                ConcentrationCalculationModeId.Surface
                                    ? NullReadOnlyNumericWhenShutdown
                                    : NullNumericWhenShutdown,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor((row) => row.ProppantStage, {
                        id: "ProppantStage",
                        header: `${resources.ProppantStage} ${units.massSmallUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor(
                        (row) => row.ProppantStageCumulative,
                        {
                            id: "ProppantStageCumulative",
                            header: `${resources.ProppantCumulative} ${units.massSmallUnit}`,
                            size: 90,
                            minSize: 90,
                            cell: NumericReadOnlyProppant,
                            enableHiding: true,
                        },
                    ),
                ],
            }),
            columnHelper.group({
                header: resources.Nitrogen.toUpperCase(),
                id: "Nitrogen",
                enableHiding: true,
                columns: [
                    columnHelper.accessor((row) => row.N2Rate, {
                        id: "N2Rate",
                        header: `${resources.N2Rate} ${units.gasRateUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.DownholeN2Rate, {
                        id: "DownholeN2Rate",
                        header: `${resources.DownholeN2Rate} ${units.rateWetFastUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.N2Ratio, {
                        id: "N2Ratio",
                        header: `${resources.N2Ratio} ${units.gasRatioUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.N2Stage, {
                        id: "N2Stage",
                        header: `${resources.N2Stage} ${units.amountGasUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.N2StageCumulative, {
                        id: "N2StageCumulative",
                        header: `${resources.N2Cumulative} ${units.amountGasUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                ],
            }),
            columnHelper.group({
                header: resources.CarbonDioxide.toUpperCase(),
                id: "CarbonDioxide",
                enableHiding: true,
                size: 300,
                minSize: 300,
                columns: [
                    columnHelper.accessor((row) => row.CO2Rate, {
                        id: "CO2Rate",
                        header: `${resources.CO2Rate} ${units.rateWetFastUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.CO2Ratio, {
                        id: "CO2Ratio",
                        header: `${resources.CO2Ratio} ${units.gasRatioUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.CO2Stage, {
                        id: "CO2Stage",
                        header: `${resources.CO2Stage} ${units.gasRatioUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.CO2StageCumulative, {
                        id: "CO2StageCumulative",
                        header: `${resources.CO2Cumulative} ${units.volumeWetLargeUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                ],
            }),
            columnHelper.group({
                header: resources.SecondaryFluidSystem.toUpperCase(),
                id: "SecondaryFluidSystem",
                enableHiding: true,
                size: 300,
                minSize: 300,
                columns: [
                    columnHelper.accessor(
                        (row) => row.SecondaryProgramFluidSystem,
                        {
                            id: "SecondaryProgramFluidSystem",
                            header: resources.FluidSystem,
                            size: 200,
                            minSize: 200,
                            cell: DropDown,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.SecondaryFluidSystemRate,
                        {
                            id: "SecondaryFluidSystemRate",
                            header: `${resources.SecondaryFluidSystemRate} ${units.rateWetFastUnit}`,
                            size: 90,
                            minSize: 90,
                            cell: NullNumericWhenShutdown,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.SecondaryFluidSystemRatio,
                        {
                            id: "SecondaryFluidSystemRatio",
                            header: `${resources.SecondaryFluidSystemRatio} ${units.ratioWetUnit}`,
                            size: 90,
                            minSize: 90,
                            cell: NullReadOnlyNumericWhenShutdown,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.SecondaryFluidSystemStage,
                        {
                            id: "SecondaryFluidSystemStage",
                            header: `${resources.SecondaryFluidSystemStage} ${units.secondaryFluidSystemVolume}`,
                            size: 90,
                            minSize: 90,
                            cell: NullNumericWhenShutdown,
                            enableHiding: true,
                        },
                    ),
                    columnHelper.accessor(
                        (row) => row.SecondaryFluidSystemStageCumulative,
                        {
                            id: "SecondaryFluidSystemStageCumulative",
                            header: `${resources.SecondaryFluidSystemStageCumulative} ${units.secondaryFluidSystemVolume}`,
                            size: 90,
                            minSize: 90,
                            cell: NullReadOnlyNumericWhenShutdown,
                            enableHiding: true,
                        },
                    ),
                ],
            }),
            columnHelper.group({
                header: resources.DownholeCondition.toUpperCase(),
                id: "DownholeCondition",
                enableHiding: true,
                size: 300,
                minSize: 300,
                columns: [
                    columnHelper.accessor((row) => row.DownholeRate, {
                        id: "DownholeRate",
                        header: `${resources.Rate} ${units.rateWetFastUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.DownholeVolume, {
                        id: "DownholeVolume",
                        header: `${resources.Volume} ${units.volumeWetMediumUnit}`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                    columnHelper.accessor((row) => row.DownholeFoamQuality, {
                        id: "DownholeFoamQuality",
                        header: `${resources.FoamQuality} (%)`,
                        size: 90,
                        minSize: 90,
                        cell: NullReadOnlyNumericWhenShutdown,
                        enableHiding: true,
                    }),
                ],
            }),
            columnHelper.group({
                header: resources.Time.toUpperCase(),
                size: 300,
                minSize: 300,
                columns: [
                    columnHelper.accessor((row) => row.StageTime, {
                        id: "StageTime",
                        header: resources.StageTimeWithUnitSymbol,
                        size: 90,
                        minSize: 90,
                        cell: (props) => (
                            <TimeMaskedCell
                                allowRerender={!props.row.original.IsShutdown}
                                {...props}
                            />
                        ),
                    }),
                    columnHelper.accessor((row) => row.StageTimeCumulative, {
                        id: "StageTimeCumulative",
                        header: resources.TimeCumulativeWithUnitSymbol,
                        size: 90,
                        minSize: 90,
                        cell: (props) => (
                            <TimeMaskedCell readOnly={true} {...props} />
                        ),
                    }),
                ],
            }),
        ],
    };
};

export const treatmentScheduleTableColumns = (units: {
    [key: string]: string;
}): ColumnInfo => {
    return {
        order: [],
        columns: [
            columnHelper.accessor((row) => row.Description, {
                id: "Description",
                header: resources.Description,
                size: 210,
                minSize: 210,
                cell: ComboBoxGrid,
            }),
            columnHelper.accessor((row) => row.StartDepth, {
                id: "StartDepth",
                header: `${resources.StartDepth} ${units.depthUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
            }),
            columnHelper.accessor((row) => row.EndDepth, {
                id: "EndDepth",
                header: `${resources.EndDepth} ${units.depthUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
            }),
            columnHelper.accessor((row) => row.Speed, {
                id: "Speed",
                header: `${resources.Speed} ${units.speedUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
            }),
            columnHelper.accessor((row) => row.Time, {
                id: "Time",
                header: `${resources.Time} ${units.time}`,
                size: 90,
                minSize: 90,
                cell: CalculationNumericCell,
            }),
            columnHelper.accessor((row) => row.ProgramFluidSystem, {
                id: "ProgramFluidSystem",
                header: resources.FluidSystem,
                size: 250,
                minSize: 250,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.FluidRate, {
                id: "FluidRate",
                header: `${resources.FluidRate} ${units.rateWetFastUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
            }),
            columnHelper.accessor((row) => row.FluidTotal, {
                id: "FluidTotal",
                header: `${resources.FluidTotal} ${units.volumeWetMediumUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
            }),
            columnHelper.accessor((row) => row.N2Rate, {
                id: "N2Rate",
                header: `${resources.N2Rate} ${units.gasRateUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
            }),
            columnHelper.accessor((row) => row.N2Total, {
                id: "N2Total",
                header: `${resources.N2Total} ${units.amountGasUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
            }),
            columnHelper.accessor((row) => row.CombinedDisplacementRate, {
                id: "CombinedDisplacementRate",
                header: `${resources.CombinedDisplacementRate} ${units.rateWetFastUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.CombinedDisplacementTotal, {
                id: "CombinedDisplacementTotal",
                header: `${resources.CombinedDisplacementTotal} ${units.volumeWetMediumUnit}`,
                size: 150,
                minSize: 150,
                cell: CalculationNumericCell,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.DownholeRate, {
                id: "DownholeRate",
                header: `${resources.DownholeRate} ${units.rateWetFastUnit}`,
                size: 150,
                minSize: 150,
                cell: NullReadOnlyNumericWhenShutdown,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.DownholeTotal, {
                id: "DownholeTotal",
                header: `${resources.DownholeTotal} ${units.volumeWetMediumUnit}`,
                size: 150,
                minSize: 150,
                cell: NullReadOnlyNumericWhenShutdown,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.NetMeter, {
                id: "NetMeter",
                header: `${resources.NetDepth} ${units.depthUnit}`,
                size: 150,
                minSize: 150,
                cell: NumericReadOnly,
            }),
        ],
    };
};

const NullNumericWhenShutdown: CellType = (props: CellContext<any, any>) => {
    return props.row.original.IsShutdown ? (
        <NumericCellNullable {...props} />
    ) : (
        <FormattedNumericCell {...props} />
    );
};

const NullReadOnlyNumericWhenShutdown: CellType = (
    props: CellContext<any, any>,
) => {
    return props.row.original.IsShutdown ? (
        <NumericReadOnlyNullable {...props} />
    ) : (
        <NumericReadOnly {...props} />
    );
};

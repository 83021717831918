import React, { useCallback, useEffect, useRef, useState } from "react";
import { DropDownList } from "@progress/kendo-react-all";
import { useQuery } from "@tanstack/react-query";

import { IPricingItemDescriptionViewModel } from "types/generated/Calfrac/Jet/Web/Models/Pricing/IPricingItemDescriptionViewModel";

import {
    CellType,
    PricingMetaT,
    RenderHashMetaT,
    TableMetaT,
} from "types/Tables/Cells";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { getTextAreaRows } from "utils/helpers";

const DescriptionDropDown: CellType = ({
    getValue,
    row: { index },
    column: { id, columnDef },
    table,
}) => {
    const initialValue = getValue<IPricingItemDescriptionViewModel>();
    const [value, setValue] =
        useState<IPricingItemDescriptionViewModel>(initialValue);
    const [filter, setFilter] = useState("");
    const [inEdit, setInEdit] = useState(false);
    const [textAreaRows, setTextAreaRows] = useState(1);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const localInputRef = useRef<DropDownList>(null);

    const tableMeta = table.options.meta as RenderHashMetaT;

    const onFocus = useCallback(() => {
        if (tableMeta?.disabled) return;

        setInEdit(true);
        tableMeta.select(index, id);
    }, [index, id, setInEdit, tableMeta]);

    const url = (table.options.meta as TableMetaT)?.urls?.Description ?? "";

    const isAdditionalPriceItem = (
        table.options.meta as PricingMetaT
    )?.isAdditionalPriceItem();
    // We need to keep and update the state of the cell normally

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = useCallback(() => {
        (table.options.meta as TableMetaT)?.updateData(index, id, value);
        setInEdit(false);
    }, [table.options.meta, index, id, value, setInEdit]);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (inEdit) localInputRef.current?.element?.focus();
        setTextAreaRows(getTextAreaRows(textAreaRef.current));
    }, [inEdit]);

    // Queries
    const { data, isLoading } = useQuery<
        IPricingItemDescriptionViewModel[] | undefined
    >({
        queryKey: [url],
        queryFn: async () => {
            return await fetchJET(url as JetApiUrls);
        },
        enabled: !!url,
    });

    const displayValue =
        typeof value === "string"
            ? value
            : ((isAdditionalPriceItem
                  ? value?.DescriptionWithUnit
                  : value?.Description) ?? "");

    const disabled = (table.options.meta as TableMetaT)?.disabled;

    let filteredData = filter
        ? data?.filter((x) =>
              (isAdditionalPriceItem ? x.DescriptionWithUnit : x.Description)
                  ?.toLowerCase()
                  .includes(filter),
          )
        : data;

    if (disabled || !isAdditionalPriceItem) {
        return <div className={"read-only"}>{displayValue}</div>;
    }

    return (
        <div
            style={{
                width: "100%",
                maxWidth: "100%",
            }}
            className={"[&>span>span]:whitespace-normal"}
        >
            {inEdit ? (
                <DropDownList
                    ref={localInputRef}
                    disabled={(table.options.meta as TableMetaT)?.disabled}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={onBlur}
                    data={filteredData}
                    filterable={true}
                    onFilterChange={(e) =>
                        setFilter(
                            e.filter.value?.toString()?.toLowerCase() ?? "",
                        )
                    }
                    textField={
                        isAdditionalPriceItem
                            ? "DescriptionWithUnit"
                            : "Description"
                    }
                    dataItemKey={"FinanceItemNumber"}
                    loading={isLoading}
                    defaultItem={{}}
                    style={{ width: columnDef.size }}
                    className={"bg-white"}
                />
            ) : (
                <textarea
                    rows={textAreaRows}
                    ref={textAreaRef}
                    onDragStart={(e) => e.preventDefault()}
                    wrap={"soft"}
                    name={id}
                    value={
                        isAdditionalPriceItem
                            ? value?.DescriptionWithUnit || ""
                            : value?.Description || ""
                    }
                    onFocus={onFocus}
                    className={
                        "w-100 resize-none overflow-hidden border-0 bg-transparent p-1"
                    }
                />
            )}
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(DescriptionDropDown, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return prevValue === nextValue;
});

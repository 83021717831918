import React from "react";
import { toast } from "react-toastify";
import {
    Upload,
    UploadOnAddEvent,
    UploadProps,
} from "@progress/kendo-react-all";

import { JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

type Props = {
    saverUrl?: JetApiUrls;
    allowMultiple?: boolean;
    showFileList?: boolean;
    allowedExtensions?: string[];
} & UploadProps;

const MAX_FILE_SIZE_BYTES = 50 * 1000 * 1000; // 50 MB

const StandardFileUpload: React.FC<Props> = ({
    saverUrl,
    allowMultiple,
    showFileList,
    allowedExtensions,
    autoUpload = true,
    restrictions,
    onAdd,
    ...props
}) => {
    return (
        <Upload
            batch={allowMultiple}
            multiple={allowMultiple}
            defaultFiles={[]}
            withCredentials={false}
            saveUrl={saverUrl}
            autoUpload={autoUpload}
            showFileList={showFileList}
            accept={allowedExtensions?.map((x) => `.${x}`).join(",")}
            restrictions={{
                ...restrictions,
                maxFileSize: MAX_FILE_SIZE_BYTES, // 25 MB
            }}
            onAdd={(props) => {
                if (!onAdd) return;
                // if any of the files are greater than 25 MB, don't upload
                const isInvalid = props.newState.some(
                    (x) => (x?.size ?? 0) > MAX_FILE_SIZE_BYTES,
                );
                if (isInvalid) {
                    toast.error(resources.MaxFileSize);
                    const invalid: UploadOnAddEvent = {
                        newState: [],
                        affectedFiles: [],
                        target: props.target,
                    };
                    onAdd(invalid);
                    return;
                }
                onAdd(props);
            }}
            {...props}
        />
    );
};

export default StandardFileUpload;

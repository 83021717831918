//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CalculationTypeId {
	StartDepthPreviousStartDepth = 1,
	StartDepthPreviousEndDepth = 2,
	StartDepthSameEndDepth = 3,
	StartDepthTimeEndDepthAndSpeed = 4,
	StartDepthPerforationTop = 5,
	StartDepthPerforationBottom = 6,
	StartDepthPerforationPlugDepth = 7,
	StartDepthPerforationSeat = 8,
	StartDepthWellConfigPbtd = 9,
	StartDepthWellConfigKop = 10,
	StartDepthReservoirTvd = 11,
	StartDepthWellConfigHeel = 12,
	StartDepthWellConfigCasingTop = 13,
	StartDepthWellConfigLinerTop = 14,
	StartDepthWellConfigTubingTop = 15,
	StartDepthWellConfigOpenHoleTop = 16,
	StartDepthWellConfigCasingBottom = 17,
	StartDepthWellConfigLinerBottom = 18,
	StartDepthWellConfigTubingBottom = 19,
	StartDepthWellConfigOpenHoleBottom = 20,
	StartDepthWellConfigDownholeItem = 21,
	StartDepthPrevScheduleStartDepth = 22,
	StartDepthPrevScheduleEndDepth = 23,
	EndDepthPreviousStartDepth = 24,
	EndDepthPreviousEndDepth = 25,
	EndDepthSameStartDepth = 26,
	EndDepthTimeStartDepthAndSpeed = 27,
	EndDepthPerforationTop = 28,
	EndDepthPerforationBottom = 29,
	EndDepthPerforationPlugDepth = 30,
	EndDepthPerforationSeat = 31,
	EndDepthWellConfigPbtd = 32,
	EndDepthWellConfigKop = 33,
	EndDepthReservoirTvd = 34,
	EndDepthWellConfigHeel = 35,
	EndDepthWellConfigCasingTop = 36,
	EndDepthWellConfigLinerTop = 37,
	EndDepthWellConfigTubingTop = 38,
	EndDepthWellConfigOpenHoleTop = 39,
	EndDepthWellConfigCasingBottom = 40,
	EndDepthWellConfigLinerBottom = 41,
	EndDepthWellConfigTubingBottom = 42,
	EndDepthWellConfigOpenHoleBottom = 43,
	EndDepthWellConfigDownholeItem = 44,
	EndDepthPrevScheduleStartDepth = 45,
	EndDepthPrevScheduleEndDepth = 46,
	SpeedStartDepthEndDepthAndTime = 47,
	TimeStartDepthEndDepthAndSpeed = 48,
	TimeFluidTotalAndRate = 49,
	TimeN2TotalAndRate = 50,
	TimeCombinedDisplacementTotalAndRate = 51,
	FluidRateTotalAndTime = 53,
	N2RateTotalAndTime = 54,
	CombinedDisplacementRateTotalAndTime = 55,
	DownholeRateTotalAndTime = 56,
	FluidTotalRateAndTime = 57,
	FluidTotalPerforationsVolumeOne = 58,
	FluidTotalPerforationsVolumeTwo = 59,
	FluidTotalPerforationsDispVolume = 60,
	FluidTotalWellConfigCTVolumeMinWallThickness = 61,
	FluidTotalFlowConfigTotalVolume = 62,
	FluidTotalFlowConfigVolume = 63,
	FluidTotalPreviousScheduleVolume = 64,
	FluidTotalPreviousSchedulesVolumeSum = 65,
	N2TotalRateAndTime = 66,
	N2TotalPerforationsVolumeOne = 67,
	N2TotalPerforationsVolumeTwo = 68,
	N2TotalPerforationsDispVolume = 69,
	N2TotalWellConfigCTVolumeMinWallThickness = 70,
	N2TotalFlowConfigTotalVolume = 71,
	N2TotalFlowConfigVolume = 72,
	N2TotalPreviousScheduleVolume = 73,
	N2TotalPreviousSchedulesVolumeSum = 74,
	CombinedDisplacementTotalRateAndTime = 75,
	CombinedDisplacementTotalPerforationsVolumeOne = 76,
	CombinedDisplacementTotalPerforationsVolumeTwo = 77,
	CombinedDisplacementTotalPerforationsDispVolume = 78,
	CombinedDisplacementTotalWellConfigCTVolumeMinWallThickness = 79,
	CombinedDisplacementTotalFlowConfigTotalVolume = 80,
	CombinedDisplacementTotalFlowConfigVolume = 81,
	CombinedDisplacementTotalPreviousScheduleVolume = 82,
	CombinedDisplacementTotalPreviousSchedulesVolumeSum = 83,
	DownholeTotalRateAndTime = 84,
	DownholeTotalPerforationsVolumeOne = 85,
	DownholeTotalPerforationsVolumeTwo = 86,
	DownholeTotalPerforationsDispVolume = 87,
	DownholeTotalWellConfigCTVolumeMinWallThickness = 88,
	DownholeTotalFlowConfigTotalVolume = 89,
	DownholeTotalFlowConfigVolume = 90,
	DownholeTotalPreviousScheduleVolume = 91,
	DownholeTotalPreviousSchedulesVolumeSum = 92,
	FluidTotalWellConfigCTVolumeMaxWallThickness = 93,
	N2TotalWellConfigCTVolumeMaxWallThickness = 94,
	CombinedDisplacementTotalWellConfigCTVolumeMaxWallThickness = 95,
	DownholeTotalWellConfigCTVolumeMaxWallThickness = 96,
	FluidTotalWellConfigCTVolume = 97,
	N2TotalWellConfigCTVolume = 98,
	CombinedDisplacementTotalWellConfigCTVolume = 99,
	DownholeTotalWellConfigCTVolume = 100,
	FluidTotalPerfBottomsUpVolume = 101
}

import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { GridCellProps } from "@progress/kendo-react-all";
import styled from "styled-components";

import TableCell from "components/kendoExtensions/gridExtensions/TableCell";
import { EditGridItemType } from "components/kendoExtensions/grids/StandardEditGrid";

import { useGridState } from "providers/GridStateProvider";

import { resources } from "utils/resources";

const DeleteTableCell = styled(TableCell)`
    > button {
        margin: 5px 0;

        > span {
            background-size: 0 0 !important;
        }
    }
`;

const DeleteRecordCell: React.FC<
    GridCellProps & { disabled?: boolean; onDelete?: (idx: number) => void }
> = (props) => {
    const { formName } = useGridState();
    const elements = useWatch({ name: formName });
    const { setValue, clearErrors } = useFormContext();

    const deleteRecord = (recordIdx: number) => {
        props?.onDelete?.(recordIdx);

        const newRecords = elements.filter(
            (record: EditGridItemType, idx: number) => idx !== recordIdx,
        );
        setValue(formName, newRecords, { shouldDirty: true });
        // TODO: Tech debt. Required to clear errors when deleting a record.
        clearErrors();
    };

    return (
        <DeleteTableCell {...props}>
            {!props.disabled && (
                <div
                    className={
                        "h-8 w-8 cursor-pointer rounded-full border-2 border-gray-200 bg-calfrac-gray-50 p-1.5 hover:border hover:border-gray-50 hover:bg-calfrac-gray-100 hover:shadow"
                    }
                    aria-label={`delete row ${props.dataIndex}`}
                    onClick={(e) => {
                        // Prevent defaults stop automatic form submission from going through and saving
                        e.preventDefault();
                        e.stopPropagation();

                        if (
                            !window.confirm(
                                resources.AreYouSureYouWantToDeleteThisRecord,
                            )
                        ) {
                            return;
                        }
                        deleteRecord(props.dataIndex);
                    }}
                >
                    <XMarkIcon />
                </div>
            )}
        </DeleteTableCell>
    );
};

export default React.memo(DeleteRecordCell);

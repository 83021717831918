import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { SortDescriptor } from "@progress/kendo-data-query";
import { GridColumn } from "@progress/kendo-react-all";
import { GridCellProps } from "@progress/kendo-react-grid";

import TableCell from "components/kendoExtensions/gridExtensions/TableCell";
import StandardIndexGrid from "components/kendoExtensions/grids/StandardIndexGrid";
import { PageHeaderBar } from "components/Layout";
import PDFCell from "components/shared/CustomGridCells/PDFCell";

import {
    AUTO_WIDTH_COLUMN_PROPS,
    DATE_COLUMN_PROPS,
    SMALL_COLUMN_PROPS,
} from "const/columns";

import { resources } from "utils/resources";

export const DEFAULT_SORT: SortDescriptor[] = [
    { field: "IsUrgent", dir: "desc" },
    { field: "EnteredOn", dir: "desc" },
];
const JSONCell: React.FC<GridCellProps> = (props) => {
    const string = JSON.parse(props.dataItem.FormState);
    const [show, setShow] = useState(false);

    const Icon = show ? ChevronUpIcon : ChevronDownIcon;
    return (
        <TableCell {...props}>
            <div className="flex flex-row items-center justify-center">
                <Icon
                    onClick={() => setShow(!show)}
                    className={"flex-0 mr mb-auto h-4 w-4 min-w-[0.75rem]"}
                />
                {!show && (
                    <pre id="json" className={"flex-1"}>
                        {JSON.stringify(string)}
                    </pre>
                )}
                {show && (
                    <pre id="json" className={"flex-1"}>
                        {JSON.stringify(string, null, 2)}
                    </pre>
                )}
            </div>
        </TableCell>
    );
};

const Page: React.FC = () => {
    return (
        <>
            <PageHeaderBar />
            <StandardIndexGrid
                path={"/api/HelpRequest/GetHelpRequests"}
                initialSort={DEFAULT_SORT}
                canExport={true}
            >
                <GridColumn
                    title={"Urgent"}
                    field={"IsUrgent"}
                    locked
                    {...SMALL_COLUMN_PROPS}
                />
                <GridColumn
                    field={"PathName"}
                    title={"Path Name"}
                    {...SMALL_COLUMN_PROPS}
                    locked
                />
                <GridColumn title={"File"} cell={PDFCell} width={55} />
                <GridColumn
                    title={"Message"}
                    field={"UserMessage"}
                    width={500}
                />
                <GridColumn
                    field={"EnteredOn"}
                    title={"Date"}
                    {...DATE_COLUMN_PROPS}
                />
                <GridColumn
                    field={"EnteredByName"}
                    title={resources.Name}
                    {...AUTO_WIDTH_COLUMN_PROPS}
                />
                <GridColumn
                    field={"EnteredByEmail"}
                    title={resources.Email}
                    {...AUTO_WIDTH_COLUMN_PROPS}
                />

                <GridColumn
                    field={"Search"}
                    title={"Search"}
                    {...SMALL_COLUMN_PROPS}
                />

                <GridColumn
                    field={"FormState"}
                    title={"Form State"}
                    {...AUTO_WIDTH_COLUMN_PROPS}
                    cell={JSONCell}
                />
                <GridColumn
                    field={"UserAgent"}
                    title={"User Agent"}
                    {...AUTO_WIDTH_COLUMN_PROPS}
                />
                <GridColumn field={"Id"} title={"Id"} {...SMALL_COLUMN_PROPS} />
            </StandardIndexGrid>
        </>
    );
};

export default Page;

import React, { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditProgramProcedureViewModel } from "types/generated/Calfrac/Jet/Web/Models/ClientOutputs/IEditProgramProcedureViewModel";

import CheckBox from "components/Cells/CheckBox";
import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import TextCell from "components/Cells/TextCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type ClientOutputsTableProps = {
    name: string;
    columnsDisabled: boolean;
};

const columnHelper = createColumnHelper<IEditProgramProcedureViewModel>();

const ClientOutputsTable: React.FC<ClientOutputsTableProps> = (props) => {
    const { program } = useProgramParams();
    const { isPageEditable } = useProgram();
    useWatch(() => props.columnsDisabled);

    const [columnsDisabled, setColumnsDisabled] = useState<boolean>(false);

    useEffect(() => {
        setColumnsDisabled(props.columnsDisabled);
    }, [props.columnsDisabled]);

    const defaultColumns = useMemo(() => {
        return [
            columnHelper.accessor((row) => row.ProcedureId, {
                id: "Delete",
                header: " ",
                size: 20,
                maxSize: 20,
                enableHiding: true,
                enablePinning: true,
                cell: (props) =>
                    !props.row.original.ProcedureId ? (
                        <DeleteButton {...props} />
                    ) : (
                        <></>
                    ),
            }),
            columnHelper.accessor((row) => row.IsSelected, {
                id: "IsSelected",
                header: " ",
                size: 50,
                maxSize: 50,

                cell: (p) => <CheckBox disabled={columnsDisabled} {...p} />,
            }),
            columnHelper.accessor((row) => row.Category, {
                id: "Category",
                header: resources.Category,
                size: 200,
                maxSize: 200,
                cell: (props) =>
                    !props.row.original.ProcedureId ? (
                        <DropDown {...props} />
                    ) : (
                        <TextReadOnly {...props} />
                    ),
            }),
            columnHelper.accessor((row) => row.ProcedureDescription, {
                id: "ProcedureDescription",
                header: resources.Description,
                size: 1100,
                maxSize: 1100,
                cell: (props) =>
                    !props.row.original.ProcedureId ? (
                        <TextCell {...props} />
                    ) : (
                        <TextReadOnly {...props} />
                    ),
            }),
        ];
    }, [columnsDisabled]);

    return (
        <BaseTable
            name={props.name}
            columns={defaultColumns}
            defaultRecord={{ IsSelected: true }}
            disableSelectColumns={[
                "IsSelected",
                "CategoryName",
                "ProcedureDescription",
            ]}
            columnVisibility={{
                Delete: true,
            }}
            meta={{
                disabled: program?.IsApproved,
                name: props.name,
                canDelete: isPageEditable,
                columnsDisabled: props.columnsDisabled,
                urls: {
                    Category: `/api/ClientOutputs/Categories?serviceLineId=${program?.ServiceLineId}`,
                },
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default ClientOutputsTable;

import React, { MutableRefObject, useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CalculatorIcon } from "@heroicons/react/24/outline";
import { Table } from "@tanstack/react-table";

import { ConcentrationCalculationModeId } from "types/generated/Calfrac/Jet/Core/Models/ConcentrationCalculationModeId";

import { BackCalculatePumpScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/BackCalculatePumpScheduleStageTarget";

import { useProgram } from "hooks/useProgramParams";

import { clearSelection } from "utils/copyDown";
import { resources } from "utils/resources";

import BackCalculateColumnInfo from "./BackCalculateColumnInfo";

export type BackCalculateButtonProps = {
    colId: string;
    table: Table<any>;
    selected: MutableRefObject<Map<number, Array<string>>>;
    updateRenderHash: () => void;
    onBackCalculate?: (
        colId: string,
        rowIdx: number,
        target: BackCalculatePumpScheduleStageTarget,
        callback: () => void,
    ) => void;
};

const BackCalculateButton: React.FC<BackCalculateButtonProps> = ({
    colId,
    table,
    selected,
    updateRenderHash,
    ...props
}: BackCalculateButtonProps) => {
    const { isPageEditable } = useProgram();
    const { formState } = useFormContext();
    const model = useWatch();

    const isVisible = useMemo(() => {
        const isValidColumn = BackCalculateColumnInfo[colId] !== undefined;
        const isDownholeMode =
            (colId === "DownholeConcentrationStart" ||
                colId === "DownholeConcentrationEnd") &&
            model?.ConcentrationCalcMode ===
                ConcentrationCalculationModeId.Downhole;
        return isValidColumn && !isDownholeMode;
    }, [colId, model?.ConcentrationCalcMode]);

    const onClick = useCallback(() => {
        const target: BackCalculatePumpScheduleStageTarget =
            BackCalculateColumnInfo[colId];

        // break if work isn't saved.
        if (formState.isDirty) {
            alert(
                resources.PleaseSaveYourCurrentChangesPriorToRunningBackCalculations,
            );
            clearSelection(selected);
            return;
        }

        let rowIdx;

        // Get the first RowID in the selected Map which has a value for the columnId
        for (let [key, value] of selected.current) {
            if (value.includes(colId)) {
                rowIdx = key;
                break;
            }
        }

        if (rowIdx === undefined) {
            const col = table.getColumn(colId);
            let columnName = col?.columnDef.header ?? colId;

            alert(
                resources.BackCalculateInvalidCell2.replace(
                    "0",
                    `"${String(columnName)}"`,
                ),
            );
            clearSelection(selected);
            return;
        }

        props.onBackCalculate?.(colId, rowIdx, target, updateRenderHash);

        // clear the selection now
        clearSelection(selected);
    }, [colId, formState.isDirty, props, selected, table, updateRenderHash]);

    if (!isVisible) return <></>;

    return (
        <div
            className={
                "mt-auto cursor-pointer text-calfrac-green-600 hover:text-calfrac-green-700"
            }
            id={"selecto-ignore"}
            onClick={onClick}
        >
            {isPageEditable && (
                <CalculatorIcon
                    id={"selecto-ignore"}
                    className={"pointer-events-none h-6 w-6"}
                />
            )}
        </div>
    );
};

export default BackCalculateButton;

import {
    GridItemChangeEvent,
    GridKeyDownEvent,
} from "@progress/kendo-react-all";

import { EditGridItemType } from "components/kendoExtensions/grids/StandardEditGrid";

// recursively searches children in DOM of an element to find a focusable input element
const findFocusableElement = (parent: HTMLElement): HTMLElement | null => {
    const nextElement = parent?.firstChild as HTMLElement;
    if (nextElement && nextElement.nodeName !== "INPUT") {
        return findFocusableElement(nextElement);
    }

    return nextElement;
};

// locates row and column index of focused cell in a grid, saves coordinates to state
export const findAndSelectFocusedCell = (
    editableData: EditGridItemType[],
    setSelectedState: React.Dispatch<
        React.SetStateAction<{
            [id: string]: boolean | number[];
        }>
    >,
    setEditID: React.Dispatch<React.SetStateAction<string | number | null>>,
) => {
    const focusedElement = document.activeElement;
    const column = focusedElement?.closest("td");
    const row = column?.closest("tr");
    const columnIndex = column?.cellIndex;
    const rowIndex = row?.rowIndex;

    if (rowIndex !== undefined && columnIndex !== undefined) {
        const rowIds = (editableData as EditGridItemType[]).map((row) =>
            String(row.Id),
        );
        const newRowId = rowIds[rowIndex];
        const newSelectedState = { [String(newRowId)]: [columnIndex] };
        setSelectedState(newSelectedState);
        setEditID(newRowId);
        return { row: rowIndex, col: columnIndex, rowId: newRowId };
    }
};

// allows for moving focus and editing capabilities to a cell
// directly below the current focused cell in the same column in a grid
export const moveDownRow = (
    editableData: EditGridItemType[],
    selectedState: { [id: string]: boolean | number[] },
    setSelectedState: React.Dispatch<
        React.SetStateAction<{
            [id: string]: boolean | number[];
        }>
    >,
    event: GridItemChangeEvent | GridKeyDownEvent,
    setEditID: React.Dispatch<React.SetStateAction<string | number | null>>,
) => {
    const rowIds = (editableData as EditGridItemType[]).map((row) =>
        String(row.Id),
    );
    const lastRowIndex = rowIds.length - 1;
    const selectedRowIds = Object.keys(selectedState);
    const selectedRowId = String(selectedRowIds[0]);
    const selectedRowIndex = rowIds.indexOf(selectedRowId);
    const nextRowIndex = selectedRowIndex + 1;
    const nextRowId = rowIds[nextRowIndex];
    const colIndices = selectedState[selectedRowId];

    if (colIndices instanceof Array && nextRowIndex <= lastRowIndex) {
        const colIndex = colIndices[0];
        const grid = event.target;
        const tableBody = grid.element?.getElementsByTagName("tbody");
        const row =
            tableBody !== undefined && tableBody.length > 0
                ? tableBody[0].childNodes[nextRowIndex]
                : undefined;
        const col = row?.childNodes[colIndex] as HTMLElement;
        const nextEditableCell = findFocusableElement(col);

        if (nextEditableCell) {
            const newSelectedState = { [String(nextRowId)]: colIndices };
            setSelectedState(newSelectedState);
            setEditID(nextRowId);

            // wait for editID to update, so next row can properly be placed in edit mode
            // before the user starts editing a cell
            setTimeout(() => {
                nextEditableCell?.focus();
                try {
                    const newFocusedElement =
                        document.activeElement as HTMLInputElement;
                    newFocusedElement?.select();
                } catch (e) {
                    // Sometimes select causes an issue when new focused element is not an HTMLElement
                    // This happens in cases where we want to ignore a key press in the grid and use it
                    // somewhere else (ex: a popup).
                }
            }, 50);
        }
    }
};

// move the user's focus to a cell (if they had previously been editing one in the grid)
// in the last row of the grid if they have added a new record
export const moveToLastRow = (
    gridRef: React.MutableRefObject<HTMLDivElement | null>,
    hasTitle: boolean,
    data: EditGridItemType[],
    selectedState: { [id: string]: boolean | number[] },
    setSelectedState: React.Dispatch<
        React.SetStateAction<{
            [id: string]: boolean | number[];
        }>
    >,
) => {
    const rowIds = (data as EditGridItemType[]).map((row) => String(row.Id));
    const lastRowIndex = rowIds.length - 1;
    const selectedRowIds = Object.keys(selectedState);
    const currentRowId = String(selectedRowIds[0]);
    const lastRowId = rowIds[lastRowIndex];
    const colIndices = selectedState[currentRowId];

    if (colIndices instanceof Array) {
        const colIndex = colIndices[0];
        const grid = hasTitle
            ? gridRef.current?.lastChild
            : gridRef.current?.firstChild;
        const tableBody =
            grid?.lastChild?.firstChild?.firstChild?.firstChild?.lastChild;
        const row = tableBody?.childNodes[lastRowIndex];
        const col = row?.childNodes[colIndex] as HTMLElement;
        const nextEditableCell = findFocusableElement(col);

        if (nextEditableCell) {
            const newSelectedState = { [String(lastRowId)]: colIndices };
            setSelectedState(newSelectedState);
            nextEditableCell?.focus();
            const newFocusedElement =
                document.activeElement as HTMLInputElement;
            if (newFocusedElement.nodeName === "INPUT") {
                newFocusedElement?.select();
            }
        }
    }
};

import React, { useCallback, useReducer, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Dialog, GridColumn } from "@progress/kendo-react-all";

import { IEditChemicalLoadingNoteViewModel } from "types/generated/Calfrac/Jet/Web/Models/ChemicalLoading/IEditChemicalLoadingNoteViewModel";

import { PrimaryButton } from "components/Buttons";
import StandardEditGrid from "components/kendoExtensions/grids/StandardEditGrid";
import RadioButtonGroup from "components/kendoExtensions/standardExtensions/RadioButtonGroup";
import { NotCementView } from "components/Layout/CementView";
import { NotCtanView } from "components/Layout/CtanView";
import FormSection from "components/Layout/FormSection";
import { GridTextBox } from "components/shared/CustomGridCells/GridTextBox";
import { GridCheckBox } from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import ChemicalLoadingTable from "components/Tables/ChemicalLoading/ChemicalLoadingTable";

import { SMALL_COLUMN_PROPS } from "const/columns";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type EditChemicalLoadingNoteProps = {
    notesName: string;
    visible?: boolean;
    onClose?: () => void;
};

/**
 * This is a popup modal component that allows the user to edit the notes for the pump schedule stages, it is opened
 * inside the main page component when the user clicks on the edit notes button on the StandardEditGrid toolbar
 * @param props
 * @constructor
 */
const EditChemicalLoadingNotes: React.FC<EditChemicalLoadingNoteProps> = (
    props,
) => {
    const { isPageEditable } = useProgramParams();
    const { setValue } = useFormContext();
    const notes = useWatch({
        name: props.notesName,
    }) as IEditChemicalLoadingNoteViewModel[];

    const handleClose = useCallback(() => {
        const mappedNotes: IEditChemicalLoadingNoteViewModel[] = notes.map(
            (note: IEditChemicalLoadingNoteViewModel) => {
                if (note?.Note && typeof note?.Note === "string") {
                    // Spread the old object into the new object, because Id might be a non-zero number (note exists in
                    // database), or it can be a string (e.g. "NEW-asdfg") for a newly created note that will be
                    // persisted on save.
                    const newNote = {
                        ...note,
                        DisplayOnOutput: note.DisplayOnOutput,
                        Note: note.Note,
                    } as IEditChemicalLoadingNoteViewModel;
                    return newNote;
                }
                return note;
            },
        );
        setValue(props.notesName, mappedNotes);
        props?.onClose?.();
    }, [notes, props, setValue]);

    if (!props?.visible) {
        return <></>;
    }

    return (
        <>
            <Dialog
                title={resources.EditNotes}
                onClose={handleClose}
                width={800}
                height={450}
                themeColor={"primary"}
            >
                <StandardEditGrid
                    canCreate
                    canEdit={isPageEditable}
                    name={props.notesName}
                    height={"300px"}
                    addRecordDefault={{ DisplayOnOutput: true }}
                >
                    <GridColumn
                        title={resources.DisplayOnOutput}
                        field={"DisplayOnOutput"}
                        editable={isPageEditable}
                        cell={GridCheckBox}
                        {...SMALL_COLUMN_PROPS}
                        width={140}
                    />
                    <GridColumn
                        title={resources.Note}
                        field={"Note"}
                        editable={isPageEditable}
                        cell={GridTextBox}
                    />
                </StandardEditGrid>
                <div className={"w-100 relative"}>
                    <PrimaryButton
                        className={"bottom-100 absolute right-0"}
                        onClick={handleClose}
                    >
                        {resources.Done}
                    </PrimaryButton>
                </div>
            </Dialog>
        </>
    );
};

/**
 * Chemical Loading page
 * @constructor
 */
const Page: React.FC = () => {
    // Constants
    const url = "/api/ChemicalLoading/EditChemicalLoading";

    // Url params
    const { programId, isPageEditable } = useProgramParams();
    const isAdvancedChemSchedule = useWatch({
        name: "IsAdvancedChemSchedule",
    }) as boolean;

    // Determines whether to show/hide the notes for Chemical Loading page
    const [notesVisible, setNotesVisible] = useState<boolean>(false);

    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    return (
        <StandardForm
            readUrl={url}
            readParams={{ programId }}
            saveUrl={url}
            clearCacheOnSave={["/api/Schedule/EditSchedules"]}
            onSaveSuccess={updateTableRenderHash}
        >
            <NotCementView>
                <NotCtanView>
                    {typeof isAdvancedChemSchedule === "boolean" &&
                        !isAdvancedChemSchedule && (
                            <EditChemicalLoadingNotes
                                notesName={"ChemicalLoadingNotes"}
                                visible={notesVisible}
                                onClose={() => setNotesVisible(false)}
                            />
                        )}
                </NotCtanView>
            </NotCementView>
            <FormSection label={resources.ChemicalLoading}>
                <NotCementView>
                    <NotCtanView>
                        <RadioButtonGroup
                            label={resources.ChemicalScheduleType}
                            name="IsAdvancedChemSchedule"
                            readOnly={!isPageEditable}
                            label1={resources.Standard}
                            label2={resources.Advanced}
                            target1={false}
                            target2={true}
                        />
                    </NotCtanView>
                </NotCementView>
                <ChemicalLoadingTable
                    name={"ChemicalLoadings"}
                    notesName={"ChemicalLoadingNotes"}
                    renderHash={tableRenderHash}
                    onEditNotes={() => {
                        setNotesVisible(true);
                    }}
                />
            </FormSection>
        </StandardForm>
    );
};

export default Page;

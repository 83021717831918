import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Dialog } from "@progress/kendo-react-all";
import styled from "styled-components";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";
import { ServiceLineId } from "types/generated/Calfrac/Jet/Core/Models/ServiceLineId";

import { IEditPerforationClusterViewModel } from "types/generated/Calfrac/Jet/Web/Models/Perforation/IEditPerforationClusterViewModel";

import StandardButton from "components/shared/GenericCustomComponents/StandardButton";
import ClustersTable from "components/Tables/Perforations/ClustersTable";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type ClustersPopupProps = {
    title?: string;
    name: string; // Name of field to edit with react-hook-form
    visible?: boolean;
    onClose: (val: boolean) => void;
};

const DoneButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 15px;
`;
export const ClustersPopup: React.FC<ClustersPopupProps> = ({
    title,
    name,
    onClose,
}: ClustersPopupProps) => {
    const perforationClusters = useWatch({ name });
    const { setValue } = useFormContext();
    const { isPageEditable } = useProgramParams();
    const { program } = useProgramParams();

    // Helpers
    const computeTotalPerf = (
        top: number | undefined = 0,
        bottom: number | undefined = 0,
        shots: number | undefined = 0,
    ) => {
        const mToFtFactor = 3.28084;

        // if Bottom and Top are not provided, calculation should return Shots as total,
        // likewise, if Top and Bottom are the same, calculation should return Shots as total
        let bottomMinusTop = bottom - top !== 0 ? bottom - top : 1;

        // if Argentina frac, we must convert from m to ft for bottomMinusTop value
        if (
            program?.CountryId === CountryId.Argentina &&
            program?.ServiceLineId === ServiceLineId.Fracturing &&
            bottom !== top
        )
            bottomMinusTop *= mToFtFactor;

        // Always round up to the nearest whole number
        return Math.ceil(bottomMinusTop * shots);
    };

    const onDone = () => {
        const [minTop, maxBottom] = perforationClusters?.reduce(
            (acc: number[], val: IEditPerforationClusterViewModel) => {
                if (!acc[0] || val.Top < acc[0]) acc[0] = val.Top;
                if (!acc[1] || val.Bottom > acc[1]) acc[1] = val.Bottom;
                return acc;
            },
            [undefined, undefined],
        );
        const topName = name.replace("PerforationClusters", "Top");
        const bottomName = name.replace("PerforationClusters", "Bottom");

        setValue(topName, minTop);
        setValue(bottomName, maxBottom);

        onClose?.(false);
    };
    const totalPerfs = () => {
        return perforationClusters
            ?.map((e: any) => computeTotalPerf(e.Top, e.Bottom, e.Shots))
            ?.reduce((acc: number, val: number) => acc + val, 0);
    };

    return (
        <Dialog
            title={resources.PerforationClusters}
            onClose={() => onClose?.(false)}
            width={700}
            themeColor={"primary"}
        >
            <h1 className={"mb-8 text-lg font-bold"}>{title}</h1>
            <div className={"mx-4"}>
                <ClustersTable
                    name={name}
                    canCreate={isPageEditable}
                    canEdit={isPageEditable}
                    canDeleteRecord={isPageEditable}
                    computeTotalPerf={computeTotalPerf}
                />
            </div>
            <div
                className={
                    "mt-4 flex justify-end bg-slate-50 px-10 py-6 font-semibold"
                }
            >
                <h1
                    className={"text-md"}
                >{`Total: ${totalPerfs()?.toFixed(0)}`}</h1>
            </div>
            <DoneButtonContainer>
                <StandardButton
                    classes={"main-header-action main-header-action--primary"}
                    text={"Done"}
                    onClick={onDone}
                />
            </DoneButtonContainer>
        </Dialog>
    );
};

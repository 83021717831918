import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { CellType, TableMetaT } from "types/Tables/Cells";

const DeleteButton: CellType = ({ table, row }) => {
    if (!(table.options.meta as TableMetaT)?.canDelete) return <></>;
    return (
        <>
            <div
                className={
                    "h-8 w-8 cursor-pointer rounded-full border-2 border-gray-200 bg-calfrac-gray-50 p-1.5 hover:border hover:border-gray-50 hover:bg-calfrac-gray-100 hover:shadow disabled:cursor-none"
                }
                onClick={() => {
                    if ((table.options.meta as TableMetaT)?.disabled) return;
                    (table.options.meta as TableMetaT).deleteRecord(
                        row.original.Id,
                    );
                }}
            >
                <XMarkIcon />
            </div>
        </>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(
    DeleteButton,
    // Return true to never rerender
    () => true,
);

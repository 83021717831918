import React, { useReducer } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";

import { H2 } from "components/Headers";
import FormSection, { FormSectionDivider } from "components/Layout/FormSection";
import { SubtitlePortal } from "components/Layout/SubtitlePortal";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import FlowConfigurationTable from "components/Tables/FlowConfig/FlowConfigurationTable";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

type Params = {
    flowConfigurationId: string;
};

const Page: React.FC = () => {
    // Constants
    const url = "/api/FlowConfiguration/EditFlowConfiguration";

    const navigate = useNavigate();

    // React-hook-form
    const model = useWatch();

    // Url params
    const { flowConfigurationId } = useParams<Params>();

    const { programId } = useProgramParams();

    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    return (
        <StandardForm
            readUrl={url}
            readParams={{ flowConfigurationId }}
            saveUrl={url}
            clearCacheOnSave={["/api/DesignResults/EditDesignResults"]}
            onSaveSuccess={updateTableRenderHash}
        >
            <SubtitlePortal>
                <div className={"flex items-center gap-5"}>
                    <H2>{`/ ${model?.FlowConfigurationDescription}`}</H2>
                    <div
                        className={"flex items-center gap-1"}
                        onClick={() => {
                            navigate(
                                `/FlowConfiguration/EditFlowConfigurations/${programId}`,
                            );
                        }}
                    >
                        <ArrowLeftCircleIcon
                            className={"h-7 w-7 fill-calfrac-green"}
                        />
                        <StandardAnchorLink
                            text={resources.BackToFlowConfigs.toUpperCase()}
                        />
                    </div>
                </div>
            </SubtitlePortal>
            <FormSectionDivider>
                <FormSection label={resources.FlowConfigurationItems}>
                    <FlowConfigurationTable
                        name={"FlowConfigurationItems"}
                        renderHash={tableRenderHash}
                    />
                </FormSection>
            </FormSectionDivider>
        </StandardForm>
    );
};

export default Page;

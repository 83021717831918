import React from "react";
import { useWatch } from "react-hook-form";
import { formatNumber } from "@progress/kendo-intl";
import { NumberFormatOptions } from "@progress/kendo-react-all";
import styled from "styled-components";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

type Props = {
    id?: string;
    text?: string | number;
    transform?: (value: any) => typeof value;
    label?: string;
    // Used with react hook form to register element
    name?: string;
    format?: NumberFormatOptions | string; // Pass it the Formats.[type]format.toString() (do not use the pattern version)
    className?: string;
    alignRight?: boolean;
    overrideValue?: string | number;
    bold?: boolean;
    underline?: boolean;
};

const StandardReadOnly: React.FC<Props> = ({
    name = " ",
    underline,
    bold,
    ...props
}: Props) => {
    let value = useWatch({ name });

    if (props.overrideValue) {
        value = props.overrideValue;
    }

    return (
        <StandardReadOnlyContainer
            className={props.className ? props.className : "col-span-1"}
            id={props.id}
            alignRight={props.alignRight}
        >
            {props.label && (
                <StandardLabel labelFor={props.id} label={props.label} />
            )}
            <span
                /* eslint-disable */
        className={`opacity-1 inline-block min-h-7 w-full rounded-md bg-calfrac-gray-50 px-2 py-1 text-sm text-black shadow-none   ${
          bold ? "font-bold" : ""
        } ${underline ? "underline" : ""}`}
          /* eslint-enable */
            >
                {props.format ? formatNumber(value, props.format) : value}
            </span>
        </StandardReadOnlyContainer>
    );
};

type StandardReadOnlyContainerProps = {
    alignRight?: boolean;
};

const StandardReadOnlyContainer = styled.span<StandardReadOnlyContainerProps>`
    text-align: ${(props) => (props.alignRight ? "right" : "inherit")};
`;

export default StandardReadOnly;

import React, { ReactNode } from "react";

import { ServiceLineId } from "types/generated/Calfrac/Jet/Core/Models/ServiceLineId";

import { IProgramContextViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/IProgramContextViewModel";

import { useProgram } from "hooks/useProgramParams";

const CementView: React.FC<{ children: ReactNode; invert?: boolean }> = ({
    children,
    invert = false,
}) => {
    const { program } = useProgram();
    const isCement = isProgramCement(program);

    if (invert ? isCement : !isCement) return null;

    return <>{children}</>;
};

export const NotCementView: React.FC<{
    children: ReactNode;
    negate?: boolean;
}> = ({ children }) => {
    return <CementView children={children} invert={true} />;
};

export const isProgramCement = (
    program: IProgramContextViewModel | undefined,
) => {
    return program?.ServiceLineId === ServiceLineId.Cement;
};

export default CementView;

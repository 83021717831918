import React from "react";
import { CellContext } from "@tanstack/react-table";

import NumericReadOnly from "./NumericReadOnly";

const IntegerReadOnly = (
    props: CellContext<any, any> & {
        pValue?: number;
        viewFormatOverride?: string;
        className?: string;
    },
) => {
    return <NumericReadOnly {...props} viewFormatOverride={"n0"} />;
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(IntegerReadOnly, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return prevValue === nextValue;
});

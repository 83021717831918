import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-all";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { Formats } from "utils/enumerations";

type Props = {
    id?: string;
    onChange?: (newDate: Date | null) => void;
    format?: Formats;
    disabled?: boolean;
    label?: string;
    // Used with react hook form to register element
    name: string;
};

const StandardDatePicker: React.FC<Props> = ({
    name = "",
    format = Formats.DateFormat,
    ...props
}: Props) => {
    // Hooks
    const { setValue, clearErrors } = useFormContext();
    const dateString: string = useWatch({ name });

    const dateObj = useMemo(() => {
        if (!dateString) {
            return undefined;
        }

        const date = new Date(dateString);

        if (date.getFullYear() < 1970) {
            return undefined;
        }

        return date;
    }, [dateString]);

    const handleChange = useCallback(
        (e: DatePickerChangeEvent) => {
            setValue(name, e.target.value, { shouldDirty: true });
            clearErrors(name);
        },

        [clearErrors, name, setValue],
    );

    return (
        <div className={"block"}>
            {props.label && <StandardLabel label={props.label} />}
            <DatePicker
                id={props.id}
                format={format.toString()}
                weekNumber={false}
                value={dateObj}
                disabled={props.disabled}
                onChange={handleChange}
                className={props?.disabled ? "bg-calfrac-gray-50" : ""}
            />
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </div>
    );
};

export default StandardDatePicker;

import React, { memo, useMemo, useReducer, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { IEditActivatorLoadingItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/ActivatorLoading/IEditActivatorLoadingItemViewModel";

import CheckBox from "components/Cells/CheckBox";
import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import LoadDefaults from "components/shared/CustomGridToolbar/ToolbarElements/LoadDefaults";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type ActivatorLoadingTableProps = {
    name: string;
};

const columnHelper = createColumnHelper<any>();

const ActivatorLoadingTable: React.FC<ActivatorLoadingTableProps> = (props) => {
    const { programId, program, isPageEditable } = useProgramParams();
    const units = useUnits();
    const [displayNotInUse, setDisplayNotInUse] = useState<boolean>(false);
    const { setValue } = useFormContext();
    const data = useWatch({ name: props.name });

    // Updating this render hash will rerender all the rows. This is useful for data changes like copying and pasting from excel.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    const defaultColumns = useMemo(
        () => [
            columnHelper.display({
                id: "Delete",
                size: 40,
                cell: DeleteButton,
            }),
            columnHelper.accessor((row) => row.ProgramProppant, {
                id: "ProgramProppant",
                header: resources.Proppant,
                size: 700,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.Activator, {
                id: "Activator",
                header: resources.Activator,
                size: 700,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.Concentration, {
                id: "Concentration",
                header: `${resources.Concentration} ${units.concentrationChemicalWetUnit}`,
                size: 100,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.IsProppantUsedOnPumpSchedule, {
                id: "IsProppantUsedOnPumpSchedule",
                header: resources.InUse,
                size: 80,
                cell: (props) => <CheckBox readOnly={true} {...props} />,
            }),
        ],
        [units.concentrationChemicalWetUnit],
    );

    const toolbarExtras = () => {
        return (
            <div
                className={
                    "sticky left-0 z-10 mb-1 flex w-full items-center space-x-4"
                }
            >
                <LoadDefaults
                    url={"/api/ActivatorLoading/LoadDefaults"}
                    params={{ programId }}
                    warningMsg={resources.LoadDefaultsWarning}
                    isVisible={isPageEditable}
                    onNewData={(newData) => {
                        for (const row of newData) {
                            const key = Math.random()
                                .toString(36)
                                .substring(2, 15);
                            row.Id = `NEW-${key}`;
                        }
                        setValue(props.name, newData, { shouldDirty: true });
                        updateRenderHash();
                    }}
                />
                <span>
                    <StandardCheckBox
                        sidelabel={resources.DisplayNotInUse}
                        checked={displayNotInUse}
                        onChange={(v) => {
                            setDisplayNotInUse(v);
                            updateRenderHash();
                        }}
                    />
                </span>
            </div>
        );
    };

    const numberOfRecordsInUse = useMemo(
        () =>
            data?.filter(
                (rec: IEditActivatorLoadingItemViewModel) =>
                    rec.IsProppantUsedOnPumpSchedule,
            ).length,
        [data],
    );

    return (
        <BaseTable
            columns={defaultColumns}
            name={props.name}
            renderHash={renderHash}
            toolbarExtras={toolbarExtras}
            defaultRecord={{
                IsProppantUsedOnPumpSchedule: true,
            }}
            displayNotInUse={displayNotInUse}
            emptyBody={
                (numberOfRecordsInUse === 0 && !displayNotInUse) ||
                (data?.length === 0 && displayNotInUse)
            }
            noRowsMessage={
                data?.length > 0 &&
                numberOfRecordsInUse === 0 &&
                !displayNotInUse
                    ? resources.NoRecordsInUse
                    : undefined
            }
            meta={{
                disabled: !isPageEditable,
                canDelete: isPageEditable,
                urls: {
                    ProgramProppant: `/api/ActivatorLoading/GetProgramProppants?programId=${programId}`,
                    Activator: `/api/ActivatorLoading/GetActivators?countryId=${program?.CountryId}`,
                },
            }}
            afterAddRecord={() => {
                updateRenderHash();
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(ActivatorLoadingTable);

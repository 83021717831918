import React, { memo, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

import { IAccountDetailsViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/IAccountDetailsViewModel";
import { IContactDetailsViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/IContactDetailsViewModel";
import { IUserDetailsViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/IUserDetailsViewModel";

import { H3 } from "components/Headers";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { resources } from "utils/resources";

import AddressDetails, { DataRow } from "./AddressDetails";

type Props = {
    visible?: boolean;
    params: { [key: string]: string };
    name: string;
    url: JetApiUrls;
};

const ContactDetails: React.FC<Props> = ({ url, params, name }: Props) => {
    const { setValue } = useFormContext();
    const contactDetails = useWatch({ name }) as
        | IContactDetailsViewModel
        | IAccountDetailsViewModel
        | IUserDetailsViewModel;

    const { data: contactData, isLoading } = useQuery<
        IContactDetailsViewModel | {}
    >({
        queryKey: ["contactDetails", url, ...Object.entries(params).flat()],
        queryFn: async () => {
            if (
                params &&
                Object.values(params).some(
                    (val) => val === null || val === undefined || val === "",
                )
            ) {
                return {};
            }
            return await fetchJET(url, params);
        },
    });

    useEffect(() => {
        setValue(name, contactData);
    }, [contactData, setValue, name]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!contactDetails) {
        return null;
    }

    return (
        <div className="">
            <H3 className={"text-calfrac-green"}>
                {"AccountName" in contactDetails
                    ? contactDetails?.AccountName
                    : contactDetails?.Name}
            </H3>
            {"AddressDetails" in contactDetails && (
                <AddressDetails
                    addressDetails={contactDetails?.AddressDetails}
                    visible={true}
                />
            )}
            <dl className={"text-sm "}>
                {contactDetails?.Phone && (
                    <a href={`tel:${contactDetails?.Phone}`}>
                        <DataRow
                            label={"Phone"}
                            value={contactDetails?.Phone}
                        />
                    </a>
                )}
                {"Mobile" in contactDetails && (
                    <a href={`tel:${contactDetails?.Mobile}`}>
                        <DataRow
                            label={"Mobile"}
                            value={contactDetails?.Mobile}
                        />
                    </a>
                )}
                {"Cell" in contactDetails && (
                    <a href={`tel:${contactDetails?.Cell}`}>
                        <DataRow
                            label={"Mobile"}
                            value={contactDetails?.Cell}
                        />
                    </a>
                )}
                {"Email" in contactDetails && (
                    <a
                        className="email"
                        href={`mailto:${contactDetails?.Email}`}
                    >
                        <DataRow
                            label={resources.Email}
                            value={contactDetails?.Email}
                        />
                    </a>
                )}
            </dl>
        </div>
    );
};

export default memo(ContactDetails);

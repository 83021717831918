import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import {
    SortDescriptor,
    toDataSourceRequestString,
} from "@progress/kendo-data-query";
import { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import { DataSourceRequestState } from "@progress/kendo-data-query/dist/npm/mvc/operators";
import {
    ExcelExport,
    Grid,
    GridDataStateChangeEvent,
    GridEvent,
    GridProps,
    GridSortSettings,
} from "@progress/kendo-react-all";
import { getter } from "@progress/kendo-react-common";
import {
    getSelectedState,
    GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import { QueryKey } from "@tanstack/query-core";
import {
    DefaultError,
    InfiniteData,
    useInfiniteQuery,
} from "@tanstack/react-query";
import styled from "styled-components";

import { PrimaryButton } from "components/Buttons";
import { HeaderPortal } from "components/Layout/HeaderPortal";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import useDebounce from "hooks/useDebounce";

import { fetchJET, JetApiUrls } from "utils/fetchJet";

const PAGE_SIZE = 50;
const SORTABLE_PROPS: GridSortSettings = {
    allowUnsort: true,
    mode: "single",
};

const GridWrapper = styled.div`
    width: 100%;

    .k-filtercell-operator {
        // On request of the client, the filter and clear filter buttons have been removed.
        // This simply turns the columns into "contains" filters only.
        display: none;
    }
`;

interface StandardIndexGridProps<TViewModel> extends GridProps {
    path: JetApiUrls;
    toolbar?: ReactNode;
    initialSort?: SortDescriptor[];
    externalFilters?: GridDataStateChangeEvent["dataState"]["filter"];
    queryData?: { [key: string]: string };
    onChangeSelectedState?: (val: { [id: string]: boolean | number[] }) => void;
    dataSourceFilter?: (
        val: TViewModel[] | undefined,
    ) => TViewModel[] | undefined;
    initialState?: { [id: string]: boolean | number[] };
    customKey?: string;
    serverFilter?: Array<FilterDescriptor>;
}

const dataStateReducer = (
    _state: GridDataStateChangeEvent["dataState"],
    e: GridDataStateChangeEvent | Pick<GridDataStateChangeEvent, "dataState">,
) => {
    return e.dataState;
};

export const toQueryString = (
    dataState: DataSourceRequestState,
    serverFilter?: Array<FilterDescriptor>,
): string => {
    // The Azure firewall doesn't like trailing dashes, so we remove them here.
    // Many of our queries have trailing dashes, so this is a quick fix.
    const regex = /(?<=')[^']*-+(?=')/g;

    const newDataState: DataSourceRequestState = {
        ...dataState,
        filter: {
            ...(dataState.filter ?? { logic: "and" }),
            filters: [
                ...(dataState?.filter?.filters ?? []),
                // Occasionally we want to apply a filter on the server only and not in the UI / UX. This is where we do that.
                ...(serverFilter ?? []),
            ],
        },
    };

    return toDataSourceRequestString(newDataState).replace(regex, (match) =>
        match.replace(/-+$/, ""),
    );
};

/**
 * StandardIndexGrid
 *
 * This component is used to render the full page grid which
 * appears on pages like Program, Request, Task
 *
 *
 * @param children
 * @param path
 * @param toolbar
 * @param externalFilters
 * @param dataItemKey
 * @param selectedField
 * @param onChangeSelectedState
 * @param initialState
 * @param customKey
 * @param props
 * @constructor
 */
const StandardIndexGrid = <TViewModel,>({
    children,
    path,
    externalFilters,
    dataItemKey = "Id",
    selectedField = "selected",
    onChangeSelectedState,
    initialState,
    customKey = "",
    queryData = {},
    canExport,
    serverFilter,
    ...props
}: StandardIndexGridProps<TViewModel> & {
    canExport?: boolean;
}): React.ReactElement => {
    // EXPORTING
    const _export = React.useRef<ExcelExport | null>(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    // SORTING + FILTERING
    const total = useRef(0);
    const [dataState, setDataState] = useReducer(dataStateReducer, {
        sort: props.initialSort,
        take: PAGE_SIZE ?? 0,
        filter: externalFilters,
    });

    useEffect(() => {
        if (!externalFilters) return;
        const nextState: Pick<GridDataStateChangeEvent, "dataState"> = {
            dataState: { filter: externalFilters, sort: props.initialSort },
        };
        setDataState(nextState);
    }, [externalFilters, props.initialSort]);

    // SELECTION

    // Selection Handling
    const idGetter = getter(dataItemKey);
    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>(initialState ?? {});

    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState,
                dataItemKey,
            });

            const additionalState = getSelectedState({
                event,
                selectedState,
                dataItemKey: customKey,
            });

            setSelectedState(newSelectedState);

            // Update the parent if this has been provided.
            onChangeSelectedState?.({
                ...newSelectedState,
                ...additionalState,
            });
        },
        [selectedState, dataItemKey, customKey, onChangeSelectedState],
    );

    // This ensures that we're not constantly searching while the user is typing or interacting with the table.]
    const searchParams = useDebounce([
        toQueryString({ ...dataState, take: PAGE_SIZE }, serverFilter),
        ...Object.values(queryData),
    ]);

    type TTablePageData = { [key: string]: string | number };

    // An infinite query is used to fetch the data from the API
    const tableData = useInfiniteQuery<
        TTablePageData,
        DefaultError,
        InfiniteData<{ [key: string]: string | number }>,
        QueryKey,
        number
    >({
        queryKey: [path, ...searchParams],
        queryFn: async (queryFnArgs: {
            queryKey: QueryKey;
            signal: AbortSignal;
            pageParam: number;
            direction: any;
            meta: Record<string, unknown> | undefined;
        }) => {
            const signal: AbortSignal = queryFnArgs.signal;
            const pageParam: number = queryFnArgs.pageParam;
            const data = await fetchJET<{
                Data: TTablePageData;
                Total: number;
                pageParam?: number;
            }>(
                path,
                { ...queryData, page: pageParam.toString() },
                searchParams[0],
                {
                    signal,
                },
            );

            // Total is the total number of rows available from the DB (not paginated)
            total.current = data.Total;

            // Returns one page of data, max size will be PAGE_SIZE
            return data.Data;
        },
        initialPageParam: 1,

        // This is used to determine if there is more data to fetch
        getNextPageParam: (
            lastPage: TTablePageData,
            allPages: TTablePageData[],
            lastPageParam: number,
            allPageParams: number[],
        ) => {
            const rowCountForLoadedPages: number = allPages.reduce(
                (acc: number, currentValue: TTablePageData) => {
                    return acc + Object.keys(currentValue).length;
                },
                0,
            );
            if (rowCountForLoadedPages < total.current) {
                return allPages.length + 1;
            }

            return null;
        },
    });

    // If we've scrolled close enough to the bottom, then refetch the data.
    const scrollHandler = useCallback(
        (event: GridEvent) => {
            // if Grid is scrolled to bottom
            const isAtBottom =
                event.nativeEvent.target.scrollTop + 100 >=
                event.nativeEvent.target.scrollHeight -
                    event.nativeEvent.target.clientHeight;

            // When user scrolls to the bottom, if we have a next page, and we are not already fetching, then we fetch the next page.
            if (
                isAtBottom &&
                tableData.hasNextPage &&
                !tableData.isFetchingNextPage
            ) {
                tableData.fetchNextPage();
            }
        },
        [tableData],
    );

    const getData = useMemo(() => {
        // Base data transformation
        const baseData = tableData?.data?.pages
            ?.flatMap((row) => row as unknown as TViewModel[])
            .map((item) => ({
                ...item,
                [selectedField]: selectedState![idGetter(item)],
            }));

        // Apply dataSourceFilter if it exists
        return props.dataSourceFilter
            ? props.dataSourceFilter(baseData)
            : baseData;
    }, [tableData?.data?.pages, props, selectedField, selectedState, idGetter]);

    return (
        <ExcelExport data={getData} ref={_export}>
            {canExport && (
                <HeaderPortal>
                    <PrimaryButton onClick={excelExport}>Export</PrimaryButton>
                </HeaderPortal>
            )}
            <GridWrapper
                className={"mb-[2rem] flex-grow overflow-hidden rounded-xl"}
            >
                <Grid
                    {...props}
                    // Styling
                    resizable
                    className={"h-full overflow-hidden"}
                    // SELECTION
                    onSelectionChange={onSelectionChange}
                    selectedField={selectedField}
                    data={getData}
                    scrollable={"scrollable"}
                    onScroll={scrollHandler}
                    // Filters
                    filterable
                    filter={dataState.filter}
                    pageSize={PAGE_SIZE}
                    // Sorting
                    sortable={SORTABLE_PROPS}
                    sort={dataState.sort}
                    onDataStateChange={setDataState}
                >
                    {children}
                </Grid>
                {tableData.isFetching && <LoadingOverlay />}
            </GridWrapper>
        </ExcelExport>
    );
};

export default StandardIndexGrid;

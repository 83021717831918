import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { ErrorOption, useFormContext, useWatch } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { Dialog } from "@progress/kendo-react-all";
import { useMutation } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";

import { CalculationTypeId } from "types/generated/Calfrac/Jet/Core/Models/CalculationTypeId";

import { FormulaCalculateScheduleStageTarget } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/FormulaCalculateScheduleStageTarget";
import { IEditStageCalculationViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditStageCalculationViewModel";

import { queryClient } from "AppRoutes/AppProviders";

import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardButton from "components/shared/GenericCustomComponents/StandardButton";
import { ValidationSummary } from "components/shared/GenericCustomComponents/StandardValidationSummary";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import { saveJET } from "utils/fetchJet";
import {
    fieldErrorToErrorObject,
    getCalculationType,
    hasEntityByCalcType,
} from "utils/helpers";
import { parseSafeStringIdToInt } from "utils/parseSafeStringIdToInt";
import { resources } from "utils/resources";

import Fields from "./CalculationFields/Fields";
import FormulaCalculateColumnInfo, {
    Formulas,
    getFormulas,
    TargetToName,
} from "./FormulaCalculateColumnInfo";

type FormulaCalculateProps = {
    onClose: () => void;
    treatmentScheduleStageId: number;
    columnName?: string;
    rowIdx: number;
    field: string;
    target: FormulaCalculateScheduleStageTarget;
};

const FormulaCalculatePopUp: React.FC<FormulaCalculateProps> = ({
    treatmentScheduleStageId = 0,
    columnName = "",
    target,
    ...props
}: FormulaCalculateProps) => {
    const { reset, setValue, getValues } = useFormContext();

    // Refers to the model tied to this popup (ex: the current calculation)
    const calculation: IEditStageCalculationViewModel | undefined = useWatch({
        name: "Calculation",
    });

    const [errors, setErrors] = useState<
        { [key: string]: ErrorOption } | undefined
    >();

    // MUTATIONS

    const mutation = useMutation({
        mutationFn: async () => {
            await saveJET<Record<string, any>>(
                "/api/Calculation/EditStageCalculation",
                {},
                serialize(calculation, { indices: true }),
            ).then(async (res: any) => {
                // If there are server validation errors
                if (res.Errors) {
                    setErrors(fieldErrorToErrorObject(res.Errors));
                    return res;
                } else {
                    reset(await res.json()); // Set the values of the new form model

                    // After "Calculate & Save" from a formula popup, we need to invalidate this query so that when the user returns
                    // to the Schedules page, via the sidebar button or by "browser back", we hit the endpoint to properly determine
                    // if there are any errors for that schedule (because the user may have resolved the last error, so they'd
                    // expect to see no red notch i.e. no errors for that schedule).
                    const clearCacheOnCalculateAndSave = [
                        "/api/Schedule/EditSchedules",
                    ];
                    await queryClient.invalidateQueries({
                        predicate: (query) => {
                            if (
                                Array.isArray(query?.queryKey) &&
                                query.queryKey.length > 0 &&
                                typeof query.queryKey[0] === "string"
                            ) {
                                return clearCacheOnCalculateAndSave.includes(
                                    query.queryKey[0],
                                );
                            }
                            return false;
                        },
                        type: "all",
                    });

                    props?.onClose();
                }
            });
        },
    });

    // USE EFFECTS

    // When pop-up is opened set the data associated to the calculation (if there is any)
    useEffect(() => {
        const calculations: IEditStageCalculationViewModel[] =
            getValues("Calculations") ?? [];

        // THIS CODE ASSUMES THAT EVERY CALCULATION LIST FOR A STAGE WILL HAVE CALCULATIONS WITH UNIQUE CalculationTypeId
        const isInCurrentRow = (scheduleId: number | null) => {
            const isMatch = scheduleId === treatmentScheduleStageId;
            return isMatch;
        };

        const isInCurrentColumn = (calcTypeId: CalculationTypeId) => {
            const isMatch =
                Formulas[
                    FormulaCalculateColumnInfo[
                        columnName
                    ] as FormulaCalculateScheduleStageTarget
                ][calcTypeId as CalculationTypeId] !== undefined;
            return isMatch;
        };

        const existingCalculation: IEditStageCalculationViewModel | undefined =
            calculations?.find((calc: IEditStageCalculationViewModel) => {
                const isMatch =
                    isInCurrentRow(
                        parseSafeStringIdToInt(calc.PumpScheduleStageId),
                    ) && isInCurrentColumn(calc.CalculationTypeId);
                return isMatch;
            });

        const newCalculationValue = {
            EntityIds: [] as number[],
            PumpScheduleStageId: treatmentScheduleStageId.toString(),
        } as IEditStageCalculationViewModel;
        setValue(
            "Calculation",
            // PumpScheduleStageId WILL be an integer, because you cannot edit a formula until you save after a copy operation (string PumpScheduleStageId is typed to
            // support the "Copy Schedule" functionality)
            existingCalculation ?? newCalculationValue,
        );
    }, [columnName, getValues, setValue, treatmentScheduleStageId]);

    const onClose = useCallback(() => {
        reset();
        props.onClose();
    }, [props, reset]);

    useHotkeys("enter", () => {
        if (
            calculation?.CalculationTypeId === undefined ||
            (calculation?.EntityIds?.length > 0 && !calculation?.EntityIds?.[0])
        )
            return;
        mutation.mutate();
    });

    const containerRef = useRef<HTMLDivElement | null>(null);

    // When the component mounts attach event listener for "Enter" key to individual components in the window
    useEffect(() => {
        const entityIds = calculation?.EntityIds ?? [];
        if (
            calculation?.CalculationTypeId === undefined ||
            (entityIds.length > 0 && !entityIds[0])
        )
            return;

        // Handle the keydown event
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                // blur the active element
                e.preventDefault();
                (e.currentTarget as HTMLInputElement)?.blur();
                if (!mutation.isPending) {
                    mutation.mutate();
                }
            }
        };
        // Input and dropdown elements
        const inputs: HTMLInputElement[] | undefined = [
            ...(containerRef.current?.getElementsByTagName("input") ?? []),
        ];
        const dropdowns: HTMLSpanElement[] | undefined = [
            ...(containerRef.current?.getElementsByTagName("span") ?? []),
        ];
        inputs.forEach((i) => i.addEventListener("keydown", onKeyDown));
        dropdowns.forEach((d) => d.addEventListener("keydown", onKeyDown));

        return () => {
            inputs.forEach((i) => i.removeEventListener("keydown", onKeyDown));
            dropdowns.forEach((d) =>
                d.removeEventListener("keydown", onKeyDown),
            );
        };
    }, [calculation?.CalculationTypeId, calculation?.EntityIds, mutation]);

    const calcHasEntityDropDown = useMemo(() => {
        if (calculation?.CalculationTypeId) {
            const calcType = getCalculationType(calculation?.CalculationTypeId);
            return (
                hasEntityByCalcType(calcType) && !calcType.isSimpleWellConfCalc
            );
        }
        return false;
    }, [calculation?.CalculationTypeId]);

    const entityIds = calculation?.EntityIds ?? [];
    const isDisabled =
        calculation?.CalculationTypeId === undefined ||
        (calcHasEntityDropDown && (entityIds.length === 0 || !entityIds[0]));
    return (
        <Dialog
            title={`${resources.TreatmentCalculate}: ${TargetToName[target]}`}
            onClose={onClose}
            width={550}
            height={270}
            themeColor={"primary"}
        >
            <div ref={containerRef}>
                <ValidationSummary externalErrors={errors} />
                <div className={"h-70 relative flex-col"}>
                    {/* Show a drop down that get a list of formulas applicable to the column */}
                    <StandardDropDownList
                        name={"Calculation.CalculationTypeId"}
                        data={getFormulas(target)}
                        label={resources.Formula}
                        byField={"Id"}
                        required={true}
                        onChange={(val) => {
                            setValue(
                                "Calculation",
                                !val
                                    ? {
                                          ...calculation,
                                          PumpScheduleStageId:
                                              treatmentScheduleStageId,
                                          EntityIds: [] as number[],
                                      }
                                    : {
                                          ...calculation,
                                          PumpScheduleStageId:
                                              treatmentScheduleStageId,
                                          CalculationTypeId: val.Id,
                                          EntityIds: [] as number[],
                                      },
                            );
                        }}
                    />
                    <div className={"h-50 w-full py-4"}>
                        <h1 className={"mb-3 text-xl font-bold"}>
                            {resources.Fields}
                        </h1>
                        <div className={"grid grid-cols-4 gap-4"}>
                            <Fields
                                scheduleStageId={treatmentScheduleStageId}
                            />
                        </div>
                    </div>
                    <div className={"flex justify-end"}>
                        <StandardButton
                            text={resources.CalculateAndSave}
                            onClick={mutation.mutate}
                            disabled={isDisabled}
                        />
                    </div>
                </div>
                {mutation.isPending && <LoadingOverlay />}
            </div>
        </Dialog>
    );
};

export default FormulaCalculatePopUp;

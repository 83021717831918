import * as React from "react";
import {
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-all";
import { GridFilterCellProps } from "@progress/kendo-react-grid";

import { resources } from "utils/resources";

const yesNoData = ["", resources.Yes, resources.No];

export const BooleanFilterCell = (props: GridFilterCellProps) => {
    const defaultItem = "";

    let hasValue: any = (value: any) => Boolean(value && value !== defaultItem);

    const onChange = (event: DropDownListChangeEvent) => {
        hasValue = hasValue(event.target.value);
        props.onChange({
            value: hasValue ? event.target.value : "",
            operator: hasValue ? "eq" : "",
            syntheticEvent: event.syntheticEvent,
        });
    };

    return (
        <div className="k-filtercell">
            <DropDownList
                style={{ width: "100%" }}
                data={yesNoData}
                value={props.value || defaultItem}
                onChange={onChange}
                className="bg-white bg-none"
            />
        </div>
    );
};

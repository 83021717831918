import React from "react";

import { IAddressDetailsViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/IAddressDetailsViewModel";

import { resources } from "utils/resources";

type Props = {
    visible: boolean;
    addressDetails?: IAddressDetailsViewModel;
};

export const DataRow: React.FC<{ label: string; value?: string }> = ({
    label,
    value,
}) =>
    value ? (
        <div className={"flex w-full flex-row "}>
            <dt className="w-24 flex-none">{label}</dt>
            <dd className="break-all">{value}</dd>
        </div>
    ) : (
        <></>
    );

const AddressDetails: React.FC<Props> = ({
    visible,
    addressDetails,
}: Props) => {
    return visible ? (
        <dl className="text-sm">
            <DataRow
                label={resources.Address1}
                value={addressDetails?.StreetAddress1}
            />
            <DataRow
                label={resources.Address2}
                value={addressDetails?.StreetAddress2}
            />
            <DataRow label={resources.City} value={addressDetails?.City} />
            <DataRow
                label={
                    addressDetails?.ProvinceStateLabel ??
                    resources.ProvinceState
                }
                value={addressDetails?.ProvinceState}
            />
            <DataRow
                label={
                    addressDetails?.PostalZipCodeLabel ?? resources.PostalCode
                }
                value={addressDetails?.PostalZipCode}
            />
            <DataRow label={"Country"} value={addressDetails?.Country} />
        </dl>
    ) : (
        <></>
    );
};

export default AddressDetails;

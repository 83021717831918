import React from "react";
import styled from "styled-components";

import AddFile, { AddFileProps } from "./ToolbarElements/AddFile";
import AddNew, { AddNewProps } from "./ToolbarElements/AddNew";
import { ConfigureGridProps } from "./ToolbarElements/ConfigureGrid";
import DisplayNotInUse, {
    DisplayNotInUseProps,
} from "./ToolbarElements/DisplayNotInUse";
import EditNotes from "./ToolbarElements/EditNotes";
import EstimatedDepth, {
    EstimatedDepthProps,
} from "./ToolbarElements/EstimatedDepth";
import LoadDefaults, {
    LoadDefaultsProps,
} from "./ToolbarElements/LoadDefaults";
import ToggleReadOnly from "./ToolbarElements/ToggleReadOnly";

export interface BaseProps {
    onClick?: () => void;
    isVisible?: boolean;
}

export type CustomToolbarProps = {
    onLoading?: (isLoading: boolean) => void;
    addNewProps?: AddNewProps;
    loadDefaultsProps?: LoadDefaultsProps;
    displayNotInUseProps?: DisplayNotInUseProps;
    copyDownProps?: BaseProps;
    importIntervalsProps?: BaseProps;
    configureGridProps?: ConfigureGridProps;
    toggleReadOnlyProps?: BaseProps;
    estimatedDepthProps?: EstimatedDepthProps;
    addNewFileProps?: AddFileProps;
    editNotesProps?: BaseProps;
};

const StandardEditGridToolbar: React.FC<CustomToolbarProps> = ({
    addNewProps = { isVisible: true },
    loadDefaultsProps = { isVisible: false },
    displayNotInUseProps = { isVisible: false },
    toggleReadOnlyProps = { isVisible: false },
    estimatedDepthProps = { isVisible: false },
    addNewFileProps = { isVisible: false },
    editNotesProps = { isVisible: false },
}: CustomToolbarProps) => {
    return (
        <ToolbarContainer>
            <div className={"left"}>
                {addNewProps?.isVisible && <AddNew {...addNewProps} />}
                {addNewFileProps?.isVisible && <AddFile {...addNewFileProps} />}
                {loadDefaultsProps?.isVisible && (
                    <LoadDefaults {...loadDefaultsProps} />
                )}
                {displayNotInUseProps?.isVisible && (
                    <DisplayNotInUse {...displayNotInUseProps} />
                )}
                {editNotesProps?.isVisible && <EditNotes {...editNotesProps} />}

                {toggleReadOnlyProps?.isVisible && (
                    <ToggleReadOnly {...toggleReadOnlyProps} />
                )}
            </div>
            <div className={"right"}>
                <EstimatedDepth {...estimatedDepthProps} />
            </div>
        </ToolbarContainer>
    );
};

const ToolbarContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;

    & button {
        margin: 0;
    }

    & .left,
    .right {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        width: 100%;
    }

    & .right {
        justify-content: right;
    }
`;

export default StandardEditGridToolbar;

import React, { memo, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Tooltip } from "@progress/kendo-react-all";
import { createColumnHelper } from "@tanstack/react-table";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { ICompletionTechnologyViewModel } from "types/generated/Calfrac/Jet/Web/Models/WellConfig/ICompletionTechnologyViewModel";
import { IEditProgramCasingViewModel } from "types/generated/Calfrac/Jet/Web/Models/WellConfig/IEditProgramCasingViewModel";
import { IEditProgramLinerViewModel } from "types/generated/Calfrac/Jet/Web/Models/WellConfig/IEditProgramLinerViewModel";
import { IEditProgramTubingViewModel } from "types/generated/Calfrac/Jet/Web/Models/WellConfig/IEditProgramTubingViewModel";

import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import IntegerCell from "components/Cells/IntegerCell";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import TextCell from "components/Cells/TextCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type WellConfigTableProps = {
    name: string;
    renderHash?: string;
};

const columnHelper = createColumnHelper<any>();

const WellConfig: React.FC<WellConfigTableProps> = ({ name, renderHash }) => {
    const model = useWatch();
    const data = useWatch({ name: name });
    const { setValue, getValues } = useFormContext();
    const units = useUnits();

    const { program, programId, isPageEditable } = useProgramParams();

    const { field, altField, header } = useMemo(() => {
        // Used to retrieve the name of the corresponding field
        switch (name) {
            case "CompletionTechnologies":
                return {
                    field: "CompletionTechnology",
                    header: resources.CompletionTechnology,
                };
            case "Casings":
                return {
                    field: "Casing",
                    header: resources.CasingWithDescription,
                };
            case "Liners":
                return {
                    field: "Liner",
                    header: resources.LinerWithDescription,
                };
            case "Tubings":
                return {
                    field: "Tubing",
                    header: resources.TubingWithDescription,
                };
            case "OpenHoles":
                return {
                    field: "OpenHole",
                    header: resources.OpenHoleWithDescription,
                };
            case "CoiledTubings":
                return {
                    field: "CoiledTubing",
                    header: resources.CoiledTubingWithDescription,
                };
            case "DownholeItems":
                return {
                    field: "DownholeItem",
                    altField: "Name",
                    header: resources.Name,
                };
            case "BallSealers":
                return { field: "BallSealer", header: resources.BallSealer };
            default:
                return { field: name, header: "" };
        }
    }, [name]);

    const defaultColumns = useMemo(
        () => [
            columnHelper.display({
                id: "Delete",
                size: 10,
                maxSize: 10,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row?.[altField ?? field], {
                id: `${altField ?? field}`,
                header: header,
                size: 530,
                cell: (props) =>
                    name === "DownholeItems" ? (
                        <TextCell {...props} />
                    ) : (
                        <DropDown {...props} />
                    ),
            }),
            columnHelper.accessor((row) => row.Flush, {
                id: "Flush",
                header: `${resources.Overflush} ${units.volumeWetLargeUnit}`,
                size: 900,
                cell: (props) => (
                    <Tooltip
                        className={"align-center z-20 flex justify-center"}
                        anchorElement={"target"}
                        position={"right"}
                    >
                        <span
                            title={
                                resources.NegativeIndicatesUnderFlushWhilePositiveIndicatesOverFlush
                            }
                        >
                            <FormattedNumericCell {...props} />
                        </span>
                    </Tooltip>
                ),
            }),
            columnHelper.accessor((row) => row?.OpenHole?.Diameter, {
                id: `OpenHole.Diameter`,
                header: `${resources.Diameter} ${units.thicknessUnit}`,
                size: 500,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row?.Depth, {
                id: `Depth`,
                header: `${resources.Depth} ${units.depthUnit}`,
                size: 900,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row?.Quantity, {
                id: `Quantity`,
                header: resources.Quantity,
                size: 900,
                cell: IntegerCell,
            }),
            columnHelper.accessor((row) => row?.[field]?.OutsideDiameter, {
                id: `${field}.OutsideDiameter`,
                header: `${resources.OD} ${units.thicknessUnit}`,
                size: 120,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row?.[field]?.Weight, {
                id: `${field}.Weight`,
                header: `${resources.Weight} ${units.weightPerLengthUnit}`,
                size: 120,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row?.CoiledTubing?.WallThickness, {
                id: "CoiledTubing.WallThickness",
                header: `${resources.WallThickness} ${units.thicknessUnit}`,
                size: 120,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row?.MaxWallThickness, {
                id: "MaxWallThickness",
                header: `${resources.MaxWallThickness} ${units.thicknessUnit}`,
                size: 150,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row?.Volume, {
                id: "Volume",
                header: `${resources.Volume} ${units.volumeWetLargeUnit}`,
                size: 80,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row?.[field]?.Grade, {
                id: `${field}.Grade`,
                header: resources.Grade,
                size: 120,
                cell: TextReadOnly,
            }),
            columnHelper.accessor((row) => row?.[field]?.InsideDiameter, {
                id: `${field}.InsideDiameter`,
                header: `${resources.ID} ${units.thicknessUnit}`,
                size: 120,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row?.InsideDiameter, {
                id: `InsideDiameter`,
                header: `${resources.ID} ${units.thicknessUnit}`,
                size: 60,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row?.[field]?.Capacity, {
                id: `${field}.Capacity`,
                header: `${resources.Capacity} ${units.capacityUnit}`,
                size: 120,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor(
                (row) => row?.[field]?.InternalYieldPressure,
                {
                    id: `${field}.InternalYieldPressure`,
                    header: `${resources.IntYield} ${units.pressureLargeUnit}`,
                    size: 120,
                    cell: NumericReadOnly,
                },
            ),
            columnHelper.accessor((row) => row?.StringLength, {
                id: "StringLength",
                header: `${resources.Length} ${
                    program?.CountryId === CountryId.Argentina
                        ? units.depthUnit
                        : units.lengthLargeUnit
                }`,
                size: 80,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.Top, {
                id: "Top",
                header: `${resources.Top} ${units.depthUnit}`,
                size: 120,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.Bottom, {
                id: "Bottom",
                header: `${resources.Bottom} ${units.depthUnit}`,
                size: 120,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.FloatShoe, {
                id: "FloatShoe",
                header: `${resources.FloatShoe} ${units.lengthLargeUnit}`,
                size: 120,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.FloatCollar, {
                id: "FloatCollar",
                header: `${resources.FloatCollar} ${units.lengthLargeUnit}`,
                size: 120,
                cell: FormattedNumericCell,
            }),
        ],
        [
            altField,
            field,
            header,
            units.thicknessUnit,
            units.depthUnit,
            units.weightPerLengthUnit,
            units.volumeWetLargeUnit,
            units.capacityUnit,
            units.pressureLargeUnit,
            units.lengthLargeUnit,
            program?.CountryId,
            name,
        ],
    );

    // Constants
    const lastCasing = model.Casings?.[model.Casings?.length - 1]?.Bottom ?? 0;
    const newCasingRecord: IEditProgramCasingViewModel = useMemo(
        () =>
            ({
                Top: lastCasing,
            }) as IEditProgramCasingViewModel,
        [lastCasing],
    );

    const lastLiner = model.Liners?.[model.Liners?.length - 1]?.Bottom ?? 0;
    const newLinerRecord: IEditProgramLinerViewModel = useMemo(
        () =>
            ({
                Top: lastLiner,
            }) as IEditProgramLinerViewModel,
        [lastLiner],
    );

    const lastTubing = model.Tubings?.[model.Tubings?.length - 1]?.Bottom ?? 0;
    const newTubingRecord: IEditProgramTubingViewModel = useMemo(
        () =>
            ({
                Top: lastTubing,
            }) as IEditProgramTubingViewModel,
        [lastTubing],
    );

    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);

    // Helpers
    const defaultRecord = useMemo(() => {
        switch (name) {
            case "Casings":
                return newCasingRecord;
            case "Liners":
                return newLinerRecord;
            case "Tubings":
                return newTubingRecord;
            default:
                return {};
        }
    }, [name, newCasingRecord, newLinerRecord, newTubingRecord]);

    const columnVisibility = () => {
        const showDuplicatedColumns =
            field !== "CompletionTechnology" &&
            field !== "OpenHole" &&
            field !== "DownholeItem" &&
            field !== "BallSealer";
        const visibility: { [key: string]: boolean } = {
            CompletionTechnology: name === "CompletionTechnologies",
            Flush: name === "CompletionTechnologies" && !isCtan,
            Casing: name === "Casings",
            Liner: name === "Liners",
            Tubing: name === "Tubings",
            OpenHole: name === "OpenHoles",
            CoiledTubing: name === "CoiledTubings",
            Name: name === "DownholeItems",
            Depth: name === "DownholeItems",
            StringLength: name === "CoiledTubings",
            BallSealer: name === "BallSealers",
            Quantity: name === "BallSealers",
            "CoiledTubing.WallThickness": name === "CoiledTubings",
            MaxWallThickness: name === "CoiledTubings",
            Volume: name === "CoiledTubings",
            "OpenHole.Diameter": name === "OpenHoles",
            Top:
                name === "Casings" ||
                name === "Liners" ||
                name === "Tubings" ||
                name === "OpenHoles",
            Bottom:
                name === "Casings" ||
                name === "Liners" ||
                name === "Tubings" ||
                name === "OpenHoles",
            InsideDiameter: field === "DownholeItem",
            FloatShoe: name === "Casings" && isCement,
            FloatCollar: name === "Casings" && isCement,
        };
        visibility[`${field}.OutsideDiameter`] = showDuplicatedColumns;
        visibility[`${field}.Weight`] =
            showDuplicatedColumns && name !== "CoiledTubings";
        visibility[`${field}.Grade`] = showDuplicatedColumns;
        visibility[`${field}.InsideDiameter`] = showDuplicatedColumns;
        visibility[`${field}.Capacity`] =
            showDuplicatedColumns || name === "OpenHoles";
        visibility[`${field}.InternalYieldPressure`] = showDuplicatedColumns;
        return visibility;
    };

    const columnOrder = useMemo(() => {
        return [
            "Delete",
            `${altField ?? field}`,
            `${field === "CoiledTubing" ? `${field}.Grade` : ""}`,
            "Flush",
            "OpenHole.Diameter",
            "Depth",
            "Quantity",
            `${field}.OutsideDiameter`,
            `${field}.Weight`,
            "CoiledTubing.WallThickness",
            "MaxWallThickness",
            "Volume",
            `${field !== "CoiledTubing" ? `${field}.Grade` : ""}`,
            `${field}.InsideDiameter`,
            `${field}.Capacity`,
            `${field}.InternalYieldPressure`,
            "StringLength",
            "Top",
            "Bottom",
        ];
    }, [altField, field]);

    const meta = useMemo(() => {
        return {
            canDelete: isPageEditable,
            disabled: !isPageEditable,
            name: name,
            pageName: "WellConfig",
            urls: {
                CompletionTechnology: `/api/WellConfig/CompletionTechnology?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                Casing: `/api/WellConfig/Casing?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                Tubing: `/api/WellConfig/Tubing?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                OpenHole: `/api/WellConfig/OpenHole?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                CoiledTubing: `/api/WellConfig/CoiledTubing?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                BallSealer: `/api/WellConfig/BallSealer?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                Liner: `/api/WellConfig/Casing?programId=${programId}&countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
            },
            updateData: (
                rowIndex: number,
                columnId: string,
                value: unknown,
            ) => {
                const valueName = `${name}.${rowIndex}.${columnId}`;
                const lastValue = getValues(valueName);
                if (lastValue === value) return;

                console.log(value);

                // Skip page index reset until after next rerender
                setValue(valueName, value, { shouldDirty: true });

                // Special case for completion technology
                if (name === "CompletionTechnologies" && columnId === field) {
                    setValue(
                        `${name}.${rowIndex}.Flush`,
                        (value as ICompletionTechnologyViewModel).DefaultFlush,
                    );
                }
            },
            deleteRecord: (rowId: number) => {
                if (
                    !window.confirm(
                        resources.AreYouSureYouWantToDeleteThisRecord,
                    )
                ) {
                    return;
                }
                // @ts-ignore
                const newData = data.filter((e) => e.Id !== rowId);
                setValue(name, newData, { shouldDirty: true });
            },
            updateNumericCellData: (
                rowIndex: number,
                columnId: string,
                value: unknown,
            ) => {
                const valueName = `${name}.${rowIndex}.${columnId}`;
                const lastValue = getValues(valueName);
                if (lastValue === value) return;

                // Skip page index reset until after next rerender
                setValue(valueName, value, { shouldDirty: true });
            },
        };
    }, [
        data,
        field,
        getValues,
        isPageEditable,
        name,
        program?.CountryId,
        program?.ServiceLineId,
        programId,
        setValue,
    ]);

    return (
        <BaseTable
            name={name}
            columns={defaultColumns}
            defaultRecord={defaultRecord}
            columnOrder={columnOrder}
            columnVisibility={columnVisibility()}
            meta={meta}
            renderHash={renderHash}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default memo(WellConfig);

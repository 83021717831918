//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CountryId {
	Canada = 1,
	UnitedStates = 2,
	Argentina = 3,
	Mexico = 4,
	Russia = 5
}

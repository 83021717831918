import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { serialize } from "object-to-formdata";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";
import { RequestProgramTypeId } from "types/generated/Calfrac/Jet/Core/Models/RequestProgramTypeId";
import { RequestStatusId } from "types/generated/Calfrac/Jet/Core/Models/RequestStatusId";
import { ServiceLineId } from "types/generated/Calfrac/Jet/Core/Models/ServiceLineId";

import { IDefaultTreatmentObjectiveViewModel } from "types/generated/Calfrac/Jet/Web/Models/Request/IDefaultTreatmentObjectiveViewModel";
import { IEditRequestViewModel } from "types/generated/Calfrac/Jet/Web/Models/Request/IEditRequestViewModel";
import { IRequestProgramAssigneeViewModel } from "types/generated/Calfrac/Jet/Web/Models/Request/IRequestProgramAssigneeViewModel";
import { IRequestProgramTreatmentObjectiveViewModel } from "types/generated/Calfrac/Jet/Web/Models/Request/IRequestProgramTreatmentObjectiveViewModel";
import { INamedItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/INamedItemViewModel";
import { IEditProgramActionViewModel } from "types/generated/Calfrac/Jet/Web/Models/Task/IEditProgramActionViewModel";

import { PrimaryButton } from "components/Buttons";
import CheckBox from "components/Cells/CheckBox";
import DateCell from "components/Cells/DateCell";
import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import GridDropDownProgramNumbers from "components/Cells/DropDownProgramNumbers";
import HyperLinkCell from "components/Cells/HyperlinkCell";
import MultiSelectCell from "components/Cells/MultiSelectCell";
import NtsDLSCell from "components/Cells/NtsDLSCell";
import TextCell from "components/Cells/TextCell";
import TextReadOnly from "components/Cells/TextReadOnly";
import { RecordType } from "components/Tables/BaseTable/Inner/InnerTable";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram } from "hooks/useProgramParams";

import { fetchJET, JetApiUrls, saveJET } from "utils/fetchJet";
import { setFormErrors } from "utils/helpers";
import { resources } from "utils/resources";

type ProgramsTableProps = {
    name: string;
    setIsAccepting: (val: boolean) => void;
    disableAddNewProgram?: boolean;
    shouldBeAbleToAcceptAll?: boolean;
};

const columnHelper = createColumnHelper<any>();

const NewProgramRegex = new RegExp(/^NEW-/);

// This breaks down the default assignees by country and service line.
const Default_Assignee_Ids: {
    [key in CountryId]: { [key in ServiceLineId]: number };
} = {
    [CountryId.Canada]: {
        [ServiceLineId.Fracturing]: 1000001,
        [ServiceLineId.Pumping]: 1000002,
        [ServiceLineId.CoiledTubing]: 1000003,
        [ServiceLineId.Cement]: 0,
        [ServiceLineId.Ngc]: 0,
    },
    [CountryId.UnitedStates]: {
        [ServiceLineId.Fracturing]: 1000004,
        [ServiceLineId.Pumping]: 1000004,
        [ServiceLineId.CoiledTubing]: 0,
        [ServiceLineId.Cement]: 0,
        [ServiceLineId.Ngc]: 0,
    },
    [CountryId.Argentina]: {
        [ServiceLineId.Fracturing]: 1000005,
        [ServiceLineId.Pumping]: 1000008,
        [ServiceLineId.CoiledTubing]: 1000009,
        [ServiceLineId.Cement]: 1000010,
        [ServiceLineId.Ngc]: 0,
    },
    [CountryId.Russia]: {
        [ServiceLineId.Fracturing]: 0,
        [ServiceLineId.Pumping]: 0,
        [ServiceLineId.CoiledTubing]: 0,
        [ServiceLineId.Cement]: 0,
        [ServiceLineId.Ngc]: 0,
    },
    [CountryId.Mexico]: {
        [ServiceLineId.Fracturing]: 0,
        [ServiceLineId.Pumping]: 0,
        [ServiceLineId.CoiledTubing]: 0,
        [ServiceLineId.Cement]: 0,
        [ServiceLineId.Ngc]: 0,
    },
};

// Helper Components
const AcceptProgramButton = ({
    row,
    setIsAccepting,
}: CellContext<any, any> & { setIsAccepting: (val: boolean) => void }) => {
    const navigate = useNavigate();
    const { formState, setError } = useFormContext();

    // Don't render the button
    if (
        !(
            row.original?.Id &&
            !isNaN(row.original?.Id) &&
            !NewProgramRegex.test(`${row.original.dataItem?.Id}`) &&
            !row.original.NewProgramId
        )
    ) {
        return <></>;
    }

    return (
        <PrimaryButton
            onClick={(event) => {
                if (formState.isDirty) {
                    alert(resources.YouHaveUnsavedChanges);
                    return;
                }

                event.preventDefault();
                event.stopPropagation();
                setIsAccepting(true);
                saveJET(
                    "/api/Request/AcceptRequestProgramWithModel",
                    {},
                    serialize({ ...row.original }, { indices: true }),
                ).then((res) => {
                    const data = res as Record<string, any>;
                    // If there are server validation errors
                    if (data.Errors) {
                        setIsAccepting(false);
                        setFormErrors(data.Errors, setError);
                        // throw so onSuccess isn't hit
                        throw Error("Error was returned from server");
                    }
                    navigate(0);
                });
            }}
        >
            {resources.Accept}
        </PrimaryButton>
    );
};

const ProgramsTable: React.FC<ProgramsTableProps> = ({
    name,
    setIsAccepting,
    disableAddNewProgram,
    shouldBeAbleToAcceptAll,
}: ProgramsTableProps) => {
    const { isPageEditable } = useProgram();

    const { requestId = "", countryId = "" } = useParams<{
        requestId: string;
        countryId: string;
    }>();

    const model = useWatch() as IEditRequestViewModel;
    const defaultTreatmentObjectives = useWatch({
        name: "DefaultTreatmentObjectives",
    }) as IDefaultTreatmentObjectiveViewModel[];

    const isCreating = Number(requestId) === 0;

    const { setValue } = useFormContext();

    const editable = isCreating || !model?.IsReadOnly;

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 20,
                maxSize: 20,
                cell: (props) =>
                    !editable || props.row.original?.NewProgramId > 0 ? (
                        <></>
                    ) : (
                        <DeleteButton {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.display({
                id: "AcceptProgram",
                size: 60,
                maxSize: 60,
                header: resources.AcceptProgram,
                cell: (props) => (
                    <AcceptProgramButton
                        {...props}
                        setIsAccepting={setIsAccepting}
                    />
                ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ProgramNumber, {
                id: "ProgramNumber",
                size: 125,
                maxSize: 125,
                header: resources.ProgramNumber,
                cell: (props) =>
                    props.row.original.NewProgramId ? (
                        <HyperLinkCell
                            {...props}
                            readOnly={true}
                            to={`/Program/EditProgramData/${props.row.original.NewProgramId}`}
                        />
                    ) : (
                        <TextReadOnly {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.RequestProgramType, {
                id: "RequestProgramType",
                size: 80,
                maxSize: 80,
                header: resources.RequestType,
                cell: (props) =>
                    RequestSubmittedAndSavedOrAccepted(props) ||
                    model.RequestStatusId === RequestStatusId.Closed ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <DropDown {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ServiceLine, {
                id: "ServiceLine",
                size: 100,
                maxSize: 100,
                header: resources.ProgramType,
                cell: (props) =>
                    RequestSubmittedAndSavedOrAccepted(props) ||
                    model.RequestStatusId === RequestStatusId.Closed ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <DropDown {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.IsPad, {
                id: "IsPad",
                size: 10,
                maxSize: 10,
                header: resources.Pad.toUpperCase(),
                cell: (props) => (
                    <CheckBox
                        {...props}
                        readOnly={
                            RequestAccepted(props) ||
                            ProgramNumberGenerated(props) ||
                            model.RequestStatusId === RequestStatusId.Closed
                        }
                    />
                ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ProgramType, {
                id: "ProgramType",
                size: 100,
                maxSize: 100,
                header: resources.ProjectType,
                cell: (props) =>
                    RequestAccepted(props) ||
                    model.RequestStatusId === RequestStatusId.Closed ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <DropDown {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.AssignedToServiceLine, {
                id: "AssignedToServiceLine",
                size: 100,
                maxSize: 100,
                header: resources.ServiceLine,
                cell: (props) =>
                    RequestAccepted(props) ||
                    model.RequestStatusId === RequestStatusId.Closed ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <DropDown {...props} />
                    ),
            }),
            columnHelper.accessor((row) => row.OriginalProgram, {
                id: "OriginalProgram",
                size: 100,
                maxSize: 100,
                header: resources.OriginalProgram,
                cell: (props) =>
                    RequestSubmittedAndSavedOrAccepted(props) ||
                    model.RequestStatusId === RequestStatusId.Closed ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <GridDropDownProgramNumbers {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.IsNts, {
                id: "IsNts",
                size: 10,
                maxSize: 10,
                header: resources.NTS,
                cell: (props) => (
                    <CheckBox
                        {...props}
                        readOnly={
                            RequestAccepted(props) ||
                            model.RequestStatusId === RequestStatusId.Closed
                        }
                    />
                ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.UwiGridDisplay, {
                id: "UwiGridDisplay",
                size: 170,
                maxSize: 170,
                header: resources.Uwi,
                cell: (props) => (
                    <NtsDLSCell
                        {...props}
                        readOnly={
                            model.RequestStatusId === RequestStatusId.Closed
                        }
                    />
                ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.WellName, {
                id: "WellName",
                size: 80,
                maxSize: 80,
                header: resources.WellName,
                cell: (props) =>
                    RequestRevisedOrAccepted(props) ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <TextCell {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Field, {
                id: "Field",
                size: 100,
                maxSize: 100,
                header: resources.Field,
                cell: (props) =>
                    RequestRevisedOrAccepted(props) ||
                    RequestPumpScheduleOrAccepted(props) ||
                    model.RequestStatusId === RequestStatusId.Closed ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <DropDown {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Formation, {
                id: "Formation",
                size: 100,
                maxSize: 100,
                header: resources.Formation,
                cell: (props) =>
                    RequestRevisedOrAccepted(props) ||
                    RequestPumpScheduleOrAccepted(props) ||
                    model.RequestStatusId === RequestStatusId.Closed ? (
                        <TextReadOnly {...props} />
                    ) : (
                        <DropDown {...props} />
                    ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.TreatmentObjectives, {
                id: "TreatmentObjectives",
                size: 230,
                maxSize: 230,
                header: resources.TreatmentObjective,
                cell: (props) => (
                    <MultiSelectCell
                        {...props}
                        readOnly={
                            RequestRevisedOrAccepted(props) ||
                            RequestPumpScheduleOrAccepted(props) ||
                            model.RequestStatusId === RequestStatusId.Closed
                        }
                        url={
                            props.row.original.ServiceLine.Id > 0
                                ? `/api/Request/TreatmentObjectives?programTypeId=${props.row.original.ServiceLine.Id}`
                                : undefined
                        }
                    />
                ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Assignees, {
                id: "Assignees",
                size: 280,
                maxSize: 280,
                header: resources.AssignTo,
                cell: (props) => (
                    <MultiSelectCell
                        {...props}
                        readOnly={
                            RequestAccepted(props) ||
                            model.RequestStatusId === RequestStatusId.Closed
                        }
                    />
                ),
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.EnteredBy, {
                id: "EnteredBy",
                size: 100,
                maxSize: 100,
                header: resources.AcceptedBy,
                cell: TextReadOnly,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.EnteredOn, {
                id: "EnteredOn",
                size: 100,
                maxSize: 100,
                header: resources.AcceptedOn,
                cell: (props) => <DateCell {...props} readOnly={true} />,
                enableHiding: true,
            }),
        ];
    }, [editable, model.RequestStatusId, setIsAccepting]);

    const cachedAssignees = useQuery<INamedItemViewModel[]>({
        queryKey: [`/api/Request/Assignees`, countryId],
        queryFn: () => {
            return fetchJET(
                `/api/Request/Assignees?countryId=${countryId}` as JetApiUrls,
            );
        },
    });

    const afterUpdateData = (columnId: string, dataItem: any) => {
        const programIdx = model?.Programs?.findIndex(
            (p) => p.Id === dataItem.Id,
        );

        // UPDATE SERVICE LINE
        if (columnId === "ServiceLine") {
            setValue(
                `Programs.${programIdx}.ServiceLineId`,
                dataItem.ServiceLine?.Id,
            );
            setValue(
                `Programs.${programIdx}.AssignedToServiceLine`,
                dataItem.ServiceLine,
            );
            setValue(
                `Programs.${programIdx}.AssignedToServiceLineId`,
                dataItem.ServiceLine?.Id,
            );
            dataItem.AssignedToServiceLine = dataItem.ServiceLine;
            dataItem.AssignedToServiceLineId = dataItem.ServiceLine?.Id;
        }

        // UPDATE DEFAULT TREATMENT OBJECTIVES
        if (
            columnId === "AssignedToServiceLine" ||
            columnId === "ServiceLine"
        ) {
            const defaultTreatmentObjectiveList =
                defaultTreatmentObjectives?.find(
                    (o) =>
                        dataItem.ServiceLine?.Id ===
                            dataItem.AssignedToServiceLine?.Id &&
                        o.ServiceLineId === dataItem.ServiceLine?.Id,
                )?.TreatmentObjectives ?? [];
            if (defaultTreatmentObjectiveList.length > 0) {
                setValue(
                    `Programs.${programIdx}.TreatmentObjectives`,
                    defaultTreatmentObjectiveList,
                );
            }
        }

        // UPDATE ASSIGNEES
        if (
            columnId === "ServiceLine" ||
            columnId === "AssignedToServiceLine" ||
            columnId === "RequestProgramType"
        ) {
            const serviceLineId =
                (dataItem?.AssignedToServiceLine?.Id as ServiceLineId) ?? null;
            const requestProgramTypeId = dataItem?.RequestProgramType?.Id;

            const name = `Programs.${programIdx}.Assignees`;

            const nextAssignees: IRequestProgramAssigneeViewModel[] = [];

            switch (requestProgramTypeId as RequestProgramTypeId) {
                case RequestProgramTypeId.PriceRevision:
                    // On a price revision we set the assignee to the selected account manager
                    const accountManager = cachedAssignees?.data?.find(
                        (o) => o.Id === model.AccountManagerId,
                    );
                    if (accountManager) {
                        nextAssignees.push(
                            accountManager as unknown as IRequestProgramAssigneeViewModel,
                        );
                    }
                    break;
                case RequestProgramTypeId.PumpScheduleTool:
                    if (model.EnteredById && model.EnteredBy) {
                        // On a pump schedule tool we set the assignee to the "Entered By" user
                        nextAssignees.push({
                            Id: model.EnteredById,
                            Name: model.EnteredBy,
                            IsDefaultAssignee: false,
                            RequestProgramAssigneeId: 0,
                        } as unknown as IRequestProgramAssigneeViewModel);
                    }
                    break;
                case RequestProgramTypeId.Revision:
                case RequestProgramTypeId.New:
                    // Find the default assignee for the service line and the current country.
                    let assignee = cachedAssignees?.data?.find(
                        (o) =>
                            o.Id ===
                            Default_Assignee_Ids?.[
                                Number(countryId) as CountryId
                            ]?.[serviceLineId],
                    );
                    // If the assignee is found, then use the first assignee.
                    if (assignee) {
                        nextAssignees.push(
                            assignee as unknown as IRequestProgramAssigneeViewModel,
                        );
                    }
                    break;
            }
            setValue(name, nextAssignees, { shouldDirty: true });
            setValue(
                `Programs.${programIdx}.AssignedToServiceLineId`,
                serviceLineId,
            );
        }
    };

    // Used to gather the default program
    const defaultProgram = useQuery({
        queryKey: [`/api/Request/DefaultProgram?countryId=${countryId}`],
        queryFn: async () => {
            return await fetchJET<IEditProgramActionViewModel>(
                `/api/Request/DefaultProgram`,
                { countryId },
            );
        },
    });

    const defaultRecord = useMemo(() => {
        const lastProgram = model.Programs?.[model.Programs?.length - 1];
        if (!lastProgram) return defaultProgram?.data;

        const record = {
            ...defaultProgram?.data,
            ServiceLineId: lastProgram.ServiceLine?.Id,
            ServiceLine: lastProgram.ServiceLine,
            AssignedToServiceLine: lastProgram.ServiceLine,
            AssignedToServiceLineId: lastProgram.ServiceLineId,
            RequestProgramType: lastProgram.RequestProgramType,
            ProgramType: lastProgram.ProgramType,
            Field: lastProgram.Field,
            Formation: lastProgram.Formation,
            TreatmentObjectives: lastProgram.TreatmentObjectives,
            Assignees: lastProgram.Assignees,
            EnteredOn: null,
        };
        if (record.ServiceLine?.Id === ServiceLineId.Fracturing) {
            record.TreatmentObjectives = (defaultTreatmentObjectives?.find(
                (o) => o.ServiceLineId === ServiceLineId.Fracturing,
            )?.TreatmentObjectives ??
                []) as IRequestProgramTreatmentObjectiveViewModel[];
        }
        return record;
    }, [defaultProgram?.data, defaultTreatmentObjectives, model.Programs]);

    return (
        <BaseTable
            name={name}
            columns={defaultColumns}
            columnVisibility={{
                AcceptProgram:
                    model?.RequestStatusId === RequestStatusId.Submitted,
                ProgramNumber:
                    model?.RequestStatusId >= RequestStatusId.Submitted,
                IsNts: (Number(countryId) as CountryId) === CountryId.Canada,
                UwiGridDisplay:
                    (Number(countryId) as CountryId) === CountryId.Canada,
                WellName:
                    (Number(countryId) as CountryId) ===
                        CountryId.UnitedStates ||
                    (Number(countryId) as CountryId) === CountryId.Argentina,
                EnteredBy: model?.RequestStatusId >= RequestStatusId.Submitted,
                EnteredOn: model?.RequestStatusId >= RequestStatusId.Submitted,
            }}
            afterUpdateData={afterUpdateData}
            defaultRecord={defaultRecord as RecordType}
            disableAddNewRecord={disableAddNewProgram}
            shouldBeAbleToAcceptAll={shouldBeAbleToAcceptAll}
            setIsAccepting={setIsAccepting}
            meta={{
                name: name,
                disabled: !isPageEditable,
                canDelete: isPageEditable,
                urls: {
                    RequestProgramType: `/api/Request/RequestTypes`,
                    ServiceLine: `/api/Program/ServiceLine?countryId=${countryId}`,
                    ProgramType: `/api/Request/ProgramTypes`,
                    Field: `/api/WellData/Field?countryId=${countryId}`,
                    Formation: `/api/ReservoirProperties/Formation?countryId=${countryId}`,
                    Assignees: `/api/Request/Assignees?countryId=${countryId}`,
                    AssignedToServiceLine: `/api/Program/ServiceLine?countryId=${countryId}`,
                },
            }}
        />
    );
};

const RequestAccepted = (props: CellContext<any, any>): boolean => {
    return props.row.original?.NewProgramId > 0;
};

const ProgramNumberGenerated = (props: CellContext<any, any>): boolean => {
    return props.row.original?.GeneratedProgramNumber != null;
};

/**
 * Locks passed in component only under the following conditions:
 *  Record type is Revision OR Price Revision OR the row record has been accepted
 */
const RequestRevisedOrAccepted = (props: CellContext<any, any>): boolean => {
    return (
        props.row.original?.RequestProgramType?.Id ===
            RequestProgramTypeId.Revision ||
        props.row.original?.RequestProgramType?.Id ===
            RequestProgramTypeId.PriceRevision ||
        props.row.original.NewProgramId > 0
    );
};

/**
 * Locks passed in component only under the following conditions:
 *  Record type is Revision OR Price Revision OR the row record has been accepted
 */
const RequestPumpScheduleOrAccepted = (
    props: CellContext<any, any>,
): boolean => {
    return (
        props.row.original?.RequestProgramType?.Id ===
            RequestProgramTypeId.PumpScheduleTool ||
        props.row.original.NewProgramId > 0
    );
};

/**
 * Locks passed in component only under the following conditions:
 *  Request has been submitted AND
 *  new records added have been saved OR records have been accepted
 */
const RequestSubmittedAndSavedOrAccepted = (
    props: CellContext<any, any>,
): boolean => {
    const requestStatusId = useWatch({ name: "RequestStatusId" });

    const requestSubmitted = requestStatusId === RequestStatusId.Submitted;
    const requestAccepted = props.row.original?.NewProgramId > 0;
    const requestSaved =
        props.row.original?.Id &&
        !NewProgramRegex.test(`${props.row.original?.Id}`) &&
        !props.row.original?.NewProgramId;

    return (requestSubmitted && requestSaved) || requestAccepted;
};

export default ProgramsTable;

import React from "react";

import { resources } from "utils/resources";

type NoteSectionProps = {
    isVisible?: boolean;
    notes?: string[];
};

const NoteSection: React.FC<NoteSectionProps> = ({
    isVisible,
    notes,
}: NoteSectionProps) => {
    if (!isVisible) return <></>;

    return (
        <div className={"px-5 py-3"}>
            <p>{resources.NotesTitle}</p>
            <ul className={"ml-7 list-disc"}>
                {notes &&
                    Array.isArray(notes) &&
                    notes?.map((note, index) => (
                        <li key={index} className={"-mt-1 list-item"}>
                            {note}
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default NoteSection;

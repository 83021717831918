import React from "react";
import { CellContext } from "@tanstack/react-table";

import HyperlinkCell from "./HyperlinkCell";

const RenderActionCell = ({
    getValue,
    cell,
    className = "",
    to = "",
    ...props
}: CellContext<any, any> & { className?: string; to?: string }) => {
    return (
        <div className={"read-only px-2 py-1 text-sm"}>
            <HyperlinkCell
                getValue={getValue}
                cell={cell}
                to={to}
                className={className}
                {...props}
            />
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(RenderActionCell);

import { ErrorOption, FieldValues, UseFormSetError } from "react-hook-form";

import { CalculationTypeId } from "types/generated/Calfrac/Jet/Core/Models/CalculationTypeId";
import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";
import { ProgramWorkflowStatusId } from "types/generated/Calfrac/Jet/Core/Models/ProgramWorkflowStatusId";

import { IFieldErrors } from "types/generated/Calfrac/Jet/Web/Models/Shared/IFieldErrors";

// Used to determine if an element should be readonly
export const pageEditable = (
    isActive = false,
    workflowStatusId?: number,
): boolean => {
    if (!isActive === undefined || !workflowStatusId === undefined)
        return false;

    const hasEditableStatus = [
        ProgramWorkflowStatusId.InDesign,
        ProgramWorkflowStatusId.QueuedForReview,
        ProgramWorkflowStatusId.InReview,
        ProgramWorkflowStatusId.InApproval,
    ].includes(workflowStatusId ?? -1);

    return isActive && hasEditableStatus;
};

export const unitTypeVolume2 = (countryId: number | undefined) => {
    switch (countryId) {
        case CountryId.UnitedStates:
            return " (bbl)";
        default:
            return " (m³)";
    }
};

export const unitTypeMass = (countryId: number | undefined) => {
    switch (countryId) {
        case CountryId.UnitedStates:
            return " (lb)";
        default:
            return " (kg)";
    }
};

export const fieldErrorToErrorObject = (
    errors: Array<IFieldErrors>,
): { [key: string]: ErrorOption } => {
    let key, value;
    const result: {
        [key: string]: ErrorOption;
    } = {};
    errors.forEach((error: IFieldErrors) => {
        key = error.Field;
        value = {
            type: "server",
            message: error.Errors!.join("\n"),
        };
        result[key] = value;
    });
    return result;
};

// Used to map server errors into react-hook-form errors
export function setFormErrors(
    errors: Array<IFieldErrors>,
    setError: UseFormSetError<FieldValues>,
) {
    errors.forEach((error) => {
        setError(error.Field, {
            type: "server",
            message: error.Errors!.join("\n"),
        });
    });
}
export function getTextAreaRows(textArea: HTMLTextAreaElement | null): number {
    const lineHeight =
        textArea !== null
            ? Number(
                  window
                      .getComputedStyle(textArea)
                      ?.lineHeight?.replace("px", "") ?? 26,
              )
            : 26;
    return (textArea?.scrollHeight ?? lineHeight) / lineHeight;
}

export function getCalculationType(calculationTypeId: CalculationTypeId) {
    let isPerfCalc = false;
    let isSimpleWellConfCalc = false;
    let isComplexWellConfCalc = false;
    let isFlowConfCalc = false;
    let isScheduleCalc = false;
    switch (calculationTypeId) {
        // Perforation
        case CalculationTypeId.StartDepthPerforationTop:
        case CalculationTypeId.StartDepthPerforationBottom:
        case CalculationTypeId.StartDepthPerforationPlugDepth:
        case CalculationTypeId.StartDepthPerforationSeat:
        case CalculationTypeId.EndDepthPerforationTop:
        case CalculationTypeId.EndDepthPerforationBottom:
        case CalculationTypeId.EndDepthPerforationPlugDepth:
        case CalculationTypeId.EndDepthPerforationSeat:
        case CalculationTypeId.FluidTotalPerforationsVolumeOne:
        case CalculationTypeId.FluidTotalPerforationsVolumeTwo:
        case CalculationTypeId.FluidTotalPerforationsDispVolume:
        case CalculationTypeId.FluidTotalPerfBottomsUpVolume:
        case CalculationTypeId.N2TotalPerforationsVolumeOne:
        case CalculationTypeId.N2TotalPerforationsVolumeTwo:
        case CalculationTypeId.N2TotalPerforationsDispVolume:
        case CalculationTypeId.CombinedDisplacementTotalPerforationsVolumeOne:
        case CalculationTypeId.CombinedDisplacementTotalPerforationsVolumeTwo:
        case CalculationTypeId.CombinedDisplacementTotalPerforationsDispVolume:
        case CalculationTypeId.DownholeTotalPerforationsVolumeOne:
        case CalculationTypeId.DownholeTotalPerforationsVolumeTwo:
        case CalculationTypeId.DownholeTotalPerforationsDispVolume:
            isPerfCalc = true;
            break;
        // Well Config (Simple)
        case CalculationTypeId.StartDepthWellConfigPbtd:
        case CalculationTypeId.StartDepthWellConfigKop:
        case CalculationTypeId.StartDepthReservoirTvd:
        case CalculationTypeId.StartDepthWellConfigHeel:
        case CalculationTypeId.EndDepthWellConfigPbtd:
        case CalculationTypeId.EndDepthWellConfigKop:
        case CalculationTypeId.EndDepthReservoirTvd:
        case CalculationTypeId.EndDepthWellConfigHeel:
        case CalculationTypeId.FluidTotalWellConfigCTVolumeMaxWallThickness:
        case CalculationTypeId.N2TotalWellConfigCTVolumeMaxWallThickness:
        case CalculationTypeId.CombinedDisplacementTotalWellConfigCTVolumeMaxWallThickness:
        case CalculationTypeId.DownholeTotalWellConfigCTVolumeMaxWallThickness:
        case CalculationTypeId.FluidTotalWellConfigCTVolumeMinWallThickness:
        case CalculationTypeId.N2TotalWellConfigCTVolumeMinWallThickness:
        case CalculationTypeId.CombinedDisplacementTotalWellConfigCTVolumeMinWallThickness:
        case CalculationTypeId.DownholeTotalWellConfigCTVolumeMinWallThickness:
        case CalculationTypeId.N2TotalWellConfigCTVolume:
        case CalculationTypeId.CombinedDisplacementTotalWellConfigCTVolume:
        case CalculationTypeId.DownholeTotalWellConfigCTVolume:
        case CalculationTypeId.FluidTotalWellConfigCTVolume:
            isSimpleWellConfCalc = true;
            break;
        // Well Config (Complex)
        case CalculationTypeId.StartDepthWellConfigCasingTop:
        case CalculationTypeId.StartDepthWellConfigLinerTop:
        case CalculationTypeId.StartDepthWellConfigTubingTop:
        case CalculationTypeId.StartDepthWellConfigOpenHoleTop:
        case CalculationTypeId.StartDepthWellConfigCasingBottom:
        case CalculationTypeId.StartDepthWellConfigLinerBottom:
        case CalculationTypeId.StartDepthWellConfigTubingBottom:
        case CalculationTypeId.StartDepthWellConfigOpenHoleBottom:
        case CalculationTypeId.StartDepthWellConfigDownholeItem:
        case CalculationTypeId.EndDepthWellConfigCasingTop:
        case CalculationTypeId.EndDepthWellConfigLinerTop:
        case CalculationTypeId.EndDepthWellConfigTubingTop:
        case CalculationTypeId.EndDepthWellConfigOpenHoleTop:
        case CalculationTypeId.EndDepthWellConfigCasingBottom:
        case CalculationTypeId.EndDepthWellConfigLinerBottom:
        case CalculationTypeId.EndDepthWellConfigTubingBottom:
        case CalculationTypeId.EndDepthWellConfigOpenHoleBottom:
        case CalculationTypeId.EndDepthWellConfigDownholeItem:
            isComplexWellConfCalc = true;
            break;
        // Flow Config
        case CalculationTypeId.FluidTotalFlowConfigTotalVolume:
        case CalculationTypeId.FluidTotalFlowConfigVolume:
        case CalculationTypeId.N2TotalFlowConfigTotalVolume:
        case CalculationTypeId.N2TotalFlowConfigVolume:
        case CalculationTypeId.CombinedDisplacementTotalFlowConfigTotalVolume:
        case CalculationTypeId.CombinedDisplacementTotalFlowConfigVolume:
        case CalculationTypeId.DownholeTotalFlowConfigTotalVolume:
        case CalculationTypeId.DownholeTotalFlowConfigVolume:
            isFlowConfCalc = true;
            break;
        // Schedule
        case CalculationTypeId.StartDepthPrevScheduleStartDepth:
        case CalculationTypeId.StartDepthPrevScheduleEndDepth:
        case CalculationTypeId.EndDepthPrevScheduleStartDepth:
        case CalculationTypeId.EndDepthPrevScheduleEndDepth:
        case CalculationTypeId.FluidTotalPreviousScheduleVolume:
        case CalculationTypeId.FluidTotalPreviousSchedulesVolumeSum:
        case CalculationTypeId.N2TotalPreviousScheduleVolume:
        case CalculationTypeId.N2TotalPreviousSchedulesVolumeSum:
        case CalculationTypeId.CombinedDisplacementTotalPreviousScheduleVolume:
        case CalculationTypeId.CombinedDisplacementTotalPreviousSchedulesVolumeSum:
        case CalculationTypeId.DownholeTotalPreviousScheduleVolume:
        case CalculationTypeId.DownholeTotalPreviousSchedulesVolumeSum:
            isScheduleCalc = true;
    }
    return {
        isPerfCalc,
        isWellConfCalc: isSimpleWellConfCalc || isComplexWellConfCalc,
        isSimpleWellConfCalc,
        isComplexWellConfCalc,
        isFlowConfCalc,
        isScheduleCalc,
    };
}

export function hasEntity(calculationTypeId: CalculationTypeId) {
    const calculationType = getCalculationType(calculationTypeId);
    return hasEntityByCalcType(calculationType);
}

export function hasEntityByCalcType(calcTypeResult: {
    isPerfCalc: boolean;
    isWellConfCalc: boolean;
    isSimpleWellConfCalc: boolean;
    isComplexWellConfCalc: boolean;
    isFlowConfCalc: boolean;
    isScheduleCalc: boolean;
}) {
    return (
        calcTypeResult.isPerfCalc ||
        calcTypeResult.isWellConfCalc ||
        calcTypeResult.isScheduleCalc ||
        calcTypeResult.isFlowConfCalc
    );
}

export function hasSubEntity(calculationTypeId: CalculationTypeId) {
    switch (calculationTypeId) {
        case CalculationTypeId.FluidTotalPreviousScheduleVolume:
        case CalculationTypeId.N2TotalPreviousScheduleVolume:
        case CalculationTypeId.CombinedDisplacementTotalPreviousScheduleVolume:
        case CalculationTypeId.FluidTotalPreviousSchedulesVolumeSum:
        case CalculationTypeId.N2TotalPreviousSchedulesVolumeSum:
        case CalculationTypeId.CombinedDisplacementTotalPreviousSchedulesVolumeSum:
            return true;
    }
    return false;
}

export function allowsMultipleEntities(calculationTypeId: CalculationTypeId) {
    switch (calculationTypeId) {
        case CalculationTypeId.FluidTotalPreviousSchedulesVolumeSum:
        case CalculationTypeId.N2TotalPreviousSchedulesVolumeSum:
        case CalculationTypeId.CombinedDisplacementTotalPreviousSchedulesVolumeSum:
            return true;
    }
    return false;
}

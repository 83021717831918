import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface IconHeaderCellProps {
    // The title of the table column, or the label of the input field (e.g. "Account Manager 1", "Account Manager 2")
    title?: string;
    // Tooltip text to be displayed on hover
    tooltipText: string;
    // Styling of the title/label (bold/light font weight)
    titleStyleClassName: "font-bold" | "fw-lighter";
}

/**
 * For displaying a label with an information icon that displays a tooltip on hover. Used for header cell in table or as
 * label for an input field.
 * @param props
 * @constructor
 */
export const IconHeaderCell = (props: IconHeaderCellProps) => {
    return (
        <div
            title={props.tooltipText}
            className="align-center flex"
            style={{
                position: "relative",
            }}
        >
            <div className={`flex ${props.titleStyleClassName}`}>
                {props.title}
                <InformationCircleIcon
                    className={"ml-2 h-5 w-5 text-sky-500"}
                />
            </div>
        </div>
    );
};

import React from "react";
import { RouteObject } from "react-router-dom";

import { resources } from "utils/resources";

import Forbidden from "views/Error/Forbidden";
import General from "views/Error/General";
import HelpRequest from "views/Error/HelpRequest";
import NotFound from "views/Error/NotFound";
import ProxyAuthenticationRequired from "views/Error/ProxyAuthenticationRequired";

const ErrorRoutes: RouteObject = {
    path: "Error",
    children: [
        {
            path: "Help",
            element: <HelpRequest />,
            handle: {
                title: "Help",
            },
        },
        {
            path: "Forbidden",
            element: <Forbidden />,
            handle: {
                title: resources.Error,
            },
        },
        {
            path: "ProxyAuthenticationRequired",
            element: <ProxyAuthenticationRequired />,
            handle: {
                title: resources.Error,
            },
        },
        {
            path: "NotFound",
            element: <NotFound />,
            handle: {
                title: resources.Error,
            },
        },
        {
            path: "General",
            element: <General />,
            handle: {
                title: resources.Error,
            },
        },
    ],
};
export default ErrorRoutes;

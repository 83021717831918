import React, { useMemo } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { GridCellProps } from "@progress/kendo-react-grid";

import { useGridState } from "providers/GridStateProvider";

interface TableCellProps extends GridCellProps {
    onClick?: (event: React.SyntheticEvent) => void;
    getRef?: (tableDataCellElement: HTMLTableCellElement | null) => void;
    // The children of the cell
    children?: React.ReactNode;
    onHotKey?: (props: GridCellProps, event?: KeyboardEvent) => void;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
}

const TableCell: React.FC<TableCellProps> = (props) => {
    const { isGridEditable: gridIsEditable, readOnlyColumns } = useGridState();

    const columnIsReadOnly = useMemo(
        () =>
            props.field &&
            readOnlyColumns.some((value) => {
                if (typeof value === "string") {
                    return props.field?.startsWith(value);
                } else {
                    return value.test(props?.field ?? "");
                }
            }),
        [props.field, readOnlyColumns],
    );

    const cellIsReadOnly = props.dataItem?.editable === false;

    const editable = gridIsEditable && !columnIsReadOnly && !cellIsReadOnly;

    return (
        <td
            ref={props.getRef}
            colSpan={props.colSpan}
            className={`${props.className} ${
                !editable
                    ? "bg-calfrac-gray-50 text-gray-500"
                    : "focus-within:bg-calfrac-green-selected"
            } `}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            data-grid-col-index={props.columnIndex}
            style={props.style}
            onClick={props.onClick}
            onKeyDown={props.onKeyDown}
        >
            <GlobalHotKeys
                handlers={{
                    CALCULATE: (event) => {
                        event?.preventDefault();
                        event?.stopPropagation();
                        props?.onHotKey?.(props, event);
                    },
                }}
            >
                {props.children}
            </GlobalHotKeys>
        </td>
    );
};

export default React.memo(TableCell);

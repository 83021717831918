import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { PencilIcon } from "@heroicons/react/24/outline";
import { toString } from "@progress/kendo-intl";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { IEditProgramFluidSystemViewModel } from "types/generated/Calfrac/Jet/Web/Models/FluidsEnergyAndProppants/IEditProgramFluidSystemViewModel";

import { ChemicalLoadingsMetaT } from "types/Tables/Cells";

import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import {
    ItemType,
    RecordType,
} from "components/Tables/BaseTable/Inner/InnerTable";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { findFieldFormatNullable } from "utils/findFieldFormat";
import { resources } from "utils/resources";

type FluidSystemTableProps = {
    name: string;
    renderHash?: string;
    openConcentrationsWindow?: (
        open: boolean,
        fluidSystemId?: number,
        rowIdx?: number,
    ) => void;
};

const columnHelper = createColumnHelper<IEditProgramFluidSystemViewModel>();

const FluidSystemTable: React.FC<FluidSystemTableProps> = (props) => {
    const { isPageEditable } = useProgram();

    const tableRef = useRef<HTMLTableElement>(null);

    const { program } = useProgramParams();
    const units = useUnits();

    const { setValue } = useFormContext();

    const data = useWatch({ name: props.name });

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.FluidSystem, {
                id: "FluidSystem",
                size: 200,
                maxSize: 200,
                header: resources.FluidSystem,
                cell: DropDown,
            }),
            columnHelper.display({
                id: "ConcentrationsAction",
                header: resources.BaseFluids,
                size: 800,
                cell: ConcentrationsAction,
            }),
            columnHelper.accessor((row) => row.BaseFluidTemperature, {
                id: "BaseFluidTemperature",
                size: 150,
                maxSize: 150,
                header: `${resources.BaseFluidTemperature} ${units.tempUnit}`,
                cell: FormattedNumericCell,
            }),
        ];
    }, [units.tempUnit]);

    // Handlers
    const afterUpdateData = useCallback(
        (
            columnId: string,
            dataItem: RecordType,
            value: RecordType | ItemType,
            rowIndex?: number,
        ) => {
            if (columnId !== "FluidSystem") return;
            setValue(
                `${props.name}.${rowIndex}.BaseFluidTemperature`,
                (dataItem?.FluidSystem as RecordType)?.BaseFluidTemperature,
                {
                    shouldDirty: true,
                },
            );
            if (Object.keys(value as RecordType).length <= 0) {
                setValue(`${props.name}.${rowIndex}.BaseFluids`, []);
            }
            updateRenderHash();
        },
        [props.name, setValue],
    );

    return (
        <BaseTable
            tableRef={tableRef}
            name={props.name}
            defaultRecord={{
                BaseFluids: [],
            }}
            columns={defaultColumns}
            renderHash={renderHash}
            afterUpdateData={afterUpdateData}
            meta={{
                canDelete: isPageEditable,
                urls: {
                    FluidSystem: `/api/FluidsEnergyProppantsCements/FluidSystem?countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                    BaseFluid: `/api/FluidsEnergyProppantsCements/BaseFluid?countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                },
                openConcentrationsWindow: (rowIndex: number) => {
                    props.openConcentrationsWindow?.(
                        true,
                        data?.[rowIndex]?.FluidSystem?.Id,
                        rowIndex,
                    );
                },
            }}
        />
    );
};

const ConcentrationsAction = (props: CellContext<any, any>) => {
    // Index of the row with error (if any)
    const errorRowIdx: number | null | undefined = useWatch({
        name: `ErrorRowIdx`,
    });

    const { program } = useProgramParams();

    const baseFluidFormat = useMemo(() => {
        const format = findFieldFormatNullable(
            "",
            `FluidSystems.BaseFluids.Concentration`,
            program?.CountryId,
        );
        const regex = /n\d+/;
        const match = format?.match(regex);
        if (match) {
            return match[0];
        }
        return "n5";
    }, [program?.CountryId]);

    return (
        <div className={"flex items-center gap-x-2"}>
            <div>
                {props.row.original.FluidSystem?.Id && (
                    <PencilIcon
                        onClick={() =>
                            (
                                props.table.options
                                    .meta as ChemicalLoadingsMetaT
                            ).openConcentrationsWindow(props.row.index)
                        }
                        className={
                            "flex h-5 w-5 cursor-pointer text-calfrac-green"
                        }
                        aria-hidden="true"
                    />
                )}
            </div>
            <div>
                <div className={"inline-block"}>
                    {props.row.original.BaseFluids?.map((bf: any) => (
                        <div
                            className={
                                "m-0.5 inline-block rounded border border-solid border-black border-opacity-20 p-1 text-xs"
                            }
                        >
                            {`${bf.BaseFluid.Name} [${toString(
                                bf.Concentration,
                                baseFluidFormat,
                            )}]`}
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={`flex justify-center ${
                    errorRowIdx === props.row.index
                        ? "border-1 rounded border-red-600 border-opacity-80 bg-red-100"
                        : ""
                } bg-red `}
            ></div>
        </div>
    );
};

// Default exported so that the memoization is named in the dev tools
export default FluidSystemTable;

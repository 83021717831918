import React, { useEffect, useReducer } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";
import { ServiceLineId } from "types/generated/Calfrac/Jet/Core/Models/ServiceLineId";

import { IEditWellConfigViewModel } from "types/generated/Calfrac/Jet/Web/Models/WellConfig/IEditWellConfigViewModel";

import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";
import CementView from "components/Layout/CementView";
import FormSection from "components/Layout/FormSection";
import { CustomNumericTextBox } from "components/shared/GenericCustomComponents/CustomNumericTextBox";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import PressureTestTable from "components/Tables/ReservoirProperties/PressureTestTable";
import WellConfigTable from "components/Tables/WellConfig/WellConfigTable";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { Formats } from "utils/enumerations";
import { JetApiUrls } from "utils/fetchJet";
import { findFieldFormat } from "utils/findFieldFormat";
import { CountriesWithUnitsT, fieldFormats } from "utils/formatLookupTable";
import { resources } from "utils/resources";

const Page: React.FC = () => {
    // Constants
    const url: JetApiUrls = "/api/WellConfig/EditWellConfig";

    const { setValue } = useFormContext();
    const model = useWatch() as IEditWellConfigViewModel;

    // Url params
    const { programId, program, isPageEditable } = useProgramParams();
    const units = useUnits();
    const country = (program?.CountryId ??
        CountryId.Canada) as CountriesWithUnitsT;

    // These values have been extracted to prevent the useEffect from rerendering too often
    const { UseTopLinerAsLinerHanger, Liners } = model;
    const topLinerValue = Liners?.[0]?.Top ?? 0;

    const [tableRenderHash, updateTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        if (UseTopLinerAsLinerHanger) {
            // get top liner
            setValue("LinerHanger", topLinerValue);
        }
    }, [UseTopLinerAsLinerHanger, setValue, topLinerValue]);

    return (
        <StandardForm
            readParams={{
                programId,
                countryId: program?.CountryId,
                serviceLineId: program?.ServiceLineId,
            }}
            readUrl={url}
            saveUrl={url}
            clearCacheOnSave={[
                "/api/DesignResults/EditDesignResults",
                "/api/FlowConfiguration/EditFlowConfiguration",
                "/api/Schedule/EditScheduleStages",
            ]}
            onSaveSuccess={updateTableRenderHash}
        >
            <FormSection label={resources.WellData}>
                <StandardDropDownList
                    url={`/api/WellConfig/WellboreOrientation`}
                    name={`WellboreOrientationId`}
                    params={{
                        programId,
                    }}
                    byField={"Id"}
                    label={resources.WellboreOrientation}
                    disabled={!isPageEditable}
                />
                <CustomNumericTextBox
                    name={"PlugBackTotalDepth"}
                    label={`${resources.PBTD} ${units.depthUnit}`}
                    readOnly={!isPageEditable}
                    defaultValue={null}
                    inViewFormat={findFieldFormat(
                        "WellConfig",
                        "PlugBackTotalDepth",
                        program?.CountryId,
                    )}
                    inEditFormat={Formats.CapacityFormat.toString()}
                />
                {/*  Numeric input KOP/Kick Off Point (m) */}
                <CustomNumericTextBox
                    name={"KickOffPoint"}
                    label={`${resources.KickOffPoint} ${units.depthUnit}`}
                    readOnly={!isPageEditable}
                    defaultValue={null}
                    // TODO: Validate format
                    inViewFormat={findFieldFormat(
                        "WellConfig",
                        "PlugBackTotalDepth",
                        program?.CountryId,
                    )}
                />

                <CustomNumericTextBox
                    name={"NinetyDegreeHeel"}
                    label={`${resources.NinetyDegreeHeel} ${units.depthUnit}`}
                    readOnly={!isPageEditable}
                    defaultValue={null}
                    // TODO: Validate format
                    inViewFormat={findFieldFormat(
                        "WellConfig",
                        "PlugBackTotalDepth",
                        program?.CountryId,
                    )}
                />
                <CustomNumericTextBox
                    name={"BitSize"}
                    label={`${resources.BitSize} ${useUnits().lengthSmallUnit}`}
                    readOnly={!isPageEditable}
                    defaultValue={null}
                    inViewFormat={fieldFormats["WellConfig.BitSize"][country]}
                />
                <StandardMultipleSelect
                    label={resources.TreatmentModes}
                    name={"TreatmentModes"}
                    url={"/api/WellConfig/TreatmentMode"}
                    params={{
                        programId,
                        serviceLineId: program?.ServiceLineId?.toString() ?? "",
                    }}
                    byField={"Id"}
                    disabled={!isPageEditable}
                    className={"col-span-full"}
                />
            </FormSection>
            <FormSection label={resources.CompletionTechnologies}>
                <WellConfigTable
                    name={"CompletionTechnologies"}
                    renderHash={tableRenderHash}
                />
            </FormSection>
            <FormSection label={resources.Casings}>
                <WellConfigTable
                    name={"Casings"}
                    renderHash={tableRenderHash}
                />
            </FormSection>
            <FormSection label={resources.Liners}>
                <div className={"col-span-full flex flex-row gap-2"}>
                    <CustomNumericTextBox
                        name={"LinerHanger"}
                        label={`${resources.LinerHanger} ${units.depthUnit}`}
                        readOnly={model.UseTopLinerAsLinerHanger}
                        defaultValue={null}
                        inViewFormat={findFieldFormat(
                            "WellConfig",
                            "LinerHanger",
                            program?.CountryId,
                        )}
                        inEditFormat={Formats.CapacityFormat.toString()}
                        className={"w-48"}
                    />
                    <StandardCheckBox
                        label={resources.UseTopLinerAsLinerHanger}
                        name={"UseTopLinerAsLinerHanger"}
                        disabled={!isPageEditable}
                        className={"mb-1 mt-auto"}
                    />
                </div>
                <WellConfigTable name={"Liners"} renderHash={tableRenderHash} />
            </FormSection>
            <FormSection label={resources.Tubings}>
                <WellConfigTable
                    name={"Tubings"}
                    renderHash={tableRenderHash}
                />
            </FormSection>
            <FormSection label={resources.OpenHoles}>
                <WellConfigTable
                    name={"OpenHoles"}
                    renderHash={tableRenderHash}
                />
            </FormSection>
            <FormSection label={resources.CoiledTubings}>
                <StandardDropDownList
                    name={"WellClassId"}
                    url={`/api/WellConfig/WellClass`}
                    params={{
                        programId,
                        countryId: program?.CountryId.toString() ?? "",
                        serviceLineId: program?.ServiceLineId.toString() ?? "",
                    }}
                    label={resources.WellClass}
                    byField={"Id"}
                    disabled={!isPageEditable}
                />
                <WellConfigTable
                    name={"CoiledTubings"}
                    renderHash={tableRenderHash}
                />
            </FormSection>
            <FormSection label={resources.DownholeItems}>
                <WellConfigTable
                    name={"DownholeItems"}
                    renderHash={tableRenderHash}
                />
            </FormSection>
            {(program?.CountryId !== CountryId.Argentina ||
                program?.ServiceLineId === ServiceLineId.Fracturing) && (
                <FormSection label={resources.BallSealers}>
                    <WellConfigTable
                        name={"BallSealers"}
                        renderHash={tableRenderHash}
                    />
                </FormSection>
            )}
            <CementView>
                <FormSection label={resources.PressureTest}>
                    <PressureTestTable
                        name={"PressureTestItems"}
                        renderHash={tableRenderHash}
                    />
                </FormSection>
            </CementView>
        </StandardForm>
    );
};

export default Page;

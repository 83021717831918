import React, { ReactNode } from "react";
import { Outlet, useMatches } from "react-router-dom";

import { H2 } from "components/Headers";
import NavMenu from "components/NavMenu";

import { useTitle } from "hooks/useTitle";

export const PageHeaderBar: React.FC<{
    children?: ReactNode;
    className?: string;
    title?: string;
}> = ({ children, className, title }) => {
    const matches = useMatches();

    // Get the last item in the array level that has a title if none has been provided
    const resolvedTitle =
        title ??
        (matches as Array<{ handle?: { title?: string } }>)[matches.length - 1]
            .handle?.title;

    return (
        <div
            className={`sticky top-0 z-10 bg-white py-4  md:flex md:items-center md:justify-between ${className}`}
        >
            <div className="min-w-0">
                <H2>{resolvedTitle}</H2>
            </div>
            <div id="subtitle-portal" className="ml-3" />
            <div className="mt-0 flex flex-1 justify-end md:ml-4 md:mt-0	">
                <div
                    id="header-portal"
                    className={
                        "flex h-full w-full flex-row justify-end gap-4 px-4"
                    }
                />
                {children}
            </div>
        </div>
    );
};

const Layout: React.FC = () => {
    const matches = useMatches();

    // Get the last item in the array level that has a title
    const title = (matches as Array<{ handle?: { title?: string } }>)[
        matches.length - 1
    ].handle?.title;

    useTitle(title);
    return (
        <div className={"absolute inset-0 overflow-hidden font-sans"}>
            <NavMenu />
            <main className="mx-auto flex h-[calc(100%-4rem)] flex-grow flex-col px-2  sm:px-6 lg:px-8">
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;

import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
    TextAreaBlurEvent,
    TextAreaChangeEvent,
} from "@progress/kendo-react-all";
import { TextArea } from "@progress/kendo-react-inputs";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

import { handleEditableCellKeyDown } from "utils/keyDownHandlers";

type Props = {
    id?: string;
    value?: string;
    className?: string;
    onChange?: (text: string) => void;
    disabled?: boolean;
    label?: string;
    // Used with react hook form to register element
    name?: string;
};

const CustomLocalTextArea: React.FC<Props> = ({
    id,
    disabled = false,
    name = " ",
    className,
    ...props
}: Props) => {
    // Form Hook
    const { setValue, clearErrors } = useFormContext();

    const value = useWatch({ name }) as string;
    const [localValue, setLocalValue] = useState<string>(value ?? "");

    return (
        <div
            className={`flex flex-col ${className ?? ""}`}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleEditableCellKeyDown(e, "textarea", clearErrors)
            }
        >
            {props.label && <StandardLabel labelFor={id} label={props.label} />}
            <TextArea
                id={id}
                value={localValue}
                onChange={(e: TextAreaChangeEvent) => {
                    setLocalValue(e.target.value ?? "");
                }}
                onBlur={(e: TextAreaBlurEvent) => {
                    setValue(name, e.target.value, { shouldDirty: true });
                    clearErrors(name);
                }}
                readOnly={disabled}
                autoSize={true}
                className={
                    disabled
                        ? "max-h-60 overflow-scroll border-none bg-calfrac-gray-50 text-calfrac-gray  "
                        : "max-h-60 overflow-scroll"
                }
            />
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </div>
    );
};

export default CustomLocalTextArea;

import { useEffect } from "react";
import { QueryKey } from "@tanstack/query-core";
import { QueryCache, useQuery } from "@tanstack/react-query";
import {
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query/src/types";
import { Updater, useImmer } from "use-immer";

// see https://github.com/tannerlinsley/react-query/issues/293
// see https://usehooks.com/useDebounce/

type Props<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

/**
 * This is a react hook that wraps the react-query useQuery hook and adds a stateful value to the result.
 * This means that on data load, either from fetch or from cache, the data will be stored in state.
 *
 * This hook relies on the react-query useQuery hook, so it takes all the same props and will also return the same result as useQuery.
 *
 * @param props
 */
const useStatefulQuery = <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    props: Props<TQueryFnData, TError, TData, TQueryKey>,
): [
    TData | undefined,
    Updater<TData | undefined>,
    UseQueryResult<TData, TError>,
    QueryCache,
] => {
    const [state, setState] = useImmer<TData | undefined>(undefined);
    const query = useQuery(props);
    const cache = new QueryCache();

    useEffect(() => {
        if (query.status === "success") {
            setState(query.data);
        }
    }, [query.data, query.status, setState]);
    return [state, setState, query, cache];
};

export default useStatefulQuery;

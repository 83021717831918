//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ProgramWorkflowStatusId {
	InDesign = 1,
	QueuedForReview = 2,
	InReview = 3,
	InApproval = 4,
	Approved = 5,
	JobExecuted = 6,
	Cancelled = 7,
	Revised = 8,
	SentToOperations = 9
}

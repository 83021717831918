import React, { useCallback, useEffect, useState } from "react";
import { CellContext } from "@tanstack/react-table";

import { CellType, TableMetaT } from "types/Tables/Cells";

import BaseNumericCell from "./BaseNumericCell";

const NumericCellNullable: CellType = ({
    getValue,
    row: { index },
    column: { id },
    table,
}: CellContext<any, any>) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState<number | null>(initialValue);
    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = useCallback(() => {
        (table.options.meta as TableMetaT)?.updateNumericCellData(
            index,
            id,
            value,
        );
    }, [value, table.options.meta, index, id]);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <BaseNumericCell
            table={table}
            onBlur={onBlur}
            rowIndex={index}
            columnId={id}
            value={value}
            setValue={(v) => setValue(v)}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(NumericCellNullable, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return prevValue === nextValue;
});

//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ServiceLineId {
	Fracturing = 1,
	Pumping = 2,
	CoiledTubing = 3,
	Cement = 4,
	Ngc = 5
}

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { Formats } from "./enumerations";

export type CountriesWithUnitsT =
    | CountryId.Argentina
    | CountryId.Canada
    | CountryId.UnitedStates;

export const fieldFormats: Record<
    string,
    Record<CountriesWithUnitsT, string>
> = {
    "ActivatorLoadings.Concentration": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalLoadings.Concentration": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalSchedule.Concentration": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalScheduleStages.ActivatorLoadingDefault": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "ChemicalScheduleStages.BlenderConcentrationEnd": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalScheduleStages.BlenderConcentrationStart": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalScheduleStages.CleanVolume": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "ChemicalScheduleStages.DownholeConcentrationEnd": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalScheduleStages.DownholeConcentrationStart": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalScheduleStages.ProppantStage": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "ChemicalScheduleStages.ProppantStageCumulative": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "ChemicalScheduleStages.SecondaryFluidSystemRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalScheduleStages.SecondaryFluidSystemStage": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "ChemicalScheduleStages.SlurryRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Clusters.ClusterTable.Bottom": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Clusters.ClusterTable.Diameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Clusters.ClusterTable.PerforationClusterNumber": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "Clusters.ClusterTable.Shots": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Clusters.ClusterTable.Top": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Clusters.ClusterTable.TotalPerforations": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "ContingencyAdditionalChemicals.Total": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.PricingQuantityFormat.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "CustomPremixedChemicals.DefaultConcentration": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "CustomPremixedChemicals.FluidVolume": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },

    "CustomPremixedChemicals.OverriddenConcentration": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "CustomPremixedChemicals.Total": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Activators.Contingency": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Activators.ContingencyVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Activators.FinalTotal": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Activators.TotalVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.AdditionalChemicals.CoiledTubingFillAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.AdditionalChemicals.CoiledTubingFillVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.AdditionalChemicals.Contingency": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.AdditionalChemicals.ContingencyAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.AdditionalChemicals.FinalTotal": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.AdditionalChemicals.TotalAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Cements.Contingency": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "DesignResults.Cements.ContingencyAmount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "DesignResults.Cements.FinalTotal": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "DesignResults.Cements.TotalAmount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "DesignResults.BaseFluids.Bottoms": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.BaseFluids.BottomsVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.BaseFluids.CoiledTubingFillVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.BaseFluids.Contingency": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.BaseFluids.ContingencyVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.BaseFluids.FinalTotal": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.BaseFluids.Percentage": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.BaseFluids.TotalVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.BaseFluidSummaries.Final": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.BottomHoleFracture": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Chemicals.CoiledTubingFillAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Chemicals.CoiledTubingFillVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Chemicals.Contingency": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Chemicals.ContingencyAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Chemicals.FinalTotal": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Chemicals.TotalAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Chemicals.TotalPrimaryAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Chemicals.TotalSecondaryAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Chemicals.Unit": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.DepthToTopPerforation": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.EnergyTypes.Bottoms": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.EnergyTypes.BottomsAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.EnergyTypes.CoiledTubingFillAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.EnergyTypes.CoiledTubingFillVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.EnergyTypes.Contingency": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.EnergyTypes.ContingencyAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.EnergyTypes.FinalTotal": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.EnergyTypes.TotalAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.EnergyTypes.Unit": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.EnergyTypes.Volume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.FluidSystems.TotalVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.FluidSystems.Volume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.FracGradient": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "DesignResults.FrictionGradients.FlowConfigurationItemEndDepth": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.FrictionGradients.FlowConfigurationItemStartDepth": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.FrictionGradients.FrictionGradient": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.ThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.ThreeDecimal.toString(),
    },
    "DesignResults.FrictionGradients.TubularFrictionPressure": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.KickOffPointBottomsUpVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.HydrostaticHead": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.MaxCombinedDisplacementRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "DesignResults.MaxCombinedDownholeRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "DesignResults.MaxDepth": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.MaxFluidRate": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.PricingQuantityFormat.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "DesignResults.MaxN2Rate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "DesignResults.NumberBridgePlugs": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.NumberFracPorts": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.NumberOfIntervals": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.OneThousandMBottomsUpVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.PremixedChemicals.CoiledTubingFillAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.PremixedChemicals.CoiledTubingFillVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.PremixedChemicals.Contingency": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.PremixedChemicals.ContingencyAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.PremixedChemicals.FinalTotal": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.PremixedChemicals.TotalAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Proppants.Contingency": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Proppants.ContingencyAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Proppants.FinalTotal": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Proppants.TotalAmount": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.PumpingFrictionPressure": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.RequiredPumpPower": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.SlurryRate": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.PricingQuantityFormat.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "DesignResults.SpecificGravity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "DesignResults.SurfacePumpingPressure": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.TrueVerticalDepth": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.MaxCoilDepth": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.MaxRate": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.MaxTreatingPressure": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.MaxTreatingRate": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Tubulars.Bottom": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Tubulars.Collapse": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Tubulars.InternalYieldPressure": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "DesignResults.Tubulars.MaxWallThickness": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.ThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.ThreeDecimal.toString(),
    },
    "DesignResults.Tubulars.OutsideDiameter": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.ThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.ThreeDecimal.toString(),
    },
    "DesignResults.Tubulars.Top": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Tubulars.Volume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Tubulars.Weight": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Tubulars.FloatShoe": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Tubulars.FloatCollar": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "DesignResults.Volume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "ExternalInhibitorAdditionalChemicals.ChemicalTotal": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "ExternalInhibitorAdditionalChemicals.MaxDepth": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "ExternalInhibitorAdditionalChemicals.RunMultiplier": {
        [CountryId.Canada]: Formats.ThreeDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.ThreeDecimal.toString(),
    },
    "ExtraPremixedChemicals.DefaultConcentration": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "ExtraPremixedChemicals.FluidVolume": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "ExtraPremixedChemicals.OverriddenConcentration": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "ExtraPremixedChemicals.Total": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "FlowConfigurationItems.Capacity": {
        [CountryId.Canada]: Formats.GridFourDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridSixDecimal.toString(),
        [CountryId.Argentina]: Formats.GridSixDecimal.toString(),
    },
    "FlowConfigurationItems.EndDepth": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "FlowConfigurationItems.FrictionGradient": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "FlowConfigurationItems.StartDepth": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "FlowConfigurationItems.Volume": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "FluidSystems.BaseFluidTemperature": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "FluidSystems.BaseFluids.Concentration": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Cements.Total": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Formations.BottomholeReservoirPressure": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Formations.BottomholeSqueezePressure": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Formations.BottomholeTemperature": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Formations.BottomholeTemperatureDepth": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Formations.BottomholeTemperatureEstimated": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Formations.CO2": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Formations.FractureGradient": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Formations.FracPressure": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Formations.PoreGradient": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Formations.PorePressure": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Formations.H2SPpm": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Formations.Permeability": {
        [CountryId.Canada]: Formats.GridThreeDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "Formations.PorosityPercent": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Formations.SqueezeGradient": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Formations.TrueVerticalDepth": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "PerforationClusters.Bottom": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "PerforationClusters.Diameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "PerforationClusters.Shots": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "PerforationClusters.Top": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Perforations.BallSize": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.ThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Perforations.Bottom": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.BottomsUpVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.DisplacementVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.FlushVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.PlugDepth": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.SeatSize": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.ThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.ThreeDecimal.toString(),
    },
    "Perforations.TVD": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.Top": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.TreatmentVolume": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.TubularFrictionPressure": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "Perforations.Volume1": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },
    "Perforations.Volume2": {
        [CountryId.Canada]: Formats.OneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.OneDecimal.toString(),
        [CountryId.Argentina]: Formats.OneDecimal.toString(),
    },

    "Perforations.ZoneNumber": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "PeriodicPillAdditionalChemicals.ChemicalTotal": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "PeriodicPillAdditionalChemicals.ChemicalVolume": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "PeriodicPillAdditionalChemicals.Frequency": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },

    "PeriodicPillAdditionalChemicals.Loading": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "PeriodicPillAdditionalChemicals.VolumePerPill": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "PressureTestItems.Pressure": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "Pricing.EquipmentMileage": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "Pricing.EstimatedGrandTotal": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GenericSubtotalOne": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GenericSubtotalThree": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GenericSubtotalTwo": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GrandTotal": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GrandTotalAfterDiscount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GrandTotalAveragePerStage": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GrandTotalBeforeDiscount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GrandTotalFirstStage": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.GrandTotalOtherStages": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.HeavyEquipmentTotal": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "Pricing.Items.BookPrice": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Pricing.Items.Discount": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Pricing.Items.FinalPrice": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Pricing.Items.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.Items.TotalPrice": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Pricing.MaterialMileage": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "Pricing.PricingAdditionalCartage.BookPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalCartage.Discount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalCartage.FinalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalCartage.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalCartage.TotalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalCartage.UnitPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalEquipment.BookPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalEquipment.Discount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalEquipment.FinalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalEquipment.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalEquipment.TotalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalEquipment.UnitPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalItems.BookPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalItems.Discount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalItems.FinalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalItems.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalItems.TotalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalItems.UnitPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalMaterials.BookPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalMaterials.Discount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalMaterials.FinalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalMaterials.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalMaterials.TotalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingAdditionalMaterials.UnitPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCartage.BookPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCartage.Discount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCartage.FinalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCartage.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCartage.TotalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCartage.UnitPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCustomAdditionalItems.BookPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCustomAdditionalItems.Discount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCustomAdditionalItems.FinalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCustomAdditionalItems.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCustomAdditionalItems.TotalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingCustomAdditionalItems.UnitPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingEquipment.BookPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingEquipment.Discount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingEquipment.FinalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingEquipment.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingEquipment.TotalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingEquipment.UnitPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingMaterials.BookPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingMaterials.Discount": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },

    "Pricing.PricingMaterials.FinalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingMaterials.Quantity": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingMaterials.TotalPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PricingMaterials.UnitPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.PumpsTotal": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "Pricing.TotalAdditionalItemsPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.TotalCartagePrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.TotalDiscountPercentage": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.TotalDiscountValue": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.TotalEquipmentPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Pricing.TotalMaterialsPrice": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Proppants.Total": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "PumpScheduleStages.CementTotal": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "PumpScheduleStages.FluidTotal": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.PricingQuantityFormat.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "PumpScheduleStages.N2Total": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.PricingQuantityFormat.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "PumpScheduleStages.NetMeterTotal": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.PricingQuantityFormat.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "PumpScheduleStages.ProppantTotal": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "PumpSchedules.FluidTotal": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "PumpSchedules.ProppantTotal": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "PumpSchedules.Repeated": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "PumpSchedules.ScheduleNumber": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "PumpSchedules.TotalChemicals.Volume": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "PumpSchedules.CementTotal": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "PumpSchedules.CementTotalBags": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.CementStage": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Stages.CementStageCumulative": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "Stages.BlenderConcentrationEnd": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.BlenderConcentrationStart": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.CO2Rate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },

    "Stages.CO2Ratio": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },

    "Stages.CO2Stage": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.CO2StageCumulative": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.CleanRateStart": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.PricingQuantityFormat.toString(),
        [CountryId.Argentina]: Formats.PricingQuantityFormat.toString(),
    },
    "Stages.CleanVolume": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Stages.CleanVolumeCumulative": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "Stages.CombinedDisplacementRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.CombinedDisplacementTotal": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.Density": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.DownholeConcentrationEnd": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.DownholeConcentrationStart": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.DownholeFoamQuality": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Stages.DownholeN2Rate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.DownholeRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.DownholeTotal": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.DownholeVolume": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Stages.EndDepth": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.FluidRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.FluidTotal": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.N2Rate": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.N2Ratio": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.N2Stage": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Stages.N2StageCumulative": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Stages.N2Total": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.NetMeter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },
    "Stages.ProppantStage": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },

    "Stages.ProppantStageCumulative": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Stages.SecondaryFluidSystemRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },

    "Stages.SecondaryFluidSystemRatio": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridTwoDecimal.toString(),
        [CountryId.Argentina]: Formats.GridTwoDecimal.toString(),
    },

    "Stages.SecondaryFluidSystemStage": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.SecondaryFluidSystemStageCumulative": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.SlurryRate": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.Speed": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Stages.StageNumber": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "Stages.StartDepth": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "Stages.Time": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "StandardPremixedChemicals.DefaultConcentration": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "StandardPremixedChemicals.FluidVolume": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "StandardPremixedChemicals.OverriddenConcentration": {
        [CountryId.Canada]: Formats.TwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.TwoDecimal.toString(),
        [CountryId.Argentina]: Formats.TwoDecimal.toString(),
    },
    "StandardPremixedChemicals.Total": {
        [CountryId.Canada]: Formats.PricingQuantityFormat.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "WellConfig.BitSize": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.BallSealers.Quantity": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "WellConfig.Casings.Bottom": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Casings.Casing.Capacity": {
        [CountryId.Canada]: Formats.GridFourDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridSixDecimal.toString(),
        [CountryId.Argentina]: Formats.GridSixDecimal.toString(),
    },
    "WellConfig.Casings.FloatCollar": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Casings.FloatShoe": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Casings.Casing.Grade": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "WellConfig.Casings.Casing.InsideDiameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.Casings.Casing.InternalYieldPressure": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "WellConfig.Casings.Casing.OutsideDiameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.Casings.Casing.Weight": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Casings.Top": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.CoiledTubings.CoiledTubing.Capacity": {
        [CountryId.Canada]: Formats.GridFourDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridSixDecimal.toString(),
        [CountryId.Argentina]: Formats.GridSixDecimal.toString(),
    },
    "WellConfig.CoiledTubings.CoiledTubing.Grade": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "WellConfig.CoiledTubings.CoiledTubing.InsideDiameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.CoiledTubings.CoiledTubing.InternalYieldPressure": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "WellConfig.CoiledTubings.CoiledTubing.OutsideDiameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.CoiledTubings.MaxWallThickness": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.CoiledTubings.StringLength": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.CoiledTubings.Volume": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.CompletionTechnologies.Flush": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.DownholeItems.Depth": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.DownholeItems.InsideDiameter": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.LinerHanger": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Liners.Bottom": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Liners.Liner.Capacity": {
        [CountryId.Canada]: Formats.GridFourDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridSixDecimal.toString(),
        [CountryId.Argentina]: Formats.GridSixDecimal.toString(),
    },
    "WellConfig.Liners.Liner.Grade": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "WellConfig.Liners.Liner.InsideDiameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.Liners.Liner.InternalYieldPressure": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "WellConfig.Liners.Liner.OutsideDiameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.Liners.Liner.Weight": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Liners.Top": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.OpenHoles.Bottom": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.OpenHoles.OpenHole.Capacity": {
        [CountryId.Canada]: Formats.GridFourDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridSixDecimal.toString(),
        [CountryId.Argentina]: Formats.GridSixDecimal.toString(),
    },
    "WellConfig.OpenHoles.OpenHole.Diameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.OpenHoles.Top": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.PlugBackTotalDepth": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.KickOffPoint": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.NinetyDegreeHeel": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Tubings.Bottom": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Tubings.Top": {
        [CountryId.Canada]: Formats.GridOneDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellConfig.Tubings.Tubing.Capacity": {
        [CountryId.Canada]: Formats.GridFourDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridSixDecimal.toString(),
        [CountryId.Argentina]: Formats.GridSixDecimal.toString(),
    },
    "WellConfig.Tubings.Tubing.Grade": {
        [CountryId.Canada]: Formats.GridInteger.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },
    "WellConfig.Tubings.Tubing.InsideDiameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.Tubings.Tubing.InternalYieldPressure": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridInteger.toString(),
        [CountryId.Argentina]: Formats.GridInteger.toString(),
    },

    "WellConfig.Tubings.Tubing.OutsideDiameter": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridThreeDecimal.toString(),
        [CountryId.Argentina]: Formats.GridThreeDecimal.toString(),
    },
    "WellConfig.Tubings.Tubing.Weight": {
        [CountryId.Canada]: Formats.GridTwoDecimal.toString(),
        [CountryId.UnitedStates]: Formats.GridOneDecimal.toString(),
        [CountryId.Argentina]: Formats.GridOneDecimal.toString(),
    },
    "WellData.EquipmentMileage": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "WellData.Latitude": {
        [CountryId.Canada]: Formats.SixDecimal.toString(),
        [CountryId.UnitedStates]: Formats.SixDecimal.toString(),
        [CountryId.Argentina]: Formats.SixDecimal.toString(),
    },
    "WellData.Longitude": {
        [CountryId.Canada]: Formats.SixDecimal.toString(),
        [CountryId.UnitedStates]: Formats.SixDecimal.toString(),
        [CountryId.Argentina]: Formats.SixDecimal.toString(),
    },
    "WellData.MaterialMileage": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "WellData.NumberOfWellsOnPad": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
    "WellData.WellSequence": {
        [CountryId.Canada]: Formats.Integer.toString(),
        [CountryId.UnitedStates]: Formats.Integer.toString(),
        [CountryId.Argentina]: Formats.Integer.toString(),
    },
};

import React, {
    ReactNode,
    useCallback,
    useEffect,
    useReducer,
    useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { formatNumber } from "@progress/kendo-intl";
import moment from "moment-timezone";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";
import { PricingItemTypeId } from "types/generated/Calfrac/Jet/Core/Models/PricingItemTypeId";

import { IEditPricingViewModel } from "types/generated/Calfrac/Jet/Web/Models/Pricing/IEditPricingViewModel";

import { queryClient } from "AppRoutes/AppProviders";

import StandardDatePicker from "components/kendoExtensions/standardExtensions/StandardDatePicker";
import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardNumericTextBox from "components/kendoExtensions/standardExtensions/StandardNumericTextBox";
import { NotCementView } from "components/Layout/CementView";
import FormSection, {
    FormSectionDivider,
    FormSectionFlex,
    Title,
} from "components/Layout/FormSection";
import { HeaderPortal } from "components/Layout/HeaderPortal";
import CustomLocalTextArea from "components/shared/GenericCustomComponents/CustomLocalTextArea";
import CustomLocalTextBox from "components/shared/GenericCustomComponents/CustomLocalTextBox";
import StandardButton from "components/shared/GenericCustomComponents/StandardButton";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import StandardReadOnly from "components/shared/GenericCustomComponents/StandardReadOnly";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";
import PricingTable from "components/Tables/Pricing/PricingTable";

import { useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { Formats } from "utils/enumerations";
import { saveJET } from "utils/fetchJet";
import { fieldFormats } from "utils/formatLookupTable";
import { resources } from "utils/resources";

import CopyPricingItems from "./_CopyPricingItems";

const Page: React.FC = () => {
    // Constants
    const url = "/api/Pricing/EditPricing";
    const units = useUnits();

    let { formState, setValue, setError } = useFormContext();
    let model = useWatch() as IEditPricingViewModel;

    // Url params
    const { programId, program, isPageEditable } = useProgramParams();

    // State
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdatePricing = useCallback(async () => {
        if (
            formState.isDirty &&
            !window.confirm(resources.YouHaveUnsavedChanges)
        ) {
            return;
        }
        setIsLoading(true);

        await saveJET<string>("/api/Pricing/UpdatePriceBookDate", {
            programId,
        });

        // fetch the queries that match predicate
        await queryClient.refetchQueries({
            predicate: (query) => {
                return (query.queryKey[0] as string).startsWith("/api/Pricing");
            },
            type: "all",
        });

        setIsLoading(false);
    }, [programId, formState.isDirty]);

    // Helpers
    const setWarnings = useCallback(() => {
        if (model.CreditLimitWarning !== null && model.CreditLimitWarning)
            setError(resources.CreditWarning, {
                message: model.CreditLimitWarning,
            });
        if (
            model.MaterialsMissingPriceBookWarning != null &&
            model.MaterialsMissingPriceBookWarning
        ) {
            if (
                model.MaterialsMissingPriceBookWarning.includes(
                    resources.SelectedArea,
                ) &&
                model.PriceLevel &&
                !formState.dirtyFields["PriceLevel"]
            ) {
                setError(resources.PriceWarning, {
                    message: model.MaterialsMissingPriceBookWarning,
                });
            }
            if (
                !model.MaterialsMissingPriceBookWarning.includes(
                    resources.SelectedArea,
                )
            ) {
                setError(resources.PriceWarning, {
                    message: model.MaterialsMissingPriceBookWarning,
                });
            }
        }
        if (
            model.MaterialsMissingFinanceCodeWarning != null &&
            model.MaterialsMissingFinanceCodeWarning
        )
            setError(resources.PriceWarning, {
                message: model.MaterialsMissingFinanceCodeWarning,
            });
    }, [
        model.CreditLimitWarning,
        model.MaterialsMissingPriceBookWarning,
        model.MaterialsMissingFinanceCodeWarning,
        model.PriceLevel,
        setError,
        formState.dirtyFields,
    ]);

    useEffect(() => {
        setWarnings();
    }, [model, setError, setValue, setWarnings]);

    const RenderCreditSection = useCallback(
        () => (
            <>
                <StandardReadOnly
                    name={"Operator"}
                    label={resources.Operator}
                />
                {program?.CountryId === CountryId.Argentina && (
                    <StandardReadOnly
                        name={"PrimaryOperator"}
                        label={resources.PrimaryOperator}
                    />
                )}
                <StandardReadOnly
                    name={"OperatorNumber"}
                    label={resources.OperatorNumber}
                />
            </>
        ),
        [program?.CountryId],
    );

    const country =
        program?.CountryId === CountryId.UnitedStates
            ? CountryId.UnitedStates
            : CountryId.Canada;

    // Updating this render hash will rerender all the rows. This is useful for data changes like after saving.
    const [pricingTableRenderHash, updatePricingTableRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    return (
        <>
            {isLoading && <LoadingOverlay />}
            <HeaderPortal>
                {isPageEditable && model.PriceLevel && (
                    <CopyPricingItems
                        afterUpdate={updatePricingTableRenderHash}
                    />
                )}
            </HeaderPortal>
            <StandardForm
                saveUrl={url}
                readUrl={url}
                readParams={{ programId }}
                onSaveSuccess={() => {
                    setWarnings();
                    updatePricingTableRenderHash();
                }}
            >
                <FormSectionDivider>
                    <FormSection label={resources.BasicInformation}>
                        <div className="col-span-full flex w-full flex-col gap-y-4">
                            <DateTimeReadOnly />
                            {isPageEditable && (
                                <StandardButton
                                    text={resources.UpdateAndResetPricing}
                                    onClick={handleUpdatePricing}
                                />
                            )}
                        </div>
                        <RenderCreditSection />
                        <StandardDropDownList
                            url={"/api/Pricing/PriceLevels"}
                            params={{
                                programId,
                            }}
                            name={`PriceLevel`}
                            byField={"Name"}
                            disabled={!isPageEditable}
                            label={resources.Area}
                        />
                        <StandardDatePicker
                            name={"EstimatedJobStartDate"}
                            disabled={true}
                            label={resources.EstimatedJobStartDate}
                        />
                    </FormSection>
                    {model.PriceLevel && (
                        <>
                            <FormSection label={resources.Mileage}>
                                <StandardReadOnly
                                    label={resources.HeavyEquipmentTotal}
                                    id={resources.HeavyEquipmentTotal}
                                    name={"HeavyEquipmentTotal"}
                                    format={
                                        fieldFormats[
                                            "Pricing.HeavyEquipmentTotal"
                                        ][country]
                                    }
                                />
                                <StandardReadOnly
                                    id={resources.MaterialMileage}
                                    name={"MaterialMileage"}
                                    label={`${resources.MaterialMileage} ${units.mileageUnit}`}
                                    format={
                                        fieldFormats["Pricing.MaterialMileage"][
                                            country
                                        ]
                                    }
                                />
                                <StandardReadOnly
                                    id={resources.PumpsTotal}
                                    name={"PumpsTotal"}
                                    label={resources.PumpsTotal}
                                    format={
                                        fieldFormats["Pricing.PumpsTotal"][
                                            country
                                        ]
                                    }
                                />
                                <StandardReadOnly
                                    id={resources.EquipmentMileage}
                                    name={"EquipmentMileage"}
                                    label={`${resources.EquipmentMileage} ${units.mileageUnit}`}
                                    format={
                                        fieldFormats[
                                            "Pricing.EquipmentMileage"
                                        ][country]
                                    }
                                />
                            </FormSection>
                            <FormSection label={resources.BreakDownOfTotalTime}>
                                <div className="col-span-full grid grid-cols-2 text-sm">
                                    <div className="bg-calfrac-gray-100 p-2">
                                        {resources.StageType}
                                    </div>
                                    <div className="bg-calfrac-gray-100 p-2">
                                        {resources.Timehhmmss}
                                    </div>
                                    {model.StageDescriptionTypeTimeBreakdown.map(
                                        (breakdown) => (
                                            <>
                                                <div className="bg-calfrac-gray-50 p-2">
                                                    {
                                                        breakdown.StageDescriptionType
                                                    }
                                                </div>
                                                <div className="bg-calfrac-gray-50 p-2">
                                                    {breakdown.TotalTime}
                                                </div>
                                            </>
                                        ),
                                    )}
                                    {model.StageDescriptionTypeTimeBreakdown
                                        .length === 0 && (
                                        <div className="col-span-2 bg-calfrac-gray-50 p-2 text-center">
                                            {resources.NoRecords}
                                        </div>
                                    )}
                                </div>
                            </FormSection>
                            <FormSection label={resources.PricingSetup}>
                                <StandardCheckBox
                                    sidelabel={
                                        resources.DisplayCustomerCodeColumn
                                    }
                                    name={"DisplayCustomerCodeColumn"}
                                />
                                <StandardCheckBox
                                    sidelabel={resources.DisplayPriceCodeColumn}
                                    name={"DisplayPriceCodeColumn"}
                                />
                            </FormSection>
                            <EquipmentPricingFormSection
                                label={resources.Equipment}
                                name={"TotalEquipmentPrice"}
                                readOnlyLabel={resources.EquipmentTotal}
                                totalsAndSubsections={
                                    model.PricingEquipmentSubSections
                                }
                            >
                                <PricingTable
                                    name={"PricingEquipment"}
                                    IsAdditionalPriceItem={false}
                                    renderHash={pricingTableRenderHash}
                                    displayCustomerCodeColumn={
                                        model.DisplayCustomerCodeColumn
                                    }
                                    displayPriceCodeColumn={
                                        model.DisplayPriceCodeColumn
                                    }
                                />
                                <PricingTable
                                    title={resources.AddEquipment}
                                    name={"PricingAdditionalEquipment"}
                                    IsAdditionalPriceItem={true}
                                    pricingItemTypeId={
                                        PricingItemTypeId.Equipment
                                    }
                                    descriptionEndPoint={`/api/Pricing/AdditionalEquipmentDescriptions?programId=${programId}`}
                                    renderHash={pricingTableRenderHash}
                                    displayCustomerCodeColumn={
                                        model.DisplayCustomerCodeColumn
                                    }
                                    displayPriceCodeColumn={
                                        model.DisplayPriceCodeColumn
                                    }
                                />
                            </EquipmentPricingFormSection>
                            <PricingFormSection
                                label={resources.Materials}
                                name={"TotalMaterialsPrice"}
                                readOnlyLabel={resources.MaterialsTotal}
                            >
                                <PricingTable
                                    name={"PricingMaterials"}
                                    IsAdditionalPriceItem={false}
                                    renderHash={pricingTableRenderHash}
                                    displayCustomerCodeColumn={
                                        model.DisplayCustomerCodeColumn
                                    }
                                    displayPriceCodeColumn={
                                        model.DisplayPriceCodeColumn
                                    }
                                />
                                <PricingTable
                                    title={resources.AddMaterials}
                                    name={"PricingAdditionalMaterials"}
                                    IsAdditionalPriceItem={true}
                                    descriptionEndPoint={`/api/Pricing/AdditionalMaterialDescriptions?programId=${programId}`}
                                    renderHash={pricingTableRenderHash}
                                    displayCustomerCodeColumn={
                                        model.DisplayCustomerCodeColumn
                                    }
                                    displayPriceCodeColumn={
                                        model.DisplayPriceCodeColumn
                                    }
                                />
                            </PricingFormSection>
                            <PricingFormSection
                                label={resources.Cartage}
                                name={"TotalCartagePrice"}
                                readOnlyLabel={resources.CartageTotal}
                            >
                                <PricingTable
                                    name={"PricingCartage"}
                                    IsAdditionalPriceItem={false}
                                    renderHash={pricingTableRenderHash}
                                    displayCustomerCodeColumn={
                                        model.DisplayCustomerCodeColumn
                                    }
                                    displayPriceCodeColumn={
                                        model.DisplayPriceCodeColumn
                                    }
                                />
                                <PricingTable
                                    title={resources.AddCartage}
                                    name={"PricingAdditionalCartage"}
                                    IsAdditionalPriceItem={true}
                                    descriptionEndPoint={`/api/Pricing/CartageDescriptions?programId=${programId}`}
                                    renderHash={pricingTableRenderHash}
                                    displayCustomerCodeColumn={
                                        model.DisplayCustomerCodeColumn
                                    }
                                    displayPriceCodeColumn={
                                        model.DisplayPriceCodeColumn
                                    }
                                />
                            </PricingFormSection>
                            <PricingFormSection
                                label={resources.AdditionalItems}
                                readOnlyLabel={resources.AdditionalItemsTotal}
                                name={"TotalAdditionalItemsPrice"}
                            >
                                <PricingTable
                                    name={"PricingAdditionalItems"}
                                    IsAdditionalPriceItem={false}
                                    renderHash={pricingTableRenderHash}
                                    displayCustomerCodeColumn={
                                        model.DisplayCustomerCodeColumn
                                    }
                                    displayPriceCodeColumn={
                                        model.DisplayPriceCodeColumn
                                    }
                                />
                                <PricingTable
                                    title={resources.AddAdditionalItems}
                                    name={"PricingCustomAdditionalItems"}
                                    IsAdditionalPriceItem={true}
                                    descriptionEndPoint={`/api/Pricing/AdditionalItemDescriptions?programId=${programId}`}
                                    renderHash={pricingTableRenderHash}
                                    displayCustomerCodeColumn={
                                        model.DisplayCustomerCodeColumn
                                    }
                                    displayPriceCodeColumn={
                                        model.DisplayPriceCodeColumn
                                    }
                                />
                            </PricingFormSection>
                            <div className="z-0 pt-2 font-bold">
                                <div className="bg-white shadow-md ring-1 ring-calfrac-gray/5  md:col-span-2">
                                    <Title title={resources.Total} />
                                    <div className="px-4 py-4">
                                        <div className="flex flex-col items-end gap-y-4">
                                            <StandardReadOnly
                                                label={
                                                    resources.GrandTotalBeforeDiscount
                                                }
                                                name={
                                                    "GrandTotalBeforeDiscount"
                                                }
                                                format={
                                                    fieldFormats[
                                                        "Pricing.GrandTotalBeforeDiscount"
                                                    ][country]
                                                }
                                                alignRight={true}
                                                className={"ml-auto w-96"}
                                                bold={true}
                                            />
                                            <StandardNumericTextBox
                                                label={
                                                    resources.TotalDiscountPercentage
                                                }
                                                name={"TotalDiscountPercentage"}
                                                alignRight={true}
                                                disabled={!isPageEditable}
                                                className={"ml-auto w-96"}
                                                format={
                                                    fieldFormats[
                                                        "Pricing.TotalDiscountPercentage"
                                                    ][country]
                                                }
                                                onChange={(
                                                    val: number | null,
                                                ) => {
                                                    if (val) {
                                                        setValue(
                                                            "TotalDiscountValue",
                                                            null,
                                                        );
                                                    }
                                                }}
                                            />
                                            <StandardNumericTextBox
                                                label={
                                                    resources.TotalDiscountValue
                                                }
                                                className={"ml-auto w-96"}
                                                name={"TotalDiscountValue"}
                                                alignRight={true}
                                                disabled={!isPageEditable}
                                                format={
                                                    fieldFormats[
                                                        "Pricing.TotalDiscountValue"
                                                    ][country]
                                                }
                                                onChange={(
                                                    val: number | null,
                                                ) => {
                                                    if (val) {
                                                        setValue(
                                                            "TotalDiscountPercentage",
                                                            null,
                                                        );
                                                    }
                                                }}
                                            />
                                            <StandardReadOnly
                                                label={
                                                    resources.GrandTotalAfterDiscount
                                                }
                                                name={"GrandTotal"}
                                                format={
                                                    fieldFormats[
                                                        "Pricing.GrandTotalAfterDiscount"
                                                    ][country]
                                                }
                                                alignRight={true}
                                                className={"ml-auto w-96"}
                                                bold={true}
                                            />
                                            <div
                                                className={
                                                    "col-span-full flex flex-col gap-y-4"
                                                }
                                            >
                                                <div
                                                    className={
                                                        "flex max-w-min flex-row gap-4"
                                                    }
                                                >
                                                    <CustomLocalTextBox
                                                        label={
                                                            resources.GenericSubtotalDescriptionOne
                                                        }
                                                        id={
                                                            resources.GenericSubtotalDescriptionOne
                                                        }
                                                        className={
                                                            "ml-auto w-56"
                                                        }
                                                        name={
                                                            "GenericSubtotalDescriptionOne"
                                                        }
                                                        alignRight={true}
                                                        disabled={
                                                            !isPageEditable
                                                        }
                                                        boldTitle={true}
                                                    />
                                                    <StandardNumericTextBox
                                                        label={
                                                            resources.GenericSubtotalOne
                                                        }
                                                        id={
                                                            resources.GenericSubtotalOne
                                                        }
                                                        className={
                                                            "ml-auto w-[9rem]"
                                                        }
                                                        name={
                                                            "GenericSubtotalOne"
                                                        }
                                                        alignRight={true}
                                                        disabled={
                                                            !isPageEditable
                                                        }
                                                        format={
                                                            fieldFormats[
                                                                "Pricing.GenericSubtotalOne"
                                                            ][country]
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        "flex max-w-min flex-row gap-4"
                                                    }
                                                >
                                                    <CustomLocalTextBox
                                                        label={
                                                            resources.GenericSubtotalDescriptionTwo
                                                        }
                                                        id={
                                                            resources.GenericSubtotalDescriptionTwo
                                                        }
                                                        className={
                                                            "ml-auto w-56"
                                                        }
                                                        name={
                                                            "GenericSubtotalDescriptionTwo"
                                                        }
                                                        alignRight={true}
                                                        disabled={
                                                            !isPageEditable
                                                        }
                                                        boldTitle={true}
                                                    />
                                                    <StandardNumericTextBox
                                                        label={
                                                            resources.GenericSubtotalTwo
                                                        }
                                                        id={
                                                            resources.GenericSubtotalTwo
                                                        }
                                                        className={
                                                            "ml-auto w-[9rem]"
                                                        }
                                                        name={
                                                            "GenericSubtotalTwo"
                                                        }
                                                        alignRight={true}
                                                        disabled={
                                                            !isPageEditable
                                                        }
                                                        format={
                                                            fieldFormats[
                                                                "Pricing.GenericSubtotalTwo"
                                                            ][country]
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        "flex max-w-min flex-row gap-4"
                                                    }
                                                >
                                                    <CustomLocalTextBox
                                                        label={
                                                            resources.GenericSubtotalDescriptionThree
                                                        }
                                                        id={
                                                            resources.GenericSubtotalDescriptionThree
                                                        }
                                                        className={
                                                            "ml-auto w-56"
                                                        }
                                                        name={
                                                            "GenericSubtotalDescriptionThree"
                                                        }
                                                        alignRight={true}
                                                        disabled={
                                                            !isPageEditable
                                                        }
                                                        boldTitle={true}
                                                    />
                                                    <StandardNumericTextBox
                                                        label={
                                                            resources.GenericSubtotalThree
                                                        }
                                                        id={
                                                            resources.GenericSubtotalThree
                                                        }
                                                        className={
                                                            "ml-auto w-[9rem]"
                                                        }
                                                        name={
                                                            "GenericSubtotalThree"
                                                        }
                                                        alignRight={true}
                                                        disabled={
                                                            !isPageEditable
                                                        }
                                                        format={
                                                            fieldFormats[
                                                                "Pricing.GenericSubtotalThree"
                                                            ][country]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <NotCementView>
                                                <StandardReadOnly
                                                    label={
                                                        resources.GrandTotalAveragePerStage
                                                    }
                                                    id={
                                                        resources.GrandTotalAveragePerStage
                                                    }
                                                    name={
                                                        "GrandTotalAveragePerStage"
                                                    }
                                                    format={
                                                        fieldFormats[
                                                            "Pricing.GrandTotalAveragePerStage"
                                                        ][country]
                                                    }
                                                    alignRight={true}
                                                    className={"ml-auto w-96"}
                                                    bold={true}
                                                />
                                            </NotCementView>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormSectionFlex label={resources.Notes}>
                                <CustomLocalTextArea
                                    id={resources.Notes}
                                    name={"PricingNote"}
                                    value={model?.PricingNote}
                                    disabled={!isPageEditable}
                                />
                            </FormSectionFlex>
                        </>
                    )}
                </FormSectionDivider>
            </StandardForm>
        </>
    );
};

const DateTimeReadOnly: React.FC = () => {
    let time: string = useWatch({ name: "PriceBookDate" });
    // Parse the ISO string
    const dateTime = moment(time);
    // Format
    const mstTime = dateTime.format("MM/DD/YYYY h:mm A");

    return (
        <StandardReadOnly
            name={" "}
            label={resources.UsingPricingAsOf}
            overrideValue={mstTime}
        />
    );
};

const PricingFormSection: React.FC<{
    label: string;
    children: ReactNode;
    name: string;
    readOnlyLabel: string;
}> = ({ label, children, name, readOnlyLabel }) => (
    <>
        <div className="z-0 pt-2">
            <div className="bg-white shadow-md ring-1 ring-calfrac-gray/5  md:col-span-2">
                {label && <Title title={label} />}
                <div className="flex flex-col gap-x-6 gap-y-14">
                    {children}

                    <div className={"mt-10 flex w-full justify-end"}>
                        <div className={"w-96 p-2"}>
                            <StandardReadOnly
                                label={readOnlyLabel}
                                name={name}
                                format={Formats.TwoDecimal.toString()}
                                alignRight={true}
                                bold={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

const EquipmentPricingFormSection: React.FC<{
    label: string;
    children: ReactNode;
    name: string;
    readOnlyLabel: string;
    totalsAndSubsections: { SubSection: string; Total?: number }[];
}> = ({ label, children, name, readOnlyLabel, totalsAndSubsections }) => (
    <>
        <div className="z-0 pt-2">
            <div className="bg-white shadow-md ring-1  ring-calfrac-gray/5 md:col-span-2">
                {label && <Title title={label} />}
                <div className="flex flex-col gap-x-6 gap-y-14">
                    {children}
                    <div
                        className={
                            "align-self-end mt-10 flex w-1/2 max-w-[23.75rem] flex-col justify-end gap-y-6 justify-self-end p-2 font-bold"
                        }
                    >
                        <div>
                            <div className="grid grid-cols-2 text-sm">
                                <div className="bg-calfrac-gray-100 p-2">
                                    {resources.SubSection}
                                </div>
                                <div className="bg-calfrac-gray-100 p-2">
                                    {resources.Total}
                                </div>
                                {totalsAndSubsections.map((subsection) => (
                                    <>
                                        <div className="bg-calfrac-gray-50 p-2 font-normal">
                                            {subsection.SubSection}
                                        </div>
                                        <div className="bg-calfrac-gray-50 p-2 font-normal">
                                            {formatNumber(
                                                subsection.Total ?? 0.0,
                                                Formats.TwoDecimal.toString(),
                                            )}
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                        <StandardReadOnly
                            label={readOnlyLabel}
                            name={name}
                            format={Formats.TwoDecimal.toString()}
                            alignRight={true}
                            bold={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default Page;

import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { createColumnHelper } from "@tanstack/react-table";

import { PremixedChemicalTypeId } from "types/generated/Calfrac/Jet/Core/Models/PremixedChemicalTypeId";

import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FormattedNumericCell from "components/Cells/FormattedNumericCell";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import { OverrideCell } from "components/Cells/OverrideCell";
import LoadDefaults from "components/shared/CustomGridToolbar/ToolbarElements/LoadDefaults";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { resources } from "utils/resources";

type PremixedChemicalsTableProps = {
    name: string;
    renderHash?: string;
    tableType: PremixedChemicalTypeId;
};

const columnHelper = createColumnHelper<any>();

const AdditionalChemicals: React.FC<PremixedChemicalsTableProps> = ({
    tableType,
    ...props
}) => {
    const maxDepthDefault = useWatch({ name: "MaxDepthDefault" });

    const { isPageEditable } = useProgram();
    const { program, programId } = useProgramParams();

    const tableRef = useRef<HTMLTableElement>(null);

    const { setValue } = useFormContext();

    const units = useUnits();

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 20,
                maxSize: 20,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.accessor((row) => row.PremixedChemical, {
                id: "PremixedChemical",
                size:
                    tableType === PremixedChemicalTypeId.ExternalInhibitor
                        ? 1100
                        : 900,
                maxSize:
                    tableType === PremixedChemicalTypeId.ExternalInhibitor
                        ? 1100
                        : 900,
                header: resources.Chemical,
                cell: DropDown,
            }),
            columnHelper.accessor((row) => row.Frequency, {
                id: "Frequency",
                size: 120,
                maxSize: 120,
                header: `${resources.Frequency} ${units.hour}`,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.VolumePerPill, {
                id: "VolumePerPill",
                size: 120,
                maxSize: 120,
                header: `${resources.VolumePerPill} ${units.volumeWetMediumUnit}`,
                cell: FormattedNumericCell,
            }),
            columnHelper.accessor((row) => row.ChemicalVolume, {
                id: "ChemicalVolume",
                size: 160,
                maxSize: 160,
                header: `${resources.ChemicalVolume} ${units.massOrVolume}`,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.ChemicalTotal, {
                id: "ChemicalTotal",
                size:
                    tableType === PremixedChemicalTypeId.PeriodicPill
                        ? 180
                        : 120,
                maxSize:
                    tableType === PremixedChemicalTypeId.PeriodicPill
                        ? 180
                        : 120,
                header: `${
                    tableType === PremixedChemicalTypeId.PeriodicPill
                        ? resources.ChemicalConcentrationPerVolume
                        : resources.ChemicalTotal
                } ${units.concentrationPerVolume}`,
                cell:
                    tableType === PremixedChemicalTypeId.PeriodicPill
                        ? FormattedNumericCell
                        : NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.MaxDepth, {
                id: "MaxDepth",
                size: 80,
                maxSize: 80,
                header: `${resources.MaxDepth} ${units.depthUnit}`,
                cell: (props) => (
                    <OverrideCell
                        pDefaultName={"MaxDepth"}
                        pOverriddenName={"OverriddenMaxDepth"}
                        overrideTooltip={`${resources.ValueHasBeenOverriddenClearTheCellToResetTheValueToTheDefaultOne}`}
                        {...props}
                    />
                ),
            }),
            columnHelper.accessor((row) => row.RunMultiplier, {
                id: "RunMultiplier",
                size: 120,
                maxSize: 120,
                header: `${resources.RunMultiplier} ${units.runMultiplier}`,
                cell: FormattedNumericCell,
            }),
        ];
    }, [
        tableType,
        units.depthUnit,
        units.hour,
        units.massOrVolume,
        units.runMultiplier,
        units.volumeWetMediumUnit,
        units.concentrationPerVolume,
    ]);

    const columnVisibility = () => {
        return {
            MaxDepth: tableType === PremixedChemicalTypeId.ExternalInhibitor,
            RunMultiplier:
                tableType === PremixedChemicalTypeId.ExternalInhibitor,
            Loading: tableType === PremixedChemicalTypeId.PeriodicPill,
            Frequency: tableType === PremixedChemicalTypeId.PeriodicPill,
            VolumePerPill: tableType === PremixedChemicalTypeId.PeriodicPill,
            ChemicalVolume: tableType === PremixedChemicalTypeId.PeriodicPill,
        };
    };

    // Handlers
    const afterUpdateData = useCallback((columnId: string) => {
        if (columnId !== "PremixedChemical") return;
        updateRenderHash();
    }, []);

    const toolbarExtras = () => {
        return (
            <div
                className={
                    "sticky left-0 z-10 mb-1 flex w-full items-center space-x-4"
                }
            >
                <LoadDefaults
                    url={`/api/PremixedChemical/LoadDefaults?programId=${programId}&chemicalTypeId=${tableType}`}
                    warningMsg={resources.LoadDefaultsWarning}
                    isVisible={
                        isPageEditable &&
                        (tableType ===
                            PremixedChemicalTypeId.ExternalInhibitor ||
                            tableType === PremixedChemicalTypeId.PeriodicPill)
                    }
                    onNewData={(newData) => {
                        for (const row of newData) {
                            const key = Math.random()
                                .toString(36)
                                .substring(2, 15);
                            row.Id = `NEW-${key}`;
                        }
                        setValue(props.name, newData, { shouldDirty: true });
                        updateRenderHash();
                    }}
                />
            </div>
        );
    };

    return (
        <BaseTable
            tableRef={tableRef}
            name={props.name}
            toolbarExtras={toolbarExtras}
            defaultRecord={{
                PremixedChemicalTypeId: tableType,
                Loading: 0.0,
                MaxDepth: maxDepthDefault,
                Frequency: 0.0,
                VolumePerPill: 0.0,
                ChemicalVolume: 0.0,
                ChemicalTotal: 0.0,
                RunMultiplier: 0.0,
            }}
            columns={defaultColumns}
            renderHash={renderHash}
            afterUpdateData={afterUpdateData}
            meta={{
                canDelete: isPageEditable,
                urls: {
                    PremixedChemical: `/api/PremixedChemical/Chemical?countryId=${program?.CountryId}&serviceLineId=${program?.ServiceLineId}`,
                },
            }}
            columnVisibility={columnVisibility()}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default AdditionalChemicals;

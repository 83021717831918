import React, { useCallback, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { Dialog, UploadOnAddEvent } from "@progress/kendo-react-all";
import { serialize } from "object-to-formdata";

import { BaseProps } from "components/shared/CustomGridToolbar/StandardEditGridToolbar";
import StandardAnchorLink from "components/shared/GenericCustomComponents/StandardAnchorLink";
import StandardDownloadLink from "components/shared/GenericCustomComponents/StandardDownloadLink";
import StandardFileUpload from "components/shared/GenericCustomComponents/StandardFileUpload";

import { useProgramParams } from "hooks/useProgramParams";

import { useGridState } from "providers/GridStateProvider";

import { saveJET } from "utils/fetchJet";
import { setFormErrors } from "utils/helpers";
import { resources } from "utils/resources";

const ImportIntervals: React.FC<BaseProps & { onClose: () => void }> = ({
    onClose,
    isVisible = false,
}) => {
    // React-hook-form
    const { setValue, setError, clearErrors } = useFormContext();
    const { programId } = useProgramParams();
    const { isGridEditable } = useGridState();
    const name = "Perforations";

    const AddedIdx = useRef(0);

    // Helpers
    const onFileAdded = useCallback(
        (event: UploadOnAddEvent) => {
            if (event?.newState?.[0]?.getRawFile === undefined) return;

            const file = event.newState?.[0].getRawFile();
            let body = serialize({ ProgramId: programId }, { indices: true });
            body.append("files[]", file, file.name);

            // Calling api to process file and retrieve new perforations
            saveJET(
                "/api/Perforation/ImportClustersXlsx",
                undefined,
                body,
                undefined,
                undefined,
                false,
            )
                .then((res) => {
                    let record = res as Record<string, any>;
                    if (record.Errors) {
                        setFormErrors(record.Errors, setError);
                        return res;
                    } else {
                        let values = res as Array<any>;

                        values = values.map((value) => {
                            if (!value.Id || value.Id === 0) {
                                value.Id = `ADDED-${AddedIdx.current++}`;
                            }
                            return value;
                        });
                        // set the perforation ids
                        setValue(name, values, { shouldDirty: true });
                    }
                })
                .finally(() => onClose());
        },
        [onClose, programId, setError, setValue],
    );

    if (!isVisible || !isGridEditable) return <></>;

    return (
        <>
            {isVisible && (
                <Dialog
                    title={"Import Intervals"}
                    onClose={onClose}
                    width={350}
                    themeColor={"primary"}
                >
                    <StandardDownloadLink
                        url={"/api/Perforation/ImportClustersTemplateXlsx"}
                        text={resources.DownloadImportIntervalsTemplate}
                    />
                    <br />
                    <br />
                    <StandardFileUpload
                        onAdd={onFileAdded}
                        allowMultiple={false}
                        showFileList={false}
                        allowedExtensions={["xlsx"]}
                    />
                </Dialog>
            )}
            <StandardAnchorLink
                text={resources.ImportIntervals.toUpperCase()}
                onClick={() => {
                    clearErrors();
                    onClose();
                }}
            />
        </>
    );
};

export default ImportIntervals;

import React from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { CellContext } from "@tanstack/react-table";

import { IEditStageCalculationViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditStageCalculationViewModel";

import { CellType, ScheduleStagesMetaT, TableMetaT } from "types/Tables/Cells";

import { resources } from "utils/resources";

const CopyRowButton: CellType = (
    props: CellContext<any, unknown> & { className?: string },
) => {
    // Do not allow the user to copy row (a single schedule stage) if that row has any stage calculation errors
    let hasError: boolean = false;
    const calculations: IEditStageCalculationViewModel[] =
        (props.table.options.meta as ScheduleStagesMetaT)?.calculations ?? [];
    for (const calc of calculations) {
        // Reference calculations
        const isReferenceCalculationError =
            typeof calc?.IsError === "boolean" && calc?.IsError;

        // External non-reference calculations
        const isExternalNonReferenceCalculation =
            typeof calc?.IsExternalNonReferenceCalculationError === "boolean" &&
            calc?.IsExternalNonReferenceCalculationError;

        // Must ensure that the row ID matches the PumpScheduleStageId, because we still want to allow copy row for
        // stages that do not have any stage calculation errors
        if (
            props.row.original.Id === calc.PumpScheduleStageId &&
            (isReferenceCalculationError || isExternalNonReferenceCalculation)
        ) {
            hasError = true;
            break;
        }
    }

    return (
        <PlusIcon
            className={`h-6 w-6 cursor-pointer ${
                hasError ? "text-calfrac-green-200" : "text-calfrac-green-600"
            }`}
            aria-hidden="true"
            onClick={() => {
                if (hasError) {
                    alert(
                        resources.YouHaveUnresolvedCalculationErrorsOnThisStage,
                    );
                } else {
                    (props.table.options.meta as TableMetaT).copyRow(
                        props.row.original.Id,
                        props.row.index,
                    );
                }
            }}
        />
    );
};

// Do not memoize this component, because we want it to re-render when stage calculation errors are resolved, thus
// changing the state of the Copy Row button (for individual stages within a schedule) from disabled to enabled.
export default CopyRowButton;

import styled from "styled-components";

import {
    CALFRAC_BLUE_CELL_STATE_OVERRIDDEN_COLOR,
    CALFRAC_RED_CELL_STATE_ERROR_COLOR,
} from "const/styleColors";

const redCellStateErrorStyle = `
  &::after {
    z-index: 1;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 10px solid ${CALFRAC_RED_CELL_STATE_ERROR_COLOR};
    border-left: 10px solid transparent;
  }
`;

const overriddenStyle = `
  &::after {
    z-index: 1;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 10px solid ${CALFRAC_BLUE_CELL_STATE_OVERRIDDEN_COLOR};
    border-left: 10px solid transparent;
  }
`;

/**
 * Used to conditionally style calculation cell with an override/formula indicator, or in the case of schedule stage
 * copy with reference errors: an error indicator (red tick).
 *
 * @param overridden
 * @param isError - Used to wrap calculation cell components, in the event of a copy schedule stage resulting in cells
 * with references that cannot be found on the target. This will style the cell with a red tick in the upper right hand
 * corner of the cell. If isError is true, it will take precedence over the overridden flag.
 */
export const OverriddenDiv = styled.div<{
    isError: boolean;
    isOverridden: boolean;
}>`
    position: relative;
    ${(props) => {
        // Error state takes precedence over the overridden style
        if (props.isError) {
            return redCellStateErrorStyle;
        } else if (props.isOverridden) {
            return overriddenStyle;
        }
    }}
`;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
    ComboBox as KendoComboBox,
    ComboBoxChangeEvent,
    ComboBoxFilterChangeEvent,
    filterBy,
} from "@progress/kendo-react-all";
import { GridCellProps } from "@progress/kendo-react-grid";
import styled, { css } from "styled-components";

import TableCell from "components/kendoExtensions/gridExtensions/TableCell";
import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";
import StandardReadOnly from "components/shared/GenericCustomComponents/StandardReadOnly";

import useStatefulQuery from "hooks/useStatefulQuery";

import { useGridState } from "providers/GridStateProvider";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import { handleEditableCellKeyDown } from "utils/keyDownHandlers";

import { LoaderWithLabel } from "./LoaderWithLabel";

interface Props<T = string, E = string> {
    className?: string;
    id?: string;
    value?: T;
    data?: E[];
    // Data Retrieval
    url?: JetApiUrls;
    params?: Record<string, string> | string;
    queryKey?: string[];
    // Configuration
    textField?: string;
    disabled?: boolean;
    filterable?: boolean;
    queryEnabled?: boolean;
    label?: string;

    // Events
    onChange?: (e: E) => void;
    getValue?: (value?: T, data?: E[]) => E | undefined;
    // Used with react hook form to register element
    name?: string;
    setValue?: (value?: E) => T | undefined;
    byField?: "Id" | "Name" | "Object";
}

export const ComboBox = ({
    filterable = true,
    name = " ",
    allData,
    className,
    ...props
}: Props & { allData: string[] | undefined }) => {
    // React hook form
    const { setValue, clearErrors } = useFormContext();

    const value = useWatch({ name });

    // State
    const [filteredData, setFilteredData] = useState<string[] | undefined>(
        allData,
    );

    // Update filtered data in case use enters a value not currently present in the combobox
    useEffect(() => {
        setFilteredData(allData);
    }, [allData]);

    // Filtering
    const filterData = useCallback(
        (event: ComboBoxFilterChangeEvent) => {
            if (allData === undefined) return;
            setFilteredData(
                filterBy(allData ?? [], [event.filter], "contains"),
            );
        },
        [allData],
    );

    const handleChange = useCallback(
        (e: ComboBoxChangeEvent) => {
            let value = e.target.value;
            setValue(name, value, { shouldDirty: true });
            clearErrors(name);
        },
        [clearErrors, name, setValue],
    );

    const currentValue = useMemo(() => {
        if (!props.byField || props.byField === "Object") {
            return value;
        }
        return value ?? value[props.byField ?? "Name"] ?? "";
    }, [props.byField, value]);

    return (
        <DropDownListContainer
            className={className}
            disabled={props.disabled}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleEditableCellKeyDown(e, "input", clearErrors)
            }
        >
            {props.label && (
                <StandardLabel labelFor={props.id} label={props.label} />
            )}
            <KendoComboBox
                style={{ width: "100%" }}
                data={filteredData ?? allData}
                value={currentValue}
                filterable={filterable}
                onFilterChange={filterData}
                onChange={handleChange}
                disabled={props.disabled}
                allowCustom={true}
                className={props?.disabled ? "bg-calfrac-gray-50" : ""}
                textField={
                    allData?.[0] && typeof allData?.[0] === "object"
                        ? (props?.byField ?? "Name")
                        : undefined
                }
            />
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </DropDownListContainer>
    );
};

const StandardComboBox = ({ queryEnabled = true, ...props }: Props) => {
    // Queries
    const [allData, , { isLoading }] = useStatefulQuery<string[] | undefined>({
        queryKey: [
            props.url,
            props.id ?? "",
            ...(props.queryKey ?? []),
            ...Object.entries(props.params ?? []).map(
                ([key, value]) => `${key}:${value}`,
            ),
        ],
        queryFn: async () => {
            return await fetchJET(props.url, props.params);
        },
        enabled: queryEnabled && !props.data,
    });

    if (props.disabled) return <StandardReadOnly {...props} />;
    if (isLoading) return <LoaderWithLabel label={props.label} />;

    return (
        <>
            <ComboBox {...props} allData={allData} />
        </>
    );
};

export const GridComboBox: React.FC<
    GridCellProps & { byField?: "Id" | "Name" | "Object" }
> = (props) => {
    const { isGridEditable, formName } = useGridState();
    const name = `${formName}.${props.dataIndex}.${props.field}`;
    const inEdit = props.dataItem.inEdit;

    let value = useWatch({ name });

    // Helpers
    const currentValue = useMemo(() => {
        return value && typeof value === "object"
            ? value[props.byField ?? "Name"]
            : (value ?? "");
    }, [props.byField, value]);

    if (!inEdit) {
        return <TableCell {...props}>{currentValue}</TableCell>;
    }

    return (
        <TableCell {...props}>
            <StandardComboBox
                url={props.format}
                name={name}
                disabled={!isGridEditable}
                byField={props.byField}
            />
        </TableCell>
    );
};

const DropDownListContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${(props) =>
        props.disabled
            ? css`
                  .k-clear-value,
                  .k-button {
                      display: none;
                  }
              `
            : ""}
`;

export default StandardComboBox;

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Tooltip } from "@progress/kendo-react-all";
import { CellContext } from "@tanstack/react-table";

import { TableMetaT } from "types/Tables/Cells";

import { OverriddenDiv } from "components/shared/StyledComponents/OverriddenDiv";

import BaseNumericCell from "./BaseNumericCell";

export const OverrideCell = ({
    row,
    column: { id },
    table,
    pDefaultName,
    pOverriddenName,
    pDefaultValue,
    pOverriddenValue,
    overrideTooltip,
    defaultViewFormat,
    allowSameValueOverride = false,
    applyReadOnlyStyle = false,
}: CellContext<any, any> & {
    pDefaultName: string;
    pOverriddenName: string;
    pDefaultValue?: number; // Used instead of default name if we have an object with an embedded array
    pOverriddenValue?: number; // Used ALONG overridden name if we have an object with an embedded array
    overrideTooltip?: string;
    defaultViewFormat?: string;
    allowSameValueOverride?: boolean;
    applyReadOnlyStyle?: boolean;
}) => {
    const overriddenValue = pOverriddenValue ?? row.original?.[pOverriddenName];
    const defaultValue = useRef(pDefaultValue ?? row.original?.[pDefaultName]);

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState<number | null>(overriddenValue);

    const isOverridden = useMemo(() => {
        return (
            (value !== null && value !== undefined && allowSameValueOverride) ||
            (value !== null &&
                value !== undefined &&
                value !== row.original?.[pDefaultName])
        );
    }, [allowSameValueOverride, pDefaultName, row.original, value]);

    // Makes sure to update the current value in case row.original changes
    // defaultValue will never cause a re-render because we are using useRef
    useEffect(() => {
        if (row.original?.[pDefaultName] === defaultValue.current) return;
        setValue(overriddenValue ?? row.original?.[pDefaultName]);
    }, [overriddenValue, pDefaultName, row.original]);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = useCallback(() => {
        // Some inputs require the original field to be updated
        // Others require the overridden field to be updated.
        // This covers both cases and lets the API endpoint manage the logic.

        const columnId = pOverriddenName;
        let newValue = value;
        if ((newValue ?? "").toString().length === 0) {
            newValue = null;
            setValue(newValue);
        }
        (table.options.meta as TableMetaT)?.updateNumericCellData(
            row.index,
            columnId,
            newValue !== defaultValue.current || allowSameValueOverride
                ? newValue
                : null,
        );
    }, [
        pOverriddenName,
        value,
        table.options.meta,
        row.index,
        allowSameValueOverride,
    ]);

    useEffect(() => {
        setValue(overriddenValue);
    }, [overriddenValue, pOverriddenName]);

    return (
        <OverriddenDiv isOverridden={isOverridden} isError={false}>
            <Tooltip anchorElement={"target"} position={"top"}>
                <span
                    title={
                        isOverridden
                            ? `${overrideTooltip} (${
                                  pDefaultValue ?? row.original?.[pDefaultName]
                              })`
                            : ""
                    }
                    className={"d-flex"}
                >
                    <BaseNumericCell
                        applyReadOnlyStyle={applyReadOnlyStyle}
                        table={table}
                        onBlur={onBlur}
                        rowIndex={row.index}
                        columnId={id}
                        pasteColumnId={pOverriddenName}
                        value={value}
                        displayNullValue={defaultValue.current}
                        setValue={(v) => setValue(v)}
                        defaultViewFormat={defaultViewFormat}
                    />
                </span>
            </Tooltip>
        </OverriddenDiv>
    );
};

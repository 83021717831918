import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { DocumentDuplicateIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Loader } from "@progress/kendo-react-all";
import { CellContext, createColumnHelper } from "@tanstack/react-table";

import { IEditFlowConfigurationViewModel } from "types/generated/Calfrac/Jet/Web/Models/FlowConfiguration/IEditFlowConfigurationViewModel";

import { FlowConfigurationsMetaT } from "types/Tables/Cells";

import { queryClient } from "AppRoutes/AppProviders";

import CheckBox from "components/Cells/CheckBox";
import DeleteButton from "components/Cells/DeleteButton";
import { IconCell } from "components/Cells/IconCell";
import TextCell from "components/Cells/TextCell";
import { isProgramCtan } from "components/Layout/CtanView";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram, useProgramParams } from "hooks/useProgramParams";

import { saveJET } from "utils/fetchJet";
import { resources } from "utils/resources";

type FlowConfigurationsTableProps = {
    name: string;
    renderHash?: string;
};

const columnHelper = createColumnHelper<IEditFlowConfigurationViewModel>();

const FlowConfigurationsTable: React.FC<FlowConfigurationsTableProps> = (
    props,
) => {
    const data = useWatch({ name: props.name });

    const { isPageEditable, program } = useProgram();
    const isCtan = isProgramCtan(program);

    const tableRef = useRef<HTMLTableElement>(null);

    const { programId } = useProgramParams();

    const [isCopyingId, setIsCopyingId] = useState<string | null>(null);
    const { reset } = useFormContext();
    const { append } = useFieldArray({ name: props.name });

    // Updating this render hash will rerender all the rows. This is useful for data changes like copy down and add Row.
    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    useEffect(() => {
        updateRenderHash();
    }, [props.renderHash]);

    useEffect(() => {
        if (!isCopyingId || !program?.ProgramId) {
            return;
        }

        saveJET<Record<string, any>>(
            "/api/FlowConfiguration/CopyFlowConfiguration",
            {
                flowConfigurationId: isCopyingId,
                programId: program.ProgramId.toString(),
            },
        )
            .then((res) => res.json())
            .then((res) => {
                const newFlowConfiguration = res[
                    "NewFlowConfiguration"
                ] as IEditFlowConfigurationViewModel;
                append(newFlowConfiguration);
                queryClient.invalidateQueries({
                    predicate: (query) => {
                        return (query.queryKey[0] as string)?.startsWith(
                            "/api/FlowConfiguration/EditFlowConfigurations",
                        );
                    },
                    type: "all",
                });
                reset({}, { keepValues: true });
            })
            .finally(() => {
                setIsCopyingId(null);
                updateRenderHash();
            });
    }, [isCopyingId, append, program?.ProgramId, reset]);

    const defaultColumns = useMemo(() => {
        return [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
                enablePinning: true,
            }),
            columnHelper.display({
                size: 40,
                maxSize: 40,
                header: " ",
                cell: (props) => (
                    <IconCell
                        {...props}
                        tooltip={resources.Edit}
                        to={`/FlowConfiguration/EditFlowConfiguration/${programId}/${props.row.original.Id}`}
                        isEmpty={isNaN(Number(props.row.original.Id))}
                    >
                        <PencilIcon
                            className={"h-5 w-5 stroke-calfrac-green"}
                        />
                    </IconCell>
                ),
            }),
            columnHelper.accessor((row) => row.IsBottomsUp, {
                id: "IsBottomsUp",
                size: 40,
                maxSize: 40,
                header: resources.BottomsUp,
                cell: CheckBox,
            }),
            columnHelper.display({
                id: "DocumentDuplicateAction",
                size: 30,
                header: resources.Copy,
                cell: (
                    props: CellContext<
                        IEditFlowConfigurationViewModel,
                        unknown
                    >,
                ) => {
                    return (
                        <>
                            {props.row.original.Id !== undefined &&
                                !props.row.original.Id.toString().startsWith(
                                    "NEW-",
                                ) &&
                                ((
                                    props.table.options
                                        .meta as FlowConfigurationsMetaT
                                ).isCopyingId ===
                                props.row.original.Id.toString() ? (
                                    <Loader size="medium" type={"pulsing"} />
                                ) : (
                                    <DocumentDuplicateIcon
                                        className={
                                            "w-100 flex h-6 cursor-pointer justify-center text-calfrac-green-600"
                                        }
                                        aria-hidden="true"
                                        onClick={() => {
                                            (
                                                props.table.options
                                                    .meta as FlowConfigurationsMetaT
                                            ).copyRow(
                                                props.row.original.Id.toString(),
                                            );
                                        }}
                                    />
                                ))}
                        </>
                    );
                },
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Description, {
                id: "Description",
                size: 1400,
                maxSize: 1400,
                header: resources.FlowConfigurationDescription,
                cell: TextCell,
            }),
        ];
    }, [programId]);

    // (CTAN only) If there is no existing rows, then the first record default for IsBottomsUp is true,
    // but after that, default for IsBottomsUp is false
    let dataTableRows: Record<string, any>[] = Array.from(data ?? []);
    const defaultRecord =
        !isCtan || (Array.isArray(dataTableRows) && dataTableRows.length > 0)
            ? {}
            : { IsBottomsUp: true };

    return (
        <BaseTable
            tableRef={tableRef}
            name={props.name}
            defaultRecord={defaultRecord}
            columnVisibility={{
                IsBottomsUp: isCtan,
            }}
            columns={defaultColumns}
            renderHash={renderHash}
            meta={{
                canDelete: isPageEditable,
                copyRow: (rowId: string) => {
                    if (isCopyingId || !program?.ProgramId) {
                        return;
                    }

                    setIsCopyingId(rowId);
                    updateRenderHash();
                },
                isCopyingId: isCopyingId,
            }}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default FlowConfigurationsTable;

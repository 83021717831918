import { useMsal } from "@azure/msal-react";

const useAdminPermissions = () => {
    const msal = useMsal();
    const result = msal.instance.getActiveAccount();
    const roles = result?.idTokenClaims?.roles;

    return roles?.includes("JET.ADMIN");
};

export default useAdminPermissions;

import React, { ReactNode } from "react";

import { ServiceLineId } from "types/generated/Calfrac/Jet/Core/Models/ServiceLineId";

import { IProgramContextViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/IProgramContextViewModel";

import { useProgram } from "hooks/useProgramParams";

/**
 * React component that guards rendering of children based on whether the current program is "Coiled Tubing",
 * and the coiled tubing feature flag is enabled.
 *
 * @param invert - If true, the children will be rendered if the current program is not "Coiled Tubing" and the feature flag is disabled.
 * @param {ReactNode} children - React component(s) that will be rendered if conditions are met
 *
 * @returns {JSX.Element | null} Returns the children if program is "Coiled Tubing" and the feature flag is enabled, otherwise returns null.
 */
const CtanView: React.FC<{ children: ReactNode; invert?: boolean }> = ({
    children,
    invert = false,
}) => {
    // Getting the current program parameters.
    const { program } = useProgram();
    // If the current program is not "Coiled Tubing" or the feature flag is disabled, return null.
    const isCtan = isProgramCtan(program);

    if (invert ? isCtan : !isCtan) return null;

    // If the current program is "Coiled Tubing" and the feature flag is enabled, return the children.
    return <>{children}</>;
};

export const NotCtanView: React.FC<{
    children: ReactNode;
    negate?: boolean;
}> = ({ children }) => {
    return <CtanView children={children} invert={true} />;
};

export const isProgramCtan = (
    program: IProgramContextViewModel | undefined,
) => {
    return (
        program?.ServiceLineId === ServiceLineId.CoiledTubing ||
        program?.ServiceLineId === ServiceLineId.Pumping
    );
};

export default CtanView;

import React from "react";

import { IconHeaderCell } from "components/Cells/IconHeaderCell";

import { resources } from "utils/resources";

type Props = {
    label?: string;
    labelFor?: string;
    className?: string;
    bold?: boolean;
    required?: boolean;
    tooltipText?: string;
    showTooltip?: boolean;
};

const StandardLabel: React.FC<Props> = ({
    className,
    label = "",
    labelFor = "",
    bold = true,
    required = false,
    tooltipText = "",
    showTooltip = false,
}: Props) => {
    return (
        <div className={className ?? "mb-0.5"}>
            <label
                htmlFor={labelFor}
                className={`flex text-sm leading-6 text-calfrac-gray ${
                    bold ? "font-bold" : "font-medium"
                }`}
            >
                {required && (
                    <span className={"mr-0.5 text-red-600"}>
                        {resources.Asterisk}
                    </span>
                )}
                {showTooltip ? (
                    <IconHeaderCell
                        title={label}
                        tooltipText={tooltipText}
                        titleStyleClassName={"font-bold"}
                    />
                ) : (
                    <div>{label}</div>
                )}
            </label>
        </div>
    );
};

export default StandardLabel;

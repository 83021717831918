import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import {
    BeakerIcon,
    DocumentDuplicateIcon,
    ListBulletIcon,
    PaperClipIcon,
    PencilIcon,
} from "@heroicons/react/24/outline";
import { Loader, Tooltip } from "@progress/kendo-react-all";
import {
    CellContext,
    createColumnHelper,
    VisibilityState,
} from "@tanstack/react-table";

import { IEditScheduleViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditScheduleViewModel";

import { SchedulesMetaT } from "types/Tables/Cells";

import { queryClient } from "AppRoutes/AppProviders";

import CheckBox from "components/Cells/CheckBox";
import DeleteButton from "components/Cells/DeleteButton";
import DropDown from "components/Cells/DropDown";
import FluidTotalCell from "components/Cells/FluidTotalCell";
import IntegerCell from "components/Cells/IntegerCell";
import NumericReadOnly from "components/Cells/NumericReadOnly";
import RangeCell from "components/Cells/RangeCell";
import TextCell from "components/Cells/TextCell";
import TimeMaskedCell from "components/Cells/TimeMaskedCell";
import CementView, { isProgramCement } from "components/Layout/CementView";
import { isProgramCtan } from "components/Layout/CtanView";
import { OverriddenDiv } from "components/shared/StyledComponents/OverriddenDiv";
import { RecordType } from "components/Tables/BaseTable/Inner/InnerTable";
import BaseTable from "components/Tables/BaseTable/Tables/BaseTable";

import { useProgram } from "hooks/useProgramParams";
import { useUnits } from "hooks/useUnits";

import { EmptyMasks, Masks } from "utils/enumerations";
import { downloadJET, saveJET } from "utils/fetchJet";
import { resources } from "utils/resources";

import { WindowType } from "views/Schedules/EditSchedules";

type SchedulesTableProps = {
    name: string;
    openWindow: (windowType: WindowType, scheduleIndex: number) => void;
};

const columnHelper = createColumnHelper<IEditScheduleViewModel>();

const SchedulesTable: React.FC<SchedulesTableProps> = (props) => {
    const { name, openWindow } = props;
    const model = useWatch();
    const { setValue, reset } = useFormContext();
    const { append } = useFieldArray({ name });
    const { isPageEditable, program } = useProgram();

    const [isCopyingId, setIsCopyingId] = useState<string | null>(null);

    const isCtan = isProgramCtan(program);
    const isCement = isProgramCement(program);

    const unit = useUnits();

    const defaultColumns = useMemo(
        () => [
            // Display Column
            columnHelper.display({
                id: "Delete",
                size: 40,
                maxSize: 40,
                cell: DeleteButton,
                enableHiding: true,
            }),
            columnHelper.display({
                id: "EditStagesAction",
                size: 30,
                header: resources.EditSchedule,
                cell: (props: CellContext<IEditScheduleViewModel, unknown>) => {
                    // CTAN If any of the stages on this schedule have calculation errors, we show an error cell style on the
                    // "Edit Schedule" column button for this row
                    const isError = props.row.original.HasCalculationErrors;
                    return (
                        <OverriddenDiv isError={isError} isOverridden={false}>
                            <Link
                                hidden={
                                    props.row.original.Id === undefined ||
                                    props.row.original.Id.toString().startsWith(
                                        "NEW-",
                                    )
                                }
                                to={`/Schedules/EditStages/${program?.ProgramId}/${props.row.original.Id}`}
                                className={"cursor-pointer"}
                            >
                                <PencilIcon
                                    className={
                                        "w-100 flex h-6 cursor-pointer justify-center text-calfrac-green"
                                    }
                                    aria-hidden="true"
                                />
                            </Link>
                        </OverriddenDiv>
                    );
                },
            }),
            columnHelper.display({
                id: "AdvancedChemScheduleAction",
                size: 30,
                header: resources.AdvancedChemicalSchedule,
                cell: (props) => {
                    const isOverridden =
                        props.row.original.IsChemicalLoadingOverriden ||
                        props.row.original.IsActivatorLoadingOverriden;
                    return (
                        <Link
                            hidden={
                                props.row.original.Id === undefined ||
                                props.row.original.Id.toString().startsWith(
                                    "NEW-",
                                )
                            }
                            to={`/ChemicalLoading/EditChemicalSchedule/${program?.ProgramId}/${props.row.original.Id}/1`}
                        >
                            <OverriddenDiv
                                isOverridden={isOverridden}
                                isError={false}
                            >
                                <Tooltip
                                    className={
                                        "align-center flex justify-center"
                                    }
                                    anchorElement={"target"}
                                    position={"right"}
                                >
                                    <span
                                        title={
                                            isOverridden
                                                ? resources.ChemicalScheduleContainsLoadingOverrides
                                                : resources.ChemicalSchedule
                                        }
                                    >
                                        <BeakerIcon
                                            className={
                                                "w-100 flex h-6 cursor-pointer justify-center text-calfrac-green"
                                            }
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Tooltip>
                            </OverriddenDiv>
                        </Link>
                    );
                },
                enableHiding: true,
            }),
            columnHelper.display({
                id: "AdvancedSecondaryChemScheduleAction",
                size: 30,
                header: resources.SecondaryAdvancedChemicalSchedule,
                cell: (props) => {
                    const isOverridden =
                        props.row.original
                            .IsChemicalLoadingOverridenForSecondaryFluid;
                    return (
                        <Link
                            hidden={
                                props.row.original.Id === undefined ||
                                props.row.original.Id.toString().startsWith(
                                    "NEW-",
                                )
                            }
                            to={`/ChemicalLoading/EditChemicalSchedule/${program?.ProgramId}/${props.row.original.Id}/2`}
                        >
                            <OverriddenDiv
                                isOverridden={isOverridden}
                                isError={false}
                            >
                                <Tooltip
                                    className={
                                        "align-center flex justify-center"
                                    }
                                    anchorElement={"target"}
                                    position={"right"}
                                >
                                    <span
                                        title={
                                            isOverridden
                                                ? resources.ChemicalScheduleContainsLoadingOverrides
                                                : resources.ChemicalSchedule
                                        }
                                    >
                                        <BeakerIcon
                                            className={
                                                "w-100 flex h-6 cursor-pointer justify-center text-calfrac-green"
                                            }
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Tooltip>
                            </OverriddenDiv>
                        </Link>
                    );
                },
                enableHiding: true,
            }),
            columnHelper.display({
                id: "ChemicalsAction",
                size: 30,
                header: resources.Chemicals,
                cell: (props) => {
                    // Do not allow user to open the Total Chemical window if this is a new schedule row that has not been saved yet
                    return (
                        <>
                            {props.row.original.Id !== undefined &&
                                !props.row.original.Id.toString().startsWith(
                                    "NEW-",
                                ) && (
                                    <ListBulletIcon
                                        onClick={() =>
                                            (
                                                props.table.options
                                                    .meta as SchedulesMetaT
                                            ).openChemicalsWindow(
                                                props.row.index,
                                            )
                                        }
                                        className={
                                            "w-100 flex h-6 cursor-pointer justify-center text-calfrac-green"
                                        }
                                        aria-hidden="true"
                                    />
                                )}
                        </>
                    );
                },
            }),
            columnHelper.display({
                id: "DocumentDuplicateAction",
                size: 30,
                header: resources.Copy,
                cell: (props: CellContext<IEditScheduleViewModel, unknown>) => {
                    // We do not allow the user to copy a schedule if it has any stage with any stage calculation
                    // error. Show a detailed alert message instead
                    const isError = props.row.original.HasCalculationErrors;
                    return (
                        <>
                            {props.row.original.Id !== undefined &&
                                !props.row.original.Id.toString().startsWith(
                                    "NEW-",
                                ) &&
                                ((props.table.options.meta as SchedulesMetaT)
                                    .isCopyingId ===
                                props.row.original.Id.toString() ? (
                                    <Loader size="medium" type={"pulsing"} />
                                ) : (
                                    <DocumentDuplicateIcon
                                        className={`w-100 flex h-6 cursor-pointer justify-center ${
                                            isError
                                                ? "text-calfrac-green-200"
                                                : "text-calfrac-green-600"
                                        }`}
                                        aria-hidden="true"
                                        onClick={() => {
                                            if (isError) {
                                                alert(
                                                    resources.YouHaveUnresolvedCalculationErrorsOnThisSchedule,
                                                );
                                            } else {
                                                (
                                                    props.table.options
                                                        .meta as SchedulesMetaT
                                                ).copyRow(
                                                    props.row.original.Id.toString(),
                                                );
                                            }
                                        }}
                                    />
                                ))}
                        </>
                    );
                },
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ScheduleNumber, {
                id: "ScheduleNumber",
                header: resources.ScheduleNumber,
                size: isCtan ? 40 : 70,
                cell: IntegerCell,
            }),
            columnHelper.accessor((row) => row.Name, {
                id: "Name",
                header: resources.ScheduleName,
                size: 250,
                cell: TextCell,
            }),
            columnHelper.accessor((row) => row.ZoneRanges, {
                id: "ZoneRanges",
                header: resources.ZonesStages,
                size: 70,
                cell: RangeCell,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.Repeated, {
                id: "Repeated",
                header: resources.Repeated,
                size: 40,
                cell: IntegerCell,
            }),
            columnHelper.accessor((row) => row.Description, {
                id: "Description",
                header: resources.Description,
                size: 150,
                cell: TextCell,
            }),
            columnHelper.accessor((row) => row.IsOnNewPage, {
                id: "IsOnNewPage",
                header: isCtan
                    ? resources.ScheduleOnNewPage
                    : resources.ScheduleOnNewPage,
                size: 70,
                cell: CheckBox,
            }),
            columnHelper.accessor((row) => row.IsChemicalScheduleOnNewPage, {
                id: "IsChemicalScheduleOnNewPage",
                header: resources.ChemScheduleOnNewPage,
                size: 70,
                cell: CheckBox,
            }),
            columnHelper.accessor((row) => row.IsOptional, {
                id: "IsOptional",
                header: resources.Optional,
                size: 70,
                cell: CheckBox,
            }),
            columnHelper.accessor((row) => row.IsExecuted, {
                id: "IsExecuted",
                header: resources.Executed,
                size: 70,
                cell: CheckBox,
            }),
            columnHelper.accessor((row) => row.ConcentrationCalcMode, {
                id: "ConcentrationCalcMode",
                header: resources.ConcentrationCalculationMode,
                size: 130,
                cell: DropDown,
                enableHiding: true,
            }),
            columnHelper.accessor((row) => row.ProppantTotal, {
                id: "ProppantTotal",
                header: `${resources.ProppantTotal} ${unit.massScheduleUnit}`,
                size: 90,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.CementTotalBags, {
                id: "CementTotalBags",
                header: `${resources.CementTotal} ${unit.massCementMedium}`,
                size: 90,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.CementTotal, {
                id: "CementTotal",
                header: `${resources.CementTotal} ${unit.massCementSmall}`,
                size: 90,
                cell: NumericReadOnly,
            }),
            columnHelper.accessor((row) => row.FluidTotal, {
                id: "FluidTotal",
                header: `${resources.FluidTotal} ${unit.volumeSchedule}`,
                size: 50,
                cell: FluidTotalCell,
            }),
            columnHelper.accessor((row) => row.TimeTotal, {
                id: "TimeTotal",
                header: resources.TimeTotal,
                size: 90,
                cell: (props) => (
                    <TimeMaskedCell
                        mask={Masks.TimeInputMaskHHMM}
                        emptyMask={EmptyMasks.TimeInputMaskHHMM}
                        readOnly={true}
                        {...props}
                    />
                ),
            }),
            columnHelper.display({
                id: "WellboreDiagram",
                size: 25,
                header: resources.WellboreDiagram,
                cell: (props) => {
                    return (
                        <>
                            {props.row.original.Id !== undefined &&
                                !props.row.original.Id.toString().startsWith(
                                    "NEW-",
                                ) && (
                                    <PaperClipIcon
                                        onClick={() =>
                                            (
                                                props.table.options
                                                    .meta as SchedulesMetaT
                                            ).openWellboreWindow(
                                                props.row.index,
                                            )
                                        }
                                        className={
                                            "w-100 flex h-5 cursor-pointer justify-center text-calfrac-green"
                                        }
                                        aria-hidden="true"
                                    />
                                )}
                        </>
                    );
                },
            }),
        ],
        [
            isCtan,
            program?.ProgramId,
            unit.massCementMedium,
            unit.massCementSmall,
            unit.massScheduleUnit,
            unit.volumeSchedule,
        ],
    );

    const hiddenColumns = useMemo(() => {
        const disabledCoiledTubingColumns = [
            "AdvancedChemScheduleAction",
            "AdvancedSecondaryChemScheduleAction",
            "IsChemicalScheduleOnNewPage",
            "ConcentrationCalcMode",
            "ProppantTotal",
        ];
        const res = defaultColumns.reduce((visibilityState, column) => {
            if (!column.id) return visibilityState;

            let colVisibility;
            switch (column.id) {
                case "AdvancedChemScheduleAction":
                case "IsChemicalScheduleOnNewPage":
                    colVisibility = model?.IsAdvancedChemSchedule;
                    break;
                case "AdvancedSecondaryChemScheduleAction":
                    colVisibility =
                        model?.IsAdvancedChemSchedule &&
                        model?.HasSecondaryFluidSystem;
                    break;
                case "DocumentDuplicateAction":
                    colVisibility = isPageEditable;
                    break;
                case "ConcentrationCalcMode":
                    colVisibility =
                        !isCement &&
                        (model?.HasEnergy || model?.HasSecondaryFluidSystem);
                    break;
                case "ProppantTotal":
                    colVisibility = !isCement;
                    break;
                case "CementTotal":
                    colVisibility = isCement;
                    break;
                case "CementTotalBags":
                    colVisibility = isCement;
                    break;
                case "WellboreDiagram":
                    colVisibility = isCement;
                    break;
                default:
                    colVisibility = true;
                    break;
            }

            if (isCtan && disabledCoiledTubingColumns.includes(column.id))
                colVisibility = false;

            visibilityState[column.id] = colVisibility ?? true;
            return visibilityState;
        }, {} as VisibilityState);

        return {
            ...res,
            ZoneRanges: !isCtan && !isCement,
            Name: isCtan,
            Repeated: isCtan,
        };
    }, [
        defaultColumns,
        isCement,
        isCtan,
        isPageEditable,
        model?.HasEnergy,
        model?.HasSecondaryFluidSystem,
        model?.IsAdvancedChemSchedule,
    ]);

    useEffect(() => {
        if (!isCopyingId || !program?.ProgramId) return;

        saveJET<Record<string, any>>("/api/Schedule/CopySchedule", {
            scheduleId: isCopyingId,
            programId: program.ProgramId.toString(),
        })
            .then((res) => res.json())
            .then((res) => {
                const newPumpSchedule = res[
                    "NewPumpSchedule"
                ] as IEditScheduleViewModel;
                append(newPumpSchedule);
                queryClient.invalidateQueries({
                    predicate: (query) => {
                        return (
                            (query.queryKey[0] as string)?.startsWith(
                                "/api/Schedule/EditSchedules",
                            ) ||
                            (query.queryKey[0] as string)?.startsWith(
                                "/api/DesignResults/EditDesignResults",
                            )
                        );
                    },
                    type: "all",
                });
                reset({}, { keepValues: true });
            })
            .finally(() => {
                setIsCopyingId(null);
                updateRenderHash();
            });
    }, [isCopyingId, append, program?.ProgramId, reset]);

    const nextScheduleNumber = useCallback(() => {
        if (!model?.PumpSchedules?.length) return 1;
        return (
            Math.max(
                ...model?.PumpSchedules?.map(
                    (e: IEditScheduleViewModel) => e.ScheduleNumber,
                ),
            ) + 1
        );
    }, [model?.PumpSchedules]);

    const [renderHash, updateRenderHash] = useReducer(
        () => Math.random().toString(36).substring(2, 15),
        Math.random().toString(36).substring(2, 15),
    );

    const afterAddRecord = useCallback(
        (val: RecordType[]) => {
            const newValues = val as unknown as IEditScheduleViewModel[];
            newValues[newValues.length - 1].ScheduleNumber =
                nextScheduleNumber();
            newValues[newValues.length - 1].Repeated = 1;
            setValue(props.name, newValues, { shouldDirty: true });
        },
        [nextScheduleNumber, props.name, setValue],
    );

    // Download Wellbore Diagram Template - Clickable link to download file (Cement only)
    const toolbarExtras = () => {
        return (
            <CementView>
                <div
                    className={
                        "sticky left-0 z-10 flex w-full items-center justify-between"
                    }
                >
                    <div className={"space-x-4"}>
                        <div
                            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                                e.preventDefault();
                                e.stopPropagation();
                                downloadJET(
                                    `/api/Schedule/GetAttachmentTemplate`,
                                );
                            }}
                            className={
                                "cursor-pointer text-sm uppercase text-calfrac-green underline hover:text-calfrac-green-300"
                            }
                        >
                            {resources.DownloadWellboreDiagramTemplate}
                        </div>
                    </div>
                </div>
            </CementView>
        );
    };

    return (
        <BaseTable
            afterAddRecord={afterAddRecord}
            defaultRecord={{
                ConcentrationCalcMode: {
                    Id: 1,
                    Name: "Surface",
                },
            }}
            toolbarExtras={toolbarExtras}
            name={props.name}
            columns={defaultColumns}
            columnVisibility={hiddenColumns}
            disableSelectColumns={[
                "Delete",
                "EditStagesAction",
                "AdvancedChemScheduleAction",
                "AdvancedSecondaryChemScheduleAction",
                "ChemicalsAction",
                "DocumentDuplicateAction",
                "IsOnNewPage",
                "IsChemicalScheduleOnNewPage",
                "IsOptional",
                "IsExecuted",
                "ProppantTotal",
                "FluidTotal",
                "TimeTotal",
                "CementTotalBags",
                "CementTotal",
                "WellboreDiagram",
            ]}
            renderHash={renderHash}
            meta={{
                canDelete: isPageEditable,
                urls: {
                    ConcentrationCalcMode:
                        "/api/Schedule/GetConcentrationCalculationModes",
                },
                openChemicalsWindow: (rowIndex: number) =>
                    openWindow(WindowType.Chemicals, rowIndex),
                openFluidsWindow: (rowIndex: number) =>
                    openWindow(WindowType.Fluids, rowIndex),
                openWellboreWindow: (rowIndex: number) =>
                    openWindow(WindowType.Wellbore, rowIndex),
                copyRow: (rowId: string) => {
                    if (isCopyingId || !program?.ProgramId) return;

                    setIsCopyingId(rowId);
                    updateRenderHash();
                },
                isCopyingId,
            }}
            copyDownEnabled={true}
        />
    );
};
// Default exported so that the memoization is named in the dev tools
export default memo(SchedulesTable);

import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RadioButton } from "@progress/kendo-react-inputs";
import styled from "styled-components";

import StandardLabel from "components/shared/GenericCustomComponents/StandardLabel";

type Props = {
    id?: string;
    // @deprecated
    target?: boolean; // The value for which this radio button should be checked
    curValue?: boolean; // The current value of the property in the model
    label?: string;
    onChange?: (val: boolean) => void;
    disabled?: boolean;
    width?: string;
    // Used with react hook form to register element
    name?: string;
};

const StandardRadioButton: React.FC<Props> = ({
    label,
    disabled,
    target,
    name = " ",
    width = "auto",
}: Props) => {
    const { setValue } = useFormContext();
    const value = useWatch({ name });

    return (
        <RadioButtonContainer width={width}>
            <RadioButton
                checked={value === target}
                onClick={() => {
                    setValue(name, Boolean(target), {
                        shouldDirty: true,
                    });
                }}
                className="white border-2 border-calfrac-gray-100 checked:border-calfrac-green checked:bg-calfrac-green"
                disabled={disabled}
            />
            {label && <StandardLabel label={label} className={"pl-2"} />}
        </RadioButtonContainer>
    );
};

const RadioButtonContainer = styled.div<{ width: string }>`
    width: ${(props) => props.width};
    display: flex;
    align-items: center;
    & .separator {
        width: 10px;
    }
`;

export default StandardRadioButton;

import React, {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";

import { IProgramContextViewModel } from "types/generated/Calfrac/Jet/Web/Models/Shared/IProgramContextViewModel";

import { WarningButton } from "components/Buttons";
import AdminView from "components/Layout/AdminView";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import useInterval from "hooks/useInterval";
import useStatefulQuery from "hooks/useStatefulQuery";

import { fetchJET, saveJET } from "utils/fetchJet";
import { pageEditable } from "utils/helpers";
import {
    initialPDFGenerationStatus,
    pollPDFGenerationStatus,
} from "utils/pdfGenerationStatus";
import { resources } from "utils/resources";

type ProgramContextViewModel = {
    program: IProgramContextViewModel | undefined;
    isLoading: boolean;
    isPageEditable: boolean;
    refetchContext: () => void;
};
export const ProgramContext = createContext<ProgramContextViewModel>({
    program: undefined,
    isLoading: false,
    isPageEditable: true,
    refetchContext: () => {},
});

/**
 * Many tested components need data related to the current program item.
 *
 * This context makes that widely available.
 * @param children
 * @constructor
 */
export const ProgramProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const { programId } = useParams();
    const [program, , { isLoading, refetch }] = useStatefulQuery({
        queryKey: ["/api/Program/GetProgramContext", programId],
        queryFn: async () => {
            if (!programId) return undefined;
            return await fetchJET<IProgramContextViewModel | undefined>(
                `/api/Program/GetProgramContext?programId=${programId}`,
            );
        },
    });

    const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
    const [pollingInterval, setPollingInterval] = useState<number | null>(null);
    const location = useLocation();

    // initial poll for pdf status since states are wiped if user refreshed page or navigated to another route
    useEffect(() => {
        initialPDFGenerationStatus(
            programId,
            (isPDFStillGenerating: boolean) => {
                if (isPDFStillGenerating) {
                    setIsGeneratingPDF(true);
                    setPollingInterval(5000);
                }
            },
        );
    }, [location, programId]);

    // Polls API endpoint for pdf generation status every specific polling interval (milliseconds).
    // Set polling interval to null to stop polling.
    useInterval(() => {
        pollPDFGenerationStatus(programId, (isPDFStillGenerating: boolean) => {
            if (!isPDFStillGenerating) {
                setIsGeneratingPDF(false);
                setPollingInterval(null);
            }
        });
    }, pollingInterval);

    const resetPDFStatus = useCallback(() => {
        // post to api endpoint to reset pdf status
        saveJET("/api/ClientOutputs/ResetGenerating", {
            programId: programId ?? "",
        }).then(() => {
            setIsGeneratingPDF(false);
            setPollingInterval(null);
        });
    }, [programId]);

    return (
        <ProgramContext.Provider
            value={{
                program,
                isLoading,
                refetchContext: refetch,
                isPageEditable: useMemo(
                    () =>
                        pageEditable(
                            program?.IsActive,
                            program?.WorkflowStatusId,
                        ),
                    [program?.IsActive, program?.WorkflowStatusId],
                ),
            }}
        >
            {isGeneratingPDF && (
                <LoadingOverlay message={resources.GeneratingPDF}>
                    <AdminView>
                        <div className={"absolute top-[calc(50%+8rem)]"}>
                            <WarningButton onClick={resetPDFStatus}>
                                {"Admin Only: Reset PDF Status"}
                            </WarningButton>
                        </div>
                    </AdminView>
                </LoadingOverlay>
            )}
            {program !== undefined && children}
        </ProgramContext.Provider>
    );
};

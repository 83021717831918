import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";

import { CellType } from "types/Tables/Cells";

import NumericReadOnly from "./NumericReadOnly";
import NumericReadOnlyNullable from "./NumericReadOnlyNullable";

const NumericReadOnlyProppant: CellType = (props) => {
    const stages = useWatch({
        name: "Stages",
    });
    const emphasize = useMemo(() => {
        const index = props.row.index;
        const row = stages[index];
        for (let i = index + 1; i < stages.length && row.ProgramProppant; i++) {
            let curProgramProppant = stages?.[i]?.ProgramProppant;
            if (curProgramProppant) {
                return curProgramProppant?.Id !== row?.ProgramProppant?.Id;
            }
            if (i + 1 === stages.length) {
                return true;
            }
        }
        return (
            row?.ProgramProppant &&
            stages.length >= 2 &&
            index + 1 === stages.length
        );
    }, [props.row.index, stages]);

    return props.row.original.IsShutdown ? (
        <NumericReadOnlyNullable
            {...props}
            className={`${emphasize ? "font-black text-black underline" : ""}`}
        />
    ) : (
        <NumericReadOnly
            {...props}
            className={`${emphasize ? "font-black text-black underline" : ""}`}
        />
    );
};

// Default exported so that the memoization is named in the dev tools
export default React.memo(NumericReadOnlyProppant, (prevProps, nextProps) => {
    const prevValue = prevProps.getValue();
    const nextValue = nextProps.getValue();
    return prevValue === nextValue;
});

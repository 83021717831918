import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { ErrorMessage } from "@hookform/error-message";

import { NumericTextBoxContainer } from "components/kendoExtensions/standardExtensions/StandardNumericTextBox";

import { Formats } from "utils/enumerations";
import { handleStandardNumericTextBoxKeyDown } from "utils/keyDownHandlers";

import StandardLabel from "./StandardLabel";

type Props = {
    id?: string;
    value?: number | null;
    defaultValue?: number | null;
    readOnly?: boolean;
    label?: string;
    // Used with react hook form to register element
    name?: string;
    // Number formatting
    inEditFormat?: string;
    inViewFormat?: string;
    // Others
    className?: string;
};

export const CustomNumericTextBox: React.FC<Props> = ({
    id,
    label,
    name = " ",
    defaultValue = 0,
    readOnly = false,
    inViewFormat = Formats.TwoDecimal,
    inEditFormat = Formats.FiveDecimal,
    className,
    ...props
}: Props) => {
    const { setValue, clearErrors } = useFormContext();

    let value = useWatch({ name }) ?? defaultValue;
    if (props.value !== undefined) {
        value = props.value;
    }

    const isEditable = !readOnly;

    // format for the column is slightly different pattern than for decimal places in this cell,
    // map to appropriate format, ex: {0:n2} becomes 2 decimals
    const viewModeDecimals = useMemo((): number => {
        const regex = /\{0:n(\d+)\}/;
        const match = inViewFormat?.toString()?.match(regex);

        if (match) {
            return Number(match[1]);
        }
        return 2;
    }, [inViewFormat]);

    // edit format for the column is slightly different pattern than for decimal places in this cell,
    // map to appropriate format, ex: n2 becomes 2 decimals
    const editModeDecimals = useMemo((): number => {
        const regex = /\d+/;
        const match = inEditFormat.match(regex);
        if (match) {
            return Number(match[0]);
        }
        return 5;
    }, [inEditFormat]);

    // State

    const [localValue, setLocalValue] = useState<number | null>(value);
    const [focused, setFocused] = useState<boolean>(false);
    const [decimalPlaces, setDecimalPlaces] =
        useState<number>(viewModeDecimals);

    // if form data changes in the parent (ie. after a save we refetch data that's calculated in the backend)
    // then we need to make sure we have the latest value from the form
    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    // updates input formatting for view mode vs edit mode
    useEffect(() => {
        setDecimalPlaces(focused ? editModeDecimals : viewModeDecimals);
    }, [focused, editModeDecimals, viewModeDecimals]);

    // Handlers
    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const inputAsNumber = e.target.value as unknown as number;

        if (!inputAsNumber) {
            setLocalValue(defaultValue);
        } else if (!Number.isNaN(inputAsNumber)) {
            setLocalValue(inputAsNumber);
        }
    };

    const onFocus = () => {
        setLocalValue(value);
        setFocused(true);

        // select content in the input by default when clicking on it
        setTimeout(() => {
            (document.activeElement as HTMLInputElement)?.select();
        }, 100);
    };

    // we don't save value to form on actual change locally to reduce re-renders,
    // blur will instead call parent onChange to save value to form once all edits are done
    const onBlur: React.FocusEventHandler<HTMLInputElement> = () => {
        setValue(name, localValue);
        setFocused(false);
    };

    return (
        <NumericTextBoxContainer
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleStandardNumericTextBoxKeyDown(
                    e,
                    name,
                    localValue,
                    clearErrors,
                    setValue,
                )
            }
            className={className}
        >
            {label && <StandardLabel labelFor={id} label={label} />}
            <NumericFormat
                id={id}
                disabled={!isEditable}
                name={name}
                decimalScale={decimalPlaces}
                value={localValue}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                className={`rounded border-calfrac-gray-200 px-2 py-1 text-sm focus:shadow-none focus:ring-calfrac-gray-100 focus:ring-offset-1 ${
                    !isEditable ? "text-black " : ""
                }`}
                fixedDecimalScale={!isEditable}
            />
            <ErrorMessage
                name={name}
                render={({ message }) => (
                    <span className="text-xs text-red-600">{message}</span>
                )}
            />
        </NumericTextBoxContainer>
    );
};

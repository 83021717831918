import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { CountriesWithUnitsT, fieldFormats } from "./formatLookupTable";

/**
 * @deprecated - use fieldFormats[<name>][<countryId>] instead.
 *
 * Search for the format for a given field in a page/grid
 *
 * @param rootName name of the page/grid the field resides in, if several grids in one page syntax is "Page.Grid"
 * @param fieldName name of the field to be formatted
 * @param countryCode country code for a program
 * @returns format for a field based on country the program is in
 */
export const findFieldFormat = (
    rootName: string,
    fieldName: string,
    countryCode: number = CountryId.Canada,
): string => {
    return findFieldFormatNullable(rootName, fieldName, countryCode) || "n5";
};

/**
 * @deprecated - use fieldFormats[<name>][<countryId>] instead.
 *
 * @param rootName
 * @param fieldName
 * @param countryCode
 */
export const findFieldFormatNullable = (
    rootName: string,
    fieldName: string,
    countryCode: CountryId = CountryId.Canada,
): string | undefined => {
    const fullFieldName = `${rootName ? rootName + "." : ""}${fieldName}`;

    // is the name in the fieldFormats object?
    if (!fieldFormats[fullFieldName]) {
        return undefined;
    }
    const fieldFormat = fieldFormats[fullFieldName];

    let supportedCountryId: CountriesWithUnitsT = CountryId.UnitedStates;
    if (
        countryCode === CountryId.Canada ||
        countryCode === CountryId.UnitedStates ||
        countryCode === CountryId.Argentina
    ) {
        supportedCountryId = countryCode;
    }
    return fieldFormat[supportedCountryId];
};

import React, { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { serialize } from "object-to-formdata";

import { IEditRequestViewModel } from "types/generated/Calfrac/Jet/Web/Models/Request/IEditRequestViewModel";

import { saveJET } from "utils/fetchJet";
import { setFormErrors } from "utils/helpers";
import { resources } from "utils/resources";

import { PrimaryButton } from "./index";

export const AcceptAllProgramsButton = ({
    setIsAccepting,
    shouldBeAbleToAcceptAll,
}: {
    setIsAccepting?: (val: boolean) => void;
    shouldBeAbleToAcceptAll?: boolean;
}) => {
    const navigate = useNavigate();
    const { formState, setError } = useFormContext();
    const model = useWatch() as IEditRequestViewModel;
    const onClick = useCallback(
        async (event: {
            preventDefault: () => void;
            stopPropagation: () => void;
        }) => {
            if (formState.isDirty) {
                alert(resources.YouHaveUnsavedChanges);
                return;
            }

            event.preventDefault();
            event.stopPropagation();
            if (setIsAccepting) {
                setIsAccepting(true);
            }
            for (const program of model.Programs) {
                if (program.NewProgramId === null) {
                    await saveJET(
                        "/api/Request/AcceptRequestProgramWithModel",
                        {},
                        serialize({ program }, { indices: true }),
                    ).then((res) => {
                        const data = res as Record<string, any>;
                        // If there are server validation errors
                        if (data.Errors) {
                            if (setIsAccepting) {
                                setIsAccepting(false);
                            }
                            setFormErrors(data.Errors, setError);
                            // throw so onSuccess isn't hit
                            throw Error("Error was returned from server");
                        }
                    });
                }
            }
            navigate(0);
        },
        [formState.isDirty, model.Programs, navigate, setError, setIsAccepting],
    );

    // Don't render the button
    if (!shouldBeAbleToAcceptAll) {
        return <></>;
    }

    return (
        <PrimaryButton onClick={onClick}>
            {resources.AcceptAllPrograms}
        </PrimaryButton>
    );
};

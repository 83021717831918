import React, { useCallback, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { SortDescriptor } from "@progress/kendo-data-query";
import { Dialog, GridColumn, GridNoRecords } from "@progress/kendo-react-all";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useMutation } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";
import styled from "styled-components";

import { CountryId } from "types/generated/Calfrac/Jet/Core/Models/CountryId";

import { IProgramIndexItemViewModel } from "types/generated/Calfrac/Jet/Web/Models/Program/IProgramIndexItemViewModel";
import { IEditScheduleStagesViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditScheduleStagesViewModel";

import BoundDate from "components/kendoExtensions/gridExtensions/BoundDate";
import StandardEditGrid, {
    EditGridItemType,
} from "components/kendoExtensions/grids/StandardEditGrid";
import StandardIndexGrid from "components/kendoExtensions/grids/StandardIndexGrid";
import { isProgramCtan } from "components/Layout/CtanView";
import {
    CountryFilterCell,
    CTTreatmentObjectiveFilterCell,
    ServiceLineFilterCell,
} from "components/shared/Filter/FilterDropdowns";
import StandardButton from "components/shared/GenericCustomComponents/StandardButton";
import { GridCheckBox } from "components/shared/GenericCustomComponents/StandardCheckBox";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import { DATE_COLUMN_PROPS } from "const/columns";

import { useProgramParams } from "hooks/useProgramParams";

import { Formats } from "utils/enumerations";
import { fetchJET, saveJET } from "utils/fetchJet";
import { resources } from "utils/resources";

type CopyPumpScheduleStagesProps = {
    title?: string;
    onClose?: () => void;

    // If true (form is dirty), the user will be prevented from the copy pump schedule stages functionality
    isFormDirty?: boolean;

    // Call to re-render all rows, used in mutation
    updateRenderHash: () => void;
};

const DEFAULT_SORT: SortDescriptor[] = [
    { field: "CreatedDate", dir: "desc" },
    { field: "ProgramNumberAndRevision", dir: "desc" },
];

const Page: React.FC<CopyPumpScheduleStagesProps> = ({
    isFormDirty = false,
    updateRenderHash,
}: CopyPumpScheduleStagesProps) => {
    const model = useWatch();
    const { setValue } = useFormContext();

    let { scheduleId } = useParams();
    const { program } = useProgramParams();

    const [visible, setVisible] = React.useState(false);
    const [selectedStateProgram, setSelectedStateProgram] = useState<{
        [id: string]: boolean | number[];
    }>({});
    const [selectedStateSchedule, setSelectedStateSchedule] = useState<{
        [id: string]: boolean | number[];
    }>({});
    const [programsVisible, setProgramsVisible] = useState(true);
    const [programPumpSchedules, setProgramPumpSchedules] = useState<
        EditGridItemType[]
    >([]);

    // Callbacks
    const onChangeSelectedStateProgram = useCallback(
        (val: { [id: string]: boolean | number[] }) => {
            setSelectedStateProgram(val);
        },
        [],
    );

    const onChangeSelectedStateSchedule = useCallback(
        (val: { [id: string]: boolean | number[] }) => {
            setSelectedStateSchedule(val);
        },
        [],
    );

    const dataSourceFilter = useCallback(
        (list: IProgramIndexItemViewModel[] | undefined) => {
            if (!list) {
                return;
            }
            return list.filter(
                (item) =>
                    item?.CountryId === model?.ProgramContext?.CountryId &&
                    item?.ServiceLineId ===
                        model?.ProgramContext?.ServiceLineId,
            );
        },
        [
            model?.ProgramContext?.CountryId,
            model?.ProgramContext?.ServiceLineId,
        ],
    );

    const getPumpSchedulesForProgramId = async (programId: string) => {
        return await fetchJET("/api/Schedule/GetSchedulesForProgramId", {
            programId,
        });
    };

    const selectProgramHandler = useCallback(async () => {
        if (Object.keys(selectedStateProgram).length !== 0) {
            const pumpSchedulesData = (await getPumpSchedulesForProgramId(
                Object.keys(selectedStateProgram)[0],
            )) as EditGridItemType[];
            setProgramPumpSchedules(pumpSchedulesData);
            setProgramsVisible(false);
        } else alert(resources.PleaseSelectTheProgramFirst);
    }, [selectedStateProgram]);

    /**
     * Mutation: fires when user selects the schedule they want to copy from the source program they have already
     * selected.
     */
    const mutation = useMutation({
        mutationFn: async () => {
            const sourceScheduleId = Object.keys(selectedStateSchedule)[0];
            await saveJET<Record<string, any>>(
                "/api/Schedule/GetCopyScheduleStages",
                {},
                serialize(
                    {
                        sourceScheduleId: sourceScheduleId,
                        targetScheduleId: scheduleId,
                    },
                    { indices: true },
                ),
            )
                .then((res) => res.json())
                .then(
                    (
                        editScheduleStagesViewModel: IEditScheduleStagesViewModel,
                    ) => {
                        setValue("Stages", editScheduleStagesViewModel.Stages, {
                            shouldDirty: true,
                        });
                        setValue(
                            "Calculations",
                            editScheduleStagesViewModel.Calculations,
                            {
                                shouldDirty: true,
                            },
                        );
                        setValue(
                            "StagesScheduleNotes",
                            editScheduleStagesViewModel.StagesScheduleNotes,
                            {
                                shouldDirty: true,
                            },
                        );
                        setVisible(false);
                        updateRenderHash();
                    },
                );
        },
    });

    const selectPumpScheduleHandler = useCallback(() => {
        if (Object.keys(selectedStateSchedule)?.length !== 0) {
            mutation.mutate();
        } else alert(resources.PleaseSelectTheProgramSchedule);
    }, [selectedStateSchedule, mutation]);

    const backHandler = useCallback(() => {
        setProgramsVisible(true);
    }, []);

    const isCtan = isProgramCtan(program);

    return (
        <>
            {visible && !isFormDirty && (
                <Dialog
                    title={
                        <div className={"flex items-center justify-end gap-2"}>
                            {!programsVisible && (
                                <ArrowLeftIcon
                                    className={"h-4 w-4 cursor-pointer"}
                                    onClick={backHandler}
                                />
                            )}
                            {resources.CopySchedule}
                        </div>
                    }
                    onClose={() => setVisible(false)}
                    width={1200}
                    height={600}
                    themeColor={"primary"}
                >
                    {mutation.isPending && <LoadingOverlay />}
                    {!mutation.isPending && (
                        <>
                            <GridContainer>
                                {programsVisible && (
                                    <>
                                        <h1 className={"py-2"}>
                                            {
                                                resources.SelectProgramToCopyPricingItemsInstructions
                                            }
                                        </h1>
                                        <StandardIndexGrid<IProgramIndexItemViewModel>
                                            path={
                                                "/api/Program/GetProgramIndexItems"
                                            }
                                            selectable={{
                                                enabled: true,
                                                drag: false,
                                                cell: false,
                                                mode: "single",
                                            }}
                                            onChangeSelectedState={
                                                onChangeSelectedStateProgram
                                            }
                                            initialState={selectedStateProgram}
                                            dataSourceFilter={dataSourceFilter}
                                            customKey={
                                                "ProgramNumberAndRevision"
                                            }
                                            queryData={{
                                                CountryId:
                                                    program?.CountryId.toString() ??
                                                    "",
                                                ServiceLineId:
                                                    program?.ServiceLineId.toString() ??
                                                    "",
                                                ProgramId:
                                                    program?.ProgramId.toString() ??
                                                    "",
                                            }}
                                            initialSort={DEFAULT_SORT}
                                        >
                                            <GridColumn
                                                field={"CreatedDate"}
                                                title={resources.CreatedDate}
                                                width={130}
                                                {...DATE_COLUMN_PROPS}
                                            />
                                            <GridColumn
                                                field={
                                                    "ProgramNumberAndRevision"
                                                }
                                                title={
                                                    resources.ProgramNumberAndRevision
                                                }
                                                width={140}
                                            />
                                            <GridColumn
                                                field={"ProgramWorkflowStatus"}
                                                title={
                                                    resources.ProgramWorkflowStatus
                                                }
                                                width={180}
                                            />
                                            <GridColumn
                                                field={"CountryCode"}
                                                title={resources.Country}
                                                width={100}
                                                filterCell={(props) => (
                                                    <CountryFilterCell
                                                        gridFilterCellProps={
                                                            props
                                                        }
                                                        url={
                                                            "/api/Program/CountryCode"
                                                        }
                                                    />
                                                )}
                                            />
                                            <GridColumn
                                                field={"ServiceLine"}
                                                title={resources.ServiceLine}
                                                width={200}
                                                filterCell={(props) => (
                                                    <ServiceLineFilterCell
                                                        gridFilterCellProps={
                                                            props
                                                        }
                                                        countryId={
                                                            model.ProgramContext
                                                                .CountryId
                                                        }
                                                    />
                                                )}
                                            />
                                            <GridColumn
                                                field={"OperatorName"}
                                                title={
                                                    model?.ProgramContext
                                                        ?.CountryId ===
                                                    CountryId.Argentina
                                                        ? resources.PrimaryOperator
                                                        : resources.Operator
                                                }
                                                width={200}
                                            />
                                            <GridColumn
                                                field={"WellIdentifier"}
                                                title={resources.WellIdentifier}
                                                width={200}
                                            />
                                            <GridColumn
                                                field={"WellName"}
                                                title={resources.WellName}
                                                width={200}
                                            />
                                            <GridColumn
                                                field={"PreparedByName"}
                                                title={resources.PreparedBy}
                                                width={200}
                                            />
                                            <GridColumn
                                                field={"ReviewedBy1Name"}
                                                title={resources.ReviewedBy1}
                                                width={200}
                                            />
                                            <GridColumn
                                                field={"AccountManagerName1"}
                                                title={
                                                    resources.AccountManager1
                                                }
                                                width={200}
                                            />
                                            <GridColumn
                                                field={"AccountManagerName2"}
                                                title={
                                                    resources.AccountManager2
                                                }
                                                width={200}
                                            />
                                            <GridColumn
                                                field={
                                                    "RequestProjectDescription"
                                                }
                                                title={resources.PadName}
                                                width={250}
                                            />
                                            <GridColumn
                                                field={"ConsultantName"}
                                                title={resources.Consultant}
                                                width={200}
                                            />
                                            <GridColumn
                                                field={"ProvinceStateName"}
                                                title={resources.ProvinceState}
                                                width={200}
                                            />
                                            <GridColumn
                                                field={"District"}
                                                title={resources.District}
                                                width={180}
                                            />
                                            <GridColumn
                                                field={"FluidSystemNames"}
                                                title={resources.FluidSystem}
                                                width={180}
                                            />
                                            <GridColumn
                                                field={"FieldName"}
                                                title={resources.Field}
                                                width={180}
                                            />
                                            <GridColumn
                                                field={"FormationNames"}
                                                title={resources.Formation}
                                                width={180}
                                            />
                                            <GridColumn
                                                field={"TotalProppant"}
                                                title={resources.ProppantTotal}
                                                width={180}
                                            />
                                            <GridColumn
                                                field={"CompletionTechnology"}
                                                title={
                                                    resources.CompletionTechnology
                                                }
                                                width={200}
                                            />
                                            <GridColumn
                                                field={
                                                    "ProgramTreatmentObjectives"
                                                }
                                                title={
                                                    resources.TreatmentObjective
                                                }
                                                width={200}
                                                filterCell={
                                                    CTTreatmentObjectiveFilterCell
                                                }
                                            />
                                            <GridColumn
                                                field={"ApprovedDate"}
                                                title={resources.ApprovedDate}
                                                width={130}
                                                cell={(props) => (
                                                    <BoundDate {...props} />
                                                )}
                                                {...DATE_COLUMN_PROPS}
                                            />
                                            <GridColumn
                                                field={
                                                    "CopiedFromProgramNumber"
                                                }
                                                title={resources.CopiedFrom}
                                                width={170}
                                            />
                                        </StandardIndexGrid>
                                    </>
                                )}
                                {!programsVisible && (
                                    <>
                                        <legend className="mb-8 text-base font-semibold leading-6 text-gray-900 ">
                                            <h2
                                                className={"text-2xl font-bold"}
                                            >
                                                Program:{" "}
                                                {
                                                    Object.keys(
                                                        selectedStateProgram,
                                                    )?.[1]
                                                }
                                            </h2>
                                            <h3
                                                className={
                                                    "text-lg font-medium"
                                                }
                                            >
                                                Schedules
                                            </h3>
                                        </legend>
                                        <EditGridWrapper className="mx-4">
                                            <StandardEditGrid
                                                canCreate={false}
                                                onChangeSelectedState={
                                                    onChangeSelectedStateSchedule
                                                }
                                                initialState={
                                                    selectedStateSchedule
                                                }
                                                dataItemKey={"Id"}
                                                canDeleteRecord={false}
                                                height={"375px"}
                                                selectable={{
                                                    enabled: true,
                                                    drag: false,
                                                    cell: false,
                                                    mode: "single",
                                                }}
                                                data={programPumpSchedules}
                                                readOnlyColumns={[
                                                    "ScheduleNumber",
                                                    "TimeTotal",
                                                ]}
                                            >
                                                <GridNoRecords>
                                                    {isCtan
                                                        ? resources.NoSchedulesFoundForThisProgram
                                                        : resources.NoSchedulesFoundForThisProgram}
                                                </GridNoRecords>
                                                <GridColumn
                                                    title={
                                                        resources.ScheduleNumber
                                                    }
                                                    field={"ScheduleNumber"}
                                                    format={Formats.GridInteger.toString()}
                                                    editable={false}
                                                />

                                                <GridColumn
                                                    title={
                                                        resources.ZonesStages
                                                    }
                                                    field={"ZoneRanges"}
                                                    editable={false}
                                                    width={isCtan ? 0 : 200}
                                                />
                                                <GridColumn
                                                    title={
                                                        resources.ScheduleName
                                                    }
                                                    field={"ScheduleName"}
                                                    editable={false}
                                                    width={isCtan ? 200 : 0}
                                                />
                                                <GridColumn
                                                    title={resources.Optional}
                                                    field={"IsOptional"}
                                                    cell={DisabledGridCheckBox}
                                                />
                                                <GridColumn
                                                    title={resources.Executed}
                                                    field={"IsExecuted"}
                                                    cell={DisabledGridCheckBox}
                                                />
                                                <GridColumn
                                                    field={"TimeTotal"}
                                                    title={resources.TimeTotal}
                                                    editable={false}
                                                />
                                            </StandardEditGrid>
                                        </EditGridWrapper>
                                    </>
                                )}
                            </GridContainer>
                            <footer
                                className={
                                    "flex items-center justify-end gap-4"
                                }
                            >
                                {programsVisible && (
                                    <StandardButton
                                        onClick={selectProgramHandler}
                                        text={resources.SelectProgram}
                                    />
                                )}
                                {!programsVisible && (
                                    <StandardButton
                                        onClick={selectPumpScheduleHandler}
                                        text={
                                            isCtan
                                                ? resources.SelectSchedule
                                                : resources.SelectSchedule
                                        }
                                    />
                                )}
                                <StandardButton
                                    text={resources.Cancel}
                                    onClick={() => setVisible(false)}
                                />
                            </footer>
                        </>
                    )}
                </Dialog>
            )}
            <StandardButton
                text={resources.CopySchedule}
                onClick={() => {
                    if (isFormDirty) {
                        alert(resources.YouHaveUnsavedChanges);
                    }
                    setProgramsVisible(true);
                    setVisible(!visible);
                }}
            />
        </>
    );
};

const DisabledGridCheckBox: React.FC<GridCellProps> = (props) => {
    return <GridCheckBox {...props} overrideEditable={false} disabled={true} />;
};

const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 475px;
`;

const EditGridWrapper = styled.div`
    height: 475px;
    max-height: 475px;
`;

export default Page;

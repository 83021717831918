import React from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";

export const SingleErrorSummary: React.FC<{
    title: string;
    errorMsg: string;
}> = ({ title, errorMsg }) => {
    return (
        <div className="mt-4 rounded-md border border-red-800 bg-red-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                        {title}
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                        <span>{errorMsg}</span>;
                    </div>
                </div>
            </div>
        </div>
    );
};

import { useEffect } from "react";

import { resources } from "utils/resources";

/**
 * This is called by default in our <Layout /> component.
 *
 * This will dynamically set the document title based on the current route.
 * @param title
 */
export const useTitle = (title?: string) => {
    useEffect(() => {
        document.title = title
            ? `${title} - ${resources.Jet}`
            : `${resources.Jet}`;
    }, [title]);
};

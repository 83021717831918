import React from "react";

import FormSection from "components/Layout/FormSection";
import StandardForm from "components/shared/GenericCustomComponents/StandardForm";
import ActivatorLoadingTable from "components/Tables/ActivatorLoading/ActivatorLoadingTable";

import { useProgramParams } from "hooks/useProgramParams";

import { resources } from "utils/resources";

const Page: React.FC = () => {
    // Url params
    const { programId } = useProgramParams();

    return (
        <StandardForm
            readUrl={"/api/ActivatorLoading/EditActivatorLoading"}
            readParams={{ programId }}
            saveUrl={"/api/ActivatorLoading/EditActivatorLoading"}
        >
            <FormSection label={resources.ActivatorLoading}>
                <ActivatorLoadingTable name={"ActivatorLoadings"} />
            </FormSection>
        </StandardForm>
    );
};

export default Page;

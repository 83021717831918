// Components
import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";

import { CalculationTypeId } from "types/generated/Calfrac/Jet/Core/Models/CalculationTypeId";

import { IEditStageCalculationViewModel } from "types/generated/Calfrac/Jet/Web/Models/PumpSchedule/IEditStageCalculationViewModel";

import StandardDropDownList from "components/kendoExtensions/standardExtensions/StandardDropDownList";
import StandardMultipleSelect from "components/kendoExtensions/standardExtensions/StandardMultipleSelect";

import { useProgramParams } from "hooks/useProgramParams";

import { JetApiUrls } from "utils/fetchJet";
import { allowsMultipleEntities, getCalculationType } from "utils/helpers";
import { resources } from "utils/resources";

type Props = {
    scheduleStageId: number;
};

const EntityField: React.FC<Props> = ({ scheduleStageId }: Props) => {
    const { programId } = useProgramParams();
    const scheduleId: number = useWatch({ name: "Id" });
    const stageCalculation: IEditStageCalculationViewModel = useWatch({
        name: "Calculation",
    });

    const url =
        `/api/Calculation/Entities?programId=${programId}&scheduleId=${scheduleId}&calcTypeId=${stageCalculation?.CalculationTypeId}&scheduleStageId=${scheduleStageId}` as
            | JetApiUrls
            | undefined;

    const {
        isPerfCalc,
        isFlowConfCalc,
        isSimpleWellConfCalc,
        isWellConfCalc,
        isScheduleCalc,
    } = useMemo(
        () => getCalculationType(stageCalculation?.CalculationTypeId),
        [stageCalculation?.CalculationTypeId],
    );

    const multipleEntitiesPresent: boolean = useMemo(
        () => allowsMultipleEntities(stageCalculation?.CalculationTypeId),
        [stageCalculation?.CalculationTypeId],
    );

    const wellEntityLabel: string | undefined = useMemo(() => {
        switch (stageCalculation.CalculationTypeId) {
            case CalculationTypeId.StartDepthWellConfigCasingTop:
            case CalculationTypeId.StartDepthWellConfigCasingBottom:
            case CalculationTypeId.EndDepthWellConfigCasingTop:
            case CalculationTypeId.EndDepthWellConfigCasingBottom:
                return resources.Casing;
            case CalculationTypeId.StartDepthWellConfigLinerTop:
            case CalculationTypeId.StartDepthWellConfigLinerBottom:
            case CalculationTypeId.EndDepthWellConfigLinerTop:
            case CalculationTypeId.EndDepthWellConfigLinerBottom:
                return resources.Liner;
            case CalculationTypeId.StartDepthWellConfigTubingTop:
            case CalculationTypeId.StartDepthWellConfigTubingBottom:
            case CalculationTypeId.EndDepthWellConfigTubingTop:
            case CalculationTypeId.EndDepthWellConfigTubingBottom:
                return resources.Tubing;
            case CalculationTypeId.StartDepthWellConfigOpenHoleTop:
            case CalculationTypeId.StartDepthWellConfigOpenHoleBottom:
            case CalculationTypeId.EndDepthWellConfigOpenHoleTop:
            case CalculationTypeId.EndDepthWellConfigOpenHoleBottom:
                return resources.OpenHole;
            case CalculationTypeId.StartDepthWellConfigDownholeItem:
            case CalculationTypeId.EndDepthWellConfigDownholeItem:
                return resources.DownholeItem;
        }
    }, [stageCalculation.CalculationTypeId]);

    const label = useMemo(() => {
        // Determine the label for the dropdown depending on what type of calculation it is
        if (isFlowConfCalc) {
            return `${resources.FlowConfiguration} (${resources.Description})`;
        } else if (isScheduleCalc) {
            return resources.ScheduleNumber;
        } else if (isWellConfCalc) {
            return `${wellEntityLabel}`;
        } else if (isPerfCalc) {
            return `${resources.Perforation} (${resources.ZoneStageNumber})`;
        } else {
            return "";
        }
    }, [
        isFlowConfCalc,
        isPerfCalc,
        isScheduleCalc,
        isWellConfCalc,
        wellEntityLabel,
    ]);

    if (isSimpleWellConfCalc) return <></>;

    return (
        <div className={"col-span-2"}>
            {!multipleEntitiesPresent && (
                <StandardDropDownList
                    name={"Calculation.EntityIds.0"}
                    url={url}
                    label={label}
                    byField={"Id"}
                    required={true}
                />
            )}
            {multipleEntitiesPresent && (
                <StandardMultipleSelect
                    name={"Calculation.EntityIds"}
                    url={url}
                    byField={"Id"}
                    label={label}
                />
            )}
        </div>
    );
};

export default EntityField;

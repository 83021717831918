import process from "process";

import { msalInstance } from "./auth";

const getAuthToken = async (): Promise<string> => {
    const msalToken = await msalInstance.acquireTokenSilent({
        scopes: [process.env.REACT_APP_AZURE_SCOPE ?? ""],
        account: msalInstance.getAllAccounts()[0],
    });
    return msalToken.accessToken;
};

export default getAuthToken;

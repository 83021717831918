import React, { useState } from "react";

import { BaseProps } from "components/shared/CustomGridToolbar/StandardEditGridToolbar";
import StandardCheckBox from "components/shared/GenericCustomComponents/StandardCheckBox";

import { useGridState } from "providers/GridStateProvider";

import { resources } from "utils/resources";

export interface DisplayNotInUseProps extends BaseProps {
    onChange?: (val: boolean) => void;
}

const DisplayNotInUse: React.FC<DisplayNotInUseProps> = ({
    isVisible = false,
    onChange,
}: DisplayNotInUseProps) => {
    const [checked, setChecked] = useState<boolean>(false);
    const { isGridEditable } = useGridState();

    if (!isVisible) return <></>;

    return (
        <StandardCheckBox
            checked={checked}
            disabled={!isGridEditable}
            sidelabel={resources.DisplayNotInUse}
            onChange={(val: boolean) => {
                setChecked(val);
                onChange?.(val);
            }}
        />
    );
};

export default DisplayNotInUse;

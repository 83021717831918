import React, { ReactNode } from "react";

import { PrimaryButton } from "components/Buttons";

type Props = {
    form?: string;
    type?: "button" | "submit" | "reset";
    onClick?: () => void;
    text?: string;
    classes?: string;
    id?: string;
    width?: string;
    icon?: ReactNode;
    disabled?: boolean;
};

const StandardButton: React.FC<Props> = ({
    form = "",
    type = "button",
    onClick,
    text = "",
    icon,
    disabled = false,
}: Props) => {
    return (
        <PrimaryButton
            form={form}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            {icon}
            {text}
        </PrimaryButton>
    );
};

export default StandardButton;
